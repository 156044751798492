import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RankMasterService } from './rank-master.service'
import { CSVExport } from '../Csv-export';
import { Helper } from '../helper'
import swal from 'sweetalert2'
@Component({
    moduleId: module.id,
    selector: 'rank-master',
    templateUrl: 'rank-master.component.html',
    styleUrls: ['rank-master.component.scss'],
    providers: [RankMasterService, Helper]
})
export class RankMasterComponent {

    isAddModal: boolean;
    fileInput: any;
    fileReaded: any;
    Courses: any;
    courseTypeId: any;
    Years: any;
    selectedYears: any;
    isCurrent: any
    showLoader: boolean;
    totalMarks: any;
    Ranks: any
    CategoryRanks: any;
    masterRankId: any;
    searchText: any;
    showAlert: boolean;
    alertMsg: any;
    alertType = "";
    column: string = 'CategoryName';
    direction: number;
    isDesc: boolean = false;

    neet_rank_url = Helper.url + "/assets/NEET_Rank.csv";
    iit_neet_rank_url = Helper.url + "/assets/IIT_NIT_Rank.csv";



    constructor(private router: Router, private rankMasterService: RankMasterService, private helper: Helper) {
        this.isAddModal = false
        this.courseTypeId = "2"
        this.selectedYears = "0"
        this.Ranks = []
        this.CategoryRanks = []
        this.showLoader = false
        this.Years = this.helper.getYears();
        var curentDate = new Date();
        this.selectedYears = curentDate.getUTCFullYear();
        if (localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "1") {
            this.getAllCourses()

        }
        else {
            this.router.navigateByUrl('/');
        }
    }

    getAllCourses() {
        this.showLoader = true
        this.rankMasterService.getAllCourses(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false
                if (data.status_code == "0") {
                    this.Courses = data.courses
                    this.getAllRanksMaster();
                }
            },
            err => {
                this.showLoader = false
            }
        )
    }

    getAllRanksMaster() {
        this.showLoader = true
        this.rankMasterService.getAllRanksMaster(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false
                if (data.status_code == "0") {
                    this.Ranks = data.ranks
                    this.totalMarks = this.Ranks[0].total_marks
                    this.CategoryRanks = this.Ranks[0].ranks
                    this.isCurrent = this.Ranks[0].is_current
                    this.selectCurrent();
                }
            },
            err => {
                this.showLoader = false
            }
        )
    }

    addNew() {
        this.isAddModal = true
        this.cleanData()
    }

    cleanData() {
        this.totalMarks = "";
        this.isCurrent = false
    }
    csv2Array(fileInput: any) {
        this.fileInput = fileInput
    }

    save() {
        if (!this.fileInput) {
            swal.fire("Please select file to import.");
        }
        else if (!this.courseTypeId || this.courseTypeId == "0") {
            swal.fire("Please select course.");
        }
        else if (!this.selectedYears || this.selectedYears == "0") {
            swal.fire("Please select year.");
        }
        else if (!this.totalMarks) {
            swal.fire("Please enter total marks.");
        }
        else {
            this.fileReaded = this.fileInput.target.files[0];


            let reader: FileReader = new FileReader();
            reader.readAsText(this.fileReaded);
            let lines = [];
            reader.onload = (e) => {
                let csv: string = reader.result as string;
                let allTextLines = csv.split(/\r|\n|\r/);
                let headers = allTextLines[0].split(',');


                for (let i = 0; i < allTextLines.length; i++) {
                    // split content based on comma
                    let data = allTextLines[i].split(',');
                    if (data.length === headers.length) {
                        let tarr = [];
                        for (let j = 0; j < headers.length; j++) {
                            tarr.push(data[j]);
                        }

                        // log each row to see output 
                        lines.push(tarr);

                    }
                }
                //(lines);
                var currentValue: any
                if (this.isCurrent == true) {
                    currentValue = 1;
                }
                else {
                    currentValue = 0;
                }
                let json = {
                    "caller_email": localStorage.getItem("email"),
                    "id_course_type": this.courseTypeId,
                    "year": this.selectedYears,
                    "excel": JSON.stringify(lines),
                    "total_marks": this.totalMarks,
                    "is_current": currentValue
                }
                this.showLoader = true
                if (this.courseTypeId != "2") {
                    this.rankMasterService.saveRankExcel(json).subscribe(
                        data => {
                            this.showLoader = false
                            if (data.status_code == "0") {
                                this.getAllRanksMaster()
                                this.hideAddEditModal()
                                this.showAlertComponent(data['status_code'], data['status_message']);
                            }
                            else {
                                swal.fire(data.status_message)
                            }
                        },
                        err => {
                            this.showLoader = false
                            swal.fire("Something went wrong, Please try again later.")
                            this.hideAddEditModal()
                        }
                    )
                }
                else {
                    this.rankMasterService.saveRankExcelForNeet(json).subscribe(
                        data => {
                            this.showLoader = false
                            if (data.status_code == "0") {
                                this.getAllRanksMaster()
                                this.hideAddEditModal()
                                this.showAlertComponent(data['status_code'], data['status_message']);
                            }
                            else {
                                swal.fire(data.status_message)
                            }
                        },
                        err => {
                            this.showLoader = false
                            swal.fire("Something went wrong, Please try again later.")
                            this.hideAddEditModal()
                        }
                    )
                }

            }

        }
    }

    hideAddEditModal() {
        this.isAddModal = false
        this.cleanData()
    }
    isButtonActive(courseTypeId) {
        if (this.courseTypeId == courseTypeId)
            return true
        else
            return false
    }
    selectCourse(data) {
        this.courseTypeId = data.id_course_type
        this.selectCurrent()
    }

    selectCurrent() {
        this.CategoryRanks = []
        if (this.Ranks.length > 0) {
            for (let data of this.Ranks) {
                if (data.is_current == "1" && data.id_course_type == this.courseTypeId) {
                    this.masterRankId = data.id_master_rank
                    this.CategoryRanks = data.ranks;
                    this.totalMarks = data.total_marks
                    this.selectedYears = data.year
                    if (data.is_current == "1") {
                        this.isCurrent = true;
                    }
                    else {
                        this.isCurrent = false;
                    }
                    break;
                }
            }
        }

    }

    selectYear() {
        //(this.courseTypeId);
        this.CategoryRanks = []
        if (this.Ranks.length > 0) {
            for (let data of this.Ranks) {
                if (data.year == this.selectedYears && data.id_course_type == this.courseTypeId) {
                    this.masterRankId = data.id_master_rank
                    this.CategoryRanks = data.ranks;
                    this.totalMarks = data.total_marks
                    if (data.is_current == "1") {
                        this.isCurrent = true;
                    }
                    else {
                        this.isCurrent = false;
                    }
                    break;
                }
            }
        }

    }

    setCurrent() {
        if (this.CategoryRanks.length > 0) {
            var value: any;
            if (this.isCurrent = true) {
                value = "1"
            }
            else {
                value = "0"
            }
            this.showLoader = true
            this.rankMasterService.setCurrent(localStorage.getItem("email"), this.selectedYears, this.courseTypeId, value, this.masterRankId).subscribe(
                data => {
                    this.showLoader = false
                    if (data.status_code == "0") {
                        if (data.status_code == "0") {
                            this.Ranks = data.ranks
                            this.totalMarks = this.Ranks[0].total_marks
                            this.CategoryRanks = this.Ranks[0].ranks
                            this.isCurrent = this.Ranks[0].is_current
                            this.selectYear();
                        }
                    }
                    else {
                        swal.fire(data.status_message)
                    }
                },
                err => {
                    this.showLoader = false
                    swal.fire("Something went wrong, Please try again later.")
                    this.hideAddEditModal()
                }
            )
        }
        else {
            swal.fire("No records found.");
        }
    }

    showAlertComponent(status, messege) {
        this.showAlert = true;
        this.alertMsg = messege;
        //("status" + status);
        if (parseInt(status) == 0) {
            this.alertType = "success";
        }
        else {
            this.alertType = "failure";
        }
    }
    hideAlert(val) {
        this.showAlert = val;
    }

    export() {
        var arrayToExport = [];
        if (this.CategoryRanks.length > 0) {
            for (let data of this.CategoryRanks) {
                var json = {}
                if (this.courseTypeId != "2") {
                    json = {
                        "Marks": data.mark,
                        "AIR": data.air,
                        "GEN": data.gen,
                        "GEN-EWS": data.gen_ews,
                        "OBC-NCL": data.obc_ncl,
                        "SC": data.sc,
                        "ST": data.st,
                        "GEN-PD": data.gen_pd,
                        "OBC-PD": data.obc_pd,
                        "SC-PD": data.sc_pd,
                        "ST-PD": data.st_pd,
                    }
                }
                else {
                    json = {
                        "Marks": data.mark,
                        "Percentile": data.percentile,
                        "AIR": data.air,
                        "GEN": data.gen,
                        "GEN-EWS": data.gen_ews,
                        "OBC-NCL": data.obc_ncl,
                        "SC": data.sc,
                        "ST": data.st,
                        "GEN-PD": data.gen_pd,
                        "OBC-PD": data.obc_pd,
                        "SC-PD": data.sc_pd,
                        "ST-PD": data.st_pd,
                    }
                }


                arrayToExport.push(json);
            }
            CSVExport.downloadcsv(arrayToExport, "Rank Master ");
        }
    }

    sort(property) {
        this.isDesc = !this.isDesc; //change the direction    
        this.column = property;
        let direction = this.isDesc ? 1 : -1;

        this.CategoryRanks.sort(function (a, b) {
            if (a[property] < b[property]) {
                return -1 * direction;
            }
            else if (a[property] > b[property]) {
                return 1 * direction;
            }
            else {
                return 0;
            }
        });
    }
}
