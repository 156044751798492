import { Component, OnInit } from '@angular/core';
import { ErrorReportingService } from './error-report.service';
import swal from 'sweetalert2'
@Component({
  selector: 'app-error-report',
  templateUrl: './error-report.component.html',
  styleUrls: ['./error-report.component.css'],
  providers: [ErrorReportingService]
})
export class ErrorReportComponent implements OnInit {
  ActiveErrors = [];
  unfilteredActiveErrors = [];
  errorText: any;
  showLoader = false;
  basicModal: any;
  isUserLogin = true;
  Headers = ["Pending", "Resolved"]
  selectedHeader = "Pending"
  isShowErrorModal = false
  errorData: any;
  constructor(private errorReportingService: ErrorReportingService) {
    this.getAllErrorReport();
  }

  ngOnInit() {
  }

  getAllErrorReport() {
    this.showLoader = true;
    this.unfilteredActiveErrors = [];
    this.errorReportingService.getAllErrorReport(localStorage.getItem("email")).subscribe(
      (data: any) => {
        this.showLoader = false;

        if (data["status_code"] == 0) {
          this.unfilteredActiveErrors = data["error_reports"]
          this.selectHeader("Pending")
        }
        else {
          swal.fire(data["status_message"]);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong.")
      }

    )

  }

  addNewError(basicModal) {
    this.errorText = ""
    basicModal.show();
    this.basicModal = basicModal
  }
  resolvedErrorReport(value) {
    swal.fire({
      title: '',
      text: "Are you sure you want to close this error?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, close it!'
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        let json = {
          "caller_email": localStorage.getItem("email"),
          "id_error_report": value.id_error_reporting
        }
        this.errorReportingService.resolvedErrorReport(json).subscribe(
          (data: any) => {
            this.showLoader = false;

            if (data["status_code"] == 0) {
              this.getAllErrorReport();
            }
            else {
              swal.fire(data["status_message"]);
            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong.")
          }

        )
      }
    })
  }

  selectHeader(data) {
    this.selectedHeader = data
    this.ActiveErrors = [];
    for (let error of this.unfilteredActiveErrors) {
      if (data == "Pending") {
        if (error.is_resolved == "0") {
          this.ActiveErrors.push(error)
        }
      }
      else if (data == "Resolved") {
        if (error.is_resolved == "1") {
          this.ActiveErrors.push(error)
        }
      }
    }
  }

  isHeaderActive(data) {

    var status = false
    if (data == this.selectedHeader)
      status = true
    else
      status = false


    return status

  }

  show(data) {
    this.errorData = data;
    this.isShowErrorModal = true
  }

  hideModal() {
    this.isShowErrorModal = false
  }

}
