import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class CoeMasterService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }
    getAllStates(email) {
        return this.http.get(this.baseUrl + "/Coe/getAllState?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllCoe(email) {
        return this.http.get(this.baseUrl + "/Coe/getAllCoe?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    addNewCoe(jsonData) {
        //JSON.stringify(jsonData);
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Coe/addNewCoe", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }
    editCoe(jsonData) {
        //JSON.stringify(jsonData);
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Coe/editCoe", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeCoe(coeId, email) {
        return this.http.get(this.baseUrl + "/Coe/removeCoe?caller_email=" + email + "&id_coe=" + coeId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    changeStatus(coeId, callerEmail, statusId) {
        return this.http.get(this.baseUrl + "/Coe/changeStatus?&id_coe=" + coeId + "&caller_email=" + callerEmail + "&id_status=" + statusId).map((res) => JSON.parse(JSON.stringify(res)));
    }
}