import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class OnlineLectureService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }

    getMasterDataForLectureGroup(email,roleId) {
        return this.http.get(this.baseUrl + "/LectureGroup/getMasterDataForLectureGroup?caller_email=" + email+"&id_role="+roleId).map((res) => JSON.parse(JSON.stringify(res)));
    }
  
    getAllLectureGroupFullDetail(email,roleId) {
        return this.http.get(this.baseUrl + "/LectureGroup/getAllLectureGroupFullDetail?caller_email=" + email+"&id_role="+roleId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    addNewLectureGroup(jsonData) {
        return this.http.post(this.baseUrl + "LectureGroup/addNewLectureGroup", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    editLectureGroup(jsonData) {
        return this.http.post(this.baseUrl + "LectureGroup/editLectureGroup", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeLectureGroup(email,id_topic_lecture_group) {
        return this.http.get(this.baseUrl + "/LectureGroup/removeLectureGroup?caller_email=" + email+"&id_topic_lecture_group="+id_topic_lecture_group).map((res) => JSON.parse(JSON.stringify(res)));
    }

    publishUnpublishLecture(email,id_topic_lecture_group,id_status,currentFor) {
        return this.http.get(this.baseUrl + "/LectureGroup/publishUnpublishLecture?caller_email=" + email+"&id_topic_lecture_group="+id_topic_lecture_group+"&id_status="+id_status+"&current_for="+currentFor).map((res) => JSON.parse(JSON.stringify(res)));
    }

    addTopicLectureNote(jsonData) {
        return this.http.post(this.baseUrl + "LectureGroup/addTopicLectureNote", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    addLectureNote(jsonData) {
        return this.http.post(this.baseUrl + "LectureGroup/addLectureNote", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeTopicLectureNote(jsonData) {
        return this.http.post(this.baseUrl + "LectureGroup/removeTopicLectureNote", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeLectureNote(jsonData) {
        return this.http.post(this.baseUrl + "LectureGroup/removeLectureNote", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getLectureGroupFullDetailById(email,id_topic_lecture_group) {
        return this.http.get(this.baseUrl + "/LectureGroup/getLectureGroupFullDetailById?caller_email=" + email+"&id_topic_lecture_group="+id_topic_lecture_group).map((res) => JSON.parse(JSON.stringify(res)));
    }

    changeLectureSequence(email,id_topic_lecture_group,ranking) {
        return this.http.get(this.baseUrl + "/LectureGroup/changeLectureSequence?caller_email=" + email+"&id_topic_lecture_group="+id_topic_lecture_group+"&ranking="+ranking).map((res) => JSON.parse(JSON.stringify(res)));
    }

   
}

  