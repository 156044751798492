import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { AttendanceMasterService } from './attendance-master.service'
import { Router } from '@angular/router'
import { Helper } from '../helper'
import { DatePipe } from '@angular/common'

import swal from 'sweetalert2'
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';
import { Subject } from 'rxjs/Subject';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent
} from 'angular-calendar';
@Component({
  selector: 'app-attendance-master',
  templateUrl: './attendance-master.component.html',
  styleUrls: ['./attendance-master.component.css'],
  providers: [AttendanceMasterService, DatePipe]
})
export class AttendanceMasterComponent implements OnInit {
  Topics: any;
  showLoader = false;
  Coes = [];
  coeId = "0"
  Batches = [];
  unfilteredBatches = [];
  batchId = "0"
  Sections = [];
  unfilteredSections = [];
  Atttendance = [];
  sectionId = "0";
  dakshana_roll_number = "";
  date: any;
  isAttendenceDone = "0";
  isShowAbsentReasonModal = false
  AbsentResons = [];
  absentReasonId = "0";
  comment: any;
  tempScholarData: any;
  attendanceFor = "1"
  todayDate: any;
  searchText: "";
  unFilteredAttendance = [];
  fromDate: any;
  toDate: any;
  scholarName: any;
  coeName: any;
  batchName: any;
  sectionName: any;
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  view: string = 'month';
  events: CalendarEvent[];
  colors: any = {
    red: {
      primary: '#ad2121',
      secondary: '#FAE3E3'
    },
    blue: {
      primary: '#1e90ff',
      secondary: '#D1E8FF'
    },
    yellow: {
      primary: '#e3bc08',
      secondary: '#FDF1BA'
    }
  }
  isShowCalender = false;
  alertDiv = false;
  sucessAlertMessage: any;
  purpose = "single";
  batchEndDate: any;
  batchStartDate: any;
  isRole: any;
  isShowTopicModal = false;
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  AttendenceStudents = []
  AttendenceDate = []
  column: string = 'CategoryName';
  direction: number;
  isDesc: boolean = false;
  constructor(private router: Router, private attendanceMasterService: AttendanceMasterService, private datePipe: DatePipe) {
    localStorage.removeItem("previous_attandance_data")
    localStorage.removeItem("current_attandance_data")

    if (localStorage.getItem("isLogin") == "true") {
      this.scholarName = localStorage.getItem("name")
      var tomorrow = new Date();
      this.date = tomorrow.toISOString().split('T')[0];
      this.todayDate = tomorrow.toISOString().split('T')[0];
      this.isRole = localStorage.getItem("id_role");
      if (this.isRole == "5") {
        this.sectionId = localStorage.getItem("id_section_scholar");
        this.dakshana_roll_number= localStorage.getItem("dakshana_roll_number");
        this.getAttendenceBySchoalrId();
      }
      else {
        this.getAllMasterData();
      }
    }
    else {
      this.router.navigateByUrl('/');
    }
  }
  ngOnInit() {
    this.searchText = "";
  }


  daySelected() {
    alert("daySelected");
  }

  getAllMasterData() {
    this.showLoader = true;
    this.attendanceMasterService.getAllMasterDataForAttendence(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.Coes = data.coes;
          this.unfilteredBatches = data.batches
          this.unfilteredSections = data.sections
          this.AbsentResons = data.absent_reasons
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getBatches() {
    this.Batches = [];
    this.Sections = [];
    this.Atttendance = [];
    for (let data of this.unfilteredBatches) {
      if (data.id_coe == this.coeId) {
        this.Batches.push(data);
      }
    }
    for (let data of this.unfilteredSections) {
      if (data.id_coe == this.coeId) {
        this.Sections.push(data);
      }
    }
  }
  getSections() {
    this.Sections = [];
    this.Atttendance = [];
    for (let data of this.unfilteredSections) {
      if (data.id_batch == this.batchId) {
        this.Sections.push(data);
      }
    }
    for (let data of this.Batches) {
      if (data.id_batch == this.batchId) {
        this.batchEndDate = this.datePipe.transform(data.end_date, 'yyyy-MM-dd');;
        this.batchStartDate = this.datePipe.transform(data.start_date, 'yyyy-MM-dd');;
        break;
      }
    }
  }

  getAttendenceBySchoalrId() {
    {
      this.showLoader = true;
      this.Atttendance = []
      this.attendanceMasterService.getAllAttendenceByScholarId(localStorage.getItem("email"), localStorage.getItem("id_user"), this.date, this.attendanceFor).subscribe(
        data => {
          this.showLoader = false;
          this.coeName = data.coe_name;
            this.batchName = data.batch_name;
            this.sectionName = data.section_name;
          if (data.status_code == "0") {
            
            this.unFilteredAttendance = data.attendances
            this.Atttendance = data.attendances;
            this.AttendenceDate = data.attendance_date
            if (this.attendanceFor == "3") {
              this.generateAttendenceForBothForScholar();
            }
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }

  }
  getAttendence() {
    if (this.sectionId == "0") {
      swal.fire("Please select section.")
    }
    else {
      if (this.isRole == "5") {
        this.getAttendenceBySchoalrId();
      }
      else {
        if (this.batchStartDate > this.date) {
          swal.fire("Batch starts from " + this.batchStartDate + ".Please select valid date")
        }
        else if (this.batchEndDate < this.date) {
          swal.fire("Batch ends on" + this.batchEndDate + ".Please select valid date")
        }
        else {
          this.showLoader = true;
          this.Atttendance = []
          this.attendanceMasterService.getAllAttendence(localStorage.getItem("email"), this.sectionId, this.date, this.attendanceFor, this.todayDate).subscribe(
            data => {
              this.showLoader = false;
              if (data.status_code == "0") {
                if (this.events) {
                 
                }
                this.events = [];
                if (this.events) {
                 
                }
                this.Atttendance = data.attendances;
                this.AttendenceStudents = data.attendence_student
                var Events = [];
                Events = data.events
                for (let data of Events) {
                  let json = {
                    start: subDays(startOfDay(data.date), 0),
                    color: this.colors.red,
                    title: "",
                    actions: null
                  }
                  this.events.push(json);
                }
                localStorage.setItem("previous_attandance_data", JSON.stringify(this.Atttendance))
                this.isAttendenceDone = data.is_attendance_done
                if (this.events) {
                }
                if (this.attendanceFor == "3") {
                  this.generateAttendenceForBoth();
                }
              }
              else {
                this.showAlertComponent(data['status_code'], data['status_message']);
              }
            },
            err => {
              this.showLoader = false;
              swal.fire("Something went wrong. Please try again later.")
            }
          )
        }

      }

    }

  }

  generateAttendenceForBothForScholar() {
    var tempStudentAttendence = [];
    for (let dataOne of this.AttendenceDate) {
      var json = {};
      for (let dataSecond of this.Atttendance) {
        if (dataOne.date == dataSecond.date) {

          json["date"] = dataSecond.date,
            json["isAttendenceDone"] = "0"

          if (dataSecond.id_attendance_type == "1") {
            json["attendance_status_for_class"] = dataSecond.attendance_status
            json["absent_reason_for_class"] = dataSecond.reason
            json["absent_reason_for_class_id"] = dataSecond.id_absent_reason
            json["absent_comment_for_class"] = dataSecond.comment
          }
          else {
            json["attendance_status_for_hostel"] = dataSecond.attendance_status
            json["absent_reason_for_hostel"] = dataSecond.reason
            json["absent_reason_for_hostel_id"] = dataSecond.id_absent_reason
            json["absent_comment_for_hostel"] = dataSecond.comment
          }

        }
      }
      if (json["date"]) {
        tempStudentAttendence.push(json);
      }

    }
    this.Atttendance = tempStudentAttendence

  }
  generateAttendenceForBoth() {
    var tempStudentAttendence = [];
    for (let dataOne of this.AttendenceStudents) {
      var json = {};
      for (let dataSecond of this.Atttendance) {
        if (dataOne.id_scholar == dataSecond.id_scholar) {

          json["name"] = dataSecond.name,
            json["mobile"] = dataSecond.mobile,
            json["dakshana_roll_number"] = dataSecond.dakshana_roll_number,
            json["coe_roll_number"] = dataSecond.coe_roll_number,
            json["isAttendenceDone"] = "0"

          if (dataSecond.id_attendance_type == "1") {
            json["attendance_status_for_class"] = dataSecond.attendance_status
            json["absent_reason_for_class"] = dataSecond.reason
            json["absent_reason_for_class_id"] = dataSecond.id_absent_reason
            json["absent_comment_for_class"] = dataSecond.comment
          }
          else {
            json["attendance_status_for_hostel"] = dataSecond.attendance_status
            json["absent_reason_for_hostel"] = dataSecond.reason
            json["absent_reason_for_hostel_id"] = dataSecond.id_absent_reason
            json["absent_comment_for_hostel"] = dataSecond.comment
          }

        }
      }
      if (json["name"]) {
        tempStudentAttendence.push(json);
      }

    }
    this.Atttendance = tempStudentAttendence
  }
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.date = date;
    this.date = this.datePipe.transform(this.date, 'yyyy-MM-dd');
    this.isShowCalender = false;
    if (this.sectionId == "0") {
      swal.fire("Please select section.")
    }
    else if (this.date > this.todayDate) {
      this.date = this.todayDate
      swal.fire("Future date attendence not allowed.")
    }
    else {
      if (this.isRole == "5") {
        this.getAttendenceBySchoalrId();
      }
      else {
        this.getAttendence();
      }


    }

  }

  changeAttandanceStatus(data, status) {
    this.purpose = "single"
    if (status == "5") {
      this.absentReasonId = "0"
      this.comment = ""
      this.tempScholarData = data
      this.isShowAbsentReasonModal = true
    }
    else {
      for (let attendance of this.Atttendance) {
        if (data.id_user == attendance.id_user) {
          attendance.attendance_status = status;
          attendance.id_absent_reason = "0"
          attendance.reason = "";
          attendance.comment = "";
        }
      }
      localStorage.setItem("current_attandance_data", JSON.stringify(this.Atttendance))
    }
  }
  hideAddEditModal() {
    this.isShowCalender = false
    this.absentReasonId = "0"
    this.comment = ""
    this.isShowAbsentReasonModal = false
    this.isShowTopicModal = false
  }
  openCalender() {
    this.isShowCalender = true;
  }

  saveAbsentComment() {
    if (this.absentReasonId == "-1") {
      swal.fire("Please select absent reason.");
    }
    else if ((!this.comment) && this.absentReasonId != "0") {
      swal.fire("Please enter comment.");
    }
    else {
      var absentReason: any;
      for (let data of this.AbsentResons) {
        if (this.absentReasonId == data.id_absent_reason) {
          absentReason = data.reason;
          break;
        }
      }
      for (let attendance of this.Atttendance) {
        if (this.purpose == "single") {
          if (this.tempScholarData.id_user == attendance.id_user) {
            attendance.attendance_status = "5";
            attendance.comment = this.comment;
            attendance.id_absent_reason = this.absentReasonId;
            attendance.reason = absentReason;
          }
        }
        else {
          for (let data of this.Atttendance) {
            if (data.attendance_status != "4" || data.attendance_status != "6") {
              data.attendance_status = "5";
              data.comment = this.comment;
              data.id_absent_reason = this.absentReasonId;
              data.reason = absentReason;
            }
          }
        }

      }
      localStorage.setItem("current_attandance_data", JSON.stringify(this.Atttendance))
      this.hideAddEditModal();
    }

  }

  selectAttandanceFor(attendanceFor) {
    this.attendanceFor = attendanceFor;
  }
  saveAttendance() {
    var checkAllScholarAttendanceMark = false;
    for (let data of this.Atttendance) {
      if (data.attendance_status == "6") {
        checkAllScholarAttendanceMark = true
        break;
      }
    }
    if (checkAllScholarAttendanceMark == true) {
      swal.fire("The attendance for some scholars has not been marked.")
    }
    else {
      let json = {
        "caller_email": localStorage.getItem("email"),
        "attendance": JSON.stringify(this.Atttendance)
      }
      this.showLoader = true;
      this.attendanceMasterService.saveAttandance(json).subscribe(
        data => {
          this.events = [];
          this.showLoader = false;
          if (data.status_code == "0") {
            this.Atttendance = data.attendances
            var Events = [];
            Events = data.events
            for (let data of Events) {
              let json = {
                start: subDays(startOfDay(data.date), 0),
                color: this.colors.red,
                title: "",
                actions: null
              }
              this.events.push(json);
            }
            localStorage.removeItem("previous_attandance_data")
            localStorage.removeItem("current_attandance_data")
            this.isAttendenceDone = "0"
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }

      )
    }
  }

  markAllPresent() {
    this.purpose = "single"
    for (let data of this.Atttendance) {
      if (data.attendance_status != "5" || data.attendance_status != "6") {
        data.attendance_status = "4";
        data.comment = ""
      }
    }
    localStorage.setItem("current_attandance_data", JSON.stringify(this.Atttendance))
  }

  markAllAbsent() {
    this.absentReasonId = "0"
    this.comment = ""
    this.purpose = "All"
    this.isShowAbsentReasonModal = true
  }

  showAlertDiv(message) {
    var that = this
    that.alertDiv = true
    this.sucessAlertMessage = message
    setTimeout(function () {
      that.alertDiv = false
    }, 5000);
  }
  checkAllMarkedOrNot() {
    var check = false;
    for (let data of this.Atttendance) {
      if (data.attendance_status != "6") {
        check = true
      }
      else {
        check = false
      }
    }
 
    return check;
  }


  showTopics(data) {
    this.isShowTopicModal = true
    this.Topics = data
  }

  filterAttendance() {
    if (this.fromDate) {
      this.Atttendance = [];
      for (let data of this.unFilteredAttendance) {
        if (data.date >= this.fromDate) {
          this.Atttendance.push(data)
        }
      }
    }
    if (this.toDate) {
      this.Atttendance = [];
      for (let data of this.unFilteredAttendance) {
        if (data.date <= this.toDate) {
          this.Atttendance.push(data)
        }
      }
    }
    if (this.fromDate && this.toDate) {
      this.Atttendance = [];
      for (let data of this.unFilteredAttendance) {
        if (data.date >= this.fromDate && data.date <= this.toDate) {
          this.Atttendance.push(data)
        }
      }
    }
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }

  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.Atttendance.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  }
}
