import { Component, OnInit } from '@angular/core';
import { LabelConfigurationService } from './label-configuration.service';
import swal from 'sweetalert2'
@Component({
  selector: 'app-label-configuration',
  templateUrl: './label-configuration.component.html',
  styleUrls: ['./label-configuration.component.css'],
  providers:[LabelConfigurationService]
})
export class LabelConfigurationComponent implements OnInit {

  showLoader: boolean;
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  searchText = "";
  addLabel = false;
  grouplabels=[];
  labelGroups= [];
  selectedIdLabelGroup:any;

  constructor(private labelService:LabelConfigurationService) { 
    this.getAllLabels();
  }

  ngOnInit() {
  }

  getAllLabels(){
    this.labelService.getAllLabels(localStorage.getItem("email")).subscribe(
      data => {
          this.showLoader = false;
          if (data.status_code == 0) {
              this.grouplabels = data['group_labels']; 
              this.labelGroups = data['groups'] ;  
                    
          }
          else {
              swal.fire(data['status_message']);
              // this.showAlertComponent(data['status_code'], data['status_message']);
          }
      },
      err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
      }
  )
  }

  hideAlert(val) {
    this.showAlert = val;
  }
  selectedType:any;
  addLabelError = false;
  addLabelErrorMsg = "";
  selectedLabelName ="";
  selectedMinChar="";
  selectedMaxChar="";
  selectedVal1="";
  // selectedVal2="";
  // selectedVal3="";
  // selectedVal4="";
  // selectedVal5="";
  // selectedVal6="";
  selectedFromDate:any;
  selectedToDate:any;
  selectedMinNumber = "";
  selectedMaxNumber = "";

  addNew(){
     this.addLabel = true;
     this.clearForm();
  }

  clearForm()
  {
    this.selectedType=undefined;
    this.addLabelError = false;
    this.addLabelErrorMsg= "";
    this.selectedLabelName ="";
    this.selectedMinChar="";
    this.selectedMaxChar="";
    this.selectedVal1="";
    // this.selectedVal2="";
    // this.selectedVal3="";
    // this.selectedVal4="";
    // this.selectedVal5="";
    // this.selectedVal6="";
    this.selectedFromDate="";
    this.selectedToDate="";
    this.selectedMinNumber = "";
    this.selectedMaxNumber = "";
  }

  closeAddLabel(){
    this.addLabel = false;
  }


  typeSelect() {
    this.addLabelError = false;
    this.addLabelErrorMsg = "";
  }

  addNewLabel() {

    console.log("In addNewLabel selectedType="+this.selectedType);

    if(!this.selectedType){
     
    }

    if ((this.selectedType == '0') || (this.selectedType == '1') || (this.selectedType == '2') || (this.selectedType == '3')) {

      let json: any;

      if (this.selectedType == '0') {

        this.addLabelErrorMsg = '';

        var validation = this.selectedMinChar + "," + this.selectedMaxChar;

        if ((this.selectedMinChar.length <= 0) || (this.selectedMaxChar.length <= 0)) {
          if ((this.selectedMinChar.length <= 0) && (this.selectedMaxChar.length <= 0)) {
            this.addLabelErrorMsg = "Enter minimum and maximum character length";
            this.addLabelError = true;
          } else
            if ((this.selectedMinChar.length <= 0)) {
              this.addLabelErrorMsg = "Enter minimum character length";
              this.addLabelError = true;

            } else if ((this.selectedMaxChar.length <= 0)) {
              this.addLabelErrorMsg = "Enter maximum character length";
              this.addLabelError = true;
            }
        } else if (parseInt(this.selectedMinChar) > parseInt(this.selectedMaxChar)) {
          this.addLabelErrorMsg = "Enter maximum character larger than minimum character";
          this.addLabelError = true;
        }


        json = { 'caller_email': localStorage.getItem('email'),'id_alumni_info_label_group': this.selectedIdLabelGroup, 'name': this.selectedLabelName, 'type': 'text', 'validation': validation }

      }
      if (this.selectedType == '1') {
        this.addLabelErrorMsg = '';
        var validation = this.selectedMinNumber + "," + this.selectedMaxNumber;
        var min = parseInt(this.selectedMinNumber);
        var max = parseInt(this.selectedMaxNumber);

        // console.log("Min=" + min);
        // console.log("Max=" + max);

        if ((this.selectedMinNumber.length <= 0) || (this.selectedMaxNumber.length <= 0)) {
          if ((this.selectedMinNumber.length <= 0) && (this.selectedMaxNumber.length <= 0)) {
            this.addLabelErrorMsg = "Enter minimum and maximum number";
            this.addLabelError = true;
          } else
            if ((this.selectedMinNumber.length <= 0)) {
              this.addLabelErrorMsg = "Enter minimum number";
              this.addLabelError = true;

            } else if ((this.selectedMaxNumber.length <= 0)) {
              this.addLabelErrorMsg = "Enter maximum number";
              this.addLabelError = true;
            }


        } else if (min > max) {
          this.addLabelErrorMsg = "Enter maximum number larger than minimum number";
          this.addLabelError = true;
        }

        json = { 'caller_email':  localStorage.getItem('email'),'id_alumni_info_label_group': this.selectedIdLabelGroup, 'name': this.selectedLabelName, 'type': 'number',  'validation': validation }

      }
      if (this.selectedType == '2') {

        this.addLabelErrorMsg = '';

        var validation = '';
        var valarray = this.selectedVal1.split(',');
        // if (this.selectedVal1.length > 0) {
        //   valarray.push(this.selectedVal1);
        // }
        // if (this.selectedVal2.length > 0) {
        //   valarray.push(this.selectedVal2);
        // }
        // if (this.selectedVal3.length > 0) {
        //   valarray.push(this.selectedVal3);
        // }
        // if (this.selectedVal4.length > 0) {
        //   valarray.push(this.selectedVal4);
        // }
        // if (this.selectedVal5.length > 0) {
        //   valarray.push(this.selectedVal5);
        // }
        // if (this.selectedVal6.length > 0) {
        //   valarray.push(this.selectedVal6);
        // }

        // for (var i = 0; i < valarray.length; i++) {
        //   if (i != (valarray.length - 1)) {
        //     validation = validation + valarray[i] + ",";
        //   } else {
        //     validation = validation + valarray[i];
        //   }
        // }


        if (valarray.length < 2) {
          this.addLabelErrorMsg = "Enter atleast 2 option values";
          this.addLabelError = true;
        } else {
          this.addLabelErrorMsg = "";
          this.addLabelError = false;
        }


         validation = this.selectedVal1;
        //var validation = this.selectedMinChar + "," + this.selectedMaxChar;
        json = { 'caller_email':localStorage.getItem('email'),'id_alumni_info_label_group': this.selectedIdLabelGroup, 'name': this.selectedLabelName, 'type': 'text_option','validation': validation }

      }
      if (this.selectedType == '3') {

        this.addLabelErrorMsg = '';
        var validation = this.selectedFromDate + "," + this.selectedToDate;

        let fromDate = new Date(this.selectedFromDate);
        let toDate = new Date(this.selectedToDate);

        if ((this.selectedFromDate.length <= 0) || (this.selectedToDate.length <= 0)) {
          if ((this.selectedFromDate.length <= 0) && (this.selectedToDate.length <= 0)) {
            this.addLabelErrorMsg = "Select from and to date";
            this.addLabelError = true;
          } else
            if ((this.selectedFromDate.length <= 0)) {
              this.addLabelErrorMsg = "Select from date";
              this.addLabelError = true;

            } else if ((this.selectedToDate.length <= 0)) {
              this.addLabelErrorMsg = "Select to date";
              this.addLabelError = true;
            }
        } else if (fromDate > toDate) {
          this.addLabelErrorMsg = "Select to date greater than from date";
          this.addLabelError = true;
        }

        json = { 'caller_email': localStorage.getItem('email'),'id_alumni_info_label_group': this.selectedIdLabelGroup,'name': this.selectedLabelName, 'type': 'date', 'validation': validation }

      }

      console.log("addHeaderErrorMsg=" + this.addLabelErrorMsg);

      if (!this.addLabelError) {        
          this.showLoader = true;
          this.labelService.addNewLabel(json).subscribe(
            (data: any) => {
              this.showLoader = false;
              if (data.status_code === 0) {
                this.addLabel = false;
                // this.templateHeaderList = data.template_headers;

                // clear data
                swal.fire(data['status_message']);
                this.getAllLabels();

              }
              else {
                swal.fire(data.status_message)
              }
            },
            (err) => {
              this.showLoader = false;
            }
          );
        
      }


    }else{
      this.addLabelError = true;
      console.log("In else");
    }

  }


  changeStatus(data){
    var statusId: any
    if (data.id_status == "0") {
        statusId = "1"
    }
    else {
        statusId = "0"
    }
   
        swal.fire({
            title: '',
            text:(statusId=='0')?"Are you sure you want to activate this label?": "Are you sure you want to deactivate this label?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#428e63',
            confirmButtonText:(statusId=='1')? "Yes, deactivate it!":"Yes, activate it!"
        }).then((result) => {
            if (result.value) {

                this.showLoader = true;
                this.labelService.changeStatus(data.id_alumni_info_label, localStorage.getItem("email"), statusId).subscribe(
                    data => {

                        this.showLoader = false;
                       
                        if (data.status_code == 0) {
                            this.getAllLabels();
                            swal.fire( data['status_message']);
                        }
                        else {
                            swal.fire( data['status_message']);
                        }
                    },
                    err => {
                        this.showLoader = false;
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }
           
        })
    
  }


  upLabelSequenceNo(labels,index){
     let firstLableId=labels[index]['id_alumni_info_label'] ;
     let secondLableId=labels[index-1]['id_alumni_info_label'] ;

     let firstLabelSequenceNo = labels[index]['sequence_no'] ;
     let secondLabelSequenceNo = labels[index-1]['sequence_no'] ;
    
     this.showLoader = true;

     var json = {
       'caller_email':localStorage.getItem('email'),
       'id_alumni_info_label_first':firstLableId,
       'id_alumni_info_label_second':secondLableId,
       'sequence_no_first':firstLabelSequenceNo,
       'sequence_no_second':secondLabelSequenceNo
     }

     this.labelService.swapSequenceNo(json).subscribe(
         data => {

             this.showLoader = false;
            
             if (data['status_code'] == 0) {
                 this.getAllLabels();
                //  swal.fire( data['status_message']);
             }
             else {
                //  swal.fire( data['status_message']);
             }
         },
         err => {
             this.showLoader = false;
             swal.fire("Something went wrong. Please try again later.")
         }
     )

      

  }

  downLabelSequenceNo(labels,index){
    let firstLableId=labels[index]['id_alumni_info_label'] ;
    let secondLableId=labels[index+1]['id_alumni_info_label'] ;

    let firstLabelSequenceNo = labels[index]['sequence_no'] ;
    let secondLabelSequenceNo = labels[index+1]['sequence_no'] ;
   
    this.showLoader = true;

    var json = {
      'caller_email':localStorage.getItem('email'),
      'id_alumni_info_label_first':firstLableId,
      'id_alumni_info_label_second':secondLableId,
      'sequence_no_first':firstLabelSequenceNo,
      'sequence_no_second':secondLabelSequenceNo
    }

    this.labelService.swapSequenceNo(json).subscribe(
        data => {

            this.showLoader = false;
           
            if (data['status_code'] == 0) {
              this.getAllLabels();
                swal.fire( data['status_message']);
            }
            else {
                swal.fire( data['status_message']);
            }
        },
        err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
        }
    )
    

 }

 changePublicStatus(data){
  var statusId: any
  if (data.is_public == "0") {
      statusId = "1"
  }
  else {
      statusId = "0"
  }
 
  console.log("In changePublicStatus");
      swal.fire({
          title: '',
          text:(statusId=='0')?"Are you sure you want to make this label public?": "Are you sure you want to make this label private?",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#8B0000',
          cancelButtonColor: '#428e63',
          confirmButtonText:(statusId=='1')? "Yes, do it!":"Yes, do it!"
      }).then((result) => {
          if (result.value) {

              this.showLoader = true;
              this.labelService.changePublicStatus(data.id_alumni_info_label, localStorage.getItem("email"), statusId).subscribe(
                  data => {

                      this.showLoader = false;
                     
                      if (data.status_code == 0) {
                          this.getAllLabels();
                          swal.fire( data['status_message']);
                      }
                      else {
                          swal.fire( data['status_message']);
                      }
                  },
                  err => {
                      this.showLoader = false;
                      swal.fire("Something went wrong. Please try again later.")
                  }
              )
          }
         
      })
  
}



}
