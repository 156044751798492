import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { QuizService } from "./quiz.service";
import swal from "sweetalert2";
import { Helper } from "../helper";
import { CSVExport } from "../Csv-export";
@Component({
  selector: "quiz",
  templateUrl: "quiz.component.html",
  styleUrls: ["quiz.component.scss"],
  providers: [QuizService],
})
export class QuizComponent {
  form: FormGroup;
  showLoader = false;
  showAlert = false;
  alertMsg: any;
  alertType: any;
  isModalShow = false;
  faculty = [];
  SubTopics = [];
  Topics = [];
  SubSubjects = [];
  Subjects = [];
  tableArray = [];
  UnFiltterdSubSubjects = [];
  UnfiltterdTopics = [];
  UnfilttersSubTopics = [];
  questionMaster = [];
  quizQuestions = [];
  quizQuestionSearch: any;
  questionSearch: any;
  quizes = [];
  unfilterdQuizes = [];
  filter = {
    id_course_type: "0",
    id_subject: "0",
    id_sub_subject: "0",
    id_topic: "0",
    id_faculty: "0",
  };
  editData = { id_quiz: "" };
  column: string = "CategoryName";
  direction: number;
  isDesc: boolean = false;
  isPreviewModalShow = false;
  eachQuestionMark;
  roleId: any;
  userId: any;
  callFor: any;
  searchText = "";
  isViewResultModal=false;
  viewResultFor=0;
  quizResultData:any;
  slectedQuizForResult:any;
  isDateModal=false;
  fromDate='';
  toDate='';
  downloadCSVtype:any;
  downloadQuiz:any;
  constructor(private quizService: QuizService) {
    this.roleId = localStorage.getItem("id_role");
    this.userId = localStorage.getItem("id_user");
    if (this.roleId == 4) {
      this.filter.id_faculty = this.userId;
    }

    this.eachQuestionMark = Helper.QUIZ_MARKS;
    this.getMasterDataForQuiz();
    this.getAllQuiz();
  }

  ngOnInit() {
    this.buildForm();
  }

  getMasterDataForQuiz() {
    this.showLoader = true;
    this.quizService
      .getMasterDataForQuiz(localStorage.getItem("email"))
      .subscribe(
        (data) => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.Subjects = data.subjects;
            this.UnFiltterdSubSubjects = data.sub_subjects;
            this.UnfiltterdTopics = data.topics;
            this.UnfilttersSubTopics = data.sub_topics;
            this.faculty = data.faculty;
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          } else {
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          }
        },
        (err) => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.");
        }
      );
  }

  getAllQuiz() {
    this.showLoader = true;
    this.quizes = [];
    this.unfilterdQuizes = [];
    this.quizService
      .getAllQuiz(localStorage.getItem("email"), this.roleId)
      .subscribe(
        (data) => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.quizes = data.quizs;
            this.unfilterdQuizes = data.quizs;

            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          } else {
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          }
        },
        (err) => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.");
        }
      );
  }

  getQuestionForQuiz(topicId, call?) {
    if (!this.editData.id_quiz) {
      this.quizQuestions = [];
    }
    if (call === "html") {
      this.quizQuestions = [];
    }
    this.questionMaster = [];

    this.showLoader = true;
    this.quizService
      .getQuestionForQuiz(localStorage.getItem("email"), topicId)
      .subscribe(
        (data) => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.questionMaster = data.questions;
            //this.questionMaster.length);
            if (this.editData.id_quiz) {
              this.removeQuestionFromQuestionMaster();
            }
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          } else {
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          }
        },
        (err) => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.");
        }
      );
  }
  removeQuestionFromQuestionMaster() {
    var that = this;
    this.questionMaster = this.questionMaster.filter(function (objFromA) {
      return !that.quizQuestions.find(function (objFromB) {
        return objFromA.id_question === objFromB.id_question;
      });
    });
    //this.questionMaster.length);
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    } else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }

  buildForm() {
    this.form = new FormGroup({
      quiz_name: new FormControl("", [Validators.required]),
      id_course_type: new FormControl("", [Validators.required]),
      ideal_time: new FormControl("", [Validators.required]),
      passing_mark: new FormControl("", [Validators.required]),
      id_subject: new FormControl("", [Validators.required]),
      id_sub_subject: new FormControl("", [Validators.required]),
      id_topic: new FormControl("", [Validators.required]),
      id_faculty: new FormControl("", [Validators.required]),
      description: new FormControl("", [Validators.required]),
    });

    if (this.roleId == 4) {
      this.form.patchValue({
        id_faculty: this.userId,
      });
      this.form.get("id_faculty").disable();

      // this.form.controls['id_faculty'].reset({ value:this.userId, disabled: true });
    }
    console.log(this.form.value);
  }

  filterSuSuject(call?: any) {
    this.SubSubjects = [];
    this.filter.id_sub_subject = "0";
    this.filter.id_topic = "0";
    this.filter.id_faculty = "0";
    for (let data of this.UnFiltterdSubSubjects) {
      if (call == "quiz-filter") {
        if (this.filter.id_subject == data.id_subject) {
          this.SubSubjects.push(data);
        }
      } else {
        if (this.form.value.id_subject == data.id_subject) {
          this.SubSubjects.push(data);
        }
      }
    }
    if (call == "quiz-filter") {
      this.filteredQuiz();
    }
    this.questionMaster = [];
    this.quizQuestions = [];
    this.form.patchValue({ id_topic: "", id_sub_subject: "" });
    this.Topics = [];
  }

  filterTopics(call?: any) {
    this.Topics = [];
    this.filter.id_topic = "0";
    this.filter.id_faculty = "0";
    for (let data of this.UnfiltterdTopics) {
      if (call == "quiz-filter") {
        if (this.filter.id_sub_subject == data.id_sub_subject) {
          this.Topics.push(data);
        }
      } else {
        if (this.form.value.id_sub_subject == data.id_sub_subject) {
          this.Topics.push(data);
        }
      }
    }
    if (call == "quiz-filter") {
      this.filteredQuiz();
    }
    this.questionMaster = [];
    this.quizQuestions = [];
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  openModal() {
    this.isModalShow = true;
  }

  closeModal() {
    this.editData.id_quiz = "";
    this.questionMaster = [];
    this.quizQuestions = [];
    this.buildForm();
    this.isModalShow = false;
  }

  save() {
    if (this.quizQuestions.length == 0) {
      swal.fire("Please add at least one question in quiz.");
    }
    let json = this.form.getRawValue();
    json["caller_email"] = localStorage.getItem("email");
    let question = [];
    for (let qu of this.quizQuestions) {
      question.push(qu.id_question);
    }
    json["quiz_question"] = question;
    json["total_marks"] = question.length * Helper.QUIZ_MARKS;
    this.showLoader = true;
    if (this.editData.id_quiz) {
      json.id_quiz = this.editData.id_quiz;
      this.editQuiz(json);
    } else {
      this.addQuiz(json);
    }
  }
  addQuiz(json) {
    this.quizService.addNewQuiz(json).subscribe(
      (data) => {
        this.showLoader = false;
        if (data.status_code == 0) {
          this.getAllQuiz();
          this.closePreviewModal();
          this.closeModal();
          swal.fire(data.status_message);
        } else {
          swal.fire(data.status_message);
        }
      },
      (err) => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.");
      }
    );
  }

  editQuiz(json) {
    this.quizService.editQuiz(json).subscribe(
      (data) => {
        this.showLoader = false;
        if (data.status_code == 0) {
          this.getAllQuiz();
          this.closePreviewModal();
          this.closeModal();
          swal.fire(data.status_message);
        } else {
          swal.fire(data.status_message);
        }
      },
      (err) => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.");
      }
    );
  }

  addQuestionInQuiz(question, index) {
    this.questionMaster.splice(index, 1);
    this.quizQuestions.unshift(question);
  }

  removeQuestionInQuiz(question, index) {
    this.quizQuestions.splice(index, 1);
    this.questionMaster.unshift(question);
  }

  filteredQuiz() {
    this.quizes = this.unfilterdQuizes;
    if (this.filter.id_course_type != "0") {
      var temporaryArray = [];
      for (let data of this.quizes) {
        if (data.id_course_type == this.filter.id_course_type) {
          temporaryArray.push(data);
        }
      }
      this.quizes = temporaryArray;
      //(this.Questions)
    }
    if (this.filter.id_subject != "0") {
      var temporaryArray = [];
      for (let data of this.quizes) {
        if (data.id_subject == this.filter.id_subject) {
          temporaryArray.push(data);
        }
      }
      this.quizes = temporaryArray;
      //(this.Questions)
    }
    if (this.filter.id_sub_subject != "0") {
      var temporaryArray = [];
      for (let data of this.quizes) {
        if (data.id_sub_subject == this.filter.id_sub_subject) {
          temporaryArray.push(data);
        }
      }
      this.quizes = temporaryArray;
      //(this.Questions)
    }
    if (this.filter.id_topic != "0") {
      var temporaryArray = [];
      for (let data of this.quizes) {
        if (data.id_topic == this.filter.id_topic) {
          temporaryArray.push(data);
        }
      }
      this.quizes = temporaryArray;
      //(this.Questions)
    }
    if (this.filter.id_faculty != "0") {
      var temporaryArray = [];
      for (let data of this.quizes) {
        if (data.id_faculty == this.filter.id_faculty) {
          temporaryArray.push(data);
        }
      }
      this.quizes = temporaryArray;
      //(this.Questions)
    }
  }

  editForm(data, callFor?) {
    this.callFor = callFor;
    this.editData.id_quiz = data.id_quiz;
    this.form.patchValue({
      id_course_type: data.id_course_type,
      quiz_name: data.quiz_name,
      id_faculty: data.id_faculty,
      description: data.description,
      id_subject: data.id_subject,
      passing_mark: data.passing_mark,
      ideal_time: data.ideal_time,
    });
    this.filterSuSuject();
    this.form.patchValue({
      id_sub_subject: data.id_sub_subject,
      id_topic: data.id_topic,
    });
    this.filterTopics();
    this.quizQuestions = data.questions;
    this.getQuestionForQuiz(data.id_topic);
    this.openModal();
    if (callFor === "preveiew") {
      this.previewQuiz();
    }
  }

  removeQuiz(data) {
    swal
      .fire({
        title: "",
        text:
          "Deleting an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to remove this Quiz?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#8B0000",
        cancelButtonColor: "#428e63",
        confirmButtonText: "Yes, remove it!",
      })
      .then((result) => {
        if (result.value) {
          this.showLoader = true;
          this.quizService
            .removeQuiz(localStorage.getItem("email"), data.id_quiz)
            .subscribe(
              (data) => {
                this.showLoader = false;
                if (data.status_code == 0) {
                  this.getAllQuiz();
                  this.showAlertComponent(
                    data["status_code"],
                    data["status_message"]
                  );
                } else {
                  this.showAlertComponent(
                    data["status_code"],
                    data["status_message"]
                  );
                }
              },
              (err) => {
                this.showLoader = false;

                swal.fire("Something went wrong. Please try again later.");
              }
            );
        }
      });
  }


  removeQuestionFromQuiz(data){
    this.showLoader = true;
    this.quizService
      .removeQuestionFromQuiz(localStorage.getItem("email"), this.editData.id_quiz,data.id_question)
      .subscribe(
        (data) => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.getQuizData();
         
          } 
        },
        (err) => {
          this.showLoader = false;

          swal.fire("Something went wrong. Please try again later.");
        }
      );
  }

  getQuizData(){
    this.showLoader = true;
    this.quizService
      .getQuizData(localStorage.getItem("email"), this.editData.id_quiz)
      .subscribe(
        (data) => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.quizQuestions=data.quizs[0]["questions"];
         
          } 
        },
        (err) => {
          this.showLoader = false;

          swal.fire("Something went wrong. Please try again later.");
        }
      );
  }
  changeStatus(data) {
    var changeStatusId: any;
    if (data.id_status == 0) {
      changeStatusId = 1;
    } else {
      changeStatusId = 0;
    }
    if (changeStatusId == 1) {
      swal
        .fire({
          title: "",
          text:
            "Deactivating an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to deactivate this Quiz?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#8B0000",
          cancelButtonColor: "#428e63",
          confirmButtonText: "Yes, deactivate it!",
        })
        .then((result) => {
          if (result.value) {
            this.showLoader = true;
            this.quizService
              .changeStatus(
                localStorage.getItem("email"),
                data.id_quiz,
                changeStatusId
              )
              .subscribe(
                (data) => {
                  this.showLoader = false;
                  if (data.status_code == 0) {
                    this.showAlertComponent(
                      data["status_code"],
                      data["status_message"]
                    );
                  } else {
                    this.showAlertComponent(
                      data["status_code"],
                      data["status_message"]
                    );
                  }
                },
                (err) => {
                  swal.fire("Something went wrong. Please try again later.");
                }
              );
          } else {
            if (changeStatusId == 0) {
              (<HTMLInputElement>(
                document.getElementById(data.id_quiz)
              )).checked = false;
            } else {
              (<HTMLInputElement>(
                document.getElementById(data.id_quiz)
              )).checked = true;
            }
          }
        });
    } else {
      this.showLoader = true;
      this.quizService
        .changeStatus(
          localStorage.getItem("email"),
          data.id_quiz,
          changeStatusId
        )
        .subscribe(
          (data) => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.getAllQuiz();
              this.showAlertComponent(
                data["status_code"],
                data["status_message"]
              );
            } else {
              this.showAlertComponent(
                data["status_code"],
                data["status_message"]
              );
            }
          },
          (err) => {
            this.showLoader = false;

            swal.fire("Something went wrong. Please try again later.");
          }
        );
    }

    //.log(data.id_status)
  }

  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.quizes.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      } else if (a[property] > b[property]) {
        return 1 * direction;
      } else {
        return 0;
      }
    });
  }
  closePreviewModal() {
    this.isPreviewModalShow = false;
    if (this.callFor === "preveiew") {
      this.closeModal();
    }
  }
  previewQuiz() {
    // if(this.getQuizTotalMarks()<this.form.value.passing_mark){
    //   swal.fire("Passing marks can not be greator than total quiz marks.")
    //   return false;
    // }
    this.isPreviewModalShow = true;
  }

  getQuestionTextForMdal(data) {
    //data)
    let x = data.split("$$");
    return "Assertion: " + x[0] + " Reason: " + x[1];
  }
  getQuestionAnswer(data) {
    let answerString = "";
    if (
      data.id_question_pattern == "1" ||
      data.id_question_pattern == "2" ||
      data.id_question_pattern == "6"
    ) {
      for (let option of data.question_optiones) {
        if (option.is_option_correct == "0") {
          if (answerString == "") {
            answerString = option.option_name;
          } else {
            answerString = answerString + "," + option.option_name;
          }
        }
      }
    } else if (data.id_question_pattern == "4") {
      answerString = data.answer;
    } else if (data.id_question_pattern == "5") {
      //.log(data.matrix_match)
      for (let match of data.matrix_match) {
        if (answerString == "") {
          answerString = match.option + "-->" + match.answers;
        } else {
          answerString =
            answerString + "," + match.option + "-->" + match.answers;
        }
      }
    }
    return answerString;
  }

  courseType = [
    { id: 1, name: "JEE" },
    { id: 2, name: "NEET" },
  ];
  getName(id, type) {
    if (type == "ct") {
      return this.courseType.find((x) => x.id == id).name;
    } else if (type == "s") {
      return this.Subjects.find((x) => x.id_subject == id).name;
    } else if (type == "ss") {
      return this.SubSubjects.find((x) => x.id_sub_subject == id).name;
    } else if (type == "t") {
      return this.Topics.find((x) => x.id_topic == id).name;
    }
  }
  getQuizTotalMarks() {
    return this.quizQuestions.length * Helper.QUIZ_MARKS;
  }

  downloadCSV(){
    if(!this.fromDate || !this.toDate){
      swal.fire("Please select from and to date.");
      return false;
    }
    let type=this.downloadCSVtype;
    let quiz=this.downloadQuiz;
    this.showLoader = true;
    let arrayToExport=[];
    this.quizService
      .getScholarQuizResultForCSV(localStorage.getItem("email"), type,quiz.id_quiz,this.fromDate,this.toDate)
      .subscribe(
        (data) => {
          this.showLoader = false;
          if (data.status_code == 0) {
            for (let scholar of data.quiz_results) {
              let json={};
              if(type===0){
                json = {
                  "Dakshana Roll Number": scholar.dakshana_roll_number,
                  "Subject": quiz.subject_name,
                  "Sub-subject": quiz.sub_subject_name,
                  "Topic": quiz.topic_name,
                  "Faculty": quiz.faculty_name,
                  "Quiz Appeared Date": scholar.quiz_appeared_date,
                  "Student Name": scholar.scholar_name,
                  "Batch": scholar.batch_name,
                  "CoE": scholar.coe_name,
                  "Obtained Marks": scholar.obtained_marks,
                  "Wrong Marks": scholar.wrong_marks,
                  "Total Marks": scholar.total_marks,
                  "Status": scholar.is_cleard===0?'Failed':scholar.is_cleard===1?'Pass':'Non-attempted',

                
                };
              }
              else{
                json = {
                  "Roll Number": "6"+scholar.scholar_mobile,
                  "Subject": quiz.subject_name,
                  "Sub-subject": quiz.sub_subject_name,
                  "Topic": quiz.topic_name,
                  "Faculty": quiz.faculty_name,
                  "Quiz Appeared Date": scholar.quiz_appeared_date,
                  "Student Name": scholar.scholar_name,
                  "Obtained Marks": scholar.obtained_marks,
                  "Wrong Marks": scholar.wrong_marks,
                  "Total Marks": scholar.total_marks,
                  "Status": scholar.is_cleard===0?'Failed':scholar.is_cleard===1?'Pass':'Non-attempted',
                
                };
              }
              arrayToExport.push(json);
            }
            console.log(arrayToExport)
            CSVExport.downloadcsv(arrayToExport, quiz.quiz_name+"_"+new Date());
            this.closeDateModal();
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          } 
        },
        (err) => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.");
        }
      );
  }

  viewResult(type,quiz){
    this.slectedQuizForResult=quiz;
    this.viewResultFor=type;
    this.quizResultData={};
    if(type==0){
      this.showLoader = true;
      this.quizService
        .getScholarQuizResultForCSV(localStorage.getItem("email"), type,quiz.id_quiz)
        .subscribe(
          (data) => {
            this.showLoader = false;
            if (data.status_code == 0) {
                this.isViewResultModal=true;
                this.quizResultData=data.quiz_results;
                let coeArray=[];
                for(let tempOne of this.quizResultData){
                  if(coeArray.findIndex(x=>x.id_coe==tempOne.id_coe)==-1){
                    coeArray.push({'id_coe':tempOne.id_coe,'coe_name':tempOne.coe_name,'total_scholar':0,'total_non_attempted_scholar':0,'total_attempted_scholar':0,'total_pass_scholar':0,'total_failed_scholar':0})
                  }
                }
                for(let coe of coeArray){
                  for(let tempOne of this.quizResultData){
                    if(coe.id_coe===tempOne.id_coe){
                      coe['total_scholar']=coe['total_scholar']+1;
                      if(tempOne.quiz_appeared_date==null){
                        coe['total_non_attempted_scholar']=coe['total_non_attempted_scholar']+1;
                      }
                      else{
                        coe['total_attempted_scholar']=coe['total_attempted_scholar']+1;
                        if(tempOne.is_cleard==="1"){
                          coe['total_pass_scholar']=coe['total_pass_scholar']+1;
                        }
                        else{
                          coe['total_failed_scholar']=coe['total_failed_scholar']+1;
                        }
                      }
                    }
                  }
                }
                this.quizResultData=coeArray;
                console.log(coeArray)
            } 
          },
          (err) => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.");
          }
        );
    }
    else{
      this.showLoader = true;
      this.quizService
        .getScholarQuizResultForCSV(localStorage.getItem("email"), type,quiz.id_quiz)
        .subscribe(
          (data) => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.isViewResultModal=true;
                this.quizResultData=data.quiz_results;
            } 
          },
          (err) => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.");
          }
        );
    }

  }

  closeViewResultModal(){
    this.isViewResultModal=false;
  }

  getPercentage(total,getTotal){
    if(total==0){
      return 0;
    }
    else{
      return ((getTotal/total)*100).toFixed(2)
    }
    
  }

  openDateModal(type,data){
    this.isDateModal=true;
    this.downloadCSVtype=type;
    this.downloadQuiz=data;
  }
  closeDateModal(){
    this.isDateModal=false;
    this.toDate='';
    this.fromDate='';
  }
}
