
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
    
    @Input() message: String;
    @Input() type: String;
    @Output() isHidden = new EventEmitter<boolean>();
    constructor() {
        //.log("under alert constructor");
    }

    ngOnInit() {
        if (this.type == "success") {
            this.hideAlert();
        }
        else {
            this.closeAlert();
        }
    }

    hideAlert() {
        var that = this;
        setTimeout(function () {
            that.isHidden.emit(false);
        }, 1000);
    }
    closeAlert() {
        var that = this;
        setTimeout(function () {
            that.isHidden.emit(false);
        }, 2000);
        // this.isHidden.emit(false);
    }

}
