import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class ManagementDashBoardService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }

    getActiveCoe(email) {
        return this.http.get(this.baseUrl + "/Coe/getAllActiveCoe?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }
    getDashboardGraphData(email) {
        return this.http.get(this.baseUrl + "/Reports/getDashboardGraphDataForManagement?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getManagementSubjectGraph(email, coeId) {
        return this.http.get(this.baseUrl + "/Reports/getManagementSubjectGraph?caller_email=" + email + "&id_coe=" + coeId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getManagementLectureGraph(email, coeId,coeName) {
        return this.http.get(this.baseUrl + "/Reports/getLectureGraphForMangement?caller_email=" + email + "&id_coe=" + coeId+ "&name=" + coeName).map((res) => JSON.parse(JSON.stringify(res)));
    }
}