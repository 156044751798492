import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TopicMasterService } from './topic-master.service'
import swal from 'sweetalert2'
import { identifierModuleUrl } from '@angular/compiler';
@Component({
    moduleId: module.id,
    selector: 'topic-master',
    templateUrl: 'topic-master.component.html',
    styleUrls: ['topic-master.component.scss'],
    providers: [TopicMasterService]
})
export class TopicMasterComponent {
    subjectModelHeaderlLabel: any;
    isSubjectModal: boolean;
    subjectName: any;
    isJee: any;
    isNeet: any;
    selectedCourse: any
    Subjects: any
    showLoader: boolean
    statusMessage: any;
    isSubjectPurpose: any
    subjectId: any;
    isSubSubjectModal: any;
    subSubjectModelHeaderlLabel: any;
    topicModelHeaderlLabel: any;
    subTopicModelHeaderlLabel
    subSubjectName: any;
    SubSubjects: any;
    subSubjectId: any;
    isTopicModal: boolean;
    topicName: any;
    topicId: any;
    unfilteredSubSubjects: any
    Topics: any
    unfilteredTopics: any;
    serachSubjectText: any;
    serachSubSubjectText: any;
    serachTopicText: any;
    alertDiv = false;
    sucessAlertMessage: any;
    showAlert: boolean;
    alertMsg: any;
    alertType = "";
    SubSubjectsDropdown = []
    column: string = 'CategoryName';
    directionSubject: number;
    isDescSubject: boolean = false;
    directionSubSubject: number;
    isDescSubSubject: boolean = false;
    directionTopic: number;
    isDescTopic: boolean = false;
    isSubTopicModal = false;
    topicDropdown = [];
    subTopicName: any;
    SubTopics = [];
    unfilteredSubTopic = [];
    subTopicId: any;
    isTopicWeightageModal = false
    topicWeightageArray = [];
    isSubSubjectWeightageModal = false;
    subSubjectWeightageArray = [];
    subSubjectWeightage: any;
    constructor(private router: Router, private topicMasterService: TopicMasterService) {

        this.subjectModelHeaderlLabel = "Add Subject"
        this.subSubjectModelHeaderlLabel = "Add Sub-subject"
        this.topicModelHeaderlLabel = "Add Topic"
        this.subjectModelHeaderlLabel = "Add Sub-topic"
        this.isSubjectModal = false
        this.isSubSubjectModal = false
        this.isTopicModal = false
        this.showLoader = false
        this.Subjects = []
        this.SubSubjects = []
        this.unfilteredSubSubjects = []
        this.unfilteredTopics = []
        this.Topics = []
        this.subjectId = "0"
        this.subSubjectId = "0"
        this.topicId = "0"
        this.isSubjectPurpose = "Add"
        this.statusMessage = "No records found"
        if (localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "1") {
            this.getAllSubjects();

        }
        else {
            this.router.navigateByUrl('/');
        }
    }

    getAllSubjects() {
        this.Subjects = [];
        this.SubSubjects = [];
        this.Topics = [];
        this.SubTopics = []
        this.showLoader = true;
        this.topicMasterService.getAllSubject(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {
                    this.Subjects = data["subjects"];
                    this.getAllSubSubjects();
                }
                else {
                    this.showAlertDiv(data.status_message)
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }

    getAllSubSubjects() {
        this.showLoader = true;
        this.topicMasterService.getAllSubSubject(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {
                    this.SubSubjects = data["sub_sujects"];
                    this.unfilteredSubSubjects = data["sub_sujects"];
                    this.getAllTopics();
                }
                else {
                    this.showAlertDiv(data.status_message)

                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }
    getAllTopics() {
        this.showLoader = true;
        this.topicMasterService.getAllTopic(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {
                    this.Topics = data["topics"];
                    this.unfilteredTopics = data["topics"];
                    if (this.subjectId != "0" && this.subSubjectId != "0") {
                        this.filtersubSubjectBySubjectId(this.subjectId)
                        //.log("subSubjectId--->" + this.subSubjectId)
                        this.filterTopicsBySubSubject(this.subSubjectId)
                    }
                    this.getAllSubTopic();
                }
                else {
                    this.showAlertDiv(data.status_message)
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }

    getAllSubTopic() {
        this.showLoader = true;
        this.topicMasterService.getAllSubTopic(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {
                    this.SubTopics = data["sub_topics"];
                    this.unfilteredSubTopic = data["sub_topics"];
                }
                else {
                    this.showAlertDiv(data.status_message)
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }
    addSubject() {
        this.isSubjectPurpose = "Add"
        this.isSubjectModal = true;
        this.subjectModelHeaderlLabel = "Add subject"
        this.subjectName = ""
        this.isJee = false;
        this.isNeet = false;
        //  this.subjectId = "0"
        // this.subSubjectId = "0"
    }

    hideModal() {
        this.isSubSubjectWeightageModal = false
        this.isSubTopicModal = false;
        this.isTopicWeightageModal = false;
        this.isSubjectPurpose = "Add"
        this.isSubjectModal = false;
        //  this.subjectId = "0"
        // this.subSubjectId = "0"
        this.isSubSubjectModal = false;
        this.isTopicModal = false
        this.topicModelHeaderlLabel = "Add Topic"
        this.subTopicModelHeaderlLabel = "Add Sub-topic"
    }

    saveSubject() {
        if (!this.subjectName) {
            swal.fire("Please enter subject name.")
        }
        else if ((!this.isNeet && !this.isJee) || (this.isJee == false && this.isNeet == false)) {
            swal.fire("Please select at least one course type.")
        }
        else {

            if (this.isSubjectPurpose == "Add") {
                var json: any
                if (this.isJee == false) {
                    json = {
                        "caller_email": localStorage.getItem("email"),
                        "name": this.subjectName,
                        "is_neet": "true",
                        "is_jee": "false"
                    }
                }
                else if (this.isNeet == false) {
                    json = {
                        "caller_email": localStorage.getItem("email"),
                        "name": this.subjectName,
                        "is_neet": "false",
                        "is_jee": "true"
                    }
                }
                else {
                    json = {
                        "caller_email": localStorage.getItem("email"),
                        "name": this.subjectName,
                        "is_neet": "true",
                        "is_jee": "true"
                    }
                }
                this.showLoader = true;
                this.topicMasterService.addSubject(json).subscribe(
                    data => {
                        this.showLoader = false;
                        if (data.status_code == 0) {
                            this.Subjects = [];
                            this.Subjects = data["subjects"];
                            this.hideModal()
                        }
                        else {
                            this.showAlertDiv(data.status_message)
                        }
                    },
                    err => {
                        this.hideModal()
                        this.showLoader = false;
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }
            else {
                var json: any
                if (this.isJee == false) {
                    json = {
                        "id_subject": this.subjectId,
                        "caller_email": localStorage.getItem("email"),
                        "name": this.subjectName,
                        "is_neet": "true",
                        "is_jee": "false"
                    }
                }
                else if (this.isNeet == false) {
                    json = {
                        "id_subject": this.subjectId,
                        "caller_email": localStorage.getItem("email"),
                        "name": this.subjectName,
                        "is_neet": "false",
                        "is_jee": "true"
                    }
                }
                else {
                    json = {
                        "id_subject": this.subjectId,
                        "caller_email": localStorage.getItem("email"),
                        "name": this.subjectName,
                        "is_neet": "true",
                        "is_jee": "true"
                    }
                }
                this.showLoader = true;
                this.topicMasterService.editSubject(json).subscribe(
                    data => {
                        this.showLoader = false;
                        if (data.status_code == 0) {
                            this.hideModal()
                            this.Subjects = [];
                            this.Subjects = data["subjects"];
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.hideModal()
                        this.isSubjectModal = false;
                        this.showLoader = false;
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }

        }
    }

    editSubject(data) {
        this.isSubjectPurpose = "Edit"
        this.isSubjectModal = true;
        this.subjectId = data.id_subject
        this.subjectModelHeaderlLabel = "Edit Subject"
        this.subjectName = data.name
        if (data.courses.length == 2) {
            this.isJee = true;
            this.isNeet = true;
        }
        else if (data.courses[0].id_course_type == "1") {
            this.isJee = true;
            this.isNeet = false;

        }
        else if (data.courses[0].id_course_type == "2") {
            this.isJee = false;
            this.isNeet = true;
        }
    }

    addSubSubject() {
        if (this.Subjects.length == 0) {
            swal.fire("First add some subject")
        }
        else {
            this.isSubjectPurpose = "Add"
            this.isSubSubjectModal = true
            this.subSubjectName = "";
            this.subSubjectModelHeaderlLabel = "Add Sub-subject"
        }

    }
    editSubSubject(data) {
        this.isSubjectPurpose = "Edit"
        this.isSubSubjectModal = true;
        this.subjectId = data.id_subject
        this.subSubjectId = data.id_sub_subject
        this.subSubjectModelHeaderlLabel = "Edit Sub-subject"
        this.subSubjectName = data.name
    }
    saveSubSubject() {
        if (this.subjectId == "0" || !this.subjectId) {
            swal.fire("Select subject")
        }
        else if (!this.subSubjectName) {
            swal.fire("Please enter sub-subject name")
        }
        else {
            if (this.isSubjectPurpose == "Add") {
                let json = {
                    "caller_email": localStorage.getItem("email"),
                    "name": this.subSubjectName,
                    "id_subject": this.subjectId
                }
                this.showLoader = true;
                this.topicMasterService.addSubSubject(json).subscribe(
                    data => {
                        this.showLoader = false;
                        if (data.status_code == 0) {
                            this.hideModal()
                            // this.SubSubjects = [];
                            // this.SubSubjects = data["sub_subjects"];
                            // this.unfilteredSubSubjects = data["sub_subjects"];
                            this.getAllSubjects();
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.hideModal()
                        this.showLoader = false;
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }
            else {
                let json = {
                    "id_sub_subject": this.subSubjectId,
                    "caller_email": localStorage.getItem("email"),
                    "name": this.subSubjectName,
                    "id_subject": this.subjectId
                }
                this.showLoader = true;
                this.topicMasterService.editSubSubject(json).subscribe(
                    data => {
                        this.showLoader = false;
                        if (data.status_code == 0) {
                            this.hideModal()
                            this.SubSubjects = [];
                            this.SubSubjects = data["sub_subjects"];
                            this.unfilteredSubSubjects = data["sub_subjects"];
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.hideModal()
                        this.showLoader = false;
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }
        }

    }

    filtersubSubject() {
        //.log(this.subjectId);
        //.log(this.Subjects);
        // this.subSubjectId = "0"
        this.SubSubjectsDropdown = []
        if (this.subjectId == "0") {
            this.SubSubjectsDropdown = this.unfilteredSubSubjects
        }
        else {
            for (let data of this.unfilteredSubSubjects) {
                if (data.id_subject == this.subjectId)
                    this.SubSubjectsDropdown.push(data);
            }
        }
    }

    filterTopic() {

        this.topicDropdown = []
        if (this.subjectId == "0") {
            this.topicDropdown = this.unfilteredTopics
        }
        else {
            for (let data of this.unfilteredTopics) {
                if (data.id_sub_subject == this.subSubjectId)
                    this.topicDropdown.push(data);
            }
        }
    }
    filtersubSubjectBySubjectId(subjectId) {
        this.subjectId = subjectId
        this.SubSubjects = []
        this.Topics = []
        this.SubTopics = []
        if (subjectId == "0") {
            this.SubSubjects = this.unfilteredSubSubjects
        }
        else {
            for (let data of this.unfilteredSubSubjects) {
                if (data.id_subject == subjectId)
                    this.SubSubjects.push(data);
            }
        }
        if (this.SubSubjects.length > 0) {
            this.filterTopicsBySubSubject(this.SubSubjects[0].id_sub_subject)
        }

    }
    filterTopicsBySubSubject(subSubjectId) {
        this.subSubjectId = subSubjectId
        let index = -1;
        index = this.SubSubjects.findIndex(x => x.id_sub_subject == this.subSubjectId)
        if (index != -1) {
            let subSubjectAtIndex = this.SubSubjects[index]
            this.subSubjectWeightage = subSubjectAtIndex['weightage']
        }
        //.log(this.subSubjectWeightage)

        this.Topics = []
        this.SubTopics = []
        if (subSubjectId == "0") {
            this.SubSubjects = this.unfilteredTopics
        }
        else {
            for (let data of this.unfilteredTopics) {
                if (data.id_sub_subject == subSubjectId)
                    this.Topics.push(data);
            }
        }
        if (this.Topics.length > 0) {
            this.filterSubTopicsByTopic(this.Topics[0].id_topic)
        }
    }

    filterSubTopicsByTopic(topicId) {
        this.topicId = topicId;
        this.SubTopics = [];
        if (topicId == "0") {
            this.SubTopics = this.unfilteredSubTopic
        }
        else {
            for (let data of this.unfilteredSubTopic) {
                if (data.id_topic == topicId)
                    this.SubTopics.push(data);
            }
        }
    }
    addTopic() {
        if (this.SubSubjects.length == 0) {
            swal.fire("First add some sub-subject.")
        }
        else {
            this.isSubjectPurpose = "Add"
            this.isTopicModal = true
            //(this.isTopicModal)
            this.topicName = "";
            this.topicModelHeaderlLabel = "Add Topic"
            this.SubSubjectsDropdown = [];
        }
        if (this.subjectId != "0") {
            this.filtersubSubject();
        }

    }
    editTopic(data) {
        this.isSubjectPurpose = "Edit"
        this.isTopicModal = true
        this.subjectId = data.id_subject
        this.filtersubSubject();
        this.subSubjectId = data.id_sub_subject
        this.topicName = data.name
        this.topicId = data.id_topic
        this.topicModelHeaderlLabel = "Edit Topic"

    }
    saveTopic() {
        if (!this.subjectId || this.subjectId == "0") {
            swal.fire("Please select subject.")
        }
        else if (!this.subSubjectId || this.subSubjectId == "0") {
            swal.fire("Please select sub-subject.")
        }
        else if (!this.topicName) {
            swal.fire("Please enter topic name.")
        }
        else {
            if (this.isSubjectPurpose == "Add") {
                let json = {
                    "caller_email": localStorage.getItem("email"),
                    "id_subject": this.subjectId,
                    "id_sub_subject": this.subSubjectId,
                    "name": this.topicName
                }

                this.showLoader = true;
                this.topicMasterService.addTopic(json).subscribe(
                    data => {
                        this.showLoader = false;
                        if (data.status_code == 0) {
                            this.filtersubSubjectBySubjectId(this.subjectId)
                            //.log("subSubjectId--->" + this.subSubjectId)
                            this.filterTopicsBySubSubject(this.subSubjectId)
                            this.hideModal()
                            this.Topics = [];
                            this.Topics = data["topics"];
                            this.unfilteredTopics = data["topics"];
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.hideModal()
                        this.showLoader = false;
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }
            else {
                let json = {
                    "id_topic": this.topicId,
                    "caller_email": localStorage.getItem("email"),
                    "id_subject": this.subjectId,
                    "id_sub_subject": this.subSubjectId,
                    "name": this.topicName
                }

                this.showLoader = true;
                this.topicMasterService.editTopic(json).subscribe(
                    data => {
                        this.showLoader = false;
                        if (data.status_code == 0) {
                            this.Topics = [];
                            this.Topics = data["topics"];
                            this.unfilteredTopics = data["topics"];
                            this.filtersubSubjectBySubjectId(this.subjectId)
                            //.log("subSubjectId--->" + this.subSubjectId)
                            this.filterTopicsBySubSubject(this.subSubjectId)
                            this.hideModal()

                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.hideModal()
                        this.showLoader = false;
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }
        }
    }

    showAlertDiv(message) {
        var that = this
        that.alertDiv = true
        this.sucessAlertMessage = message
        setTimeout(function () {
            that.alertDiv = false
        }, 5000);
    }

    showAlertComponent(status, messege) {
        this.showAlert = true;
        this.alertMsg = messege;
        //("status" + status);
        if (parseInt(status) == 0) {
            this.alertType = "success";
        }
        else {
            this.alertType = "failure";
        }
    }
    hideAlert(val) {
        this.showAlert = val;
    }

    removeSubject(data) {
        swal.fire({
            title: '',
            text: "Are you sure you want to remove this subject?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#428e63',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.value) {
                this.showLoader = true;
                let json = {
                    "caller_email": localStorage.getItem("email"),
                    "id_subject": data.id_subject,
                }
                this.topicMasterService.removeSubject(json).subscribe(
                    data => {
                        this.showLoader = false;
                        if (data.status_code == "0") {
                            this.getAllSubjects();
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.showLoader = false;
                    }
                )
            }
        })
    }

    removeSubSubject(data) {
        swal.fire({
            title: '',
            text: "Are you sure you want to remove this sub-subject?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#428e63',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.value) {
                this.showLoader = true;
                let json = {
                    "caller_email": localStorage.getItem("email"),
                    "id_sub_subject": data.id_sub_subject,
                }
                this.topicMasterService.removeSubSubject(json).subscribe(
                    data => {
                        this.showLoader = false;
                        if (data.status_code == "0") {
                            this.getAllSubjects();
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.showLoader = false;
                    }
                )
            }
        })
    }

    removeTopic(data) {
        swal.fire({
            title: '',
            text: "Please ensure that the total weightage is 100% and try saving again.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#428e63',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.value) {
                this.showLoader = true;
                let json = {
                    "caller_email": localStorage.getItem("email"),
                    "id_topic": data.id_topic,
                }
                this.topicMasterService.removeTopic(json).subscribe(
                    data => {
                        this.showLoader = false;
                        if (data.status_code == "0") {
                            this.getAllSubjects();
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.showLoader = false;
                    }
                )
            }
        })
    }

    sort(property, tableType) {

        this.column = property;


        if (tableType == "1") {
            this.isDescSubject = !this.isDescSubject; //change the direction   
            let directionSubject = this.isDescSubject ? 1 : -1;
            this.Subjects.sort(function (a, b) {
                if (a[property] < b[property]) {
                    return -1 * directionSubject;
                }
                else if (a[property] > b[property]) {
                    return 1 * directionSubject;
                }
                else {
                    return 0;
                }
            });
        }
        else if (tableType == "2") {
            this.isDescSubSubject = !this.isDescSubSubject;
            let directionSubSubject = this.isDescSubSubject ? 1 : -1;
            this.SubSubjects.sort(function (a, b) {
                if (a[property] < b[property]) {
                    return -1 * directionSubSubject;
                }
                else if (a[property] > b[property]) {
                    return 1 * directionSubSubject;
                }
                else {
                    return 0;
                }
            });
        }
        else if (tableType == "3") {
            this.isDescTopic = !this.isDescTopic;
            let directionTopic = this.isDescTopic ? 1 : -1;
            this.Topics.sort(function (a, b) {
                if (a[property] < b[property]) {
                    return -1 * directionTopic;
                }
                else if (a[property] > b[property]) {
                    return 1 * directionTopic;
                }
                else {
                    return 0;
                }
            });
        }


    }
    addSubTopic() {
        this.isSubTopicModal = true
        this.subTopicModelHeaderlLabel = "Add Sub-topic"
        this.isSubjectPurpose = "Add";
        this.subTopicName = "";
        this.subjectId = "0";
        this.subSubjectId = "0";
        this.topicId = "0"
    }
    saveSubTopic() {
        if (this.subjectId == "0") {
            swal.fire("Please select subject.")
        }
        else if (this.subSubjectId == "0") {
            swal.fire("Please select sub-subject.")
        }
        else if (this.topicId == "0") {
            swal.fire("Please select topic.")
        }
        else if (!this.subTopicName) {
            swal.fire("Please enter sub-topic name.")
        }
        else {
            if (this.isSubjectPurpose == "Add") {
                let json = {
                    "caller_email": localStorage.getItem("email"),
                    "id_subject": this.subjectId,
                    "id_sub_subject": this.subSubjectId,
                    "id_topic": this.topicId,
                    "name": this.subTopicName
                }
                this.showLoader = true;
                this.topicMasterService.addNewSubTopic(json).subscribe(
                    data => {
                        this.showLoader = false;
                        if (data.status_code == 0) {
                            this.filtersubSubjectBySubjectId(this.subjectId)
                            //.log("subSubjectId--->" + this.subSubjectId)
                            this.filterTopicsBySubSubject(this.subSubjectId)
                            this.hideModal()
                            this.SubTopics = [];
                            this.SubTopics = data["sub_topics"];
                            this.unfilteredSubTopic = data["sub_topics"];
                            this.filterSubTopicsByTopic(this.topicId)
                            this.showAlertComponent(data['status_code'], data['status_message']);

                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.hideModal()
                        this.showLoader = false;
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }
            else {
                let json = {
                    "caller_email": localStorage.getItem("email"),
                    "id_subject": this.subjectId,
                    "id_sub_subject": this.subSubjectId,
                    "id_topic": this.topicId,
                    "id_sub_topic": this.subTopicId,
                    "name": this.subTopicName
                }
                this.showLoader = true;
                this.topicMasterService.editSubTopic(json).subscribe(
                    data => {
                        this.showLoader = false;
                        if (data.status_code == 0) {
                            this.filtersubSubjectBySubjectId(this.subjectId)
                            //.log("subSubjectId--->" + this.subSubjectId)
                            this.filterTopicsBySubSubject(this.subSubjectId)
                            //.log(this.topicId)

                            this.hideModal()
                            this.SubTopics = [];
                            this.SubTopics = data["sub_topics"];
                            this.unfilteredSubTopic = data["sub_topics"];
                            this.filterSubTopicsByTopic(this.topicId)
                            this.showAlertComponent(data['status_code'], data['status_message']);

                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.hideModal()
                        this.showLoader = false;
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }

        }
    }

    editSubTopic(data) {
        this.subjectId = data.id_subject;
        this.filtersubSubject();
        this.subSubjectId = data.id_sub_subject;
        this.topicId = data.id_topic;
        this.filterTopic();
        this.subTopicId = data.id_sub_topic;
        this.subTopicName = data.sub_topic_name;
        this.subTopicModelHeaderlLabel = "Edit Sub-topic"
        this.isSubTopicModal = true;
        this.isSubjectPurpose = "Edit"
    }
    removeSubTopic(data) {
        swal.fire({
            title: '',
            text: "Are you sure you want to remove this sub-topic?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#428e63',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.value) {
                this.showLoader = true;
                let json = {
                    "caller_email": localStorage.getItem("email"),
                    "id_sub_topic": data.id_sub_topic,
                }
                this.topicMasterService.removeSubTopic(json).subscribe(
                    data => {
                        this.showLoader = false;
                        if (data.status_code == "0") {
                            this.getAllSubjects();
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.showLoader = false;
                    }
                )
            }
        })
    }


    showTopicWeightage() {
        this.topicWeightageArray = []
        if (this.Topics.length == 0) {
            swal.fire("Please add some topic first")
        }
        else if (this.subjectId == "0" || this.subSubjectId == "0") {
            swal.fire("Please select subject and sub-subject first")
        }
        else {
            this.isTopicWeightageModal = true
            for (let data of this.Topics) {
                var weightage = 0;
                if (data.weightage) {
                    weightage = data.weightage
                }
                let json = {
                    "id_topic": data.id_topic,
                    "name": data.name,
                    "weightage": weightage
                }
                this.topicWeightageArray.push(json)
            }
        }
    }

    saveTopicWeightage() {
        var totalWeightage = 0.0;
        let check = false;
        for (let data of this.topicWeightageArray) {
            //.log(data.weightage)
            if (data.weightage == "") {
                check = true;
                break
            }

        }
        if (check) {
            swal.fire("Please enter weightage of all topic.")
        }
        else {
            for (let data of this.topicWeightageArray) {
                totalWeightage = totalWeightage + parseFloat(data.weightage)
            }
            if (totalWeightage > parseInt(this.subSubjectWeightage) || totalWeightage < parseInt(this.subSubjectWeightage)) {
                swal.fire("Please add proper weightage,It should be equal to " + this.subSubjectWeightage + " %");
            }
            else {
                let json = {
                    "caller_email": localStorage.getItem("email"),
                    "id_subject": this.subjectId,
                    "id_sub_subject": this.subSubjectId,
                    "topics": JSON.stringify(this.topicWeightageArray)
                }
                this.showLoader = true
                this.topicMasterService.saveTopicWeightage(json).subscribe(
                    data => {
                        this.showLoader = false
                        if (data.status_code == "0") {
                            this.hideModal();
                            this.getAllTopics();

                        }
                    },
                    err => {
                        this.showLoader = false
                    }
                )
            }
        }

    }


    showSubSubjectWeightage() {
        this.subSubjectWeightageArray = []
        if (this.SubSubjects.length == 0) {
            swal.fire("Please add some subject first")
        }
        else if (this.subjectId == "0") {
            swal.fire("Please select subject first")
        }
        else {
            this.isSubSubjectWeightageModal = true
            for (let data of this.SubSubjects) {
                var weightage = 0;
                if (data.weightage) {
                    weightage = data.weightage
                }
                let json = {
                    "id_sub_subject": data.id_sub_subject,
                    "name": data.name,
                    "weightage": weightage
                }
                this.subSubjectWeightageArray.push(json)
            }
        }
    }


    saveSubSubjectWeightage() {
        var totalWeightage = 0.0;
        let check = false;
        for (let data of this.subSubjectWeightageArray) {
            //.log(data.weightage)
            if (data.weightage == "") {
                check = true;
                break
            }

        }
        if (check) {
            swal.fire("Please enter weightage of all sub-subject.")
        }
        else {
            for (let data of this.subSubjectWeightageArray) {
                totalWeightage = totalWeightage + parseFloat(data.weightage)
            }
            if (totalWeightage > 100 || totalWeightage < 100) {
                swal.fire("Please add proper weightage,It should be equal to 100 %");
            }
            else {
                let json = {
                    "caller_email": localStorage.getItem("email"),
                    "id_subject": this.subjectId,
                    "sub_subjects": JSON.stringify(this.subSubjectWeightageArray),
                }
                this.showLoader = true
                this.topicMasterService.saveSubSubjectWeightage(json).subscribe(
                    data => {
                        this.showLoader = false
                        if (data.status_code == "0") {
                            this.hideModal();
                            this.getAllSubSubjects();

                        }
                    },
                    err => {
                        this.showLoader = false
                    }
                )
            }
        }

    }

}
