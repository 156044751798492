import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QuestionSourceService } from './question-source.service'
import swal from 'sweetalert2'
@Component({
  selector: 'question-source',
  templateUrl: './question-source.component.html',
  styleUrls: ['./question-source.component.css'],
  providers: [QuestionSourceService]
})
export class QuestionSourceComponent implements OnInit {
  isAddModal = false;
  showLoader = false;
  searchText: any;
  sourceName: any;
  modalPurpose: any;
  QuestionSources = [];
  questionSourceId: any;
  alertDiv = false;
  sucessAlertMessage: any;
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  column: string = 'CategoryName';
  direction: number;
  isDesc: boolean = false;
  sourseFor = "0"
  constructor(private router: Router, private questionSourceService: QuestionSourceService) {
    if (localStorage.getItem("isLogin") == "true") {
      this.getAllQuestionSource();

    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  getAllQuestionSource() {
    this.showLoader = true;
    this.questionSourceService.getAllQuestionSources(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {

          this.QuestionSources = data.question_sources
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }
  showAlertDiv(message) {
    var that = this
    that.alertDiv = true
    this.sucessAlertMessage = message
    setTimeout(function () {
      that.alertDiv = false
    }, 5000);
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    //("status" + status);
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }
  addNew() {
    this.clearData()
    this.modalPurpose = "Add"
    this.isAddModal = true
  }
  clearData() {
    this.sourceName = ""
  }

  hideAddEditModal() {
    this.isAddModal = false
  }
  editQuestion(data) {
    this.isAddModal = true;
    this.modalPurpose = "Edit";
    this.sourceName = data.name;
    this.sourseFor = data.source_for
    this.questionSourceId = data.id_question_source;
  }
  save() {
    if (!this.sourceName) {
      swal.fire("Please enter source name")
    }
    else if (this.sourseFor == "0") {
      swal.fire("Please select source")
    }
    else {
      if (this.modalPurpose == "Add") {
        let json = {
          "caller_email": localStorage.getItem("email"),
          "name": this.sourceName,
          "source_for": this.sourseFor
        }
        this.showLoader = true;
        this.questionSourceService.addQuestionResource(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == "0") {
              this.hideAddEditModal()
              this.QuestionSources = data.question_sources
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
      else {
        let json = {
          "caller_email": localStorage.getItem("email"),
          "name": this.sourceName,
          "id_question_source": this.questionSourceId,

          "source_for": this.sourseFor
        }

        this.showLoader = true;
        this.questionSourceService.editQuestionResource(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == "0") {
              this.hideAddEditModal()
              this.QuestionSources = data.question_sources
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
    }
  }

  removequestion(data) {
    swal.fire({
      title: '',
      text: "Are you sure you want to remove this question source?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;

        this.questionSourceService.removeQuestion(data.id_question_source, localStorage.getItem("email")).subscribe(
          data => {
            this.QuestionSources = []
            this.showLoader = false;
            if (data.status_code == 0) {
              this.QuestionSources = data.question_sources;
              this.hideAddEditModal()
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.hideAddEditModal()
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
    })
  }
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.QuestionSources.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  }
}
