import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReviewRequiementService } from './review-requirement.service';
import swal from 'sweetalert2'
@Component({
  selector: 'review-requirement',
  templateUrl: './review-requirement.component.html',
  styleUrls: ['./review-requirement.component.css'],
  providers: [ReviewRequiementService]
})
export class ReviewRequirementComponent implements OnInit {
  statusMessage: any;
  showLoader = false;
  ReviewRequiements = [];
  showAlert = false;
  alertMsg: any;
  alertType: any;
  constructor(private router: Router, private reviewRequiementService: ReviewRequiementService) {

    if (localStorage.getItem("isLogin") == "true") {
      this.getAllReviewRequirements()
    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  getAllReviewRequirements() {
    this.showLoader = true;
    this.reviewRequiementService.getAllReviewRequirement(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == 0) {
          this.ReviewRequiements = data["review_requirements"];
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }


  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    //("status" + status);
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }
}
