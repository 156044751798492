import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray,
  FormArrayName,
} from "@angular/forms";
import { OnlineLectureService } from "./online-lecture.service";
import swal from "sweetalert2";
import { Helper } from "../helper";
import { ValidateFile } from "../helper/file-validator";
@Component({
  selector: "online-lecture",
  templateUrl: "online-lecture.component.html",
  styleUrls: ["online-lecture.component.scss"],
  providers: [OnlineLectureService],
})
export class OnlineLectureComponent {
  form: FormGroup;
  showLoader = false;
  showAlert = false;
  alertMsg: any;
  alertType: any;
  isModalShow = false;
  faculty = [];
  SubTopics = [];
  Topics = [];
  SubSubjects = [];
  Subjects = [];
  tableArray = [];
  UnFiltterdSubSubjects = [];
  UnfiltterdTopics = [];
  UnfilttersSubTopics = [];
  questionMaster = [];
  quizQuestions = [];
  quizQuestionSearch: any;
  questionSearch: any;
  quizes = [];
  unfilterdQuizes = [];
  filter = {
    id_course_type: "0",
    id_subject: "0",
    id_sub_subject: "0",
    id_topic: "0",
    id_faculty: "0",
  };
  editData = { id_topic_lecture_group: "", lg: "" };
  column: string = "CategoryName";
  direction: number;
  isDesc: boolean = false;
  isPreviewModalShow = false;
  eachQuestionMark;
  lectures: FormArray;
  letureGroups = [];
  unfilterdLectureGroups = [];
  isDocumentsView = false;
  fileUploadForm: FormGroup;
  isUploadNotes = false;
  notesType = 0;
  documentFile: any;
  postId: number;
  options: Object = {
    url: Helper.imageUploadURL,

    params: { post_id: this.postId },
  };
  isViewDomuments = false;
  roleId: any;
  userId: any;
  searchText = "";
  doubtType=0;
  constructor(
    private onlineLectureService: OnlineLectureService,
    private formBuilder: FormBuilder
  ) {
    this.roleId = localStorage.getItem("id_role");
    this.userId = localStorage.getItem("id_user");
    if (this.roleId == 4) {
      this.filter.id_faculty = this.userId;
    }
    this.getMasterDataForLectureGroup();
    this.getAllLectureGroupFullDetail();
  }

  getMasterDataForLectureGroup() {
    this.showLoader = true;
    this.onlineLectureService
      .getMasterDataForLectureGroup(localStorage.getItem("email"), this.roleId)
      .subscribe(
        (data) => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.Subjects = data.subjects;
            this.UnFiltterdSubSubjects = data.sub_subjects;
            this.UnfiltterdTopics = data.topics;
            this.UnfilttersSubTopics = data.sub_topics;
            this.faculty = data.faculty;
            this.quizes = data.quizes;
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          } else {
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          }
        },
        (err) => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.");
        }
      );
  }

  getAllLectureGroupFullDetail() {
    this.showLoader = true;
    this.letureGroups = [];
    this.unfilterdLectureGroups = [];
    this.onlineLectureService
      .getAllLectureGroupFullDetail(localStorage.getItem("email"), this.doubtType)
      .subscribe(
        (data) => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.letureGroups = data.lectures_groups;
            this.unfilterdLectureGroups = data.lectures_groups;

            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          } else {
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          }
        },
        (err) => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.");
        }
      );
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    } else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }

  filterSuSuject(call?: any) {
    this.SubSubjects = [];
    this.filter.id_sub_subject = "0";
    this.filter.id_topic = "0";
    this.filter.id_faculty = "0";
    for (let data of this.UnFiltterdSubSubjects) {
      if (this.filter.id_subject == data.id_subject) {
        this.SubSubjects.push(data);
      }
    }
    this.filteredLecture();
    this.Topics = [];
  }

  filterTopics(call?: any) {
    this.Topics = [];
    this.filter.id_topic = "0";
    this.filter.id_faculty = "0";
    for (let data of this.UnfiltterdTopics) {
      if (this.filter.id_sub_subject == data.id_sub_subject) {
        this.Topics.push(data);
      }
    }
    this.filteredLecture();
  }

  openModal() {
    this.isModalShow = true;
  }

  closeModal() {
    this.isDocumentsView = false;
    this.isModalShow = false;
  }

  getFormattedJSON(json) {
    console.log(json);
    if (json == "") {
      return [];
    } else {
      return JSON.parse(json);
    }
  }

  filteredLecture() {
    this.letureGroups = this.unfilterdLectureGroups;
    if (this.filter.id_course_type != "0") {
      var temporaryArray = [];
      for (let data of this.letureGroups) {
        if (data.id_course_type == this.filter.id_course_type) {
          temporaryArray.push(data);
        }
      }
      this.letureGroups = temporaryArray;
      //(this.Questions)
    }
    console.log(this.filter);
    if (this.filter.id_subject != "0") {
      var temporaryArray = [];
      for (let data of this.letureGroups) {
        if (data.id_subject == this.filter.id_subject) {
          temporaryArray.push(data);
        }
      }
      this.letureGroups = temporaryArray;
      //(this.Questions)
    }
    if (this.filter.id_sub_subject != "0") {
      var temporaryArray = [];
      for (let data of this.letureGroups) {
        if (data.id_sub_subject == this.filter.id_sub_subject) {
          temporaryArray.push(data);
        }
      }
      this.letureGroups = temporaryArray;
      //(this.Questions)
    }
    if (this.filter.id_topic != "0") {
      var temporaryArray = [];
      for (let data of this.letureGroups) {
        if (data.id_topic == this.filter.id_topic) {
          temporaryArray.push(data);
        }
      }
      this.letureGroups = temporaryArray;
      //(this.Questions)
    }
    if (this.filter.id_faculty != "0") {
      var temporaryArray = [];
      for (let data of this.letureGroups) {
        if (data.id_faculty == this.filter.id_faculty) {
          temporaryArray.push(data);
        }
      }
      this.letureGroups = temporaryArray;
      //(this.Questions)
    }
  }

  changeStatus(data, changeFor) {
    var changeStatusId: any;
    var currentFor: any;

    if (data.is_current == 0 && changeFor == 1) {
      changeStatusId = 1;
      if (data.current_for != 0) {
        currentFor = 3;
      } else {
        currentFor = changeFor;
      }
    } else if (data.is_current == 0 && changeFor == 2) {
      changeStatusId = 1;
      if (data.current_for != 0) {
        currentFor = 3;
      } else {
        currentFor = changeFor;
      }
    } else if (data.is_current == 1 && changeFor == 1) {
      if (data.current_for == 2 && data.current_for != 3) {
        changeStatusId = 1;
        currentFor = 3;
      } else {
        if (data.current_for == 3) {
          changeStatusId = 1;
          currentFor = 2;
        } else {
          changeStatusId = 0;
          currentFor = 0;
        }
      }
    } else if (data.is_current == 1 && changeFor == 2) {
      if (data.current_for == 1 && data.current_for != 3) {
        changeStatusId = 1;
        currentFor = 3;
      } else {
        if (data.current_for == 3) {
          changeStatusId = 1;
          currentFor = 1;
        } else {
          changeStatusId = 0;
          currentFor = 0;
        }
      }
    } else if (data.is_current == 1 && data.current_for != 0) {
      currentFor = 3;
      changeStatusId = 1;
    } else if (data.is_current == 0 && data.current_for == 3) {
      changeStatusId = 0;
      currentFor = 0;
    }

    swal
      .fire({
        title: "",
        text: "Are you sure you want to perform this action?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#8B0000",
        cancelButtonColor: "#428e63",
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.value) {
          this.showLoader = true;
          this.onlineLectureService
            .publishUnpublishLecture(
              localStorage.getItem("email"),
              data.id_topic_lecture_group,
              changeStatusId,
              currentFor
            )
            .subscribe(
              (data) => {
                this.showLoader = false;
                if (data.status_code == 0) {
                  this.getAllLectureGroupFullDetail();
                  this.showAlertComponent(
                    data["status_code"],
                    data["status_message"]
                  );
                } else {
                  this.showAlertComponent(
                    data["status_code"],
                    data["status_message"]
                  );
                }
              },
              (err) => {
                swal.fire("Something went wrong. Please try again later.");
              }
            );
        } else {
          console.log(data.id_topic_lecture_group + changeFor);
          console.log(changeStatusId);
          if (changeStatusId == 1) {
            (<HTMLInputElement>(
              document.getElementById(data.id_topic_lecture_group + changeFor)
            )).checked = false;
          } else {
            (<HTMLInputElement>(
              document.getElementById(data.id_topic_lecture_group + changeFor)
            )).checked = true;
          }
        }
      });
  }

  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.letureGroups.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      } else if (a[property] > b[property]) {
        return 1 * direction;
      } else {
        return 0;
      }
    });
  }

  getFacultyDetails() {
    let findFaculty = this.faculty.find(
      (x) => x.id_user == this.form.getRawValue().id_faculty
    );
    //findFaculty)
    this.form.patchValue({
      faculty_description: findFaculty.user_info,
    });
  }

  lectureId: any;
  openUplodAddNotesModal(type, view, lect) {
    this.isUploadNotes = true;
    this.notesType = type;
    this.isViewDomuments = view;
    if (lect) {
      if ("id_topic_lecture_mapping" in lect) {
        this.lectureId = lect.id_topic_lecture_mapping;
      }
    }
  }
  closeUplodAddNotesModal() {
    this.isUploadNotes = false;
    this.lectureId = "";
  }

  getLectureGroupFullDetailById() {
    this.onlineLectureService
      .getLectureGroupFullDetailById(
        localStorage.getItem("email"),
        this.editData.id_topic_lecture_group
      )
      .subscribe(
        (data) => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.isDocumentsView = true;
            let response = data.lectures_groups[0];
            this.editData = {
              id_topic_lecture_group: response.id_topic_lecture_group,
              lg: response,
            };

            // this.closeModal();
            // swal.fire(data.status_message);
          }
        },
        (err) => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.");
        }
      );
  }

  viewLecturePreview(data) {
    this.isPreviewModalShow = true;
    this.editData.id_topic_lecture_group = data.id_topic_lecture_group;
    this.editData.lg = data;
  }

  closeLecturePreviewModal() {
    this.closeModal();
    this.closeUplodAddNotesModal();
    this.isPreviewModalShow = false;
  }
  getFileSource(file) {
    return Helper.imageURL + file;
  }
  downloadURI(uri) {
    var link = document.createElement("a");
    link.download = uri;
    link.href = Helper.imageURL + uri;
    link.click();
  }
  changeLectureSequence(data, event) {
    console.log(event.target.value);
    this.showLoader = true;
    this.onlineLectureService
      .changeLectureSequence(
        localStorage.getItem("email"),
        data.id_topic_lecture_group,
        event.target.value
      )
      .subscribe(
        (data) => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.getAllLectureGroupFullDetail();
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          } else {
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          }
        },
        (err) => {
          swal.fire("Something went wrong. Please try again later.");
        }
      );
  }
}
