import { Component, OnInit } from '@angular/core';
import { BookMasterService } from '../book-master/book-master.service'
import swal from 'sweetalert2'
import { CSVExport } from '../Csv-export';
@Component({
  selector: 'book-shelves',
  templateUrl: './shelf.component.html',
  styleUrls: ['./shelf.component.css'],
  providers: [BookMasterService]
})
export class BookShelvesComponent implements OnInit {
  searchText: any;
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  bookShelfObject: any = { 'id_coe': '' };
  isAddModal = false;
  showLoader = false;
  Shelfes = [];
  isAdd = 0;
  purpose = "Add";
  CoE = [];
  previousCoeId = ''
  previousCode = ''
  previousShelfCode = ''
  isUploadModal = false;
  fileInput: any;
  fileReaded: any;
  AccessionCodes = []
  isAccessionCodeModal = false;
  constructor(private bookMasterService: BookMasterService) {

  }
  ngOnInit() {
    this.getAllActiveCoe()
    this.getAllBookShelf()

  }
  getAllActiveCoe() {
    this.showLoader = true;
    this.CoE = []
    this.bookMasterService.getAllActiveCoe(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.CoE = data.coes
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getPreviousShelfCode(coeId) {
    if (this.previousCoeId != coeId) {
      this.showLoader = true;
      let index = this.CoE.findIndex(x => x.id_coe == coeId)
      var coeName = ''
      if (index != -1) {
        coeName = this.CoE[index]['name']
      }
      this.bookMasterService.getLastBookShelfCode(localStorage.getItem("email"), coeId).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.bookShelfObject['shelf_code'] = coeName + (parseInt(data.shelfe_code[0]['code']) + 1)
            this.bookShelfObject['code'] = (parseInt(data.shelfe_code[0]['code']) + 1)
          }
          else {
            this.bookShelfObject['shelf_code'] = coeName + "1"
            this.bookShelfObject['code'] = 1
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }
    else {
      this.bookShelfObject['shelf_code'] = this.previousShelfCode
      this.bookShelfObject['code'] = this.previousCode
    }

  }

  getAllBookShelf() {
    this.showLoader = true;
    this.Shelfes = []
    this.bookMasterService.getAllBookShelf(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.Shelfes = data.shelfes
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  addShelf() {
    this.bookShelfObject = { 'id_coe': '' };
    this.isAddModal = true
    this.isAdd = 0
  }

  editShelf(data) {
    this.purpose = "Edit"
    this.isAddModal = true
    this.isAdd = 1
    let tempObject = Object.assign({}, data);
    this.previousCoeId = tempObject['id_coe']
    this.previousCode = tempObject['code']
    this.previousShelfCode = tempObject['shelf_code']
    this.bookShelfObject = tempObject
  }

  save() {
    // this.showLoader = true;
    this.bookShelfObject['email'] = localStorage.getItem("email")
    if (this.isAdd == 0) {
      this.bookMasterService.addBookShelf(this.bookShelfObject).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.closeModal();
            this.getAllBookShelf()
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }
    else {
      this.bookMasterService.editBookShelf(this.bookShelfObject).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.closeModal();
            this.getAllBookShelf()
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }

  }
  closeModal() {
    this.isAddModal = false
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }

  hideAlert(val) {
    this.showAlert = val;
  }

  removeShelf(data) {
    swal.fire({
      title: '',
      text: "Deleting an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to remove this Book Shelve?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        this.bookMasterService.removeBookShelf(localStorage.getItem("email"), data.id_book_shelf).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.getAllBookShelf()
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);

            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
    })
  }


  export() {
    var arrayToExport = [];
    if (this.Shelfes.length > 0) {
      for (let data of this.Shelfes) {
        let json = {
          "Shelf Name": data.name,
          "Shelf Code": data.shelf_code,
          "CoE": data.coe_name,
          "Location": data.location,
          "No. Of Books": data.number_of_books,


        }

        arrayToExport.push(json);


      }
      CSVExport.downloadcsv(arrayToExport, " Book Shelfes");
    }
  }

  uploadModal() {
    this.isUploadModal = true;
  }
  hideUploadModal() {
    this.isUploadModal = false;
  }

  csv2Array(fileInput: any) {
    this.fileInput = fileInput
  }


  uploadCSV() {
    if (!this.fileInput) {
      swal.fire("Please select file to import.");
    }

    else {
      this.fileReaded = this.fileInput.target.files[0];
      let reader: FileReader = new FileReader();
      reader.readAsText(this.fileReaded);
      let lines = [];
      reader.onload = (e) => {
        let csv: string = reader.result as string;
        let allTextLines = csv.split(/\r|\n|\r/);
        let headers = allTextLines[0].split(',');


        for (let i = 0; i < allTextLines.length; i++) {
          // split content based on comma
          let data = allTextLines[i].split(',');
          if (data.length === headers.length) {
            let tarr = [];
            for (let j = 0; j < headers.length; j++) {
              tarr.push(data[j]);
            }

            // log each row to see output 
            lines.push(tarr);

          }
        }
        //(lines);

        let json = {
          "caller_email": localStorage.getItem("email"),
          "excel": JSON.stringify(lines),
        }
        this.showLoader = true
        this.bookMasterService.saveBokShelfCSV(json).subscribe(
          data => {
            this.showLoader = false
            if (data.status_code == "0") {
              this.getAllBookShelf()
              this.hideUploadModal()
            }
            else {
              swal.fire(data.status_message)
            }
          },
          err => {
            this.showLoader = false
            swal.fire("Something went wrong, Please try again later.")
            // this.hideUploadModal()
          }
        )



      }

    }
  }

  getAccessionCode(data) {
    this.AccessionCodes = []
    this.bookMasterService.getAccessionCode(localStorage.getItem("email"), data.id_book_shelf).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.AccessionCodes = data.book_copies
          this.isAccessionCodeModal = true;

        }
        else {
          swal.fire(data.status_message)
        }
      },
      err => {
        this.showLoader = false
        swal.fire("Something went wrong, Please try again later.")
        // this.hideUploadModal()
      }
    )
  }
  hideAccessionPrintModal() {
    this.isAccessionCodeModal = false;
  }

  printAccessionCode(printSectionId) {
    let printContents, popupWin;
    printContents = document.getElementById(printSectionId).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          .text-center{
           text-align:center;
          }
          .mb-0{
            margin-bottom:0px!important;
          } 
          .pb-0{
            padding-bottom:0px!important;
          } 
          
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
}
