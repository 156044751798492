import { Component, OnInit } from '@angular/core';
import { OnlineTestTemplateService } from './online-test-template.service'
import { Router } from '@angular/router'
import swal from 'sweetalert2';
import { DatePipe } from '@angular/common'
@Component({
  selector: 'app-online-test-template',
  templateUrl: './online-test-template.component.html',
  styleUrls: ['./online-test-template.component.css'],
  providers: [OnlineTestTemplateService, DatePipe]
})
export class OnlineTestTemplateComponent implements OnInit {
  Courses = [];
  courseTypeId: any;
  Exams = [];
  unFilteredExam = [];
  showLoader = false;
  showAlert = false;
  alertType: any;
  alertMsg: any;
  isAddModal = false;
  testDate: any;
  testName: any;
  examTemplateId: any;
  examType: any;
  purpose = "Add";
  searchText: any;
  showTestTemplateSection = false;
  testTemplateName: any;
  isAddEditTemplateSectionModal = false;
  Coe = [];
  coeId: any;
  Batch = [];
  batchId: any;
  unFilterdBatch = [];
  Section = [];
  sectionId: any;
  unFilterSection = [];
  startTime: any;
  endTime: any;
  sectionTestDate: any;
  TemplateSectionMapping = [];
  examSectionTemplateId: any;
  Scholars = [];
  isShowScholars = false;
  serachScholarText: any;
  sectionName: any;
  currentDate: any;
  column: string = 'CategoryName';
  direction: number;
  isDesc: boolean = false;
  allCenters: false;
  

  constructor(private router: Router, private onlineTestTemplateService: OnlineTestTemplateService, private datePipe: DatePipe) {
    this.currentDate = new Date();
    this.currentDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
    this.courseTypeId = "0";
    this.coeId = "0";
    this.batchId = "0";
    this.sectionId = "0";
    if (localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "1" || localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "2") {
      this.getCoe();

    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  getCoe() {
    this.showLoader = true;
    this.onlineTestTemplateService.getAllCoe(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == 0) {
          this.Coe = data["coes"];
          this.showAlertComponent(data['status_code'], data['status_message']);
          this.getBatch();
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {

        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }
  getBatch() {
    this.showLoader = true;
    this.onlineTestTemplateService.getAllBatches(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == 0) {
          this.unFilterdBatch = data["batches"];
          this.showAlertComponent(data['status_code'], data['status_message']);
          this.getSection();
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {

        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }
  getSection() {
    this.showLoader = true;
    this.onlineTestTemplateService.getAllSections(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == 0) {
          this.unFilterSection = data["sections"];
          this.showAlertComponent(data['status_code'], data['status_message']);
          this.getCourses();
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {

        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }
  getCourses() {
    this.showLoader = true;
    this.onlineTestTemplateService.getCourses(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == 0) {
          this.Courses = data["courses"];
          this.showAlertComponent(data['status_code'], data['status_message']);
          this.getAllExamTemplate();
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {

        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }
  getAllExamTemplate() {
    this.showLoader = true;
    this.onlineTestTemplateService.getExamTemplate(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == 0) {
          this.Exams = data["exam_template"];
          this.unFilteredExam = data["exam_template"];
          this.showAlertComponent(data['status_code'], data['status_message']);

        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {

        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    //("status" + status);
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }

  hideAlert(val) {
    this.showAlert = val;
  }

  selectCourseType() {

  }

  hideAddEditModal() {
    this.isAddModal = false;
    this.isAddEditTemplateSectionModal = false;
    this.isShowScholars = false;
  }
  addNew() {
    this.router.navigateByUrl('/Add exam pattern')
  }
  save() {
    if (this.courseTypeId == "0") {
      swal.fire("Please select course type.")
    }
    else if (!this.testName) {
      swal.fire("Please enter test name")
    }
    else if (!this.testDate) {
      swal.fire("Please select test date.")
    }
    else {
      if (this.purpose == "Add") {
        let json = {
          "caller_email": localStorage.getItem("email"),
          "id_course_type": this.courseTypeId,
          "test_name": this.testName,
          "test_date": this.testDate,
        }

        this.showLoader = true;
        this.onlineTestTemplateService.addExamTemplate(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.Exams = data["exam_template"];
              this.unFilteredExam = data["exam_template"];
              this.showAlertComponent(data['status_code'], data['status_message']);
              this.hideAddEditModal()
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {

            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
      else {
        let json = {
          "id_exam_template": this.examTemplateId,
          "caller_email": localStorage.getItem("email"),
          "id_course_type": this.courseTypeId,
          "test_name": this.testName,
          "test_date": this.testDate,
        }

        this.showLoader = true;
        this.onlineTestTemplateService.editExamTemplate(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.Exams = data["exam_template"];
              this.unFilteredExam = data["exam_template"];
              this.showAlertComponent(data['status_code'], data['status_message']);
              this.hideAddEditModal()
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {

            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
    }
  }

  editTemplate(data) {
    // this.examTemplateId = data.id_exam_template;
    // this.testName = data.exam_template_name;
    // this.testDate = this.datePipe.transform(data.created_tms, 'yyyy-MM-dd');
    // this.courseTypeId = data.id_course_type;
    // this.isAddModal = true;
    // this.purpose = "Edit";
    this.getTemplateQuestions(data);

  }

  getTemplateQuestions(data) {
    this.showLoader = true;

    this.onlineTestTemplateService.getExamTemplateQuestions(localStorage.getItem("email"), data.id_exam_template).subscribe(
      templateData => {
        this.showLoader = false;
        if (templateData.status_code == 0) {
          localStorage.setItem("exam_template_question", JSON.stringify(templateData.exam_template_details))
          console.log("can_edit=" + data.can_edit);

          this.router.navigate(['./Add exam pattern'], { queryParams: { testName: data.exam_template_name, examTemplateId: data.id_exam_template, testDate: this.datePipe.transform(data.created_tms, 'yyyy-MM-dd'), courseTypeId: data.id_course_type, patternId: data.id_pattern, description: data.question_paper_description, instruction: data.question_paper_instruction, duration: data.duration, examType: data.exam_type, exam_for: data.exam_for, can_edit: data.can_edit } });
        }
        else {
          localStorage.setItem("exam_template_question", null);
          swal.fire(templateData.status_message);
        }
      },
      err => {

        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  removeTemplate(data) {


    swal.fire({
      title: '',
      text: "Deleting an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to remove this test template?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        this.onlineTestTemplateService.removeExamTemplate(data.id_exam_template, localStorage.getItem("email")).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.Exams = data["exam_template"];
              this.unFilteredExam = data["exam_template"];
              this.showAlertComponent(data['status_code'], data['status_message']);

            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {

            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
    })

  }

  isExamMarkAsDone = false;
  conductedDate: any;
  examTemplateSectionData: any;
  openMarkDiscussionModal(data) {
    this.isExamMarkAsDone = true;
    this.examTemplateSectionData = data
  }

  closeMarkDiscussionModal() {
    this.isExamMarkAsDone = false;
  }

  markAsDone() {
    this.showLoader = true;
    let josn = { 'caller_email': localStorage.getItem("email"), 'id_exam_template_section_mapping': this.examTemplateSectionData.id_exam_template_section_mapping, 'is_discussion_done': 1, 'discussion_date': this.conductedDate }
    this.onlineTestTemplateService.updateExamTemplateSection(josn).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.closeMarkDiscussionModal();
          this.getTemplateSection();
        }
        else {
          swal.fire(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  changeStatus(data) {
    var changeStatusId: any;
    this.examTemplateId = data.id_exam_template
    if (data.id_status == 0) {
      changeStatusId = 1
    }
    else {
      changeStatusId = 0
    }
    if (changeStatusId == 1) {
      swal.fire({
        title: '',
        text: "Deactivating an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to deactivate this CoE?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#8B0000',
        cancelButtonColor: '#428e63',
        confirmButtonText: "Yes, deactivate it!"
      }).then((result) => {
        if (result.value) {
          this.showLoader = true;
          this.onlineTestTemplateService.changeStatus(this.examTemplateId, changeStatusId, localStorage.getItem("email")).subscribe(
            data => {
              this.showLoader = false;
              if (data.status_code == 0) {
                this.isAddModal = false;
                this.Exams = data["exam_template"];
                this.unFilteredExam = data["exam_template"];
                this.showAlertComponent(data['status_code'], data['status_message']);
              }
              else {
                this.hideAddEditModal()

                this.showAlertComponent(data['status_code'], data['status_message']);
              }
            },
            err => {
              this.showLoader = false;
              this.isAddModal = false;

              swal.fire("Something went wrong. Please try again later.")
            }
          )
        }
        else {

          if (changeStatusId == 0) {
            (<HTMLInputElement>document.getElementById(data.id_exam_template)).checked = false;
          }
          else {
            (<HTMLInputElement>document.getElementById(data.id_exam_template)).checked = true;
          }
        }
      })
    }
    else {
      this.showLoader = true;
      this.onlineTestTemplateService.changeStatus(this.examTemplateId, changeStatusId, localStorage.getItem("email")).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.isAddModal = false;

            this.Exams = data["exam_template"];
            this.unFilteredExam = data["exam_template"];

            this.showAlertComponent(data['status_code'], data['status_message']);
          }
          else {
            this.hideAddEditModal()

            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          this.isAddModal = false;

          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }
  }

  filterExamTemplate() {
    this.Exams = [];
    if (this.courseTypeId == "0") {
      this.Exams = this.unFilteredExam
    }
    else {
      for (let data of this.unFilteredExam) {
        if (data.id_course_type == this.courseTypeId) {
          this.Exams.push(data);
        }
      }
    }

  }

  showSectionView(data) {

    // swal.fire("type=" + data.exam_type);
    this.showTestTemplateSection = true;
    this.testTemplateName = data.exam_template_name;
    this.examTemplateId = data.id_exam_template;
    this.examType = data.exam_type;

    //swal.fire("test_type=" + data['examType']);

    this.getTemplateSection();
  }

  addNewTestTemplateSectionMapping() {
    this.purpose = "Add";
    this.isAddEditTemplateSectionModal = true;
  }

  filterBatch() {
    this.Batch = [];
    for (let data of this.unFilterdBatch) {
      if (this.coeId == data.id_coe) {
        this.Batch.push(data);
      }
    }
  }
  filterSection() {
    this.Section = [];
    for (let data of this.unFilterSection) {
      if (this.batchId == data.id_batch) {
        this.Section.push(data);
      }
    }
  }

  saveTemplateSection() {
    if (this.coeId == "0") {
      swal.fire("Please select coe.");
    }
    else if (this.batchId == "0") {
      swal.fire("Please select batch.");
    }
    else if (this.sectionId == "0") {
      swal.fire("Please select section.");
    }
    else if ((!this.startTime)) {
      swal.fire("Please enter start time.");
    }
    else if ((!this.endTime)) {
      swal.fire("Please enter end time.");
    }
    else if ((!this.sectionTestDate) && (this.examType != 2)) {
      swal.fire("Please enter date.");
    }
    else if ((this.timeValidation(this.startTime, this.endTime) == false) && (this.examType != 2)) {
      swal.fire("Selected Time is not correct.")
    }
    else {
      if (this.purpose == "Add") {
        let json = {
          "caller_email": localStorage.getItem("email"),
          "id_exam_template": this.examTemplateId,
          "id_section": this.sectionId,
          "start_time": this.startTime,
          "end_time": this.endTime,
          "date": this.sectionTestDate
        }

        this.showLoader = true;
        this.onlineTestTemplateService.addNewSectionExamTemplate(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.TemplateSectionMapping = data["exam_template_section"];
              this.showAlertComponent(data['status_code'], data['status_message']);
              this.hideAddEditModal();
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {

            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
      else {
        let json = {
          "caller_email": localStorage.getItem("email"),
          "id_exam_template": this.examTemplateId,
          "id_exam_template_section_mapping": this.examSectionTemplateId,
          "id_section": this.sectionId,
          "start_time": this.startTime,
          "end_time": this.endTime,
          "date": this.sectionTestDate
        }

        this.showLoader = true;
        this.onlineTestTemplateService.editSectionExamTemplate(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.TemplateSectionMapping = data["exam_template_section"];
              this.showAlertComponent(data['status_code'], data['status_message']);
              this.hideAddEditModal();
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {

            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }

    }
  }

  timeValidation(startTime, endTime) {
    var dateOne = new Date("12-25-2012 " + startTime);
    var dateSecond = new Date("12-25-2012 " + endTime);
    if (dateOne.getTime() > dateSecond.getTime()) {
      return false
    }
    else {
      return true
    }
  }
  getTemplateSection() {
    this.showLoader = true;
    this.TemplateSectionMapping = [];
    this.onlineTestTemplateService.getAllSectionForTestTemplate(localStorage.getItem("email"), this.examTemplateId).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == 0) {
          this.TemplateSectionMapping = data["exam_template_section"];
          this.showAlertComponent(data['status_code'], data['status_message']);

        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {

        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  editTemplateSection(data) {
    this.purpose = "Edit";
    this.examSectionTemplateId = data.id_exam_template_section_mapping;
    this.coeId = data.id_coe;
    this.filterBatch();
    this.batchId = data.id_batch;
    this.filterSection();
    this.sectionId = data.id_section;
    this.startTime = data.start_time;
    this.endTime = data.end_time;
    if (data.date != "0000-00-00 00:00:00")
      this.sectionTestDate = this.datePipe.transform(data.date, 'yyyy-MM-dd');
    this.isAddEditTemplateSectionModal = true;
  }

  removeTemplateSection(data) {
    swal.fire({
      title: '',
      text: "Deleting an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to remove this test template?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        this.onlineTestTemplateService.removeSectionExamTemplate(data.id_exam_template_section_mapping, this.examTemplateId, localStorage.getItem("email")).subscribe(
          data => {
            this.showLoader = false;
            this.TemplateSectionMapping = [];
            if (data.status_code == 0) {
              this.TemplateSectionMapping = data["exam_template_section"];
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
    })

  }

  sectionScholars(data) {
    this.showLoader = true;
    this.serachScholarText = ""
    this.Scholars = [];
    this.sectionName = data.section_name
    this.onlineTestTemplateService.getSectionScholar(localStorage.getItem("email"), data.id_section, this.examTemplateId).subscribe(
      data => {
        this.showLoader = false;

        if (data.status_code == 0) {
          this.Scholars = data.scholars
          this.isShowScholars = true;
        }
        else {
          this.isShowScholars = false;
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }


  resetExam(data) {
    swal.fire({
      title: '',
      text: "This action cannot be undone.Are you sure you want to reset this scholar exam?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, reset it!'
    }).then((result) => {
      if (result.value) {
        if (navigator.onLine) {
          this.showLoader = true;
          this.onlineTestTemplateService.ScholarExamReset(localStorage.getItem("email"), this.examTemplateId, data.coe_roll_number).subscribe(
            (data: any) => {
              this.showLoader = false;

              if (data["status_code"] == 0) {
                this.sectionScholars(data);
              }
              else {
                swal.fire(data["status_message"]);
              }
            },
            err => {
              this.showLoader = false;
              swal.fire("Something went wrong.")
            }

          )
        }
        else {
          swal.fire("Please check your internet connection.")
        }

      }
    })
  }
  print() {
    let printContents, popupWin;

    printContents = document.getElementById('print-sction').innerHTML;
    setTimeout(function () {

      var htmlToPrint = '' +
        '<style type="text/css">' +
        'table th, table td {' +
        'border:1px solid #000;' +
        'padding;0.5em;' +
        '}' +
        '</style>';
      htmlToPrint += printContents;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
          <html>
              <head>
                 
                  <style>
                      //........Customized style.......
                  </style>
              </head>
              <body onload="window.print();window.close()">${htmlToPrint}

              </body>
          </html>`
      );
      popupWin.document.close();
    }, 10)


  }

  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.Exams.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  }

  checkedScholars = [];

  checkUncheckScholar(data) {

    var check = true

    if (this.checkedScholars.length != 0) {
      for (let i = 0; i < this.checkedScholars.length; i++) {
        if (this.checkedScholars[i].coe_roll_number == data.coe_roll_number) {
          this.checkedScholars.splice(i, 1)
          check = false
          break;
        }
        else {
          check = true;
        }
      }

      if (check == true) {
        let json = {
          "coe_roll_number": data.coe_roll_number,
        }
        this.checkedScholars.push(json)
      }
    }
    else {
      let json = {
        "coe_roll_number": data.coe_roll_number,
      }
      this.checkedScholars.push(json)
    }

    //this.checkedScholars)

  }

  checkScholarIschecked(data) {
    var isChecked = false;
    if (this.checkedScholars.length != 0) {
      for (let checkedData of this.checkedScholars) {
        if (data.coe_roll_number == checkedData.coe_roll_number) {
          isChecked = true;
          break;
        }
        else {
          isChecked = false;
        }

      }

    }
    else {
      return false
    }
    if (isChecked) {
      return true;
    }
    else {
      return false;
    }
  }
  resetSelectedScholarExam() {
    if (this.checkedScholars.length == 0) {
      swal.fire("Please select at least one scholar")
    }
    else {
      swal.fire({
        title: '',
        text: "This action cannot be undone.Are you sure you want to reset this scholar exam?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#8B0000',
        cancelButtonColor: '#428e63',
        confirmButtonText: 'Yes, reset it!'
      }).then((result) => {
        if (result.value) {
          if (navigator.onLine) {
            this.showLoader = true;
            let json = {
              'caller_email': localStorage.getItem("email"),
              'id_exam_template': this.examTemplateId,
              'scholars': this.checkedScholars
            }
            this.onlineTestTemplateService.ScholarArrayExamReset(JSON.stringify(json)).subscribe(
              (data: any) => {
                this.showLoader = false;

                if (data["status_code"] == 0) {
                  this.sectionScholars(data);
                }
                else {
                  swal.fire(data["status_message"]);
                }
              },
              err => {
                this.showLoader = false;
                swal.fire("Something went wrong.")
              }

            )
          }
          else {
            swal.fire("Please check your internet connection.")
          }

        }
      })

    }
  }

  backToCenters() {
    this.showTestTemplateSection = false;
  }

  SelectedQuestions = [];
  isSollutionVisibleForExam = false;
  isExamPaperPreview = false;

  hideModal() {
    this.isExamPaperPreview = false;

  }


  downloadQuestionPaperWithSolution(data) {

    this.testName = data.exam_template_name;
    this.showLoader = true;
    console.log("HIIIIIIIIIIII");
    this.onlineTestTemplateService.getExamTemplateQuestions(localStorage.getItem("email"), data.id_exam_template).subscribe(
      templateData => {
        this.showLoader = false;
        if (templateData.status_code == 0) {
          this.SelectedQuestions = templateData.exam_template_details;
          console.log("SelectedQuestions length = " + this.SelectedQuestions.length);
          
          //new code
          let tempQuestionArray = this.SelectedQuestions;
          // this.orignalSelectedQuestions = JSON.parse(localStorage.getItem("exam_template_question"));
          var QuestionArray = [];
          for (let dataOne of tempQuestionArray) {
            let json = {};
            if (dataOne.id_comprehension != "0") {
              const index = QuestionArray.findIndex(question => question.id_comprehension === dataOne.id_comprehension);
              if (index == -1) {
                json["id_comprehension"] = dataOne.id_comprehension
                json["comprehension_text"] = dataOne.comprehension_text
                json["id_subject"] = dataOne.id_subject
                json["subject_name"] = dataOne.subject_name
                json["id_sub_subject"] = dataOne.id_sub_subject
                json["sub_subject_name"] = dataOne.sub_subject_name
                json["id_topic"] = dataOne.id_topic
                json["topic_name"] = dataOne.topic_name
                json["id_question_source"] = dataOne.id_question_source
                json["id_difficult_level"] = dataOne.id_difficult_level
                json["question_source_name"] = dataOne.question_source_name
                json["difficulty_name"] = dataOne.difficulty_name
                json["last_review_date"] = dataOne.last_review_date
                json["id_question_pattern"] = "3"
                json["is_approved"] = dataOne.is_approved
                json["question_pattern_name"] = "Comprehension"
                var comprehensionQuestionArray = []

                for (let dataSecond of tempQuestionArray) {
                  if (dataOne.id_comprehension == dataSecond.id_comprehension && dataOne.id_comprehension != "0") {
                    comprehensionQuestionArray.push(dataSecond)
                  }

                }
                json["questions"] = comprehensionQuestionArray
                QuestionArray.push(json)
              }

            }
            else {
              QuestionArray.push(dataOne)
            }
          }
          this.SelectedQuestions = QuestionArray;
          // new code end
          // this.orignalSelectedQuestions = QuestionArray;

          
          // console.log("SelectedQuestions length = " + json.stringifythis.SelectedQuestions.length);
          if (this.SelectedQuestions.length == 0) {
            swal.fire("Please add some questions first.")
          }
          else {
            this.isSollutionVisibleForExam = true;
            this.isExamPaperPreview = true;
          }

        }
        else {

          swal.fire(templateData.status_message);
        }
      },
      err => {

        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )



  }

  downloadQuestionPaperWithoutSolution(data) {

    this.testName = data.exam_template_name;
    this.isSollutionVisibleForExam = false;
    this.showLoader = true;

    this.onlineTestTemplateService.getExamTemplateQuestions(localStorage.getItem("email"), data.id_exam_template).subscribe(
      templateData => {
        this.showLoader = false;
        if (templateData.status_code == 0) {
          this.SelectedQuestions = templateData.exam_template_details;
           //new code
           let tempQuestionArray = this.SelectedQuestions;
           // this.orignalSelectedQuestions = JSON.parse(localStorage.getItem("exam_template_question"));
           var QuestionArray = [];
           for (let dataOne of tempQuestionArray) {
             let json = {};
             if (dataOne.id_comprehension != "0") {
               const index = QuestionArray.findIndex(question => question.id_comprehension === dataOne.id_comprehension);
               if (index == -1) {
                 json["id_comprehension"] = dataOne.id_comprehension
                 json["comprehension_text"] = dataOne.comprehension_text
                 json["id_subject"] = dataOne.id_subject
                 json["subject_name"] = dataOne.subject_name
                 json["id_sub_subject"] = dataOne.id_sub_subject
                 json["sub_subject_name"] = dataOne.sub_subject_name
                 json["id_topic"] = dataOne.id_topic
                 json["topic_name"] = dataOne.topic_name
                 json["id_question_source"] = dataOne.id_question_source
                 json["id_difficult_level"] = dataOne.id_difficult_level
                 json["question_source_name"] = dataOne.question_source_name
                 json["difficulty_name"] = dataOne.difficulty_name
                 json["last_review_date"] = dataOne.last_review_date
                 json["id_question_pattern"] = "3"
                 json["is_approved"] = dataOne.is_approved
                 json["question_pattern_name"] = "Comprehension"
                 var comprehensionQuestionArray = []
 
                 for (let dataSecond of tempQuestionArray) {
                   if (dataOne.id_comprehension == dataSecond.id_comprehension && dataOne.id_comprehension != "0") {
                     comprehensionQuestionArray.push(dataSecond)
                   } 
                 }
                 json["questions"] = comprehensionQuestionArray
                 QuestionArray.push(json)
               }
 
             }
             else {
               QuestionArray.push(dataOne)
             }
           }
           this.SelectedQuestions = QuestionArray;
           // new code end
          if (this.SelectedQuestions.length == 0) {
            swal.fire("Please add some questions first.")
          }
          else {

            this.isExamPaperPreview = true;
          }
        }
        else {

          swal.fire(templateData.status_message);
        }
      },
      err => {

        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )

  }


  getQuestionTextForMdal(data) {


    {
      let x = data.split("$$");
      return "Assertion: " + x[0] + " Reason: " + x[1]
    }

  }

  MatrixCheckedAnswer = [];
  ComprehensionTypeMatrix = [];

  generateMatrixMatchingAnswerForComprehension(columnOne, columnSecond, matrixMatch) {
    var tempArray = [];
    this.MatrixCheckedAnswer = matrixMatch
    if (columnOne.length > 0 && columnSecond.length > 0) {
      for (let data of columnOne) {
        let json = {
          "option": data.column_name,
          "answers": columnSecond
        }
        tempArray.push(json)
      }
    }
    this.ComprehensionTypeMatrix = tempArray
    return tempArray

  }

  checkIsCheckedOrNot(optionName, answerName) {
    let status = false;
    for (let data of this.MatrixCheckedAnswer) {
      if (data.option == optionName && data.answers == answerName) {
        status = true;
        break;
      }
    }

    return status;
  }


  printQuestions() {

    // let doc = new jsPDF()
    // let specialElementHandlers = {
    //   '#editor': function (element, renderer) {
    //     return true;
    //   }
    // };
    // let content = document.getElementById('printSection').innerHTML
    // doc.fromHTML(content, 15, 15, {
    //   'width': 190,
    //   'elementHnadlers': specialElementHandlers
    // }, function (bla) { doc.save('saveInCallback.pdf'); });

    let printContents, popupWin;

    printContents = document.getElementById('printSection').innerHTML;
    var htmlToPrint = '' +
      '<style type="text/css">' +
      'table th, table td {' +
      'border:1px solid #000;' +
      'padding;0.5em;' +
      '}' +
      '</style>';
    htmlToPrint += printContents;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
          <head>
          <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/css/bootstrap.min.css" integrity="sha384-9gVQ4dYFwwWSjIDZnLEWnxCjeSWFphJiwGPXr1jddIhOegiu1FwO5qRGvFXOdJZ4"
          crossorigin="anonymous">
        <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
          crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.0/umd/popper.min.js" integrity="sha384-cs/chFZiN24E4KMATLdqdvsezGxaGsi4hLGOzlXwp5UZB1LY//20VyM2taTB4QvJ"
          crossorigin="anonymous"></script>
        <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/js/bootstrap.min.js" integrity="sha384-uefMccjFJAIv6A+rW+L4AHf99KvxDjWSu1z9VI8SKNVmz4sk7buKt/6v9KI65qnm"
          crossorigin="anonymous"></script>
              <style>
              li{
                list-style:none;
              }
              ul {
                list-style-type: none;
              }
              
              </style>
          </head>
          <body onload="window.print();window.close()">${htmlToPrint}

          </body>
      </html>`
    );
    popupWin.document.close();
  }

  getQuestionAnswer(data) {
    let answerString = "";
    if (data.id_question_pattern == "1" || data.id_question_pattern == "2" || data.id_question_pattern == "6") {

      for (let option of data.question_optiones) {
        if (option.is_option_correct == "0") {
          if (answerString == "") {
            answerString = option.option_name
          }
          else {
            answerString = answerString + "," + option.option_name;
          }

        }
      }
    }
    else if (data.id_question_pattern == "4") {
      answerString = data.answer
    }
    else if (data.id_question_pattern == "5") {
      //.log(data.matrix_match)
      for (let match of data.matrix_match) {
        if (answerString == "") {
          answerString = match.option + "-->" + match.answers;
        }
        else {
          answerString = answerString + "," + match.option + "-->" + match.answers;
        }
      }
    }
    return answerString
  }


  checkComprehensionMatrixIsCheckedOrNot(optionName, answerName) {
    let status = false;
    for (let data of this.MatrixCheckedAnswer) {
      if (data.option == optionName && data.answers == answerName) {
        status = true;
        break;
      }
    }

    return status;
  }




}
