import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
@Injectable()
export class MasterService {
    baseUrl = Helper.baseURL
    constructor(private http: HttpClient) {


    }
    getAllMasterData(email) {
        return this.http.get(this.baseUrl + "/Master/getAllMasterTab?email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }


}