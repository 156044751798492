import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent implements OnInit {
  @Input() lineChartLabels = [];
  @Input() lineChartData = [];
  lineChartLegend = true

  constructor() {
    //.log("under line chart component")
  }

  ngOnInit() {
  }

  public lineChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          min: 0,
          max: 100,
          stepSize: 10
        }
      }],
      xAxes: [{
        ticks: {
          stepSize: 1,
          min: 0,
          autoSkip: false
        }
      }]
    }
  };
  public lineChartColors: Array<any> = [
    { // blue
      backgroundColor: 'rgba(123,104,238,0.2)',
      borderColor: 'rgba(123,104,238)',
      pointBackgroundColor: 'rgba(123,104,238)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(123,104,238,0.8)'
    },
    { // orange
      backgroundColor: 'rgb(255,99,71,0.2)',
      borderColor: 'rgb(255,99,71)',
      pointBackgroundColor: 'rgb(255,99,71)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(255,99,71,0.8)'
    },
    { // green
      backgroundColor: 'rgb(66, 142, 99,0.2)',
      borderColor: 'rgb(66, 142, 99)',
      pointBackgroundColor: 'rgb(66, 142, 99)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(66, 142, 99,0.8)'
    },
    { // purple
      backgroundColor: 'rgb(157, 68, 122,0.2)',
      borderColor: 'rgb(157, 68, 122)',
      pointBackgroundColor: 'rgb(157, 68, 122)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(157, 68, 122,0.8)'
    },
  ];
  public lineChartType: string = 'line';
  public chartClicked(e: any): void {
    //.log(e);
  }

  public chartHovered(e: any): void {
    //.log(e);
  }
}
