import { Component, OnInit } from '@angular/core';

import { UploadService } from '../services/upload.service';
import { MyDocumentsService } from './my-document.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2'
import * as JSZip from 'jszip';
import { saveAs } from 'save-as'
@Component({
  selector: 'app-my-document',
  templateUrl: './my-document.component.html',
  styleUrls: ['./my-document.component.css'],
  providers: [UploadService, MyDocumentsService]
})
export class MyDocumentComponent implements OnInit {
  isErrorMessage = false;
  searchText: any;
  showLoader = false;
  alertDiv = false;
  sucessAlertMessage: any;
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  statusMessage = ""

  isUploadModal = false;
  fileInput: any;
  fileReaded: any;
  imageFile: any;

  scholarData: any;
  isViewDocumentModal = false;
  scholarDocumnets = []
  isViewSingleDocumentModal = false
  scholarDocument: any;
  errorMessage: any

  constructor(private domSanitizer: DomSanitizer, private uploadService: UploadService, private myDocumentsService: MyDocumentsService) { }

  ngOnInit() {
    this.getUserFullDetailByRoleId()
  }
  getUserFullDetailByRoleId() {
    this.myDocumentsService.getUserFullDetailByRoleId(localStorage.getItem("email"), localStorage.getItem("id_role"), localStorage.getItem("id_user")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == 0) {

          this.scholarData = data["users"][0];
          this.viewDocuments(this.scholarData);

        }

      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }
  viewDocuments(data) {
    this.scholarData = {}
    this.scholarData = data
    this.getScholarDocuments(data.coe_roll_number)
  }

  getScholarDocuments(path) {
    this.scholarDocumnets = []
    var that = this
    this.uploadService.getFile(path).then(function (data: any) {


      var objectsArray = []
      for (let file of data) {
        //(file)
        let documentName = file.Key.split('/').pop().split('.')[0]
        that.uploadService.getDocument(file).then(
          function (fileObject: any) {

            var data = fileObject
            // //(fileObject)
            var json = { 'document_type': documentName }
            var str = data.Body.reduce(function (a, b) { return a + String.fromCharCode(b) }, '');
            json['string_images'] = str
            if (data.ContentType != "application/pdf") {
              json['document'] = that.getImage(btoa(str).replace(/.{76}(?=.)/g, '$&\n'))
            }
            else {
              json['document'] = that.getPDF(btoa(str).replace(/.{76}(?=.)/g, '$&\n'))
            }
            json['content_type'] = data.ContentType
            json['coe_roll_number'] = path
            json['key'] = file.Key
            that.scholarDocumnets.push(json)
          }
        );


      }
    });
  }

  public getImage(data) {

    var image;
    // //(data)
    image = 'data:image/jpeg;base64,' + data;
    image = this.domSanitizer.bypassSecurityTrustUrl(image);
    return image
  }

  public getPDF(data) {
    var image = 'data:application/pdf;base64,' + data;
    return image;
  }

  downloadDocument(data) {
    var fileName = "";
    var image = "";
    if (data.content_type == "application/pdf") {
      fileName = this.scholarData.coe_roll_number + "-" + this.scholarData.name + "-" + data.document_type + ".pdf"
      image = 'data:application/pdf;base64,' + btoa(data.string_images).replace(/.{76}(?=.)/g, '$&\n');
    }
    else {
      fileName = this.scholarData.coe_roll_number + "-" + this.scholarData.name + "-" + data.document_type + ".jpg"
      image = 'data:image/jpeg;base64,' + btoa(data.string_images).replace(/.{76}(?=.)/g, '$&\n');
    }
    const downloadLink = document.createElement("a");

    downloadLink.href = image;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  viewDocument(data) {
    this.scholarDocument = {}
    this.isViewSingleDocumentModal = true
    this.scholarDocument = data

  }

  hideSingleDocumentModal() {
    this.isViewSingleDocumentModal = false;
  }

  downloadImageAsZip() {
    var zip = new JSZip();
    var zipFilename = this.scholarData.coe_roll_number + "-" + this.scholarData.name + "-" + ".zip";
    var count = 0;
    for (let file of this.scholarDocumnets) {
      var fileName = ""
      if (file.content_type == "application/pdf") {
        fileName = file.document_type + ".pdf"
      }
      else {
        fileName = file.document_type + ".jpg"
      }
      zip.file(fileName, file.string_images, { binary: true });
      count++;
      if (count == this.scholarDocumnets.length) {
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          saveAs(content, zipFilename);
        });
      }
    }

  }

}
