import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray,
  FormArrayName,
} from "@angular/forms";
import { LectureGroupMasterService } from "./lecture-groups-master.service";
import swal from "sweetalert2";
import { Helper } from "../helper";
import { ValidateFile } from "../helper/file-validator";
import * as _ from "lodash";
import { ValueConverter } from "@angular/compiler/src/render3/view/template";
import { Router } from "@angular/router";
@Component({
  selector: "lecture-groups-master",
  templateUrl: "lecture-groups-master.component.html",
  styleUrls: ["lecture-groups-master.component.scss"],
  providers: [LectureGroupMasterService],
})
export class LectureGroupsMasterComponent {
  form: FormGroup;
  showLoader = false;
  showAlert = false;
  alertMsg: any;
  alertType: any;
  isModalShow = false;
  faculty = [];
  SubTopics = [];
  Topics = [];
  SubSubjects = [];
  Subjects = [];
  tableArray = [];
  UnFiltterdSubSubjects = [];
  UnfiltterdTopics = [];
  UnfilttersSubTopics = [];
  questionMaster = [];
  quizQuestions = [];
  quizQuestionSearch: any;
  questionSearch: any;
  quizes = [];
  unfilterdQuizes = [];
  filter = {
    id_course_type: "0",
    id_subject: "0",
    id_sub_subject: "0",
    id_topic: "0",
    id_faculty: "0",
  };
  editData = { id_topic_lecture_group: "", lg: "" };
  column: string = "CategoryName";
  direction: number;
  isDesc: boolean = false;
  isPreviewModalShow = false;
  eachQuestionMark;
  lectures: FormArray;
  letureGroups = [];
  unfilterdLectureGroups = [];
  isDocumentsView = false;
  fileUploadForm: FormGroup;
  isUploadNotes = false;
  notesType = 0;
  documentFile: any;
  postId: number;
  searchText = "";
  options: Object = {
    url: Helper.pdfFileUploadURL,

    params: { post_id: this.postId },
  };
  imageUploadOptions: Object = {
    url: Helper.imageUploadURL,

    params: { post_id: this.postId },
  };
  isViewDomuments = false;
  roleId: any;
  userId: any;
  applicableFor: any = [
    {
      name: "Class XI-XII",
      selected: false,
      value: 1,
    },
    {
      name: "Class XIII",
      value: 2,
      selected: false,
    },
  ];
  selectedAfor: [string];
  initEditText:any;
  constructor(
    private lectureGroupMasterService: LectureGroupMasterService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    if (localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "1" || localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "4") {
      this.initializeTiny();
      this.roleId = localStorage.getItem("id_role");
      this.userId = localStorage.getItem("id_user");
      if (this.roleId == 4) {
        this.filter.id_faculty = this.userId;
      }
      this.eachQuestionMark = Helper.QUIZ_MARKS;
      this.getMasterDataForLectureGroup();
      this.getAllLectureGroupFullDetail();
    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    this.buildForm();
  }

  getMasterDataForLectureGroup() {
    this.showLoader = true;
    this.lectureGroupMasterService
      .getMasterDataForLectureGroup(localStorage.getItem("email"), this.roleId)
      .subscribe(
        (data) => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.Subjects = data.subjects;
            this.UnFiltterdSubSubjects = data.sub_subjects;
            this.UnfiltterdTopics = data.topics;
            this.UnfilttersSubTopics = data.sub_topics;
            this.faculty = data.faculty;
            // this.quizes=data.quizes;
            this.unfilterdQuizes = data.quizes;
            if (this.roleId == 4) {
              this.form.controls["id_faculty"].reset({
                value: this.userId,
                disabled: true,
              });
              this.getFacultyDetails();
            }
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          } else {
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          }
        },
        (err) => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.");
        }
      );
  }

  getAllLectureGroupFullDetail() {
    this.showLoader = true;
    this.letureGroups = [];
    this.unfilterdLectureGroups = [];
    this.lectureGroupMasterService
      .getAllLectureGroupFullDetail(localStorage.getItem("email"), this.roleId)
      .subscribe(
        (data) => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.letureGroups = data.lectures_groups;
            this.unfilterdLectureGroups = data.lectures_groups;

            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          } else {
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          }
        },
        (err) => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.");
        }
      );
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    } else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }

  buildForm() {
    this.form = this.formBuilder.group({
      id_course_type: new FormControl("", [Validators.required]),
      id_subject: new FormControl("", [Validators.required]),
      id_sub_subject: new FormControl("", [Validators.required]),
      id_topic: new FormControl("", [Validators.required]),
      id_faculty: new FormControl("", [Validators.required]),
      video_language:new FormControl("", [Validators.required]),
      faculty_description: new FormControl("", [Validators.required]),
      number_of_lecture: new FormControl("1", [Validators.required]),
      amount_of_course: new FormControl(""),
      applicable_fors: this.createApplicableFor(this.applicableFor),
      lectures: this.formBuilder.array([this.createLecture()]),
    });

    this.fileUploadForm = this.formBuilder.group({
      type: new FormControl("", [Validators.required]),
      file: new FormControl("", [Validators.required]),
    });
  }

  createApplicableFor(applicableForInputs) {
    const arr = applicableForInputs.map((afor) => {
      return new FormControl(afor.selected || false, [Validators.required]);
    });
    return new FormArray(arr);
  }

  getSelectedApplicableFor() {
    this.selectedAfor = _.map(
      this.form.controls.applicable_fors["controls"],
      (afor, i) => {
        return afor.value && this.applicableFor[i].value;
      }
    );
    this.getSelectedApplicableForsName();
  }

  getSelectedApplicableForsName() {
    this.selectedAfor = _.filter(this.selectedAfor, function (hobby) {
      if (hobby !== false) {
        return hobby;
      }
    });
    console.log(this.selectedAfor);
  }

  initializeTiny() {
    this.initEditText = {
      plugins: "image",
      readonly: 1,
      toolbar: "sizeselect | bold italic | fontselect |  fontsizeselect",
      images_upload_url: 'http://dakshana.beta.vendorZApp.in/service/upload_image.php',
      convert_urls: false,
      images_upload_handler: function (blobInfo, success, failure) {
        var xhr, formData;
        xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.open('POST', 'http://dakshana.beta.vendorZApp.in/service/upload_image.php');
        xhr.onload = function () {
          var json;

          if (xhr.status != 200) {
            failure('HTTP Error: ' + xhr.status);
            return;
          }
          json = JSON.parse(xhr.responseText);

          if (!json || typeof json.generatedName != 'string') {
            failure('Invalid JSON: ' + xhr.responseText);
            return;
          }
          success(json.generatedName);
        };
        formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.filename());
        xhr.send(formData);
      }
    }


  }
  resetLectures() {
    let value = this.form.value;
    this.form = this.formBuilder.group({
      id_course_type: new FormControl(value.id_course_type, [
        Validators.required,
      ]),
      id_subject: new FormControl(value.id_subject, [Validators.required]),
      id_sub_subject: new FormControl(value.id_sub_subject, [
        Validators.required,
      ]),
      id_topic: new FormControl(value.id_topic, [Validators.required]),
      id_faculty: new FormControl(value.id_faculty, [Validators.required]),
      video_language:new FormControl("", [Validators.required]),
      faculty_description: new FormControl(value.faculty_description, [
        Validators.required,
      ]),
      applicable_fors: this.createApplicableFor(this.applicableFor),
      number_of_lecture: new FormControl("1", [Validators.required]),
      amount_of_course: new FormControl(""),
      lectures: this.formBuilder.array([this.createLecture()]),
    });
    if (this.roleId == 4) {
      this.form.controls["id_faculty"].reset({
        value: this.userId,
        disabled: true,
      });
      this.getFacultyDetails();
    }
  }
  createLecture(): FormGroup {
    return this.formBuilder.group({
      id_topic_lecture_mapping:new FormControl(""),
      topic_to_learn: new FormControl("", [Validators.required]),
      duration: new FormControl("", [Validators.required]),
      videos: this.formBuilder.array([
        this.createVideoLink(),
        this.createVideoLink(),
        this.createVideoLink(),
        this.createVideoLink(),
        this.createVideoLink(),
      ]),
      home_work: new FormControl("", [Validators.required]),
      id_quiz: new FormControl("", [Validators.required]),
      notes: new FormControl("[]"),
    });
  }

  createVideoLink(): FormGroup {
    return this.formBuilder.group({
      url: new FormControl(""),
    });
  }

  filterSuSuject(call?: any) {
    this.SubSubjects = [];
    this.filter.id_sub_subject = "0";
    this.filter.id_topic = "0";
    this.filter.id_faculty = "0";
    for (let data of this.UnFiltterdSubSubjects) {
      if (call == "lecture") {
        if (this.filter.id_subject == data.id_subject) {
          this.SubSubjects.push(data);
        }
      } else {
        if (this.form.value.id_subject == data.id_subject) {
          this.SubSubjects.push(data);
        }
        if(call=='html')
        this.filteredQuiz();
      }
    }
    this.form.patchValue({ id_topic: "", id_sub_subject: "" });
    if (call == "html") {

      this.resetLectures();
    }
    if (call == "lecture") {
      this.filteredLecture();
    }

    this.Topics = [];
  }

  filterTopics(call?: any) {
    this.Topics = [];
    this.filter.id_topic = "0";
    this.filter.id_faculty = "0";
    for (let data of this.UnfiltterdTopics) {
      if (call == "lecture") {
        if (this.filter.id_sub_subject == data.id_sub_subject) {
          this.Topics.push(data);
        }
      } else {
        if (this.form.value.id_sub_subject == data.id_sub_subject) {
          this.Topics.push(data);
        }
        if(call=='html')
        this.filteredQuiz();
      }
    }
    if (call == "html") {
      this.resetLectures();
    }
    if (call == "lecture") {
      this.filteredLecture();
    }
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  public lectureHasError = (controlName: string, errorName: string, index) => {
    return this.form
      .get("lectures")
      ["controls"][index].controls[controlName].hasError(errorName);
  };

  openModal() {
    this.isModalShow = true;
  }

  closeModal() {
    this.lectures = new FormArray([]);
    this.isPreviewModalShow = false;
    this.editData.id_topic_lecture_group = "";
    this.quizQuestions = [];
    this.buildForm();
    this.isDocumentsView = false;
    this.isModalShow = false;
  }

  saveDraft() {
    let json = this.form.getRawValue();
    if (json.applicable_fors.findIndex((x) => x == true) == -1) {
      swal.fire("Please select at least one applicable for.");
      return true;
    }
    for (let lecture of json.lectures) {
      if (lecture.id_quiz == -1) {
        let find = lecture.videos.findIndex((x) => x.url != "");
        if (find == -1) {
          swal.fire(
            "Please make sure if you select NO Quiz then there be a at least one video link of that lecture."
          );
          return true;
        }
      }
    }
    json["caller_email"] = localStorage.getItem("email");
    this.showLoader = true;
    if (this.editData.id_topic_lecture_group) {
      json.id_topic_lecture_group = this.editData.id_topic_lecture_group;
      this.editLectureGroup(json);
    } else {
      this.addNewLectureGroup(json);
    }
  }
  addNewLectureGroup(json) {
    this.lectureGroupMasterService.addNewLectureGroup(json).subscribe(
      (data) => {
        this.showLoader = false;
        if (data.status_code == 0) {
          this.isDocumentsView = true;
          let response = data.lecture_groups[0];
          this.editData = {
            id_topic_lecture_group: response.id_topic_lecture_group,
            lg: response,
          };
          this.getLectureGroupFullDetailById();
          // this.closeModal();
          swal.fire(data.status_message);
        } else {
          swal.fire(data.status_message);
        }
      },
      (err) => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.");
      }
    );
  }

  getFormattedJSON(json) {
    console.log(json);
    if (json == "") {
      return [];
    } else {
      return JSON.parse(json);
    }
  }

  editLectureGroup(json) {
    this.lectureGroupMasterService.editLectureGroup(json).subscribe(
      (data) => {
        this.showLoader = false;
        if (data.status_code == 0) {
          this.isDocumentsView = true;
          this.getLectureGroupFullDetailById();
          swal.fire(data.status_message);
        } else {
          swal.fire(data.status_message);
        }
      },
      (err) => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.");
      }
    );
  }

  filteredQuiz(call?: any) {
    console.log(this.unfilterdQuizes);
    this.quizes = this.unfilterdQuizes;
    if (this.form.value.id_course_type) {
      var temporaryArray = [];
      for (let data of this.quizes) {
        if (data.id_course_type === this.form.value.id_course_type) {
          temporaryArray.push(data);
        }
      }
      // if(temporaryArray)
      this.quizes = temporaryArray;
    }
    if (this.form.value.id_subject) {
      var temporaryArray = [];
      for (let data of this.quizes) {
        if (data.id_subject === this.form.value.id_subject) {
          temporaryArray.push(data);
        }
      }
      this.quizes = temporaryArray;
    }
    if (this.form.value.id_sub_subject) {
      var temporaryArray = [];
      for (let data of this.quizes) {
        if (data.id_sub_subject === this.form.value.id_sub_subject) {
          temporaryArray.push(data);
        }
      }
      this.quizes = temporaryArray;
    }
    if (this.form.value.id_topic) {
      var temporaryArray = [];
      for (let data of this.quizes) {
        if (data.id_topic === this.form.value.id_topic) {
          temporaryArray.push(data);
        }
      }
      this.quizes = temporaryArray;
    }
    if (this.form.value.id_topic) {
      var temporaryArray = [];
      for (let data of this.quizes) {
        if (data.id_topic === this.form.value.id_topic) {
          temporaryArray.push(data);
        }
      }
      this.quizes = temporaryArray;
    }
    if (this.form.value.id_faculty) {
      var temporaryArray = [];
      for (let data of this.quizes) {
        if (data.id_faculty === this.form.value.id_faculty) {
          temporaryArray.push(data);
        }
      }
      this.quizes = temporaryArray;
    }
    if (call == "html") {
      this.resetLectures();
    }
  }

  editForm(data) {
    let tempAfor = [];
    if (data.applicable_for === "1") {
      tempAfor.push(true);
      tempAfor.push(false);
    } else if (data.applicable_for === "2") {
      tempAfor.push(false);
      tempAfor.push(true);
    } else if (data.applicable_for === "3") {
      tempAfor.push(true);
      tempAfor.push(true);
    }
    this.editData = {
      id_topic_lecture_group: data.id_topic_lecture_group,
      lg: data,
    };
    this.form.patchValue({
      id_course_type: data.id_course_type,
      number_of_lecture: data.number_of_lecture,
      amount_of_course: data.amount_of_course,
      id_subject: data.id_subject,
      id_faculty: data.id_faculty,
      video_language:data.video_language,
      applicable_fors: tempAfor,
    });
    this.filterSuSuject();
  
    this.form.patchValue({
      id_sub_subject: data.id_sub_subject,
      id_topic: data.id_topic,
    });
    this.filterTopics();
    this.lectures = this.form.get("lectures") as FormArray;
    this.lectures.removeAt(0);
    for (let lect of data.lectures) {
      let temp = this.createLecture();
      let videos = temp.controls["videos"] as FormArray;
      let video = this.createVideoLink();
      let i = 0;
      for (let vd of JSON.parse(lect.videos)) {
        videos.controls[i].patchValue({ url: vd.url });
        i = i + 1;
      }
      if (lect.id_quiz == null) {
        lect.id_quiz = "-1";
      }
      temp.patchValue({
        topic_to_learn: lect.topic_to_learn,
        duration: lect.duration,
        home_work: lect.home_work,
        id_quiz: lect.id_quiz,
        notes: lect.notes,
        id_topic_lecture_mapping:lect.id_topic_lecture_mapping
      });
      this.lectures.push(temp);
    }
    this.getFacultyDetails();
    // this.filteredQuiz();
    this.openModal();
  }

  removeLectureGroup(data) {
    swal
      .fire({
        title: "",
        text:
          "Deleting an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to remove this lecture group?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#8B0000",
        cancelButtonColor: "#428e63",
        confirmButtonText: "Yes, remove it!",
      })
      .then((result) => {
        if (result.value) {
          this.showLoader = true;
          this.lectureGroupMasterService
            .removeLectureGroup(
              localStorage.getItem("email"),
              data.id_topic_lecture_group
            )
            .subscribe(
              (data) => {
                this.showLoader = false;
                if (data.status_code == 0) {
                  this.getAllLectureGroupFullDetail();
                  this.showAlertComponent(
                    data["status_code"],
                    data["status_message"]
                  );
                } else {
                  this.showAlertComponent(
                    data["status_code"],
                    data["status_message"]
                  );
                }
              },
              (err) => {
                this.showLoader = false;

                swal.fire("Something went wrong. Please try again later.");
              }
            );
        }
      });
  }

  changeStatus(data) {
    var changeStatusId: any;
  
    if (data.id_status == 0) {
      changeStatusId = 1;
    } else {
      changeStatusId = 0;
    }
    if (changeStatusId == 1) {
      swal
        .fire({
          title: "",
          text:
            "Deactivating an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to deactivate this Lecture group?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#8B0000",
          cancelButtonColor: "#428e63",
          confirmButtonText: "Yes, deactivate it!",
        })
        .then((result) => {
          if (result.value) {
            this.showLoader = true;
            this.lectureGroupMasterService
              .changeStatus(
                localStorage.getItem("email"),
                data.id_topic_lecture_group,
                changeStatusId
              )
              .subscribe(
                (data) => {
                  this.showLoader = false;
                  if (data.status_code == 0) {
                    this.showAlertComponent(
                      data["status_code"],
                      data["status_message"]
                    );
                  } else {
                    this.showAlertComponent(
                      data["status_code"],
                      data["status_message"]
                    );
                  }
                },
                (err) => {
                  swal.fire("Something went wrong. Please try again later.");
                }
              );
          } else {
            if (changeStatusId == 0) {
              (<HTMLInputElement>(
                document.getElementById(data.id_topic_lecture_group)
              )).checked = false;
            } else {
              (<HTMLInputElement>(
                document.getElementById(data.id_topic_lecture_group)
              )).checked = true;
            }
          }
        });
    } else {
      this.showLoader = true;
      this.lectureGroupMasterService
        .changeStatus(
          localStorage.getItem("email"),
          data.id_topic_lecture_group,
          changeStatusId
        )
        .subscribe(
          (data) => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.getAllLectureGroupFullDetail();
              this.showAlertComponent(
                data["status_code"],
                data["status_message"]
              );
            } else {
              this.showAlertComponent(
                data["status_code"],
                data["status_message"]
              );
            }
          },
          (err) => {
            this.showLoader = false;

            swal.fire("Something went wrong. Please try again later.");
          }
        );
    }

    //.log(data.id_status)
  }

  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.letureGroups.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      } else if (a[property] > b[property]) {
        return 1 * direction;
      } else {
        return 0;
      }
    });
  }
  closePreviewModal() {
    this.isPreviewModalShow = false;
  }
  previewQuiz() {
    this.isPreviewModalShow = true;
  }

  getFacultyDetails(call?: any) {
    console.log(this.faculty);
    let findFaculty = this.faculty.find(
      (x) => x.id_user === this.form.getRawValue().id_faculty
    );
    console.log(findFaculty);
    //findFaculty)
    if (findFaculty) {
      this.form.patchValue({
        faculty_description: findFaculty.user_info,
      });
    }
    console.log(call);

    this.filteredQuiz();
    if (call == "html") {

      this.resetLectures();
    }
  }

  addRemoveLecture(value) {
    if (value != "") {
      let number = parseInt(this.form.value.number_of_lecture);
      this.lectures = this.form.get("lectures") as FormArray;
      let currentLength = this.lectures.length;
      let requiredLength = 0;
      if (value > currentLength) {
        requiredLength = value - currentLength;
      }

      if (currentLength > value) {
        for (let j = 0; j < currentLength - value; j++) {
          console.log(this.lectures);
          console.log(this.lectures.value[this.lectures.length - 1].id_topic_lecture_mapping);
          this.removeLectureMapping(this.lectures.value[this.lectures.length - 1].id_topic_lecture_mapping);
         
        }
      } else {
        for (let i = 0; i < requiredLength; i++) {
          this.lectures.push(this.createLecture());
        }
      }
    }
  }

  // saveDraft(){
  //   //this.form.value)
  // }
  lectureId: any;
  openUplodAddNotesModal(type, view, lect) {
    this.isUploadNotes = true;
    this.notesType = type;
    this.isViewDomuments = view;
    if (lect) {
      if ("id_topic_lecture_mapping" in lect) {
        this.lectureId = lect.id_topic_lecture_mapping;
      }
    }
  }
  closeUplodAddNotesModal() {
    this.fileUploadForm.reset();
    this.documentFile = undefined;
    this.isUploadNotes = false;
    this.lectureId = "";
  }

  // handleUpload(event){
  //   this.documentFile=event.target.files[0];

  // }
  handleUpload(data, type): void {
    this.showLoader = true;
    if (data && data.response) {
      this.showLoader = false;
      data = JSON.parse(data.response);
      if (data.status) {
        // data.originalName
        if (type == "lecture-thumbnail") {
          this.uploadLectureThumbnail(data.originalName);
        } else {
          this.uploadNotes(data.originalName);
        }
      } else {
        swal.fire(data.msg);
      }
    }
  }

  uploadLectureThumbnail(fileName) {
    this.showLoader = true;
    let json = {
      caller_email: localStorage.getItem("email"),
      file: fileName,
      id_topic_lecture_group: this.editData.id_topic_lecture_group,
    };

    this.lectureGroupMasterService.uploadLectureThumbnail(json).subscribe(
      (data) => {
        this.showLoader = false;
        if (data.status_code == 0) {
          this.closeUplodAddNotesModal();
          this.getLectureGroupFullDetailById();
          this.showAlertComponent(data["status_code"], data["status_message"]);
        } else {
          this.showAlertComponent(data["status_code"], data["status_message"]);
        }
      },
      (err) => {
        this.showLoader = false;

        swal.fire("Something went wrong. Please try again later.");
      }
    );
  }
  uploadNotes(fileName) {
    this.showLoader = true;
    if (this.notesType == 0) {
      let json = {
        caller_email: localStorage.getItem("email"),
        file: fileName,
        id_topic_lecture_group: this.editData.id_topic_lecture_group,
        type: this.fileUploadForm.value.type,
      };

      this.lectureGroupMasterService.addTopicLectureNote(json).subscribe(
        (data) => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.closeUplodAddNotesModal();
            this.getLectureGroupFullDetailById();
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          } else {
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          }
        },
        (err) => {
          this.showLoader = false;

          swal.fire("Something went wrong. Please try again later.");
        }
      );
    } else {
      let json = {
        caller_email: localStorage.getItem("email"),
        file: fileName,
        id_topic_lecture_mapping: this.lectureId,
        type: this.fileUploadForm.value.type,
      };

      this.lectureGroupMasterService.addLectureNote(json).subscribe(
        (data) => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.closeUplodAddNotesModal();
            this.getLectureGroupFullDetailById();
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          } else {
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          }
        },
        (err) => {
          this.showLoader = false;

          swal.fire("Something went wrong. Please try again later.");
        }
      );
    }
  }

  getLectureGroupFullDetailById() {
    this.lectureGroupMasterService
      .getLectureGroupFullDetailById(
        localStorage.getItem("email"),
        this.editData.id_topic_lecture_group
      )
      .subscribe(
        (data) => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.isDocumentsView = true;
            let response = data.lectures_groups[0];
            this.editData = {
              id_topic_lecture_group: response.id_topic_lecture_group,
              lg: response,
            };

            // this.closeModal();
            // swal.fire(data.status_message);
          }
        },
        (err) => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.");
        }
      );
  }

  removeDocuments(id, note, type) {
    if (type == 0) {
      let json = {
        caller_email: localStorage.getItem("email"),
        id_topic_lecture_group: id,
        file: note.name,
      };
      this.lectureGroupMasterService.removeTopicLectureNote(json).subscribe(
        (data) => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.getLectureGroupFullDetailById();
            // this.closeModal();
            swal.fire(data.status_message);
          } else {
            swal.fire(data.status_message);
          }
        },
        (err) => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.");
        }
      );
    } else {
      let json = {
        caller_email: localStorage.getItem("email"),
        id_topic_lecture_mapping: id,
        file: note.name,
      };
      this.lectureGroupMasterService.removeLectureNote(json).subscribe(
        (data) => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.getLectureGroupFullDetailById();
            // this.closeModal();
            swal.fire(data.status_message);
          } else {
            swal.fire(data.status_message);
          }
        },
        (err) => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.");
        }
      );
    }
  }

  save() {
    this.lectureGroupMasterService
      .changeStatus(
        localStorage.getItem("email"),
        this.editData.id_topic_lecture_group,
        0
      )
      .subscribe(
        (data) => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.closeModal();
            this.getAllLectureGroupFullDetail();
          }
        },
        (err) => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.");
        }
      );
  }

  viewLecturePreview(data) {
    console.log("viewLecturePreview")
    this.isPreviewModalShow = true;
    this.editData.id_topic_lecture_group = data.id_topic_lecture_group;
    this.editData.lg = data;
  }
  closeLecturePreviewModal() {
    this.closeModal();
    this.closeUplodAddNotesModal();
    this.isPreviewModalShow = false;
  }

  filteredLecture() {
    this.letureGroups = this.unfilterdLectureGroups;
    console.log(this.filter);
    if (this.filter.id_course_type != "0") {
      var temporaryArray = [];
      for (let data of this.letureGroups) {
        if (data.id_course_type == this.filter.id_course_type) {
          temporaryArray.push(data);
        }
      }
      this.letureGroups = temporaryArray;
      //(this.Questions)
    }
    if (this.filter.id_subject != "0") {
      var temporaryArray = [];
      for (let data of this.letureGroups) {
        if (data.id_subject == this.filter.id_subject) {
          temporaryArray.push(data);
        }
      }
      this.letureGroups = temporaryArray;
      //(this.Questions)
    }
    if (this.filter.id_sub_subject != "0") {
      var temporaryArray = [];
      for (let data of this.letureGroups) {
        if (data.id_sub_subject == this.filter.id_sub_subject) {
          temporaryArray.push(data);
        }
      }
      this.letureGroups = temporaryArray;
      //(this.Questions)
    }
    if (this.filter.id_topic != "0") {
      var temporaryArray = [];
      for (let data of this.letureGroups) {
        if (data.id_topic == this.filter.id_topic) {
          temporaryArray.push(data);
        }
      }
      this.letureGroups = temporaryArray;
      //(this.Questions)
    }
    if (this.filter.id_faculty != "0") {
      var temporaryArray = [];
      for (let data of this.letureGroups) {
        if (data.id_faculty == this.filter.id_faculty) {
          temporaryArray.push(data);
        }
      }
      this.letureGroups = temporaryArray;
      //(this.Questions)
    }
  }

  getFileSource(file) {
    return Helper.imageURL + file;
  }
  downloadURI(uri) {
    var link = document.createElement("a");
    link.download = uri;
    link.href = Helper.imageURL + uri;
    link.click();
  }

  removeLectureMapping(id_topic_lecture_mapping) {
    this.showLoader = true;
    this.lectureGroupMasterService
      .removeLectureMapping(
        localStorage.getItem("email"),
        id_topic_lecture_mapping
      )
      .subscribe(
        (data) => {
          this.showLoader = false;
          if (data.status_code == 0) {
            // this.getAllLectureGroupFullDetail();
            this.lectures.removeAt(this.lectures.length - 1);
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          } else {
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          }
        },
        (err) => {
          this.showLoader = false;

          swal.fire("Something went wrong. Please try again later.");
        }
      );
  }
}
