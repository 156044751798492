import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';

@Injectable()
export class AdminDashBoardService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }
    getAdminDashBoardMaster(email) {
        return this.http.get(this.baseUrl + "/Reports/getAdminDashBoardMaster?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getDashboardGraphData(email, selectedYear, selectedMonth, coeId, sectionId) {
        return this.http.get(this.baseUrl + "/Reports/getDashboardGraphDataForAdmin?caller_email=" + email + "&current_year=" + selectedYear + "&current_month=" + selectedMonth + "&id_coe=" + coeId + "&id_section=" + sectionId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getDashboardGraphDataBySectionId(email, sectionId, selectedYear, selectedMonth) {
        return this.http.get(this.baseUrl + "/Reports/getDashboardGraphDataBySectionId?caller_email=" + email + "&id_section=" + sectionId + "&selected_year=" + selectedYear + "&selected_month=" + selectedMonth).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getScholarPerformanceGraphAdminDashBoard(email) {
        return this.http.get(this.baseUrl + "/Reports/getScholarPerformanceGraphAdminDashBoard?caller_email=" + email+"&examLimit="+5).map((res) => JSON.parse(JSON.stringify(res)));
    }
}