import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class AdminAnalysisReportService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }


    getAllCoe(email) {
        return this.http.get(this.baseUrl + "/Coe/getAllActiveCoe?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllBatches(email) {
        return this.http.get(this.baseUrl + "/Batch/getAllActiveBatch?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllSections(email) {
        return this.http.get(this.baseUrl + "/Section/getAllActiveSection?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    genearateScholarAnalysis(email, sectionId, limit, callFor) {
        return this.http.get(this.baseUrl + "/Reports/genearateScholarAnalysis?caller_email=" + email + "&id_section=" + sectionId + "&minimum_exam_number=" + limit + "&call_for=" + callFor).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getScholarAnaysisReport(email, sectionId, limit, scholarId) {
        return this.http.get(this.baseUrl + "/Reports/getScholarAnaysisReport?caller_email=" + email + "&id_section=" + sectionId + "&minimum_exam_number=" + limit + "&id_scholar=" + scholarId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getScholarReportCard(email, limit, scholarId) {
        return this.http.get(this.baseUrl + "/Reports/scholarReportCard?caller_email=" + email + "&minimum_exam_number=" + limit + "&id_scholar=" + scholarId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getScholarAllReportCard(email, limit, scholarId) {
        return this.http.get(this.baseUrl + "/Reports/scholarAllReportCard?caller_email=" + email + "&minimum_exam_number=" + limit + "&id_scholar=" + scholarId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllScholarReportCard(email, limit, sectionId) {
        return this.http.get(this.baseUrl + "/Reports/getAllScholarReportCard?caller_email=" + email + "&minimum_exam_number=" + limit + "&id_section=" + sectionId).map((res) => JSON.parse(JSON.stringify(res)));
    }
}