import { Component, OnInit } from '@angular/core';
import { ExamMasterService } from './exam-master.service'
import { Router } from '@angular/router'
import { Helper } from '../helper'
import { DatePipe } from '@angular/common'
import swal from 'sweetalert2'
import { CSVExport } from '../Csv-export';
@Component({
  selector: 'app-exam-master',
  templateUrl: './exam-master.component.html',
  styleUrls: ['./exam-master.component.css'],
  providers: [ExamMasterService, Helper, DatePipe]
})
export class ExamMasterComponent implements OnInit {
  showLoader = false;
  Coes = [];
  Batches = [];
  unfilteredBatches = [];
  Sections = [];
  unfilterdSections = [];

  ExamStatus = [];
  ExamTypes = [];
  ExamPatterns = [];
  Subjects = [];
  SubSubjects = [];
  Topics = [];
  unfilteredTopics = [];
  unfilteredSubSubjects = []
  subSubjectId = "0";
  subjectId = "0";
  examTypeId = "0";
  examPatternId = "0";
  coeId = "0";
  batchId = "0";
  sectionId = "0";
  examStatusId = "-1";
  isAddEditExamMadal = false;
  modalHeading = "Add Exam";
  isCheckedAllTopic = "1";
  CheckedTopics = [];
  examWeightage: any;
  isResultConfidential = false
  name: any;
  scheduleDate: any;
  unfilteredExams = [];
  Exams = [];
  ExamTopics = [];
  isShowExamTopicModal = false;
  isShowExamWeghitage = false;
  examId: any;
  purpose: any;
  isExamResultImportModal = false;
  coeName: any;
  batchName: any;
  sectionName: any;
  paternName: any;
  patternType: any;
  examName: any;
  fileInput: any;
  fileReaded: any;
  searchText: any;
  alertDiv = false;
  sucessAlertMessage: any;
  isRole: any;
  examMarks: any;
  examWeightagePattern = /^[0-9]+\.?[0-9]*$/;
  isExamMarkAsDone = false;
  conductedDate: any;
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  Students = [];
  column: string = 'CategoryName';
  direction: number;
  isDesc: boolean = false;
  checkedSubject = [];
  currentDate: any;
  ExamFor=[{'id':1,'name':'JEE Advanced'},{'id':2,'name':'JEE Mains'},{'id':3,'name':'NEET'}];
  examFor="0";

  constructor(private examMasterService: ExamMasterService, private helper: Helper, private router: Router, private datePipe: DatePipe) {
    this.currentDate = new Date();
    // this.testDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
    this.currentDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
    if (localStorage.getItem("isLogin") == "true") {

      this.isRole = localStorage.getItem("id_role");
      this.ExamStatus = this.helper.getExamStatus();
      this.getAllCoe();

    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }


  getAllCoe() {
    this.showLoader = true;
    this.examMasterService.getAllCoe(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.Coes = data.coes
          this.getAllBatches();
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllBatches() {
    this.showLoader = true;
    this.examMasterService.getAllBatches(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          // this.Batches = data.batches
          this.unfilteredBatches = data.batches
          this.getAllSections();
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllSections() {
    this.showLoader = true;
    this.examMasterService.getAllSections(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          // this.Sections = data.sections
          this.unfilterdSections = data.sections
          this.getAllExamMasterData();
        }
        else {
          this.showAlertDiv(data.status_message)
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllExams() {
    this.showLoader = true;
    ////.log(localStorage.getItem("id_section_scholar"))
    var val = localStorage.getItem("id_section_scholar");
    //.log(val.length);
    if (val) {
      //.log(val);
    }
    if (localStorage.getItem("id_section_scholar")) {
      // //.log(localStorage.getItem("id_section_scholar"))
      this.sectionId = localStorage.getItem("id_section_scholar")
      this.examMasterService.getAllExamsBySectionId(localStorage.getItem("email"), this.sectionId).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.Exams = data.exams
            this.unfilteredExams = data.exams
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
          else {
            this.showAlertDiv(data.status_message)
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }
    else {
      this.examMasterService.getAllExams(localStorage.getItem("email")).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.Exams = data.exams
            this.unfilteredExams = data.exams
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }

  }

  filterExam(type?: String) {
    this.Batches = []
    this.Sections = []
    this.Exams = this.unfilteredExams
    //("Exams==>" + this.Exams)
    if (type == 'coe') {
      this.batchId = '0';
      this.sectionId = '0';
    }
    if (type == 'batch') {
      this.sectionId = '0';
    }

    if (this.coeId != "0") {
      this.Subjects = [];
      this.SubSubjects = [];
      this.Topics = [];
      this.Exams = []
      for (let data of this.unfilteredBatches) {
        if (data.id_coe == this.coeId) {
          this.Batches.push(data);
        }
      }

      for (let data of this.unfilteredExams) {
        if (data.id_coe == this.coeId) {
          this.Exams.push(data)
        }
      }
    }
    if (this.batchId != "0") {
      this.Subjects = [];
      this.SubSubjects = [];
      this.Topics = [];
      this.Exams = [];
      for (let data of this.unfilterdSections) {
        if (data.id_batch == this.batchId) {

          this.Sections.push(data);
        }
      }
      for (let data of this.unfilteredExams) {
        if (data.id_batch == this.batchId) {
          this.Exams.push(data)
        }
      }
    }
    if (this.sectionId != "0") {
      this.Subjects = [];
      this.SubSubjects = [];
      this.Topics = [];
      this.Exams = []
      for (let data of this.unfilteredExams) {
        if (data.id_section == this.sectionId) {
          this.Exams.push(data)
        }
      }
    }

    if (this.examStatusId != "-1") {

      var tempArray = this.Exams;
      this.Exams = []
      for (let data of tempArray) {
        if (data.id_status == this.examStatusId) {
          this.Exams.push(data)
        }
      }
    }

  }

  getSyllabusDataBySectionId() {
    this.showLoader = true;
    this.examMasterService.getSyllabusDataBySectionId(localStorage.getItem("email"), this.sectionId).subscribe(
      data => {
        this.showLoader = false;
        this.Subjects = [];
        this.SubSubjects = [];
        this.Topics = [];
        if (data.status_code == "0") {
          this.Subjects = data.subjects;
          this.SubSubjects = data.sub_subjects;
          this.unfilteredSubSubjects = data.sub_subjects;
          this.unfilteredTopics = data.topics;
          this.Topics = data.topics
          if (this.purpose == "Edit") {
            this.getSubSubject();
            this.getTopic();
          }

          this.showAlertComponent(data['status_code'], data['status_message']);
        }
        else {
          swal.fire(data['status_message'])
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  addNew() {
    this.checkedSubject = [];
    this.isAddEditExamMadal = true
    this.modalHeading = "Add Exam";
    this.purpose = "Add";
    if (this.sectionId != "0") {
      this.getSyllabusDataBySectionId()
    }
    //this.showLoader = true;

  }
  getAllExamMasterData() {
    this.showLoader = true;
    this.examMasterService.getAllExamMasterData(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {

          this.ExamTypes = data.exam_type;
          this.ExamPatterns = data.exam_pattern;
          this.getAllExams();
        }
        else {
          this.showAlertDiv(data.status_message)
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }
  getSubSubject() {
    this.isCheckedAllTopic = "1"
    //this.CheckedTopics = []
    this.SubSubjects = []
    //.log("this.unfilteredSubSubjects-->" + this.unfilteredSubSubjects)
    for (let data of this.unfilteredSubSubjects) {
      if (data.id_subject == this.subjectId) {
        this.SubSubjects.push(data);
      }

    }

    this.Topics = []
    for (let data of this.unfilteredTopics) {
      if (data.id_subject == this.subjectId) {
        this.Topics.push(data);
      }
    }
    //.log("this.SubSubjects-->" + this.SubSubjects)
    //("Topics" + this.Topics)
  }
  getTopic() {
    this.isCheckedAllTopic = "1"
    //this.CheckedTopics = []
    if (this.subjectId == "0") {
      swal.fire("Please select first subject.");
    }
    else {
      this.Topics = []
      for (let data of this.unfilteredTopics) {
        if (data.id_sub_subject == this.subSubjectId) {
          this.Topics.push(data);
        }
      }
    }
  }

  selectAllSectionTopic() {
    //this.CheckedTopics = []
    if (this.isCheckedAllTopic == "1") {
      this.isCheckedAllTopic = "0"
      for (let data of this.Topics) {
        var isPresent = false
        for (let checkedTopic of this.CheckedTopics) {
          if (data.id_topic == checkedTopic.id_topic) {
            isPresent = true;
            break;
          }
        }
        if (isPresent == false) {
          let json = {
            "id_topic": data.id_topic,
            "id_subject": data.id_subject,
            "id_sub_subject": data.id_sub_subject,
            "subject_name": data.subject_name,
            "sub_subject_name": data.sub_subject_name,
            "topic_name": data.name
          }
          this.CheckedTopics.push(json)

        }
        var checkSubject = false;
        for (let subject of this.checkedSubject) {
          if (subject.id_subject == data.id_subject) {
            checkSubject = true
            break;
          }
        }
        if (checkSubject == false) {
          let json = {
            "name": data.subject_name,
            "id_subject": data.id_subject,
            "subject_mark": "",
          }
          this.checkedSubject.push(json)
        }

      }

    }
    else {
      this.checkedSubject = [];
      this.isCheckedAllTopic = "1"
      for (let topic of this.Topics) {
        for (let i = 0; i < this.CheckedTopics.length; i++) {
          if (this.CheckedTopics[i].id_topic == topic.id_topic) {
            this.CheckedTopics.splice(i, 1)
          }
        }
      }
    }
  }
  removeDups(array) {
    let unique = {};
    array.forEach(function (i) {
      if (!unique[i]) {
        unique[i] = true;
      }
    });
    return Object.keys(unique);
  }
  checkTopicIschecked(topicId) {
    var isChecked = false;
    if (this.CheckedTopics.length != 0) {
      for (let checkedData of this.CheckedTopics) {
        if (topicId == checkedData.id_topic) {
          isChecked = true;
          break;
        }
        else {
          isChecked = false;
        }

      }

    }
    else {
      return false
    }
    if (isChecked) {
      return true;
    }
    else {
      return false;
    }
  }

  checkUncheckTopic(data) {
    //(data)
    var check = true
    var checkSubject = true
    //.log(this.CheckedTopics.length);
    if (this.CheckedTopics.length != 0) {
      for (let i = 0; i < this.CheckedTopics.length; i++) {
        if (this.CheckedTopics[i].id_topic == data.id_topic) {
          this.CheckedTopics.splice(i, 1)
          this.isCheckedAllTopic = "1"
          check = false
          break;
        }
        else {
          check = true;
        }
        for (var j = 0; j < this.checkedSubject.length; j++) {
          if (this.checkedSubject[j].id_subject == data.id_subject) {
            this.isCheckedAllTopic = "1"
            checkSubject = false
            break;
          }
          else {
            checkSubject = true;
          }
        }
      }

      if (check == true) {
        let json = {
          "id_topic": data.id_topic,
          "id_subject": data.id_subject,
          "id_sub_subject": data.id_sub_subject,
          "subject_name": data.subject_name,
          "sub_subject_name": data.sub_subject_name,
          "topic_name": data.name
        }
        this.CheckedTopics.push(json)
      }
      var element = <HTMLInputElement>document.getElementById(data.id_topic);
      var isChecked = element.checked;
      if (isChecked == true) {
        if (checkSubject == true) {
          let json = {
            "name": data.subject_name,
            "id_subject": data.id_subject,
            "subject_mark": "",
          }
          this.checkedSubject.push(json)
        }
      }

    }
    else {
      let json = {
        "id_topic": data.id_topic,
        "id_subject": data.id_subject,
        "id_sub_subject": data.id_sub_subject,
        "subject_name": data.subject_name,
        "sub_subject_name": data.sub_subject_name,
        "topic_name": data.name
      }
      this.CheckedTopics.push(json);
      var element = <HTMLInputElement>document.getElementById(data.id_topic);
      var isChecked = element.checked;
      if (isChecked == true) {
        let subjectJson = {
          "name": data.subject_name,
          "id_subject": data.id_subject,
          "subject_mark": "",
        }
        this.checkedSubject.push(subjectJson)
      }

    }
    var element = <HTMLInputElement>document.getElementById(data.id_topic);
    var isChecked = element.checked;
    if (isChecked == false) {
      if (this.CheckedTopics.length > 0) {
        this.removeTopics(data)
      }
      else {
        this.checkedSubject = []
      }
    }
  }

  saveExam() {
    var totalSubjectMarks = 0
    for (let data of this.checkedSubject) {
      totalSubjectMarks = totalSubjectMarks + (+data.subject_mark)
    }
    if (!this.name) {
      swal.fire("Please enter name.")
    }
    else if (!this.examTypeId || this.examTypeId == "0") {
      swal.fire("Please select exam type.")
    }
    else if (this.examFor=="0") {
      swal.fire("Please select exam for.")
    }
    else if (!this.examTypeId || this.examTypeId == "0") {
      swal.fire("Please select exam type.")
    }
    else if (!this.examPatternId || this.examPatternId == "0") {
      swal.fire("Please select exam pattern.")
    }
    else if (this.coeId == "0") {
      swal.fire("Please select CoE.")
    }
    else if (this.batchId == "0") {
      swal.fire("Please select batch.")
    }
    else if (this.sectionId == "0") {
      swal.fire("Please select section.")
    }
    else if (!this.scheduleDate) {
      swal.fire("Please enter exam schedule date.")
    }
    else if (this.CheckedTopics.length == 0) {
      swal.fire("Please select at least one topic.")
    }

    else if (!this.examMarks) {
      swal.fire("Please enter exam marks.")
    }
    else if (this.examMarks != totalSubjectMarks) {
      swal.fire("Please enter correct subject marks.")
    }
    else if (!this.examWeightage) {
      swal.fire("Please enter exam weightage.")
    }
    else if ((!(this.examWeightage.match(this.examWeightagePattern)) || parseFloat(this.examWeightage) > 1.0)) {
      swal.fire("Please enter fraction between 0 & 1.");
    }
    else {
      var isResultConfidential: any
      if (this.isResultConfidential) {
        isResultConfidential = "1"
      }
      else {
        isResultConfidential = "0"

      }
      if (this.purpose == "Add") {
        let json = {
          'caller_email': localStorage.getItem("email"),
          'name': this.name,
          'id_exam_type': this.examTypeId,
          'id_exam_pattern': this.examPatternId,
          'id_coe': this.coeId,
          'id_batch': this.batchId,
          'id_section': this.sectionId,
          'scheduled_date': this.scheduleDate,
          'topics': JSON.stringify(this.CheckedTopics),
          'subjects': JSON.stringify(this.checkedSubject),
          'is_result_confidential': isResultConfidential,
          'predictive_analysis_weightage': this.examWeightage,
          'exam_marks': this.examMarks,
          'exam_for':this.examFor
        }
        this.showLoader = true;
        this.examMasterService.addNewExam(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == "0") {
              this.unfilteredExams = data.exams
              this.Exams = data.exams
              this.hideAddEditModal();
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
      else {
        let json = {
          'id_exam': this.examId,
          'caller_email': localStorage.getItem("email"),
          'name': this.name,
          'id_exam_type': this.examTypeId,
          'id_exam_pattern': this.examPatternId,
          'id_coe': this.coeId,
          'id_batch': this.batchId,
          'id_section': this.sectionId,
          'scheduled_date': this.scheduleDate,
          'topics': JSON.stringify(this.CheckedTopics),
          'subjects': JSON.stringify(this.checkedSubject),
          'is_result_confidential': isResultConfidential,
          'predictive_analysis_weightage': this.examWeightage,
          'exam_marks': this.examMarks,
          'exam_for':this.examFor
        }
        this.showLoader = true;
        this.examMasterService.editExam(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == "0") {
              this.unfilteredExams = data.exams
              this.Exams = data.exams
              this.filterExam();
              this.hideAddEditModal();
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
    }

  }

  markDiscussionAsDone(examId,date){

    let json={
      'id_exam':examId,
      'caller_email': localStorage.getItem("email"),
      'discussion_date': date,
      'is_discussion_done':"1"
    }
   this.examMasterService.markExamDone(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == "0") {
              this.getAllExams();
              this.filterExam();
              this.hideAddEditModal();
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
  }

  cleanData() {
    this.name = "";
    this.examPatternId = "0";
    this.examTypeId = "0";
    this.scheduleDate = "";
    this.conductedDate = "";
    this.isResultConfidential = false;
    this.examWeightage = "";
    this.subjectId = "0";
    this.subSubjectId = "0";
    this.examMarks = "";
    this.SubSubjects = [];
    this.Topics = [];
    this.CheckedTopics = [];
  }
  hideAddEditModal() {
    this.isAddEditExamMadal = false;
    this.isExamMarkAsDone = false
    this.isShowExamTopicModal = false;
    this.isExamResultImportModal = false;
    this.isShowExamWeghitage = false;
    this.cleanData();
  }
  viewExamsTopic(data) {
    this.isShowExamTopicModal = true
    this.ExamTopics = data.topics
  }

  editExamWeghitage(data) {
    this.isShowExamWeghitage = true
    this.examId = data.id_exam;
    this.examWeightage = data.predictive_analysis_weightage;
  }

  saveExamWeightage() {
    if (!this.examWeightage) {
      swal.fire("Please enter exam weightage.");
    }
    else if (!(this.examWeightage.match(this.examWeightagePattern) || parseInt(this.examWeightage) > 1)) {
      swal.fire("Please enter fraction between 0 & 1.");
    }
    else {
      this.showLoader = true;
      this.examMasterService.editExamWeightage(localStorage.getItem("email"), this.examId, this.examWeightage).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.unfilteredExams = data.exams
            this.Exams = data.exams
            this.filterExam();
            this.hideAddEditModal();
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }
  }

  callFromForMark:any;
  markAsDone(data,callFrom) {
    this.isExamMarkAsDone = true;
    this.examId = data.id_exam;
    this.callFromForMark=callFrom

  }


  saveConductedDate() {
    if (!this.conductedDate) {
      swal.fire("Please select exam conducted date.")
    }
    else {
      this.showLoader = true;
      // //this.callFromForMark)
      if(this.callFromForMark==='done'){
        this.examMasterService.markExamAsDone(localStorage.getItem("email"), this.examId, this.conductedDate).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == "0") {
              this.unfilteredExams = data.exams
              this.Exams = data.exams
              this.filterExam();
              this.hideAddEditModal();
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
      else{
        this.markDiscussionAsDone(this.examId,this.conductedDate)
      }
     
    }

  }
  editExam(data) {
    this.sectionId = data.id_section;
    this.getSyllabusDataBySectionId()
    this.modalHeading = "Edit Exam"
    this.purpose = "Edit";
    this.isAddEditExamMadal = true;
    this.name = data.exam_name;
    this.checkedSubject = data.subjects
    this.examId = data.id_exam;
    this.examMarks = data.exam_marks;
    this.examPatternId = data.id_exam_pattern;
    this.examTypeId = data.id_exam_type;
    this.coeId = data.id_coe;
    this.batchId = data.id_batch;
    this.examFor = data.exam_for;

    this.scheduleDate = this.datePipe.transform(data.scheduled_date, 'yyyy-MM-dd');
    this.subjectId = data.topics[0].id_subject;
    this.subSubjectId = data.topics[0].id_sub_subject;
    this.examMarks = data.exam_marks;

    this.filterExam();
    this.examWeightage = data.predictive_analysis_weightage;
    if (data.is_result_confidential == "1") {
      this.isResultConfidential = true
    }
    else {
      this.isResultConfidential = false
    }
    // this.isResultConfidential = data.is_result_confidential;

    this.CheckedTopics = data.topics;
    if (this.CheckedTopics.length == this.Topics.length) {
      this.isCheckedAllTopic = "0"
    }
    else {
      this.isCheckedAllTopic = "1"
    }
  }


  exportResult(data) {
    this.Students = data.students;
    this.coeName = data.coe_name;
    this.batchName = data.batch_name;
    this.sectionName = data.section_name;
    this.examName = data.exam_name;
    this.examId = data.id_exam
    this.scheduleDate = this.datePipe.transform(data.scheduled_date, 'yyyy-MM-dd');
    this.isExamResultImportModal = true
  }

  csv2Array(fileInput: any) {
    this.fileInput = fileInput
  }
  saveExportData() {
    this.fileReaded = this.fileInput.target.files[0];
    let reader: FileReader = new FileReader();
    reader.readAsText(this.fileReaded);
    let lines = [];
    reader.onload = (e) => {
      let csv: string = reader.result as string;
      let allTextLines = csv.split(/\r|\n|\r/);
      let headers = allTextLines[0].split(',');


      for (let i = 0; i < allTextLines.length; i++) {

        let data = allTextLines[i].split(',');
        if (data.length === headers.length) {
          let tarr = [];
          for (let j = 0; j < headers.length; j++) {
            tarr.push(data[j]);
          }
          // log each row to see output 
          lines.push(tarr);

        }
      }
      var check = false;
      var checkSubject = false;
      var coeRollNumber = "";
      var subject = ""
      let temporarayArray = []
      for (var i = 1; i < lines.length; i++) {
        let json = {
          "coe_roll_number": lines[i][0],
          "subjects": lines[i][2]
        }
        temporarayArray.push(json);
      }
      if (this.Students.length > 0) {
        for (let result of temporarayArray) {
          check = this.Students.some(e => e.coe_roll_number === result.coe_roll_number)
          checkSubject = this.Students.some(e => e.subjects === result.subjects)
          if (check == false) {
            coeRollNumber = result.coe_roll_number
            break
          }
          if (checkSubject == false) {
            subject = result.subjects
            break
          }
        }

      }
      else {
        check = true
      }
      if (check == true && checkSubject == true) {
        let json = {
          "caller_email": localStorage.getItem("email"),
          "id_exam": this.examId,
          "excel": JSON.stringify(lines)
        }
        this.showLoader = true
        this.examMasterService.exportExamResult(json).subscribe(
          data => {
            this.showLoader = false
            if (data.status_code == "0") {
              this.unfilteredExams = data.exams
              this.Exams = data.exams
              this.filterExam();
              this.hideAddEditModal();
              this.hideAddEditModal();
            }
            else {
              swal.fire(data.status_message)
            }
          },
          err => {
            this.showLoader = false
            swal.fire("Something went wrong, Please try again later")
            this.hideAddEditModal()
          }
        )
      }
      else {
        if (this.Students.length > 0) {
          if (check == false) {
            swal.fire("Coe roll number " + coeRollNumber + " is wrong.")
          }
          else if (checkSubject == false) {
            swal.fire("Subject" + subject + " is wrong.")

          }

        }
        else {
          swal.fire("Please upload correct csv.")
        }

      }
    }
  }

  viewExamResult(data) {
    localStorage.setItem("exam-scholar", JSON.stringify(data.students));
    this.coeName = data.coe_name;
    this.batchName = data.batch_name;
    this.sectionName = data.section_name;
    this.examName = data.exam_name;
    this.examId = data.id_exam
    this.scheduleDate = this.datePipe.transform(data.scheduled_date, 'yyyy-MM-dd');
    // this.examName = 'EE-MAINS_2015 B'
    // let url = "http://edu.dakshana.org/view result/" + this.coeName + "/" + this.batchName + "/" + this.sectionName + "/" + this.examName + "/" + this.scheduleDate + "/" + this.examId + "/" + data.exam_marks
    // let url = "http://localhost:4200/view result/" + this.coeName + "/" + this.batchName + "/" + this.sectionName + "/" + this.examName + "/" + this.scheduleDate + "/" + this.examId + "/" + data.exam_marks
    
    // let url = "http://localhost:4200/view result/" + this.coeName + "/" + this.batchName + "/" + this.sectionName + "/" + this.examName + "/" + this.scheduleDate + "/" + this.examId + "/" + data.exam_marks
    // let width = "width=" + window.innerWidth
    // let height = "height=" + window.innerHeight
    // let frame = width + height
    // window.open(url, "Dakshana", frame);
    this.router.navigate(['./view_result'], { queryParams: { coeName: this.coeName, batchName: this.batchName, sectionName: this.sectionName, examName: this.examName, date: this.scheduleDate, examId: this.examId, examMarks: data.exam_marks } });

  }


  showAlertDiv(message) {
    var that = this
    that.alertDiv = true
    this.sucessAlertMessage = message
    setTimeout(function () {
      that.alertDiv = false
    }, 5000);
  }

  validateExamWeighatge(event) {
    const pattern = /^[0-1]+\.?[0-9]*$/;

    let inputChar = String.fromCharCode(event.charCode);
    //(pattern.test(inputChar));
    if (event.charCode > 31 && (event.charCode < 48 || event.charCode > 57) && event.charCode != 46) {
      event.preventDefault();
    }
  }

  removeTopics(data) {
    //.log(this.CheckedTopics)
    for (var i = 0; i < this.CheckedTopics.length; i++) {
      if (this.CheckedTopics[i].id_topic == data.id_topic) {
        this.CheckedTopics.splice(i, 1)
      }
    }
    for (var i = 0; i < this.checkedSubject.length; i++) {
      var check = false;
      for (let data of this.CheckedTopics) {
        if (this.checkedSubject[i].id_subject == data.id_subject) {
          check = true;
          break;
        }
      }
      if (check == false) {
        this.checkedSubject.splice(i, 1);
      }
    }
    //.log(this.checkedSubject)
  }


  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    //("status" + status);
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }

  downloadExamResultTemplate() {
    var arrayToExport = [];
    if (this.Students.length > 0) {
      for (let data of this.Students) {
        let json = {
          "CoE Roll Number": data.coe_roll_number,
          "Dakshana Roll Number": data.dakshana_roll_number,
          "Subject": data.subjects,
          "Positive Mark": "",
          "Negative Mark": "",
          "Effective Mark": "",
          "id_subject": data.id_subject
        }
        arrayToExport.push(json);
      }
      CSVExport.downloadcsv(arrayToExport, "Exam_Result" + "_" + this.coeName + "_" + this.batchName + "_" + this.sectionName + "_" + this.examName + Math.floor(1000 + Math.random() * 9000));
    }
    else {
      swal.fire("Scholars not present.")
    }
  }
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.Exams.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  }

  removeExam(data) {
    swal.fire({
      title: '',
      text: "Deleting an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to remove this Exam?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.Coes = [];
        this.showLoader = true;
        this.examMasterService.removeExam(localStorage.getItem("email"), data.id_exam).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == "0") {
              this.unfilteredExams = data.exams
              this.Exams = data.exams
              this.filterExam();
              this.hideAddEditModal();
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;

            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
    })
  }

  checkedExams=[];

checkUncheckExam(data) {

  var check = true
 
    if (this.checkedExams.length != 0) {
      for (let i = 0; i < this.checkedExams.length; i++) {
        if (this.checkedExams[i] == data.id_exam) {
          this.checkedExams.splice(i, 1)
          check = false
          break;
        }
        else {
          check = true;
        }
      }

      if (check == true) {
     
        this.checkedExams.push(data.id_exam)
      }
    }
    else {
     
      this.checkedExams.push(data.id_exam)
    }
  
    //this.checkedExams)

}

checkExamIschecked(data) {
  var isChecked = false;
  if (this.checkedExams.length != 0) {
    for (let checkedData of this.checkedExams) {
      if (data.id_exam == checkedData) {
        isChecked = true;
        break;
      }
      else {
        isChecked = false;
      }

    }

  }
  else {
    return false
  }
  if (isChecked) {
    return true;
  }
  else {
    return false;
  }
}

DownloadExamResult(){
  if(this.checkedExams.length==0 && this.sectionId=="0"){
    swal.fire("Please select at least one exam or select section.")
    return
  }
  else{
    let tempExams=[];
    if(this.checkedExams.length==0){
      for(let data of this.Exams){
        tempExams.push(data.id_exam)
      }
    }
    else{
      tempExams=this.checkedExams
    }
    this.showLoader = true;
    let json={
      'caller_email':localStorage.getItem("email"),
      'exams':tempExams
    }
    this.examMasterService.getExamResultDataForExams(JSON.stringify(json)).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          swal.fire(data['status_message'])
          // this.showAlertComponent(data['status_code'], data['status_message']);
        }
        else {
          swal.fire(data['status_message'])
          // this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;

        swal.fire("Something went wrong. Please try again later.")
      }
    )
    }
  }

}
