import { Component, OnInit } from '@angular/core';
import { ScholarDashBoardService } from './scholar-dashboard.service';
import { Router } from '@angular/router';
import { Helper } from '../helper';
import { Chart } from 'chart.js';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
@Component({
  selector: 'app-scholar-dashboard',
  templateUrl: './scholar-dashboard.component.html',
  styleUrls: ['./scholar-dashboard.component.css'],
  providers: [ScholarDashBoardService, Helper]
})
export class ScholarDashboardComponent implements OnInit {
  sucessAlertMessage: any;
  alertDiv = false;
  showLoader = false;
  FeedbackRequest = [];
  facultyId: any;
  facultyName: any;
  subjectName: any;
  isAddModal = false;
  YesNoQuestions = [];
  AgreeDiagreeQuestions = [];
  RatingQuestions = [];
  FeedbackAnswers = [];
  fecultyFeedbackRequestId: any;
  public attendancebarChartLabels: String[];
  public attendancebarChartType: string;
  public attendancebarChartLegend = false;
  public attendancebarChartData: any[]
  Years = [];
  Months = [];
  selectedYear: any;
  selectedMonth: any;
  scholarId: any;
  monthName: any
  Attendance = [];
  examTables=[];
  examTablesHeading=[]
  examTableFormattedData=[];
   public examScorebarChartLabels: String[];
  public examScorebarChartType: string;
  public examScorebarChartLegend = false;
  public examScorebarChartData: any[];
  scholarPerformance=[];
   lineScholarChartData=[];
  lineScholarChartLabels=[];
  // lineChartLegend=false;

  lineScholarExamChartData=[];
  lineScholarExamChartLabels=[];
  lineExamChartLegend=false;
  keys=[];
  predictionType = 0;


  // Line chart data
  public lineChartData: ChartDataSets[] = [];
  public overallLinechartData: ChartDataSets[] = [];
  public scholarAllExamGraphLabels: Label[] = [];
  public lineChartOptions: (ChartOptions) = {
    responsive: false,
    maintainAspectRatio: false,
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];


  constructor(private scholarDashBoardService: ScholarDashBoardService, private router: Router, private helper: Helper) {
    this.Years = helper.getArrayOfYears();
    this.Months = helper.getArrayOfMonths();
    this.selectedYear = this.Years[0];
    this.selectedMonth = (new Date()).getMonth() + 1;
    this.scholarId = localStorage.getItem("id_user")
    if (localStorage.getItem("isLogin") == "true") {
     // this.getAllFeedbackRequest();
      this.generatePerformanceGraph();
      this.getAllExamPerformanceGraph();
      
      
    }
    else {
      this.router.navigateByUrl('/');
    }

   
  }

  ngOnInit() {
  }
  // public lineChartOptions: any = {
  //   scaleShowVerticalLines: false,
  //   responsive: true,
  //   scales: {
  //     yAxes: [{
  //       ticks: {
  //         min: 0,
  //         max: 100,
  //         stepSize: 10
  //       }
  //     }]
  //   }
  // };
  //   public lineChartColors: Array<any> = [
  //   { // grey
  //     backgroundColor: 'rgba(148,159,177,0.2)',
  //     borderColor: 'rgba(123,104,238)',
  //     pointBackgroundColor: 'rgba(123,104,238)',
  //     pointBorderColor: '#fff',
  //     pointHoverBackgroundColor: '#fff',
  //     pointHoverBorderColor: 'rgba(123,104,238)'
  //   },
  //   { // dark grey
  //     backgroundColor: 'rgba(77,83,96,0.2)',
  //     borderColor: 'rgba(255,140,0)',
  //     pointBackgroundColor: 'rgba(255,140,0)',
  //     pointBorderColor: '#fff',
  //     pointHoverBackgroundColor: '#fff',
  //     pointHoverBorderColor: 'rgba(255,140,0)'
  //   },
  //   { // grey
  //     backgroundColor: 'rgba(148,159,177,0.2)',
  //     borderColor: 'rgba(148,159,177,1)',
  //     pointBackgroundColor: 'rgba(148,159,177,0.2)',
  //     pointBorderColor: '#fff',
  //     pointHoverBackgroundColor: '#fff',
  //     pointHoverBorderColor: 'rgba(148,159,177,0.2)'
  //   }
  // ];

  // public lineChartType: string = 'line';
  getAllFeedbackRequest() {
    this.showLoader = true;
    this.scholarDashBoardService.getAllFacultyFeedback(localStorage.getItem("email"), localStorage.getItem("id_user")).subscribe(
      data => {
        this.showLoader = false;
        this.FeedbackRequest = [];
        if (data.status_code == "0") {
          this.FeedbackRequest = data.feedback_request
          this.getDashboardGraphData()
        }
        else {
          this.showAlertDiv(data.status_message)
          this.getDashboardGraphData()
        }
      },
      err => {
        this.showLoader = false;
      }
    )
  }
  showAlertDiv(message) {
    var that = this
    that.alertDiv = true
    this.sucessAlertMessage = message
    setTimeout(function () {
      that.alertDiv = false
    }, 5000);
  }

  viewFeedback(data) {
    this.isAddModal = true;
    this.facultyId = data.id_faculty;
    this.facultyName = data.name;
    this.subjectName = data.subject_name;
    this.fecultyFeedbackRequestId = data.id_faculty_feedback_request
    this.getAllFeedBackQuestion();
  }

  getDashboardGraphData() {
    this.showLoader = true
    this.scholarDashBoardService.getDashboardGraphData(localStorage.getItem("email"), this.scholarId, this.selectedYear, this.selectedMonth, this.monthName).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.Attendance = data.attendance
          this.getScholarAnaysisReport();
          this.generatAttendanceGraph()
        }
      },
      err => {
        this.showLoader = false
      }
    )
  }

  getScholarAnaysisReport() {
    this.showLoader = true
    this.scholarDashBoardService.getScholarAnaysisReport(localStorage.getItem("email"),localStorage.getItem("id_section_scholar") ,this.scholarId).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
           this.examTables = data.exams
          this.examTablesHeading = data.exam_table_heading
          this.scholarPerformance=data.scholar_performance
          this.generateExamTableData();
          this.generateScholarPerformanceGraph();
          this.generatePerformanceGraph();
        }
      },
      err => {
        this.showLoader = false
      }
    )
  }
  generateScholarPerformanceGraph(){
    for (let data of this.scholarPerformance) {
        this.lineScholarChartLabels.push(data.exam_name)
      }
      var highestMarks = []
      var gain_marks = []
      var average_marks = []
      for (let data of this.scholarPerformance) {
        highestMarks.push(parseInt(data.highest_marks))
        gain_marks.push(parseInt(data.gain_marks))
        average_marks.push(parseInt(data.average_marks))
      }
  
      this.lineScholarChartData = [
        { data: gain_marks, label: 'Mark Secured' },
        { data: average_marks, label: 'Class Average' },
        { data: highestMarks, label: 'Class Topper Marks' }
      ];
      this.lineChartLegend=true
  }
   generateExamTableData() {
    //this.examTableFormattedData.push(json);
    var testdata = [];


    for (let exam of this.examTables) {
      //data['test_name'] = exam.exam_name;
      //data['exam_marks'] = exam.total_marks;
      var totalMarks = 0;
        
      for (let ed of exam.exam_deatils) {
        //data['test_name'] = exam.exam_name;
       
        for (let heading of this.examTablesHeading) {
          //.log(exam.exam_name + "--" + (heading.id == ed.id_subject))
          var data = {};
          if (heading.id == ed.id_subject) {
            data['sub_name'] = heading.name;
            data['marks'] = ((+ed.effective_marks) * 100) / ((+exam.total_marks) / exam.exam_deatils.length)
            //.log(data);
          }
          else {
            if(this.examTablesHeading.length != exam.exam_deatils.length){
            data['sub_name'] = heading.name;
            data['marks'] = 0;
            //.log(data);
            }
          }
          if(data['sub_name']){
            this.examTableFormattedData.push(data);
          }
        }
        //.log(data);
      }
      
    }
    this.keys = Object.keys(this.examTableFormattedData[0])
    //.log(this.examTableFormattedData)
    this.generateExamScoreGraph();
  }

  generateExamScoreGraph(){
    this.examScorebarChartLabels = [];
    this.examScorebarChartData = []
    for (let data of this.examTables) {
      this.lineScholarExamChartLabels.push(data.exam_name)
    }
    for (let exam of this.examTablesHeading) {
        var subjectGainMarkPercentage=[]
        var json={};
      for(let subject of this.examTableFormattedData){
        
        if(subject.sub_name==exam.name){
         subjectGainMarkPercentage.push(subject.marks+"");
        json={
            data:subjectGainMarkPercentage,label:exam.name
          }
        }
         
      }
       this.lineScholarExamChartData.push(json);
    }
    this.lineExamChartLegend=true
    //.log(this.lineScholarExamChartData)
}
  generatAttendanceGraph() {
    this.attendancebarChartLegend = true;
    this.attendancebarChartLabels = [];
    this.attendancebarChartData = []
    for (let data of this.Attendance) {
      this.attendancebarChartLabels.push(data.month)
    }
    var classData = []
    for (let data of this.Attendance) {
      classData.push(parseInt(data.class_attendence))
    }
    var hostelData = []
    for (let data of this.Attendance) {
      hostelData.push(parseInt(data.hostel_attendence))
    }
    this.attendancebarChartData = [
      { data: classData, label: 'Class' },
      { data: hostelData, label: 'Hostel' }
    ];
  }

  getAllFeedBackQuestion() {
    this.showLoader = true;
    this.scholarDashBoardService.getAllFeedbackQuestion(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.YesNoQuestions = data.yes_no
          this.AgreeDiagreeQuestions = data.agree_disagree
          this.RatingQuestions = data.rating
        }
        else {
          this.showAlertDiv(data.status_message)
        }
      },
      err => {
        this.showLoader = false;
      }
    )
  }

  getSelectedAnswer(answerId, answerPoint, questionId, answerTypeId, maxMarks) {
    for (var i = 0; i < this.FeedbackAnswers.length; i++) {
      if (this.FeedbackAnswers[i].id_question == questionId) {
        this.FeedbackAnswers.splice(i, 1);
        break;
      }
    }
    let json = {
      "id_feedback_item": questionId,
      "id_faculty": this.facultyId,
      "answer_point": answerPoint,
      "max_mark": maxMarks,
      "id_section": localStorage.getItem("id_section_scholar"),
      "id_faculty_feedback_request": this.fecultyFeedbackRequestId,
      "id_scholar": localStorage.getItem("id_user"),
      "id_answer_point": answerId,
      "id_answer_type": answerTypeId
    }
    this.FeedbackAnswers.push(json)
  }

  hideAddEditModal() {
    this.isAddModal = false;
  }

  save() {
    this.showLoader = true;
    // var totalGivenPoint=0;
    // var maxGivenPoint=0;
    // var YesNomaxGivenPoint=0;
    // var AgrreDisagreeGivenPoint=0;
    // var RatingGivenPoint=0;
    // for(let data of this.FeedbackAnswers){
    //  totalGivenPoint=totalGivenPoint+parseInt(data.answer_point)
    // }
    // for(let data of this.FeedbackAnswers){
    //   maxGivenPoint=maxGivenPoint+parseInt(data.max_mark)
    //  }
    let json = {
      "caller_email": localStorage.getItem("email"),
      "feedback_answer": JSON.stringify(this.FeedbackAnswers)
    }
    this.scholarDashBoardService.addFacultyFeedbackRequestByScholar(json).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.showAlertDiv(data.status_message)
          this.hideAddEditModal();
          this.getAllFeedbackRequest();
        }
        else {
          this.showAlertDiv(data.status_message)
        }
      },
      err => {
        this.showLoader = false;
      }
    )
  }

  // performance chart
  CourseTypeTable = [];
  chart:any;
  generatePerformanceGraph()
  {
    this.showLoader = true;
    this.scholarDashBoardService.getScholarAnaysisReport1(localStorage.getItem("email"), localStorage.getItem("id_section_scholar"), "5", localStorage.getItem("id_user")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.CourseTypeTable = data.course_type_tabel   
          
          this.showPerformaceGraph(0);     
          
          
        }
        else {
          // swal.fire(data.status_message)
        }
      },
      err => {

      }
    )
  }

 selectedCourseType:any;
 showPerformaceGraph(index){
  this.predictionType = index;
  console.log("showPerformaceGraph predictionType="+index);   
  this.selectedCourseType = this.CourseTypeTable[index];
  var data1 = this.selectedCourseType.gain_marks;
  var data2 = parseInt(this.selectedCourseType.main_marks)- parseInt(this.selectedCourseType.gain_marks);

  this.chart = new Chart('canvas', {
    type: 'doughnut',
    data: {
      labels: ['total mark','Data2'],
      datasets: [
        { 
          data: [data1,data2],
          backgroundColor: ['rgba(255, 0, 0, 1)','rgba(255, 0, 0, 0.1)'],
          fill: false,

        },

      ]
    },
    options: {
      legend: {
        display: false
      },
      tooltips:{
        enabled:false
      }
    }
  });
 
 }

 subjects = [];
 subjects1 = [];
 showOtherGraph()
 {
  console.log("In showOtherGraph");
  this.subjects = this.overall_subjects;  
  this.subjects1 = this.overall_subjects ;  
  var color = ['#00FFFF','#FFFF00','#FFC0CB','#FFA500','#800080'];
  var index = 0;
  this.subjects.forEach(sub => {
    sub.is_checked = '0';
    sub.color=color[index];
    index++;
  });
  var index1 = 0;
  this.subjects1.forEach(sub => {
    sub.is_check = '0';
    sub.color=color[index1];
    index1++;
  });
  this.subjects[0]['is_checked']='1';
  this.subjects1[0]['is_check']='1';
  this.subjectId = this.subjects[0]['id_subject'];
  this.getStrengthTopicData();
  this.getWeakTopicData();
  if(this.isShowscholarTimeUsageGraphData)
  this.getTimeUsageData();
 

  // this.showLoader = true;
  // this.scholarDashBoardService.getAllSubject(localStorage.getItem("email")).subscribe(
  //   data => {
  //     this.showLoader = false;
  //     if (data.status_code == "0") {
      
  //     }
  //     else {
  //       // swal.fire(data.status_message)
  //     }
  //   },
  //   err => {

  //   }
  // )
 }


 stregthTopic = [];
 weakTopic = [];
 subjectId:any;
 getStrengthTopicData()
 {
  this.showLoader = true;

  var selectedSubject = [];
  this.subjects.forEach(sub => {
    console.log("is_checked="+sub['is_checked']);
      if(sub.is_checked == '1')
      {
        var v = {'id_subject':0};
        v.id_subject = sub.id_subject;
     
        selectedSubject.push(v);
      }      
  });

  this.stregthTopic = [];
  let json = {
    "caller_email": localStorage.getItem("email"),
    "subjects": JSON.stringify(selectedSubject),
    "id_user": this.scholarId
  }

  this.scholarDashBoardService.getStrengthAndWeakTopicsListBySubjectId(json).subscribe(
    data => {
      this.showLoader = false;
      if (data.status_code == "0") {
        var topic_data = data.topics ;  
        topic_data.forEach(topic => {
          
          if(topic['topic_percentage']>= 80)
          {
             topic.subject_letter =  topic['subject_name'].charAt(0);
             this.stregthTopic.push(topic);
          }
          // else{
          //    this.weakTopic.push(topic);
          // }

         
        });

        // if(!this.isShowscholarTimeUsageGraphData)
        // this.getTimeUsageData();
        // if(this.weakTopic.length<=0)
        //   this.getWeakTopicData();

      }
      else {
        // swal.fire(data.status_message)
      }
    },
    err => {

    }
  )
 }

 getWeakTopicData()
 {
  this.showLoader = true;
  var selectedSubject = [];
  this.subjects1.forEach(sub => {
      if(sub.is_check == '1')
      {
        var v = {'id_subject':0};
        v.id_subject = sub.id_subject;
        selectedSubject.push(sub);
      }      
  });
  this.weakTopic = [];
  let json = {
    "caller_email": localStorage.getItem("email"),
    "subjects": JSON.stringify(selectedSubject),
    "id_user": this.scholarId
  }

  this.scholarDashBoardService.getStrengthAndWeakTopicsListBySubjectId(json).subscribe(
    data => {
      this.showLoader = false;
      if (data.status_code == "0") {
        var topic_data = data.topics ;  
        topic_data.forEach(topic => {
          if(topic['topic_percentage']>= 80)
          {
            //  this.stregthTopic.push(topic);
          }else{
            //get topic subject initial letter
            topic.subject_letter =  topic['subject_name'].charAt(0);
            this.weakTopic.push(topic);
          }
        });
       
      }
      else {
        // swal.fire(data.status_message)
      }
    },
    err => {

    }
  )
 }


 checkStrengthSubject(data){
   console.log("data.is_checked="+data.is_checked);
   if(data.is_checked == '1') 
      data.is_checked = '0'
   else
      data.is_checked = '1'
      
  this.getStrengthTopicData();
 }

 checkWeakSubject(data){
  console.log("data.is_checked="+data.is_checked);
  if(data.is_check == '1') 
     data.is_check = '0'
  else
     data.is_check = '1'
  this.getWeakTopicData();
 }

 getColor(id_subject)
 {
   var color = '';
   this.subjects.forEach(sub => {
       if(sub.id_subject == id_subject)
       {
         color = sub.color;
       }
   });
   return color;
 }

 time_data = [];
 total_time = 0;
 total_spend_time = 0;
 chartTypeTimeUsage: string = 'horizontalBar';
 public barChartLegendTimeUsage = true;

 public barChartDataTimeUsage = [];
 public barChartLabelsTimeUsage: string[] = [];
 isShowscholarTimeUsageGraphData = true;
 getTimeUsageData(){

  var dataCorrectQuestions = [];
  var dataPartiallyCorrectQuestions = [];
  var dataInCorrectQuestions = [];
  var dataUnattemptedQuestions = [];
  var dataLable = [];
  
  let json = {
    "caller_email": localStorage.getItem("email"),
    "subjects": JSON.stringify(this.subjects),
    "id_user": this.scholarId
  }

  this.scholarDashBoardService.getTimeUsageData(json).subscribe(
    data => {
      this.showLoader = false;
      if (data.status_code == "0") {

       this.subjects = data.subjects;
       this.total_time= data.total_exam_duration;
       this.total_spend_time = data.total_time_spend;

       this.subjects.forEach(subject => {

        dataLable.push(subject.name);
        dataCorrectQuestions.push(subject.correct_question_time_spend.toPrecision(2));
        dataPartiallyCorrectQuestions.push(subject.partial_correct_question_time_spend.toPrecision(2));
        dataInCorrectQuestions.push(subject.incorrect_question_time_spend.toPrecision(2));
        dataUnattemptedQuestions.push(subject.unattempted_question_time_spend.toPrecision(2));
  
      });
  
      console.log("dataLable length=" + dataLable.length);
      console.log("dataCorrectQuestions length=" + dataCorrectQuestions.length);
  
      this.barChartDataTimeUsage = [
        { data: dataCorrectQuestions, label: '% Time Spent in correct questions', stack: 'questions' },
        { data: dataPartiallyCorrectQuestions, label: '% Time Spent in partially correct questions', stack: 'questions' },
        { data: dataInCorrectQuestions, label: '% Time Spent in incorrect questions', stack: 'questions' },
        { data: dataUnattemptedQuestions, label: '% Time Spent in unattempted questions', stack: 'questions' }
      ];
  
  
      this.barChartLabelsTimeUsage = dataLable;
      this.isShowscholarTimeUsageGraphData = true;
  



       
      }
      
    },
    err => {

    }
  )
 }

 // commulative graph data
 scholarAllExamGraphData = [];
 examHeading = [];

 getAllExamPerformanceGraph()
 {
  this.showLoader = true;
  this.scholarDashBoardService.getAllExamPerformanceGraph(localStorage.getItem("email"), localStorage.getItem("id_user"),'').subscribe(
    (data: any) => {
      this.showLoader = false;
      this.scholarAllExamGraphData = [];
      this.scholarAllExamGraphLabels = [];
      if (data["status_code"] == 0) {
        let ExamScoreGrpah = data.exam_score_graph
        this.examTables = data.exam_score_graph
        this.examHeading = data.exam_headaing




        // var dataArray = []
        for (let data of ExamScoreGrpah) {
          this.scholarAllExamGraphLabels.push(data.exam_name);
        }
        console.log("scholarAllExamGraphLabels=" + JSON.stringify(this.scholarAllExamGraphLabels))
        // let json = {
        //   data: dataArray,
        //   label: "Score %"
        // }
        // this.scholarAllExamGraphData.push(json);
        // console.log(JSON.stringify(this.scholarAllExamGraphData))
        // this.isScholarAllExamGraph = true;
        // this.generateExamTableData();

        this.arrangeCommulativeGraphData();

      


      }
      else {
        // swal(data["status_message"]);
      }
    },
    err => {

    }
  )
 }

 overall_subjects = [];
 graph_subjects = [];

  showCommulativeGraph = false;
  arrangeCommulativeGraphData() {
    var all_subject = [];
    this.graph_subjects = [];
    // First find total subject across all exam
    this.examTables.forEach(exam => {
      var flag = false;
      exam.subjects.forEach(subject => {

        all_subject.forEach(sub => {
          if (sub.id_subject == subject.id_subject) {
            flag = true;
          }
        });

        if (!flag) {
          var s = { 'id_subject': subject.id_subject, 'name': subject.subject_name, 'data': [], 'topper_data': [], 'average_data': [] }
          all_subject.push(s);
          var s1 = { 'id_subject': subject.id_subject, 'name': subject.subject_name, 'is_checked': 0 }
          this.graph_subjects.push(s1);
        }

      });

    });

    // add overall to for radio button
    var o = { 'id_subject': 0, 'name': 'Overall', 'is_checked': 1 };
    this.graph_subjects.push(o)


    this.overall_subjects = all_subject;

    //for each subject get marks of exam ,if exam is not for the subject assign 0
    this.overall_subjects.forEach(subject => {
      var data = [];
      var top_data = [];
      var average_data = [];
      this.examTables.forEach(exam => {

        var mark = 0;
        var top_mark = 0;
        var average_mark = 0;
        exam.subjects.forEach(sub => {
          if (sub.id_subject == subject.id_subject) {
            mark = sub.marks_percentage;
            top_mark = sub.topper_marks_percentage;
            average_mark = sub.average_marks_percentage;
          }
        });
        data.push(mark);
        top_data.push(top_mark);
        average_data.push(average_mark);

      });
      subject.data = data;
      subject.topper_data = top_data;
      subject.average_data = average_data;
    });

    console.log("first overall_subjects=" + JSON.stringify(this.overall_subjects));

    this.lineChartData = [];
    this.overallLinechartData = [];

    // now for overall - me and topper,average
    var d = { data: [], label: 'Me' }
    var d1 = { data: [], label: 'Topper' }
    var d2 = { data: [], label: 'Average' }

    var myData = [];
    var topperData = [];
    var averageData = [];
    this.examTables.forEach(exam => {
      myData.push(exam.marks_percentage);
      topperData.push(exam.topper_mark_percentage);
      averageData.push(exam.average_mark_percentage);
    });
    d.data = myData;
    d1.data = topperData;
    d2.data = averageData;
    this.lineChartData.push(d);
    this.lineChartData.push(d1);
    this.lineChartData.push(d2);
    this.overallLinechartData = this.lineChartData;
    // this.isScholarAllExamGraph = true;
    console.log("finalGraphData=" + JSON.stringify(this.lineChartData));

    this.showOtherGraph();
  }

selectSubject(subject) {

    this.graph_subjects.forEach(subject => {
      subject.is_checked = 0;
    });

    subject.is_checked = 1;

    if (subject.name == 'Overall') {
      this.lineChartData = this.overallLinechartData;
    } else {
      var selectedSubject = { data: [], label: '' };
      var topper_data = [];
      var average_data = [];
      console.log("overall_subjects=" + JSON.stringify(this.overall_subjects));
      this.overall_subjects.forEach(element => {
        if (element.id_subject == subject.id_subject) {

          selectedSubject.data = element.data;
          selectedSubject.label = "Me " + element.name;
          topper_data = element.topper_data;
          average_data = element.average_data;
        }
      });

      this.lineChartData = [];
      this.lineChartData.push(selectedSubject);
      this.lineChartData.push({ data: topper_data, label: 'Topper ' + selectedSubject.label });
      this.lineChartData.push({ data: average_data, label: 'Average ' + selectedSubject.label });
      console.log("finalGraphData=" + JSON.stringify(this.lineChartData));
    }
    

  }



}
