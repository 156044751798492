import { Component, OnInit } from '@angular/core';
import { BookMasterService } from '../book-master/book-master.service'
import swal from 'sweetalert2'
import { DatePipe } from '@angular/common'
import { float } from 'html2canvas/dist/types/css/property-descriptors/float';
import { CSVExport } from '../Csv-export';
@Component({
  selector: 'app-library-report',
  templateUrl: './library-report.component.html',
  styleUrls: ['./library-report.component.css'],
  providers: [BookMasterService, DatePipe]
})
export class LibraryReportComponent implements OnInit {
  UnfilterdBookCopies = [];
  BookCopies = [];
  searchText: any;

  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  showLoader = false;
  IssueReturn = true;
  lostBooks = 0
  damagedBooks = 0
  totalFine = 0
  fromDate: any;
  toDate: any;
  column: string = 'CategoryName';
  direction: number;
  isDesc: boolean = false;
  constructor(private bookMasterService: BookMasterService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getAllAllotedAndDamagedCopiesInLibrary()
  }

  getAllAllotedAndDamagedCopiesInLibrary() {

    this.showLoader = true;
    this.UnfilterdBookCopies = []
    this.BookCopies = [];
    this.bookMasterService.getAllAllotedAndDamagedCopiesInLibrary(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.UnfilterdBookCopies = data.book_copies
          this.filteredBookCopies()
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  filteredBookCopies() {
    if (this.IssueReturn) {
      this.BookCopies = [];
      for (let data of this.UnfilterdBookCopies) {
        if (data.book_status == '17' || data.book_status == '21') {
          this.BookCopies.push(data)
        }
      }
    }
    else {
      this.BookCopies = [];
      this.totalFine = 0
      this.damagedBooks = 0
      this.lostBooks = 0
      for (let data of this.UnfilterdBookCopies) {
        if (data.book_status == '19' || data.book_status == '20') {
          if (data.book_status == '19') {
            this.damagedBooks = this.damagedBooks + 1
          }
          else {
            this.lostBooks = this.lostBooks + 1
          }
          this.totalFine = this.totalFine + parseFloat(data.fine)
          this.BookCopies.push(data)
        }
      }
    }
  }
  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }

  hideAlert(val) {
    this.showAlert = val;
  }

  IssueReturnReport(value) {
    this.fromDate = '';
    this.toDate = '';
    this.searchText = ''
    this.IssueReturn = value
    this.filteredBookCopies()
  }

  filterCopiesByDate() {
    //(this.fromDate)
    if (this.fromDate && this.toDate) {
      this.BookCopies = [];
      for (let data of this.UnfilterdBookCopies) {
        if (this.datePipe.transform(data.issued_date, 'yyyy-MM-dd') >= this.fromDate && this.datePipe.transform(data.issued_date, 'yyyy-MM-dd') <= this.toDate) {
          this.BookCopies.push(data)
        }
      }
    }
    else if (this.fromDate) {
      this.BookCopies = [];
      for (let data of this.UnfilterdBookCopies) {
        //(this.datePipe.transform(data.issued_date, 'yyyy-MM-dd'))
        if (this.datePipe.transform(data.issued_date, 'yyyy-MM-dd') >= this.fromDate) {
          this.BookCopies.push(data)
        }
      }
    }
    else if (this.toDate) {
      this.BookCopies = [];
      for (let data of this.UnfilterdBookCopies) {
        if (this.datePipe.transform(data.issued_date, 'yyyy-MM-dd') <= this.toDate) {
          this.BookCopies.push(data)
        }
      }
    }
  }

  export() {
    var arrayToExport = [];
    if (this.BookCopies.length > 0) {
      for (let data of this.BookCopies) {
        var json = {}
        if (this.IssueReturn) {
          if (data.return_date != "0000-00-00 00:00:00") {
            json = {
              "Book Title": data.book_title_name,
              "Author": data.author,
              "Edition": data.book_edition_name,
              "Accession Code": data.accession_code,
              "Alloted To": data.scholar_name,
              "CoE Roll No.": data.coe_roll_number,
              "Issued Date": data.issued_date,
              "Returned Date": data.return_date,


            }

          }
          else {
            json = {
              "Book Title": data.book_title_name,
              "Author": data.author,
              "Edition": data.book_edition_name,
              "Accession Code": data.accession_code,
              "Alloted To": data.scholar_name,
              "CoE Roll No.": data.coe_roll_number,
              "Issued Date": data.issued_date,
              "Returned Date": "",


            }
          }

          arrayToExport.push(json);
        }
        else {
          var bookLost = ""
          if (data.book_status == "20") {
            bookLost = "Yes"
          }
          else {
            bookLost = "No"
          }
          var bookDamaged = ""
          if (data.book_status == "19") {
            bookDamaged = "Yes"
          }
          else {
            bookDamaged = "No"
          }
          let json = {
            "Book Title": data.book_title_name,
            "Author": data.author,
            "Edition": data.book_edition_name,
            "Accession Code": data.accession_code,
            "Alloted To": data.scholar_name,
            "CoE Roll No.": data.coe_roll_number,
            "Issued Date": data.issued_date,
            "Returned Date": data.return_date,
            "Book Lost": data.bookLost,
            "Book Damaged": data.bookDamaged,
            "Fine Collected": data.comment,
            "Comment": data.return_date,



          }

          arrayToExport.push(json);
        }




      }
      CSVExport.downloadcsv(arrayToExport, "Library Reports");
    }
  }

  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.BookCopies.sort(function (a, b) {
        if (a[property] < b[property]) {
            return -1 * direction;
        }
        else if (a[property] > b[property]) {
            return 1 * direction;
        }
        else {
            return 0;
        }
    });
}
}
