import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FeedMasterService } from './feedback-master.service'
import swal from 'sweetalert2'
@Component({
    moduleId: module.id,
    selector: 'feedback-master',
    templateUrl: 'feedback-master.component.html',
    styleUrls: ['feedback-master.component.scss'],
    providers: [FeedMasterService]
})
export class FeedbackMasterComponent {
    isAddModal: boolean
    modelHeadelLabel: any
    FeedbackAnswerType: any
    feedbackAnswerTypeId: any
    showLoader: boolean
    FeedbackQuestion: any
    questionId: any;
    modalPurpose: any
    question: any
    searchText: any
    alertDiv = false;
    sucessAlertMessage: any;
    showAlert: boolean;
    alertMsg: any;
    alertType = "";
    column: string = 'CategoryName';
    direction: number;
    isDesc: boolean = false;
    constructor(private router: Router, private feedMasterService: FeedMasterService) {
        this.isAddModal = false
        this.modelHeadelLabel = "Add New Question"
        this.feedbackAnswerTypeId = "0"
        this.modalPurpose = "Add"
        this.FeedbackAnswerType = []
        this.FeedbackQuestion = []
        this.showLoader = false
        if (localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "1") {
            this.getAllFeedbackAnswerType();

        }
        else {
            this.router.navigateByUrl('/');
        }
    }


    getAllFeedbackAnswerType() {
        this.showLoader = true;
        this.feedMasterService.getAllFeedbackAnswerType(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == "0") {

                    this.FeedbackAnswerType = data.feedback_type
                    this.getAllFeedbackQuestion();
                }
                else {
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }
    getAllFeedbackQuestion() {
        this.showLoader = true;
        this.feedMasterService.getAllFeedbackQuestion(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == "0") {
                    this.FeedbackQuestion = data.feedback_questions
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
                else {
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }
    addNew() {
        this.clearData()
        this.modalPurpose = "Add"
        this.isAddModal = true
    }

    hideAddEditModal() {
        this.modalPurpose = "Add"
        this.isAddModal = false
        this.clearData()
    }

    save() {
        if (!this.question) {
            swal.fire("Please enter question.")
        }
        else if (!this.feedbackAnswerTypeId || this.feedbackAnswerTypeId == "0") {
            swal.fire("Please select type.")
        }
        else {
            if (this.modalPurpose == "Add") {
                let json = {
                    "caller_email": localStorage.getItem("email"),
                    "question": this.question,
                    "id_answer_type": this.feedbackAnswerTypeId
                }
                this.showLoader = true;
                this.feedMasterService.addNewQuestion(json).subscribe(
                    data => {
                        this.showLoader = false;
                        if (data.status_code == "0") {
                            this.FeedbackQuestion = []
                            this.hideAddEditModal()
                            this.FeedbackQuestion = data.feedback_questions
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.showLoader = false;
                        this.hideAddEditModal()
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }
            else {
                let json = {
                    "id_feedback_item": this.questionId,
                    "caller_email": localStorage.getItem("email"),
                    "question": this.question,
                    "id_answer_type": this.feedbackAnswerTypeId
                }
                this.showLoader = true;
                this.feedMasterService.editQuestion(json).subscribe(
                    data => {
                        this.showLoader = false;
                        if (data.status_code == "0") {
                            this.FeedbackQuestion = []
                            this.hideAddEditModal()
                            this.FeedbackQuestion = data.feedback_questions
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.showLoader = false;
                        this.hideAddEditModal()
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }

        }
    }

    editQuestion(data) {
        this.modalPurpose = "Edit"
        this.question = data.question;
        this.feedbackAnswerTypeId = data.id_answer_type
        this.questionId = data.id_feedback_item
        this.isAddModal = true
    }

    clearData() {
        this.question = "";
        this.modalPurpose = "Add"
        this.feedbackAnswerTypeId = "0"
    }

    removequestion(data) {
        swal.fire({
            title: '',
            text: "Are you sure you want to remove this question?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#428e63',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.value) {
                this.showLoader = true;

                this.feedMasterService.removeQuestion(data.id_feedback_item, localStorage.getItem("email")).subscribe(
                    data => {
                        this.FeedbackQuestion = []
                        this.showLoader = false;
                        if (data.status_code == 0) {
                            this.FeedbackQuestion = data.feedback_questions;
                            this.hideAddEditModal()
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.hideAddEditModal()
                        this.showLoader = false;
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }
        })
    }

    showAlertDiv(message) {
        var that = this
        that.alertDiv = true
        this.sucessAlertMessage = message
        setTimeout(function () {
            that.alertDiv = false
        }, 5000);
    }

    showAlertComponent(status, messege) {
        this.showAlert = true;
        this.alertMsg = messege;
        //("status" + status);
        if (parseInt(status) == 0) {
            this.alertType = "success";
        }
        else {
            this.alertType = "failure";
        }
    }
    hideAlert(val) {
        this.showAlert = val;
    }
    sort(property) {
        this.isDesc = !this.isDesc; //change the direction    
        this.column = property;
        let direction = this.isDesc ? 1 : -1;

        this.FeedbackQuestion.sort(function (a, b) {
            if (a[property] < b[property]) {
                return -1 * direction;
            }
            else if (a[property] > b[property]) {
                return 1 * direction;
            }
            else {
                return 0;
            }
        });
    }
}
