import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScheduleMasterService } from './schedule-master.service'
import { DatePipe } from '@angular/common'
import swal from 'sweetalert2'
@Component({
  selector: 'app-schedule-master',
  templateUrl: './schedule-master.component.html',
  styleUrls: ['./schedule-master.component.css'],
  providers: [ScheduleMasterService, DatePipe]
})
export class ScheduleMasterComponent implements OnInit {
  Coes = []
  searchText: any;
  Batches = []
  unfilterdBatches = [];
  unfilteredSections = []
  Sections = []
  coeId = "0"
  batchId = "0"
  sectionId = "0"
  showLoader = false;
  sectionName: any;
  isModifySchedule = false;
  isAddDailyClassScheduleModal = false
  classStartTime: any
  classEndTime: any;
  isMon = false
  isTue = false
  isWed = false
  isThu = false
  isFri = false
  isSat = false
  isSun = false
  selectedTime: "";
  DailyClassSchedule = [];
  Holidays = [];
  dailClassScheduleAddPurpose = "Add"
  dailyClassScheduleId: any;
  holidayStartDate: any;
  holidayEndDate: any;
  holidayReason: any;
  isAddHoliDayModal = false
  isAddScheduleTemplate = false
  TeplatesData = [];
  SaveTeplatesData = [];
  sheduleStartDate: any;
  sheduleEndDate: any;
  isApplyScheduleTemplate = false;
  DailySchedules = []
  isShowMainTable = true
  topicCompletedHours = 0
  bacthStartDate: any;
  classesScheduleUntil: any;
  totalHoursSchedule: any;
  isComplitionChange: any;
  classTypeId = "1"
  lectureDate: any;
  lectureEndTime: any;
  lectureStartTime: any;
  ClassTypes = [];
  isTopicCompleted = false;
  topicName: any;
  scheduletopicId: any;
  sheduleId: any;
  topicId: any;
  lectureDeviationReason = "0"
  isComplitionChangeLectureDeviation = false
  Topics = [];
  TempTopics = []
  Faculty = [];
  facultyId = "0"
  isAddExtraLecture = false
  adhoc = "0"
  isAdhocModal = false
  TempScheduleTopics = []
  ScheduleTopics = []
  isAddOtherLecture = false
  otherLectureScheduleId = "0"
  lectureFacultyName: any;
  lectureTopicName: any;
  otherLectureScheduleEndTime: any;
  otherLectureScheduleDate: any;
  otherLectureScheduleStartTime: any;
  totalScheduleHoursCompleted: any;
  uncompletedHours: any;
  filerEndDate: any;
  filerStartDate: any;
  UnfilteredDailySchedule = [];
  tableRowIndex = 0
  minDate: any;
  alertDiv = false;
  sucessAlertMessage: any
  coeName: any;
  batchName: any;
  isRole: any;
  TempScheduleTemplate = []
  deviationMaxDate: any;
  isPrint = false;
  batchEndDate: any;
  previousLectureStatus: any;
  scholarSectionId: any;
  unscheduleHour: any;
  isAllDays: any;
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  column: string = 'CategoryName';
  direction: number;
  isDesc: boolean = false;
  constructor(private scheduleMasterService: ScheduleMasterService, private router: Router, private datePipe: DatePipe) {
    var tomorrow = new Date();
    this.deviationMaxDate = tomorrow.toISOString().split('T')[0];
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.minDate = tomorrow.toISOString().split('T')[0];
    if (localStorage.getItem("isLogin") == "true") {
      this.isRole = localStorage.getItem("id_role")
      this.getSectionSyllabusMasterData();



    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  getSectionSyllabusMasterData() {
    this.showLoader = true
    this.scheduleMasterService.getAllMasterData(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.Coes = data.coes
          //this.Batches = data.batches
          this.unfilterdBatches = data.batches
          //this.Sections = data.sections
          this.unfilteredSections = data.sections
          this.ClassTypes = data.class_type
          if (localStorage.getItem("id_section_scholar")) {
            //(localStorage.getItem("id_section_scholar"));
            this.scholarSectionId = localStorage.getItem("id_section_scholar");
            this.getSectionName()
          }
          else {
            if (this.sectionId != "0") {
              this.getDailySectionSchedule();
            }

          }

        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false
      }
    )
  }

  getDailySectionSchedule() {
    this.showLoader = true
    this.DailySchedules = [];
    this.UnfilteredDailySchedule = [];
    this.scheduleMasterService.getDailySectionSchedule(localStorage.getItem("email"), this.sectionId).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.unscheduleHour = data.unschedule_hour
          this.DailySchedules = data.schedule
          var tempDailyClassSchedule = data.schedule
          if (this.isRole == "4") {
            this.DailySchedules = []
            for (let data of tempDailyClassSchedule) {
              if (data.id_faculty == localStorage.getItem("id_user")) {
                this.DailySchedules.push(data)
              }
            }
          }
          this.TempScheduleTemplate = data.schedule_template;
          this.UnfilteredDailySchedule = data.schedule
          this.Faculty = data.faculty
          this.TempTopics = data.faculty_topic
          this.TempScheduleTopics = data.schedule_topic
          this.getScheduleData();
        }
        else {
          //.log("under else")
          this.DailySchedules = [];
          this.DailyClassSchedule = [];
          this.Holidays = [];
          this.SaveTeplatesData = [];
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false
      }
    )
  }

  getTopicByFaculty() {
    this.Topics = []
    for (let data of this.TempTopics) {
      if (data.id_faculty == this.facultyId) {
        this.Topics.push(data)
      }
    }
  }
  getScheduleData() {
    this.classesScheduleUntil = "";
    this.totalHoursSchedule = 0;
    this.totalScheduleHoursCompleted = 0;
    this.uncompletedHours = 0;
    this.classesScheduleUntil = this.DailySchedules[this.DailySchedules.length - 1].class_date
    for (let data of this.DailySchedules) {
      var dateOne = new Date("12-25-2012 " + data.start_time);
      var dateSecond = new Date("12-25-2012 " + data.end_time);
      var diffMs = Math.abs(dateSecond.getTime() - dateOne.getTime()); // milliseconds between now & Christmas
      var diffMins = Math.round((diffMs) / 60000); // minutes
      var hours = Math.floor(diffMins / 60)
      var minutes = diffMins % 60
      var tempHours = hours + "." + minutes
      this.totalHoursSchedule = this.totalHoursSchedule + parseFloat(tempHours)
      if (data.id_status == "8" || data.id_status == "9" || data.id_status == "10") {
        var dateOne = new Date("12-25-2012 " + data.start_time);
        var dateSecond = new Date("12-25-2012 " + data.end_time);
        var diffMs = Math.abs(dateSecond.getTime() - dateOne.getTime()); // milliseconds between now & Christmas
        var diffMins = Math.round((diffMs) / 60000); // minutes
        var hours = Math.floor(diffMins / 60)
        var minutes = diffMins % 60
        var tempHours = hours + "." + minutes
        this.totalScheduleHoursCompleted = this.totalScheduleHoursCompleted + parseFloat(tempHours)
      }
      else {
        var dateOne = new Date("12-25-2012 " + data.start_time);
        var dateSecond = new Date("12-25-2012 " + data.end_time);
        var diffMs = Math.abs(dateSecond.getTime() - dateOne.getTime()); // milliseconds between now & Christmas
        var diffMins = Math.round((diffMs) / 60000); // minutes
        var hours = Math.floor(diffMins / 60)
        var minutes = diffMins % 60
        //("minutes-->" + minutes)
        var tempHours = hours + "." + minutes
        //(tempHours)
        this.uncompletedHours = (this.uncompletedHours + parseFloat(tempHours))
        //("this.uncompletedHours--" + this.uncompletedHours.toFixed(2));
      }
    }

  }
  filter(purpose, batchStartDate) {
    this.Sections = []
    this.Batches = []
    this.DailySchedules = [];
    this.UnfilteredDailySchedule = [];
    if (purpose == "bacth") {
      this.batchId = "0"
    }
    this.sectionId = "0"
    for (let data of this.unfilterdBatches) {
      if (data.id_coe == this.coeId) {
        this.Batches.push(data)
      }
    }
    if (this.batchId != "0") {
      for (let data of this.unfilteredSections) {
        if (data.id_batch == this.batchId) {
          this.bacthStartDate = batchStartDate

          this.Sections.push(data)
        }
      }
      for (let data of this.Batches) {
        if (data.id_batch == this.batchId) {
          this.bacthStartDate = data.start_date
          this.minDate = this.datePipe.transform(data.start_date, 'yyyy-MM-dd');
          this.batchEndDate = data.end_date
          //(this.batchEndDate)
          //this.batchEndDate=this.datePipe.transform(this.batchEndDate, 'yyyy-MM-dd');
          break
        }
        else {
          this.bacthStartDate = "";
        }
      }
    }
  }

  getSectionName() {
    //(this.Sections)
    for (let data of this.Sections) {
      if (data.id_section == this.sectionId) {
        this.sectionName = data.name;
        this.coeName = data.coe_name;
        this.batchName = data.batch_name;
        this.sectionId = data.id_section
        this.getDailySectionSchedule();
        break
      }
    }
    if (this.isModifySchedule == true) {
      this.getDailyClassSchedule();
    }
  }

  modifySchedule() {
    if (this.sectionId == "0") {
      swal.fire("Please select section first.")
    }
    else {
      this.isModifySchedule = true
      this.getDailyClassSchedule();
    }

  }

  getDailyClassSchedule() {
    this.showLoader = true;
    this.DailyClassSchedule = []
    this.DailySchedules = []
    this.Holidays = [];
    this.SaveTeplatesData = [];
    this.scheduleMasterService.getDailyClassSchedule(localStorage.getItem("email"), this.sectionId).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.DailyClassSchedule = data.daily_class_schedule
          if (data.max_date != "") {
            this.minDate = data.max_date
          }
          this.getHolidays();
        }
        else {

          this.getHolidays();
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
      }
    )
  }
  getHolidays() {
    this.showLoader = true;
    this.scheduleMasterService.getHolidays(localStorage.getItem("email"), this.sectionId).subscribe(
      data => {
        this.showLoader = false;
        this.Holidays = [];
        if (data.status_code == "0") {
          this.Holidays = data.holidays
          this.getSectionFacultyTemplate()
        }
        else {
          this.getSectionFacultyTemplate()
          //this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
      }
    )
  }
  hideAddEditModal() {
    this.isAddDailyClassScheduleModal = false
    this.isAddHoliDayModal = false
    this.isAddScheduleTemplate = false
    this.isComplitionChange = false;
    this.isAddExtraLecture = false;
    this.isAdhocModal = false
    this.isComplitionChangeLectureDeviation = false
    this.isAddOtherLecture = false;
    this.isApplyScheduleTemplate = false;
    this.clearData();
  }
  addDailyClassSchedule() {
    this.isAddDailyClassScheduleModal = true
  }

  saveDailyClassSchedule() {

    if (!this.classStartTime) {
      swal.fire("Please select class start time.")
    }
    else if (!this.classEndTime) {
      swal.fire("Please select class end time.")
    }
    else if (this.timeValidation(this.classStartTime, this.classEndTime) == false) {
      swal.fire("Time schedule is not correct.")
    }
    else if (!this.isMon && !this.isTue && !this.isWed && !this.isThu && !this.isFri && !this.isSat && !this.isSun) {
      swal.fire("Please select at least one day.")
    }
    else {
      var json = {
        "caller_email": localStorage.getItem("email"),
        "is_mon": "0",
        "is_tue": "0",
        "is_wed": "0",
        "is_thu": "0",
        "is_fri": "0",
        "is_sat": "0",
        "is_sun": "0",
        "class_start_time": this.classStartTime,
        "class_end_time": this.classEndTime,
        "id_section": this.sectionId
      }
      if (this.isMon) {
        json["is_mon"] = "1"
      }
      if (this.isTue) {
        json["is_tue"] = "1"
      }
      if (this.isWed) {
        json["is_wed"] = "1"
      }
      if (this.isThu) {
        json["is_thu"] = "1"
      }
      if (this.isFri) {
        json["is_fri"] = "1"
      }
      if (this.isSat) {
        json["is_sat"] = "1"
      }
      if (this.isSun) {
        json["is_sun"] = "1"
      }
      var dateOne = new Date("12-25-2012 " + this.classStartTime);
      var dateSecond = new Date("12-25-2012 " + this.classEndTime);
      var diffMs = Math.abs(dateSecond.getTime() - dateOne.getTime()); // milliseconds between now & Christmas
      var diffMins = Math.round((diffMs) / 60000); // minutes
      json["minutes"] = diffMins
      if (this.dailClassScheduleAddPurpose == "Add") {
        this.showLoader = true;
        this.scheduleMasterService.addDailyClassSchedule(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == "0") {
              this.DailyClassSchedule = data.daily_class_schedule
              this.hideAddEditModal();
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
          }
        )
      }
      else {
        json["id_daily_class_schedule"] = this.dailyClassScheduleId
        this.showLoader = true;
        this.scheduleMasterService.editDailyClassSchedule(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == "0") {
              this.DailyClassSchedule = data.daily_class_schedule
              this.hideAddEditModal();
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
          }
        )
      }

    }
  }

  clearData() {
    this.dailClassScheduleAddPurpose = "Add"
    this.isAddDailyClassScheduleModal = false
    this.classStartTime = ""
    this.classEndTime = ""
    this.isMon = false
    this.isTue = false;
    this.isWed = false;
    this.isThu = false;
    this.isFri = false;
    this.isSat = false;
    this.isSun = false;
    this.facultyId = "0";
    this.otherLectureScheduleId = "0"
    this.holidayReason = "";
    this.holidayEndDate = "";
    this.holidayEndDate = "";

  }

  editDailyClassSchedule(data) {
    this.clearData();
    this.isAddDailyClassScheduleModal = true;
    this.classStartTime = data.class_start_time;
    this.classEndTime = data.class_end_time;
    if (data.is_mon == "1") {
      this.isMon = true
    }
    if (data.is_tue == "1") {
      this.isTue = true
    }
    if (data.is_wed == "1") {
      this.isWed = true
    }
    if (data.is_thu == "1") {
      this.isThu = true
    }
    if (data.is_fri == "1") {
      this.isFri = true
    }
    if (data.is_sat == "1") {
      this.isSat = true
    }
    if (data.is_sun == "1") {
      this.isSun = true
    }
    this.checkDays();
    this.dailyClassScheduleId = data.id_daily_class_schedule
    this.dailClassScheduleAddPurpose = "Edit"
  }

  removeDailyClassSchedule(data) {
    swal.fire({
      title: '',
      text: "Are you sure you want to remove this schedule?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        let json = {
          "caller_email": localStorage.getItem("email"),
          "id_daily_class_schedule": data.id_daily_class_schedule,
          "id_section": this.sectionId
        }
        this.scheduleMasterService.removeDailyClassSchedule(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == "0") {
              this.DailyClassSchedule = data.daily_class_schedule
              this.hideAddEditModal();
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
          }
        )
      }
    })
  }

  addHoliday() {
    this.isAddHoliDayModal = true
  }

  saveHoliday() {
    if (!this.holidayStartDate) {
      swal.fire("Please select holiday start date.")
    }
    else if (!this.holidayEndDate) {
      swal.fire("Please select holiday end date.")
    }
    else if (this.holidayStartDate > this.holidayEndDate) {
      swal.fire("Dates are not valid.")
    }
    else if (!this.holidayReason) {
      swal.fire("Please enter reason.")
    }
    else {
      let json = {
        "caller_email": localStorage.getItem("email"),
        "start_date": this.holidayStartDate,
        "end_date": this.holidayEndDate,
        "reason": this.holidayReason,
        "id_section": this.sectionId
      }

      this.showLoader = true;
      this.scheduleMasterService.addHoliday(json).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.Holidays = data.holidays
            this.hideAddEditModal();
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
        }
      )
    }

  }

  addTemplate() {
    //(this.DailyClassSchedule);
    if (this.DailyClassSchedule.length == 0) {
      swal.fire("First add daily class schedule.")
    }
    else {
      this.showLoader = true;
      this.scheduleMasterService.getMasterDataForTemplate(localStorage.getItem("email"), this.sectionId).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.isAddScheduleTemplate = true
            this.TeplatesData = data.master_data_for_template
          }
          else {
            swal.fire(data.status_message)
          }
        },
        err => {
          this.showLoader = false;
        }
      )
    }

  }

  getSectionFacultyTemplate() {
    this.showLoader = true;
    this.scheduleMasterService.getMasterDataForTemplate(localStorage.getItem("email"), this.sectionId).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.TeplatesData = data.master_data_for_template
          if (this.TempScheduleTemplate.length > 0) {
            for (let data of this.TempScheduleTemplate) {
              for (let template of this.TeplatesData) {
                if (data.start_time == template.start_time && data.day == "1") {
                  template.id_mon_faculty = data.id_faculty
                }
                else if (data.start_time == template.start_time && data.day == "2") {
                  template.id_tue_faculty = data.id_faculty
                }
                else if (data.start_time == template.start_time && data.day == "3") {
                  template.id_wed_faculty = data.id_faculty
                }
                else if (data.start_time == template.start_time && data.day == "4") {
                  template.id_thu_faculty = data.id_faculty
                }
                else if (data.start_time == template.start_time && data.day == "5") {
                  template.id_fri_faculty = data.id_faculty
                }
                else if (data.start_time == template.start_time && data.day == "6") {
                  template.id_sat_faculty = data.id_faculty
                }
              }
            }
            this.SaveTeplatesData = this.TeplatesData
          }
        }
        else {
          swal.fire(data.status_message)
        }
      },
      err => {
        this.showLoader = false;
      }
    )
  }
  saveScheduleTemplate() {
    var isEmpty = false;
    for (let data of this.TeplatesData) {
      //(JSON.stringify(data))
      if ((data.id_mon_faculty == "0" && data.is_mon == "1") || (data.id_tue_faculty == "0" && data.is_tue == "1") || (data.id_wed_faculty == "0" && data.is_wed == "1") || (data.id_thu_faculty == "0" && data.is_thu == "1") || (data.id_fri_faculty == "0" && data.is_fri == "1") || (data.id_sat_faculty == "0" && data.is_sat == "1") || (data.id_sun_faculty == "0" && data.is_sun == "1")) {
        isEmpty = true
        break
      }
    }
    if (isEmpty == true) {
      swal.fire("All fields are required")
    }
    else {
      this.SaveTeplatesData = this.TeplatesData
      //(this.SaveTeplatesData)
      this.hideAddEditModal();
    }

  }

  applyTemplate() {
    if (this.SaveTeplatesData.length == 0) {
      swal.fire("Please create template first.")
    }
    else {
      this.isApplyScheduleTemplate = true
    }

  }

  applyScheduleTemplate() {
    if (!this.sheduleStartDate) {
      swal.fire("Please select schedule  start date.")
    }
    else if (this.minDate > this.sheduleStartDate) {
      swal.fire("Please select a schedule start date after the last 'completed' lecture in the current schedule.")
    }
    else if (!this.sheduleStartDate) {
      swal.fire("Please select schedule  end date.")
    }
    else if (this.sheduleStartDate > this.sheduleEndDate) {
      swal.fire("Dates are not valid.")
    }
    else {
      var template = []
      for (let data of this.SaveTeplatesData) {
        if (data.is_mon == "1") {
          let json = {
            "start_time": data.start_time,
            "end_time": data.end_time,
            "day": "1",
            "id_faculty": data.id_mon_faculty,
          }
          template.push(json)
        }
        if (data.is_tue == "1") {
          let json = {
            "start_time": data.start_time,
            "end_time": data.end_time,
            "day": "2",
            "id_faculty": data.id_tue_faculty,
          }
          template.push(json)
        }
        if (data.is_wed == "1") {
          let json = {
            "start_time": data.start_time,
            "end_time": data.end_time,
            "day": "3",
            "id_faculty": data.id_wed_faculty,
          }
          template.push(json)
        }
        if (data.is_thu == "1") {
          let json = {
            "start_time": data.start_time,
            "end_time": data.end_time,
            "day": "4",
            "id_faculty": data.id_thu_faculty,
          }
          template.push(json)
        }
        if (data.is_fri == "1") {
          let json = {
            "start_time": data.start_time,
            "end_time": data.end_time,
            "day": "5",
            "id_faculty": data.id_fri_faculty,
          }
          template.push(json)
        }
        if (data.is_sat == "1") {
          let json = {
            "start_time": data.start_time,
            "end_time": data.end_time,
            "day": "6",
            "id_faculty": data.id_sat_faculty,
          }
          template.push(json)
        }
        if (data.is_sun == "1") {
          let json = {
            "start_time": data.start_time,
            "end_time": data.end_time,
            "day": "7",
            "id_faculty": data.id_sun_faculty,
          }
          template.push(json)
        }

      }
      let json = {
        "caller_email": localStorage.getItem("email"),
        "id_section": this.sectionId,
        "start_date": this.sheduleStartDate,
        "end_date": this.sheduleEndDate,
        "teplate": JSON.stringify(template)
      }
      this.showLoader = true;
      this.scheduleMasterService.applyScheduleTemplate(json).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.unscheduleHour = data.unschedule_hour
            this.DailySchedules = data.schedule
            this.UnfilteredDailySchedule = data.schedule
            this.TempScheduleTemplate = data.schedule_template;
            this.UnfilteredDailySchedule = data.schedule
            this.Faculty = data.faculty
            this.TempTopics = data.faculty_topic
            this.TempScheduleTopics = data.schedule_topic
            this.getScheduleData();
            this.hideAddEditModal();
            this.isModifySchedule = false
            this.isShowMainTable = true
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
        }
      )

    }
  }

  getCompletedHour(startTime, endTime) {
    var dateOne = new Date("12-25-2012 " + startTime);
    var dateSecond = new Date("12-25-2012 " + endTime);
    var diffMs = Math.abs(dateSecond.getTime() - dateOne.getTime()); // milliseconds between now & Christmas
    var diffMins = Math.round((diffMs) / 60000); // minutes
    var hours = Math.floor(diffMins / 60);
    var minutes = diffMins % 60;

    if (minutes == 0) {
      return hours
    }
    else {
      if (minutes < 10) {
        return parseFloat(hours + ".0" + minutes)
      }
      else {
        return parseFloat(hours + "." + minutes)
      }

    }
  }

  getTopicRemainingHours(totalHours, topicId, index) {
    var remainingHours = 0;
    if (index == 0) {
      return parseInt(totalHours) - this.topicCompletedHours
    }
    else {
      for (var i = 0; i <= index; i++) {
        if (topicId == this.DailySchedules[i].id_topic)
          remainingHours = remainingHours + this.getCompletedHour(this.DailySchedules[i].start_time, this.DailySchedules[i].end_time)
      }
      return parseInt(totalHours) - remainingHours
    }

  }

  openCompletionModal(data, index) {
    this.tableRowIndex = index
    this.isComplitionChange = true;
    this.isTopicCompleted = false;
    this.classTypeId = "1"
    this.topicName = data.topic_name;
    this.lectureStartTime = data.start_time;
    this.lectureEndTime = data.end_time;
    this.lectureDate = data.class_date;
    this.scheduletopicId = data.id_topic;
    this.sectionId = data.id_section
    this.sheduleId = data.id_schedule
    this.topicId = data.id_topic
  }

  saveComplitionChange() {
    if (this.classTypeId == "0") {
      swal.fire("Please select class type.")
    }
    else {

      var idTopicStatus = "1";
      if (this.isTopicCompleted) {
        idTopicStatus = "3";
      }
      else {
        idTopicStatus = "1";
      }
      var json: any;
      if (this.isTopicCompleted) {
        var check = false
        for (var i = this.tableRowIndex - 1; i >= 0; i--) {
          if (this.DailySchedules[i].id_topic == this.topicId) {
            if (this.DailySchedules[i].id_status == "7") {
              check = true;
              break;
            }
          }
        }
        if (check == true) {
          swal.fire("Please mark previous lectures of this topic first.");
        }
        else {
          var completedHours = this.getTotalCompletedHour(this.tableRowIndex, this.sectionId, this.topicId)
          json = {
            "caller_email": localStorage.getItem("email"),
            "id_schedule": this.sheduleId,
            "id_section": this.sectionId,
            "id_topic": this.topicId,
            "start_time": this.lectureStartTime,
            "end_time": this.lectureEndTime,
            "date": this.lectureDate,
            "id_lecture_type": this.classTypeId,
            "is_topic_completed": this.isTopicCompleted,
            "completed_hours": completedHours,
            "id_status": "8",
            "completion_comment": "On Time"
          }
          this.showLoader = true;
          this.scheduleMasterService.saveComplitionChange(json).subscribe(
            data => {
              this.showLoader = false;
              if (data.status_code == "0") {
                this.unscheduleHour = data.unschedule_hour
                this.DailySchedules = data.schedule
                this.UnfilteredDailySchedule = data.schedule
                this.isComplitionChange = false
                this.getScheduleData()
              }
              else {
                swal.fire(data['status_message'])
                // this.showAlertComponent(data['status_code'], data['status_message']);
              }
            },
            err => {
              this.showLoader = false;
            }
          )
        }

      }
      else {
        json = {
          "caller_email": localStorage.getItem("email"),
          "id_schedule": this.sheduleId,
          "id_section": this.sectionId,
          "id_topic": this.topicId,
          "start_time": this.lectureStartTime,
          "end_time": this.lectureEndTime,
          "date": this.lectureDate,
          "id_lecture_type": this.classTypeId,
          "is_topic_completed": this.isTopicCompleted,
          "id_status": "8",
          "completion_comment": "On Time"
        }
        this.showLoader = true;
        this.scheduleMasterService.saveComplitionChange(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == "0") {
              this.unscheduleHour = data.unschedule_hour
              this.DailySchedules = data.schedule
              this.UnfilteredDailySchedule = data.schedule
              this.isComplitionChange = false
              this.getScheduleData()
            }
            else {
              swal.fire(data['status_message'])
              // this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
          }
        )
      }

    }
  }


  getTotalCompletedHour(index, sectionId, topicId) {
    var completedHour = 0;
    for (var i = 0; i <= index; i++) {
      if (this.DailySchedules[i].id_section == sectionId && this.DailySchedules[i].id_topic == topicId) {
        var hours = this.getCompletedHour(this.DailySchedules[i].start_time, this.DailySchedules[i].end_time)
        completedHour = completedHour + hours;
      }
    }
    return completedHour
  }
  openCompletionModalForLectureDeviation(data, i) {
    this.tableRowIndex = i
    this.isTopicCompleted = false;
    this.classTypeId = "1"
    this.isComplitionChangeLectureDeviation = true;
    this.topicName = data.topic_name;
    this.lectureStartTime = data.start_time;
    this.lectureEndTime = data.end_time;
    this.lectureDate = data.class_date;
    this.scheduletopicId = data.id_topic;
    this.sectionId = data.id_section
    this.sheduleId = data.id_schedule
    this.topicId = data.id_topic
  }


  saveComplitionChangeForLectureDevation() {
    if (this.lectureDeviationReason == "0") {
      swal.fire("Please select class type.")
    }
    else if (this.lectureDeviationReason != 'Lecture not required') {
      if (!this.lectureStartTime) {
        swal.fire("Please select start time.")
      }
      else if (!this.lectureEndTime) {
        swal.fire("Please select end time.")
      }
      else if (this.timeValidation(this.lectureStartTime, this.lectureEndTime) == false) {
        swal.fire("Selected time schedule is not correct.")
      }
      else if (!this.lectureDate) {
        swal.fire("Please select date.")
      }
      else {

        var idTopicStatus = "1";
        if (this.isTopicCompleted) {
          idTopicStatus = "3";
        }
        else {
          idTopicStatus = "1";
        }
        var statusId = "9"
        if (this.lectureDeviationReason == "Lecture not required") {
          statusId = "10"
        }
        var json: any;
        if (this.isTopicCompleted) {
          var check = false
          for (var i = this.tableRowIndex - 1; i >= 0; i--) {
            if (this.DailySchedules[i].id_topic == this.topicId) {
              if (this.DailySchedules[i].id_status == "7") {
                check = true;
                break;
              }
            }
          }
          if (check == true) {
            swal.fire("Please mark previous lectures of this topic first.");
          }
          else {
            var completedHours = this.getTotalCompletedHour(this.tableRowIndex, this.sectionId, this.topicId)

            json = {
              "caller_email": localStorage.getItem("email"),
              "id_schedule": this.sheduleId,
              "id_section": this.sectionId,
              "id_topic": this.topicId,
              "start_time": this.lectureStartTime,
              "end_time": this.lectureEndTime,
              "date": this.lectureDate,
              "id_lecture_type": this.classTypeId,
              "is_topic_completed": this.isTopicCompleted,
              "completed_hours": completedHours,
              "id_status": statusId,
              "lecture_date": this.lectureDate + " " + this.lectureStartTime + " to " + this.lectureEndTime,
              "completion_comment": this.lectureDeviationReason
            }
            this.showLoader = true;
            this.scheduleMasterService.saveComplitionChange(json).subscribe(
              data => {
                this.showLoader = false;
                if (data.status_code == "0") {
                  this.unscheduleHour = data.unschedule_hour
                  this.DailySchedules = data.schedule
                  this.UnfilteredDailySchedule = data.schedule
                  this.hideAddEditModal()
                  this.getScheduleData()
                }
                else {
                  swal.fire(data['status_message']);
                  //this.showAlertComponent(data['status_code'], data['status_message']);
                }
              },
              err => {
                this.showLoader = false;
              }
            )
          }

        }
        else {
          json = {
            "caller_email": localStorage.getItem("email"),
            "id_schedule": this.sheduleId,
            "id_section": this.sectionId,
            "id_topic": this.topicId,
            "start_time": this.lectureStartTime,
            "end_time": this.lectureEndTime,
            "date": this.lectureDate,
            "id_lecture_type": this.classTypeId,
            "is_topic_completed": this.isTopicCompleted,
            "id_status": statusId,
            "lecture_date": this.lectureDate + " " + this.lectureStartTime + " to " + this.lectureEndTime,
            "completion_comment": this.lectureDeviationReason
          }
          this.showLoader = true;
          this.scheduleMasterService.saveComplitionChange(json).subscribe(
            data => {
              this.showLoader = false;
              if (data.status_code == "0") {
                this.unscheduleHour = data.unschedule_hour
                this.DailySchedules = data.schedule
                this.UnfilteredDailySchedule = data.schedule
                this.hideAddEditModal()
                this.getScheduleData()
              }
              else {
                swal.fire(data['status_message']);
              }
            },
            err => {
              this.showLoader = false;
            }
          )
        }

      }
    }
    else {
      let json = {
        "id_status": "10",
        "caller_email": localStorage.getItem("email"),
        "id_schedule": this.sheduleId,
        "id_section": this.sectionId,
        "id_topic": this.topicId,
        "completion_comment": this.lectureDeviationReason
      }

      this.showLoader = true;
      this.scheduleMasterService.lectureNotRequired(json).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.DailySchedules = data.schedule
            this.UnfilteredDailySchedule = data.schedule
            this.hideAddEditModal()
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
        }
      )
    }

  }

  addExtraLecture() {
    if (this.sectionId == "0") {
      swal.fire("Please select section first.")

    }
    else {
      this.isAddExtraLecture = true
    }

  }

  saveExtraLecture() {
    if (this.facultyId == "") {
      swal.fire("Please select faculty.")
    }
    else if (this.topicId == "0" || !this.topicId) {
      swal.fire("Please select topic.")
    }
    else if (!this.lectureStartTime) {
      swal.fire("Please select start time.")
    }
    else if (!this.lectureEndTime) {
      swal.fire("Please select end  time.")
    }
    else if (this.timeValidation(this.lectureStartTime, this.lectureEndTime) == false) {
      swal.fire("Time schedule is not correct.")
    }
    else if (!this.lectureDate) {
      swal.fire("Please select lecture date.")
    }
    else {
      let json = {
        "caller_email": localStorage.getItem("email"),
        "id_section": this.sectionId,
        "date": this.lectureDate,
        "id_faculty": this.facultyId,
        "id_topic": this.topicId,
        "start_time": this.lectureStartTime,
        "end_time": this.lectureEndTime,
        "id_lecture_type": "2"
      }

      this.showLoader = true;
      this.scheduleMasterService.addExtraLecture(json).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.DailySchedules = data.schedule
            this.UnfilteredDailySchedule = data.schedule
            this.hideAddEditModal()
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
        }
      )
    }
  }


  setAdhoc(data) {
    this.isAdhocModal = true
    this.topicName = data.topic_name;
    this.lectureStartTime = data.start_time;
    this.lectureEndTime = data.end_time;
    this.lectureDate = data.class_date;
    this.scheduletopicId = data.id_topic;
    this.sectionId = data.id_section
    this.sheduleId = data.id_schedule
    this.topicId = data.id_topic
    //("section Id=" + this.sectionId)
  }
  saveAdhoc() {
    if (this.adhoc == "0") {
      swal.fire("Please select adhoc")
    }
    else {
      let json = {
        "caller_email": localStorage.getItem("email"),
        "id_schedule": this.sheduleId,
        "id_section": this.sectionId,
        "adhoc": this.adhoc,
      }

      this.showLoader = true;
      this.scheduleMasterService.setAdhoc(json).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.DailySchedules = data.schedule
            this.UnfilteredDailySchedule = data.schedule
            this.hideAddEditModal()
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
        }
      )
    }
  }

  addOtherLecture(data) {
    this.otherLectureScheduleDate = "";
    this.otherLectureScheduleStartTime = "";
    this.otherLectureScheduleEndTime = "";
    this.isAddOtherLecture = true;
    this.sheduleId = data.id_schedule;
    this.lectureDate = data.class_date;
    this.previousLectureStatus = data.id_status;
    this.lectureStartTime = data.start_time;
    this.lectureEndTime = data.end_time
    this.lectureFacultyName = data.faculty_name;
    this.lectureTopicName = data.topic_name;
  }
  getScheduleTopics() {
    this.ScheduleTopics = []
    for (let data of this.TempScheduleTopics) {
      if (data.id_faculty == this.facultyId) {
        this.ScheduleTopics.push(data)
      }
    }
  }

  getScheduleDetail() {
    for (let data of this.ScheduleTopics) {
      if (this.otherLectureScheduleId == data.id_schedule) {
        this.otherLectureScheduleDate = data.date;
        this.otherLectureScheduleStartTime = data.start_time;
        this.otherLectureScheduleEndTime = data.end_time
      }
    }
  }

  saveOtherLecture() {
    if (this.facultyId == "0") {
      swal.fire("Please select faculty")
    }
    else if (this.otherLectureScheduleId == "0") {
      swal.fire("Please select topic")
    }
    else {
      let json = {
        "caller_email": localStorage.getItem("email"),
        "id_section": this.sectionId,
        "id_schedule": this.sheduleId,
        "id_status": this.previousLectureStatus,
        "lecture_date": this.lectureDate + " " + this.lectureStartTime + " to " + this.lectureEndTime,
        "id_other_schedule": this.otherLectureScheduleId
      }
      //(this.lectureDate)
      this.showLoader = true;
      this.scheduleMasterService.addOtherLecture(json).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.unscheduleHour = data.unschedule_hour
            this.DailySchedules = data.schedule
            this.UnfilteredDailySchedule = data.schedule
            this.TempTopics = data.faculty_topic
            this.TempScheduleTopics = data.schedule_topic
            this.getScheduleData()
            this.hideAddEditModal()
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
        }
      )
    }
  }

  filterSchedule() {
    this.DailySchedules = [];
    if (this.filerStartDate && this.filerEndDate) {
      for (let data of this.UnfilteredDailySchedule) {
        if (data.class_date >= this.filerStartDate && data.class_date <= this.filerEndDate) {
          this.DailySchedules.push(data)
        }
      }
    }
    else if (this.filerStartDate) {
      for (let data of this.UnfilteredDailySchedule) {
        if (data.class_date >= this.filerStartDate) {
          this.DailySchedules.push(data)
        }
      }
    }
    else if (this.filerEndDate) {
      for (let data of this.UnfilteredDailySchedule) {
        if (data.class_date <= this.filerEndDate) {
          this.DailySchedules.push(data)
        }
      }
    }
    else {
      this.DailySchedules = this.UnfilteredDailySchedule
    }
  }

  timeValidation(startTime, endTime) {
    var dateOne = new Date("12-25-2012 " + startTime);
    var dateSecond = new Date("12-25-2012 " + endTime);
    if (dateOne.getTime() > dateSecond.getTime()) {
      return false
    }
    else {
      return true
    }
  }

  showAlertDiv(message) {
    var that = this
    that.alertDiv = true
    this.sucessAlertMessage = message
    setTimeout(function () {
      that.alertDiv = false
    }, 5000);
  }

  back() {
    this.clearData();

    this.isModifySchedule = false
    this.getDailySectionSchedule();
  }

  print() {
    this.isPrint = true;
    let printContents, popupWin;
    var that = this;

    setTimeout(function () {
      printContents = document.getElementById('printSection').innerHTML;
      var htmlToPrint = '' +
        '<style type="text/css">' +
        'table th, table td {' +
        'border:1px solid #000;' +
        'padding;0.5em;' +
        '}' +
        '</style>';
      htmlToPrint += printContents;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
          <html>
              <head>
                 
                  <style>
                      //........Customized style.......
                  </style>
              </head>
              <body onload="window.print();window.close()">${htmlToPrint}

              </body>
          </html>`
      );
      popupWin.document.close();
      that.isPrint = false
    }, 10)

  }
  checkCondition() {
    var tomorrow = new Date();
    var todayDate = tomorrow.toISOString().split('T')[0];
    this.batchEndDate = this.datePipe.transform(this.batchEndDate, 'yyyy-MM-dd');
    //(this.batchEndDate < todayDate)
    if (this.isRole == "1" && this.batchEndDate > todayDate) {
      return true
    }
    else {
      return false;
    }
  }
  getFormatedHours() {
    if (this.unscheduleHour) {
      let tempHours = parseInt(this.unscheduleHour.split(".")[0]) * 60
      let tempMinutes = parseInt(this.unscheduleHour.split(".")[1])
      let totalMinutes = tempHours + tempMinutes;
      let hours = Math.floor(totalMinutes / 60)
      let minutes = totalMinutes % 60
      if (minutes < 10) {
        return hours + ".0" + minutes
      }
      else {
        return hours + "." + minutes
      }
    }
  }

  selectAllDays() {
    if (this.isAllDays == true) {
      this.isMon = true
      this.isTue = true
      this.isWed = true
      this.isThu = true
      this.isFri = true
      this.isSat = true
      this.isSun = true
    }
    else {
      this.isMon = false
      this.isTue = false
      this.isWed = false
      this.isThu = false
      this.isFri = false
      this.isSat = false
      this.isSun = false
    }
  }

  checkDays() {
    if (this.isMon == true && this.isTue == true && this.isWed == true && this.isThu == true && this.isFri == true && this.isSat == true && this.isSun == true) {
      this.isAllDays = true;
    }
    else {
      this.isAllDays = false;
    }
  }


  checkforFuture(date) {
    date = this.datePipe.transform(date, 'yyyy-MM-dd');;
    var tomorrow = new Date()
    var todayDate = tomorrow.toISOString().split('T')[0];
    // var todayDate = "2018-07-30";
    //(date > todayDate)
    if (date > todayDate) {
      return false
    }
    else {
      return true
    }
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    //("status" + status);
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }

  removeHoliday(data) {
    swal.fire({
      title: '',
      text: "Are you sure you want to remove this holiday?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        let json = {
          "caller_email": localStorage.getItem("email"),
          "id_holiday": data.id_holiday,
          "id_section": this.sectionId
        }
        this.scheduleMasterService.removeHolidays(json).subscribe(
          data => {
            this.showLoader = false;
            this.Holidays = []
            if (data.status_code == "0") {
              this.Holidays = data.holidays
              this.hideAddEditModal();
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
          }
        )
      }
    })
  }

  resetFilter() {
    this.filerStartDate = null
    this.filerEndDate = null;
    this.filterSchedule();
  }

  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.DailySchedules.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  }

  getFormatedDate(data) {
    let tempDate = data.split(" ")[0]

    var formatedDate = this.datePipe.transform(tempDate, 'MMM d, y');
    if (data.split(" ")[1]) {
      return formatedDate + " " + data.split(" ")[1]
    }
    else {
      return formatedDate
    }

  }
}
