import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";

import { Helper } from '../helper'
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class ErrorReportingService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }
    getAllErrorReport(email) {
        return this.http.get(this.baseUrl + "ErrorReporting/getAllErrorReport?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    resolvedErrorReport(json) {
        //.log(JSON.stringify(json))
        return this.http.post(this.baseUrl + "/ErrorReporting/resolvedErrorReport", json, this.options).map((res) => JSON.parse(JSON.stringify(res)));

    }
}