import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class BookMasterService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }
    getAllActiveCoe(email) {
        return this.http.get(this.baseUrl + "/Coe/getAllActiveCoe?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }


    addBookType(jsonData) {
        return this.http.post(this.baseUrl + "LibraryMaster/addBookType", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    editBookType(jsonData) {
        return this.http.post(this.baseUrl + "LibraryMaster/editBookType", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllBookType(email) {
        return this.http.get(this.baseUrl + "/LibraryMaster/getAllBookType?email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeBookType(email, bookTypeId) {
        return this.http.get(this.baseUrl + "/LibraryMaster/removeBookType?email=" + email + "&id_book_type=" + bookTypeId).map((res) => JSON.parse(JSON.stringify(res)));
    }



    addBookTitle(jsonData) {
        return this.http.post(this.baseUrl + "LibraryMaster/addBookTitle", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    editBookTitle(jsonData) {
        return this.http.post(this.baseUrl + "LibraryMaster/editBookTitle", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllBookTitle(email) {
        return this.http.get(this.baseUrl + "/LibraryMaster/getAllBookTitle?email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeBookTitle(email, bookTitleId) {
        return this.http.get(this.baseUrl + "/LibraryMaster/removeBookTitle?email=" + email + "&id_book_title=" + bookTitleId).map((res) => JSON.parse(JSON.stringify(res)));
    }



    addBookEdition(jsonData) {
        return this.http.post(this.baseUrl + "LibraryMaster/addBookEdition", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    editBookEdition(jsonData) {
        return this.http.post(this.baseUrl + "LibraryMaster/editBookEdition", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllBookEditionByBookTitle(email, bookTitleId) {
        return this.http.get(this.baseUrl + "/LibraryMaster/getAllBookEditionByBookTitle?email=" + email + "&id_book_title=" + bookTitleId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeBookEdition(email, bookEditionId) {
        return this.http.get(this.baseUrl + "/LibraryMaster/removeBookEdition?email=" + email + "&id_book_edition=" + bookEditionId).map((res) => JSON.parse(JSON.stringify(res)));
    }





    addBookShelf(jsonData) {
        return this.http.post(this.baseUrl + "LibraryMaster/addBookShelf", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    editBookShelf(jsonData) {
        return this.http.post(this.baseUrl + "LibraryMaster/editBookShelf", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllBookShelf(email) {
        return this.http.get(this.baseUrl + "/LibraryMaster/getAllBookShelf?email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getLastBookShelfCode(email, coeId) {
        return this.http.get(this.baseUrl + "/LibraryMaster/getLastBookShelfCode?email=" + email + "&id_coe=" + coeId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeBookShelf(email, bookShelfId) {
        return this.http.get(this.baseUrl + "/LibraryMaster/removeBookShelf?email=" + email + "&id_book_shelf=" + bookShelfId).map((res) => JSON.parse(JSON.stringify(res)));
    }


    getBookRelatedStatus(email) {
        return this.http.get(this.baseUrl + "/LibraryMaster/getBookRelatedStatus?email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }





    addBookQuantity(jsonData) {
        return this.http.post(this.baseUrl + "LibraryMaster/addBookQuantity", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    editBookQuantity(jsonData) {
        return this.http.post(this.baseUrl + "LibraryMaster/editBookQuantity", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllBookQuantity(email) {
        return this.http.get(this.baseUrl + "/LibraryMaster/getAllBookQuantity?email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getBookQuantityAccessionCode(email, bookQuantityId) {
        return this.http.get(this.baseUrl + "/LibraryMaster/getBookQuantityAccessionCode?email=" + email + "&id_book_quantity=" + bookQuantityId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeBookQuantity(email, bookQuantityId) {
        return this.http.get(this.baseUrl + "/LibraryMaster/removeBookQuantity?email=" + email + "&id_book_quantity=" + bookQuantityId).map((res) => JSON.parse(JSON.stringify(res)));
    }


    getAllBookQuantityForCopies(email) {
        return this.http.get(this.baseUrl + "/LibraryMaster/getAllBookQuantityForCopies?email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    addBookCopieInBookShelf(jsonData) {
        return this.http.post(this.baseUrl + "LibraryMaster/addBookCopieInBookShelf", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllBookQuantityForCopiesPagination(email, pageNumber) {
        return this.http.get(this.baseUrl + "/LibraryMaster/getAllBookQuantityForCopiesPagination?email=" + email + "&page_number=" + pageNumber).map((res) => JSON.parse(JSON.stringify(res)));
    }
    getAllBookQuantityForCopiesPaginationByFilter(email, pageNumber, filter) {
        let json = { 'email': email, 'page_number': pageNumber, 'filter': JSON.stringify(filter) }
        return this.http.post(this.baseUrl + "/LibraryMaster/getAllBookQuantityForCopiesPaginationByFilter", json, this.options).map((res) => JSON.parse(JSON.stringify(res)));

    }


    csvUploadForBookIssue(jsonData) {
        return this.http.post(this.baseUrl + "LibraryMaster/csvUploadForBookIssue", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }


    removeBookCopies(email, bookCopiesId) {
        return this.http.get(this.baseUrl + "/LibraryMaster/removeBookCopies?email=" + email + "&id_book_accession_code=" + bookCopiesId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    editCopies(jsonData) {
        return this.http.post(this.baseUrl + "LibraryMaster/editCopies", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllAvailableCopiesInLibrary(email) {
        return this.http.get(this.baseUrl + "/LibraryMaster/getAllAvailableCopiesInLibrary?email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllAvailableCopiesDamaged(email) {
        return this.http.get(this.baseUrl + "/LibraryMaster/getAllAvailableCopiesDamaged?email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllAvailableCopiesLost(email) {
        return this.http.get(this.baseUrl + "/LibraryMaster/getAllAvailableCopiesLost?email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllAllotedCopiesInLibrary(email) {
        return this.http.get(this.baseUrl + "/LibraryMaster/getAllAllotedCopiesInLibrary?email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }
    getUserByRoleId(email, roleId) {
        return this.http.get(this.baseUrl + "/User/getUserFullDetailByRoleId?email=" + email + "&id_role=" + roleId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    issueBookToScholar(jsonData) {
        return this.http.post(this.baseUrl + "LibraryMaster/issueBookToScholar", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    returnBookToLibrary(jsonData) {
        return this.http.post(this.baseUrl + "LibraryMaster/returnBookToLibrary", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllAllotedAndDamagedCopiesInLibrary(email) {
        return this.http.get(this.baseUrl + "/LibraryMaster/getAllAllotedAndDamagedCopiesInLibrary?email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    saveBokShelfCSV(jsonData) {

        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "LibraryMaster/saveBokShelfCSV", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }


    getAccessionCode(email, bookSelfId) {
        return this.http.get(this.baseUrl + "/LibraryMaster/getAccessionCode?email=" + email + "&id_book_shelf=" + bookSelfId).map((res) => JSON.parse(JSON.stringify(res)));
    }


    getAllAllotedAndDamagedCopiesInLibraryByScholar(email, userId) {
        return this.http.get(this.baseUrl + "/LibraryMaster/getAllAllotedAndDamagedCopiesInLibraryByScholar?email=" + email + "&id_user=" + userId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllAllotedCopiesInLibraryByPagination(email, pageNumber, accesionCode) {
        return this.http.get(this.baseUrl + "/LibraryMaster/getAllAllotedCopiesInLibraryByPagination?email=" + email + "&page_number=" + pageNumber + "&accession_code=" + accesionCode).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllAvailableCopiesInLibraryByPagination(email, pageNumber, accesionCode) {
        return this.http.get(this.baseUrl + "/LibraryMaster/getAllAvailableCopiesInLibraryByPagination?email=" + email + "&page_number=" + pageNumber + "&accession_code=" + accesionCode).map((res) => JSON.parse(JSON.stringify(res)));
    }

    bulkIssueBookToScholar(jsonData) {
        return this.http.post(this.baseUrl + "LibraryMaster/bulkIssueBookToScholar", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    rerutrnBulkBook(jsonData) {
        return this.http.post(this.baseUrl + "LibraryMaster/rerutrnBulkBook", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAvailableBookCopieDetails(email, accesionCode) {
        return this.http.get(this.baseUrl + "LibraryMaster/getAvailableBookCopieDetails?email=" + email + "&accession_code=" + accesionCode).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllotedBookCopieDetails(email, accesionCode) {
        return this.http.get(this.baseUrl + "LibraryMaster/getAllotedBookCopieDetails?email=" + email + "&accession_code=" + accesionCode).map((res) => JSON.parse(JSON.stringify(res)));
    }

    downloadAllBookCopies(email) {
        return this.http.get(this.baseUrl + "LibraryMaster/downloadAllBookCopies?email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }
}