import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
@Injectable()
export class LoginService {
    baseUrl = Helper.baseURL
    constructor(private http: HttpClient) {}
    
    authUser(email, password) {
        return this.http.get(this.baseUrl + "User/authUserWithPassword?email=" + email + "&password=" + password).map((res) => JSON.parse(JSON.stringify(res)));
    }

    logout() {
        return this.http.get(this.baseUrl + "User/logout");
    }

    generateOtp(email) {
        return this.http.get(this.baseUrl + "Otp/createOtp?email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    verifyUserOtp(otp, userId, password, email, action?) {
        return this.http.get(this.baseUrl + "Otp/verifyUserOtp?otp=" + otp + "&id_user=" + userId + "&purpose=forgetPassword" + "&password=" + password + "&email=" + email + "&action=" + action).map((res) => JSON.parse(JSON.stringify(res)));
    }
}