import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http'; @Injectable()
export class InstituteMasterService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }
    getAllCourses(email) {
        return this.http.get(this.baseUrl + "/Rank/getAllCourse?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllInstituteRanksMaster(email) {
        return this.http.get(this.baseUrl + "/Institute/getAllInstituteMaster?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }
    saveRankExcel(jsonData) {

        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Institute/saveInstituteExcel", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }
    saveInstituteExcelForExceptNIT(jsonData) {
        //.log(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Institute/saveInstituteExcelForExceptNIT", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    setCurrent(email, year, courseId, value, instituteId) {
        return this.http.get(this.baseUrl + "/Institute/changeisCurrentStatus?caller_email=" + email + "&year=" + year + "&id_course_type=" + courseId + "&is_current=" + value + "&id_institute=" + instituteId).map((res) => JSON.parse(JSON.stringify(res)));
    }
}