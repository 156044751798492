import { Component, OnInit } from '@angular/core';

import { OnlineExamService } from './online-exam.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2'
import { md5 } from '../md5'
import { DatePipe } from '@angular/common'
import { Helper } from '../helper'
import { CSVExport } from '../Csv-export';

@Component({
  selector: 'app-online-exam',
  templateUrl: './online-exam.component.html',
  styleUrls: ['./online-exam.component.css'],
  providers: [OnlineExamService, Helper, DatePipe]
})
export class OnlineExamComponent implements OnInit {
  showLoader = false;
  searchText = '';
  UnfilteredExam = [];
  ActiveExams = [];
  isUserLogin = false;
  todayDate: any;
  Coes = [];
  Batches = [];
  unfilteredBatches = [];
  Sections = [];
  unfilterdSections = [];
  sectionId = "0";
  coeId = "0";
  batchId = "0";
  isRole: any;
  p = 0;
  column: string = "CategoryName";
  direction: number;
  isDesc: boolean = false;

  constructor(private helper: Helper, private datePipe: DatePipe, private onlineExamService: OnlineExamService, private router: Router) {
    var todayDate = new Date();

    this.isRole = localStorage.getItem("id_role")
    window.onfocus = function () {
      //.log("focused");
    }

    this.todayDate = todayDate.toISOString().split('T')[0];
    //(this.todayDate)
    if (localStorage.getItem("isLogin") == "true") {
      this.getAllCoe();
    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }


  getAllCoe() {
    this.showLoader = true;
    this.onlineExamService.getAllCoe(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.Coes = data.coes
          this.getAllBatches();
        }
        else {
          swal.fire(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }


  getAllBatches() {
    this.showLoader = true;
    this.onlineExamService.getAllBatches(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          // this.Batches = data.batches
          this.unfilteredBatches = data.batches
          this.getAllSections();
        }
        else {
          swal.fire(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllSections() {
    this.showLoader = true;
    this.onlineExamService.getAllSections(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          // this.Sections = data.sections
          this.unfilterdSections = data.sections
          this.getAllActiveExam();
        }
        else {
          swal.fire(data.status_message)
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  ngAfterContentChecked() {
    if (localStorage.getItem("isLogin") == "true") {
      this.isUserLogin = true
    }
    else {
      this.isUserLogin = false
    }
    //this.getAllActiveExam();

  }
  getAllActiveExam() {
    this.showLoader = true;
    this.onlineExamService.getAllExam(localStorage.getItem("email"), localStorage.getItem("id_user"), localStorage.getItem("id_section_scholar")).subscribe(
      (data: any) => {
        this.showLoader = false;

        if (data["status_code"] == 0) {
          //below line commented by sandeep
          //this.ActiveExams = data.active_exams
          this.UnfilteredExam = data.active_exams

        }
        else {
          swal.fire(data["status_message"]);
        }
      },
      err => {

      }
    )
  }

  // startExam(data) {
  //   localStorage.setItem("exam_name", data.name)
  //   // let url = "http://dev.dot.vendorzapp.in/exam/" + localStorage.getItem("email") + "/" + localStorage.getItem("id_user") + "/" + localStorage.getItem("id_section_scholar") + "/" + data.id_exam_template
  //   let url = "http://localhost:4200/exam/" + localStorage.getItem("email") + "/" + localStorage.getItem("id_user") + "/" + localStorage.getItem("id_section_scholar") + "/" + data.id_exam_template
  //   let width = "width=" + window.innerWidth
  //   let height = "height=" + window.innerHeight
  //   let frame = width + height
  //   window.open(url, "Dakshana Online Test", frame);
  // }

  viewResult(data) {
    localStorage.setItem("exam_name", data.name)
    let url = '/online-exam-result-anaylsis/' + data.id_exam_template + "/" + data.id_pattern + "/" + data.id_section
    this.router.navigateByUrl(url);
  }

  checkStudentCanStartExam(data) {
    let hours = new Date().getHours();
    let minutes = new Date().getMinutes();
    let currentTime = hours + ":" + minutes + ":00"
    let x = data.start_time.split(" ");
    let modeStart = x[1]
    var startTime;
    var endTime;
    if (modeStart == "PM") {
      let y = data.start_time.split(":");
      if (parseInt(y[0]) != 12) {
        var tempStartHour = parseInt(y[0]) + 12;
      }
      else {
        tempStartHour = parseInt(y[0])
      }


      let z = y[1].split(" ")
      startTime = tempStartHour + ":" + z[0] + ":00";

    }
    else {
      let y = data.start_time.split(" ");
      startTime = y[0] + ":00";
    }
    let z = data.end_time.split(" ");
    let modeEnd = z[1];
    if (modeEnd == "PM") {
      let y = data.end_time.split(":");
      if (parseInt(y[0]) != 12) {
        var tempEndHour = parseInt(y[0]) + 12;
      }
      else {
        tempEndHour = parseInt(y[0])
      }
      let z = y[1].split(" ")
      endTime = tempEndHour + ":" + z[0] + ":00";

    }
    else {
      let y = data.end_time.split(" ");
      endTime = y[0] + ":00";
    }
    let check = this.compareTime(currentTime, startTime, endTime)
    //.log(check)

    //.log(this.todayDate)
    let date = this.datePipe.transform(data.date, 'yyyy-MM-dd');
    //.log(date)
    if (date == this.todayDate && check == true) {
      return true;
    }
  }
  compareTime(currentTime, startTime, endTime) {
    //.log("currentTime" + currentTime)
    //.log("startTime" + startTime)
    //.log("endTime" + endTime)
    if (Date.parse('2011-01-01 ' + currentTime) > Date.parse('2011-01-01 ' + startTime) && Date.parse('2011-01-01 ' + currentTime) < Date.parse('2011-01-01 ' + endTime)) {
      return true
    }
    else {
      return false
    }

  }


  filterExam(type?: String) {
    this.Batches = []
    this.Sections = []
    this.ActiveExams = this.UnfilteredExam
    //("Exams==>" + this.Exams)
    if (type == 'coe') {
      this.batchId = '0';
      this.sectionId = '0';
    }
    if (type == 'batch') {
      this.sectionId = '0';
    }

    if (this.coeId != "0") {

      this.ActiveExams = []
      for (let data of this.unfilteredBatches) {
        if (data.id_coe == this.coeId) {
          this.Batches.push(data);
        }
      }

      for (let data of this.UnfilteredExam) {
        if (data.id_coe == this.coeId) {
          this.ActiveExams.push(data)
        }
      }
    }
    if (this.batchId != "0") {

      this.ActiveExams = [];
      for (let data of this.unfilterdSections) {
        if (data.id_batch == this.batchId) {

          this.Sections.push(data);
        }
      }
      for (let data of this.UnfilteredExam) {
        if (data.id_batch == this.batchId) {
          this.ActiveExams.push(data)
        }
      }
    }
    if (this.sectionId != "0") {

      this.ActiveExams = []
      for (let data of this.UnfilteredExam) {
        if (data.id_section == this.sectionId) {
          this.ActiveExams.push(data)
        }
      }
    }

    this.checkedExams = [];

  }


  checkedExams = [];

  checkUncheckExam(data) {

    var check = true

    if (this.checkedExams.length != 0) {
      for (let i = 0; i < this.checkedExams.length; i++) {
        if (this.checkedExams[i].id_exam_template == data.id_exam_template && this.checkedExams[i].id_section == data.id_section) {
          this.checkedExams.splice(i, 1)
          check = false
          break;
        }
        else {
          check = true;
        }
      }

      if (check == true) {
        let json = {
          'id_exam_template': data.id_exam_template,
          'id_section': data.id_section,
          'id_pattern': data.id_pattern,
          'name': data.name
        }
        this.checkedExams.push(json)
      }
    }
    else {

      let json = {
        'id_exam_template': data.id_exam_template,
        'id_section': data.id_section,
        'id_pattern': data.id_pattern,
        'name': data.name
      }
      this.checkedExams.push(json)
    }

    //this.checkedExams)

  }

  checkExamIschecked(data) {
    var isChecked = false;
    if (this.checkedExams.length != 0) {
      for (let checkedData of this.checkedExams) {
        if (data.id_exam_template == checkedData.id_exam_template && data.id_section == checkedData.id_section) {
          isChecked = true;
          break;
        }
        else {
          isChecked = false;
        }

      }

    }
    else {
      return false
    }
    if (isChecked) {
      return true;
    }
    else {
      return false;
    }
  }

  DownloadExamResult() {
    var length = this.checkedExams.length;
    console.log("checkedExams length = " + this.checkedExams.length);
    if ((!(length == 1)) && (this.sectionId == "0")) {
      if (this.sectionId == "0") {
        swal.fire("Please select section.")
        return
      } else if (this.checkedExams.length > 5) {
        swal.fire("Please select maximum five exams.")
      } else if (this.checkedExams.length == 0) {
        swal.fire("Please select maximum five exams.")
      }
    } else if (this.checkedExams.length > 5) {
      swal.fire("Please select maximum five exams.")
    }
    else {
      let tempExams = [];
      if (this.checkedExams.length == 0) {
        for (let data of this.ActiveExams) {
          tempExams.push({
            'id_exam_template': data.id_exam_template,
            'id_section': data.id_section,
            'id_pattern': data.id_pattern,
            'name': data.name
          })
        }
      }
      else {
        tempExams = this.checkedExams
      }
      this.showLoader = true;
      let json = {
        'caller_email': localStorage.getItem("email"),
        'exams': this.checkedExams
      }
      this.onlineExamService.getOnlineExamResultForExams(JSON.stringify(json)).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {

            swal.fire(data['status_message']);
          }
          else {
            swal.fire(data['status_message']);
          }
        },
        err => {
          this.showLoader = false;

          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }
  }

  downloadAbsentStudentList(id_exam, exam_name, coe_name, batch_name, section_name, exam_date, id_section) {
    this.showLoader = true;
    let json = {
      'caller_email': localStorage.getItem("email"),
      'id_exam': id_exam,
      'id_section': id_section
    }
    this.onlineExamService.getAbsentStudentList(JSON.stringify(json)).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0" || (data.status_code == 0)) {
          // swal.fire(data['status_message']);
          this.downloadAbsentStudentDetails(data['absent_student_details'], exam_name, coe_name, batch_name, section_name, exam_date);
        }
        else {
          swal.fire(data['status_message']);
        }
      },
      err => {
        this.showLoader = false;

        swal.fire("Something went wrong. Please try again later." + err)
      }
    )
  }

  downloadAbsentStudentDetails(absent_student_details, exam_name, coe_name, batch_name, section_name, exam_date) {
    let csvArray = [];
    for (let candidate of absent_student_details) {


      let json = {
        'DRN': (candidate['dakshana_roll_number'] ? candidate['dakshana_roll_number'] : 'NA'),
        'Name': (candidate['scholar_name'] ? candidate['scholar_name'] : 'NA'),
        'Contact No': (candidate['mobile'] ? candidate['mobile'] : 'NA')
      }
      csvArray.push(json)
    }

    exam_date = this.datePipe.transform(exam_date, 'yyyy-MM-dd');

    var utc = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
    CSVExport.downloadcsv(csvArray, "Absent_student_list" + '_' + exam_name + '_' + coe_name + '_' + batch_name + '_-' + section_name + '_' + exam_date + "_on_");
  }

  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    if (property != 'date') {
      this.ActiveExams.sort(function (a, b) {
        if (a[property] < b[property]) {
          return -1 * direction;
        } else if (a[property] > b[property]) {
          return 1 * direction;
        } else {
          return 0;
        }
      });
    } else {
      if (!this.isDesc) {
        this.ActiveExams.sort((a, b) =>
          new Date(b.date).getTime() - new Date(a.date).getTime());
      }

      if (this.isDesc) {
        this.ActiveExams.sort((a, b) =>
          new Date(a.date).getTime() - new Date(b.date).getTime());
      }


    }
  }

}
