import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';@Injectable()
export class RankMasterService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }
    getAllCourses(email) {
        return this.http.get(this.baseUrl + "/Rank/getAllCourse?caller_email="+email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllRanksMaster(email) {
        return this.http.get(this.baseUrl + "/Rank/getAllRankMaster?caller_email="+email).map((res) => JSON.parse(JSON.stringify(res)));
    }
    saveRankExcel(jsonData) {
        //JSON.stringify(jsonData);
        //.log(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Rank/saveRankExcel", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }
    setCurrent(email,year,courseId,value,masterRankId) {
        return this.http.get(this.baseUrl + "/Rank/changeisCurrentStatus?caller_email="+email+"&year="+year+"&id_course_type="+courseId+"&is_current="+value+"&id_master_rank="+masterRankId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    saveRankExcelForNeet(jsonData) {
        //JSON.stringify(jsonData);
        //.log(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Rank/saveRankExcelForNeet", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }
}