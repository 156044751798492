import { Component, OnInit } from '@angular/core';
import { CandidateResponseService } from './candidate-response.service';
import Swal from 'sweetalert2';
import { CSVExport } from '../Csv-export';

interface ResponseData {
  sections: any[]; // You can specify a more specific type if needed
  status_code: number;
  status_message: string;
}

@Component({
  selector: 'app-candidate-response',
  templateUrl: './candidate-response.component.html',
  styleUrls: ['./candidate-response.component.css']
})
export class CandidateResponseComponent implements OnInit {
  responseData: ResponseData;
  selectedExamTemplate: number;
  examTemplates: any[] = [];
  //responseData: any[] = [];
  count: number = 0;
  keys: string[] = [];
  searchTerm: string; // Declare a variable to store the search ID
  filteredQuestions: any[];
  showSearchedData: boolean = false;
  
  constructor(private candidateRes: CandidateResponseService) { }

  ngOnInit() {
    this.getExamTemplates();
  }

  getExamTemplates() {
    this.candidateRes.getExamTemplates(localStorage.getItem("email")).subscribe(
      (data) => {
        this.examTemplates = data;
        //console.log(this.examTemplates)
      },
      (error) => {
        console.error('Error fetching exam templates:', error);
      }
    )
  }

  fetchData() {
    if (this.selectedExamTemplate) {
      this.candidateRes.getResponseByTemplate(localStorage.getItem("email"), this.selectedExamTemplate)
        .subscribe((data) => {
          this.responseData = data;
          //console.log(this.responseData);
          this.downloadCSV();
        },
        (error) => {
          console.error('Error fetching exam templates:', error);
        }
        );
    }
  }

  downloadCSV() {
    // Check if responseData is not empty
    if (this.responseData.sections && this.responseData.sections.length > 0) {
      const arrayToExport = [];

      //console.log('responseData:', this.responseData);

      // Dynamically extract keys from the first item in responseData.sections
      const firstItemKeys = Object.keys(this.responseData.sections[0]);

      // Push the keys as the header row (if needed)
      //arrayToExport.push(firstItemKeys);

      // Iterate over responseData.sections
      this.responseData.sections.forEach((data) => {
        const json = {};

        // Use the dynamically extracted keys to populate json
        firstItemKeys.forEach((key) => {
          json[key] = data[key];
        });

        // Log data and json object for inspection
        //console.log('Data:', data);
        //console.log('JSON Object:', json);

        // Push the JSON object into arrayToExport
        arrayToExport.push(json);
      });

      // Download CSV
      CSVExport.downloadcsv(arrayToExport, "Candidate_Response_" + this.selectedExamTemplate);
    } else {
      Swal.fire("No data is available to download");
    }
  }

  onSearch() {
  // Filter the questions based on the search ID
    this.filteredQuestions = this.responseData.sections.filter(question => {
      return (
      (question.id_question === this.searchTerm ||
      question.id_comprehension === this.searchTerm) &&
      question.scholar_name.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
    });
    //console.log(this.filteredQuestions);
    this.showSearchedData = true;
}

}
