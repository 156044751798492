import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class QuizService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }

    getMasterDataForQuiz(email) {
        return this.http.get(this.baseUrl + "/Quiz/getMasterDataForQuiz?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }
    getQuestionForQuiz(email,topicId) {
        return this.http.get(this.baseUrl + "/Quiz/getQuestionForQuiz?caller_email=" + email+"&id_topic="+topicId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllQuiz(email,roleId) {
        return this.http.get(this.baseUrl + "/Quiz/getAllQuiz?caller_email=" + email+"&id_role="+roleId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    addNewQuiz(jsonData) {
        return this.http.post(this.baseUrl + "Quiz/addNewQuiz", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    editQuiz(jsonData) {
        return this.http.post(this.baseUrl + "Quiz/editQuiz", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeQuiz(email,id_quiz) {
        return this.http.get(this.baseUrl + "/Quiz/removeQuiz?caller_email=" + email+"&id_quiz="+id_quiz).map((res) => JSON.parse(JSON.stringify(res)));
    }
    removeQuestionFromQuiz(email,id_quiz,id_question){
        return this.http.get(this.baseUrl + "/Quiz/removeQuestionFromQuiz?caller_email=" + email+"&id_quiz="+id_quiz+"&id_question="+id_question).map((res) => JSON.parse(JSON.stringify(res)));
    
    }

    getQuizData(email,id_quiz){
        return this.http.get(this.baseUrl + "/Quiz/getQuizData?caller_email=" + email+"&id_quiz="+id_quiz).map((res) => JSON.parse(JSON.stringify(res)));
    
    }

    changeStatus(email,id_quiz,id_status) {
        return this.http.get(this.baseUrl + "/Quiz/changeStatus?caller_email=" + email+"&id_quiz="+id_quiz+"&id_status="+id_status).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getScholarQuizResultForCSV(email,type,quizId,fromDate?,toDate?) {
        if(!fromDate){
         fromDate='';
         toDate='';
        }
        return this.http.get(this.baseUrl + "/Quiz/getScholarQuizResultForCSV?caller_email=" + email+"&result_for="+type+"&id_quiz="+quizId+"&from_date="+fromDate+"&to_date="+toDate).map((res) => JSON.parse(JSON.stringify(res)));
    }

  
}