import { Component, OnInit } from '@angular/core';
import { ScholarAnalysisReportService } from './scholar-analysis.service'
import { Router } from '@angular/router'
import { Helper } from '../helper'
import swal from 'sweetalert2'

@Component({
  selector: 'app-scholar-analysis',
  templateUrl: './scholar-analysis.component.html',
  styleUrls: ['./scholar-analysis.component.css'],
  providers: [ScholarAnalysisReportService]
})
export class ScholarAnalysisComponent implements OnInit {
  CourseTypeTable = [];
  iitInstitutes = [];
  niitInstitutes = [];
  neetInstitutes = [];
  iit10Institutes = [];
  niit10Institutes = [];
  neet10Institutes = [];
  examTables = [];
  examTablesHeading = [];
  examTableFormattedData = [];
  keys = [];
  showLoader = false;
  scholarName: any;
  coeName: any;
  batchName: any;
  sectionName: any;
  dakshanaRoleNumber: any;
  courseTypeId = "";
  minimum_no_of_test = 0;     // 0 for all test
  showMoreNEET = false;
  showMoreNIIT = false;
  showMoreIIT = false;
  constructor(private scholarAnalysisReportService: ScholarAnalysisReportService, private router: Router) {
    if (localStorage.getItem("isLogin") == "true") {

      this.viewDetails();
      //(localStorage.getItem("id_section_scholar"))
    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  changeExam(){
  this.CourseTypeTable = [];
  this.iitInstitutes = [];
  this.niitInstitutes = [];
  this.neetInstitutes = [];
  this.examTables = [];
  this.examTablesHeading = [];
  this.examTableFormattedData = [];
  this.keys = [];
    console.log("minimum_no_of_test="+this.minimum_no_of_test);
    this.viewDetails();
  }
  viewDetails() {
    this.showMoreNEET = false;
    this.showMoreNIIT = false;
    this.showMoreIIT = false;

    this.showLoader = true;
    this.scholarAnalysisReportService.getScholarAnaysisReport(localStorage.getItem("email"), localStorage.getItem("id_section_scholar"), this.minimum_no_of_test, localStorage.getItem("id_user")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.scholarName = localStorage.getItem("name");
          this.coeName = data.coe_name
          this.batchName = data.batch_name
          this.sectionName = data.section_name
          this.dakshanaRoleNumber = data.dakshana_roll_number
          this.CourseTypeTable = data.course_type_tabel
          this.neetInstitutes = data.possible_neet_institute
          this.iitInstitutes = data.possible_iit_institute
          this.niitInstitutes = data.possible_niit_institute
          this.neet10Institutes = this.neetInstitutes.slice(0,9)
          this.iit10Institutes = this.iitInstitutes.slice(0,9);
          console.log("iit10Institutes size="+this.iit10Institutes.length);
          this.niit10Institutes = this.niitInstitutes.slice(0,9);
          this.examTables = data.exams
          this.examTablesHeading = data.exam_table_heading
          this.courseTypeId = data.id_course_type
          this.generateExamTableData();
          
        }
        else {
          swal.fire(data.status_message)
        }
      },
      err => {

      }
    )
  }

  generateExamTableData() {
    //this.examTableFormattedData.push(json);
    var testdata = [];


    for (let exam of this.examTables) {
      var data = {};
      data['test_name'] = exam.exam_name;

      var totalMarks = 0;
      for (let ed of exam.exam_deatils) {
        for (let heading of this.examTablesHeading) {
          //.log(exam.exam_name + "--" + (heading.id == ed.id_subject))

          if (heading.id == ed.id_subject) {
            data[heading.name] = ed.effective_marks;
            totalMarks = totalMarks + (+ed.effective_marks)

          }
          else {
            if (!data[heading.name]) {
              data[heading.name] = '-';
            }

          }
        }
      }
      data['total_score'] = exam.total_marks;
      var per = (totalMarks * 100) / (+exam.total_marks)
      data['per'] = per.toFixed(2);
      this.examTableFormattedData.push(data);
    }
    this.keys = Object.keys(this.examTableFormattedData[0])
    //.log(this.examTableFormattedData)

  }

  showMoreLessIITClick(){
    this.showMoreIIT = !this.showMoreIIT;
  }
  
  showMoreLessNIITClick(){
    this.showMoreNIIT = !this.showMoreNIIT;
  }
 
  showMoreLessNEETClick(){
    this.showMoreNEET = !this.showMoreNEET;
  }


}
