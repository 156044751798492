import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SyllabusMasterService } from './syllabus-master.service'
import swal from 'sweetalert2'
@Component({
  selector: 'app-syllabus-master',
  templateUrl: './syllabus-master.component.html',
  styleUrls: ['./syllabus-master.component.css'],
  providers: [SyllabusMasterService]
})
export class SyllabusMasterComponent implements OnInit {
  Syllabus = []
  showLoader = false
  isShowSyllabusTopic = false;
  syllabusName: any;
  SyllabusTopics = [];
  searchSyllabusTopic: any;
  totalHours: any
  searchText: any;
  alertDiv = false;
  sucessAlertMessage: any;
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  column: string = 'CategoryName';
  direction: number;
  isDesc: boolean = false;
  constructor(private router: Router, private syllabusMasterService: SyllabusMasterService) {
    localStorage.removeItem('syllabus_topic');
    if (localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "1") {
      this.getAllSyllabus();

    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }
  getAllSyllabus() {
    this.showLoader = true
    this.syllabusMasterService.getAllSyllabus(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.Syllabus = data.syllabus
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false
        swal.fire("Something went wrong. Please try again later.")

      }
    )
  }

  addSyllabus() {
    this.router.navigate(['./add syllabus'], { queryParams: { purpose: 'Add' } });
  }

  edit(data) {
    this.syllabusMasterService.sendData(data);
    this.router.navigate(['./add syllabus'], { queryParams: { purpose: 'Edit', data: data } });
    localStorage.setItem("syllabus_topic", JSON.stringify(data));
  }
  showTopics(data) {
    this.totalHours = 0;
    this.isShowSyllabusTopic = true;
    this.syllabusName = data.syllabus_name;
    this.SyllabusTopics = data.topics;
    var totalMinutes = 0
    for (let data of this.SyllabusTopics) {
      let temp = data.hours.split(".");
      let hour = temp[0];
      let minutes = temp[1];
      let tempTotalMinutes = ((+hour) * 60) + (+minutes);
      totalMinutes += tempTotalMinutes
      // this.totalHours = this.totalHours + parseFloat(data.hours);
    }
    var hours = Math.floor(totalMinutes / 60);
    var tempminutes = totalMinutes % 60;
    this.totalHours = hours + "." + tempminutes
  }

  removeSyllabus(data) {
    swal.fire({
      title: '',
      text: "Are you sure you want to remove this syllabus?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;

        this.syllabusMasterService.removeSyllabus(localStorage.getItem("email"), data.id_master_syllabus).subscribe(
          data => {
            this.showLoader = false
            if (data.status_code == "0") {
              this.Syllabus = data.syllabus;
              this.isShowSyllabusTopic = false;
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false
            swal.fire("Something went wrong. Please try again later.")

          }
        )
      }
    })
  }

  showAlertDiv(message) {
    var that = this
    that.alertDiv = true
    this.sucessAlertMessage = message
    setTimeout(function () {
      that.alertDiv = false
    }, 5000);
  }


  print() {
    let printContents, popupWin;

    printContents = document.getElementById('idprintPreviewDiv').innerHTML;
    var htmlToPrint = '' +
      '<style type="text/css">' +
      'table th, table td {' +
      'border:1px solid #000;' +
      'padding;0.5em;' +
      '}' +
      '</style>';
    htmlToPrint += printContents;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
          <html>
              <head>
                 
                  <style>
                      //........Customized style.......
                  </style>
              </head>
              <body onload="window.print();window.close()">${htmlToPrint}

              </body>
          </html>`
    );
    popupWin.document.close();
  }
  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.Syllabus.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  }
}
