import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import swal from 'sweetalert2'
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var authToken = localStorage.getItem("token") || "";
        var idUser = localStorage.getItem("id_user");
        console.log("authToken => " + authToken);

        var modifiedReq =  req.clone({
            headers: req.headers.set('Auth-Token', authToken),
            params: req.params.set(
                "id", idUser
            ),
        });

        return next.handle(modifiedReq).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    // console.log("Status Code => " + evt.body.status_code);
                    if (evt.body && evt.body.status_code == 401) {
                        localStorage.clear()
                        this.router.navigateByUrl('/');
                    }
                }
            }),
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    try {
                        // this.toasterService.error(err.error.message, err.error.title, { positionClass: 'toast-bottom-center' });
                    } catch (e) {
                        // this.toasterService.error('An error occurred', '', { positionClass: 'toast-bottom-center' });
                    }
                    //log error 
                }
                return of(err);
            }));
    }
}