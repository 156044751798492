import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class ReviewRequiementService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }
    getAllReviewRequirement(email) {
        return this.http.get(this.baseUrl + "/TestMaster/getAllReviewRequirement?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }


}