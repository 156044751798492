import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class UserMasterService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }
    getAllStates(email) {
        return this.http.get(this.baseUrl + "/Coe/getAllState?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }
    getAllCategory(email) {
        return this.http.get(this.baseUrl + "/Coe/getAllCategory?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }
    getAllUserRole(email) {
        return this.http.get(this.baseUrl + "/User/getAllUserRole?email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getUserByRoleId(email, roleId) {
        return this.http.get(this.baseUrl + "/User/getUserFullDetailByRoleId?email=" + email + "&id_role=" + roleId).map((res) => JSON.parse(JSON.stringify(res)));
    }
    addNewUserRatherThanScholar(name, email, mobile, password, roleId, callerEmail, userInfo) {
        return this.http.get(this.baseUrl + "/User/addUser?email=" + email + "&name=" + name + "&id_role=" + roleId + "&mobile=" + mobile + "&password=" + password + "&caller_email=" + callerEmail + "&user_info=" + userInfo).map((res) => JSON.parse(JSON.stringify(res)));
    }
    editUserRatherThanScholar(userId, name, email, mobile, roleId, callerEmail, userInfo) {
        return this.http.get(this.baseUrl + "/User/editUser?email=" + email + "&name=" + name + "&id_role=" + roleId + "&mobile=" + mobile + "&id_user=" + userId + "&caller_email=" + callerEmail + "&user_info=" + userInfo).map((res) => JSON.parse(JSON.stringify(res)));
    }
    removeUser(userId, roleId, callerEmail) {
        return this.http.get(this.baseUrl + "/User/removeUser?id_role=" + roleId + "&id_user=" + userId + "&caller_email=" + callerEmail).map((res) => JSON.parse(JSON.stringify(res)));
    }

    changeUserStatus(userId, roleId, callerEmail, statusId) {
        return this.http.get(this.baseUrl + "/User/changeUserStatus?id_role=" + roleId + "&id_user=" + userId + "&caller_email=" + callerEmail + "&id_status=" + statusId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllCoe(email) {
        return this.http.get(this.baseUrl + "/Coe/getAllActiveCoe?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllBatches(email) {
        return this.http.get(this.baseUrl + "/Batch/getAllActiveBatch?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllSections(email) {
        return this.http.get(this.baseUrl + "/Section/getAllActiveSection?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    addCoordinatorSectionMapping(jsonData) {
        //JSON.stringify(jsonData);
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "User/addCoordinatorSectionMapping", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }


    getCoordinatorSectionMappings(email, idCoordinator) {
        return this.http.get(this.baseUrl + "/User/getCoordinatorSectionMapping?caller_email=" + email + "&id_coordinator=" + idCoordinator).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeCoordinatorSectionMappings(email, idCoordinator, idCoe, sectionId) {
        return this.http.get(this.baseUrl + "/User/removeCoordinatorSectionMapping?caller_email=" + email + "&id_coordinator=" + idCoordinator + "&id_coe=" + idCoe + "&id_section=" + sectionId).map((res) => JSON.parse(JSON.stringify(res)));
    }


    addFacultySectionMapping(jsonData) {
        //JSON.stringify(jsonData);
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "User/addFacultySectionMapping", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getFacultySectionMappings(email, facultyId) {
        return this.http.get(this.baseUrl + "/User/getFacultySectionMapping?caller_email=" + email + "&id_faculty=" + facultyId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeFacultySectionMappings(email, facultyId, sectionId) {
        return this.http.get(this.baseUrl + "/User/removeFacultySectionMapping?caller_email=" + email + "&id_faculty=" + facultyId + "&id_section=" + sectionId).map((res) => JSON.parse(JSON.stringify(res)));
    }


    getAllSubject(email) {
        return this.http.get(this.baseUrl + "/Topic/getAllSubject?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllSubSubject(email) {
        return this.http.get(this.baseUrl + "/Topic/getAllSubSubject?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllTopics(email) {
        return this.http.get(this.baseUrl + "/Topic/getAllTopic?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }


    addFacultyTopicMapping(jsonData) {
        //JSON.stringify(jsonData);
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "User/addFacultyTopicMapping", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getFacultyTopicMapping(email, facultyId) {
        return this.http.get(this.baseUrl + "/User/getFacultyTopicMapping?caller_email=" + email + "&id_faculty=" + facultyId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeFacultyTopicsMappings(email, facultyId, topicId) {
        return this.http.get(this.baseUrl + "/User/removeFacultyTopicMapping?caller_email=" + email + "&id_faculty=" + facultyId + "&id_topic=" + topicId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    addScholar(jsonData) {
        //.log(JSON.stringify(jsonData));
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "User/addScholar", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    importScholarData(jsonData) {
        //JSON.stringify(jsonData);
        //.log(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "User/importScholar", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    editScholar(jsonData) {
        //.log(JSON.stringify(jsonData));
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "User/editScholar", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }


    changeExternalScholarRegistrationStatus(userId, callerEmail, statusId) {
        return this.http.get(this.baseUrl + "/User/changeExternalScholarRegistrationStatus?id_user=" + userId + "&caller_email=" + callerEmail + "&id_status=" + statusId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    editExternalScholar(jsonData) {
        //.log(JSON.stringify(jsonData));
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "User/editExternalScholar", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    resetPassword(jsonData){
        return this.http.post(this.baseUrl + "User/resetScholarPassword", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));

    }
}