import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class BookletSolutionService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }

    getMasterDataForLectureGroup(email,roleId) {
        return this.http.get(this.baseUrl + "/LectureGroup/getMasterDataForLectureGroup?caller_email=" + email+"&id_role="+roleId).map((res) => JSON.parse(JSON.stringify(res)));
    }
  
    getBookletSolutionForAdmin(json) {
        return this.http.post(this.baseUrl + "/BookletSolution/getBookletSolutionForAdmin",json).map((res) => JSON.parse(JSON.stringify(res)));
    }

    editBookletSolution(jsonData) {
        return this.http.post(this.baseUrl + "BookletSolution/editBookletSolution", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    addBookletSolution(jsonData) {
        return this.http.post(this.baseUrl + "BookletSolution/addBookletSolution", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }



    removeBookletSolution(jsonData) {
        return this.http.post(this.baseUrl + "BookletSolution/removeBookletSolution", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res))); }

}