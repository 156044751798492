import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import swal from 'sweetalert2';
import { ReasoningQuestionService } from './reasoning-question.service'
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
  selector: 'reasoning-question',
  templateUrl: './reasoning-question.component.html',
  styleUrls: ['./reasoning-question.component.css'],
  providers: [ReasoningQuestionService]
})
export class ReasoningQuestionComponent implements OnInit {
  showLoader = false;
  @Output() isHidden = new EventEmitter<string>();
  @Input() Subjects = [];
  @Input() subjectId: any;
  SubSubjects = [];
  @Input() UnFiltterdSubSubjects = [];
  @Input() subSubjectId: any;
  Topics = [];
  @Input() topicId: any;
  @Input() UnfiltterdTopics = [];
  SubTopics = [];
  @Input() UnfilttersSubTopics = [];
  @Input() subTopicId: any;
  idCourseType = 14;
  orignalQuestionSources = [];
  @Input() QuestionSources = [];
  @Input() questionSourceId: any;
  @Input() DifficultyLevels: any;
  @Input() difficultyLevelId: any;
  @Input() QuestionTypes = [];
  @Input() questionTypeId: any;
  @Input() ReviewRequirements = [];
  @Input() reviewRequirementId: any;
  base64textQuestionString = "";
  questionImage: any;
  sollutionText = "";
  questionText: any;
  QuestionsOptions = [];
  isAddQueestionOptionModal = false
  isCorrect = false;
  searchText: any;
  optionName: any;
  optionText: any;
  questionOptionImage: any;
  base64textQuestionOtionString = "";
  constructor(private domSanitizer: DomSanitizer, private reasoningQuestionService: ReasoningQuestionService) { }
  purposeOfQuestionOptionModal = "Add"
  questionOptionIndex: any;
  @Input() previousData: any;
  questionId: any
  showAlert = false;
  alertMsg: any;
  alertType: any;
  assertion: any;
  reason: any;
  roleId: any;
  @Input() QuestionInterpretation = [];
  questionInterpretationId: any;
  @Input() TimeOfQuestion = [];
  timeOfQuestionId: any;
  isAddRejectionCommentModal = false;
  rejectComment: any;
  @Input() tabId: any;
  questionStatusId: any;
  purpose = "Add";
  solutionImage: any;
  base64textSolutionString = "";
  imageType: any;
  questionCode: any;
  // questionFor: any;
  @Input() questionFor: any;

  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'id_question_source',
    textField: 'name',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    closeDropDownOnSelection: true
  };
  selectedPatternItems = [];
  firstTime = true;  
  ngOnInit() {
    this.orignalQuestionSources = this.QuestionSources;
    this.roleId = localStorage.getItem("id_role");
    this.timeOfQuestionId = "0";
    this.questionInterpretationId = "0";
    this.questionStatusId = "12"
    if (this.previousData) {
      this.purpose = "Edit";
      this.questionId = this.previousData.id_question;
      this.questionText = this.previousData.question_text;
      //.log(this.questionText)
      let x = this.questionText.split("$$")
      this.assertion = x[0]; // Gets the first part
      this.reason = x[1];
      this.questionImage = this.getImage(this.previousData.question_image);
      this.base64textQuestionString = this.previousData.question_image
      this.sollutionText = this.previousData.sollution_text;
      this.QuestionsOptions = this.previousData.question_optiones;
      this.solutionImage = this.getImage(this.previousData.solution_image);
      this.questionFor = this.previousData.question_for;
      this.base64textSolutionString = this.previousData.solution_image
      this.subjectId = this.previousData.id_subject;
      this.filterSuSuject();
      this.subSubjectId = this.previousData.id_sub_subject;
      this.filterTopics();
      this.topicId = this.previousData.id_topic;
      this.filterSubTopics();
      this.subTopicId = this.previousData.id_sub_topic;
      this.questionSourceId = this.previousData.id_question_source;
      this.QuestionSources.forEach(item => {
        if (item.id_question_source == this.questionSourceId) {
          this.selectedPatternItems.push({ item_id: this.questionSourceId, name: item.name });
        }
      });

      this.questionStatusId = this.previousData.is_approved;
      this.questionCode = this.previousData.question_code;
      if (this.previousData.id_question_interpretation == null) {
        this.questionInterpretationId = "0"
      }
      else {
        this.questionInterpretationId = this.previousData.id_question_interpretation
      }
      if (this.previousData.id_time_for_question == null) {
        this.timeOfQuestionId = "0"
      }
      else {
        this.timeOfQuestionId = this.previousData.id_time_for_question
      }
      if (this.previousData.id_difficult_level == null) {
        this.difficultyLevelId = "0";
      }
      else {
        this.difficultyLevelId = this.previousData.id_difficult_level;
      }
      if (this.previousData.id_review_requirement == null) {
        this.reviewRequirementId = "0";
      }
      else {
        this.reviewRequirementId = this.previousData.id_review_requirement;
      }

      this.questionTypeId = this.previousData.id_question_pattern;
      // this.filterSuSuject();
      // this.filterTopics();
      // this.filterSubTopics();
    }
    else {
      this.generateOptions();

      this.QuestionSources.forEach(item => {
        if (item.id_question_source == this.questionSourceId) {
          this.selectedPatternItems.push({ id_question_source: this.questionSourceId, name: item.name });
        }
      });

      this.filterSuSuject();
      this.filterTopics();
      this.filterSubTopics();

    }

  }

  public json = {
    plugins: "powerpaste imagetools",
    powerpaste_allow_local_images: true,
    readonly: 1,
    toolbar: "sizeselect | bold italic | fontselect |  fontsizeselect|charmap",
  }


  handleFileSelect(evt, image) {
    var files = evt.target.files;
    var file = files[0];
    this.imageType = image;
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {

    var binaryString = readerEvt.target.result;
    //.log(this.imageType)
    if (this.imageType == "question") {
      this.base64textQuestionString = btoa(binaryString);
      var image = 'data:image/png;base64,' + this.base64textQuestionString;
      this.questionImage = this.domSanitizer.bypassSecurityTrustUrl(image);
    }
    else {
      this.base64textSolutionString = btoa(binaryString);
      var image = 'data:image/png;base64,' + this.base64textSolutionString;
      this.solutionImage = this.domSanitizer.bypassSecurityTrustUrl(image);
    }

  }

  generateOptions() {
    let jsonOne = {
      "option_name": "1",
      "option_text": " Both Assertion and Reason are true and Reason is the correct explanation of Assertion",
      "option_image": "",
      "is_option_correct": "1"
    }
    this.QuestionsOptions.push(jsonOne)

    let jsonTwo = {
      "option_name": "2",
      "option_text": "Both Assertion and Reason are true but Reason is not correct explanation of Assertion.",
      "option_image": "",
      "is_option_correct": "1"
    }
    this.QuestionsOptions.push(jsonTwo)

    let jsonThree = {
      "option_name": "3",
      "option_text": "Assertion is true but Reason is false.",
      "option_image": "",
      "is_option_correct": "1"
    }
    this.QuestionsOptions.push(jsonThree)
    let jsonFour = {
      "option_name": "4",
      "option_text": "Assertion and Reason are false.",
      "option_image": "",
      "is_option_correct": "1"
    }
    this.QuestionsOptions.push(jsonFour)
  }
  filterSuSuject() {
    this.SubSubjects = [];
    for (let data of this.UnFiltterdSubSubjects) {
      if (this.subjectId == data.id_subject) {
        this.SubSubjects.push(data)
      }
    }
    this.subSubjectId = "0"
    this.topicId = "0"
    this.subTopicId = "0"
  }

  filterTopics() {
    this.Topics = [];
    for (let data of this.UnfiltterdTopics) {
      if (this.subSubjectId == data.id_sub_subject) {
        this.Topics.push(data)
      }
    }

    this.topicId = "0"
    this.subTopicId = "0"
  }
  filterSubTopics() {
    this.SubTopics = [];
    for (let data of this.UnfilttersSubTopics) {
      if (this.topicId == data.id_topic) {
        this.SubTopics.push(data)
      }
    }

    this.subTopicId = "0"
  }
  addNewQuestionOptions() {
    this.isAddQueestionOptionModal = true;
    this.purposeOfQuestionOptionModal = "Add";
    this.clearData();
  }

  handleOptionImageFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];

    if (files && file) {
      var reader = new FileReader();

      reader.onload = this._handleOptionImageReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  _handleOptionImageReaderLoaded(readerEvt) {

    var binaryString = readerEvt.target.result;
    this.base64textQuestionOtionString = btoa(binaryString);
    var image = 'data:image/png;base64,' + this.base64textQuestionOtionString;
    this.questionOptionImage = this.domSanitizer.bypassSecurityTrustUrl(image);
  }
  hideAddEditModal() {
    this.isAddQueestionOptionModal = false;
  }
  save() {
    if (!this.optionName) {
      swal.fire("Please enter option name.")
    }
    else if (!this.optionText) {
      swal.fire("Please enter option text.")
    }
    else {
      var correct = "1";
      if (this.isCorrect) {
        for (let data of this.QuestionsOptions) {
          data.is_option_correct = "1";
        }
        correct = "0"
      }
      let json = {
        "option_name": this.optionName,
        "option_text": this.optionText,
        "option_image": this.base64textQuestionOtionString,
        "is_option_correct": correct
      }
      if (this.purposeOfQuestionOptionModal == "Add") {

        this.QuestionsOptions.push(json);
      }
      else {
        this.QuestionsOptions[this.questionOptionIndex] = json
      }

      this.hideAddEditModal();
    }
  }
  getImage(imagString) {
    var image: any;
    if ((!imagString) || imagString == "") {
      image = "../../assets/logo.png";
      return image
    }
    else {
      image = 'data:image/png;base64,' + imagString;
      image = this.domSanitizer.bypassSecurityTrustUrl(image);
      return image
    }
  }

  editQuestionOption(data, index) {
    this.optionName = data.option_name;
    this.optionText = data.option_text;
    this.base64textQuestionOtionString = data.option_image;
    this.questionOptionImage = this.getImage(this.base64textQuestionOtionString);
    if (data.is_option_correct == "0") {
      this.isCorrect = true
    }
    else {
      this.isCorrect = false
    }
    this.purposeOfQuestionOptionModal = "Edit";
    this.isAddQueestionOptionModal = true;
    this.questionOptionIndex = index;
  }

  removeQuestionOption(data, index) {
    swal.fire({
      title: '',
      text: "Are you sure you want to remove this option?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.QuestionsOptions.splice(this.questionOptionIndex, 1);
      }
    })
  }

  clearData() {
    this.optionName = "";
    this.optionText = "";
    this.isCorrect = false;
    this.questionOptionImage = null;
    this.base64textQuestionOtionString = "";
  }

  getByteSize(str) {
    var size = (new Blob([str]).size * .001).toFixed(2);
    return Number(size);
  }

  saveQuestion() {
    var checkAllOptionISIncorrect = false;
    for (let data of this.QuestionsOptions) {
      if (data.is_option_correct == "0") {
        checkAllOptionISIncorrect = true
      }
    }

    var invalidAssertionTextSize = false;
    if (+this.getByteSize(this.assertion) > 64) {
      invalidAssertionTextSize = true
    }

    var invalidReasonTextSize = false;
    if (+this.getByteSize(this.reason) > 64) {
      invalidReasonTextSize = true
    }

    var invalidSolutionTextSize = false;
    if (this.sollutionText) {
      if (+this.getByteSize(this.sollutionText) > 64) {
        invalidSolutionTextSize = true
      }
    }

    if (this.subjectId == "0") {
      swal.fire("Please select subject.");
    }
    else if (this.subSubjectId == "0") {
      swal.fire("Please select sub-subject.");
    }
    else if (this.topicId == "0") {
      swal.fire("Please select topic.");
    }
    else if (this.subTopicId == "0") {
      swal.fire("Please select sub-topic.");
    }
    else if (this.questionSourceId == "0") {
      swal.fire("Please select question source.");
    }

    else if (!this.assertion) {
      swal.fire("Please enter assertion text");
    }
    else if (!this.reason) {
      swal.fire("Please enter reason text");
    }

    else if (!(this.questionCode)) {
      swal.fire("Question tag  are compulsory");
    }
    else if (!(this.sollutionText)) {
      swal.fire("Solution text compulsory.")
    }
    else if (checkAllOptionISIncorrect == false) {
      swal.fire("Atleast one option is correct.")
    }
    else if (invalidAssertionTextSize || invalidSolutionTextSize || invalidReasonTextSize) {
      swal.fire("Allowed size exceeded. Please reduce text/image size for fields with validation errors.")
    }
    else {
      if (!this.questionId) {
        let json = {
          "caller_email": localStorage.getItem("email"),
          "id_subject": this.subjectId,
          "id_sub_subject": this.subSubjectId,
          "id_topic": this.topicId,
          "id_sub_topic": this.subTopicId,
          "id_question_source": this.questionSourceId,
          "id_question_pattern": this.questionTypeId,
          "question_text": this.assertion + "$$" + this.reason,
          "question_image": this.base64textQuestionString,
          "question_optiones": JSON.stringify(this.QuestionsOptions),
          "sollution_text": this.sollutionText,
          "solution_image": this.base64textSolutionString,
          "question_code": this.questionCode,
          "question_for": this.questionFor

        }
        this.showLoader = true;
        this.reasoningQuestionService.addQuestion(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.showAlertComponent(data['status_code'], data['status_message']);
              this.hideQuestionModal('approved');
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
      else {
        let json = {
          "caller_email": localStorage.getItem("email"),
          "id_question": this.questionId,
          "id_subject": this.subjectId,
          "id_sub_subject": this.subSubjectId,
          "id_topic": this.topicId,
          "id_sub_topic": this.subTopicId,
          "id_question_source": this.questionSourceId,

          "id_question_pattern": this.questionTypeId,

          "question_text": this.assertion + "$$" + this.reason,
          "question_image": this.base64textQuestionString,
          "question_optiones": JSON.stringify(this.QuestionsOptions),
          "sollution_text": this.sollutionText,
          "solution_image": this.base64textSolutionString,
          "question_code": this.questionCode,
          "purpose": "edit"
        }
        this.showLoader = true;
        this.reasoningQuestionService.editQuestion(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.showAlertComponent(data['status_code'], data['status_message']);
              this.hideQuestionModal('edit');
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }

    }
  }
  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    //("status" + status);
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }
  hideQuestionModal(forData?: string) {
    this.isHidden.emit(forData);
  }

  changeStatus(data) {
    if (data.is_option_correct == "1") {
      data.is_option_correct = "0"
      for (let option of this.QuestionsOptions) {
        if (option.option_name != data.option_name) {
          option.is_option_correct = "1"
        }
      }
    }
    else {
      data.is_option_correct = "1"
    }
  }

  approved() {


    var checkAllOptionISIncorrect = false;
    for (let data of this.QuestionsOptions) {
      if (data.is_option_correct == "0") {
        checkAllOptionISIncorrect = true
      }
    }
    if (this.subjectId == "0") {
      swal.fire("Please select subject.");
    }
    else if (this.subSubjectId == "0") {
      swal.fire("Please select sub-subject.");
    }
    else if (this.topicId == "0") {
      swal.fire("Please select topic.");
    }
    else if (this.subTopicId == "0") {
      swal.fire("Please select sub-topic.");
    }
    else if (this.questionSourceId == "0") {
      swal.fire("Please select question source.");
    }
    else if (this.difficultyLevelId == "0") {
      swal.fire("Please select question difficulty level.");
    }
    else if (this.reviewRequirementId == "0") {
      swal.fire("Please select review frequency.");
    }
    else if (this.questionInterpretationId == "0") {
      swal.fire("Please select question interpretation.");
    }
    else if (this.timeOfQuestionId == "0") {
      swal.fire("Please select Time to solve.");
    }
    else if (!this.assertion) {
      swal.fire("Please enter assertion text");
    }
    else if (!this.reason) {
      swal.fire("Please enter reason text");
    }

    else if (!(this.sollutionText)) {
      swal.fire("Solution text  are compulsory.")
    }
    else if (checkAllOptionISIncorrect == false) {
      swal.fire("Atleast one option is correct.")
    }
    else {


      let json = {
        "caller_email": localStorage.getItem("email"),
        "id_question": this.questionId,
        "id_subject": this.subjectId,
        "id_sub_subject": this.subSubjectId,
        "id_topic": this.topicId,
        "id_sub_topic": this.subTopicId,
        "id_question_source": this.questionSourceId,
        "id_difficult_level": this.difficultyLevelId,
        "id_question_pattern": this.questionTypeId,
        "id_review_requirement": this.reviewRequirementId,
        "question_text": this.assertion + "$$" + this.reason,
        "question_image": this.base64textQuestionString,
        "question_optiones": JSON.stringify(this.QuestionsOptions),
        "sollution_text": this.sollutionText,
        "id_time_for_question": this.timeOfQuestionId,
        "id_question_interpretation": this.questionInterpretationId,
        "solution_image": this.base64textSolutionString,
        "question_code": this.questionCode,
        "question_for": this.questionFor,
        "purpose": "approved"
      }
      this.showLoader = true;
      this.reasoningQuestionService.editQuestion(json).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.showAlertComponent(data['status_code'], data['status_message']);
            this.hideQuestionModal();
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )


    }
  }

  hideRejectionCommentModal() {
    this.isAddRejectionCommentModal = false
  }
  reject() {
    this.isAddRejectionCommentModal = true

  }

  saveComment() {
    if (!this.rejectComment) {
      swal.fire("Please enter comment")
    }
    else {
      let json = {
        "caller_email": localStorage.getItem("email"),
        "id_question": this.questionId,
        "comment": this.rejectComment
      }
      this.showLoader = true;
      this.reasoningQuestionService.rejectComment(json).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.showAlertComponent(data['status_code'], data['status_message']);
            this.hideQuestionModal();
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }
  }


  checkUncheck(data, index) {
    for (let option of this.QuestionsOptions) {
      if (option.option_name != data.option_name) {
        option.is_option_correct = 1;
      }
    }
    if (data.is_option_correct == 0) {
      data.is_option_correct = 1
    }
    else {
      data.is_option_correct = 0
    }
  }
  checkCheckIsCheckedOrNot(data) {
    if (data.is_option_correct == 0) {
      return true;
    }
    else {
      return false;
    }
  }

  filterSource() {
    this.QuestionSources = [];

    if (this.idCourseType == 14)
      this.QuestionSources = this.orignalQuestionSources;

    if (this.idCourseType == 15) {
      this.orignalQuestionSources.
        forEach(source => {
          if (source['source_for'] != 16) {
            this.QuestionSources.push(source);
          }
        });
    }

    if (this.idCourseType == 16) {

      this.orignalQuestionSources.
        forEach(source => {
          if (source['source_for'] != 15) {
            this.QuestionSources.push(source);
          }
        });

    }

    if(this.firstTime){
      var flag = false; 
      this.selectedPatternItems = [];
      this.QuestionSources.forEach(item => {
        if (item.id_question_source == this.questionSourceId) {
          this.selectedPatternItems.push({ id_question_source: this.questionSourceId, name: item.name });
          flag = true;
        }
      });

      if(!flag)
        this.questionSourceId = '0';

      this.firstTime = false;  
    }else{
      this.questionSourceId = '0';
      this.selectedPatternItems = [];
    }
  }

  onItemDeSelectQuestionSource() {
    this.questionSourceId = 0;
    this.selectedPatternItems = [];
  }

  onItemSelectQuestionSource(event) {
    this.questionSourceId = event.id_question_source;
  }
}
