import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { SectionSyllabusService } from './section-syllabus.service'
import { Helper } from '../helper'
import swal from 'sweetalert2'
@Component({
  selector: 'app-section-syllabus',
  templateUrl: './section-syllabus.component.html',
  styleUrls: ['./section-syllabus.component.css'],
  providers: [SectionSyllabusService, Helper]
})
export class SectionSyllabusComponent implements OnInit {
  Coes = []
  searchText: any;
  Batches = []
  unfilterdBatches = [];
  unfilteredSections = []
  Sections = []
  coeId = "0"
  batchId = "0"
  sectionId = "0"
  couseTypeId
  sectionName: any;
  showLoader = false;
  isImportSyllabus = false;
  SectionSyllabus = []
  Syllabus = [];
  syllabusId = "0";
  Topics = []
  isShowMainTable = true;
  CreatedSyllabus = [];
  fromIndex: any;
  isAddModal = false
  Rows = [];
  numberOfRows: any;
  SyllabusRows = [];
  Subjects = [];
  SubSubjects = [];
  unfilteredSubSubjects = []
  TopicsMaster = []
  unfilteredTopicsMaster = []
  faculty = []
  ClassType = [];
  isChangeDuration = false
  topicDuration: any;
  tempTopicData;
  totalHours = "";
  alertDiv = false;
  sucessAlertMessage: any;
  NewTopics = [];
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  maxTopicIndexWithoutScheduleOrUnscheduled = ""
  maxTopicIdithoutScheduleOrUnscheduled = ""
  column: string = 'CategoryName';
  direction: number;
  isDesc: boolean = false;
  constructor(private helper: Helper, private router: Router, private activatedRoute: ActivatedRoute, private sectionSyllabusService: SectionSyllabusService) {
    if (localStorage.getItem("isLogin") == "true") {
      this.activatedRoute.queryParams.subscribe(params => {
        this.sectionId = params.sectionId
        this.couseTypeId = params.courseTypeId
        this.coeId = params.coeId
        this.batchId = params.batchId
        this.sectionName = params.sectionName
        this.Rows = this.helper.getRowsValue();
        this.numberOfRows = this.Rows[0];
        //this.filter('section')
        this.getSectionSyllabusMasterData();
      });
    }
    else {
      this.router.navigateByUrl('/');
    }

  }

  ngOnInit() {
  }

  getSectionSyllabusMasterData() {
    this.showLoader = true
    this.sectionSyllabusService.getAllMasterData(localStorage.getItem("email"), this.sectionId).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.Coes = data.coes
          this.Batches = data.batches
          this.unfilterdBatches = data.batches
          this.Sections = data.sections
          this.unfilteredSections = data.sections
          this.Subjects = data.subjects;
          this.ClassType = data.class_type
          this.unfilteredSubSubjects = data.sub_subjects;
          this.unfilteredTopicsMaster = data.topics
          this.getAllSectionSyllabus();

        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false
      }
    )
  }

  filter(purpose) {
    this.isShowMainTable = true;
    this.CreatedSyllabus = [];
    this.Sections = []
    this.Batches = []
    this.SectionSyllabus = [];
    if (purpose == "bacth") {

      this.batchId = "0"
    }
    this.sectionId = "0"
    for (let data of this.unfilterdBatches) {
      if (data.id_coe == this.coeId) {
        this.Batches.push(data)
      }
    }
    if (this.batchId != "0") {
      for (let data of this.unfilteredSections) {
        if (data.id_batch == this.batchId) {
          this.Sections.push(data)
        }
      }
    }
  }

  getSectionName() {
    for (let data of this.Sections) {
      if (data.id_section == this.sectionId) {
        this.sectionName = data.name;
        this.couseTypeId = data.couseTypeId
        break
      }
    }
    this.isShowMainTable = true
    this.CreatedSyllabus = []
    this.getAllSectionSyllabus();

  }
  import() {
    if (this.sectionId == "0") {
      swal.fire("Please select section first.")
    }
    else {
      this.searchText = ""
      this.syllabusId = "0"
      //this.CreatedSyllabus=[]
      this.Topics = [];
      this.isImportSyllabus = true
      this.showLoader = true
      this.sectionSyllabusService.getMasterSyllabus(localStorage.getItem("email"), this.couseTypeId, this.sectionId).subscribe(
        data => {
          this.showLoader = false
          if (data.status_code == "0") {
            this.Syllabus = data.syllabus
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false
        }
      )
    }

  }
  hideAddEditModal() {
    this.isChangeDuration = false;
    this.isImportSyllabus = false
    this.isAddModal = false;
  }
  saveImport() {
    this.CreatedSyllabus = [];
    this.hideAddEditModal();
    this.isShowMainTable = false;
    this.CreatedSyllabus = this.Topics;
    this.totalHours = ""
    var totalMinutes = 0;
    for (let data of this.SectionSyllabus) {
      let temp = data.hours.split(".");
      let hour = temp[0];
      let minutes = temp[1];
      let tempTotalMinutes = ((+hour) * 60) + (+minutes);
      totalMinutes += tempTotalMinutes

    }
    var hours = Math.floor(totalMinutes / 60);
    var tempminutes = totalMinutes % 60;
    this.totalHours = hours + "." + tempminutes

  }
  getTopics() {
    this.Topics = []
    for (let data of this.Syllabus) {
      if (data.id_master_syllabus == this.syllabusId) {
        this.Topics = data.topics
        break
      }
    }
  }
  isDropDownDisable(data) {
    if (data.length == 1) {
      return true
    }
    else {
      return false;
    }
  }

  onUp(index) {
    if (index > this.maxTopicIndexWithoutScheduleOrUnscheduled) {
      this.shuffleRow(index, index - 1);
    }


  }
  onDown(index) {
    this.shuffleRow(index, index + 1);
  }

  shuffleRow(from, to) {
    var temp = this.CreatedSyllabus[from];
    this.CreatedSyllabus[from] = this.CreatedSyllabus[to];
    this.CreatedSyllabus[to] = temp;
  }

  remove(index) {
    this.CreatedSyllabus.splice(index, 1);
    this.totalHours = ""
    var totalMinutes = 0;
    for (let data of this.SectionSyllabus) {
      let temp = data.hours.split(".");
      let hour = temp[0];
      let minutes = temp[1];
      let tempTotalMinutes = ((+hour) * 60) + (+minutes);
      totalMinutes += tempTotalMinutes

    }
    var hours = Math.floor(totalMinutes / 60);
    var tempminutes = totalMinutes % 60;
    this.totalHours = hours + "." + tempminutes

  }

  addMoreInMiddle(index) {
    this.NewTopics = [];
    this.fromIndex = index;
    this.isAddModal = true;
    this.SyllabusRows = []
    if (this.CreatedSyllabus.length > 0) {
      for (let data of this.unfilteredTopicsMaster) {
        var check = false
        for (let topic of this.CreatedSyllabus) {
          if (data.id_topic == topic.id_topic) {
            check = true;
            break;
          }
          // else {
          //   check = true;
          // }
        }
        if (check == false) {
          this.NewTopics.push(data);
        }
      }
    }
    else {
      this.NewTopics = this.unfilteredTopicsMaster
    }
    for (var i = 0; i < this.Rows[0]; i++) {
      let json = {
        'id_subject': "0",
        'id_sub_subject': "0",
        'id_topic': "0",
        'id_topic_status': "0",
        'hours': "",
        "subjects": this.Subjects,
        "sub_subjects": this.unfilteredSubSubjects,
        "topics": this.NewTopics,
        "id_class_type": "0",
        "class_type": this.ClassType,
      }
      this.SyllabusRows.push(json)
    }

  }

  generateRows() {
    this.SyllabusRows = []
    this.NewTopics = [];
    if (this.CreatedSyllabus.length > 0) {
      for (let data of this.unfilteredTopicsMaster) {
        var check = false
        for (let topic of this.CreatedSyllabus) {
          if (data.id_topic == topic.id_topic) {
            check = false;
            break;
          }
          else {
            check = true;
          }
        }
        if (check == true) {
          this.NewTopics.push(data);
        }
      }
    }
    else {
      this.NewTopics = this.unfilteredTopicsMaster
    }
    for (var i = 0; i < this.numberOfRows; i++) {
      let json = {
        'id_subject': "0",
        'id_sub_subject': "0",
        'id_topic': "0",
        'hours': "",
        'id_topic_status': "0",
        "subjects": this.Subjects,
        "sub_subjects": this.unfilteredSubSubjects,
        "topics": this.NewTopics,
        "id_class_type": "0",
        "class_type": this.ClassType,
      }
      this.SyllabusRows.push(json)
    }
  }

  getSubSubject(data) {
    data.sub_subjects = []
    data.topics = []
    for (let subSubject of this.unfilteredSubSubjects) {
      if (subSubject.id_subject == data.id_subject) {
        data.sub_subjects.push(subSubject)
      }
    }
    for (let topic of this.NewTopics) {
      if (topic.id_subject == data.id_subject) {
        data.topics.push(topic)
      }
    }
  }

  getMasterTopics(data) {
    data.topics = []
    for (let topic of this.NewTopics) {
      if (topic.id_sub_subject == data.id_sub_subject) {
        data.topics.push(topic)
      }
    }
  }

  getTopicFaculty(data) {
    for (let topic of this.NewTopics) {
      if (topic.id_topic == data.id_topic) {
        this.faculty = topic.faculty
        break;
      }
    }
  }

  removeRow(index) {
    this.SyllabusRows.splice(index, 1);
  }

  saveData() {
    this.isShowMainTable = false;
    var check = false;
    var message: any;
    for (let data of this.SyllabusRows) {
      if (data.id_subject == "0" || data.id_sub_subject == "0" || data.id_topic == "0" || data.hours == "" || data.id_class_type == "0") {
        check = true
        message = "Some fields are empty please fill it first."
        break
      }
      else {
        let hours = data.hours
        //(hours)
        let x = hours.split(".");
        let decimalRightValue = x[1];
        if (!decimalRightValue) {
          decimalRightValue = "00"
        }
        if (parseFloat(decimalRightValue) > 59) {
          check = true
          message = "Please enter a valid fraction of an hour. Fractions are allowed only till .59. Every .01 means 1 minute from an hour."
          break
        }
      }
    }
    if (check == true) {
      swal.fire(message)
    }
    else {
      check = false;
      for (let data of this.CreatedSyllabus) {
        for (let row of this.SyllabusRows) {
          if (data.id_topic == row.id_topic) {
            check = true
            break
          }
        }
      }
      if (check == true) {
        swal.fire("Topic already present in syllabus.")
      }
      else {
        let index = 0;
        for (let row of this.SyllabusRows) {
          index = index + 1
          for (let data of row.topics) {

            if (row.id_topic == data.id_topic) {
              this.faculty = data.faculty
              var json: any;
              if (this.faculty.length == 1) {
                json = {
                  "id_topic": data.id_topic,
                  "topic_name": data.name,
                  "id_topic_status": "0",
                  "id_sub_subject": data.id_sub_subject,
                  "sub_subject_name": data.sub_subject_name,
                  "id_subject": data.id_subject,
                  "subject_name": data.subject_name,
                  "hours": row.hours,
                  "id_faculty": this.faculty[0].id_faculty,
                  "faculty": this.faculty,
                  "id_class_type": row.id_class_type,
                  "class_type": this.ClassType,
                }
              }
              else {
                json = {
                  "id_topic": data.id_topic,
                  "topic_name": data.name,
                  "id_topic_status": "0",
                  "id_sub_subject": data.id_sub_subject,
                  "sub_subject_name": data.sub_subject_name,
                  "id_subject": data.id_subject,
                  "subject_name": data.subject_name,
                  "hours": row.hours,
                  "id_faculty": "0",
                  "faculty": this.faculty,
                  "id_class_type": row.id_class_type,
                  "class_type": this.ClassType,
                }
              }
              if (this.fromIndex != null) {
                this.CreatedSyllabus.splice(this.fromIndex + index, 0, json);
              }
              else {
                this.CreatedSyllabus.push(json)
              }
            }
          }
        }
        this.SyllabusRows = [];
        this.hideAddEditModal();
        this.isAddModal = false
        this.totalHours = ""
        var totalMinutes = 0;
        for (let data of this.SectionSyllabus) {
          let temp = data.hours.split(".");
          let hour = temp[0];
          let minutes = temp[1];
          let tempTotalMinutes = ((+hour) * 60) + (+minutes);
          totalMinutes += tempTotalMinutes

        }
        var hours = Math.floor(totalMinutes / 60);
        var tempminutes = totalMinutes % 60;
        this.totalHours = hours + "." + tempminutes

      }
    }
  }

  back() {
    this.isShowMainTable = true
  }

  saveSectionSyllabus() {
    var check = false
    for (let data of this.CreatedSyllabus) {
      if (data.id_faculty == "0") {
        check = true
        break
      }
    }
    if (check == true) {
      swal.fire("Please assign faculty to respective topic.")
    }
    else {
      //.log(this.CreatedSyllabus.length)
      var length = this.CreatedSyllabus.length;
      var temporaryArray = [];
      for (var i = 0; i < length; i++) {
        if (this.CreatedSyllabus[i].id_topic_status != "2" && this.CreatedSyllabus[i].id_topic_status != "3" && this.CreatedSyllabus[i].id_topic_status != "1") {
          //.log(this.CreatedSyllabus[i].id_topic_status)
          temporaryArray.push(this.CreatedSyllabus[i])
        }
      }
      //.log(this.CreatedSyllabus.length)
      let json = {
        "caller_email": localStorage.getItem("email"),
        "id_section": this.sectionId,
        "id_master_syllabus": this.syllabusId,
        "max_id_topic": this.maxTopicIdithoutScheduleOrUnscheduled,
        "topics": JSON.stringify(temporaryArray)
      }
      this.showLoader = true
      this.SectionSyllabus = []
      this.CreatedSyllabus = []
      this.sectionSyllabusService.addNewSectionSyllabus(json).subscribe(
        data => {

          this.totalHours = ""
          this.showLoader = false
          if (data.status_code == "0") {
            this.SectionSyllabus = data.syllabus
            this.isShowMainTable = true;
            var totalMinutes = 0;
            for (let data of this.SectionSyllabus) {
              let temp = data.hours.split(".");
              let hour = temp[0];
              let minutes = temp[1];
              let tempTotalMinutes = ((+hour) * 60) + (+minutes);
              totalMinutes += tempTotalMinutes

            }
            var hours = Math.floor(totalMinutes / 60);
            var tempminutes = totalMinutes % 60;
            this.totalHours = hours + "." + tempminutes
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
          else {
            this.getAllSectionSyllabus();
            this.isShowMainTable = true
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false
        }
      )
    }

  }

  getAllSectionSyllabus() {
    this.showLoader = true
    this.totalHours = ""
    this.sectionSyllabusService.getAllSectionSyllabus(localStorage.getItem("email"), this.sectionId).subscribe(
      data => {
        this.SectionSyllabus = []
        this.showLoader = false
        if (data.status_code == "0") {
          this.SectionSyllabus = data.syllabus
          var totalMinutes = 0;
          for (let data of this.SectionSyllabus) {
            let temp = data.hours.split(".");
            let hour = temp[0];
            let minutes = temp[1];
            let tempTotalMinutes = ((+hour) * 60) + (+minutes);
            totalMinutes += tempTotalMinutes

          }
          var hours = Math.floor(totalMinutes / 60);
          var tempminutes = totalMinutes % 60;
          this.totalHours = hours + "." + tempminutes

        }
        else {
          this.isShowMainTable = false;
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false
      }
    )
  }

  markAsDone(data) {
    //(data)
    this.showLoader = true
    this.totalHours = ""
    this.sectionSyllabusService.changeSectionSyllabusTopic(localStorage.getItem("email"), data.id_section_syllabus, data.id_topic, data.id_faculty, "3", this.sectionId).subscribe(
      data => {
        this.showLoader = false
        this.SectionSyllabus = []
        if (data.status_code == "0") {
          this.SectionSyllabus = data.syllabus
          var totalMinutes = 0;
          for (let data of this.SectionSyllabus) {
            let temp = data.hours.split(".");
            let hour = temp[0];
            let minutes = temp[1];
            let tempTotalMinutes = ((+hour) * 60) + (+minutes);
            totalMinutes += tempTotalMinutes

          }
          var hours = Math.floor(totalMinutes / 60);
          var tempminutes = totalMinutes % 60;
          this.totalHours = hours + "." + tempminutes
          //this.showAlertDiv(data.status_message)
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false
      }
    )
  }
  markAsCancelled(data) {
    if (data.id_topic_status == "1") {
      swal.fire({
        title: '',
        text: "Cancelling the topic will marked all remaining scheduled lectures as ' not required' . Are you sure you want to continue?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#8B0000',
        cancelButtonColor: '#428e63',
        confirmButtonText: 'Yes, remove it!'
      }).then((result) => {
        if (result.value) {
          this.Coes = [];
          this.showLoader = true;
          this.showLoader = true
          this.totalHours = ""
          this.sectionSyllabusService.changeSectionSyllabusTopic(localStorage.getItem("email"), data.id_section_syllabus, data.id_topic, data.id_faculty, "4", this.sectionId).subscribe(
            data => {
              this.showLoader = false
              this.SectionSyllabus = []
              if (data.status_code == "0") {
                this.SectionSyllabus = data.syllabus
                var totalMinutes = 0;
                for (let data of this.SectionSyllabus) {
                  let temp = data.hours.split(".");
                  let hour = temp[0];
                  let minutes = temp[1];
                  let tempTotalMinutes = ((+hour) * 60) + (+minutes);
                  totalMinutes += tempTotalMinutes

                }
                var hours = Math.floor(totalMinutes / 60);
                var tempminutes = totalMinutes % 60;
                this.totalHours = hours + "." + tempminutes

                this.showAlertComponent(data['status_code'], data['status_message']);
              }
              else {
                this.showAlertComponent(data['status_code'], data['status_message']);
              }
            },
            err => {
              this.showLoader = false
            }
          )
        }
      })
    }
    else {
      this.showLoader = true
      this.totalHours = ""
      this.sectionSyllabusService.changeSectionSyllabusTopic(localStorage.getItem("email"), data.id_section_syllabus, data.id_topic, data.id_faculty, "4", this.sectionId).subscribe(
        data => {
          this.showLoader = false
          this.SectionSyllabus = []
          if (data.status_code == "0") {
            this.SectionSyllabus = data.syllabus
            var totalMinutes = 0;
            for (let data of this.SectionSyllabus) {
              let temp = data.hours.split(".");
              let hour = temp[0];
              let minutes = temp[1];
              let tempTotalMinutes = ((+hour) * 60) + (+minutes);
              totalMinutes += tempTotalMinutes

            }
            var hours = Math.floor(totalMinutes / 60);
            var tempminutes = totalMinutes % 60;
            this.totalHours = hours + "." + tempminutes

            this.showAlertComponent(data['status_code'], data['status_message']);
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false
        }
      )
    }


  }

  changeDurationOfTopic(data) {
    this.tempTopicData = data;
    this.topicDuration = data.hours
    this.isChangeDuration = true
  }

  saveTopicDuration() {
    if (!this.topicDuration) {
      swal.fire("Please enter duration")
    }
    else {
      let hours = this.topicDuration
      //(hours)
      let x = hours.split(".");
      let decimalRightValue = x[1];
      if (!decimalRightValue) {
        decimalRightValue = "00"
      }
      if (parseFloat(decimalRightValue) > 59 || decimalRightValue.length != 2) {
        swal.fire("Please enter a valid fraction of an hour. Fractions are allowed only till .59. Every .01 means 1 minute from an hour.");
      }
      else {
        for (let data of this.CreatedSyllabus) {
          if (data.id_topic == this.tempTopicData.id_topic) {
            data.hours = this.topicDuration;
            break;
          }
        }
        this.isChangeDuration = false
      }
    }

  }

  modifySyllabus() {

    this.isShowMainTable = false;
    this.CreatedSyllabus = [];
    var i = 0;
    for (let data of this.SectionSyllabus) {
      if (data.id_master_syllabus != null) {
        this.syllabusId = data.id_master_syllabus
      }
      //(this.syllabusId)
      let json = {
        "id_topic": data.id_topic,
        "topic_name": data.topic_name,
        "id_sub_subject": data.id_sub_subject,
        "sub_subject_name": data.sub_subject_name,
        "id_subject": data.id_subject,
        "subject_name": data.subject_name,
        "hours": data.hours,
        "id_faculty": data.id_faculty,
        "id_topic_status": data.id_topic_status,
        "id_class_type": data.id_class_type,
        "class_type": data.class_type,
        "sequence_number": data.sequence_number,
        "faculty": data.faculty,
      }
      this.CreatedSyllabus.push(json)

      if (data.id_topic_status == "1" || data.id_topic_status == "2" || data.id_topic_status == "3" || data.id_topic_status == "4") {
        this.maxTopicIndexWithoutScheduleOrUnscheduled = "" + i;
        this.maxTopicIdithoutScheduleOrUnscheduled = data.id_topic
      }
      i++;
    }

  }
  addNewSyllabus() {
    this.numberOfRows = 1;
    if (this.CreatedSyllabus.length == 0) {
      swal.fire("Please first import master syllabus.")
    }
    else {
      this.NewTopics = [];
      this.isAddModal = true;
      this.searchText = ""
      this.SyllabusRows = []
      if (this.CreatedSyllabus.length > 0) {
        for (let data of this.unfilteredTopicsMaster) {
          var check = false
          for (let topic of this.CreatedSyllabus) {
            if (data.id_topic == topic.id_topic) {
              check = true;
              break;
            }
            // else {
            //   check = true;
            // }
          }
          if (check == false) {
            this.NewTopics.push(data);
          }
        }
      }
      else {
        this.NewTopics = this.unfilteredTopicsMaster
      }
      for (var i = 0; i < this.Rows[0]; i++) {
        let json = {
          'id_subject': "0",
          'id_sub_subject': "0",
          'id_topic': "0",
          'hours': "",
          'id_topic_status': "0",
          "subjects": this.Subjects,
          "sub_subjects": this.unfilteredSubSubjects,
          "topics": this.NewTopics,
          "id_class_type": "0",
          "class_type": this.ClassType,
        }
        this.SyllabusRows.push(json)
      }
    }

  }

  showAlertDiv(message) {
    var that = this
    that.alertDiv = true
    this.sucessAlertMessage = message
    setTimeout(function () {
      that.alertDiv = false
    }, 5000);
  }

  onBack() {
    this.router.navigateByUrl('/section master')
  }

  convertMinutesInHour(minutes, totalHours) {
    if (minutes != null) {
      var hour = Math.floor(parseInt(minutes) / 60)
      var newMinutes = Math.floor(parseInt(minutes) % 60)
      var temp = hour + "." + newMinutes
      if (parseFloat(temp) > totalHours) {
        return totalHours
      }
      else {
        return hour + "." + newMinutes
      }

    }
    else return "0"
  }
  getRemainingHours(totalHours, completdMinutes, topicStatus) {
    if (topicStatus == "3" || topicStatus == "4") {
      return "0"
    }
    else {
      if (completdMinutes != null) {
        var temp = totalHours.split(".")
        var decimalFirstMinutes = parseInt(temp[0]) * 60
        var decimalSecondMinutes = parseInt(temp[1])
        var totalMinutes = decimalFirstMinutes + decimalSecondMinutes
        var differnce = totalMinutes - parseInt(completdMinutes)
        if (differnce < 0) {
          return "0"
        }
        else {
          var hours = Math.floor(differnce / 60);
          var minutes = differnce % 60;


          if (minutes < 10) {
            var temporary = hours + ".0" + minutes
            if (parseFloat(temporary) > totalHours) {
              return totalHours
            }
            else {
              return temporary
            }
          }
          else {
            var temporary = hours + "." + minutes
            if (parseFloat(temporary) > totalHours) {
              return totalHours
            }
            else {
              return temporary
            }
          }

        }

      }
      else {
        return totalHours
      }
    }


  }


  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    //("status" + status);
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.SectionSyllabus.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  }
}
