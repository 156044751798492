import { Component, OnInit } from '@angular/core';
import { BookMasterService } from '../book-master/book-master.service'
import swal from 'sweetalert2'
import { Router } from '@angular/router';
@Component({
  selector: 'app-library-dashboard',
  templateUrl: './library-dashboard.component.html',
  styleUrls: ['./library-dashboard.component.css'],
  providers: [BookMasterService]
})
export class LibraryDashboardComponent implements OnInit {
  isLibraryDashboard = true;
  tableName = ""
  showLoader = false;
  BookTypes = [];
  BookTitles = [];
  searchText: any;
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  BookCopies = [];
  BookCopiesInLibrary = [];
  BookCopiesDamaged = [];
  BookCopiesLost = [];
  booksDataArray = [];
  AllotedBookCopies = [];

  constructor(private bookMasterService: BookMasterService, private router: Router) { 
    if (localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "1") {
      this.getAllBookTitle()
      this.getAllBookType()
      this.getAllBookQuantityForCopies()
      this.getAllAvailableCopiesInLibrary()
      this.getAllAvailableCopiesDamaged()
      this.getAllAvailableCopiesLost()
      this.getAllAllotedCopiesInLibrary()
    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    // this.getAllBookTitle()
    // this.getAllBookType()
    // this.getAllBookQuantityForCopies()
    // this.getAllAvailableCopiesInLibrary()
    // this.getAllAvailableCopiesDamaged()
    // this.getAllAvailableCopiesLost()
    // this.getAllAllotedCopiesInLibrary()
  }


  getAllBookType() {
    this.showLoader = true;
    this.BookTypes = []
    this.bookMasterService.getAllBookType(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.BookTypes = data.book_types
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllBookTitle() {
    this.showLoader = true;
    this.BookTitles = []
    this.bookMasterService.getAllBookTitle(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.BookTitles = data.book_titles
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllBookQuantityForCopies() {
    this.showLoader = true;
    this.BookCopies = []
    this.bookMasterService.getAllBookQuantityForCopies(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.BookCopies = data.book_copies
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }
  showTable(tableName, booksDataArray) {
    this.tableName = tableName;
    this.booksDataArray = booksDataArray;
    this.isLibraryDashboard = false;
  }
  showLibraryDashboard(value) {
    this.isLibraryDashboard = value;
  }


  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }

  getTotalValue(dataArray) {
    let totalPrice = 0;
    for (let data of dataArray) {
      totalPrice = totalPrice + parseFloat(data.price)
    }
    return totalPrice.toFixed(2);
  }

  getAllAvailableCopiesInLibrary() {
    this.showLoader = true;
    this.BookCopiesInLibrary = []
    this.bookMasterService.getAllAvailableCopiesInLibrary(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.BookCopiesInLibrary = data.book_copies
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllAvailableCopiesDamaged() {
    this.showLoader = true;
    this.BookCopiesDamaged = []
    this.bookMasterService.getAllAvailableCopiesDamaged(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.BookCopiesDamaged = data.book_copies
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllAvailableCopiesLost() {
    this.showLoader = true;
    this.BookCopiesLost = []
    this.bookMasterService.getAllAvailableCopiesLost(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.BookCopiesLost = data.book_copies
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllAllotedCopiesInLibrary() {
    this.showLoader = true;
    // this.UnfilterdBookCopies = []
    this.bookMasterService.getAllAllotedCopiesInLibrary(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.AllotedBookCopies = data.book_copies
          // this.filterCopies()
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

}
