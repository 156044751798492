import { Component } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NoticeBoardService } from "./notice-board.service";
import swal from "sweetalert2";
import { DatePipe } from "@angular/common";
import { Helper } from '../helper';
@Component({
  selector: "notice-board",
  templateUrl: "notice-board.component.html",
  styleUrls: ["notice-board.component.scss"],
  providers: [NoticeBoardService, DatePipe],
})
export class NoticeBoardComponent {
  isPostNoticeModal = false;
  showLoader = false;
  showAlert = false;
  alertMsg: any;
  alertType: any;
  noticeForm: FormGroup;
  initEditText: any;
  noticeTags = [];
  editNoticeData = { id_notice_board: "" };
  filterObject = { offset: 1, limit: 5 };
  tableArray = [];
  searchText: any;
  totalNotices: any;
  baseUrl = Helper.baseURL;
  postId: any;
  pdfUploadoptions: Object = {
    url: Helper.pdfFileUploadURL,
    params: { post_id: this.postId },
  };
  imageUploadOptions: Object = {
    url: Helper.imageUploadURL,
    params: { post_id: this.postId },
  };
  fileName = "";
  fileType = "";

  constructor(
    private noticeBoardService: NoticeBoardService,
    private datePipe: DatePipe
  ) {
    this.initializeTiny();
    this.buildForm();
    this.getAllNotices();
  }

  initializeTiny() {
    this.initEditText = {
      plugins: "image",
      readonly: 1,
      toolbar: "sizeselect | bold italic | fontselect |  fontsizeselect",
      images_upload_url:
        "http://dakshana.beta.vendorZApp.in/service/upload_image.php",
      convert_urls: false,
      images_upload_handler: function (blobInfo, success, failure) {
        var xhr, formData;
        xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.open(
          "POST",
          "http://dakshana.beta.vendorZApp.in/service/upload_image.php"
        );
        xhr.onload = function () {
          var json;

          if (xhr.status != 200) {
            failure("HTTP Error: " + xhr.status);
            return;
          }
          json = JSON.parse(xhr.responseText);

          if (!json || typeof json.generatedName != "string") {
            failure("Invalid JSON: " + xhr.responseText);
            return;
          }
          success(json.generatedName);
        };
        formData = new FormData();
        formData.append("file", blobInfo.blob(), blobInfo.filename());
        xhr.send(formData);
      },
    };
  }

  buildForm() {
    this.noticeForm = new FormGroup({
      title: new FormControl("", [Validators.required]),
      description: new FormControl("", [Validators.required]),
      audiance_type: new FormControl("0"),
      tag: new FormControl(""),
      tags: new FormControl(""),
      post_date: new FormControl("", [Validators.required]),
      file: new FormControl(""),
      file_type: new FormControl(""),
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.noticeForm.controls[controlName].hasError(errorName);
  };

  addTags(event) {
    console.log(event);
    let value = event.target.value;
    this.noticeTags.push(value);
    this.noticeForm.patchValue({ tag: "" });
  }

  removeTags(index) {
    this.noticeTags.splice(index, 1);
  }

  openAddNoteModal() {
    this.fileName = "";
    this.fileType = "";
    this.noticeTags=[];
    this.isPostNoticeModal = true;
  }

  closePostNoticeModal() {
    this.noticeForm.reset();
    this.editNoticeData.id_notice_board='';
    this.fileName = "";
    this.fileType = "";
    this.noticeTags=[];
    this.isPostNoticeModal = false;
  }

  handleFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];

    if (files && file) {
      var reader = new FileReader();

      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.noticeForm.patchValue({
      file: btoa(binaryString),
    });
  }

  handleUpload(data, type): void {
    this.showLoader = true;
    if (data && data.response) {
      this.showLoader = false;
      data = JSON.parse(data.response);
      if (data.status) {
        if (this.fileType == "image") {
          this.fileName = data.generatedName;
        }
        else {
          this.fileName = data.originalName;
        }

      } else {
        swal.fire(data.msg);
      }
    }
  }

  selectFileType(type) {
    this.fileType = type;
    this.fileName = "";
  }

  submit() {
    let json = this.noticeForm.getRawValue();
    json["caller_email"] = localStorage.getItem("email");
    json["tags"] = JSON.stringify(this.noticeTags);
    json["file_name"] = this.fileName;
    json["file_type"] = this.fileType;
    if (this.editNoticeData.id_notice_board == "") {
      this.addNewNotice(json);
    } else {
      json["id_notice_board"] = this.editNoticeData.id_notice_board;
      this.updateNotice(json);
    }
  }

  getAllNotices() {
    this.showLoader = true;
    this.noticeBoardService
      .getAllNotices(
        localStorage.getItem("email"),
        this.filterObject.offset,
        this.filterObject.limit
      )
      .subscribe(
        (data) => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.tableArray = data.notices;
            this.totalNotices = data.total_notices;
          } else {
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          }
        },
        (err) => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.");
        }
      );
  }

  getImageFromURL(fileName) {
    return Helper.imageURL + fileName;
  }

  addNewNotice(json) {
    this.showLoader = true;
    this.noticeBoardService.addNewNotice(json).subscribe(
      (data) => {
        this.showLoader = false;
        if (data.status_code == 0) {
          this.showAlertComponent(data["status_code"], data["status_message"]);
          this.filterObject.offset = 1;
          this.closePostNoticeModal();
          this.getAllNotices();
        } else {
          this.showAlertComponent(data["status_code"], data["status_message"]);
        }
      },
      (err) => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.");
      }
    );
  }

  updateNotice(json) {
    this.showLoader = true;
    this.noticeBoardService.updateNotice(json).subscribe(
      (data) => {
        this.showLoader = false;
        if (data.status_code == 0) {
          this.showAlertComponent(data["status_code"], data["status_message"]);
          this.closePostNoticeModal();
          this.getAllNotices();
        } else {
          this.showAlertComponent(data["status_code"], data["status_message"]);
        }
      },
      (err) => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.");
      }
    );
  }

  editNotice(data) {
    this.isPostNoticeModal = true;
    this.editNoticeData.id_notice_board = data.id_notice_board;
    this.fileName = data.file_name;
    this.fileType = data.file_type;
    this.noticeForm.patchValue({
      title: data.title,
      description: data.description,
      audiance_type: data.audiance_type,
      post_date: this.datePipe.transform(data.post_date, "yyyy-MM-dd"),
    });
    this.noticeTags = JSON.parse(data.tags);
  }

  removeNotice(data) {
    var that = this;
    swal
      .fire({
        title: "",
        text:
          "Deleting an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to remove this Notice?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#8B0000",
        cancelButtonColor: "#428e63",
        confirmButtonText: "Yes, remove it!",
      })
      .then((result) => {
        if (result.value) {
          that.showLoader = true;
          that.noticeBoardService
            .removeNotice(
              localStorage.getItem("email"),
              data.id_notice_board
            )
            .subscribe(
              (data) => {
                that.showLoader = false;
                if (data.status_code == 0) {
                  that.showAlertComponent(
                    data["status_code"],
                    data["status_message"]
                  );
                  that.getAllNotices();
                } else {
                  that.showAlertComponent(
                    data["status_code"],
                    data["status_message"]
                  );
                }
              },
              (err) => {
                swal.fire("Something went wrong. Please try again later.");
              }
            );
        }
      });
  }

  changeStatus(data) {
    var that = this;
    var changeStatusId: any;
    if (data.id_status == 0) {
      changeStatusId = 1;
    } else {
      changeStatusId = 0;
    }
    if (changeStatusId == 1) {
      swal
        .fire({
          title: "",
          text:
            "Deactivating an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to deactivate this Notice?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#8B0000",
          cancelButtonColor: "#428e63",
          confirmButtonText: "Yes, deactivate it!",
        })
        .then((result) => {
          if (result.value) {
            that.showLoader = true;
            that.noticeBoardService
              .changeStatus(
                localStorage.getItem("email"),
                data.id_notice_board,
                changeStatusId
              )
              .subscribe(
                (data) => {
                  that.showLoader = false;
                  if (data.status_code == 0) {
                    that.showAlertComponent(
                      data["status_code"],
                      data["status_message"]
                    );
                    that.getAllNotices();
                  } else {
                    that.showAlertComponent(
                      data["status_code"],
                      data["status_message"]
                    );
                  }
                },
                (err) => {
                  swal.fire("Something went wrong. Please try again later.");
                }
              );
          } else {
            if (changeStatusId == 0) {
              (<HTMLInputElement>(
                document.getElementById(data.id_notice_board)
              )).checked = false;
            } else {
              (<HTMLInputElement>(
                document.getElementById(data.id_notice_board)
              )).checked = true;
            }
          }
        });
    } else {
      that.showLoader = true;
      that.noticeBoardService
        .changeStatus(
          localStorage.getItem("email"),
          data.id_notice_board,
          changeStatusId
        )
        .subscribe(
          (data) => {
            that.showLoader = false;
            if (data.status_code == 0) {
              that.showAlertComponent(
                data["status_code"],
                data["status_message"]
              );
              that.getAllNotices();
            } else {
              that.showAlertComponent(
                data["status_code"],
                data["status_message"]
              );
            }
          },
          (err) => {
            swal.fire("Something went wrong. Please try again later.");
          }
        );
    }

    //.log(data.id_status)
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    } else {
      this.alertType = "failure";
    }
  }

  hideAlert(val) {
    this.showAlert = val;
  }

  pageChanged(value) {
    this.filterObject.offset = value;
    this.getAllNotices();
  }
}
