import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UploadService {
  FOLDER = 'Dakshana/';
  bucket = new S3(
    {
      accessKeyId: environment.awsAccessKeyId,
      secretAccessKey: environment.awsSecretAccessKey,
      region: environment.awsRegion
    }
  );
  constructor() { }
  uploadFile(folder, file) {

    const contentType = file.type;



    const params = {
      Bucket: environment.s3BucketName,
      Key:
        folder + "/" + file.name,
      Body: file,
      // ACL: 'public-read',
      // ContentType: contentType
    };

    var that = this
    return new Promise(

      function (resolve, reject) {
        that.bucket.upload(params, function (err, data) {
          if (err) {
            console.error(err); // an error occurred
          }
          else {
            resolve(data)

          }
        });
      }
    );
  }


  getFile(path) {
    const params = {
      Bucket: environment.s3BucketName,
      Delimiter: '/',
      Prefix: path + '/'
    };

    var that = this
    return new Promise(

      function (resolve, reject) {
        that.bucket.listObjects(params, function (err, data) {
          if (err) {
            console.error(err); // an error occurred
          }
          else {
            resolve(data.Contents)

          }
        });
      }
    );

  }

  getDocument(fileObject) {
    var that = this
    return new Promise(
      function (resolve, reject) {
        const params = {
          Bucket: environment.s3BucketName,
          Key: fileObject.Key,
        };
        that.bucket.getObject(params, function (err, data) {
          if (err) {
            console.error(err); // an error occurred
          }
          else {
            
            resolve(data)
          }
        });
      }
    );

  }




  deleteFile(path) {
    const params = {
      Bucket: environment.s3BucketName,
      Key: path,
    };

    var that = this
    return new Promise(

      function (resolve, reject) {
        that.bucket.deleteObject(params, function (err, data) {
          if (err) {
            console.error(err); // an error occurred
          }
          else {
            //(data)
            resolve(data)

          }
        });
      }
    );
  }


  printHelloWithPromise(name) {
    let promise = new Promise(function (resolve, reject) {
      // In place of the code given below, one can call REST APIs
      // For errors, one can choose to reject the promise;
      // For successful processing, promise can be resolved
      //
      const responseVar = 1 + 1;
      if (responseVar == 2) {
        resolve("Success: " + name);
      } else {
        reject("Error: " + name);
      }
    });
    return promise;
  }


  listObject(coeRollNumber, fileName) {
    const that = this;
    let dataAvailable = false;
    let promise = new Promise(function (resolve, reject) {

      const params = {
        Bucket: environment.s3BucketName,
        Prefix: coeRollNumber + "/" + fileName
      };
      that.bucket.listObjects(params, function (err, data) {
        if (err) {
          console.error(err); // an error occurred
          reject(false);
          // dataAvailable = false;
        }
        else {
          // dataAvailable = true;
          if (data.Contents.length > 0) {
            // that.getObjectWithKey(data, coeRollNumber);

            let getObjectPromise = that.getObjectWithKey(data, coeRollNumber);
            getObjectPromise.then(
              function (resolveOutput) {
                resolve(getObjectPromise)
              }, function (rejectOutput) {
                reject(false);
              }
            );
            // promiseArray.push(getListPromise)
          }
          else {
            reject(false);
          }
        }
      });

    });
    return promise;
  }

  getObjectWithKey(data, coeRollNumber) {
    const that = this;
    let promise = new Promise(function (resolve, reject) {
      const params = {
        Bucket: environment.s3BucketName,
        Key: data.Contents[0]['Key'],
      };
      that.bucket.getObject(params, function (err, data) {
        if (err) {
          reject(false);
        }
        else {
          if (data) {
            data['coe_roll_number'] = coeRollNumber
            resolve(data)
          }
          else {
            reject(false);
          }

        }
      });
    });
    return promise;
  }


  getDocumentByType(fileObject) {
    var that = this

    const params = {
      Bucket: environment.s3BucketName,
      Prefix: fileObject
      // Prefix:  fileObject
    };
    that.bucket.listObjects(params, function (err, data) {
      if (err) {
        console.error(err); // an error occurred
      }
      else {
        // this.getDocumentData(data.Contents)

        //data.Contents)
        return data.Contents;
      }
    });
    // return new Promise(

    //   function (resolve, reject) {
    //     const params = {
    //       Bucket: 'dakshanaawsbucket',
    //       Prefix: fileObject
    //       // Prefix:  fileObject
    //     };
    //     that.bucket.listObjects(params, function (err, data) {
    //       if (err) {
    //         console.error(err); // an error occurred
    //       }
    //       else {
    //         // this.getDocumentData(data.Contents)

    //         resolve(data.Contents)

    //       }
    //     });
    //   }
    // );

  }


  getDocumentData(fileObject) {
    var that = this
    return new Promise(

      function (resolve, reject) {
        const params = {
          Bucket: environment.s3BucketName,
          Key: fileObject.Key,
        };
        that.bucket.getObject(params, function (err, data) {
          if (err) {
            console.error(err); // an error occurred
          }
          else {
            resolve(data)
          }
        });
      }
    );

  }


}

