import { Component, OnInit } from '@angular/core';
import { FacultyDashBoardService } from './faculty-dashboard.service'
import { ViewChild } from '@angular/core'

import { BaseChartDirective } from 'ng2-charts/ng2-charts';
import { Router } from '@angular/router'
import { Helper } from '../helper'
@Component({
  selector: 'app-faculty-dashboard',
  templateUrl: './faculty-dashboard.component.html',
  styleUrls: ['./faculty-dashboard.component.css'],
  providers: [FacultyDashBoardService, Helper]
})
export class FacultyDashboardComponent implements OnInit {
  showLoader = false;
  public attendancebarChartLabels: String[];
  public attendancebarChartType: string;
  public attendancebarChartLegend = false;
  public attendancebarChartData: any[]
  Years = [];
  Months = [];
  selectedYear: any;
  selectedMonth: any;
  facultyId: any;
  monthName: any
  Attendance = [];
  Subjects = [];
  SubjectGraph = [];
  Sections = [];
  sectionId: any;
  SectionSyllabus = [];
  SectionSyllabusGraph = [];
  examTables = [];
  examTablesHeading = [];
  examTableFormattedData = [];
  public examScorebarChartLabels: String[];
  public examScorebarChartType: string;
  public examScorebarChartLegend = false;
  public examScorebarChartData: any[];
  lineScholarExamChartLabels = [];
  lineScholarExamChartData = [];
  lineExamChartLegend = false
  schlarPerformancebarChartType: any;
  ScholarSelectionProbabilty = [];
  schlarPerformancebarChartLegend = false;
  schlarPerformancebarChartLabels = [];
  schlarPerformancebarChartData = [];
  sectionName;
  showExamLineChart = false
  alertMsg = "message";
  constructor(private facultyDashBoardService: FacultyDashBoardService, private helper: Helper, private router: Router) {

    this.Years = helper.getArrayOfYears();
    this.Months = helper.getArrayOfMonths();
    this.selectedYear = this.Years[0];
    this.selectedMonth = (new Date()).getMonth() + 1;
    this.facultyId = localStorage.getItem("id_user")
    if (localStorage.getItem("isLogin") == "true") {

      this.getSection();

    }
    else {
      this.router.navigateByUrl('/');
    }


  }

  getSection() {
    this.showLoader = true
    this.facultyDashBoardService.getAllSections(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.Sections = data.sections
          this.sectionId = this.Sections[0].id_section
          this.getDashboardGraphData();
        }
      },
      err => {
        this.showLoader = false
      }
    )
  }
  ngOnInit() {
  }
  getDashboardGraphData() {
    this.showLoader = true
    this.showExamLineChart = false;
    for (let data of this.Sections) {
      if (this.sectionId == data.id_section) {
        this.sectionName = data.name;
      }
    }
    this.facultyDashBoardService.getDashboardGraphData(localStorage.getItem("email"), this.facultyId, this.selectedYear, this.selectedMonth, this.monthName, this.sectionId).subscribe(
      data => {
        this.showLoader = false
        // this.clearGraphData();
        if (data.status_code == "0") {
          this.Attendance = data.attendance
          this.Subjects = data.syllabus
          this.SectionSyllabus = data.syllabus_section
          this.examTables = data.exam_details
          this.examTablesHeading = data.subjects
          this.generatAttendanceGraph()
          this.generatSubjectGraph();
          this.generatSectionSyllabusGraph();
          this.generateExamTableData();
          this.getScholarPerformanceGraphAdminDashBoard();
        }
      },
      err => {
        this.showLoader = false
      }
    )
  }
  getScholarPerformanceGraphAdminDashBoard() {
    this.showLoader = true
    this.facultyDashBoardService.getScholarPerformanceGraphAdminDashBoard(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.ScholarSelectionProbabilty = data.selection_probability
          this.generateScholarPerforamnceGraph();

        }
      },
      err => {
        this.showLoader = false
      }
    )
  }
  clearGraphData() {
    this.schlarPerformancebarChartLegend = false;
    this.schlarPerformancebarChartLabels = [];
    this.schlarPerformancebarChartData = [];
    this.lineExamChartLegend = false;
    this.lineScholarExamChartLabels = [];
    this.lineScholarExamChartData = [];
  }
  generateScholarPerforamnceGraph() {
    this.schlarPerformancebarChartType = 'bar';
    this.schlarPerformancebarChartLegend = true;
    this.schlarPerformancebarChartLabels = [];
    this.schlarPerformancebarChartData = []
    for (let data of this.ScholarSelectionProbabilty) {
      this.schlarPerformancebarChartLabels.push(data.section_name)
    }
    var iitData = []
    for (let data of this.ScholarSelectionProbabilty) {
      iitData.push(parseInt(data.iit_probability))
    }
    var nitData = []
    for (let data of this.ScholarSelectionProbabilty) {
      nitData.push(parseInt(data.nit_probability))
    }
    var neetData = []
    for (let data of this.ScholarSelectionProbabilty) {
      neetData.push(parseInt(data.neet_probability))
    }
    this.schlarPerformancebarChartData = [
      { data: iitData, label: 'IIT' },
      { data: nitData, label: 'NIT' },
      { data: neetData, label: 'NEET' }
    ];
    this.schlarPerformancebarChartLegend = true;
  }
  generateExamTableData() {
    var testdata = [];
    for (let exam of this.examTables) {
      var totalMarksGain = 0;
      for (let es of exam.exam_subjects) {
        for (let heading of this.examTablesHeading) {

          if (heading.id_subject != "0") {
            //.log("heading.id_subject--" + heading.id_subject);
            var data = {};
            if (heading.id_subject == es.id_subject) {
              data['sub_name'] = heading.name;
              var subjectMarksAverage = (+es.marks) / (+exam.total_students)
              totalMarksGain = totalMarksGain + (+es.marks)
              data['marks'] = (subjectMarksAverage * 100) / ((+exam.total_marks) / exam.exam_subjects.length)
            }
            else {
              if ((this.examTablesHeading.length - 1) != exam.exam_subjects.length) {
                data['sub_name'] = heading.name;
                data['marks'] = 0;
              }
            }
            if (data['sub_name']) {
              this.examTableFormattedData.push(data);
            }
          }
        }
      }
      var averageOfTotalMarksGain = totalMarksGain / (+exam.total_students)
      let json = {
        'sub_name': "Total",
        'marks': (averageOfTotalMarksGain * 100) / (+exam.total_marks)
      }
      this.examTableFormattedData.push(json);
    }
    //.log(this.examTableFormattedData)
    this.generateExamScoreGraph();
  }
  generateExamScoreGraph() {
    this.lineExamChartLegend = true

    this.examScorebarChartData = [];
    this.lineScholarExamChartData = [];
    this.lineScholarExamChartLabels = [];
    this.showExamLineChart = false;
    for (let data of this.examTables) {
      this.lineScholarExamChartLabels.push(data.exam_name)
    }
    for (let exam of this.examTablesHeading) {
      var json = {}
      var subjectGainMarkPercentage = []
      for (let subject of this.examTableFormattedData) {

        if (subject.sub_name == exam.name) {
          subjectGainMarkPercentage.push(subject.marks + "");
          json = {
            data: subjectGainMarkPercentage, label: exam.name
          }
        }

      }
      this.lineScholarExamChartData.push(json);
    }
    this.showExamLineChart = true
  }

  generatAttendanceGraph() {
    this.attendancebarChartType = 'bar';
    this.attendancebarChartLegend = true;
    this.attendancebarChartLabels = [];
    this.attendancebarChartData = []
    for (let data of this.Attendance) {
      this.attendancebarChartLabels.push(data.section_name)
    }
    var classData = []
    for (let data of this.Attendance) {
      classData.push(parseInt(data.class_attendence))
    }
    var hostelData = []
    for (let data of this.Attendance) {
      hostelData.push(parseInt(data.hostel_attendence))
    }
    this.attendancebarChartData = [
      { data: classData, label: 'Class' },
      { data: hostelData, label: 'Hostel' }
    ];
  }

  generatSubjectGraph() {
    this.SubjectGraph = [];
    for (let data of this.Subjects) {
      var pieChartLabels: string[] = ['Completed', 'Not-completed'];
      var completed = (data.completed_syllabus).toFixed(2);
      var not_completed = (data.not_completed_syllabus).toFixed(2);
      var pieChartData: number[] = [completed, not_completed];
      let json = {
        "coe_name": data.coe_name,
        "pieChartType": 'pie',
        "pieChartLabels": pieChartLabels,
        "pieChartData": pieChartData
      }

      this.SubjectGraph.push(json)
    }

  }

  generatSectionSyllabusGraph() {
    this.SectionSyllabusGraph = [];
    for (let data of this.SectionSyllabus) {
      var pieChartLabels: string[] = ['Completed', 'Not-completed'];
      var completed = (data.completed_syllabus).toFixed(2);
      var not_completed = (data.not_completed_syllabus).toFixed(2);
      var pieChartData: number[] = [completed, not_completed];
      let json = {
        "section_name": data.section_name,
        "pieChartType": 'pie',
        "pieChartLabels": pieChartLabels,
        "pieChartData": pieChartData
      }

      this.SectionSyllabusGraph.push(json)
    }

  }
}
