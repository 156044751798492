import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DifficultLevelService } from './difficult-level.service';
import swal from 'sweetalert2'
@Component({
  selector: 'difficult-level',
  templateUrl: './difficult-level.component.html',
  styleUrls: ['./difficult-level.component.css'],
  providers: [DifficultLevelService]
})
export class DifficultLevelComponent implements OnInit {
  statusMessage: any;
  showLoader = false;
  DifficultyLevels = [];
  showAlert = false;
  alertMsg: any;
  alertType: any;
  constructor(private router: Router, private difficultLevelService: DifficultLevelService) {

    if (localStorage.getItem("isLogin") == "true") {
      this.getAllDifficultLevel()
    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }
  getAllDifficultLevel() {
    this.showLoader = true;
    this.difficultLevelService.getAllDifficultLevel(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == 0) {
          this.DifficultyLevels = data["difficulty_levels"];
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }
  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }

}
