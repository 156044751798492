import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TimeForQuestionService } from './time-for-question.service';
import swal from 'sweetalert2'
@Component({
  selector: 'time-for-question',
  templateUrl: './time-for-question.component.html',
  styleUrls: ['./time-for-question.component.css'],
  providers: [TimeForQuestionService]
})
export class TimeForQuestionComponent implements OnInit {
  statusMessage: any;
  showLoader = false;
  TimeForQuestions = [];
  showAlert = false;
  alertMsg: any;
  alertType: any;
  constructor(private router: Router, private timeForQuestionService: TimeForQuestionService) {

    if (localStorage.getItem("isLogin") == "true") {
      this.getAllTimeForQuestion()
    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  getAllTimeForQuestion() {
    this.showLoader = true;
    this.timeForQuestionService.getAllTimeForQuestion(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == 0) {
          this.TimeForQuestions = data["time_for_questions"];
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }


  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    //("status" + status);
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }
}
