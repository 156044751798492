import { Component, OnInit } from '@angular/core';
import { ManagementDashBoardService } from './management-dashboard.service'
import { Router } from '@angular/router'
import swal from 'sweetalert2'
@Component({
  selector: 'app-management-dashboard',
  templateUrl: './management-dashboard.component.html',
  styleUrls: ['./management-dashboard.component.css'],
  providers: [ManagementDashBoardService]
})
export class ManagementDashboardComponent implements OnInit {
  showLoader = false
  Coes = []
  coeId = "0"
  coeName: any;
  Attendance = []
  CoeSyllabus = []
  public attendancebarChartLabels: String[];
  public attendancebarChartType: string;
  public attendancebarChartLegend = false;
  public attendancebarChartData: any[]
  public syllabusbarChartLabels: String[];
  public syllabusbarChartType: string;
  public syllabusbarChartLegend = false;
  public syllabusbarChartData: any[];
  Subjects = [];
  SubjectGraph = [];
  CoeLectures = [];
  public lecturebarChartLabels: String[];
  public lecturebarChartType: string;
  public lecturebarChartLegend = false;
  public lecturebarChartData: any[];
  onTimeLecturecoeId: any
  constructor(private managementDashBoardService: ManagementDashBoardService, private router: Router) {

    if (localStorage.getItem("isLogin") == "true") {
      this.getCoes();

    }
    else {
      this.router.navigateByUrl('/');
    }

  }
  getCoes() {
    this.showLoader = true
    this.managementDashBoardService.getActiveCoe(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.Coes = data.coes
          this.coeId = this.Coes[0].id_coe
          this.onTimeLecturecoeId = this.Coes[0].id_coe
          this.getSubjectsCovered();
          this.getLectureCovered();
          this.getDashboardGraphData()
        }
        else {
          swal.fire(data.status_message)
        }
      },
      err => {

      }
    )
  }

  getDashboardGraphData() {
    this.showLoader = true
    this.managementDashBoardService.getDashboardGraphData(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.Attendance = data.attendance
          this.CoeSyllabus = data.coe_syllabus

          this.generatAttendanceGraph()
          this.generatSyllabusGraph();
        }
        else {
          swal.fire(data.status_code)
        }
      },
      err => {

      }
    )
  }

  generatAttendanceGraph() {
    //attendance  graph

    this.attendancebarChartType = 'bar';
    this.attendancebarChartLegend = true;
    this.attendancebarChartLabels = [];
    this.attendancebarChartData = []
    for (let data of this.Attendance) {
      this.attendancebarChartLabels.push(data.coe_name)
    }
    var classData = []
    for (let data of this.Attendance) {
      classData.push(parseInt(data.class_attendence))
    }
    var hostelData = []
    for (let data of this.Attendance) {
      hostelData.push(parseInt(data.hostel_attendence))
    }
    this.attendancebarChartData = [
      { data: classData, label: 'Class' },
      { data: hostelData, label: 'Hostel' }
    ];
  }


  generatSyllabusGraph() {
    //attendance  graph

    this.syllabusbarChartType = 'bar';
    this.syllabusbarChartLegend = true;
    this.syllabusbarChartLabels = [];
    this.syllabusbarChartData = [];
    for (let data of this.CoeSyllabus) {
      this.syllabusbarChartLabels.push(data.coe_name)
    }
    var completed = []
    for (let data of this.CoeSyllabus) {
      completed.push(parseInt(data.completed_syllabus))
    }
    var notCompleted = []
    for (let data of this.CoeSyllabus) {
      notCompleted.push(parseInt(data.not_completed_syllabus))
    }
    this.syllabusbarChartData = [
      { data: completed, label: 'Completed' },
      { data: notCompleted, label: 'Not-completed' }
    ];
  }


  // public barChartOptions: any = {
  //   scaleShowVerticalLines: false,
  //   responsive: true,
  //   scales: {
  //     yAxes: [{
  //       ticks: {
  //         min: 0,
  //         max: 100,
  //         stepSize: 10
  //       }
  //     }]
  //   }
  // };


  getSubjectsCovered() {
    this.showLoader = true
    this.managementDashBoardService.getManagementSubjectGraph(localStorage.getItem("email"), this.coeId).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.Subjects = data.coe_subject

          this.generatSubjectGraph();
        }
        else {

        }
      },
      err => {
        this.showLoader = false
      }
    )
  }

  generatSubjectGraph() {
    this.SubjectGraph = [];
    for (let data of this.Subjects) {
      if (data.subject_name != null) {
        //subject graph
        var pieChartLabels: string[] = ['Completed', 'Not-completed'];
        var pieChartData: number[] = [data.completed, data.not_completed];
        let json = {
          "subject_name": data.subject_name,
          "pieChartType": 'pie',
          "pieChartLabels": pieChartLabels,
          "pieChartData": pieChartData
        }

        this.SubjectGraph.push(json)
      }

    }

  }

  getLectureCovered() {
    this.lecturebarChartLegend = false;
    for (let data of this.Coes) {
      if (data.id_coe == this.onTimeLecturecoeId) {
        this.coeName = data.name
        break;
      }
    }
    this.showLoader = true
    this.managementDashBoardService.getManagementLectureGraph(localStorage.getItem("email"), this.onTimeLecturecoeId, this.coeName).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.CoeLectures = data.coe_lecture
          this.generatCoeLectureGraph();
        }
        else {

        }
      },
      err => {

      }
    )
  }

  generatCoeLectureGraph() {
    //lecture  graph

    this.lecturebarChartType = 'bar';
    //this.lecturebarChartLegend = true;
    this.lecturebarChartLabels = [];
    this.lecturebarChartData = [];
    for (let data of this.CoeLectures) {
      this.lecturebarChartLabels.push(data.coe_name)
    }
    var completed = []
    for (let data of this.CoeLectures) {
      completed.push(parseInt(data.completed))
    }
    var notCompleted = []
    for (let data of this.CoeLectures) {
      notCompleted.push(parseInt(data.not_completed))
    }
    this.lecturebarChartData = [
      { data: completed, label: 'completed' },
      { data: notCompleted, label: 'not-completed' }
    ];
    this.lecturebarChartLegend = true;
  }

  ngOnInit() {
  }

}
