import { Component, OnInit } from '@angular/core';
import { FeddbackHistoryService } from './feedback-history.service'
import { Router, ActivatedRoute } from '@angular/router'
import swal from 'sweetalert2'
@Component({
  selector: 'app-feedback-history',
  templateUrl: './feedback-history.component.html',
  styleUrls: ['./feedback-history.component.css'],
  providers: [FeddbackHistoryService]
})
export class FeedbackHistoryComponent implements OnInit {
  FeedbackHistory = []
  facultyId: any;
  facultyFedbackRequestId: any
  showLoader = false;
  facultyName: any
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  constructor(private feddbackHistoryService: FeddbackHistoryService, private router: Router, private activatedRoute: ActivatedRoute) {
    if (localStorage.getItem("isLogin") == "true") {
      this.activatedRoute.queryParams.subscribe(params => {
        if (params.facultyId) {
          this.facultyId = params.facultyId
          this.facultyName = params.facultyName
          this.facultyFedbackRequestId = params.facultyFedbackRequestId
          this.getFacultyFeedbackHistory();
        }
      });
    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  getFacultyFeedbackHistory() {
    this.showLoader = true
    this.feddbackHistoryService.getFacultyFeedbackHistory(localStorage.getItem("email"), this.facultyId, this.facultyFedbackRequestId).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.FeedbackHistory = data.feedback_history
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      }
    )
  }

  onBackPress() {
    this.router.navigateByUrl('/admin-faculty-feedback')
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    //("status" + status);
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }

  viewDetails(data) {
    let url = '/faculty-feedback-analysis/' + this.facultyId + "/" + data.id_faculty_feedback_request + "/" + this.facultyName + "/" + data.created_tms
    this.router.navigateByUrl(url);
  }
}
