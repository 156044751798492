import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import swal from 'sweetalert2'
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  isRole: any;
  constructor(private router: Router) {
    if (localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "1" || localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "4") {
      this.isRole = localStorage.getItem("id_role");
    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  openTopicStatusReport() {
    this.router.navigateByUrl('/topic-status-report');
  }
  openTopicReport() {
    this.router.navigateByUrl('/topic-report');
  }

  openSectionSyllabusReport() {
    this.router.navigateByUrl('/section-syllabus-report');
  }

  openAttendanceReport() {
    this.router.navigateByUrl('/attendance-report');
  }
  openDailyAttendanceReport() {
    this.router.navigateByUrl('/daily-attendance-report');
  }
  openQualifyingProbability() {
     this.router.navigateByUrl('/qualifying-probability');
  }
}
