import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class ScheduleMasterService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }

    getAllMasterData(email) {
        return this.http.get(this.baseUrl + "/Section/getAllMasterDataForSection?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getDailyClassSchedule(email, sectionId) {
        return this.http.get(this.baseUrl + "/Schedule/getDailyClassSchedule?caller_email=" + email + "&id_section=" + sectionId).map((res) => JSON.parse(JSON.stringify(res)));
    }


    addDailyClassSchedule(jsonData) {
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Schedule/addDailyClassSchedule", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    editDailyClassSchedule(jsonData) {
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Schedule/editDailyClassSchedule", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeDailyClassSchedule(jsonData) {
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Schedule/removeDailyClassSchedule", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }


    addHoliday(jsonData) {
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Schedule/addHoliday", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getHolidays(email, sectionId) {
        return this.http.get(this.baseUrl + "/Schedule/getHoliday?caller_email=" + email + "&id_section=" + sectionId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getMasterDataForTemplate(email, sectionId) {
        return this.http.get(this.baseUrl + "/Schedule/getMastereForScheduleTemplate?caller_email=" + email + "&id_section=" + sectionId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    applyScheduleTemplate(jsonData) {
        //.log(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Schedule/saveScheduleTemplate", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getDailySectionSchedule(email, sectionId) {
        return this.http.get(this.baseUrl + "Schedule/getSectionSchedule?caller_email=" + email + "&id_section=" + sectionId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    saveComplitionChange(jsonData) {
        //.log(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Schedule/changeDailyClassScheduleStatus", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    lectureNotRequired(jsonData) {
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Schedule/lectureNotRequired", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    addExtraLecture(jsonData) {
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Schedule/addExtraLecture", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    setAdhoc(jsonData) {
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Schedule/setAdhoc", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    addOtherLecture(jsonData) {
        //.log(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Schedule/addOtherLecture", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeHolidays(jsonData) {
        //.log(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Schedule/removeHolidays", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }
}