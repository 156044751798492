import { Component, OnInit } from '@angular/core';
import { AddExamPatternService } from './add-exam-pattern.service'
import { Router, ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';
import swal from 'sweetalert2'
import { DatePipe } from '@angular/common'
import { Jsonp } from '@angular/http';
import { retry } from 'rxjs/operator/retry';
declare let jsPDF;
@Component({
  selector: 'app-add-exam-apttern',
  templateUrl: './add-exam-apttern.component.html',
  styleUrls: ['./add-exam-apttern.component.css'],
  providers: [AddExamPatternService, DatePipe]
})
export class AddExamAptternComponent implements OnInit {
  examPatternId: any;
  patternName: any;
  searchText: any;
  CourseType = [];
  courseTypeId = "0";
  showLoader = false;
  Patterns = [];
  UnfilteredPatterns = [];
  patternId = "0";
  PatternsSubjects = [];
  SubjectPattern = [];
  FormatedPatternSubject = [];
  isAddModalQuestion = false;
  Subjects = [];
  subjectId = "0";
  SubSubjects = [];
  subSubjectId = "0"
  UnFilteredSubSubjects = [];
  Topics = [];
  topicId = "0"
  unFilteredTopics = [];
  SubTopics = [];
  subTopicId = "0"
  unFilteredSubTopics = [];
  Questions = [];
  UnfilteredQuestions = [];
  isCheckedAllQuestion = "1";
  CheckedQuestions = [];
  SelectedQuestions = [];
  isAutogenerateModalQuestion = false;
  ArrayForAutogenerate = [];
  CheckedTopics = [];
  testName: any;
  testDate: any;
  showAlert = false;
  alertMsg: any;
  alertType: any;
  examTemplateId: any;
  checkAnyQuestionAddManually = false;
  purpose = "Add";
  QuestionSources = [];
  questionSourceId = "0";
  QuestionPatterns = [];
  QuestionDifficulty = [];
  questionDifficultyId = "0";
  questionPatternId = "0";
  isQuestionViewModal = false;

  QuestionData: any;
  selectedQuestionPatternId: any;
  correctAnswer: any;
  ColumnSecond: any;
  ColumnOne: any;
  Answer: any;
  Optiones: any;
  MatrixMatchingAnswerTable = [];
  sollutionText: any;
  MatrixCheckedAnswer = [];
  UnfiteredArrayForAutogenerate = [];
  DifficultyLevelPercentage = [];
  InterpretationLevelPercentage = [];
  QuestionInterpratation = [];
  examDescription: any;
  examInstruction: any;
  examDuration: any;
  examTypeId = "0";
  initEditText: any;
  ComprehensionQuestions = [];
  isComprehensionQuestionViewModal = false;
  ComprehensionTypeMatrix = [];
  unformatedQuestion = [];
  isExamPaperPreview = false;
  isSollutionVisibleForExam = false;
  currentDate: any
  ExamFor = [{ 'id': 1, 'name': 'JEE Advanced' }, { 'id': 2, 'name': 'JEE Mains' }, { 'id': 3, 'name': 'NEET' }];
  examFor = "0";
  dropdownSettings = {};
  selectedPatternItems = [];
  can_edit = "true";
  // changes for filters
  selectedIdSubject = '0';
  selectedIdQuestionType = '0';
  selectedIdDifficultyLevel = '0';
  orignalSelectedQuestions=[];


  constructor(private datePipe: DatePipe, private activatedRoute: ActivatedRoute, private router: Router, private addExamPatternService: AddExamPatternService) {

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };



    this.currentDate = new Date();
    this.testDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
    this.currentDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
    this.initializeTiny();


    if (localStorage.getItem("isLogin") == "true") {
      this.getAllQuestionSources();
      this.examTypeId = "0";
      this.activatedRoute.queryParams.subscribe(params => {
        if (params['testName']) {
          this.purpose = "Edit"

          this.can_edit = params['can_edit'];
          console.log(" In Add exam can_edit=" + this.can_edit);
          this.testName = params['testName'];
          this.examTemplateId = params['examTemplateId']
          this.examDescription = params['description'];
          this.examInstruction = params['instruction'];
          this.examDuration = params['duration'];
          this.examTypeId = params['examType'];
          this.testDate = params['testDate'];
          this.courseTypeId = params['courseTypeId'];
          this.patternId = params['patternId'];
          this.examFor = params['exam_for'];
          this.SelectedQuestions = JSON.parse(localStorage.getItem("exam_template_question"));
          let tempQuestionArray = this.SelectedQuestions;
          this.orignalSelectedQuestions = JSON.parse(localStorage.getItem("exam_template_question"));
          var QuestionArray = [];
          for (let dataOne of tempQuestionArray) {
            let json = {};
            if (dataOne.id_comprehension != "0") {
              const index = QuestionArray.findIndex(question => question.id_comprehension === dataOne.id_comprehension);
              if (index == -1) {
                json["id_comprehension"] = dataOne.id_comprehension
                json["comprehension_text"] = dataOne.comprehension_text
                json["id_subject"] = dataOne.id_subject
                json["subject_name"] = dataOne.subject_name
                json["id_sub_subject"] = dataOne.id_sub_subject
                json["sub_subject_name"] = dataOne.sub_subject_name
                json["id_topic"] = dataOne.id_topic
                json["topic_name"] = dataOne.topic_name
                json["id_question_source"] = dataOne.id_question_source
                json["id_difficult_level"] = dataOne.id_difficult_level
                json["question_source_name"] = dataOne.question_source_name
                json["difficulty_name"] = dataOne.difficulty_name
                json["last_review_date"] = dataOne.last_review_date
                json["id_question_pattern"] = "3"
                json["is_approved"] = dataOne.is_approved
                json["question_pattern_name"] = "Comprehension"
                var comprehensionQuestionArray = []

                for (let dataSecond of tempQuestionArray) {
                  if (dataOne.id_comprehension == dataSecond.id_comprehension && dataOne.id_comprehension != "0") {
                    comprehensionQuestionArray.push(dataSecond)
                  }

                }
                json["questions"] = comprehensionQuestionArray
                QuestionArray.push(json)
              }

            }
            else {
              QuestionArray.push(dataOne)
            }
          }
          this.SelectedQuestions = QuestionArray;
          this.orignalSelectedQuestions = QuestionArray;
          this.calculateDifficultyAndInterpretationLevelPercentage();
          this.getPatternByCourseType()
          this.getPatternSubject();
        }
      });

    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  getAllQuestionSources() {
    this.showLoader = true;
    this.addExamPatternService.getAllQuestionSources(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.QuestionSources = data.question_sources
          this.questionSourceId = this.QuestionSources[0]['id_question_source'];
          this.QuestionPatterns = data.question_pattern
          this.QuestionDifficulty = data.question_difficulty
          this.QuestionInterpratation = data.question_interpretation
          this.getAllCourseType();
        }
        else {
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllCourseType() {
    this.showLoader = true;
    this.addExamPatternService.getAllCourseType(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.CourseType = data.courses
          this.getAllSubjects();
        }
        else {
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllSubjects() {
    this.showLoader = true;
    this.addExamPatternService.getAllSubjects(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.Subjects = data.subjects
          this.getAllSubSubject();
        }
        else {
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllSubSubject() {
    this.showLoader = true;
    this.addExamPatternService.getAllSubSubject(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.UnFilteredSubSubjects = data.sub_sujects
          this.getAllTopic();
        }
        else {
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllTopic() {
    this.showLoader = true;
    this.addExamPatternService.getAllTopic(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.unFilteredTopics = data.topics
          this.getAllSubTopic();
        }
        else {
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllSubTopic() {
    this.showLoader = true;
    this.addExamPatternService.getAllSubTopic(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.unFilteredSubTopics = data.sub_topics
          this.getAllPattern();
        }
        else {
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }


  getAllPattern() {
    this.showLoader = true;
    this.addExamPatternService.getAllPattern(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.UnfilteredPatterns = data.patterns
          if (this.courseTypeId != "0") {
            this.getPatternByCourseType();
          }
        }
        else {
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  dropdownPatternList = [];
  getPatternByCourseType() {
    this.Patterns = [];
    // console.log("courseTypeId=" + this.courseTypeId);
    for (let data of this.UnfilteredPatterns) {
      if (data.id_course_type == this.courseTypeId) {
        this.Patterns.push(data);
      }
    }

    this.dropdownPatternList = [];
    for (let i = 0; i < this.Patterns.length; i++) {
      // console.log("Name=" + this.Patterns[i]['name']);
      this.dropdownPatternList.push({ item_id: this.Patterns[i]['id_pattern'], item_text: this.Patterns[i]['name'] });
    }


    //(this.Patterns)
  }

  onItemSelect(item: any) {
    this.patternId = item['item_id'];
    console.log(item);
    this.getPatternSubject();
  }

  onItemDeSelect(items: any) {
    this.patternId = null;
  }


  getPatternSubject() {
    if (this.courseTypeId != "0") {
      this.showLoader = true;
      this.PatternsSubjects = [];
      this.addExamPatternService.getPatternSubject(localStorage.getItem("email"), this.patternId).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.PatternsSubjects = data.pattern_subjects
            this.generateDataForPatternSubject();
            this.getQuestionForPatternSubject();

          }
          else {
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }
    else {
      this.patternId = "0";
      swal.fire("Please select course type first.")
      this.patternId = "0";
    }
  }

  getQuestionForPatternSubject() {
    this.showLoader = true;
    this.Questions = [];
    this.UnfilteredQuestions = []

    this.addExamPatternService.getQuestionForPatternSubject(localStorage.getItem("email"), this.patternId, this.courseTypeId, this.questionSourceId).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          let tempQuestionArray = data.questions;
          this.unformatedQuestion = data.questions;
          var QuestionArray = [];
          for (let dataOne of tempQuestionArray) {
            let json = {};
            if (dataOne.id_comprehension != "0") {
              const index = QuestionArray.findIndex(question => question.id_comprehension === dataOne.id_comprehension);
              if (index == -1) {
                json["id_comprehension"] = dataOne.id_comprehension
                json["comprehension_text"] = dataOne.comprehension_text
                json["id_subject"] = dataOne.id_subject
                json["subject_name"] = dataOne.subject_name
                json["id_sub_subject"] = dataOne.id_sub_subject
                json["sub_subject_name"] = dataOne.sub_subject_name
                json["id_topic"] = dataOne.id_topic
                json["topic_name"] = dataOne.topic_name
                json["id_question_source"] = dataOne.id_question_source
                json["id_difficult_level"] = dataOne.id_difficult_level
                json["question_source_name"] = dataOne.question_source_name
                json["difficulty_name"] = dataOne.difficulty_name
                json["last_review_date"] = dataOne.last_review_date
                json["id_question_pattern"] = "3"
                json["is_approved"] = dataOne.is_approved
                json["quesion_pattern_name"] = "Comprehension"

                var comprehensionQuestionArray = []

                for (let dataSecond of tempQuestionArray) {
                  if (dataOne.id_comprehension == dataSecond.id_comprehension && dataOne.id_comprehension != "0") {
                    comprehensionQuestionArray.push(dataSecond)
                  }

                }
                json["questions"] = comprehensionQuestionArray
                QuestionArray.push(json)
              }

            }
            else {
              QuestionArray.push(dataOne)
            }
          }


          this.Questions = QuestionArray
          this.UnfilteredQuestions = QuestionArray;

          this.filterAddManualyQuestion();

        }
        else {
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  filterQuestionSource() { 
    console.log("questionPatternId="+this.questionPatternId);  
    this.getQuestionForPatternSubject();
  }

  generateDataForPatternSubject() {
    this.SubjectPattern = [];
    for (let data of this.PatternsSubjects) {
      let json = {
        "id_subject": data.id_subject,
        "name": data.subject_name,

      }
      this.SubjectPattern.push(json)
    }

    var json = {};
    const tempArray = [];
    for (let data of this.PatternsSubjects) {

      for (let pattern of data.pattern_question_subject) {
        if (Object.keys(json).length == this.PatternsSubjects.length) {
          json = {};
        }
        if (tempArray.length === data.pattern_question_subject.length) {
          for (let i = 0; i < tempArray.length; i++) {
            const temp = Object.values(tempArray[i]);

            if (temp[0]['id_question_pattern'] == pattern.id_question_pattern) {
              const tempObj = tempArray[i]
              tempObj[data.id_subject] = pattern;
              tempArray[i] = tempObj;
            }
          }
        }
        else {
          const tempObj = {}
          tempObj[data.id_subject] = pattern;
          tempArray.push(tempObj);
        }
      }
    }
    this.FormatedPatternSubject = tempArray;
    this.refreshTablePfQuestionPattern();
    this.calculateDifficultyAndInterpretationLevelPercentage();
  }

  filterSuSuject() {
    this.SubSubjects = [];
    this.Questions = [];
    for (let data of this.UnFilteredSubSubjects) {
      if (this.subjectId == data.id_subject) {
        this.SubSubjects.push(data)
      }
    }
    //this.clearCheckedQuestions();
    if (this.SelectedQuestions.length > 0) {
      var tempArray = this.removeSelectedQuestionFormQuestion();
      for (let data of tempArray) {
        if (data.id_subject == this.subjectId) {
          this.Questions.push(data)
        }
      }      
    }
    else {
      for (let data of this.UnfilteredQuestions) {
        if (data.id_subject == this.subjectId) {
          this.Questions.push(data)
        }
      }
    }

    var tempArray = [];
    this.ArrayForAutogenerate = [];
    for (let data of this.UnfiteredArrayForAutogenerate) {
      let json = {
        "id_subject": data.id_subject,
        "subject_name": data.subject_name,
        "topics": data.topics
      }
      this.ArrayForAutogenerate.push(json)
    }
    for (let data of this.ArrayForAutogenerate) {

      for (let subTopic of data.sub_topics) {
        if (this.subjectId == subTopic.id_subject) {
          tempArray.push(subTopic)
        }
      }
      if (data.id_subject == this.subjectId)
        data.sub_topics = tempArray
    }

    let tempQuestionArray = this.Questions
    var QuestionArray = [];
    for (let dataOne of tempQuestionArray) {
      let json = {};
      if (dataOne.id_comprehension != "0") {
        const index = QuestionArray.findIndex(question => question.id_comprehension === dataOne.id_comprehension);
        if (index == -1) {
          json["id_comprehension"] = dataOne.id_comprehension
          json["comprehension_text"] = dataOne.comprehension_text
          json["id_subject"] = dataOne.id_subject
          json["subject_name"] = dataOne.subject_name
          json["id_sub_subject"] = dataOne.id_sub_subject
          json["sub_subject_name"] = dataOne.sub_subject_name
          json["id_topic"] = dataOne.id_topic
          json["topic_name"] = dataOne.topic_name
          json["last_review_date"] = dataOne.last_review_date
          json["id_question_source"] = dataOne.id_question_source
          json["id_difficult_level"] = dataOne.id_difficult_level
          json["question_source_name"] = dataOne.question_source_name
          json["difficulty_name"] = dataOne.difficulty_name
          json["id_question_pattern"] = "3"
          json["is_approved"] = dataOne.is_approved
          json["quesion_pattern_name"] = "Comprehension"

          var comprehensionQuestionArray = []

          for (let dataSecond of tempQuestionArray) {
            if (dataOne.id_comprehension == dataSecond.id_comprehension && dataOne.id_comprehension != "0") {
              comprehensionQuestionArray.push(dataSecond)
            }

          }
          json["questions"] = comprehensionQuestionArray
          QuestionArray.push(json)
        }

      }
      else {
        QuestionArray.push(dataOne)
      }
    }


    this.Questions = QuestionArray



  }

  filterTopics() {
    this.Topics = [];
    this.Questions = [];
    this.topicId = "0"
    for (let data of this.unFilteredTopics) {
      if (this.subSubjectId == data.id_sub_subject) {
        this.Topics.push(data)
      }
    }
    // this.clearCheckedQuestions();
    if (this.SelectedQuestions.length > 0) {
      var tempArray = this.removeSelectedQuestionFormQuestion();
      for (let data of tempArray) {
        if (data.id_sub_subject == this.subSubjectId) {
          this.Questions.push(data)
        }
      }
    }
    else {
      for (let data of this.UnfilteredQuestions) {
        if (data.id_sub_subject == this.subSubjectId) {
          this.Questions.push(data)
        }
      }
    }
    var tempArray = [];
    this.ArrayForAutogenerate = [];
    for (let data of this.UnfiteredArrayForAutogenerate) {
      let json = {
        "id_subject": data.id_subject,
        "subject_name": data.subject_name,
        "sub_topics": data.sub_topics
      }
      this.ArrayForAutogenerate.push(json)
    }
    for (let data of this.ArrayForAutogenerate) {

      for (let subTopic of data.sub_topics) {
        if (this.subjectId == subTopic.id_subject && subTopic.id_sub_subject == this.subSubjectId) {
          tempArray.push(subTopic)
        }
        else if (this.subSubjectId == "0" && this.subjectId == subTopic.id_subject) {
          tempArray.push(subTopic)
        }
      }
      if (data.id_subject == this.subjectId)
        data.sub_topics = tempArray
    }


    let tempQuestionArray = this.Questions
    var QuestionArray = [];
    for (let dataOne of tempQuestionArray) {
      let json = {};
      if (dataOne.id_comprehension != "0") {
        const index = QuestionArray.findIndex(question => question.id_comprehension === dataOne.id_comprehension);
        if (index == -1) {
          json["id_comprehension"] = dataOne.id_comprehension
          json["comprehension_text"] = dataOne.comprehension_text
          json["id_subject"] = dataOne.id_subject
          json["subject_name"] = dataOne.subject_name
          json["id_sub_subject"] = dataOne.id_sub_subject
          json["sub_subject_name"] = dataOne.sub_subject_name
          json["id_topic"] = dataOne.id_topic
          json["topic_name"] = dataOne.topic_name
          json["last_review_date"] = dataOne.last_review_date
          json["id_question_pattern"] = "3"
          json["id_question_source"] = dataOne.id_question_source
          json["id_difficult_level"] = dataOne.id_difficult_level
          json["question_source_name"] = dataOne.question_source_name
          json["difficulty_name"] = dataOne.difficulty_name
          json["is_approved"] = dataOne.is_approved
          json["quesion_pattern_name"] = "Comprehension"

          var comprehensionQuestionArray = []

          for (let dataSecond of tempQuestionArray) {
            if (dataOne.id_comprehension == dataSecond.id_comprehension && dataOne.id_comprehension != "0") {
              comprehensionQuestionArray.push(dataSecond)
            }

          }
          json["questions"] = comprehensionQuestionArray
          QuestionArray.push(json)
        }

      }
      else {
        QuestionArray.push(dataOne)
      }
    }


    this.Questions = QuestionArray


  }

  filterSubTopics() {
    this.SubTopics = [];
    this.Questions = [];
    for (let data of this.unFilteredSubTopics) {
      if (this.topicId == data.id_topic) {
        this.SubTopics.push(data)
      }
    }
    // this.clearCheckedQuestions();
    if (this.SelectedQuestions.length > 0) {
      var tempArray = this.removeSelectedQuestionFormQuestion();
      for (let data of tempArray) {
        if (data.id_topic == this.topicId) {
          this.Questions.push(data)
        }
      }
    }
    else {
      for (let data of this.UnfilteredQuestions) {
        if (data.id_topic == this.topicId) {
          this.Questions.push(data)
        }
      }
    }
  }

  filterQuestionBySubTopicId() {
    this.Questions = [];
    if (this.SelectedQuestions.length > 0) {
      var tempArray = this.removeSelectedQuestionFormQuestion();
      for (let data of tempArray) {
        if (data.id_sub_topic == this.subTopicId) {
          this.Questions.push(data)
        }
      }
    }
    else {
      for (let data of this.UnfilteredQuestions) {
        if (data.id_sub_topic == this.subTopicId) {
          this.Questions.push(data)
        }
      }
    }
  }

  addManually() {
    // this.questionSourceId = "0"
    if (this.courseTypeId == "0" || this.patternId == "0") {
      swal.fire("Please select course type and pattern first.")
    }
    else {
      this.isAddModalQuestion = true;
      this.showLoader = true;
      let tempArray = [];
      for (let data of this.QuestionSources) {
        if (this.courseTypeId == "1") {
          if (data.source_for == "14" || data.source_for == "15") {
            tempArray.push(data)
          }
        }
        else {
          if (data.source_for == "14" || data.source_for == "16") {
            tempArray.push(data)
          }
        }
      }
      this.QuestionSources = tempArray
      this.subSubjectId = "0"
      this.subjectId = "0"
      this.topicId = "0"

      this.CheckedQuestions = [];
      // this.Questions = this.removeSelectedQuestionFormQuestion();

      this.showLoader = false;

      // let tempQuestionArray = this.Questions
      // var QuestionArray = [];
      // for (let dataOne of tempQuestionArray) {
      //   let json = {};
      //   if (dataOne.id_comprehension != "0") {
      //     const index = QuestionArray.findIndex(question => question.id_comprehension === dataOne.id_comprehension);
      //     if (index == -1) {
      //       json["id_comprehension"] = dataOne.id_comprehension
      //       json["comprehension_text"] = dataOne.comprehension_text
      //       json["id_subject"] = dataOne.id_subject
      //       json["subject_name"] = dataOne.subject_name
      //       json["id_sub_subject"] = dataOne.id_sub_subject
      //       json["sub_subject_name"] = dataOne.sub_subject_name
      //       json["id_topic"] = dataOne.id_topic
      //       json["topic_name"] = dataOne.topic_name
      //       json["last_review_date"] = dataOne.last_review_date
      //       json["id_question_pattern"] = "3"
      //       json["is_approved"] = dataOne.is_approved
      //       json["quesion_pattern_name"] = "Comprehension"
      //       var comprehensionQuestionArray = []
      //       for (let dataSecond of tempQuestionArray) {
      //         if (dataOne.id_comprehension == dataSecond.id_comprehension && dataOne.id_comprehension != "0") {
      //           comprehensionQuestionArray.push(dataSecond)
      //         }

      //       }
      //       json["questions"] = comprehensionQuestionArray
      //       QuestionArray.push(json)
      //     }

      //   }
      //   else {
      //     QuestionArray.push(dataOne)
      //   }
      // }


      // this.Questions = QuestionArray


      this.subjectId = this.SubjectPattern[0]['id_subject'];
      this.filterAddManualyQuestion();
    }
  }

  removeSelectedQuestionFormQuestion() {
    var tempArray = []
    if (this.SelectedQuestions.length > 0) {
      for (let data of this.UnfilteredQuestions) {
        var check = false;
        for (let selectedQuestion of this.SelectedQuestions) {
          if (data.id_question == selectedQuestion.id_question && data.id_question_pattern != "3") {
            check = true;
          }
          else if (data.id_comprehension == selectedQuestion.id_comprehension && data.id_question_pattern == "3") {
            check = true;
          }
        }
        if (check == false) {
          tempArray.push(data);
        }
      }
    }
    else {
      tempArray = this.UnfilteredQuestions
    }
    return tempArray;

  }

  selectAllSectionTopic() {
    this.CheckedQuestions = []
    if (this.isCheckedAllQuestion == "1") {
      this.isCheckedAllQuestion = "0"
      for (let data of this.Questions) {

        if(data.id_question_pattern != '3')
        {
          let json = {
            "id_question": data.id_question
          }
          this.CheckedQuestions.push(json)
        }else{

          for (let dt of data.questions) {
            var check = true
            for (let i = 0; i < this.CheckedQuestions.length; i++) {
  
              if (this.CheckedQuestions[i].id_question == dt.id_question) {
                this.CheckedQuestions.splice(i, 1)
                this.isCheckedAllQuestion = "1"
                check = false
                break;
              }
              else {
                check = true;
              }
            }
            if (check == true) {
              let json = {
                "id_question": dt.id_question,
                "id_comprehension": dt.id_comprehension
              }
              this.CheckedQuestions.push(json)
            }
          }
  

        }
      }
    }
    else {
      this.isCheckedAllQuestion = "1"
      this.CheckedQuestions = []
    }
  }

  checkUncheckQuestion(questionId, questionPatternId) {

    var check = true
    if (questionPatternId != "3") {
      if (this.CheckedQuestions.length != 0) {
        for (let i = 0; i < this.CheckedQuestions.length; i++) {
          if (this.CheckedQuestions[i].id_question == questionId) {
            this.CheckedQuestions.splice(i, 1)
            this.isCheckedAllQuestion = "1"
            check = false
            break;
          }
          else {
            check = true;
          }
        }

        if (check == true) {
          let json = {
            "id_question": questionId,
            "id_comprehension": "0"
          }
          this.CheckedQuestions.push(json)
        }
      }
      else {
        let json = {
          "id_question": questionId
        }
        this.CheckedQuestions.push(json)
      }
    }

  }


  checkUncheckComprehensionQuestion(comprehensionQuestion, questionPatternId) {

    let tempQuestion = comprehensionQuestion
    if (questionPatternId == "3") {
      if (this.CheckedQuestions.length != 0) {

        for (let data of tempQuestion) {
          var check = true
          for (let i = 0; i < this.CheckedQuestions.length; i++) {

            if (this.CheckedQuestions[i].id_question == data.id_question) {
              this.CheckedQuestions.splice(i, 1)
              this.isCheckedAllQuestion = "1"
              check = false
              break;
            }
            else {
              check = true;
            }
          }
          if (check == true) {
            let json = {
              "id_question": data.id_question,
              "id_comprehension": data.id_comprehension
            }
            this.CheckedQuestions.push(json)
          }
        }


      }
      else {
        for (let data of tempQuestion) {
          let json = {
            "id_question": data.id_question,
            "id_comprehension": data.id_comprehension
          }
          this.CheckedQuestions.push(json)
        }
      }
    }

  }

  checkComprehensionQuestionIsChecked(comprhensionQuestion) {
    // //comprhensionQuestion)
    var isChecked = false;
    for (let data of comprhensionQuestion.questions) {
      for (let check of this.CheckedQuestions) {
        if (data.id_question == check.id_question) {
          isChecked = true;
          break;
        }
      }
      if (isChecked) {
        break;
      }
    }
    return isChecked
  }

  checkQuestionIschecked(questionId) {
    var isChecked = false;
    if (this.CheckedQuestions.length != 0) {
      for (let checkedData of this.CheckedQuestions) {
        if (questionId == checkedData.id_question) {
          isChecked = true;
          break;
        }
        else {
          isChecked = false;
        }

      }

    }
    else {
      return false
    }
    if (isChecked) {
      return true;
    }
    else {
      return false;
    }
  }

  clearCheckedQuestions() {
    this.isCheckedAllQuestion = "1";
    this.CheckedQuestions = [];
  }

  saveCheckedQuestions() {
    var tempSelectedQuestions = [];
    for (let data of this.unformatedQuestion) {
      for (let checkQuestion of this.CheckedQuestions) {
        if (data.id_question == checkQuestion.id_question) {
          let json = {
            "id_question": data.id_question,
            "id_question_source": data.id_question_source,
            "comprehension_text": data.comprehension_text,
            "id_comprehension": data.id_comprehension,
            "id_subject": data.id_subject,
            "subject_name": data.subject_name,
            "id_question_pattern": data.id_question_pattern,
            "question_optiones": data.question_optiones,
            "question_pattern_name": data.quesion_pattern_name,
            "question_text": data.question_text,
            "answer": data.answer,
            "matrix_match": data.matrix_match,
            "column_one": data.column_one,
            "column_second": data.column_second,
            "sollution_text": data.sollution_text,
            "is_auto_generated": "1",
            "question_interpretation_name": data.question_interpretation_name,
            "difficulty_name": data.difficulty_name,
            "id_difficult_level": data.id_difficult_level
          }
          tempSelectedQuestions.push(json);
        }
      }
    }
    let tempQuestionArray = tempSelectedQuestions;
    var QuestionArray = [];
    for (let dataOne of tempQuestionArray) {
      let json = {};
      if (dataOne.id_comprehension != "0") {
        const index = QuestionArray.findIndex(question => question.id_comprehension === dataOne.id_comprehension);
        if (index == -1) {

          json["id_comprehension"] = dataOne.id_comprehension
          json["comprehension_text"] = dataOne.comprehension_text
          json["id_subject"] = dataOne.id_subject,
          json["id_question_source"] = dataOne.id_question_source
          json["id_difficult_level"] = dataOne.id_difficult_level
          json["question_source_name"] = dataOne.question_source_name
          json["difficulty_name"] = dataOne.difficulty_name
          json["id_question_pattern"] = "3"
          json["question_pattern_name"] = "Comprehension"

          var comprehensionQuestionArray = []

          for (let dataSecond of tempQuestionArray) {
            if (dataOne.id_comprehension == dataSecond.id_comprehension && dataOne.id_comprehension != "0") {
              comprehensionQuestionArray.push(dataSecond)
            }

          }
          json["questions"] = comprehensionQuestionArray
          QuestionArray.push(json)
        }

      }
      else {
        QuestionArray.push(dataOne)
      }
    }
    if (this.SelectedQuestions.length > 0) {
      this.SelectedQuestions = this.SelectedQuestions.concat(QuestionArray);
      this.orignalSelectedQuestions= this.SelectedQuestions.concat(QuestionArray);
    }
    else {
      this.SelectedQuestions = QuestionArray;
      this.orignalSelectedQuestions = QuestionArray;
    }

    this.isAddModalQuestion = false;
    this.refreshTablePfQuestionPattern();
    this.calculateDifficultyAndInterpretationLevelPercentage();
  }

  refreshTablePfQuestionPattern() {
    for (let fp of this.FormatedPatternSubject) {
      for (let ps of this.PatternsSubjects) {

        let temp = +fp[ps.id_subject].selected
        temp++
        fp[ps.id_subject].selected = "0";

      }
    }
    for (let data of this.SelectedQuestions) {

      for (let fp of this.FormatedPatternSubject) {
        for (let ps of this.PatternsSubjects) {
          if (ps.id_subject == data.id_subject && fp[ps.id_subject].id_question_pattern == data.id_question_pattern) {
            let temp = +fp[ps.id_subject].selected
            temp++
            fp[ps.id_subject].selected = temp.toString();
          }
        }
      }
    }

  }

  hideAddEditModal() {
    this.isAddModalQuestion = false;
    this.isAutogenerateModalQuestion = false;
    this.clearCheckedQuestions();
  }

  autogenerateQuestions() {
    this.CheckedTopics = []
    this.questionSourceId = "0"
    if (this.courseTypeId == "0" || this.patternId == "0") {
      swal.fire("Please select course type and pattern first.")
    }
    else {
      let tempArray = [];
      for (let data of this.QuestionSources) {
        if (this.courseTypeId == "1") {
          if (data.source_for == "14" || data.source_for == "15") {
            tempArray.push(data)
          }
        }
        else {
          if (data.source_for == "14" || data.source_for == "16") {
            tempArray.push(data)
          }
        }
      }
      this.QuestionSources = tempArray
      this.subSubjectId = "0"
      this.subjectId = "0"
      this.topicId = "0"
      this.checkAnyQuestionAddManually = false;
      // for (let data of this.SelectedQuestions) {
      //   this.checkAnyQuestionAddManually = true;
      //   break;
      // }
      this.selectSubTopicForAutogenerateQuestion();
      this.isAutogenerateModalQuestion = true;
    }
  }

  selectSubTopicForAutogenerateQuestion() {
    this.ArrayForAutogenerate = [];
    this.UnfiteredArrayForAutogenerate = [];
    for (let data of this.PatternsSubjects) {
      var topics = [];
      for (let topic of this.unFilteredTopics) {
        if (data.id_subject == topic.id_subject && topic.weightage != "0") {
          topics.push(topic)


        }

      }
      let json = {
        "id_subject": data.id_subject,
        "subject_name": data.subject_name,
        "topics": topics
      }
      this.ArrayForAutogenerate.push(json)
      this.UnfiteredArrayForAutogenerate.push(json)
    }
  }

  checkUncheckSubTopic(subjectId, topicId, topicWeightage) {
    var check = true
    if (this.CheckedTopics.length != 0) {
      for (let i = 0; i < this.CheckedTopics.length; i++) {
        if (this.CheckedTopics[i].id_topic == topicId) {
          this.CheckedTopics.splice(i, 1)
          check = false
          break;
        }
        else {
          check = true;
        }
      }

      if (check == true) {
        let json = {
          "id_subject": subjectId,
          "id_topic": topicId,
          "topic_weightage": topicWeightage
        }
        this.CheckedTopics.push(json)

      }
    }
    else {
      let json = {
        "id_subject": subjectId,
        "id_topic": topicId,
        "topic_weightage": topicWeightage
      }
      this.CheckedTopics.push(json)
    }
  }

  checkSubTopicIschecked(topicId) {
    var isChecked = false;
    if (this.CheckedTopics.length != 0) {
      for (let checkedData of this.CheckedTopics) {
        if (topicId == checkedData.id_topic) {
          isChecked = true;
          break;
        }
        else {
          isChecked = false;
        }

      }

    }
    else {
      return false
    }
    if (isChecked) {
      return true;
    }
    else {
      return false;
    }
  }

  generateAutomaticallyQuestions() {
    if (this.CheckedTopics.length == 0) {
      swal.fire("Please select topic.")
    }
    else {
      let totalTopicWeightage = 0.0
      for (let data of this.CheckedTopics) {
        totalTopicWeightage = totalTopicWeightage + parseFloat(data.topic_weightage)
      }
      for (let data of this.CheckedTopics) {
        let weightage = (parseFloat(data.topic_weightage) / totalTopicWeightage) * 100
        data.topic_weightage = weightage.toFixed(2)
      }
      if (this.checkAnyQuestionAddManually == false) {
        if (this.questionSourceId == "0") {
          swal.fire({
            title: '',
            text: "Question source not selected. Are you sure you want to continue ? ",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#428e63',
            confirmButtonText: 'Yes'
          }).then((result) => {
            if (result.value) {
              let json = {
                "caller_email": localStorage.getItem("email"),
                "id_pattern": this.patternId,
                "id_course_type": this.courseTypeId,
                "id_question_source": this.questionSourceId,
                "selected_sub_topics": JSON.stringify(this.CheckedTopics)
              }
              this.showLoader = true;
              // //.log(json)
              this.addExamPatternService.automatedQuestionGeneration(json).subscribe(
                data => {
                  this.showLoader = false;
                  if (data.status_code == "0") {
                    this.SelectedQuestions = [];
                    var questions = data.questions
                    for (let data of questions) {
                      let json = {
                        "id_question": data.id_question,
                        "id_comprehension": data.id_comprehension,
                        "comprehesion_text": data.comprehesion_text,
                        "id_question_source": data.id_question_source,
                        "question_text": data.question_text,
                        "is_auto_generated": "0",
                        "id_subject": data.id_subject,
                        "id_question_pattern": data.id_question_pattern,
                        "question_pattern_name": data.question_pattern_name,
                        "question_optiones": data.question_optiones,

                        "answer": data.answer,
                        "matrix_match": data.matrix_match,
                        "column_one": data.column_one,
                        "column_second": data.column_second,
                        "sollution_text": data.sollution_text,
                        "question_interpretation_name": data.question_interpretation_name,
                        "difficulty_name": data.difficulty_name,
                      }
                      this.SelectedQuestions.push(json)
                      this.orignalSelectedQuestions.push(json);
                    }

                    let tempQuestionArray = this.SelectedQuestions;
                    var QuestionArray = [];
                    for (let dataOne of tempQuestionArray) {
                      let json = {};
                      if (dataOne.id_comprehension != "0") {
                        const index = QuestionArray.findIndex(question => question.id_comprehension === dataOne.id_comprehension);
                        if (index == -1) {

                          json["id_comprehension"] = dataOne.id_comprehension

                          json["comprehension_text"] = dataOne.comprehesion_text
                          json["id_subject"] = dataOne.id_subject
                          json["subject_name"] = dataOne.subject_name
                          json["id_sub_subject"] = dataOne.id_sub_subject
                          json["sub_subject_name"] = dataOne.sub_subject_name
                          json["id_topic"] = dataOne.id_topic
                          json["topic_name"] = dataOne.topic_name
                          json["last_review_date"] = dataOne.last_review_date
                          json["id_question_pattern"] = "3"
                          json["is_approved"] = dataOne.is_approved
                          json["id_question_source"] = dataOne.id_question_source
                          json["id_difficult_level"] = dataOne.id_difficult_level
                          json["question_source_name"] = dataOne.question_source_name
                          json["difficulty_name"] = dataOne.difficulty_name
                          json["question_pattern_name"] = "Comprehension"

                          var comprehensionQuestionArray = []

                          for (let dataSecond of tempQuestionArray) {
                            if (dataOne.id_comprehension == dataSecond.id_comprehension && dataOne.id_comprehension != "0") {
                              comprehensionQuestionArray.push(dataSecond)
                            }

                          }
                          json["questions"] = comprehensionQuestionArray
                          QuestionArray.push(json)
                        }

                      }
                      else {
                        QuestionArray.push(dataOne)
                      }
                    }
                    this.SelectedQuestions = QuestionArray;
                    this.orignalSelectedQuestions = QuestionArray;
                    this.refreshTablePfQuestionPattern();
                    this.calculateDifficultyAndInterpretationLevelPercentage();
                    this.isAutogenerateModalQuestion = false;
                  }
                  else {
                    this.showAlertComponent(data['status_code'], data['status_message']);
                  }
                },
                err => {
                  this.showLoader = false;
                  swal.fire("Something went wrong. Please try again later.")
                }
              )
            }
          })
        }
        else {
          let json = {
            "caller_email": localStorage.getItem("email"),
            "id_pattern": this.patternId,
            "id_course_type": this.courseTypeId,
            "id_question_source": this.questionSourceId,
            "selected_sub_topics": JSON.stringify(this.CheckedTopics)
          }
          this.showLoader = true;
          this.addExamPatternService.automatedQuestionGeneration(json).subscribe(
            data => {
              this.showLoader = false;
              if (data.status_code == "0") {
                this.SelectedQuestions = [];
                this.orignalSelectedQuestions = [];
                var questions = data.questions
                for (let data of questions) {
                  let json = {
                    "id_question": data.id_question,
                    "id_comprehension": data.id_comprehension,
                    "comprehesion_text": data.comprehesion_text,
                    "id_question_source": data.id_question_source,
                    "question_text": data.question_text,
                    "is_auto_generated": "0",
                    "id_subject": data.id_subject,
                    "id_question_pattern": data.id_question_pattern,
                    "question_pattern_name": data.question_pattern_name,
                    "question_optiones": data.question_optiones,

                    "answer": data.answer,
                    "matrix_match": data.matrix_match,
                    "column_one": data.column_one,
                    "column_second": data.column_second,
                    "sollution_text": data.sollution_text,
                    "question_interpretation_name": data.question_interpretation_name,
                    "difficulty_name": data.difficulty_name,
                  }
                  this.SelectedQuestions.push(json)
                  this.orignalSelectedQuestions.push(json);
                }
                let tempQuestionArray = this.SelectedQuestions;
                var QuestionArray = [];
                for (let dataOne of tempQuestionArray) {
                  let json = {};
                  if (dataOne.id_comprehension != "0") {
                    const index = QuestionArray.findIndex(question => question.id_comprehension === dataOne.id_comprehension);
                    if (index == -1) {

                      json["id_comprehension"] = dataOne.id_comprehension

                      json["comprehension_text"] = dataOne.comprehesion_text
                      json["id_subject"] = dataOne.id_subject
                      json["subject_name"] = dataOne.subject_name
                      json["id_sub_subject"] = dataOne.id_sub_subject
                      json["sub_subject_name"] = dataOne.sub_subject_name
                      json["id_topic"] = dataOne.id_topic
                      json["topic_name"] = dataOne.topic_name
                      json["last_review_date"] = dataOne.last_review_date
                      json["id_question_pattern"] = "3"
                      json["id_question_source"] = dataOne.id_question_source
                      json["id_difficult_level"] = dataOne.id_difficult_level
                      json["question_source_name"] = dataOne.question_source_name
                      json["difficulty_name"] = dataOne.difficulty_name
                      json["is_approved"] = dataOne.is_approved
                      json["question_pattern_name"] = "Comprehension"

                      var comprehensionQuestionArray = []

                      for (let dataSecond of tempQuestionArray) {
                        if (dataOne.id_comprehension == dataSecond.id_comprehension && dataOne.id_comprehension != "0") {
                          comprehensionQuestionArray.push(dataSecond)
                        }

                      }
                      json["questions"] = comprehensionQuestionArray
                      QuestionArray.push(json)
                    }

                  }
                  else {
                    QuestionArray.push(dataOne)
                  }
                }
                this.SelectedQuestions = QuestionArray;
                this.orignalSelectedQuestions = QuestionArray;

                this.refreshTablePfQuestionPattern();
                this.calculateDifficultyAndInterpretationLevelPercentage();
                this.isAutogenerateModalQuestion = false;
              }
              else {
                this.showAlertComponent(data['status_code'], data['status_message']);
              }
            },
            err => {
              this.showLoader = false;
              swal.fire("Something went wrong. Please try again later.")
            }
          )
        }

      }
      // else {
      //   var tempjson = this.getJsonForGenerateQuestionAutomatically();
      //   var tempSelectedQuestion = [];
      //   for (let data of this.SelectedQuestions) {
      //     let json = {
      //       "id_question": data.id_question
      //     }
      //     tempSelectedQuestion.push(json)
      //   }
      //   let json = {
      //     "caller_email": localStorage.getItem("email"),
      //     "id_pattern": this.patternId,
      //     "id_course_type": this.courseTypeId,
      //     "selected_sub_topics": JSON.stringify(tempjson),

      //     "selected_question": JSON.stringify(tempSelectedQuestion)
      //   }
      //   this.showLoader = true;
      //   this.addExamPatternService.automatedRemainingQuestionGeneration(json).subscribe(
      //     data => {
      //       this.showLoader = false;
      //       if (data.status_code == "0") {
      //         var questions = data.questions
      //         for (let data of questions) {
      //           let json = {
      //             "id_question": data.id_question,
      //             "question_text": data.question_text,
      //             "id_comprehension": data.id_comprehension,
      //             "is_auto_generated": "0",
      //             "id_subject": data.id_subject,
      //             "id_question_pattern": data.id_question_pattern,
      //             "question_optiones": data.question_optiones,
      //             "answer": data.answer,
      //             "matrix_match": data.matrix_match,
      //             "column_one": data.column_one,
      //             "column_second": data.column_second,
      //             "sollution_text": data.sollution_text,
      //             "question_interpretation_name": data.question_interpretation_name,
      //             "difficulty_name": data.difficulty_name,
      //           }
      //           this.SelectedQuestions.push(json)
      //           this.refreshTablePfQuestionPattern();
      //           this.calculateDifficultyAndInterpretationLevelPercentage();
      //         }
      //         this.isAutogenerateModalQuestion = false;
      //       }
      //       else {
      //         this.showAlertComponent(data['status_code'], data['status_message']);
      //       }
      //     },
      //     err => {
      //       this.showLoader = false;
      //       swal.fire("Something went wrong. Please try again later.")
      //     }
      //   )
      // }
    }
  }

  getJsonForGenerateQuestionAutomatically() {
    var jsonArray = [];
    for (let data of this.CheckedTopics) {

      for (let pattern of this.FormatedPatternSubject) {
        if (data.id_subject == pattern[data.id_subject].id_subject) {

          let unselectedQuestion = (+pattern[data.id_subject].number_of_question) - (+pattern[data.id_subject].selected)
          if (unselectedQuestion < 0) {
            unselectedQuestion = 0;
          }
          var json = {
            "id_subject": data.id_subject,
            "id_topic": data.id_topic,
            "topic_weightage": data.topic_weightage,
            "id_question_pattern": pattern[data.id_subject].id_question_pattern,
            "unselected_question": unselectedQuestion
          }
          jsonArray.push(json)

        }
      }
    }
    return jsonArray;
  }

  refresh(index, value) {


    let tempArray = []
    for (let data of this.UnfilteredQuestions) {
      var check = false;
      if (data.id_comprehension == "0") {
        for (let selectedQuestion of this.SelectedQuestions) {
          if (data.id_question == selectedQuestion.id_question) {
            check = true;
            break;
          }
        }
      }
      else {
        for (let selectedQuestion of this.SelectedQuestions) {
          if (data.id_comprehension == selectedQuestion.id_comprehension) {
            check = true;
            break;
          }
        }
      }

      if (check == false) {
        tempArray.push(data)
      }
    }
    for (let data of tempArray) {

      if (value.id_comprehension == "0" && value.id_question_pattern == data.id_question_pattern && value.id_question_source == data.id_question_source && value.id_subject == data.id_subject) {
        let json = {
          "id_question": data.id_question,
          "id_question_source": data.id_question_source,
          "id_comprehension": data.id_comprehension,
          "question_text": data.question_text,
          "id_subject": data.id_subject,
          "question_pattern_name": data.quesion_pattern_name,
          "id_question_pattern": data.id_question_pattern,
          "is_auto_generated": "0",
          "question_optiones": data.question_optiones,
          "answer": data.answer,
          "matrix_match": data.matrix_match,
          "column_one": data.column_one,
          "column_second": data.column_second,
          "sollution_text": data.sollution_text,
          "question_interpretation_name": data.question_interpretation_name,
          "difficulty_name": data.difficulty_name,

        }
        this.SelectedQuestions[index] = json;
        break


      }

      else if (value.id_comprehension != "0" && value.id_question_pattern == "3" && data.id_comprehension != "0" && value.id_subject == data.id_subject) {

        data["question_pattern_name"] = data["quesion_pattern_name"]
        this.SelectedQuestions[index] = data;
        break
      }

    }
    this.refreshTablePfQuestionPattern();
    this.calculateDifficultyAndInterpretationLevelPercentage();
  }

  removeQuestion(index) {
    this.SelectedQuestions.splice(index, 1);
    this.refreshTablePfQuestionPattern();
    this.calculateDifficultyAndInterpretationLevelPercentage();
  }

  cancel() {
    this.router.navigateByUrl('/Online Test Template')
  }

  saveExamTemplate() {
    if (!this.testName) {
      swal.fire("Please enter test name.");
    }
    else if (!this.examDescription) {
      swal.fire("Please enter test description.")
    }
    else if (!this.examInstruction) {
      swal.fire("Please enter test instruction.")
    }
    else if (!this.examDuration) {
      swal.fire("Please enter test duration.")
    }
    else if (this.examTypeId == "0") {
      swal.fire("Please select test type.")
    }
    else if (this.courseTypeId == "0") {
      swal.fire("Please select course type.")
    }
    else if (this.patternId == "0") {
      swal.fire("Please select pattern.")
    }
    else if (this.examFor == "0") {
      swal.fire("Please select exam for.")
    }
    else if (this.SelectedQuestions.length == 0) {
      swal.fire("Please add some question.")
    }
    else {
      var checkQuestionPatternIsCorrect = false;
      for (let data of this.FormatedPatternSubject) {
        for (let header of this.PatternsSubjects) {
          if (data[header.id_subject].selected != data[header.id_subject].number_of_question) {
            checkQuestionPatternIsCorrect = true;
          }
        }
      }
      if (checkQuestionPatternIsCorrect == false) {
        if (this.examTemplateId) {
          let json = {
            "caller_email": localStorage.getItem("email"),
            "id_exam_template": this.examTemplateId,
            "id_course_type": this.courseTypeId,
            "id_pattern": this.patternId,
            "test_name": this.testName,
            "test_date": this.testDate,
            "test_description": this.examDescription,
            "test_instruction": this.examInstruction,
            "test_duration": this.examDuration,
            "test_type": this.examTypeId,
            "exam_for": this.examFor,
            "question": JSON.stringify(this.SelectedQuestions)
          }

          this.showLoader = true;
          this.addExamPatternService.editExamTemplate(json).subscribe(
            data => {
              this.showLoader = false;
              if (data.status_code == 0) {
                this.showAlertComponent(data['status_code'], data['status_message']);
                this.cancel();
              }
              else {
                this.showAlertComponent(data['status_code'], data['status_message']);
              }
            },
            err => {

              this.showLoader = false;
              swal.fire("Something went wrong. Please try again later.")
            }
          )
        }
        else {
          let json = {
            "caller_email": localStorage.getItem("email"),
            "id_course_type": this.courseTypeId,
            "id_pattern": this.patternId,
            "test_name": this.testName,
            "test_date": this.testDate,
            "test_description": this.examDescription,
            "test_instruction": this.examInstruction,
            "test_duration": this.examDuration,
            "test_type": this.examTypeId,
            "exam_for": this.examFor,
            "question": JSON.stringify(this.SelectedQuestions)
          }

          this.showLoader = true;

          this.addExamPatternService.addNewExamTemplate(json).subscribe(
            data => {
              this.showLoader = false;
              if (data.status_code == 0) {
                this.showAlertComponent(data['status_code'], data['status_message']);
                this.cancel();
              }
              else {
                this.showAlertComponent(data['status_code'], data['status_message']);
              }
            },
            err => {

              this.showLoader = false;
              swal.fire("Something went wrong. Please try again later.")
            }
          )
        }
      }
      else {
        swal.fire("Please add/remove/edit question to match pattern.")
      }

    }
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }

  hideAlert(val) {
    this.showAlert = val;
  }

  getQuestionText(data) {


    if (data.id_question_pattern == "6") {
      let x = data.question_text.split("$$");

      return "Assertion: " + x[0] + " Reason: " + x[1]
    }
    else {
      return data.question_text;
    }
  }

  filterQuestionByQuestionSourceId() {
    this.Questions = [];
    if (this.SelectedQuestions.length > 0) {
      var tempArray = this.removeSelectedQuestionFormQuestion();
      for (let data of tempArray) {
        if (data.id_sub_topic == this.subTopicId) {
          this.Questions.push(data)
        }
        else {
          this.Questions.push(data)
        }
      }
    }
    else {
      if (this.questionSourceId != "0") {
        for (let data of this.UnfilteredQuestions) {
          if (data.id_question_source == this.questionSourceId) {
            this.Questions.push(data)
          }
        }
      }
      else {
        this.Questions = this.UnfilteredQuestions
      }

    }


    let tempQuestionArray = this.Questions
    var QuestionArray = [];
    for (let dataOne of tempQuestionArray) {
      let json = {};
      if (dataOne.id_comprehension != "0") {
        const index = QuestionArray.findIndex(question => question.id_comprehension === dataOne.id_comprehension);
        if (index == -1) {
          json["id_comprehension"] = dataOne.id_comprehension
          json["comprehension_text"] = dataOne.comprehension_text
          json["id_subject"] = dataOne.id_subject
          json["subject_name"] = dataOne.subject_name
          json["id_sub_subject"] = dataOne.id_sub_subject
          json["sub_subject_name"] = dataOne.sub_subject_name
          json["id_topic"] = dataOne.id_topic
          json["topic_name"] = dataOne.topic_name
          json["id_question_source"] = dataOne.id_question_source
          json["id_difficult_level"] = dataOne.id_difficult_level
          json["question_source_name"] = dataOne.question_source_name
          json["difficulty_name"] = dataOne.difficulty_name
          json["last_review_date"] = dataOne.last_review_date
          json["id_question_pattern"] = "3"
          json["is_approved"] = dataOne.is_approved
          json["quesion_pattern_name"] = "Comprehension"
          var comprehensionQuestionArray = []

          for (let dataSecond of tempQuestionArray) {
            if (dataOne.id_comprehension == dataSecond.id_comprehension && dataOne.id_comprehension != "0") {
              comprehensionQuestionArray.push(dataSecond)
            }

          }
          json["questions"] = comprehensionQuestionArray
          QuestionArray.push(json)
        }

      }
      else {
        QuestionArray.push(dataOne)
      }
    }
    this.Questions = QuestionArray


  }

  filterQuestionByQuestionPatternId() {
    this.Questions = [];
    if (this.SelectedQuestions.length > 0) {
      var tempArray = this.removeSelectedQuestionFormQuestion();
      for (let data of tempArray) {
        if (data.id_sub_topic == this.subTopicId) {
          this.Questions.push(data)
        }
        else {
          this.Questions.push(data)
        }
      }
    }
    else {
      if (this.questionPatternId != "0") {
        for (let data of this.UnfilteredQuestions) {
          if (this.questionSourceId != "0") {
            if (data.id_question_pattern == this.questionPatternId && data.id_question_source == this.questionSourceId) {
              this.Questions.push(data)
            }
          }
          else {
            if (data.id_question_pattern == this.questionPatternId) {
              this.Questions.push(data)
            }
          }

        }
      }
      else {
        this.Questions = this.UnfilteredQuestions
      }

    }

    let tempQuestionArray = this.Questions
    var QuestionArray = [];
    for (let dataOne of tempQuestionArray) {
      let json = {};
      if (dataOne.id_comprehension != "0") {
        const index = QuestionArray.findIndex(question => question.id_comprehension === dataOne.id_comprehension);
        if (index == -1) {
          json["id_comprehension"] = dataOne.id_comprehension
          json["comprehension_text"] = dataOne.comprehension_text
          json["id_subject"] = dataOne.id_subject
          json["subject_name"] = dataOne.subject_name
          json["id_sub_subject"] = dataOne.id_sub_subject
          json["sub_subject_name"] = dataOne.sub_subject_name
          json["id_topic"] = dataOne.id_topic
          json["topic_name"] = dataOne.topic_name
          json["id_question_source"] = dataOne.id_question_source
          json["id_difficult_level"] = dataOne.id_difficult_level
          json["question_source_name"] = dataOne.question_source_name
          json["difficulty_name"] = dataOne.difficulty_name
          json["last_review_date"] = dataOne.last_review_date
          json["id_question_pattern"] = "3"
          json["is_approved"] = dataOne.is_approved
          json["quesion_pattern_name"] = "Comprehension"
          var comprehensionQuestionArray = []
          for (let dataSecond of tempQuestionArray) {
            if (dataOne.id_comprehension == dataSecond.id_comprehension && dataOne.id_comprehension != "0") {
              comprehensionQuestionArray.push(dataSecond)
            }

          }
          json["questions"] = comprehensionQuestionArray
          QuestionArray.push(json)
        }

      }
      else {
        QuestionArray.push(dataOne)
      }
    }


    this.Questions = QuestionArray


  }

  filterQuestionByQuestionDifficultyId() {
    this.Questions = [];
    if (this.SelectedQuestions.length > 0) {
      var tempArray = this.removeSelectedQuestionFormQuestion();
      for (let data of tempArray) {
        if (data.id_sub_topic == this.subTopicId) {
          this.Questions.push(data)
        }
        else {
          this.Questions.push(data)
        }
      }
    }
    else {
      if (this.questionDifficultyId != "0") {
        for (let data of this.UnfilteredQuestions) {
          if (this.questionSourceId != "0" && this.questionPatternId != "0") {
            if (data.id_difficult_level == this.questionDifficultyId && data.id_question_pattern == this.questionPatternId && data.id_question_source == this.questionSourceId) {
              this.Questions.push(data)
            }
          }
          else if (this.questionSourceId != "0" && this.questionPatternId == "0") {
            if (data.id_difficult_level == this.questionDifficultyId && data.id_question_source == this.questionSourceId) {
              this.Questions.push(data)
            }
          }
          else if (this.questionSourceId == "0" && this.questionPatternId != "0") {
            if (data.id_difficult_level == this.questionDifficultyId && data.id_question_pattern == this.questionPatternId) {
              this.Questions.push(data)
            }
          }
          else {
            if (data.id_question_pattern == this.questionPatternId && data.id_question_source == this.questionSourceId) {
              this.Questions.push(data)
            }
          }
        }
      }
      else {
        this.Questions = this.UnfilteredQuestions
      }

    }

    let tempQuestionArray = this.Questions
    var QuestionArray = [];
    for (let dataOne of tempQuestionArray) {
      let json = {};
      if (dataOne.id_comprehension != "0") {
        const index = QuestionArray.findIndex(question => question.id_comprehension === dataOne.id_comprehension);
        if (index == -1) {
          json["id_comprehension"] = dataOne.id_comprehension
          json["comprehension_text"] = dataOne.comprehension_text
          json["id_subject"] = dataOne.id_subject
          json["subject_name"] = dataOne.subject_name
          json["id_sub_subject"] = dataOne.id_sub_subject
          json["sub_subject_name"] = dataOne.sub_subject_name
          json["id_topic"] = dataOne.id_topic
          json["id_question_source"] = dataOne.id_question_source
          json["id_difficult_level"] = dataOne.id_difficult_level
          json["question_source_name"] = dataOne.question_source_name
          json["difficulty_name"] = dataOne.difficulty_name
          json["topic_name"] = dataOne.topic_name
          json["last_review_date"] = dataOne.last_review_date
          json["id_question_pattern"] = "3"
          json["is_approved"] = dataOne.is_approved
          json["quesion_pattern_name"] = "Comprehension"

          var comprehensionQuestionArray = []

          for (let dataSecond of tempQuestionArray) {
            if (dataOne.id_comprehension == dataSecond.id_comprehension && dataOne.id_comprehension != "0") {
              comprehensionQuestionArray.push(dataSecond)
            }

          }
          json["questions"] = comprehensionQuestionArray
          QuestionArray.push(json)
        }

      }
      else {
        QuestionArray.push(dataOne)
      }
    }


    this.Questions = QuestionArray


  }


  showQuestionMOdal(data) {
    if (data.id_comprehension == "0" && data.id_question_pattern != "3") {
      this.QuestionData = data.question_text
      this.Optiones = data.question_optiones
      this.Answer = data.answer;
      this.ColumnOne = data.column_one;
      this.ColumnSecond = data.column_second;
      this.selectedQuestionPatternId = data.id_question_pattern;
      this.sollutionText = data.sollution_text
      this.MatrixCheckedAnswer = data.matrix_match
      this.generateMatrixMatchingAnswer();
      this.isQuestionViewModal = true;
    }
    else {
      this.QuestionData = data.comprehension_text
      this.ComprehensionQuestions = data.questions
      this.isComprehensionQuestionViewModal = true;
    }


  }

  hideModal() {
    this.isExamPaperPreview = false
    this.isQuestionViewModal = false;
    this.isComprehensionQuestionViewModal = false;
  }

  generateMatrixMatchingAnswer() {
    this.MatrixMatchingAnswerTable = [];
    if (this.ColumnOne.length > 0 && this.ColumnSecond.length > 0) {
      for (let data of this.ColumnOne) {
        let json = {
          "option": data.column_name,
          "answers": this.ColumnSecond
        }
        this.MatrixMatchingAnswerTable.push(json)
      }
    }


  }

  getQuestionTextForMdal(data) {


    {
      let x = data.split("$$");
      return "Assertion: " + x[0] + " Reason: " + x[1]
    }

  }

  checkIsCheckedOrNot(optionName, answerName) {
    let status = false;
    for (let data of this.MatrixCheckedAnswer) {
      if (data.option == optionName && data.answers == answerName) {
        status = true;
        break;
      }
    }

    return status;
  }

  filterTopicsForAutogenerate() {
    //.log("unser filterTopicsForAutogenerate")
    var tempArray = [];
    this.ArrayForAutogenerate = [];
    for (let data of this.UnfiteredArrayForAutogenerate) {
      let json = {
        "id_subject": data.id_subject,
        "subject_name": data.subject_name,
        "topics": data.topics
      }
      this.ArrayForAutogenerate.push(json)
    }

    //.log(" ArrayForAutogenerate-->" + this.ArrayForAutogenerate)
    for (let data of this.ArrayForAutogenerate) {
      for (let topic of data.topics) {

        if (this.subjectId == topic.id_subject && this.subSubjectId == topic.id_sub_subject) {
          //.log("unde if")
          tempArray.push(topic)
        }
        // else if (this.subjectId == topic.id_subject) {
        //   tempArray.push(topic)
        // }
        else if (this.subSubjectId == "0" && this.subjectId == topic.id_subject) {
          tempArray.push(topic)
        }
      }
      if (data.id_subject == this.subjectId) {
        data.topics = tempArray
      }

    }

  }

  calculateDifficultyAndInterpretationLevelPercentage() {
    this.DifficultyLevelPercentage = [];
    this.InterpretationLevelPercentage = [];
    //.log(this.QuestionDifficulty)
    for (let difficuty of this.QuestionDifficulty) {
      var count = 0;
      for (let question of this.SelectedQuestions) {
        if (question.hasOwnProperty('questions')) {
          for (let cq of question.questions) {
            if (difficuty.name == cq.difficulty_name) {
              count++;
            }
          }
          count = count / question.questions.length
          //.log(count)
        }
        else {
          if (difficuty.name == question.difficulty_name) {
            count++;
          }
        }

      }
      var percentage = ((count * 100) / this.SelectedQuestions.length).toFixed(2)
      let json = {
        "name": difficuty.name,
        "percentage": percentage + "%"
      }
      this.DifficultyLevelPercentage.push(json)
    }

    for (let interpratation of this.QuestionInterpratation) {
      var count = 0;
      for (let question of this.SelectedQuestions) {
        if (question.questions) {
          for (let cq of question.questions) {
            if (interpratation.name == cq.question_interpretation_name) {
              count++;
            }
          }
          count = count / question.questions.length
        }
        if (interpratation.name == question.question_interpretation_name) {
          count++;
        }
      }
      var percentage = ((count * 100) / this.SelectedQuestions.length).toFixed(2)
      let json = {
        "name": interpratation.name,
        "percentage": percentage + "%"
      }
      this.InterpretationLevelPercentage.push(json)
    }
    //.log(this.DifficultyLevelPercentage)
  }

  initializeTiny() {
    this.initEditText = {
      plugins: "image",
      readonly: 1,
      toolbar: "sizeselect | bold italic | fontselect |  fontsizeselect",
      images_upload_url: 'http://dakshana.beta.vendorZApp.in/service/upload_image.php',
      convert_urls: false,
      images_upload_handler: function (blobInfo, success, failure) {
        var xhr, formData;
        xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.open('POST', 'http://dakshana.beta.vendorZApp.in/service/upload_image.php');
        xhr.onload = function () {
          var json;

          if (xhr.status != 200) {
            failure('HTTP Error: ' + xhr.status);
            return;
          }
          json = JSON.parse(xhr.responseText);

          if (!json || typeof json.generatedName != 'string') {
            failure('Invalid JSON: ' + xhr.responseText);
            return;
          }
          success(json.generatedName);
        };
        formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.filename());
        xhr.send(formData);
      }
    }


  }


  filterAddManualyQuestion() {
    //.log(this.topicId)
    this.Questions = [];
    if (this.SelectedQuestions.length > 0) {
      this.Questions = this.removeSelectedQuestionFormQuestion();

    }
    else {
      this.Questions = this.UnfilteredQuestions;
    }

    if (this.subjectId != "0") {
      this.SubSubjects = [];
      for (let data of this.UnFilteredSubSubjects) {
        if (this.subjectId == data.id_subject) {
          this.SubSubjects.push(data)
        }
      }
      var tempArray = [];
      for (let question of this.Questions) {
        if (question.id_subject == this.subjectId) {
          tempArray.push(question)
        }
      }
      this.Questions = tempArray
    }
    if (this.subSubjectId != "0") {
      this.Topics = [];
      for (let data of this.unFilteredTopics) {
        if (this.subSubjectId == data.id_sub_subject) {
          this.Topics.push(data)
        }
      }
      var tempArray = [];
      for (let question of this.Questions) {
        if (question.id_sub_subject == this.subSubjectId) {
          tempArray.push(question)
        }
      }
      this.Questions = tempArray
    }
    if (this.topicId != "0") {
      this.SubTopics = [];
      for (let data of this.unFilteredSubTopics) {
        if (this.topicId == data.id_topic) {
          this.SubTopics.push(data)
        }
      }
      var tempArray = [];
      for (let question of this.Questions) {

        if (question.id_topic == this.topicId) {

          tempArray.push(question)
        }
      }
      this.Questions = tempArray
    }
    if (this.subTopicId != "0") {
      var tempArray = [];
      for (let question of this.Questions) {
        if (question.id_sub_topic == this.subTopicId) {
          tempArray.push(question)
        }
      }
      this.Questions = tempArray
    }
    if (this.questionSourceId != "0") {
      var tempArray = [];
      for (let question of this.Questions) {
        if (question.id_question_source == this.questionSourceId) {
          tempArray.push(question)
        }
      }
      this.Questions = tempArray
    }
    if (this.questionPatternId != "0") {
      var tempArray = [];
      for (let question of this.Questions) {
        if (question.id_question_pattern == this.questionPatternId) {
          tempArray.push(question)
        }
      }
      this.Questions = tempArray
    }

    if (this.questionDifficultyId != "0") {
      var tempArray = [];
      for (let question of this.Questions) {
        if (question.id_difficult_level == this.questionDifficultyId) {
          tempArray.push(question)
        }
      }
      this.Questions = tempArray
    }
    if (this.questionDifficultyId != "0") {
      var tempArray = [];
      for (let question of this.Questions) {
        if (question.id_difficult_level == this.questionDifficultyId) {
          tempArray.push(question)
        }
      }
      this.Questions = tempArray
    }

    // let tempQuestionArray = this.Questions
    // var QuestionArray = [];
    // for (let dataOne of tempQuestionArray) {
    //   let json = {};
    //   if (dataOne.id_comprehension != "0") {
    //     const index = QuestionArray.findIndex(question => question.id_comprehension === dataOne.id_comprehension);
    //     if (index == -1) {

    //       json["id_comprehension"] = dataOne.id_comprehension
    //       json["comprehension_text"] = dataOne.comprehension_text
    //       json["id_subject"] = dataOne.id_subject
    //       json["subject_name"] = dataOne.subject_name
    //       json["id_sub_subject"] = dataOne.id_sub_subject
    //       json["sub_subject_name"] = dataOne.sub_subject_name
    //       json["id_topic"] = dataOne.id_topic
    //       json["topic_name"] = dataOne.topic_name
    //       json["last_review_date"] = dataOne.last_review_date

    //       json["id_question_pattern"] = "3"
    //       json["is_approved"] = dataOne.is_approved

    //       json["quesion_pattern_name"] = "Comprehension"

    //       var comprehensionQuestionArray = []

    //       for (let dataSecond of tempQuestionArray) {
    //         if (dataOne.id_comprehension == dataSecond.id_comprehension && dataOne.id_comprehension != "0") {
    //           comprehensionQuestionArray.push(dataSecond)
    //         }

    //       }
    //       json["questions"] = comprehensionQuestionArray
    //       QuestionArray.push(json)
    //     }

    //   }
    //   else {
    //     QuestionArray.push(dataOne)
    //   }
    // }


    // this.Questions = QuestionArray


  }



  generateMatrixMatchingAnswerForComprehension(columnOne, columnSecond, matrixMatch) {
    var tempArray = [];
    this.MatrixCheckedAnswer = matrixMatch
    if (columnOne.length > 0 && columnSecond.length > 0) {
      for (let data of columnOne) {
        let json = {
          "option": data.column_name,
          "answers": columnSecond
        }
        tempArray.push(json)
      }
    }
    this.ComprehensionTypeMatrix = tempArray
    return tempArray

  }

  checkComprehensionMatrixIsCheckedOrNot(optionName, answerName) {
    let status = false;
    for (let data of this.MatrixCheckedAnswer) {
      if (data.option == optionName && data.answers == answerName) {
        status = true;
        break;
      }
    }

    return status;
  }




  showViewForExamDownloadWithoutSollution() {
    if (this.SelectedQuestions.length == 0) {
      swal.fire("Please add some questions first.")
    }
    else {
      this.isSollutionVisibleForExam = false;
      this.isExamPaperPreview = true;
    }


  }

  showViewForExamDownloadWithSollution() {
    if (this.SelectedQuestions.length == 0) {
      swal.fire("Please add some questions first.")
    }
    else {
      this.isSollutionVisibleForExam = true;
      this.isExamPaperPreview = true;
    }


  }

  print() {

    // let doc = new jsPDF()
    // let specialElementHandlers = {
    //   '#editor': function (element, renderer) {
    //     return true;
    //   }
    // };
    // let content = document.getElementById('printSection').innerHTML
    // doc.fromHTML(content, 15, 15, {
    //   'width': 190,
    //   'elementHnadlers': specialElementHandlers
    // }, function (bla) { doc.save('saveInCallback.pdf'); });

    let printContents, popupWin;

    printContents = document.getElementById('printSection').innerHTML;
    var htmlToPrint = '' +
      '<style type="text/css">' +
      'table th, table td {' +
      'border:1px solid #000;' +
      'padding;0.5em;' +
      '}' +
      '</style>';
    htmlToPrint += printContents;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
          <head>
          <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/css/bootstrap.min.css" integrity="sha384-9gVQ4dYFwwWSjIDZnLEWnxCjeSWFphJiwGPXr1jddIhOegiu1FwO5qRGvFXOdJZ4"
          crossorigin="anonymous">
        <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
          crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.0/umd/popper.min.js" integrity="sha384-cs/chFZiN24E4KMATLdqdvsezGxaGsi4hLGOzlXwp5UZB1LY//20VyM2taTB4QvJ"
          crossorigin="anonymous"></script>
        <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/js/bootstrap.min.js" integrity="sha384-uefMccjFJAIv6A+rW+L4AHf99KvxDjWSu1z9VI8SKNVmz4sk7buKt/6v9KI65qnm"
          crossorigin="anonymous"></script>
              <style>
              li{
                list-style:none;
              }
              ul {
                list-style-type: none;
              }
              
              </style>
          </head>
          <body onload="window.print();window.close()">${htmlToPrint}

          </body>
      </html>`
    );
    popupWin.document.close();
  }


  getQuestionAnswer(data) {
    let answerString = "";
    if (data.id_question_pattern == "1" || data.id_question_pattern == "2" || data.id_question_pattern == "6") {

      for (let option of data.question_optiones) {
        if (option.is_option_correct == "0") {
          if (answerString == "") {
            answerString = option.option_name
          }
          else {
            answerString = answerString + "," + option.option_name;
          }

        }
      }
    }
    else if (data.id_question_pattern == "4") {
      answerString = data.answer
    }
    else if (data.id_question_pattern == "5") {
      //.log(data.matrix_match)
      for (let match of data.matrix_match) {
        if (answerString == "") {
          answerString = match.option + "-->" + match.answers;
        }
        else {
          answerString = answerString + "," + match.option + "-->" + match.answers;
        }
      }
    }
    return answerString
  }

  filterSelectedQuestions()
  {

    console.log();

    this.SelectedQuestions = [];
    // filter by subject
    if(this.selectedIdSubject != '0'){
      this.orignalSelectedQuestions.forEach(qts => {
          if(qts.id_subject == this.selectedIdSubject)
          {
            this.SelectedQuestions.push(qts);
          }
      });
    }else{
      this.SelectedQuestions = this.orignalSelectedQuestions;
    }

    // filter by question type
    if(this.selectedIdQuestionType != '0')
    {
      var temp = [];
      this.SelectedQuestions.forEach(qts => {
        temp.push(qts);
      });

      this.SelectedQuestions= [];
      temp.forEach(qts => {
          if(qts.id_question_pattern == this.selectedIdQuestionType)
          {
            this.SelectedQuestions.push(qts);
          }
      });

    }

    // filter by difficulty level

    console.log("selectedIdDifficultyLevel="+this.selectedIdDifficultyLevel)

    if(this.selectedIdDifficultyLevel != '0')
    {
      var temp = [];
      this.SelectedQuestions.forEach(qts => {
        temp.push(qts);
      });

      this.SelectedQuestions= [];
      temp.forEach(qts => {
        console.log("qts=="+qts.id_difficult_level)
          if(qts.id_difficult_level == this.selectedIdDifficultyLevel)
          {
            this.SelectedQuestions.push(qts);
          }
      });

    }


  }

}
