import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';

@Injectable()
export class QulayfyingProbablityReportsService{
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }
     getAllMasterData(email) {
        return this.http.get(this.baseUrl + "/Section/getAllMasterDataForSection?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }
 
    getScholarPerformanceGraphAdminDashBoard(email,examLimit) {
        return this.http.get(this.baseUrl + "/Reports/getScholarPerformanceGraphAdminDashBoard?caller_email=" + email+"&examLimit="+examLimit ).map((res) => JSON.parse(JSON.stringify(res)));
    }

    
    getScholarPerformanceGraphAdminDashBoardByCoeId(email,examLimit,idCoe) {
        return this.http.get(this.baseUrl + "/Reports/getScholarPerformanceGraphAdminDashBoardByCoeId?caller_email=" + email+"&examLimit="+examLimit+"&id_coe="+idCoe).map((res) => JSON.parse(JSON.stringify(res)));
    }


}