import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class ExamResultServiceService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }


    getExamResultOverView(email, coeRollNumber, examTemplateId) {
        return this.http.get(this.baseUrl + "OnlineExam/getExamResultOverView?email=" + email + "&coe_roll_number=" + coeRollNumber + "&id_exam_template=" + examTemplateId).map((res) => JSON.parse(JSON.stringify(res)));
    }
    getExamResultDetailed(email, coeRollNumber, examTemplateId) {
        return this.http.get(this.baseUrl + "OnlineExam/getExamResult?email=" + email + "&coe_roll_number=" + coeRollNumber + "&id_exam_template=" + examTemplateId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getScholarPerformanceGraph(email, coeRollNumber, examTemplateId, id_subject) {
        return this.http.get(this.baseUrl + "OnlineExam/getScholarPerformanceGraph?caller_email=" + email + "&coe_roll_number=" + coeRollNumber + "&id_exam_template=" + examTemplateId + "&id_subject=" + id_subject).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllExamPerformanceGraph(email, coeRollNumber, examTemplateId) {
        return this.http.get(this.baseUrl + "OnlineExam/getExamScoreGraph?caller_email=" + email + "&coe_roll_number=" + coeRollNumber + "&id_exam_template=" + examTemplateId + "&by_default=" + "5").map((res) => JSON.parse(JSON.stringify(res)));
    }

    getScholarPeerComparisionGraph(email, coeRollNumber, examTemplateId, subjectId) {
        return this.http.get(this.baseUrl + "OnlineExam/getScholarPeerComparisionGraph?caller_email=" + email + "&coe_roll_number=" + coeRollNumber + "&id_exam_template=" + examTemplateId + "&by_default=" + "5" + "&id_subject=" + subjectId).map((res) => JSON.parse(JSON.stringify(res)));
    }
}