import { Component } from '@angular/core';
import { BatchMasterService } from './batch-master.service'
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2'
import { DatePipe } from '@angular/common'
import { Helper } from '../helper'
@Component({
    selector: 'batch-master',
    templateUrl: 'batch-master.component.html',
    styleUrls: ['batch-master.component.scss'],
    providers: [BatchMasterService, Helper, DatePipe]
})
export class BatchMasterComponent {
    showLoader: boolean;
    Coes: any;
    coeId: any;
    courseId: any;
    startDate: any;
    endDate: any;
    statusMessage: any;
    isAddModal: boolean;
    Courses: any;
    batchName: any;
    modelHeadelLabel: any;
    Years: any;
    selectedYear: any;
    Batches: any;
    unfilterdBatches: any
    searchText: any;
    searchText1:any;
    previousCoeId: any;
    previousCourseId: any;
    purpose: any;
    batchId: any;
    isShowActivebatches: any
    showSection: boolean
    Sections: any;
    selectedBatchId: any;
    alertDiv = false;
    sucessAlertMessage: any;
    showAlert: boolean;
    alertMsg: any;
    alertType = "";
    column: string = 'CategoryName';
    direction: number;
    isDesc: boolean = false;
    showConvertToAlumn = false;
    showAlumnStudentView = false;
    isAlumnStudentModal = false;
    constructor(private batchMasterService: BatchMasterService, private router: Router, private helper: Helper, private datepipe: DatePipe, private activatedRoute: ActivatedRoute) {
        this.coeId = "0"
        this.courseId = "0"
        this.previousCourseId = "0"
        this.previousCoeId = "0"
        this.courseId = "0"
        this.selectedYear = "0"
        this.isAddModal = false;
        this.purpose = "Add"
        this.isShowActivebatches = 0;
        this.showSection = false
        this.Years = this.helper.getYears();
        this.statusMessage = "No record found"
        this.Batches = []
        this.unfilterdBatches = [];

        if (localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "1") {
            this.activatedRoute.queryParams.subscribe(params => {
                if (params.coeId) {
                    this.coeId = params.coeId
                }
            });
            this.getAllCourse();


        }
        else {
            this.router.navigateByUrl('/');
        }
    }

    getAllCourse() {
        this.Courses = []
        this.batchMasterService.getAllCourse(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {
                    this.Courses = data["courses"];
                    this.getAllCoe();
                }
                else {
                    this.showAlertDiv(data.status_message)
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }

    getAllBatches() {
        this.Batches = []
        this.showLoader = true;
        this.batchMasterService.getAllBatches(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false;
                this.showSection = false;
                if (data.status_code == 0) {
                    this.Batches = data["batches"];
                    this.unfilterdBatches = data["batches"];
                    this.showAlertComponent(data['status_code'], data['status_message']);
                    this.showSectionsView(this.Batches[0]);
                    this.selectCoEType()
                    this.isShowActivebatches = "0"
                    this.showOnlyActiveBatches();
                }
                else {
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }
    getAllCoe() {
        this.Coes = [];
        this.showLoader = true
        this.batchMasterService.getAllCoe(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {
                    this.Coes = data["coes"];
                    this.getAllBatches();
                }
                else {
                    this.showAlertDiv(data.status_message)
                }
                if (this.Coes.length == 0) {
                    this.statusMessage = "No records found";
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }
    addNew() {
        if (this.Coes.length == 0) {
            swal.fire("First you have to add CoE, to add Batch.")
        }
        else {
            this.isAddModal = true;
            this.modelHeadelLabel = "Add New Batch"
            this.clearData();
        }

    }

    dateVailidation() {
        //(this.startDate)
    }
    save() {
        if (!this.coeId || this.coeId == "0") {
            swal.fire("Please select CoE.");
        }
        else if (!this.courseId || this.courseId == "0") {
            swal.fire("Please select course.");
        }
        else if (!this.batchName) {
            swal.fire("Please enter batch name.");
        }
        else if (!this.startDate) {
            swal.fire("Please select start date.");
        }
        else if (!this.endDate) {
            swal.fire("Please select end date.");
        }
        else if (this.startDate > this.endDate) {
            swal.fire("End date can not be less than start date.");
        }
        else if (!this.selectedYear || this.selectedYear == "0") {
            swal.fire("Please select year of attempt.");
        }
        else {
            if (this.purpose == "Add") {
                let json = {
                    "caller_email": localStorage.getItem("email"),
                    "id_coe": this.coeId,
                    "id_course_type": this.courseId,
                    "batch_name": this.batchName,
                    "start_date": this.startDate,
                    "end_date": this.endDate,
                    "year_of_attempt": this.selectedYear

                }
                this.showLoader = true;
                this.batchMasterService.addNewBatch(json).subscribe(
                    data => {
                        this.showLoader = false;
                        this.showSection = false
                        if (data.status_code == 0) {
                            this.Batches = data["batches"];
                            this.unfilterdBatches = data["batches"];
                            this.showSectionsView(this.Batches[0]);
                            this.isAddModal = false;
                            this.clearData()
                            this.showAlertComponent(data['status_code'], data['status_message']);
                            this.selectCoEType()
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                        if (this.Coes.length == 0) {
                            this.statusMessage = "No records found";
                        }
                    },
                    err => {
                        this.showLoader = false;
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }
            else {
                let json = {
                    "id_batch": this.batchId,
                    "caller_email": localStorage.getItem("email"),
                    "id_coe": this.coeId,
                    "id_course_type": this.courseId,
                    "batch_name": this.batchName,
                    "start_date": this.startDate,
                    "end_date": this.endDate,
                    "year_of_attempt": this.selectedYear

                }
                this.showLoader = true;
                this.batchMasterService.editBatch(json).subscribe(
                    data => {
                        this.showSection = false;
                        this.showLoader = false;
                        if (data.status_code == 0) {
                            this.Batches = data["batches"];
                            this.unfilterdBatches = data["batches"];
                            this.showSectionsView(this.Batches[0]);
                            this.isAddModal = false
                            this.showAlertComponent(data['status_code'], data['status_message']);
                            this.selectCoEType()
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.showLoader = false;
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }

        }
    }
    editBatch(data) {
        this.purpose = "Edit"
        this.modelHeadelLabel = "Edit Batch"
        this.batchId = data.id_batch
        this.isAddModal = true
        this.coeId = data.id_coe
        this.courseId = data.id_course_type
        this.batchName = data.name
        this.startDate = this.datepipe.transform(data.start_date, 'yyyy-MM-dd');
        //(this.startDate)
        this.endDate = this.datepipe.transform(data.end_date, 'yyyy-MM-dd');
        this.selectedYear = data.attempt_year
    }
    removeBatch(data) {
        swal.fire({
            title: '',
            text: "Deleting an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to remove this batch?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#428e63',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.value) {
                this.Batches = [];
                this.unfilterdBatches = [];
                this.showLoader = true;
                this.showLoader = true;

                this.batchMasterService.removeBatch(data.id_batch, localStorage.getItem("email")).subscribe(
                    data => {
                        this.showSection = false
                        this.showLoader = false;
                        if (data.status_code == 0) {
                            this.Batches = data["batches"];
                            this.unfilterdBatches = data["batches"];
                            this.showSectionsView(this.Batches[0]);
                            this.isAddModal = false
                            this.showAlertComponent(data['status_code'], data['status_message']);
                            this.selectCoEType()
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.showLoader = false;
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }
        })

    }

    changeStatus(data) {
        var statusId: any
        if (data.id_status == "0") {
            statusId = "1"
        }
        else {
            statusId = "0"
        }
        if (statusId == 1) {
            swal.fire({
                title: '',
                text: "Deactivating an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to deactivate this Batch?",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#8B0000',
                cancelButtonColor: '#428e63',
                confirmButtonText: "Yes, deactivate it!"
            }).then((result) => {
                if (result.value) {

                    this.showLoader = true;
                    this.batchMasterService.changeStatus(data.id_batch, localStorage.getItem("email"), statusId).subscribe(
                        data => {

                            this.showLoader = false;
                            this.showSection = false
                            if (data.status_code == 0) {
                                this.Batches = data["batches"];
                                this.showSectionsView(this.Batches[0]);
                                this.unfilterdBatches = data["batches"];
                                this.isAddModal = false
                                this.showAlertComponent(data['status_code'], data['status_message']);
                                if (this.isShowActivebatches == 0) {
                                    this.isShowActivebatches = 1
                                }
                                else {
                                    this.isShowActivebatches = 0
                                }

                                this.showOnlyActiveBatches();
                                this.selectCoEType()

                            }
                            else {
                                this.showAlertComponent(data['status_code'], data['status_message']);
                            }
                        },
                        err => {
                            this.showLoader = false;
                            swal.fire("Something went wrong. Please try again later.")
                        }
                    )
                }
                else {
                    if (statusId == 0) {
                        (<HTMLInputElement>document.getElementById(data.id_batch)).checked = false;
                    }
                    else {
                        (<HTMLInputElement>document.getElementById(data.id_batch)).checked = true;
                    }
                }
            })
        }
        else {
            this.showLoader = true;
            this.batchMasterService.changeStatus(data.id_batch, localStorage.getItem("email"), statusId).subscribe(
                data => {

                    this.showLoader = false;
                    this.showSection = false
                    if (data.status_code == 0) {
                        this.Batches = data["batches"];
                        this.showSectionsView(this.Batches[0]);
                        this.unfilterdBatches = data["batches"];
                        this.isAddModal = false
                        this.showAlertComponent(data['status_code'], data['status_message']);
                        if (this.isShowActivebatches == 0) {
                            this.isShowActivebatches = 1
                        }
                        else {
                            this.isShowActivebatches = 0
                        }

                        this.showOnlyActiveBatches();
                        this.selectCoEType()

                    }
                    else {
                        this.showAlertComponent(data['status_code'], data['status_message']);
                    }
                },
                err => {
                    this.showLoader = false;
                    swal.fire("Something went wrong. Please try again later.")
                }
            )
        }

        this.showLoader = true;

    }
    hideAddEditModal() {
        this.isAddModal = false
        this.clearData();

    }
    clearData() {
        this.purpose = "Add"
        //(this.previousCoeId)
        if (this.previousCourseId != "0") {
            this.coeId = this.previousCoeId
        }

        this.courseId = this.previousCourseId
        this.batchName = ""
        this.startDate = ""
        this.endDate = ""
        this.selectedYear = "0"
    }

    selectCoEType() {
        this.showSection = false;
        var tempArray = this.Batches;

        this.previousCoeId = this.coeId;
        this.previousCourseId = this.courseId;
        if (this.coeId != "0") {
            this.Batches = [];
            for (let data of this.unfilterdBatches) {
                if (data.id_coe == this.coeId) {
                    this.Batches.push(data)
                }
            }
        }
        if (this.isShowActivebatches == 0) {
            this.isShowActivebatches = 1
        }
        else {
            this.isShowActivebatches = 0
        }
        this.showOnlyActiveBatches();


    }

    showOnlyActiveBatches() {
        this.Batches = [];
        //.log("showOnlyActiveBatches" + JSON.stringify(this.unfilterdBatches));
        if (this.isShowActivebatches == 1) {
            this.isShowActivebatches = 0
            for (let data of this.unfilterdBatches) {
                if (this.coeId !== "0") {
                    if (data.id_status == "1" && data.id_coe == this.coeId) {
                        this.Batches.push(data)
                    }
                }
                else {
                    if (data.id_status == "1") {
                        this.Batches.push(data)
                    }
                }

            }
        }
        else {
            this.isShowActivebatches = 1
            for (let data of this.unfilterdBatches) {
                if (this.coeId !== "0") {
                    if (data.id_status == "0" && data.id_coe == this.coeId) {
                        this.Batches.push(data)
                    }
                }
                else {
                    if (data.id_status == "0") {
                        this.Batches.push(data)
                    }
                }
            }
        }
    }

    showOnlyBatches(status) {
        this.Batches = [];
        //.log("showOnlyActiveBatches" + JSON.stringify(this.unfilterdBatches));
        if (status == 0) {
            this.isShowActivebatches = 0
            for (let data of this.unfilterdBatches) {
                if (this.coeId !== "0") {
                    if (data.id_status == "1" && data.id_coe == this.coeId) {
                        this.Batches.push(data)
                    }
                }
                else {
                    if (data.id_status == "1") {
                        this.Batches.push(data)
                    }
                }

            }
        }
        else {
            this.isShowActivebatches = 1
            for (let data of this.unfilterdBatches) {
                if (this.coeId !== "0") {
                    if (data.id_status == "0" && data.id_coe == this.coeId) {
                        this.Batches.push(data)
                    }
                }
                else {
                    if (data.id_status == "0") {
                        this.Batches.push(data)
                    }
                }
            }
        }
    }

    hideSectionView() {
        this.showSection = false;
    }
    showSectionsView(data) {
        //(this.showSection)
        this.selectedBatchId = data.id_batch;
        this.showSection = true;
        this.showLoader = true;
        this.Sections = []
        this.batchMasterService.getAllSectionsByBatchId(localStorage.getItem("email"), data.id_batch).subscribe(
            data => {

                this.showLoader = false;
                if (data.status_code == 0) {
                    this.Sections = data["sections"];
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
                else {
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }
    isRowActive(batchId) {
        //("selected batch id" + this.selectedBatchId)
        //(" batch id" + batchId)
        if (this.selectedBatchId == batchId) {
            return true
        }
        else {
            return false;
        }
    }
    showAlertDiv(message) {
        var that = this
        that.alertDiv = true
        this.sucessAlertMessage = message
        setTimeout(function () {
            that.alertDiv = false
        }, 5000);
    }

    addSection() {
        this.router.navigateByUrl('/section master')
    }

    moveToSection(data) {
        this.router.navigate(['./section master'], { queryParams: { batchId: data.id_batch, coeId: data.id_coe } });

    }


    showAlertComponent(status, messege) {
        this.showAlert = true;
        this.alertMsg = messege;
        //("status" + status);
        if (parseInt(status) == 0) {
            this.alertType = "success";
        }
        else {
            this.alertType = "failure";
        }
    }
    hideAlert(val) {
        this.showAlert = val;
    }
    sort(property) {
        this.isDesc = !this.isDesc; //change the direction    
        this.column = property;
        let direction = this.isDesc ? 1 : -1;

        this.Batches.sort(function (a, b) {
            if (a[property] < b[property]) {
                return -1 * direction;
            }
            else if (a[property] > b[property]) {
                return 1 * direction;
            }
            else {
                return 0;
            }
        });
    }

    convertToAlumn(){
        this.showConvertToAlumn = !this.showConvertToAlumn;
        this.showAlumnStudentView = false;
        this.selectedCoeId = this.Coes[0]['id_coe'];
        this.getAllCoeBatches();
    }

    backOnStudentViewClick()
    {
        this.showConvertToAlumn = true;
        this.showAlumnStudentView = false;
    }

    coe_batches = [];
    selectedCoeId = '';
    getAllCoeBatches() {
        this.coe_batches = []
        this.showLoader = true;
        this.batchMasterService.getAllBatchByCoeId(localStorage.getItem("email"),this.selectedCoeId).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {
                    this.coe_batches = data["batches"];     
                    this.coe_batches.forEach(batch => {
                         batch.sections.forEach(section => {
                             section.is_checked = '0';
                         });
                    });               
                }
                else {
                    swal.fire(data['status_message']);
                    // this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }

    checkUncheckSection(section){
        console.log("checkUncheckSection called");
        if(section.is_checked == '1')
          section.is_checked = '0'
        else
          section.is_checked = '1'

    }

    openAlumnStuentView()
    {
        let isAtLeastOneSectionSelected = false;
        let selectedSection = [];
        for(let batch of this.coe_batches){
            for(let section of batch.sections){
                if(section.is_checked == '1') 
                {
                 isAtLeastOneSectionSelected = true;
                  

                 break;
                } 
            }

            if(isAtLeastOneSectionSelected)break;
        }

        if(isAtLeastOneSectionSelected)
        {     this.showAlumnStudentView = true;
              // get selected sections students
              this.getSelectedSectionsStudents();
        }
        else{
            swal.fire("Please select at least one section.")
        }
      
    }

    sectionStudents = [];
    scholarStatusStudents = [];
    getSelectedSectionsStudents()
    {
        this.sectionStudents = []
        this.showLoader = true;
        let selectedSection = [];
        for(let batch of this.coe_batches){
            for(let section of batch.sections){
                if(section.is_checked == '1') 
                {
                     var obj = {'id_section':section.id_section};
                     selectedSection.push(obj);
                }
            }
        }        
      
        let json = {
            "sections": selectedSection,
            "caller_email": localStorage.getItem("email")            
        }
        this.batchMasterService.getAllSectionStudents(json).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {
                    this.sectionStudents = data['students'];    
                     // get student only has scholar status as lifetime status
                     this.scholarStatusStudents = [];
                     this.sectionStudents.forEach(student => {
                          if(student.lifetime_status == '25')
                          {
                             this.scholarStatusStudents.push( student);
                          }
                     });
                          
                }
                else {
                    swal.fire(data['status_message']);
                    // this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }

    isCheckAll = false;
    checkStudentArray = [];
    checkUncheckAll() {
            this.isCheckAll = !this.isCheckAll;
            this.checkStudentArray = [];
            if (this.isCheckAll) {
                for (let data of this.scholarStatusStudents) {
                    this.checkStudentArray.push({ 'id_scholar': data.id_scholar ,'name':data.name,'dakshana_roll_number':data.dakshana_roll_number,'batch_name':data.batch_name});
                }
            }

            console.log("this.checkStudentArray length="+this.checkStudentArray.length);
        
    }

    isCheckAllCandidate()
    {
        if (this.checkStudentArray.length === this.scholarStatusStudents.length) {
            return true;
        }
        else {
            return false;
        }
    }

    checkisCheckedOrNot(scholarId) {
        
        if(this.checkStudentArray.length > 0){
        let index = this.checkStudentArray.findIndex(x => x.id_scholar === scholarId)
        console.log("index="+index);
        if ( (index != -1)) {
            console.log("return="+true);
            return true
        }
        else {
            console.log("return="+false);
            return false;
        }
       }else{
           return false;
       }
    }

    checkUncheckScholar(data) {
        let index = this.checkStudentArray.findIndex(x => x.id_scholar == data.id_scholar)
        
        if (index != -1) {
            this.checkStudentArray.splice(index, 1)
        }
        else {
            this.checkStudentArray.push({ 'id_scholar': data.id_scholar ,'name':data.name,'dakshana_roll_number':data.dakshana_roll_number,'batch_name':data.batch_name})
        }
    }

    
    convertStudentToAlumn()
    {        
        let json = {
            "students": this.checkStudentArray,
            "caller_email": localStorage.getItem("email")            
        }

        var batchNameArray=[];
        this.checkStudentArray.forEach(student => {
            var flag = false;
             batchNameArray.forEach(bname => {
                 if(bname == student.batch_name){
                     flag = true;
                 }
             });
             if(!flag)
             batchNameArray.push(student.batch_name);
        });

        var batchString = batchNameArray.join(","); 

        var content = document.createElement('div');
        content.innerHTML = 'Are you sure you want to convert '+this.checkStudentArray.length +' scholars of </br> Batch '+'<strong>'+ batchString +'</strong> </br> to alumni?';



        var that = this;
        swal.fire({
            title: '',
            // text: "Are you sure you want to convert "+this.checkStudentArray.length + " students of batch "+batchString +"  to alumnus?",
            html:content,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#428e63',
            confirmButtonText: 'Yes, convert it!'
        }).then((result) => {
            if(result.value){
                that.showLoader = true;
                that.batchMasterService.convertToAlumn(json).subscribe(
                    data => {
                        that.showLoader = false;
                        if (data.status_code == 0) {
                            swal.fire(data['status_message']);
                            that.showConvertToAlumn = false;
                            that.showAlumnStudentView = false;
                            that.getAllBatches() ;
                        }
                        else {
                            swal.fire(data['status_message']);
                            // this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        that.showLoader = false;
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }
        });        
    }

    convertStudentToNonAlumn()
    {  
       
        let json = {
            "students": this.checkStudentArray,
            "caller_email": localStorage.getItem("email")            
        }

        var batchNameArray=[];
        this.checkStudentArray.forEach(student => {
            var flag = false;
             batchNameArray.forEach(bname => {
                 if(bname == student.batch_name){
                     flag = true;
                 }
             });
             if(!flag)
             batchNameArray.push(student.batch_name);
        });

        var batchString = batchNameArray.join(","); 
        var content = document.createElement('div');
        content.innerHTML = 'Are you sure you want to convert '+this.checkStudentArray.length +' scholars of </br> Batch '+'<strong>'+ batchString +'</strong> </br> to non-alumni?';


        var that = this;
        swal.fire({
            title: '',
            html:content,
            // text: "Are you sure you want to convert "+this.checkStudentArray.length + " students of batch "+batchString +"  to non-alumnus?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#428e63',
            confirmButtonText: 'Yes, convert it!'
        }).then((result) => {
            if(result.value){
                that.showLoader = true;                
                that.batchMasterService.convertToNonAlumn(json).subscribe(
                    data => {
                        that.showLoader = false;
                        if (data.status_code == 0) {
                            swal.fire(data['status_message']);
                            that.showConvertToAlumn = false;
                            that.showAlumnStudentView = false;
                            that.getAllBatches() ;
                        }
                        else {
                            swal.fire(data['status_message']);
                            // this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        that.showLoader = false;
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }
        });
      
    }

    showAlumnStudentModal(id_batch){
        this.isAlumnStudentModal = true;
        this.getAllBatchStudent(id_batch)
    }
    hideAlumnStudentModal(){
        this.isAlumnStudentModal = false;
    }

    batchStudents = [];
    getAllBatchStudent(id_batch)
    {
        this.batchMasterService.getAllbatchStudents(localStorage.getItem("email"),id_batch).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {
                    this.batchStudents = data['students'];    
                          
                }
                else {
                    swal.fire(data['status_message']);
                    // this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }

    clearSelection(){
        this.checkStudentArray = []; 
    }
    

}
