import { Component, OnInit } from '@angular/core';
import { BookMasterService } from '../book-master/book-master.service'
import swal from 'sweetalert2'
import { Router } from '@angular/router';

@Component({
  selector: 'app-book-issue-return',
  templateUrl: './book-issue-return.component.html',
  styleUrls: ['./book-issue-return.component.css'],
  providers: [BookMasterService]
})
export class BookIssueReturnComponent implements OnInit {
  isIssueBook = true;
  CoE = [];
  searchText: any;
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  showLoader = false;
  UnfilterdBookCopies = [];
  BookCopies = [];
  coeId = "0";
  isIssuedBookModal = false;
  bookIssueObject = {};
  Users = [];
  lostDamageFine: any;
  isCollect: any;
  isLostDamaged: any;
  isReturnBookModal = false;
  bookReurnObject = {};
  currentPage = 1
  copiesCount: any
  pageNumber = 0;
  accessionCode = ''
  isUploadCSVModal = false;
  fileInput: any;
  fileReaded: any;
  selectedBookCopies = [];
  selectedReturnBookCopies = [];
  isBulkIssuedBookModal = false;
  isBulkReturnBookModal = false;
  accessionCodeForBulk: any;
  bookCopiesForBulk = [];
  isBulkIssuedBookModalConfirm = false
  isBulkReturnBookModalConfirm = false;
  idRole = "";
  constructor(private bookMasterService: BookMasterService, private router: Router) { 
    if (localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "1" || localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "2") {
      this.idRole = localStorage.getItem("id_role");
      // this.coeId = localStorage.getItem("id_coe");
      this.getAllActiveCoe()
      this.getAllAvailableCopiesInLibrary()
      // this.getAllAllotedCopiesInLibrary()
      this.getUserForScholarByRoleId()
    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    // this.idRole = localStorage.getItem("id_role");
    // // this.coeId = localStorage.getItem("id_coe");
    // this.getAllActiveCoe()
    // this.getAllAvailableCopiesInLibrary()
    // // this.getAllAllotedCopiesInLibrary()
    // this.getUserForScholarByRoleId()
  }
  SetlostDamageFine() {

  }

  issueBook(value) {
    this.isIssueBook = value;
    this.accessionCode = ''
    this.selectedBookCopies = []
    this.selectedReturnBookCopies = [];
    if (this.isIssueBook) {
      this.currentPage = 1
      this.pageNumber = 0;
      this.copiesCount = 0;
      this.getAllAvailableCopiesInLibrary()
    }
    else {
      this.currentPage = 1
      this.pageNumber = 0;
      this.copiesCount = 0;
      this.getAllAllotedCopiesInLibrary()
    }
  }

  getAllActiveCoe() {
    this.showLoader = true;
    this.CoE = []
    this.bookMasterService.getAllActiveCoe(localStorage.getItem("email")).subscribe(
      data => {
        // this.showLoader = false;
        if (data.status_code == "0") {
          this.CoE = data.coes
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }

  getAllAvailableCopiesInLibrary() {
    this.showLoader = true;
    this.UnfilterdBookCopies = []
    this.BookCopies = [];
    this.bookMasterService.getAllAvailableCopiesInLibraryByPagination(localStorage.getItem("email"), this.pageNumber, this.accessionCode).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.UnfilterdBookCopies = data.book_copies
          if (this.pageNumber == 0) {
            this.copiesCount = data.copies_count
            this.currentPage = 1

          }
          this.filterCopies()
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllAllotedCopiesInLibrary() {
    this.showLoader = true;
    this.UnfilterdBookCopies = []
    this.BookCopies = [];
    this.bookMasterService.getAllAllotedCopiesInLibraryByPagination(localStorage.getItem("email"), this.pageNumber, this.accessionCode).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.UnfilterdBookCopies = data.book_copies
          if (this.pageNumber == 0) {
            this.copiesCount = data.copies_count
            this.currentPage = 1

          }
          this.filterCopies()
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  filterCopies() {
    this.BookCopies = this.UnfilterdBookCopies
    if (this.coeId != "0") {
      var temporaryArray = [];
      for (let data of this.BookCopies) {
        if (data.id_coe == this.coeId) {
          temporaryArray.push(data);
        }
      }
      this.BookCopies = temporaryArray;
    }
  }

  issueToScholar(data) {
    this.bookIssueObject = data;
    this.bookIssueObject["scholar_name"] = ''
    this.bookIssueObject["id_scholar"] = ''
    this.isIssuedBookModal = true;
  }
  closeModal() {

    this.isIssuedBookModal = false;
    this.isBulkIssuedBookModal = false;
    this.isBulkIssuedBookModalConfirm = false;
  }
  getUserForScholarByRoleId() {
    this.showLoader = true
    this.Users = [];

    this.bookMasterService.getUserByRoleId(localStorage.getItem("email"), "5").subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == 0) {
          this.Users = data["users"];
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }

      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }
  getScholarName() {
    for (let data of this.Users) {
      if (data.coe_roll_number == this.bookIssueObject["coe_roll_number"] || data.dakshana_roll_number == this.bookIssueObject["coe_roll_number"]) {
        this.bookIssueObject["scholar_name"] = data.name
        this.bookIssueObject["id_scholar"] = data.id_scholar
        break;
      }
      else {
        this.bookIssueObject["scholar_name"] = ''
        this.bookIssueObject["id_scholar"] = ''
        // swal.fire("This roll is not present.");
        // this.bookIssueObject["scholar_name"] = ""
        // this.bookIssueObject["id_scholar"] = ""
      }
    }

  }

  issueBookToScholar() {
    let checkRollNumber = false
    for (let data of this.Users) {
      if (data.coe_roll_number == this.bookIssueObject["coe_roll_number"] || data.dakshana_roll_number == this.bookIssueObject["coe_roll_number"]) {
        checkRollNumber = true;
        break;
      }

    }
    if (checkRollNumber) {
      this.UnfilterdBookCopies = []
      this.bookIssueObject["email"] = localStorage.getItem("email")
      swal.fire({
        title: '',
        text: "Are you sure you want to issue this book?.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#8B0000',
        cancelButtonColor: '#428e63',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.value) {
          this.showLoader = true;
          this.bookMasterService.issueBookToScholar(this.bookIssueObject).subscribe(
            data => {
              this.showLoader = false;
              if (data.status_code == "0") {
                this.getAllAvailableCopiesInLibrary()
                this.closeModal()
              }
              else {
                this.showAlertComponent(data['status_code'], data['status_message']);
              }
            },
            err => {
              this.showLoader = false;
              swal.fire("Something went wrong. Please try again later.")
            }
          )
        }
      })
    }
    else {
      swal.fire("This roll number is not present.")
    }

  }

  openBookReturnModal(data) {
    this.isReturnBookModal = true
    this.bookReurnObject = Object.assign({}, data);
  }
  closeBookReturnModal() {
    this.isReturnBookModal = false
    this.isBulkReturnBookModal = false;
    this.isBulkReturnBookModalConfirm = false;
  }

  collectFine() {
    if (this.bookReurnObject['book_status'] == '19' || this.bookReurnObject['book_status'] == '20') {
      this.bookReurnObject["fine"] = this.bookReurnObject["price"].trim()
      this.isLostDamaged = true
    }
    else {
      this.bookReurnObject['fine'] = ''
      this.bookReurnObject['comment'] = ''
      this.returnBookToLibrary()
    }

  }
  closeCollectFineModal() {
    this.isLostDamaged = false
  }



  returnBookToLibrary() {
    this.bookReurnObject["email"] = localStorage.getItem("email")
    // this.bookReurnObject["book_status"] = "18"
    this.bookMasterService.returnBookToLibrary(this.bookReurnObject).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.getAllAllotedCopiesInLibrary()
          this.closeBookReturnModal()
          this.closeCollectFineModal()
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  hideAlert(val) {
    this.showAlert = val;
  }

  nextPage() {
    this.pageNumber = this.pageNumber + this.UnfilterdBookCopies.length;
    if (this.isIssueBook) {
      this.getAllAvailableCopiesInLibrary()
    }
    else {
      this.getAllAllotedCopiesInLibrary()
    }
  }
  previousPage() {
    this.pageNumber = this.pageNumber - this.UnfilterdBookCopies.length;
    if (this.isIssueBook) {
      this.getAllAvailableCopiesInLibrary()
    }
    else {
      this.getAllAllotedCopiesInLibrary()
    }
  }

  pageChanged(event) {
    //(event)
    this.pageNumber = (event - 1) * 500
    this.currentPage = event
    if (this.isIssueBook) {
      this.getAllAvailableCopiesInLibrary()
    }
    else {
      this.getAllAllotedCopiesInLibrary()
    }
  }

  searchAccessionCode() {
    this.currentPage = 1
    this.pageNumber = 0;
    this.coeId = "0"
    this.searchText = ""
    if (this.isIssueBook) {
      this.getAllAvailableCopiesInLibrary()
    }
    else {
      this.getAllAllotedCopiesInLibrary()
    }
  }

  clearFilter() {
    this.currentPage = 1
    this.pageNumber = 0;
    this.coeId = "0"
    this.searchText = ""
    this.accessionCode = ""
    if (this.isIssueBook) {
      this.getAllAvailableCopiesInLibrary()
    }
    else {
      this.getAllAllotedCopiesInLibrary()
    }
  }
  csv2Array(fileInput: any) {
    this.fileInput = fileInput
  }

  uploadCSV() {
    if (!this.fileInput) {
      swal.fire("Please select file to import.");
    }

    else {
      this.fileReaded = this.fileInput.target.files[0];
      let reader: FileReader = new FileReader();
      reader.readAsText(this.fileReaded);
      let lines = [];
      reader.onload = (e) => {
        let csv: string = reader.result as string;
        let allTextLines = csv.split(/\r|\n|\r/);
        let headers = allTextLines[0].split(',');


        for (let i = 0; i < allTextLines.length; i++) {
          let data = allTextLines[i].split(',');
          if (data.length === headers.length) {
            let tarr = [];
            for (let j = 0; j < headers.length; j++) {
              tarr.push(data[j]);
            }
            lines.push(tarr);

          }
        }

        let json = {
          "caller_email": localStorage.getItem("email"),
          "excel": JSON.stringify(lines),

        }
        this.showLoader = true
        this.bookMasterService.csvUploadForBookIssue(json).subscribe(
          data => {
            this.showLoader = false
            if (data.status_code == "0") {
              this.getAllAvailableCopiesInLibrary()
              this.isUploadCSVModal = false
              swal.fire(data.status_message)
            }
            else {
              swal.fire(data.status_message)
            }
          },
          err => {
            this.showLoader = false
            swal.fire("Something went wrong, Please try again later.")

          }
        )

      }

    }
  }

  uploadLibraryCSV() {
    this.isUploadCSVModal = true
  }
  hideIssueBookModal() {
    this.isUploadCSVModal = false
  }


  isBookCopiesSelected(accessionCodeId) {
    let index = this.selectedBookCopies.findIndex(x => x.id_book_accession_code == accessionCodeId)
    if (index != -1) {
      return true
    }
    else {
      return false
    }

  }

  addRemovebookCopies(data) {
    let index = this.selectedBookCopies.findIndex(x => x.id_book_accession_code == data.id_book_accession_code)
    if (index == -1) {
      this.selectedBookCopies.push(data)
    }
    else {
      this.selectedBookCopies.splice(index, 1)
    }
  }

  addRemoveReturnbookCopies(data) {
    let index = this.selectedBookCopies.findIndex(x => x.id_book_accession_code == data.id_book_accession_code)
    if (index == -1) {
      data['fine'] = data.price
      data['comment'] = ""
      data['book_status'] = "17"
      this.selectedBookCopies.push(data)
    }
    else {
      this.selectedBookCopies.splice(index, 1)
    }
  }

  issueBulkBookToScholar() {
    if (this.selectedBookCopies.length == 0) {
      swal.fire('Please add book first.')
      return
    }
    else {
      let checkRollNumber = false
      for (let data of this.Users) {
        if (data.coe_roll_number == this.bookIssueObject["coe_roll_number"] || data.dakshana_roll_number == this.bookIssueObject["coe_roll_number"]) {
          checkRollNumber = true;
          break;
        }

      }
      if (checkRollNumber) {
        this.isBulkIssuedBookModalConfirm = true
      }
      else {
        swal.fire("This roll number is not present.")
      }



    }

  }

  issueBooks() {
    this.showLoader = true;
    this.UnfilterdBookCopies = []
    this.bookIssueObject["email"] = localStorage.getItem("email")
    this.bookIssueObject["copies"] = JSON.stringify(this.selectedBookCopies)
    this.bookMasterService.bulkIssueBookToScholar(this.bookIssueObject).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.selectedBookCopies = [];
          this.getAllAvailableCopiesInLibrary()
          this.closeModal()
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  bulkIssueissueToScholar() {
    this.bookIssueObject = {}
    this.isBulkIssuedBookModal = true;
    this.bookCopiesForBulk = [];
    this.selectedBookCopies = [];
    this.accessionCodeForBulk = '';

  }

  bulkReturnToScholar() {
    this.isBulkReturnBookModal = true;
    this.bookCopiesForBulk = [];
    this.selectedBookCopies = [];
    this.accessionCodeForBulk = '';

  }

  rerutrnBulkBook() {
    if (this.selectedBookCopies.length == 0) {
      swal.fire('Please add book first.')
      return
    }
    else {
      this.isBulkReturnBookModalConfirm = true;
      this.selectedBookCopies.sort(function (var1, var2) {
        const a = var1.id_scholar, b = var2.id_scholar;
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }

        return 0;
      });

    }

  }

  returnBooks() {
    this.showLoader = true;
    this.UnfilterdBookCopies = []
    this.bookIssueObject["email"] = localStorage.getItem("email")
    this.bookIssueObject["copies"] = JSON.stringify(this.selectedBookCopies)
    this.bookMasterService.rerutrnBulkBook(this.bookIssueObject).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.isBulkReturnBookModalConfirm = false;
          this.closeModal()
          this.getAllAllotedCopiesInLibrary()
          this.closeBookReturnModal()
          this.selectedReturnBookCopies = [];
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }
  getAvailableBookCopieDetails() {
    this.showLoader = true;

    this.bookMasterService.getAvailableBookCopieDetails(localStorage.getItem("email"), this.accessionCodeForBulk).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          if (data.book_copies.length > 0) {
            this.accessionCodeForBulk = ""
            let index = this.bookCopiesForBulk.findIndex(x => x.accession_code == data.book_copies[0].accession_code)
            if (index == -1) {
              this.bookCopiesForBulk.push(data.book_copies[0])
              this.selectedBookCopies.push(data.book_copies[0])
            }

          }

        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllotedBookCopieDetails() {
    this.showLoader = true;
    this.bookMasterService.getAllotedBookCopieDetails(localStorage.getItem("email"), this.accessionCodeForBulk).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          if (data.book_copies.length > 0) {

            this.accessionCodeForBulk = ""
            let tempObject = data.book_copies[0]
            let index = this.bookCopiesForBulk.findIndex(x => x.accession_code == tempObject.accession_code)
            if (index == -1) {
              tempObject['fine'] = tempObject.price
              tempObject['comment'] = ""
              tempObject['book_status'] = "17"
              this.selectedBookCopies.push(tempObject)
              this.bookCopiesForBulk.push(tempObject)
            }

          }

        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  closeBulkIssueConfirmModal() {
    this.isBulkIssuedBookModalConfirm = false;
    this.isBulkReturnBookModalConfirm = false;
  }
}
