import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class SectionSyllabusService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }

    getAllMasterData(email, sectionId) {
        return this.http.get(this.baseUrl + "/Section/getAllMasterDataForSection?caller_email=" + email + "&id_section=" + sectionId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getMasterSyllabus(email, courseTypeId, sectionId) {
        return this.http.get(this.baseUrl + "/Section/getAllSyllabusBySectionId?caller_email=" + email + "&id_course_type=" + courseTypeId + "&id_section=" + sectionId).map((res) => JSON.parse(JSON.stringify(res)));
    }


    addNewSectionSyllabus(jsonData) {
        //.log(JSON.stringify(jsonData));
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Section/addNewSectionSyllabus", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }


    getAllSectionSyllabus(email, sectionId) {
        return this.http.get(this.baseUrl + "/Section/getAllSectionSyllabus?caller_email=" + email + "&id_section=" + sectionId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    changeSectionSyllabusTopic(email, sectionSyllabusId, topicId, facultyId, topicStatusId, sectionId) {
        return this.http.get(this.baseUrl + "/Section/changeSectionSyllabusTopic?caller_email=" + email + "&id_section_syllabus=" + sectionSyllabusId + "&id_topic=" + topicId + "&id_faculty=" + facultyId + "&id_topic_status=" + topicStatusId + "&id_section=" + sectionId).map((res) => JSON.parse(JSON.stringify(res)));
    }

}