import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { InstituteMasterService } from "./institute-master.service";
import { CSVExport } from "../Csv-export";
import { Helper } from "../helper";
import swal from "sweetalert2";
@Component({
  moduleId: module.id,
  selector: "institute-master",
  templateUrl: "institute-master.component.html",
  styleUrls: ["institute-master.component.scss"],
  providers: [Helper, InstituteMasterService]
})
export class InstituteMasterComponent {
  isAddModal: boolean;
  fileInput: any;
  fileReaded: any;
  Courses: any;
  courseTypeId: any;
  Years: any;
  selectedYears: any;
  isCurrent: any;
  showLoader: boolean;
  Institutes: any;
  InstituteRanks: any;
  instituteId: any;
  searchText: any;
  column: string = "CategoryName";
  direction: number;
  isDesc: boolean = false;

  institure_master_neet_url = Helper.url + "/assets/Institute_Master_for_NEET.csv";
  institure_master_iit_url = Helper.url + "/assets/Institute_Master_for_IIT.csv";
  institure_master_nit_url = Helper.url + "/assets/Institute_Master_for_NIT.csv";


  constructor(
    private helper: Helper,
    private router: Router,
    private instituteMasterService: InstituteMasterService
  ) {
    this.isAddModal = false;
    this.courseTypeId = "2";
    this.Years = this.helper.getYears();
    this.Courses = [];
    this.InstituteRanks = [];
    this.Institutes = [];
    var curentDate = new Date();
    this.selectedYears = curentDate.getUTCFullYear();
    if (localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "1") {
      this.getAllCourses();
    } else {
      this.router.navigateByUrl("/");
    }
  }
  getAllCourses() {
    this.showLoader = true;
    this.instituteMasterService
      .getAllCourses(localStorage.getItem("email"))
      .subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.Courses = data.courses;
            this.getAllInstituteRanksMaster();
          }
        },
        err => {
          this.showLoader = false;
        }
      );
  }
  clearData() {
    this.isCurrent = false;
  }
  getAllInstituteRanksMaster() {
    this.showLoader = true;
    this.instituteMasterService
      .getAllInstituteRanksMaster(localStorage.getItem("email"))
      .subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.Institutes = data.institutes;
            this.selectCurrent();
          }
        },
        err => {
          this.showLoader = false;
        }
      );
  }

  selectCurrent() {
    this.InstituteRanks = [];
    if (this.Institutes.length > 0) {
      for (let data of this.Institutes) {
        if (
          data.is_current == "1" &&
          data.id_course_type == this.courseTypeId
        ) {
          this.instituteId = data.id_institute;
          this.InstituteRanks = data.institutes_ranks;
          this.selectedYears = data.year;
          if (data.is_current == "1") {
            this.isCurrent = true;
          } else {
            this.isCurrent = false;
          }
          break;
        }
      }
    }
  }
  selectYear() {
    this.InstituteRanks = [];
    if (this.Institutes.length > 0) {
      for (let data of this.Institutes) {
        if (
          data.year == this.selectedYears &&
          data.id_course_type == this.courseTypeId
        ) {
          this.instituteId = data.id_institute;
          this.InstituteRanks = data.institutes_ranks;
          if (data.is_current == "1") {
            this.isCurrent = true;
          } else {
            this.isCurrent = false;
          }
          break;
        }
      }
    }
  }
  selectCourse(data) {
    this.courseTypeId = data.id_course_type;
    this.selectCurrent();
  }
  hideAddEditModal() {
    this.isAddModal = false;
    //this.cleanData()
  }
  isButtonActive(courseTypeId) {
    if (this.courseTypeId == courseTypeId) return true;
    else return false;
  }
  addNew() {
    this.isAddModal = true;
  }
  csv2Array(fileInput: any) {
    this.fileInput = fileInput;
  }

  save() {
    if (!this.fileInput) {
      swal.fire("Please select file to import.");
    } else if (!this.courseTypeId || this.courseTypeId == "0") {
      swal.fire("Please select course.");
    } else if (!this.selectedYears || this.selectedYears == "0") {
      swal.fire("Please select year.");
    } else {
      this.fileReaded = this.fileInput.target.files[0];
      let reader: FileReader = new FileReader();
      reader.readAsText(this.fileReaded);
      let lines = [];
      reader.onload = e => {
        let csv: string = reader.result as string;
        let allTextLines = csv.split(/\r|\n|\r/);
        let headers = allTextLines[0].split(",");

        for (let i = 0; i < allTextLines.length; i++) {
          // split content based on comma
          let data = allTextLines[i].split(",");
          if (data.length === headers.length) {
            let tarr = [];
            for (let j = 0; j < headers.length; j++) {
              tarr.push(data[j]);
            }

            // log each row to see output
            lines.push(tarr);
          }
        }
        //(lines);
        var currentValue: any;
        if (this.isCurrent == true) {
          currentValue = 1;
        } else {
          currentValue = 0;
        }
        let json = {
          caller_email: localStorage.getItem("email"),
          id_course_type: this.courseTypeId,
          year: this.selectedYears,
          excel: JSON.stringify(lines),
          is_current: currentValue
        };
        this.showLoader = true;
        if (this.courseTypeId == "4") {
          this.instituteMasterService.saveRankExcel(json).subscribe(
            data => {
              this.showLoader = false;
              if (data.status_code == "0") {
                this.getAllInstituteRanksMaster();
                this.hideAddEditModal();
              } else {
                swal.fire(data.status_message);
              }
            },
            err => {
              this.showLoader = false;
              swal.fire("Something went wrong, Please try again later.");
              this.hideAddEditModal();
            }
          );
        } else {
          this.instituteMasterService
            .saveInstituteExcelForExceptNIT(json)
            .subscribe(
              data => {
                this.showLoader = false;
                if (data.status_code == "0") {
                  this.getAllInstituteRanksMaster();
                  this.hideAddEditModal();
                } else {
                  swal.fire(data.status_message);
                }
              },
              err => {
                this.showLoader = false;
                swal.fire("Something went wrong, Please try again later.");
                this.hideAddEditModal();
              }
            );
        }
      };
    }
  }

  setCurrent() {
    if (this.InstituteRanks.length > 0) {
      var value: any;
      if ((this.isCurrent = true)) {
        value = "1";
      } else {
        value = "0";
      }
      this.showLoader = true;
      this.instituteMasterService
        .setCurrent(
          localStorage.getItem("email"),
          this.selectedYears,
          this.courseTypeId,
          value,
          this.instituteId
        )
        .subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == "0") {
              if (data.status_code == "0") {
                this.Institutes = data.institutes;
                this.selectYear();
              }
            } else {
              swal.fire(data.status_message);
            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong, Please try again later.");
            this.hideAddEditModal();
          }
        );
    } else {
      swal.fire("No records found.");
    }
  }

  export() {
    var arrayToExport = [];
    if (this.InstituteRanks.length > 0) {
      for (let data of this.InstituteRanks) {
        if (this.courseTypeId != "2") {
          let json = {
            "Institute Name": data.institute_name,
            "Institute Code": data.institute_code,
            State: data.state,
            "Program Name": data.program_name,
            "Program Code": data.program_code,
            Quota: data.quota_type,
            "GEN Open": data.gen_open_rank,
            "GEN Close": data.gen_close_rank,
            "GEN-EWS Open": data.gen_ews_open_rank,
            "GEN-EWS Close": data.gen_ews_close_rank,
            "OBC-NCL Open": data.obc_ncl_open_rank,
            "OBC-NCL Close": data.obc_ncl_close_rank,
            "SC Open": data.sc_open_rank,
            "SC Close": data.sc_close_rank,
            "ST Open": data.st_open_rank,
            "ST Close": data.st_close_rank,
            "GEN-PD Open": data.gen_pd_open_rank,
            "GEN-PD Close": data.gen_pd_close_rank,
            "OBC-NCL-PD Open": data.obc_ncl_pd_open_rank,
            "OBC-NCL-PD Close": data.obc_ncl_pd_close_rank,
            "SC-PD Open": data.sc_pd_open_rank,
            "SC-PD Close": data.sc_pd_close_rank,
            "ST-PD Open": data.st_pd_close_rank,
            "ST-PD Close": data.st_close_rank
          };

          arrayToExport.push(json);
        } else {
          let json = {
            "Institute Name": data.institute_name,
            "Institute Code": data.institute_code,
            State: data.state,
            "University Name": data.program_name,
            Quota: data.quota_type,
            "GEN Open": data.gen_open_rank,
            "GEN-EWS Close": data.gen_close_rank,
            "GEN-EWS Open": data.gen_ews_open_rank,
            "GEN Close": data.gen_ews_close_rank,
            "OBC-NCL Open": data.obc_ncl_open_rank,
            "OBC-NCL Close": data.obc_ncl_close_rank,
            "SC Open": data.sc_open_rank,
            "SC Close": data.sc_close_rank,
            "ST Open": data.st_open_rank,
            "ST Close": data.st_close_rank,
            "GEN-PD Open": data.gen_pd_open_rank,
            "GEN-PD Close": data.gen_pd_close_rank,
            "OBC-NCL-PD Open": data.obc_ncl_pd_open_rank,
            "OBC-NCL-PD Close": data.obc_ncl_pd_close_rank,
            "SC-PD Open": data.sc_pd_open_rank,
            "SC-PD Close": data.sc_pd_close_rank,
            "ST-PD Open": data.st_pd_close_rank,
            "ST-PD Close": data.st_close_rank
          };

          arrayToExport.push(json);
        }
      }
      CSVExport.downloadcsv(arrayToExport, "Institute Master ");
    }
  }
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.InstituteRanks.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      } else if (a[property] > b[property]) {
        return 1 * direction;
      } else {
        return 0;
      }
    });
  }
}
