import { Component, OnInit } from '@angular/core';
import { CoordinatoDashBoardService } from './coordinator-dashboard.service'
import { Router } from '@angular/router'
import { Helper } from '../helper'
import { DatePipe } from '@angular/common'
import swal from 'sweetalert2'
@Component({
  selector: 'app-coordinator-dasboard',
  templateUrl: './coordinator-dasboard.component.html',
  styleUrls: ['./coordinator-dasboard.component.css'],
  providers: [CoordinatoDashBoardService]
})
export class CoordinatorDasboardComponent implements OnInit {
  Schedules = []
  Coes = []
  coeId = "0"
  isComplitionChange = false
  topicName: any;
  lectureStartTime: any;
  lectureEndTime: any;
  lectureDate: any;
  scheduletopicId: any;
  sectionId: any;
  sheduleId: any;
  topicId: any;
  ClassTypes = [];
  classTypeId = "1";
  isTopicCompleted = false;
  showLoader = false;
  isComplitionChangeLectureDeviation = false;
  lectureDeviationReason = "0";
  isAddOtherLecture = false;
  lectureFacultyName: any;
  lectureTopicName: any;
  Faculty = []
  facultyId = "0"
  TempTopics = []
  TempScheduleTopics = []
  ScheduleTopics = []
  otherLectureScheduleId = "0"
  otherLectureScheduleDate: any;
  otherLectureScheduleStartTime: any;
  otherLectureScheduleEndTime: any;
  alertDiv = false;
  sucessAlertMessage: any;
  Attendance = [];
  CoeAttendance = [];
  public attendancebarChartLabels: String[];
  public attendancebarChartType: string;
  public attendancebarChartLegend = false;
  public attendancebarChartData: any[]
  public coeattendancebarChartLabels: String[];
  public coeattendancebarChartType: string;
  public coeattendancebarChartLegend = false;
  public coeattendancebarChartData: any[];
  CoeSyllabus = [];
  public coesyllabusbarChartLabels: String[];
  public coesyllabusbarChartType: string;
  public coesyllabusbarChartLegend = false;
  public coesyllabusbarChartData: any[];
  SubjectGraph = [];
  Subjects = [];
  coeName: any
  CoeLectures = [];
  public lecturebarChartLabels: String[];
  public lecturebarChartType: string;
  public lecturebarChartLegend = false;
  public lecturebarChartData: any[];
  todayDate: any;
  lectureStatus: any;
  constructor(private router: Router, private coordinatoDashBoardService: CoordinatoDashBoardService) {
    if (localStorage.getItem("isLogin") == "true") {
      this.getTodayAllSchedule();
    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  getTodayAllSchedule() {
    var date = new Date();
    this.showLoader = true
    var todayDate = date.toISOString().split('T')[0];
    this.todayDate = todayDate
    this.coordinatoDashBoardService.getTodaySectionSchedule(localStorage.getItem("email"), todayDate, localStorage.getItem("id_user")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.Schedules = data.schedule
          this.Coes = data.coe_response;
          this.getGraphdata();
        }
        else {
          this.showAlertDiv(data.status_message)
        }
      },
      err => {

      }
    )
  }

  getGraphdata() {
    var date = new Date();
    this.showLoader = true
    var todayDate = date.toISOString().split('T')[0];
    this.coordinatoDashBoardService.getTodayGraphData(localStorage.getItem("email"), todayDate, localStorage.getItem("id_user")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.Attendance = data.attendance
          this.CoeAttendance = data.coe_attaendance
          this.CoeSyllabus = data.coe_syllabus
          this.generatAttendanceGraph();
          this.generatCoeAttendanceGraph();
          this.generatCoeSyllabusGraph();
          this.coeId = this.Coes[0].id_coe
          this.getCoeName();
          this.getSubjectsCovered();
          this.getLectureCovered();
        }
        else {
          this.showAlertDiv(data.status_message)
        }
      },
      err => {
        this.showLoader = false
      }
    )
  }


  getSubjectsCovered() {

    var date = new Date();
    this.showLoader = true
    var todayDate = date.toISOString().split('T')[0];
    this.coordinatoDashBoardService.getTodaySubjectGraph(localStorage.getItem("email"), todayDate, this.coeId, localStorage.getItem("id_user")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.Subjects = data.coe_subject
          this.generatSubjectGraph();
          this.getLectureCovered();
        }
        else {
          this.showAlertDiv(data.status_message)
          this.getLectureCovered();
        }
      },
      err => {
        this.showLoader = false
      }
    )
  }
  openCompletionModal(data, schedule) {
    this.isTopicCompleted = false;
    this.isComplitionChange = true;
    this.topicName = data.topic_name;
    this.lectureStartTime = data.start_time;
    this.lectureEndTime = data.end_time;
    this.lectureDate = data.class_date;
    this.scheduletopicId = data.id_topic;
    this.sectionId = data.id_section
    this.sheduleId = data.id_schedule
    this.topicId = data.id_topic
    this.ClassTypes = schedule.class_type
  }

  hideAddEditModal() {
    this.isComplitionChange = false;
    this.isComplitionChangeLectureDeviation = false
    this.isAddOtherLecture = false;

  }
  saveComplitionChange() {
    if (this.classTypeId == "0") {
      swal.fire("Please select class type.")
    }
    else {
      this.showLoader = true;
      var idTopicStatus = "1";
      if (this.isTopicCompleted) {
        idTopicStatus = "3";
      }
      else {
        idTopicStatus = "1";
      }
      let json = {
        "caller_email": localStorage.getItem("email"),
        "id_schedule": this.sheduleId,
        "id_section": this.sectionId,
        "id_topic": this.topicId,
        "start_time": this.lectureStartTime,
        "end_time": this.lectureEndTime,
        "date": this.lectureDate,
        "id_lecture_type": this.classTypeId,
        "is_topic_completed": this.isTopicCompleted,
        "id_status": "8",
        "completion_comment": "On Time"
      }
      this.coordinatoDashBoardService.saveComplitionChange(json).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.getTodayAllSchedule();
            this.hideAddEditModal()
          }
          else {
            swal.fire(data.status_message)
          }
        },
        err => {
          this.showLoader = false;
        }
      )
    }
  }

  openCompletionModalForLectureDeviation(data, schedule) {
    this.isComplitionChangeLectureDeviation = true;
    this.topicName = data.topic_name;
    this.lectureStartTime = data.start_time;
    this.lectureEndTime = data.end_time;
    this.lectureDate = data.class_date;
    this.scheduletopicId = data.id_topic;
    this.sectionId = data.id_section
    this.sheduleId = data.id_schedule
    this.topicId = data.id_topic
    this.ClassTypes = schedule.class_type
  }

  saveComplitionChangeForLectureDevation() {
    if (this.classTypeId == "0") {
      swal.fire("Please select class type.")
    }
    else if (!this.lectureStartTime) {
      swal.fire("Please select start time.")
    }
    else if (!this.lectureEndTime) {
      swal.fire("Please select end time.")
    }
    else if (this.timeValidation(this.lectureStartTime, this.lectureEndTime) == false) {
      swal.fire("Time schedule is not correct.")
    }
    else if (!this.lectureDate) {
      swal.fire("Please select date.")
    }
    else {
      this.showLoader = true;
      var idTopicStatus = "1";
      if (this.isTopicCompleted) {
        idTopicStatus = "3";
      }
      else {
        idTopicStatus = "1";
      }
      var statusId = "9"
      if (this.lectureDeviationReason == "Lecture not required") {
        statusId = "10"
      }
      let json = {
        "caller_email": localStorage.getItem("email"),
        "id_schedule": this.sheduleId,
        "id_section": this.sectionId,
        "id_topic": this.topicId,
        "start_time": this.lectureStartTime,
        "end_time": this.lectureEndTime,
        "date": this.lectureDate,
        "id_lecture_type": this.classTypeId,
        "is_topic_completed": this.isTopicCompleted,
        "id_status": statusId,
        "completion_comment": this.lectureDeviationReason
      }
      this.showLoader = true;
      this.coordinatoDashBoardService.saveComplitionChange(json).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.getTodayAllSchedule();
            this.hideAddEditModal()
          }
          else {
            swal.fire(data.status_message)
          }
        },
        err => {
          this.showLoader = false;
        }
      )
    }
  }


  addOtherLecture(data, schedule) {
    this.isAddOtherLecture = true;
    this.sheduleId = data.id_schedule;
    this.lectureDate = data.class_date;
    this.lectureStartTime = data.start_time;
    this.lectureEndTime = data.end_time
    this.lectureFacultyName = data.faculty_name;
    this.lectureTopicName = data.topic_name;
    this.Faculty = schedule.faculty;
    this.TempTopics = schedule.faculty_topic;
    this.lectureStatus = data.id_status;
    this.sectionId = data.id_section
    this.TempScheduleTopics = schedule.schedule_topic
  }

  getScheduleTopics() {
    this.ScheduleTopics = []
    for (let data of this.TempScheduleTopics) {
      if (data.id_faculty == this.facultyId) {
        this.ScheduleTopics.push(data)
      }
    }
  }

  getScheduleDetail() {
    for (let data of this.ScheduleTopics) {
      if (this.otherLectureScheduleId == data.id_schedule) {
        this.otherLectureScheduleDate = data.date;
        this.otherLectureScheduleStartTime = data.start_time;
        this.otherLectureScheduleEndTime = data.end_time
      }
    }
  }

  saveOtherLecture() {
    if (this.facultyId == "0") {
      swal.fire("Please select faculty.")
    }
    else if (this.otherLectureScheduleId == "0") {
      swal.fire("Please select topic.")
    }
    else {
      let json = {
        "caller_email": localStorage.getItem("email"),
        "id_section": this.sectionId,
        "id_schedule": this.sheduleId,
        "lecture_date": this.otherLectureScheduleDate + " " + this.otherLectureScheduleStartTime + " to " + this.otherLectureScheduleEndTime,
        "id_status": this.lectureStatus,
        "id_other_schedule": this.otherLectureScheduleId
      }
      this.showLoader = true;
      this.coordinatoDashBoardService.addOtherLecture(json).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.getTodayAllSchedule();
            this.hideAddEditModal()
          }
          else {
            this.showAlertDiv(data.status_message)
          }
        },
        err => {
          this.showLoader = false;
        }
      )
    }
  }

  timeValidation(startTime, endTime) {
    var dateOne = new Date("12-25-2012 " + startTime);
    var dateSecond = new Date("12-25-2012 " + endTime);
    if (dateOne.getTime() > dateSecond.getTime()) {
      return false
    }
    else {
      return true
    }
  }


  showAlertDiv(message) {
    var that = this
    that.alertDiv = true
    this.sucessAlertMessage = message
    setTimeout(function () {
      that.alertDiv = false
    }, 5000);
  }


  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          min: 0,
          max: 100,
          stepSize: 10
        }
      }]
    }
  };


  generatAttendanceGraph() {
    this.attendancebarChartType = 'bar';
    this.attendancebarChartLegend = true;
    this.attendancebarChartLabels = [];
    this.attendancebarChartData = []
    for (let data of this.Attendance) {
      this.attendancebarChartLabels.push(data.section_name)
    }
    var classData = []
    for (let data of this.Attendance) {
      classData.push(parseInt(data.class_attendence))
    }
    var hostelData = []
    for (let data of this.Attendance) {
      hostelData.push(parseInt(data.hostel_attendence))
    }
    this.attendancebarChartData = [
      { data: classData, label: 'Class' },
      { data: hostelData, label: 'Hostel' }
    ];
  }


  generatCoeAttendanceGraph() {
    //attendance  graph
    this.coeattendancebarChartType = 'bar';
    this.coeattendancebarChartLegend = true;
    this.coeattendancebarChartLabels = [];
    this.coeattendancebarChartData = []
    for (let data of this.CoeAttendance) {
      this.coeattendancebarChartLabels.push(data.coe_name)
    }
    var classData = []
    for (let data of this.CoeAttendance) {
      classData.push(parseInt(data.class_attendence))
    }
    var hostelData = []
    for (let data of this.CoeAttendance) {
      hostelData.push(parseInt(data.hostel_attendence))
    }
    this.coeattendancebarChartData = [
      { data: classData, label: 'Class' },
      { data: hostelData, label: 'Hostel' }
    ];
  }

  generatCoeSyllabusGraph() {
    //attendance  graph
    this.coesyllabusbarChartType = 'bar';
    this.coesyllabusbarChartLegend = true;
    this.coesyllabusbarChartLabels = [];
    this.coesyllabusbarChartData = []
    for (let data of this.CoeSyllabus) {
      this.coesyllabusbarChartLabels.push(data.coe_name)
    }
    var completedData = []
    for (let data of this.CoeSyllabus) {
      completedData.push(parseInt(data.completed_syllabus))
    }
    var uncompletedData = []
    for (let data of this.CoeSyllabus) {
      uncompletedData.push(parseInt(data.not_completed_syllabus))
    }
    this.coesyllabusbarChartData = [
      { data: completedData, label: 'Covered' },
      { data: uncompletedData, label: 'Not-covered' }
    ];
  }

  generatSubjectGraph() {
    this.SubjectGraph = [];
    for (let data of this.Subjects) {
      if (data.subject_name != null) {
        //subject graph
        var pieChartLabels: string[] = ['Covered', 'Not-covered'];
        var pieChartData: number[] = [(data.completed).toFixed(2), (data.not_completed).toFixed(2)];
        let json = {
          "subject_name": data.subject_name,
          "pieChartType": 'pie',
          "pieChartLabels": pieChartLabels,
          "pieChartData": pieChartData
        }

        this.SubjectGraph.push(json)
      }

    }

  }

  openAttendance() {
    this.router.navigateByUrl('/Attendance')
  }

  getCoeName() {
    for (let data of this.Coes) {
      if (data.id_coe == this.coeId) {
        this.coeName = data.name
        break;
      }
    }
  }
  getLectureCovered() {
    this.getCoeName();
    var date = new Date();
    var todayDate = date.toISOString().split('T')[0];
    this.coordinatoDashBoardService.getTodayLectureGraph(localStorage.getItem("email"), todayDate, this.coeId, localStorage.getItem("id_user"), this.coeName).subscribe(
      data => {
        if (data.status_code == "0") {
          this.CoeLectures = data.coe_lecture
          this.generatCoeLectureGraph();
        }
        else {
          this.showAlertDiv(data.status_message)
        }
      },
      err => {

      }
    )
  }

  generatCoeLectureGraph() {
    this.lecturebarChartType = 'bar';
    this.lecturebarChartLegend = true;
    this.lecturebarChartLabels = [];
    this.lecturebarChartData = [];
    for (let data of this.CoeLectures) {
      this.lecturebarChartLabels.push(data.coe_name)
    }
    var completed = []
    for (let data of this.CoeLectures) {
      completed.push(parseInt(data.completed))
    }
    var notCompleted = []
    for (let data of this.CoeLectures) {
      notCompleted.push(parseInt(data.not_completed))
    }
    this.lecturebarChartData = [
      { data: completed, label: 'completed' },
      { data: notCompleted, label: 'not-completed' }
    ];
  }
}
