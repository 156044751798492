import { Component, OnInit } from '@angular/core';
import { BookMasterService } from '../book-master/book-master.service'
import { CSVExport } from '../Csv-export';
import swal from 'sweetalert2'
import { Helper } from '../helper';
@Component({
  selector: 'book-quantities',
  templateUrl: './book-quantities.component.html',
  styleUrls: ['./book-quantities.component.css'],
  providers: [BookMasterService, Helper]
})
export class BookQuantitiesComponent implements OnInit {
  isBookQunatity = true;
  bookQunatityObject: any = { 'id_coe': '', 'id_book_type': '', 'id_book_title': '', 'year_of_puchase': '', 'id_book_edition': '', 'is_print_accession_code': false }
  searchText: any;
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  isAddModal = false;
  showLoader = false;
  BookTitles = [];
  BookTypes = []
  isAdd = 0;
  purpose = "Add"
  selectedBookTitleId: any
  CoE = [];
  BookEditions = [];
  yearsArray = []
  FilterdBookTitles = [];
  BookQuantity = [];
  BookQuantityAccessionCode = []
  isAccessionCodeModal = false;
  constructor(private bookMasterService: BookMasterService, private helper: Helper) {

  }
  ngOnInit() {
    this.yearsArray = this.helper.getArrayOfYears()
    this.getAllBookQuantity()
    this.getAllActiveCoe()
    this.getAllBookTitle()
    this.getAllBookType()

  }

  getAllActiveCoe() {
    this.showLoader = true;
    this.CoE = []
    this.bookMasterService.getAllActiveCoe(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.CoE = data.coes
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }
  getAllBookType() {
    this.showLoader = true;
    this.BookTypes = []
    this.bookMasterService.getAllBookType(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.BookTypes = data.book_types
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllBookTitle() {
    this.showLoader = true;
    this.BookTitles = []
    this.bookMasterService.getAllBookTitle(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.BookTitles = data.book_titles
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }


  getAllBookEditionByBookTitle() {
    this.showLoader = true;
    this.BookEditions = []
    this.bookMasterService.getAllBookEditionByBookTitle(localStorage.getItem("email"), this.bookQunatityObject['id_book_title']).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.BookEditions = data.book_editions
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllBookQuantity() {
    this.showLoader = true;
    this.BookQuantity = []
    this.bookMasterService.getAllBookQuantity(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.BookQuantity = data.book_quantities
          this.bookQunatityObject['is_print_accession_code']
          if (this.bookQunatityObject['is_print_accession_code'] == true) {
            this.getBookQuantityAccessionCode(this.bookQunatityObject['id_book_quantity'])
          }
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getBookQuantityAccessionCode(bookQuantityId) {
    this.showLoader = true;
    this.BookQuantityAccessionCode = []
    this.bookMasterService.getBookQuantityAccessionCode(localStorage.getItem("email"), bookQuantityId).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.isAccessionCodeModal = true
          this.BookQuantityAccessionCode = data.book_qaccession_code
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }


  addBookQuantity() {
    this.bookQunatityObject = { 'id_coe': '', 'id_book_type': '', 'id_book_title': '', 'year_of_puchase': '', 'id_book_edition': '', 'is_print_accession_code': false }

    this.isAddModal = true
    this.isAdd = 0
    // this.bookQunatityObject = { 'is_print_accession_code': false }
  }

  editBookQuantity(data) {
    this.bookQunatityObject = { 'id_coe': '', 'id_book_type': '', 'id_book_title': '', 'year_of_puchase': '', 'id_book_edition': '', 'is_print_accession_code': false }

    this.purpose = "Edit"
    this.isAddModal = true
    this.isAdd = 1
    this.bookQunatityObject = Object.assign({}, data);
    //(this.bookQunatityObject)
    this.FilterdBookTitles = []

    for (let data of this.BookTitles) {
      if (data['id_book_type'] == this.bookQunatityObject['id_book_type']) {
        this.FilterdBookTitles.push(data)
      }
    }
    // this.bookQunatityObject.id_book_title = ''
    // this.bookQunatityObject.author = ''
    // this.bookQunatityObject.publisher = ''
    //(this.bookQunatityObject.id_book_title)
    // this.reflectBookTypeRelatedDataTofield()
    this.getAllBookEditionByBookTitle()

  }

  save() {
    this.bookQunatityObject['email'] = localStorage.getItem("email")
    if (this.isAdd == 0) {
      //(this.bookQunatityObject)
      this.bookMasterService.addBookQuantity(this.bookQunatityObject).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.closeModal();
            this.bookQunatityObject['id_book_quantity'] = data.id_book_quantity
            this.getAllBookQuantity()

          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }
    else {
      this.bookMasterService.editBookQuantity(this.bookQunatityObject).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.closeModal();
            this.getAllBookQuantity()
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }

  }
  closeModal() {
    this.isAddModal = false
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }

  hideAlert(val) {
    this.showAlert = val;
  }

  removeBookQuantity(data) {
    swal.fire({
      title: '',
      text: "Deleting an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to remove this Quantity?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        this.bookMasterService.removeBookQuantity(localStorage.getItem("email"), data.id_book_quantity).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.getAllBookQuantity()
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);

            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
    })
  }
  manageEdition(bookTitleId) {
    this.selectedBookTitleId = bookTitleId
    this.isBookQunatity = false;
  }
  closeBookEdition() {
    this.isBookQunatity = true;
  }

  reflectEditionRelatedDataTofield() {
    let index = this.BookEditions.findIndex(x => x.id_book_edition == this.bookQunatityObject['id_book_edition'])
    if (index != -1) {
      this.bookQunatityObject['isbn_code'] = this.BookEditions[index]['isbn_code']
      this.bookQunatityObject['price'] = this.BookEditions[index]['price']
    }
    //(this.bookQunatityObject)

  }
  reflectBookTypeRelatedDataTofield() {
    this.FilterdBookTitles = []

    for (let data of this.BookTitles) {
      if (data['id_book_type'] == this.bookQunatityObject['id_book_type']) {
        this.FilterdBookTitles.push(data)
      }
    }
    this.bookQunatityObject.id_book_title = ''
    this.bookQunatityObject.author = ''
    this.bookQunatityObject.publisher = ''
    //(this.bookQunatityObject.id_book_title)
  }

  reflectBookTitleRelatedDataTofield() {
    let index = this.BookTitles.findIndex(x => x.id_book_title == this.bookQunatityObject['id_book_title'])
    if (index != -1) {
      this.bookQunatityObject['author'] = this.BookTitles[index]['author']
      this.bookQunatityObject['publisher'] = this.BookTitles[index]['publisher']
    }
    this.getAllBookEditionByBookTitle()

  }

  hideAccessionPrintModal() {
    this.isAccessionCodeModal = false
    this.bookQunatityObject = { 'is_print_accession_code': false }
  }

  printAccessionCode(printSectionId) {
    let printContents, popupWin;
    printContents = document.getElementById(printSectionId).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          .text-center{
            text-align:center !important;
           }
           .mb-0{
             margin-bottom:0px!important;
           } 
           .pb-0{
             padding-bottom:0px!important;
           } 
           
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  export() {
    let bookTilte = ''
    var arrayToExport = [];
    if (this.BookQuantity.length > 0) {
      for (let data of this.BookQuantity) {
        bookTilte = data.book_title
        let json = {
          "CoE": data.coe_name,
          "Book Type": data.book_type_name,
          "Book Title": data.book_title_name,
          "Publisher": data.publisher,
          "Author": data.author,
          "Edition": data.book_edition_name,
          "Price": data.price,
          "ISBN Code": data.isbn_code,
          "Quantity": data.quantity,
          "Year Of Purchse": data.year_of_puchase,

        }

        arrayToExport.push(json);


      }
      CSVExport.downloadcsv(arrayToExport, " Book Quantities");
    }
  }
}

