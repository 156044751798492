import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent implements OnInit {
  @Input() barChartLabels = [];
  @Input() barChartData = [];
  barChartLegend = true
  constructor() { }

  ngOnInit() {
  }

  public barChartType: string = 'bar';
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          min: 0,
          max: 100,
          stepSize: 10
        }
      }],
      xAxes: [{
        ticks: {
          stepSize: 1,
          min: 0,
          autoSkip: false
        }
      }]
    }
  };
  public chartColors: Array<any> = [
    { // blue
      backgroundColor: 'rgba(123,104,238)',
      borderColor: 'rgba(123,104,238)',
      pointBackgroundColor: 'rgba(123,104,238)',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(123,104,238)'
    },
    { // purple
      backgroundColor: 'rgb(157, 68, 122)',
      borderColor: 'rgb(157, 68, 122)',
      pointBackgroundColor: 'rgb(157, 68, 122)',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(157, 68, 122)'
    },
    { // green
      backgroundColor: 'rgb(66, 142, 99)',
      borderColor: 'rgb(66, 142, 99)',
      pointBackgroundColor: 'rgb(66, 142, 99)',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(66, 142, 99)'
    },

    { // orange
      backgroundColor: 'rgb(254, 119, 123)',
      borderColor: 'rgb(254, 119, 123)',
      pointBackgroundColor: 'rgb(254, 119, 123)',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(254, 119, 123)'
    }
    ,


    { // orange
      backgroundColor: 'rgb(201, 58, 244)',
      borderColor: 'rgb(201, 58, 244)',
      pointBackgroundColor: 'rgb(201, 58, 244)',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(201, 58, 244)'
    }


  ];

  public chartOptions: any = {
    responsive: true
  };
  public chartClicked(e: any): void {
    //.log(e);
  }

  public chartHovered(e: any): void {
    //.log(e);
  }
}
