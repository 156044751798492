import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { ExamResultServiceService } from './online-exam-result-details.service'
import { DomSanitizer } from '@angular/platform-browser';
import swal from 'sweetalert2'
import { Route } from '@angular/compiler/src/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { ChartsModule } from 'ng2-charts';

@Component({
  selector: 'app-online-exam-result-details',
  templateUrl: './online-exam-result-details.component.html',
  styleUrls: ['./online-exam-result-details.component.css'],
  providers: [ExamResultServiceService]
})
export class OnlineExamResultDetailsComponent implements OnInit {
  examTemplateId: any;
  showLoader = false;
  Results = [];
  UnfilteredResult = [];
  examMarks: any;
  examName: any;
  totalQuestion = 0;
  totalAnsweredQuestion = 0;
  totalNotAnsweredQuestion = 0;

  totalCorrectAnswers = 0;
  totalPartialCorrectAnswers = 0;
  totalInCorrectAnswers = 0;
  comprehensionText: any;
  comprehensionId = "0"
  isQuestionViewModal = false;
  totalPositiveMarks = 0;
  totalNegativeMarks = 0;
  totalEffectiveMarks = 0;
  Headers = [];
  isUserLogin = false;
  selectedHeaderId: any;;
  QuestionData: any;
  Optiones = [];
  checkedOption = [];
  Answer: any;
  ColumnOne = [];
  ColumnSecond = [];
  questionPatternId: any;
  scholarAnswer: any;
  correctAnswer: any;
  coeRollNumber: any;
  patternId: any;
  sectionId: any;
  pieChartLabels = [];
  pieChartData = [];
  isShowPerfrmanceGraph = false;
  examScoreGraphLabel = [];
  examScoreGraphData = [];
  isShowExamScoreGraph = false;
  positiveNegativeChartLabels = [];
  positiveNegativeChartData = [];
  isPositiveNegativeGraph = false;
  // scholarAllExamGraphLabels = [];
  scholarAllExamGraphData = [];
  isScholarAllExamGraph = false;
  scholarName: any;
  dakshanaRollNumber: any;
  categoryName: any;
  isPD: any;
  attemptId = "0";
  timeId = "0";
  DifficultyLevel = [];
  difficultyLevelId = "0";
  isShowscholarDifficultyGraphData = false;
  scholarDifficultyGraphLabel = [];

  scholarDifficultyGraphData = [];
  selectedSubjectId = "0";
  Subjects = [];
  totalEffectiveMarksPercentage: any;
  solutionText; any;
  examTableFormattedData = [];
  examTables = [];
  examHeading = [];
  keys = [];
  examScorebarChartLabels = [];
  examScorebarChartType: any;
  examScorebarChartData = [];
  examScorebarChartLegend = false;

  public lineChartData: ChartDataSets[] = [];
  public overallLinechartData: ChartDataSets[] = [];
  public scholarAllExamGraphLabels: Label[] = [];
  public lineChartOptions: (ChartOptions) = {
    responsive: true,
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  all_subjects = [];

  timeUsageGraphData = {};

  //time usage graph
  isShowscholarTimeUsageGraphData = false;
  public barChartOptionsTimeUsage = {
    responsive: true,
  };

  chartTypeTimeUsage: string = 'horizontalBar';
  public barChartLegendTimeUsage = true;

  public barChartDataTimeUsage = [];
  public barChartLabelsTimeUsage: string[] = [];
  examType: any;
  rank: any;

  selectedSubjectIdForDetailedAnalysis = 0;
  selectedSubjectIdForPerformance = null;

  constructor(private domSanitizer: DomSanitizer, private activatedRoute: ActivatedRoute, private examResultServiceService: ExamResultServiceService, private router: Router) {
    this.generateHeaders();
    this.selectedHeaderId = this.Headers[0].id
    if (localStorage.getItem("isLogin") == "true") {
      this.activatedRoute.params.subscribe(params => {
        this.examName = localStorage.getItem("exam_name")
        this.examTemplateId = params.id_exam_template;
        this.coeRollNumber = params.coe_roll_number;
        this.getExamResultOverView();

      });
    }
    // else {
    //   this.router.navigateByUrl('/');
    // }

  }

  ngOnInit() {
  }

  generateHeaders() {
    let jsonOne = {
      "id": "1",
      "name": "Overview"
    }
    this.Headers.push(jsonOne);
    let jsonSecond = {
      "id": "2",
      "name": "Detailed Analysis"
    }
    this.Headers.push(jsonSecond);

    let jsonThird = {
      "id": "3",
      "name": "Peer Comparison"
    }
    this.Headers.push(jsonThird);
    let jsonFourth = {
      "id": "4",
      "name": "Cumulative Analysis"
    }
    this.Headers.push(jsonFourth);

    let jsonFifth = {
      "id": "5",
      "name": "Performance"
    }
    this.Headers.push(jsonFifth);
  }
  ngAfterContentChecked() {
    if (localStorage.getItem("isLogin") == "true") {
      this.isUserLogin = true
    }
    else {
      this.isUserLogin = false
    }

  }
  getExamResultOverView() {
    this.showLoader = true;
    this.examResultServiceService.getExamResultOverView(localStorage.getItem("email"), this.coeRollNumber, this.examTemplateId).subscribe(
      (data: any) => {
        this.showLoader = false;

        if (data["status_code"] == 0) {
          this.Results = data.results
          //console.log(this.Results);
          this.rank = data.rank;
          this.examMarks = data.exam_total_marks
          this.scholarName = data.scholar_name
          this.dakshanaRollNumber = data.dakshana_roll_number
          this.categoryName = data.category_name
          if (data.is_pd == 0) {
            this.isPD = "No"
          }
          else {
            this.isPD = "Yes"
          }

          this.patternId = data.id_pattern;
          this.sectionId = data.id_section;

          this.Subjects = [];
          for (let data of this.Results) {
            let json = {
              "name": data.subject_name,
              "id_subject": data.id_subject,

            }
            this.Subjects.push(json)
          }
          this.generateResultData();

        }
        else {
          swal.fire(data["status_message"]);
        }
      },
      err => {

      }
    )
  }

  // generateResultData() {
  //   this.totalQuestion = 0;
  //   this.totalAnsweredQuestion = 0;
  //   this.totalNotAnsweredQuestion = 0;
  //   this.totalCorrectAnswers = 0;
  //   this.totalPartialCorrectAnswers = 0;
  //   this.totalInCorrectAnswers = 0;
  //   this.totalPositiveMarks = 0;
  //   this.totalNegativeMarks = 0;
  //   this.totalEffectiveMarks = 0;
  //   for (let data of this.Results) {
  //     this.totalQuestion = this.totalQuestion + parseInt(data.total_question)
  //     this.totalAnsweredQuestion = this.totalAnsweredQuestion + parseInt(data.answered_question)
  //     this.totalNotAnsweredQuestion = this.totalNotAnsweredQuestion + parseInt(data.not_answered)
  //     this.totalCorrectAnswers = this.totalCorrectAnswers + parseInt(data.corect_answers)
  //     this.totalPartialCorrectAnswers = this.totalPartialCorrectAnswers + parseInt(data.partially_correct_answers)
  //     this.totalInCorrectAnswers = this.totalInCorrectAnswers + parseInt(data.incorrect_answer)
  //     this.totalPositiveMarks = this.totalPositiveMarks + parseFloat(data.positive_marks)
  //     this.totalNegativeMarks = this.totalNegativeMarks + parseInt(data.negative_marks)
  //     this.totalEffectiveMarks = this.totalEffectiveMarks + parseFloat(data.effective_marks)
  //   }
  //   this.totalEffectiveMarksPercentage = ((this.totalEffectiveMarks * 100) / this.examMarks).toFixed(2)
  // }

  generateResultData() {
    if (this.selectedHeaderId == '1') {
      this.totalQuestion = 0
      this.totalAnsweredQuestion = 0
      this.totalNotAnsweredQuestion = 0
      this.totalCorrectAnswers = 0
      this.totalPartialCorrectAnswers = 0
      this.totalInCorrectAnswers = 0
    }
    this.totalPositiveMarks = 0
    this.totalNegativeMarks = 0
    this.totalEffectiveMarks = 0
    this.totalAnsweredQuestion = 0
    this.totalNotAnsweredQuestion = 0
    this.totalCorrectAnswers = 0
    this.totalInCorrectAnswers = 0
    this.totalPartialCorrectAnswers = 0


    for (let data of this.Results) {

      if (this.selectedHeaderId == '1') {
        this.totalQuestion = this.totalQuestion + parseInt(data.total_question)
        this.totalAnsweredQuestion = this.totalAnsweredQuestion + parseInt(data.answered_question)
        this.totalNotAnsweredQuestion = this.totalNotAnsweredQuestion + parseInt(data.not_answered)
        this.totalCorrectAnswers = this.totalCorrectAnswers + parseInt(data.corect_answers)
        this.totalPartialCorrectAnswers = this.totalPartialCorrectAnswers + parseInt(data.partially_correct_answers)
        this.totalInCorrectAnswers = this.totalInCorrectAnswers + parseInt(data.incorrect_answer)
      } else {
        this.totalQuestion = this.Results.length;

        if (data.given_answers.length > 0) {

          this.totalAnsweredQuestion++;

          if (data.question_marks == data.positive_marks)
            this.totalCorrectAnswers++;

          if (data.positive_marks == 0)
            this.totalInCorrectAnswers++;

          if ((data.positive_marks > 0) && (data.question_marks != data.positive_marks))
            this.totalPartialCorrectAnswers++;


        } else {
          this.totalNotAnsweredQuestion++;
        }



      }
      this.totalPositiveMarks = this.totalPositiveMarks + parseFloat(data.positive_marks)
      this.totalNegativeMarks = this.totalNegativeMarks + parseInt(data.negative_marks)
      this.totalEffectiveMarks = this.totalEffectiveMarks + parseFloat(data.effective_marks)

    }
  }


  getImage(imagString) {
    var image: any;
    //.log("image" + imagString)
    {
      image = 'data:image/png;base64,' + imagString;
      image = this.domSanitizer.bypassSecurityTrustUrl(image);
      return image
    }
  }

  getEffectiveMarks(positiveMarks, negativeMarks) {
    return (parseInt(positiveMarks) - parseInt(negativeMarks));
  }

  selectHeader(data) {
    this.selectedHeaderId = data.id
    this.selectedSubjectId = "0";
    this.timeId = "0";
    this.difficultyLevelId = "0";
    this.attemptId = "0"
    //.log(this.selectedHeaderId)
    if (this.selectedHeaderId == '2') {
      this.showLoader = true;
      this.examResultServiceService.getExamResultDetailed(localStorage.getItem("email"), this.coeRollNumber, this.examTemplateId).subscribe(
        (data: any) => {
          console.log(data);
          this.showLoader = false;

          if (data["status_code"] == 0) {
            this.Results = data.results
            //console.log(this.Results);
            this.examMarks = data.exam_total_marks
            //console.log(this.examMarks);
            this.UnfilteredResult = data.results
            this.DifficultyLevel = data.difficulty_level
            this.generateResultData();
            this.selectedSubjectIdForDetailedAnalysis = this.Subjects[0]['id_subject'];
            this.filterQuestionsBySubject();

          }
          else {
            swal.fire(data["status_message"]);
          }
        },
        err => {

        }
      )
    }
    else if (this.selectedHeaderId == '3') {
      this.showLoader = true;
      this.examResultServiceService.getScholarPeerComparisionGraph(localStorage.getItem("email"), this.coeRollNumber, this.examTemplateId, this.selectedSubjectId).subscribe(
        data => {
          this.showLoader = false;
          this.examScoreGraphLabel = [];
          this.examScoreGraphData = [];
          this.positiveNegativeChartData = [];
          this.positiveNegativeChartLabels = [];
          if (data["status_code"] == 0) {
            let ExamScoreGrpah = data.exam_score_graph
            var dataArray = []
            for (let data of ExamScoreGrpah) {
              this.examScoreGraphLabel.push(data.label);
              dataArray.push(data.marks_percentage)


            }
            let json = {
              data: dataArray,
              label: "Score %"
            }
            this.examScoreGraphData.push(json);
            //.log(JSON.stringify(this.examScoreGraphData))
            this.isShowExamScoreGraph = true;



            let ExamPositiveNegativeScoreGrpah = data.exam_positive_negative_score_graph
            var dataArrayPositive = [];
            var dataArrayNegative = [];
            for (let data of ExamPositiveNegativeScoreGrpah) {
              this.positiveNegativeChartLabels.push(data.label);
              dataArrayPositive.push(data.positive);
              dataArrayNegative.push(data.negative)


            }
            let jsonPositive = {
              data: dataArrayPositive,
              label: "Positive"
            }
            let jsonNegative = {
              data: dataArrayNegative,
              label: "Negative"
            }
            this.positiveNegativeChartData.push(jsonPositive);
            this.positiveNegativeChartData.push(jsonNegative);
            //.log(JSON.stringify(this.positiveNegativeChartData))
            this.isPositiveNegativeGraph = true;


          }
          else {
            swal.fire(data["status_message"]);
          }
        },
        err => {

        }
      )
    }

    else if (this.selectedHeaderId == '4') {
      this.showLoader = true;
      this.examResultServiceService.getAllExamPerformanceGraph(localStorage.getItem("email"), this.coeRollNumber, this.examTemplateId).subscribe(
        (data: any) => {
          this.showLoader = false;
          this.scholarAllExamGraphLabels = [];
          this.scholarAllExamGraphData = [];
          if (data["status_code"] == 0) {
            let ExamScoreGrpah = data.exam_score_graph
            this.examTables = data.exam_score_graph
            this.examHeading = data.exam_headaing
            // var dataArray = []
            // for (let data of ExamScoreGrpah) {
            //   this.scholarAllExamGraphLabels.push(data.exam_name);
            //   dataArray.push(data.marks_percentage)


            // }
            // let json = {
            //   data: dataArray,
            //   label: "Score %"
            // }
            // this.scholarAllExamGraphData.push(json);
            // //.log(JSON.stringify(this.scholarAllExamGraphData))
            // this.isScholarAllExamGraph = true;
            // this.generateExamTableData();

            for (let data of ExamScoreGrpah) {
              this.scholarAllExamGraphLabels.push(data.exam_name);
            }
            console.log("scholarAllExamGraphLabels=" + JSON.stringify(this.scholarAllExamGraphLabels))
            this.arrangeCommulativeGraphData();

          }
          else {
            swal.fire(data["status_message"]);
          }
        },
        err => {

        }
      )
    }
    else if (this.selectedHeaderId == '5') {
      this.showLoader = true;
      this.examResultServiceService.getScholarPerformanceGraph(localStorage.getItem("email"), this.coeRollNumber, this.examTemplateId, this.selectedSubjectIdForPerformance).subscribe(
        (data: any) => {
          this.showLoader = false;
          this.pieChartLabels = [];
          this.pieChartData = [];
          this.scholarDifficultyGraphData = [];
          this.scholarDifficultyGraphLabel = [];
          if (data["status_code"] == 0) {
            this.pieChartLabels.push("Correct Answer");
            this.pieChartLabels.push("In-Correct Answer");
            this.pieChartLabels.push("Partial Answer");
            this.pieChartLabels.push("Not-attempt Answer");

            this.pieChartData.push(data.correct_answer);
            this.pieChartData.push(data.in_correct_answer);
            this.pieChartData.push(data.partial_correct);
            this.pieChartData.push(data.not_attendent);
            this.isShowPerfrmanceGraph = true;
            this.scholarDifficultyGraphLabel.push("Correct");
            this.scholarDifficultyGraphLabel.push("Partial Correct");
            this.scholarDifficultyGraphLabel.push("Incorrect");
            this.scholarDifficultyGraphLabel.push("Unattempted");
            var veryEasy = [];
            var easy = [];
            var medium = [];
            var difficult = [];
            var verydifficult = [];
            for (let co of data.correct_answer_array) {
              if (co.level == "Very Easy") {

                veryEasy.push(co.data)
              }
              else if (co.level == "Easy") {

                easy.push(co.data)
              }

              else if (co.level == "Medium") {

                medium.push(co.data)
              }
              else if (co.level == "Difficult") {

                difficult.push(co.data)
              }
              else if (co.level == "Very Difficult") {

                verydifficult.push(co.data)
              }

            }
            for (let co of data.partial_correct_answer_array) {
              if (co.level == "Very Easy") {

                veryEasy.push(co.data)
              }
              else if (co.level == "Easy") {

                easy.push(co.data)
              }

              else if (co.level == "Medium") {

                medium.push(co.data)
              }
              else if (co.level == "Difficult") {

                difficult.push(co.data)
              }
              else if (co.level == "Very Difficult") {

                verydifficult.push(co.data)
              }

            }
            for (let co of data.incorrect_answer_array) {
              if (co.level == "Very Easy") {

                veryEasy.push(co.data)
              }
              else if (co.level == "Easy") {

                easy.push(co.data)
              }

              else if (co.level == "Medium") {

                medium.push(co.data)
              }
              else if (co.level == "Difficult") {

                difficult.push(co.data)
              }
              else if (co.level == "Very Difficult") {

                verydifficult.push(co.data)
              }

            }

            for (let co of data.unanswered_array) {
              if (co.level == "Very Easy") {

                veryEasy.push(co.data)
              }
              else if (co.level == "Easy") {

                easy.push(co.data)
              }

              else if (co.level == "Medium") {

                medium.push(co.data)
              }
              else if (co.level == "Difficult") {

                difficult.push(co.data)
              }
              else if (co.level == "Very Difficult") {

                verydifficult.push(co.data)
              }

            }


            let jsonEasy = {
              data: easy,
              label: "Easy"
            }
            let jsonVeryEasy = {
              data: veryEasy,
              label: "Very Easy"
            }
            let jsonMedium = {
              data: medium,
              label: "Medium"
            }
            let jsonDifficult = {
              data: difficult,
              label: "Difficult"
            }
            let jsonVeyDifficult = {
              data: verydifficult,
              label: "Very Difficult"
            }

            this.scholarDifficultyGraphData.push(jsonEasy)
            this.scholarDifficultyGraphData.push(jsonVeryEasy)
            this.scholarDifficultyGraphData.push(jsonMedium)
            this.scholarDifficultyGraphData.push(jsonDifficult)
            this.scholarDifficultyGraphData.push(jsonVeyDifficult)
            this.isShowscholarDifficultyGraphData = true


            // for stacked horizontal barchart time usage

            console.log("Time_usage_graph_data=" + this.timeUsageGraphData);
            this.timeUsageGraphData = data.time_usage_graph_data;
            this.generateTimeUsageTableData();



          }
          else {
            swal.fire(data["status_message"]);
          }
        },
        err => {

        }
      )
    }
    else {
      this.getExamResultOverView();
    }
  }

  isHeaderActive(data) {
    if (data.id == this.selectedHeaderId) {
      return true;
    }
    else {
      return false;
    }
  }

  getScholarPerformanceGraph() {
    this.showLoader = true;
    this.examResultServiceService.getScholarPerformanceGraph(localStorage.getItem("email"), this.coeRollNumber, this.examTemplateId, this.selectedSubjectIdForPerformance).subscribe(
      (data: any) => {
        this.showLoader = false;
        this.pieChartLabels = [];
        this.pieChartData = [];
        this.scholarDifficultyGraphData = [];
        this.scholarDifficultyGraphLabel = [];
        if (data["status_code"] == 0) {
          this.pieChartLabels.push("Correct Answer");
          this.pieChartLabels.push("In-Correct Answer");
          this.pieChartLabels.push("Partial Answer");
          this.pieChartLabels.push("Not-attempt Answer");

          this.pieChartData.push(data.correct_answer);
          this.pieChartData.push(data.in_correct_answer);
          this.pieChartData.push(data.partial_correct);
          this.pieChartData.push(data.not_attendent);
          this.isShowPerfrmanceGraph = true;
          this.scholarDifficultyGraphLabel.push("Correct");
          this.scholarDifficultyGraphLabel.push("Partial Correct");
          this.scholarDifficultyGraphLabel.push("Incorrect");
          this.scholarDifficultyGraphLabel.push("Unattempted");
          var veryEasy = [];
          var easy = [];
          var medium = [];
          var difficult = [];
          var verydifficult = [];
          for (let co of data.correct_answer_array) {
            if (co.level == "Very Easy") {

              veryEasy.push(co.data)
            }
            else if (co.level == "Easy") {

              easy.push(co.data)
            }

            else if (co.level == "Medium") {

              medium.push(co.data)
            }
            else if (co.level == "Difficult") {

              difficult.push(co.data)
            }
            else if (co.level == "Very Difficult") {

              verydifficult.push(co.data)
            }

          }
          for (let co of data.partial_correct_answer_array) {
            if (co.level == "Very Easy") {

              veryEasy.push(co.data)
            }
            else if (co.level == "Easy") {

              easy.push(co.data)
            }

            else if (co.level == "Medium") {

              medium.push(co.data)
            }
            else if (co.level == "Difficult") {

              difficult.push(co.data)
            }
            else if (co.level == "Very Difficult") {

              verydifficult.push(co.data)
            }

          }
          for (let co of data.incorrect_answer_array) {
            if (co.level == "Very Easy") {

              veryEasy.push(co.data)
            }
            else if (co.level == "Easy") {

              easy.push(co.data)
            }

            else if (co.level == "Medium") {

              medium.push(co.data)
            }
            else if (co.level == "Difficult") {

              difficult.push(co.data)
            }
            else if (co.level == "Very Difficult") {

              verydifficult.push(co.data)
            }

          }

          for (let co of data.unanswered_array) {
            if (co.level == "Very Easy") {

              veryEasy.push(co.data)
            }
            else if (co.level == "Easy") {

              easy.push(co.data)
            }

            else if (co.level == "Medium") {

              medium.push(co.data)
            }
            else if (co.level == "Difficult") {

              difficult.push(co.data)
            }
            else if (co.level == "Very Difficult") {

              verydifficult.push(co.data)
            }

          }


          let jsonEasy = {
            data: easy,
            label: "Easy"
          }
          let jsonVeryEasy = {
            data: veryEasy,
            label: "Very Easy"
          }
          let jsonMedium = {
            data: medium,
            label: "Medium"
          }
          let jsonDifficult = {
            data: difficult,
            label: "Difficult"
          }
          let jsonVeyDifficult = {
            data: verydifficult,
            label: "Very Difficult"
          }

          this.scholarDifficultyGraphData.push(jsonEasy)
          this.scholarDifficultyGraphData.push(jsonVeryEasy)
          this.scholarDifficultyGraphData.push(jsonMedium)
          this.scholarDifficultyGraphData.push(jsonDifficult)
          this.scholarDifficultyGraphData.push(jsonVeyDifficult)
          this.isShowscholarDifficultyGraphData = true


          // for stacked horizontal barchart time usage

          console.log("Time_usage_graph_data=" + this.timeUsageGraphData);
          this.timeUsageGraphData = data.time_usage_graph_data;
          this.generateTimeUsageTableData();



        }
        else {
          swal.fire(data["status_message"]);
        }
      },
      err => {

      }
    )
  }

  showQuestionMOdal(data) {
    this.comprehensionText = data.comprehension_text
    this.comprehensionId = data.id_comprehension
    this.QuestionData = data.question_text
    this.Optiones = data.question_optiones
    this.Answer = data.answer;
    this.ColumnOne = data.column_one;
    this.ColumnSecond = data.column_second;
    this.questionPatternId = data.id_question_pattern;
    this.scholarAnswer = data.given_answers;
    this.correctAnswer = data.correct_answer_string
    this.solutionText = data.solution_text
    this.isQuestionViewModal = true;

  }
  hideModal() {
    this.isQuestionViewModal = false;

  }

  getColorClassName(data) {
    //.log(data.ideal_time);
    var colorName;
    if (data.ideal_time) {
      var idealTime = ((data.ideal_time).split(" "))[0]
      var actualTime = data.actual_time
      let result = Math.round(parseInt(actualTime) * 10.0 / parseInt(idealTime)) / 100
      if (result > 1.2) {
        colorName = "color-red"
      }
      else if (result > 1.1) {
        colorName = "color-amber"
      }
      else if (result < 1.0) {
        colorName = "color-green"
      }
      //.log(colorName)
      return colorName
    }

  }


  back() {
    localStorage.setItem("exam_name", this.examName)
    let url = '/online-exam-result-anaylsis/' + this.examTemplateId + "/" + this.patternId + "/" + this.sectionId
    this.router.navigateByUrl(url);
  }

  getQuestionText() {
    ////.log(this.QuestionData.question_text)

    if (this.questionPatternId == "6") {
      let x = this.QuestionData.split("$$");
      //.log(x)
      return "Assertion: " + x[0] + " Reason: " + x[1]
    }
    else {
      return this.QuestionData;
    }
  }

  // filterQuestionData(way) {
  //   this.Results = this.UnfilteredResult;


  //   if (this.attemptId != "0") {
  //     var temporaryArray = [];
  //     for (let data of this.Results) {
  //       if (this.attemptId == "1") {
  //         if (data.question_positive_marks == data.effective_marks) {
  //           temporaryArray.push(data)
  //         }
  //       }
  //       else if (this.attemptId == "2") {
  //         if ((data.question_negative_marks == (-1) * data.effective_marks) && data.given_answers != "") {
  //           temporaryArray.push(data)
  //         }
  //       }
  //       else if (this.attemptId == "3") {
  //         if (data.given_answers == "") {
  //           temporaryArray.push(data)
  //         }
  //       }
  //     }
  //     this.Results = temporaryArray

  //   }



  //   if (this.difficultyLevelId != "0") {
  //     var temporaryArray = [];
  //     for (let data of this.Results) {
  //       if (data.id_difficult_level == this.difficultyLevelId) {
  //         temporaryArray.push(data)
  //       }
  //     }
  //     this.Results = temporaryArray
  //   }



  //   if (this.timeId != "0") {
  //     var temporaryArray = [];
  //     for (let data of this.Results) {
  //       //.log("data.ideal_time" + data.ideal_time)
  //       if (this.timeId == "1") {
  //         if (data.ideal_time) {
  //           let idealTime = data.ideal_time.split(" ")[0];
  //           if (parseInt(idealTime) >= parseInt(data.actual_time)) {
  //             temporaryArray.push(data)
  //           }
  //         }

  //       }
  //       else {
  //         if (data.ideal_time) {
  //           let idealTime = data.ideal_time.split(" ")[0];
  //           if (parseInt(idealTime) < parseInt(data.actual_time)) {
  //             temporaryArray.push(data)
  //           }
  //         }


  //       }

  //     }
  //     this.Results = temporaryArray
  //   }


  //   //.log(JSON.stringify(this.Results))
  //   this.generateResultData();
  // }

  filterQuestionData(way) {
    this.Results = this.UnfilteredResult;
    console.log(this.Results)

    if (this.attemptId != "0") {
      var temporaryArray = [];
      console.log(this.attemptId)
      for (let data of this.Results) {
        if (this.attemptId == "1") {
          // console.log(data)
          if (data.question_positive_marks == data.effective_marks && data.given_answers != "") {
            temporaryArray.push(data)
          }
        }
        else if (this.attemptId == "2") {
          if (data.negative_marks != "0" && data.given_answers != "") {
            temporaryArray.push(data)
          }

          if (data.positive_marks == "0" && data.negative_marks == "0" && data.given_answers != "") {
            temporaryArray.push(data)
          }

        }
        else if (this.attemptId == "3") {
          if (data.given_answers == "") {
            temporaryArray.push(data)
          }
        }
      }

      this.Results = temporaryArray
    }



    if (this.difficultyLevelId != "0") {
      var temporaryArray = [];
      for (let data of this.Results) {
        if (data.id_difficult_level == this.difficultyLevelId) {
          temporaryArray.push(data)
        }
      }
      this.Results = temporaryArray
    }



    if (this.timeId != "0") {
      var temporaryArray = [];
      for (let data of this.Results) {
        console.log("data.ideal_time" + data.ideal_time)
        if (this.timeId == "1") {
          if (data.ideal_time) {
            let idealTime = data.ideal_time.split(" ")[0];
            if (parseInt(idealTime) >= parseInt(data.actual_time)) {
              temporaryArray.push(data)
            }
          }

        }
        else {
          if (data.ideal_time) {
            let idealTime = data.ideal_time.split(" ")[0];
            if (parseInt(idealTime) < parseInt(data.actual_time)) {
              temporaryArray.push(data)
            }
          }


        }

      }
      this.Results = temporaryArray
    }


    if (this.selectedSubjectIdForDetailedAnalysis != 0) {
      var tempArray = [];
      this.Results.forEach(question => {
        if (question.id_subject == this.selectedSubjectIdForDetailedAnalysis) {
          tempArray.push(question);
        }
      });
      this.Results = tempArray;
    }



    console.log(JSON.stringify(this.Results))
    this.generateResultData();
  }


  getScholarPeerComaprision() {
    this.showLoader = true;
    this.examResultServiceService.getScholarPeerComparisionGraph(localStorage.getItem("email"), this.coeRollNumber, this.examTemplateId, this.selectedSubjectId).subscribe(
      (data: any) => {
        this.showLoader = false;
        this.positiveNegativeChartData = [];
        this.positiveNegativeChartLabels = [];
        this.examScoreGraphLabel = []
        this.examScoreGraphData = []
        if (data["status_code"] == 0) {
          let ExamScoreGrpah = data.exam_score_graph
          var dataArray = []
          for (let data of ExamScoreGrpah) {
            this.examScoreGraphLabel.push(data.label);
            dataArray.push(data.marks_percentage)


          }
          let json = {
            data: dataArray,
            label: "Score %"
          }
          this.examScoreGraphData.push(json);
          //.log(JSON.stringify(this.examScoreGraphData))
          this.isShowExamScoreGraph = true;



          let ExamPositiveNegativeScoreGrpah = data.exam_positive_negative_score_graph
          var dataArrayPositive = [];
          var dataArrayNegative = [];
          for (let data of ExamPositiveNegativeScoreGrpah) {
            this.positiveNegativeChartLabels.push(data.label);
            dataArrayPositive.push(data.positive);
            dataArrayNegative.push(data.negative)


          }
          let jsonPositive = {
            data: dataArrayPositive,
            label: "Positive"
          }
          let jsonNegative = {
            data: dataArrayNegative,
            label: "Negative"
          }
          this.positiveNegativeChartData.push(jsonPositive);
          this.positiveNegativeChartData.push(jsonNegative);
          //.log(JSON.stringify(this.positiveNegativeChartData))
          this.isPositiveNegativeGraph = true;

        }
        else {
          swal.fire(data["status_message"]);
        }
      },
      err => {

      }
    )
  }



  public barChartType: string = 'bar';
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          min: 0,
          max: 100,
          stepSize: 10
        }
      }]
    }
  };

  public chartColors: Array<any> = [
    { // blue
      backgroundColor: 'rgba(51, 153, 255)',
      borderColor: 'rgba(51, 153, 2558)',
      pointBackgroundColor: 'rgba(51, 153, 255)',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(51, 153, 255)'
    },
    { // purple
      backgroundColor: 'rgb(220, 57, 18)',
      borderColor: 'rgb(220, 57, 18)',
      pointBackgroundColor: 'rgb(220, 57, 18)',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(220, 57, 18)'
    },
    { // green
      backgroundColor: 'rgb(255, 255, 0)',
      borderColor: 'rgb(255, 255, 0)',
      pointBackgroundColor: 'rgb(255, 255, 0)',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(255, 255, 0)'
    },

    { // green
      backgroundColor: 'rgb(255, 153, 0)',
      borderColor: 'rgb(255, 153, 0)',
      pointBackgroundColor: 'rgb(255, 153, 0)',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(255, 153, 0)'
    }


  ];

  public chartOptions: any = {
    responsive: true
  };
  public chartClicked(e: any): void {
    //.log(e);
  }

  public chartHovered(e: any): void {
    //.log(e);
  }

  generateExamTableData() {
    var testdata = [];
    this.examTableFormattedData = [];

    for (let exam of this.examTables) {
      var data = {};
      data['test_name'] = exam.exam_name;
      data['total_marks'] = exam.marks_percentage;
      var totalMarks = 0;
      for (let ed of exam.subjects) {
        for (let heading of this.examHeading) {
          if (heading.id_subject == ed.id_subject) {
            data[heading.subject_name] = ed.marks_percentage;
          }
          else {
            if (!data[heading.subject_name]) {
              data[heading.subject_name] = '-';
            }

          }
        }
      }

      this.examTableFormattedData.push(data);
    }
    this.keys = Object.keys(this.examTableFormattedData[0])
    //.log(this.examTableFormattedData)
    this.generateExamScoreGraph();
  }

  generateExamScoreGraph() {
    //.log(this.examTableFormattedData)
    this.examScorebarChartType = 'bar';

    this.examScorebarChartLabels = [];
    this.examScorebarChartData = []
    for (let data of this.examTableFormattedData) {
      this.examScorebarChartLabels.push(data.test_name)
    }
    let jsonTotalArray = [];
    for (let subject of this.examHeading) {
      let jsonArray = [];

      for (let data of this.examTableFormattedData) {
        for (let key of this.keys) {
          if (key == subject.subject_name) {
            if (data[key] != "-") {
              let subjectMarksPercentage = data[key]
              if (subjectMarksPercentage != null) {
                jsonArray.push(subjectMarksPercentage.toFixed(2))

              }
              else {
                jsonArray.push(subjectMarksPercentage)

              }
            }
            else {
              jsonArray.push(0)
            }
          }
          if (key == "total_marks") {
            var obtainedTotalPercentage = data["total_marks"]
            jsonTotalArray.push(obtainedTotalPercentage)

          }

        }

      }

      let json = {
        data: jsonArray, label: subject.subject_name
      }
      this.examScorebarChartData.push(json);

    }
    let jsonSecond = {
      data: jsonTotalArray, label: "Total"
    }
    this.examScorebarChartData.push(jsonSecond);
    //.log(JSON.stringify(this.examScorebarChartData))
    this.examScorebarChartLegend = true;

  }

  overall_subjects = [];
  subjects = [];

  showCommulativeGraph = false;
  arrangeCommulativeGraphData() {
    var all_subject = [];
    this.subjects = [];
    // First find total subject across all exam
    this.examTables.forEach(exam => {
      var flag = false;
      exam.subjects.forEach(subject => {

        all_subject.forEach(sub => {
          if (sub.id_subject == subject.id_subject) {
            flag = true;
          }
        });

        if (!flag) {
          var s = { 'id_subject': subject.id_subject, 'name': subject.subject_name, 'data': [], 'topper_data': [], 'average_data': [] }
          all_subject.push(s);
          var s1 = { 'id_subject': subject.id_subject, 'name': subject.subject_name, 'is_checked': 0 }
          this.subjects.push(s1);
        }

      });

    });

    // add overall to for radio button
    var o = { 'id_subject': 0, 'name': 'Overall', 'is_checked': 1 };
    this.subjects.push(o)


    this.overall_subjects = all_subject;

    //for each subject get marks of exam ,if exam is not for the subject assign 0
    this.overall_subjects.forEach(subject => {
      var data = [];
      var top_data = [];
      var average_data = [];
      this.examTables.forEach(exam => {

        var mark = 0;
        var top_mark = 0;
        var average_mark = 0;
        exam.subjects.forEach(sub => {
          if (sub.id_subject == subject.id_subject) {
            mark = sub.marks_percentage;
            top_mark = sub.topper_marks_percentage;
            average_mark = sub.average_marks_percentage;
          }
        });
        data.push(mark);
        top_data.push(top_mark);
        average_data.push(average_mark);

      });
      subject.data = data;
      subject.topper_data = top_data;
      subject.average_data = average_data;
    });

    console.log("first overall_subjects=" + JSON.stringify(this.overall_subjects));

    this.lineChartData = [];
    this.overallLinechartData = [];

    // now for overall - me and topper,average
    var d = { data: [], label: 'Me' }
    var d1 = { data: [], label: 'Topper' }
    var d2 = { data: [], label: 'Average' }

    var myData = [];
    var topperData = [];
    var averageData = [];
    this.examTables.forEach(exam => {
      myData.push(exam.marks_percentage);
      topperData.push(exam.topper_mark_percentage);
      averageData.push(exam.average_mark_percentage);
    });
    d.data = myData;
    d1.data = topperData;
    d2.data = averageData;
    this.lineChartData.push(d);
    this.lineChartData.push(d1);
    this.lineChartData.push(d2);
    this.overallLinechartData = this.lineChartData;
    this.isScholarAllExamGraph = true;
    console.log("finalGraphData=" + JSON.stringify(this.lineChartData));

  }

  selectSubject(subject) {

    this.all_subjects.forEach(subject => {
      subject.is_checked = 0;
    });

    subject.is_checked = 1;

    if (subject.name == 'Overall') {
      this.lineChartData = this.overallLinechartData;
    } else {
      var selectedSubject = { data: [], label: '' };
      var topper_data = [];
      var average_data = [];
      console.log("overall_subjects=" + JSON.stringify(this.overall_subjects));
      this.overall_subjects.forEach(element => {
        if (element.id_subject == subject.id_subject) {

          selectedSubject.data = element.data;
          selectedSubject.label = "Me " + element.name;
          topper_data = element.topper_data;
          average_data = element.average_data;
        }
      });

      this.lineChartData = [];
      this.lineChartData.push(selectedSubject);
      this.lineChartData.push({ data: topper_data, label: 'Topper ' + selectedSubject.label });
      this.lineChartData.push({ data: average_data, label: 'Average ' + selectedSubject.label });
      console.log("finalGraphData=" + JSON.stringify(this.lineChartData));
    }

  }

  totalTimeSpend = 0;
  totalTime = 0;
  totalPercentage = 0;

  generateTimeUsageTableData() {

    var dataCorrectQuestions = [];
    var dataPartiallyCorrectQuestions = [];
    var dataInCorrectQuestions = [];
    var dataUnattemptedQuestions = [];
    var dataLable = [];

    this.totalTimeSpend = this.timeUsageGraphData['total_spend_time'];
    this.totalTime = this.timeUsageGraphData['total_time'];

    this.totalPercentage = (this.totalTimeSpend * 100) / this.totalTime;

    var subjects = this.timeUsageGraphData['subjects'];

    subjects.forEach(subject => {

      dataLable.push(subject.subject);
      dataCorrectQuestions.push(subject.time_perc_coreect_question);
      dataPartiallyCorrectQuestions.push(subject.time_perc_partial_question);
      dataInCorrectQuestions.push(subject.time_perc_incoreect_question);
      dataUnattemptedQuestions.push(subject.time_perc_unattempt_question);

    });

    console.log("dataLable length=" + dataLable.length);
    console.log("dataCorrectQuestions length=" + dataCorrectQuestions.length);

    this.barChartDataTimeUsage = [
      { data: dataCorrectQuestions, label: '% Time Spent in correct questions', stack: 'questions' },
      { data: dataCorrectQuestions, label: '% Time Spent in partially correct questions', stack: 'questions' },
      { data: dataInCorrectQuestions, label: '% Time Spent in incorrect questions', stack: 'questions' },
      { data: dataUnattemptedQuestions, label: '% Time Spent in unattempted questions', stack: 'questions' }
    ];


    this.barChartLabelsTimeUsage = dataLable;
    this.isShowscholarTimeUsageGraphData = true;

  }

  filterQuestionsBySubject() {
    this.Results = [];
    if (this.selectedSubjectIdForDetailedAnalysis == 0) {
      this.Results = this.UnfilteredResult;
    } else {
      this.UnfilteredResult.forEach(question => {
        if (question.id_subject == this.selectedSubjectIdForDetailedAnalysis) {
          this.Results.push(question);
        }
      });
    }
  }


}
