import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class ExamMasterService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }


    getAllCoe(email) {
        return this.http.get(this.baseUrl + "/Coe/getAllActiveCoe?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllBatches(email) {
        return this.http.get(this.baseUrl + "/Batch/getAllActiveBatch?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllSections(email) {
        return this.http.get(this.baseUrl + "/Section/getAllActiveSection?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllExamMasterData(email) {
        return this.http.get(this.baseUrl + "/Exam/getAllExamMasterdata?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllExams(email) {
        return this.http.get(this.baseUrl + "/Exam/getAllExams?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }
    getAllExamsBySectionId(email, sectionId) {
        return this.http.get(this.baseUrl + "/Exam/getAllExamsBySectionId?caller_email=" + email + "&id_section=" + sectionId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    addNewExam(jsonData) {
        //JSON.stringify(jsonData);
        //.log(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Exam/addNewExam", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    editExam(jsonData) {
       
        return this.http.post(this.baseUrl + "Exam/editExam", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }
    markExamDone(jsonData) {
       
        return this.http.post(this.baseUrl + "Exam/markExamDone", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }
    
    editExamWeightage(email, idExam, weightage) {
        return this.http.get(this.baseUrl + "/Exam/editExamWeightage?caller_email=" + email + "&id_exam=" + idExam + "&exam_weightage=" + weightage).map((res) => JSON.parse(JSON.stringify(res)));
    }
    markExamAsDone(email, idExam, conductedDate) {
        return this.http.get(this.baseUrl + "/Exam/markExamAsDone?caller_email=" + email + "&id_exam=" + idExam + "&conducted_date=" + conductedDate).map((res) => JSON.parse(JSON.stringify(res)));
    }


    exportExamResult(jsonData) {
        return this.http.post(this.baseUrl + "Exam/exportExamResult", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getSyllabusDataBySectionId(email, sectionId) {
        return this.http.get(this.baseUrl + "/Exam/getSyllabusDataBySectionId?caller_email=" + email + "&id_section=" + sectionId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeExam(email, idExam) {
        return this.http.get(this.baseUrl + "/Exam/removeExam?caller_email=" + email + "&id_exam=" + idExam).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getExamResultDataForExams(jsonData) {
       
        return this.http.post(this.baseUrl + "Exam/getExamResultDataForExams", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }
    
}