import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import { Helper } from '../helper';
import { Headers, RequestOptions } from '@angular/http';
@Injectable({
  providedIn: 'root'
})
export class AlumnMasterService {
  baseUrl = Helper.baseURL;
  headers: any;
  options: any
  constructor(private http: HttpClient) { 
    this.headers = new Headers({ 'Content-Type': 'text/plain' });
    this.options = new RequestOptions({ headers: this.headers });
  }

  getAllAlumns(email) {
    return this.http.get(this.baseUrl + "/Alumni/getAlumnFullDetail?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
  }

  getAllCoe(email) {
    return this.http.get(this.baseUrl + "/Coe/getAllActiveCoe?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
  }

    getAllCourse(email) {
        return this.http.get(this.baseUrl + "/Course/getAllCourseForBatch?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }
    changeUserStatus(userId, callerEmail, statusId) {
      return this.http.get(this.baseUrl + "/User/changeUserStatus?id_role=" + 5 + "&id_user=" + userId + "&caller_email=" + callerEmail + "&id_status=" + statusId).map((res) => JSON.parse(JSON.stringify(res)));
    }


}
