import { Component } from '@angular/core';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2'
import { md5 } from '../md5'

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss'],
    providers: [LoginService]
})
export class LoginComponent {

    email: any;
    password: any;
    emailPattern = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/;
    isForgotModal: boolean
    otp: any;
    confirmPassword: any;
    newPassword: any;
    userId: any;
    showLoader: boolean;
    

    constructor(private loginService: LoginService, private router: Router) {
        this.isForgotModal = false;
        this.showLoader = false;
        if (localStorage.getItem("isLogin") == "true") {
            if (localStorage.getItem("id_role") == "1") {
                this.router.navigateByUrl('/Master');
            }
            else if (localStorage.getItem("id_role") == "2") {
                this.router.navigateByUrl('/Coordinator-Dashboard');
            }
            else if (localStorage.getItem("id_role") == "3") {
                this.router.navigateByUrl('/Management-Dashboard');
            }
            else if (localStorage.getItem("id_role") == "4") {
                this.router.navigateByUrl('/question bank');
            }
            else if (localStorage.getItem("id_role") == "5") {

                //localStorage.setItem("photo_url", this.imageString);
                this.router.navigateByUrl('/Scholar-Dashboard');
            }
            else if (localStorage.getItem("id_role") == "6") {
                this.router.navigateByUrl('/question bank');
            }


        }

    }

    login() {
        localStorage.clear();
        if (!this.email) {
            swal.fire("Please enter email.");
        }
        else if ((this.email.length != 11)&&(this.email.length != 10)&&!(this.email.toLowerCase().match(this.emailPattern))) {
            swal.fire("Please enter correct email/dakshaana roll no/mobile");
        }
        else if (!this.password) {
            swal.fire("Please enter password.");
        }
        else {
            let encyptPassword = md5(this.password);
            this.showLoader = true;
            this.loginService.authUser(this.email, encyptPassword).subscribe(
                data => {
                    this.showLoader = false;
                    if (data.status_code == 0) {
                        let userData = data["user_data"][0];
                        localStorage.setItem("isLogin", "true");
                        localStorage.setItem("id_user", userData["id_user"]);
                        localStorage.setItem("name", userData["name"]);
                        localStorage.setItem("mobile", userData["mobile"]);
                        localStorage.setItem("email", userData["email"]);
                        localStorage.setItem("id_role", userData["id_role"]);
                        localStorage.setItem("role", userData["role"]);
                        localStorage.setItem("token", data["token"]);
                        if (data.id_section) {
                            localStorage.setItem("id_section_scholar", data.id_section);
                            localStorage.setItem("section_name", data.section_name);
                            localStorage.setItem("coe_name", data.coe_name);
                            localStorage.setItem("address", data.address);
                            localStorage.setItem("batch_name", data.batch_name);
                            localStorage.setItem("id_section_scholar", data.id_section);
                            localStorage.setItem("dakshana_roll_number", data.dakshana_roll_number);
                            localStorage.setItem("coe_roll_number", data.coe_roll_number);
                        }
                        else {
                            localStorage.setItem("id_section_scholar", "");
                        }

                        // //(localStorage.getItem("id_section_scholar"))
                        if (localStorage.getItem("id_role") == "1") {
                            this.router.navigateByUrl('/Master');
                        }
                        else if (localStorage.getItem("id_role") == "2") {
                            this.router.navigateByUrl('/Coordinator-Dashboard');
                        }
                        else if (localStorage.getItem("id_role") == "3") {
                            this.router.navigateByUrl('/Management-Dashboard');
                        }
                        else if (localStorage.getItem("id_role") == "4") {
                            this.router.navigateByUrl('/question bank');
                        }
                        else if (localStorage.getItem("id_role") == "5") {                         
                           this.router.navigateByUrl('/Scholar-Dashboard');                           

                        }
                        else if (localStorage.getItem("id_role") == "6") {
                            this.router.navigateByUrl('/question bank');
                        }

                    }
                    else {
                        localStorage.setItem("isLogin", "false");
                        swal.fire(data["status_message"]);
                    }
                },
                err => {
                    this.showLoader = false;
                    swal.fire("Something went wrong. Please try again later.")
                }
            )
        }
    }

    forgotPassword() {
        this.otp = ""
        this.confirmPassword = "";
        this.confirmPassword = "";
        if (!this.email) {
            swal.fire("Please enter email.");
        }
        else {
            this.showLoader = true;
            this.loginService.generateOtp(this.email).subscribe(
                data => {
                    this.showLoader = false;
                    if (data.status_code == 0) {
                        this.isForgotModal = true
                        this.userId = data["id_user"];
                        swal.fire(data["status_message"]);
                    }
                    else {
                        localStorage.setItem("isLogin", "false");
                        swal.fire(data["status_message"]);
                    }
                },
                err => {
                    this.showLoader = false;
                    swal.fire("Something went wrong. Please try again later.")
                }
            )
        }
    }
    hideForgotPasswordModal() {
        this.isForgotModal = false;
    }
    updatePassword() {
        if (!this.otp) {
            swal.fire("Please enter OTP.");
        }
        else if (!this.newPassword) {
            swal.fire("Please enter new password.");
        }
        else if (!this.confirmPassword) {
            swal.fire("Please confirm password.");
        }
        else if (this.confirmPassword != this.newPassword) {
            swal.fire("Password does not match.");
        }
        else {
            let encruptPassword = md5(this.newPassword);
            this.showLoader = true;
            this.loginService.verifyUserOtp(this.otp, this.userId, encruptPassword, this.email, "forgotPassword").subscribe(
                data => {
                    this.showLoader = false;
                    if (data.status_code == 0) {
                        let userData = data["user_data"][0];
                        localStorage.setItem("isLogin", "true");
                        localStorage.setItem("id_user", userData["id_user"]);
                        localStorage.setItem("name", userData["name"]);
                        localStorage.setItem("mobile", userData["mobile"]);
                        localStorage.setItem("email", userData["email"]);
                        localStorage.setItem("id_role", userData["id_role"]);
                        localStorage.setItem("role", userData["role"]);
                        localStorage.setItem("token", data["token"]);
                        if (data.id_section) {
                            localStorage.setItem("id_section_scholar", data.id_section);
                            localStorage.setItem("section_name", data.section_name);
                            localStorage.setItem("coe_name", data.coe_name);
                            localStorage.setItem("address", data.address);
                            localStorage.setItem("batch_name", data.batch_name);
                            localStorage.setItem("id_section_scholar", data.id_section);
                            localStorage.setItem("dakshana_roll_number", data.dakshana_roll_number);
                            localStorage.setItem("coe_roll_number", data.coe_roll_number);
                        }
                        else {
                            localStorage.setItem("id_section_scholar", "");
                        }
                        if (localStorage.getItem("id_role") == "1") {
                            this.router.navigateByUrl('/Master');
                        }
                        else if (localStorage.getItem("id_role") == "2") {
                            this.router.navigateByUrl('/Coordinator-Dashboard');
                        }
                        else if (localStorage.getItem("id_role") == "3") {
                            this.router.navigateByUrl('/Management-Dashboard');
                        }
                        else if (localStorage.getItem("id_role") == "4") {
                            this.router.navigateByUrl('/question bank');
                        }
                        else if (localStorage.getItem("id_role") == "5") {
                            this.router.navigateByUrl('/Scholar-Dashboard');
                        }
                        else if (localStorage.getItem("id_role") == "6") {
                            this.router.navigateByUrl('/question bank');
                        }
                    }
                    else {
                        localStorage.setItem("isLogin", "false");
                        swal.fire(data["status_message"]);
                    }
                },
                err => {
                    this.showLoader = false;
                    swal.fire("Something went wrong. Please try again later.")
                }
            )
        }
    }

  
 

// getScholarPhoto() {
//     var imageString = "../assets/logoscholar.jpg";
//     if (this.scholarDocumnets.length > 0) {
//         let index = this.scholarDocumnets.findIndex(
//             (data, index) => data.document_type === "Scholar Photo"
//         );

//         if (index != -1) {
//             imageString = this.scholarDocumnets[index].document;
//         }
//     }
//     return imageString;
// }


}
