import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IntegerTypeQuestionService } from './integertype-question.service'
import swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
  selector: 'integertype-question',
  templateUrl: './integertype-question.component.html',
  styleUrls: ['./integertype-question.component.css'],
  providers: [IntegerTypeQuestionService]
})
export class IntegertypeQuestionComponent implements OnInit {
  showLoader = false;
  @Output() isHidden = new EventEmitter<string>();
  @Input() Subjects = [];
  @Input() subjectId: any;
  @Input() questionFor: any;
  SubSubjects = [];
  @Input() UnFiltterdSubSubjects = [];
  @Input() subSubjectId: any;
  Topics = [];
  @Input() topicId: any;
  @Input() UnfiltterdTopics = [];
  SubTopics = [];
  @Input() UnfilttersSubTopics = [];
  @Input() subTopicId: any;
  idCourseType = 14;
  orignalQuestionSources = [];
  @Input() QuestionSources = [];
  @Input() questionSourceId: any;
  @Input() DifficultyLevels: any;
  @Input() difficultyLevelId: any;
  @Input() QuestionTypes = [];
  @Input() questionTypeId: any;
  @Input() ReviewRequirements = [];
  @Input() reviewRequirementId: any;
  base64textQuestionString = "";
  questionImage: any;
  sollutionText = "";
  questionText = "";
  constructor(private domSanitizer: DomSanitizer, private integerTypeQuestionService: IntegerTypeQuestionService) { }
  questionOptionIndex: any;
  @Input() previousData: any;
  questionId: any
  showAlert = false;
  searchText: any;

  alertMsg: any;
  alertType: any;
  roleId: any;
  questionAnswer: any;
  @Input() QuestionInterpretation = [];
  questionInterpretationId: any;
  @Input() TimeOfQuestion = [];
  timeOfQuestionId: any;
  isAddRejectionCommentModal = false;
  rejectComment: any;
  @Input() tabId: any;
  questionStatusId: any
  purpose = "Add";
  imageType: any;
  base64textSolutionString = "";
  solutionImage: any;
  questionCode: any;
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'id_question_source',
    textField: 'name',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    closeDropDownOnSelection: true
  };
  selectedPatternItems = [];
  firstTime = true;  
  ngOnInit() {
    this.orignalQuestionSources = this.QuestionSources;
    this.roleId = localStorage.getItem("id_role");
    this.timeOfQuestionId = "0";
    this.questionInterpretationId = "0";
    this.questionStatusId = "12"
    if (this.previousData) {
      this.purpose = "Edit"
      this.questionId = this.previousData.id_question;
      this.questionText = this.previousData.question_text;
      this.questionImage = this.getImage(this.previousData.question_image);
      this.base64textQuestionString = this.previousData.question_image;
      this.solutionImage = this.getImage(this.previousData.solution_image);
      this.base64textSolutionString = this.previousData.solution_image;
      this.sollutionText = this.previousData.sollution_text;
      this.subjectId = this.previousData.id_subject;
      this.questionFor = this.previousData.question_for;
      this.filterSuSuject();
      this.subSubjectId = this.previousData.id_sub_subject;
      this.filterTopics();
      this.topicId = this.previousData.id_topic;
      this.filterSubTopics();
      this.subTopicId = this.previousData.id_sub_topic;
      this.questionSourceId = this.previousData.id_question_source;
      this.questionStatusId = this.previousData.is_approved;
      this.questionCode = this.previousData.question_code;
      if (this.previousData.id_question_interpretation == null) {
        this.questionInterpretationId = "0"
      }
      else {
        this.questionInterpretationId = this.previousData.id_question_interpretation;
        this.QuestionSources.forEach(item => {
          if (item.id_question_source == this.questionSourceId) {
            this.selectedPatternItems.push({ item_id: this.questionSourceId, name: item.name });
          }
        });

      }
      if (this.previousData.id_time_for_question == null) {
        this.timeOfQuestionId = "0"
      }
      else {
        this.timeOfQuestionId = this.previousData.id_time_for_question
      }
      if (this.previousData.id_difficult_level == null) {
        this.difficultyLevelId = "0";
      }
      else {
        this.difficultyLevelId = this.previousData.id_difficult_level;
      }
      if (this.previousData.id_review_requirement == null) {
        this.reviewRequirementId = "0";
      }
      else {
        this.reviewRequirementId = this.previousData.id_review_requirement;
      }

      this.questionTypeId = this.previousData.id_question_pattern;
      this.questionAnswer = this.previousData.answer
      // this.filterSuSuject();
      // this.filterTopics();
      // this.filterSubTopics();
    }
    else {

      this.QuestionSources.forEach(item => {
        if (item.id_question_source == this.questionSourceId) {
          this.selectedPatternItems.push({ id_question_source: this.questionSourceId, name: item.name });
        }
      });


      this.filterSuSuject();
      this.filterTopics();
      this.filterSubTopics();
    }
  }



  public json = {
    plugins: "powerpaste imagetools",
    powerpaste_allow_local_images: true,
    readonly: 1,
    toolbar: "sizeselect | bold italic | fontselect |  fontsizeselect|charmap",
  }

  filterSuSuject() {
    this.SubSubjects = [];
    //this.subjectId)
    for (let data of this.UnFiltterdSubSubjects) {
      if (this.subjectId == data.id_subject) {
        this.SubSubjects.push(data)
      }
    }
    this.subSubjectId = "0"
    this.topicId = "0"
    this.subTopicId = "0"
  }

  filterTopics() {
    this.Topics = [];
    for (let data of this.UnfiltterdTopics) {
      if (this.subSubjectId == data.id_sub_subject) {
        this.Topics.push(data)
      }
    }

    this.topicId = "0"
    this.subTopicId = "0"
  }
  filterSubTopics() {
    this.SubTopics = [];
    for (let data of this.UnfilttersSubTopics) {
      if (this.topicId == data.id_topic) {
        this.SubTopics.push(data)
      }
    }

    this.subTopicId = "0"
  }
  handleFileSelect(evt, image) {
    var files = evt.target.files;
    var file = files[0];
    this.imageType = image;
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {

    var binaryString = readerEvt.target.result;
    if (this.imageType == "question") {
      this.base64textQuestionString = btoa(binaryString);
      var image = 'data:image/png;base64,' + this.base64textQuestionString;
      this.questionImage = this.domSanitizer.bypassSecurityTrustUrl(image);
    }
    else {
      this.base64textSolutionString = btoa(binaryString);
      var image = 'data:image/png;base64,' + this.base64textSolutionString;
      this.solutionImage = this.domSanitizer.bypassSecurityTrustUrl(image);
    }

  }

  getImage(imagString) {
    var image: any;
    if ((!imagString) || imagString == "") {
      image = "../../assets/logo.png";
      return image
    }
    else {
      image = 'data:image/png;base64,' + imagString;
      image = this.domSanitizer.bypassSecurityTrustUrl(image);
      return image
    }
  }

  getByteSize(str) {
    var size = (new Blob([str]).size * .001).toFixed(2);
    return Number(size);
  }

  saveQuestion() {

    var invalidQuesionTextSize = false;
    if (+this.getByteSize(this.questionText) > 64) {
      invalidQuesionTextSize = true
    }

    var invalidSolutionTextSize = false;
    if (this.sollutionText) {
      if (+this.getByteSize(this.sollutionText) > 64) {
        invalidSolutionTextSize = true
      }
    }

    if (this.subjectId == "0") {
      swal.fire("Please select subject.");
    }
    else if (this.subSubjectId == "0") {
      swal.fire("Please select sub-subject.");
    }
    else if (this.topicId == "0") {
      swal.fire("Please select topic.");
    }
    else if (this.subTopicId == "0") {
      swal.fire("Please select sub-topic.");
    }
    else if (this.questionSourceId == "0") {
      swal.fire("Please select question source.");
    }

    else if (!(this.questionText)) {
      swal.fire("Question text are compulsory");
    }
    else if (!(this.questionCode)) {
      swal.fire("Question tag  are compulsory");
    }
    else if (!this.questionAnswer) {
      swal.fire("Please enter answer.")
    }
    else if (!(this.sollutionText)) {
      swal.fire("Solution text  are compulsory.")
    }
    else if (invalidQuesionTextSize || invalidSolutionTextSize) {
      swal.fire("Allowed size exceeded. Please reduce text/image size for fields with validation errors.")
    }
    else {
      if (!this.questionId) {
        let json = {
          "caller_email": localStorage.getItem("email"),
          "id_subject": this.subjectId,
          "id_sub_subject": this.subSubjectId,
          "id_topic": this.topicId,
          "id_sub_topic": this.subTopicId,
          "id_question_source": this.questionSourceId,
          "id_question_pattern": this.questionTypeId,
          "question_text": this.questionText,
          "question_image": this.base64textQuestionString,
          "answer": this.questionAnswer,
          "sollution_text": this.sollutionText,
          "solution_image": this.base64textSolutionString,
          "question_code": this.questionCode,
          "question_for": this.questionFor
        }
        this.showLoader = true;
        this.integerTypeQuestionService.addQuestion(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.showAlertComponent(data['status_code'], data['status_message']);
              this.hideQuestionModal();
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
      else {
        let json = {
          "caller_email": localStorage.getItem("email"),
          "id_question": this.questionId,
          "id_subject": this.subjectId,
          "id_sub_subject": this.subSubjectId,
          "id_topic": this.topicId,
          "id_sub_topic": this.subTopicId,
          "id_question_source": this.questionSourceId,
          "id_question_pattern": this.questionTypeId,
          "question_text": this.questionText,
          "question_image": this.base64textQuestionString,
          "answer": this.questionAnswer,
          "question_code": this.questionCode,
          "sollution_text": this.sollutionText,
          "solution_image": this.base64textSolutionString,
          "question_for": this.questionFor,
          "purpose": "edit"
        }
        this.showLoader = true;
        this.integerTypeQuestionService.editQuestion(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.showAlertComponent(data['status_code'], data['status_message']);
              this.hideQuestionModal('edit');
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }

    }
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }
  hideQuestionModal(forData?: string) {
    this.isHidden.emit(forData);
  }

  approved() {
    if (this.subjectId == "0") {
      swal.fire("Please select subject.");
    }
    else if (this.subSubjectId == "0") {
      swal.fire("Please select sub-subject.");
    }
    else if (this.topicId == "0") {
      swal.fire("Please select topic.");
    }
    else if (this.subTopicId == "0") {
      swal.fire("Please select sub-topic.");
    }
    else if (this.questionSourceId == "0") {
      swal.fire("Please select question source.");
    }
    else if (this.difficultyLevelId == "0") {
      swal.fire("Please select question difficulty level.");
    }
    else if (this.reviewRequirementId == "0") {
      swal.fire("Please select review frequency.");
    }
    else if (this.questionInterpretationId == "0") {
      swal.fire("Please select question interpretation.");
    }
    else if (this.timeOfQuestionId == "0") {
      swal.fire("Please select Time to solve.");
    }
    else if (!(this.questionText) && this.base64textQuestionString == "") {
      swal.fire("Question text or Question image are compulsory");
    }
    else if (!this.questionAnswer) {
      swal.fire("Please enter answer.")
    }
    else if (!(this.sollutionText) && this.base64textSolutionString == "") {
      swal.fire("Solution text or Solution image are compulsory.")
    }
    else {
      let json = {
        "caller_email": localStorage.getItem("email"),
        "id_question": this.questionId,
        "id_subject": this.subjectId,
        "id_sub_subject": this.subSubjectId,
        "id_topic": this.topicId,
        "id_sub_topic": this.subTopicId,
        "id_question_source": this.questionSourceId,
        "id_difficult_level": this.difficultyLevelId,
        "id_question_pattern": this.questionTypeId,
        "id_review_requirement": this.reviewRequirementId,
        "question_text": this.questionText,
        "question_image": this.base64textQuestionString,
        "answer": this.questionAnswer,
        "sollution_text": this.sollutionText,
        "id_time_for_question": this.timeOfQuestionId,
        "solution_image": this.base64textSolutionString,
        "id_question_interpretation": this.questionInterpretationId,
        "question_code": this.questionCode,
        "question_for": this.questionFor,
        "purpose": "approved"
      }
      this.showLoader = true;
      this.integerTypeQuestionService.editQuestion(json).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.showAlertComponent(data['status_code'], data['status_message']);
            this.hideQuestionModal('approved');
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }
  }

  hideRejectionCommentModal() {
    this.isAddRejectionCommentModal = false
  }
  reject() {
    this.isAddRejectionCommentModal = true

  }

  saveComment() {
    if (!this.rejectComment) {
      swal.fire("Please enter comment")
    }
    else {
      let json = {
        "caller_email": localStorage.getItem("email"),
        "id_question": this.questionId,
        "comment": this.rejectComment
      }
      this.showLoader = true;
      this.integerTypeQuestionService.rejectComment(json).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.showAlertComponent(data['status_code'], data['status_message']);
            this.hideQuestionModal();
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }
  }

  filterSource() {
    this.QuestionSources = [];

    if (this.idCourseType == 14)
      this.QuestionSources = this.orignalQuestionSources;

    if (this.idCourseType == 15) {
      this.orignalQuestionSources.
        forEach(source => {
          if (source['source_for'] != 16) {
            this.QuestionSources.push(source);
          }
        });
    }

    if (this.idCourseType == 16) {

      this.orignalQuestionSources.
        forEach(source => {
          if (source['source_for'] != 15) {
            this.QuestionSources.push(source);
          }
        });

    }

    if(this.firstTime){
      var flag = false; 
      this.selectedPatternItems = [];
      this.QuestionSources.forEach(item => {
        if (item.id_question_source == this.questionSourceId) {
          this.selectedPatternItems.push({ id_question_source: this.questionSourceId, name: item.name });
          flag = true;
        }
      });

      if(!flag)
        this.questionSourceId = '0';

      this.firstTime = false;  
    }else{
      this.questionSourceId = '0';
      this.selectedPatternItems = [];
    }
  }

  onItemDeSelectQuestionSource() {
    this.questionSourceId = 0;
  }

  onItemSelectQuestionSource(event) {
    console.log("questionSourceId=" + event.id_question_source);
    this.questionSourceId = event.id_question_source;
  }

}
