import { Component, OnInit } from '@angular/core';
import { AdminAnalysisReportService } from './admin-analysis-reports.service'
import { Router } from '@angular/router'
import { Helper } from '../helper'
import swal from 'sweetalert2'
import { isThisHour } from 'date-fns';
import { DatePipe } from '@angular/common'
@Component({
  selector: 'app-admin-analysis-reports',
  templateUrl: './admin-analysis-reports.component.html',
  styleUrls: ['./admin-analysis-reports.component.css'],
  providers: [AdminAnalysisReportService, DatePipe]
})
export class AdminAnalysisReportsComponent implements OnInit {
  showLoader = false;
  Coes = [];
  coeId = "0";
  Batches = [];
  unfilteredBatches = [];
  batchId = "0";
  Sections = [];
  sectionId = "0";
  unfilterdSections = [];
  AnalysisReport = [];
  scholarDeatils: any;
  isViewAnalysisDeatils = false
  CourseTypeTable = [];
  iitInstitutes = [];
  niitInstitutes = [];
  neetInstitutes = [];
  examTables = [];
  examTablesHeading = [];
  examTableFormattedData = [];
  keys = [];
  scholarId;
  ReportsCard = []
  isViewReportCardModel = false
  roleId: any;
  filerStartDate: any;
  filerEndDate: any;
  unfilterdScholarReport = [];
  totalMarks: any;
  positiveMarks: any;
  negativeMarks: any;
  effectiveMarks: any;
  isPrintAllReportCard = false
  maxExamNumber: any;
  AllReportCards = [];
  public examScorebarChartLabels: String[];
  public examScorebarChartType: string;
  public examScorebarChartLegend = false;
  public examScorebarChartData: any[];
  scholarPerformance = [];
  lineScholarChartData = [];
  lineScholarChartLabels = [];
  lineChartLegend = false;
  column: string = 'CategoryName';
  direction: number;
  isDesc: boolean = false;
  unfilteredIitInstitutes = [];
  unfilteredNitInstitutes = [];
  unfilteredNeetInstitutes = [];
  examLimit = "";
  showCanvasImage = false
  showCanvas = true;
  courseTypeId: any;
  analysisDate = "";
  constructor(private adminAnalysisReportService: AdminAnalysisReportService, private router: Router, private datePipe: DatePipe) {
    if (localStorage.getItem("isLogin") == "true" ) {
      this.roleId = localStorage.getItem("id_role")
      console.log(this.roleId)
      this.getAllCoe();
    }
    else {
      this.router.navigateByUrl('/');
    }
  }


  ngOnInit() {
  }

  getAllCoe() {
    this.showLoader = true;
    this.adminAnalysisReportService.getAllCoe(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.Coes = data.coes
          this.getAllBatches();
        }
        else {
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllBatches() {
    this.showLoader = true;
    this.adminAnalysisReportService.getAllBatches(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.Batches = data.batches
          this.unfilteredBatches = data.batches
          this.getAllSections();
        }
        else {
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllSections() {
    this.showLoader = true;
    this.adminAnalysisReportService.getAllSections(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.Sections = data.sections
          this.unfilterdSections = data.sections
        }
        else {
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  filterExam() {

    this.AllReportCards = [];
    this.AnalysisReport = [];
    this.Batches = []
    this.Sections = []
    if (this.coeId != "0") {
      for (let data of this.unfilteredBatches) {
        if (data.id_coe == this.coeId) {
          this.Batches.push(data);
        }
      }
    }
    if (this.batchId != "0") {
      this.sectionId = "0"
      for (let data of this.unfilterdSections) {

        if (data.id_batch == this.batchId) {
          this.courseTypeId = data.id_course_type
          this.Sections.push(data);
        }
      }

    }
  }

  getReports(callFor) {
    if(!this.examLimit){
     swal.fire("Please select exam limit.")
    }
    if (this.sectionId != "0") {
      this.showLoader = true;
      if (callFor != "regenerate_analysis") {
        this.AnalysisReport = [];
        this.analysisDate = ""

      }

      this.adminAnalysisReportService.genearateScholarAnalysis(localStorage.getItem("email"), this.sectionId, this.examLimit, callFor).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            if (callFor != "regenerate_analysis") {
              this.AnalysisReport = data.analysis
              this.analysisDate = data.analysis_date
            }

          }
          else if (data.status_code == "2") {
            swal.fire(data.status_message)
          }
          else {
            swal.fire(data.status_message)
          }
        },
        err => {

        }
      )
    }
  }
  viewDetails(data) {
    this.examLimit="5";
    this.scholarDeatils = data;
    this.isViewAnalysisDeatils = true
    this.scholarId = data.id_user
    this.showLoader = true;
    this.adminAnalysisReportService.getScholarAnaysisReport(localStorage.getItem("email"), this.sectionId, this.examLimit, data.id_user).subscribe(
      data => {
        this.showLoader = false;
        this.CourseTypeTable = [];
        this.iitInstitutes = [];
        this.niitInstitutes = [];
        this.neetInstitutes = [];
        this.examTables = [];
        this.examTablesHeading = [];
        this.scholarPerformance = [];
        if (data.status_code == "0") {
          this.CourseTypeTable = data.course_type_tabel
          this.unfilteredIitInstitutes = data.possible_iit_institute
          this.unfilteredNitInstitutes = data.possible_niit_institute
          this.unfilteredNeetInstitutes = data.possible_neet_institute
          this.examTables = data.exams
          this.examTablesHeading = data.exam_table_heading
          this.scholarPerformance = data.scholar_performance
          console.log(this.scholarPerformance);

          if (this.unfilteredIitInstitutes.length > 5) {
            for (var i = 0; i < 6; i++) {
              this.iitInstitutes.push(this.unfilteredIitInstitutes[i])
            }
          }
          else {
            this.iitInstitutes = this.unfilteredIitInstitutes
          }

          if (this.unfilteredNitInstitutes.length > 5) {
            for (var i = 0; i < 6; i++) {
              this.niitInstitutes.push(this.unfilteredNitInstitutes[i])
            }
          }
          else {
            this.niitInstitutes = this.unfilteredNitInstitutes
          }
          if (this.unfilteredNeetInstitutes.length > 5) {
            for (var i = 0; i < 6; i++) {
              this.neetInstitutes.push(this.unfilteredNeetInstitutes[i])
            }
          }
          else {
            this.neetInstitutes = this.unfilteredNeetInstitutes
          }

          this.generateExamTableData();
          this.generateScholarPerformanceGraph();
        }
        else {
          swal.fire(data.status_message)
        }
      },
      err => {

      }
    )
  }

  changeExamLimit() {
    this.showLoader = true;
    this.examScorebarChartLegend = false;
    this.lineChartLegend = false
    this.adminAnalysisReportService.getScholarAnaysisReport(localStorage.getItem("email"), this.sectionId, this.examLimit, this.scholarId).subscribe(
      data => {
        this.showLoader = false;
        this.CourseTypeTable = [];
        this.iitInstitutes = [];
        this.niitInstitutes = [];
        this.neetInstitutes = [];
        this.examTables = [];
        this.examTablesHeading = [];
        this.scholarPerformance = [];
        if (data.status_code == "0") {
          this.CourseTypeTable = data.course_type_tabel
          this.unfilteredIitInstitutes = data.possible_iit_institute
          this.unfilteredNitInstitutes = data.possible_niit_institute
          this.unfilteredNeetInstitutes = data.possible_neet_institute
          this.examTables = data.exams
          this.examTablesHeading = data.exam_table_heading
          this.scholarPerformance = data.scholar_performance

          if (this.unfilteredIitInstitutes.length > 5) {
            for (var i = 0; i < 6; i++) {
              this.iitInstitutes.push(this.unfilteredIitInstitutes[i])
            }
          }
          else {
            this.iitInstitutes = this.unfilteredIitInstitutes
          }

          if (this.unfilteredNitInstitutes.length > 5) {
            for (var i = 0; i < 6; i++) {
              this.niitInstitutes.push(this.unfilteredNitInstitutes[i])
            }
          }
          else {
            this.niitInstitutes = this.unfilteredNitInstitutes
          }
          if (this.unfilteredNeetInstitutes.length > 5) {
            for (var i = 0; i < 6; i++) {
              this.neetInstitutes.push(this.unfilteredNeetInstitutes[i])
            }
          }
          else {
            this.neetInstitutes = this.unfilteredNeetInstitutes
          }

          this.generateExamTableData();
          this.generateScholarPerformanceGraph();
        }
        else {
          swal.fire(data.status_message)
        }
      },
      err => {

      }
    )
  }

  hideAddEditModal() {
    this.isViewAnalysisDeatils = false;
    this.isViewReportCardModel = false;
    this.isPrintAllReportCard = false;
  }


  generateScholarPerformanceGraph() {
    this.lineScholarChartLabels = [];
    this.lineScholarChartData = [];
    for (let data of this.scholarPerformance) {
      this.lineScholarChartLabels.push(data.exam_name)
    }
    var highestMarks = []
    var gain_marks = []
    var average_marks = []
    for (let data of this.scholarPerformance) {
      highestMarks.push(parseInt(data.highest_marks))
      gain_marks.push(parseInt(data.gain_marks))
      average_marks.push(parseInt(data.average_marks))
    }

    this.lineScholarChartData = [
      { data: gain_marks, label: 'Mark Secured' },
      { data: average_marks, label: 'Class Average' },
      { data: highestMarks, label: 'Class Topper Marks' }
    ];
    this.lineChartLegend = true
  }
  generateExamTableData() {
    var testdata = [];
    this.examTableFormattedData = [];

    for (let exam of this.examTables) {
      var data = {};
      data['test_name'] = exam.exam_name;
      data['exam_marks'] = exam.total_marks;

      if (exam.exam_mode == "1") {
        data['exam_mode'] = "Online";
      }
      else {
        data['exam_mode'] = "Offline";
      }
      data['exam_date'] = this.datePipe.transform(exam.exam_date, "MMM d, y");

      var totalMarks = 0;
      for (let ed of exam.exam_deatils) {
        for (let heading of this.examTablesHeading) {
          if (heading.id == ed.id_subject) {
            data[heading.name] = (+ed.effective_marks).toFixed(2);
            totalMarks = totalMarks + (+ed.effective_marks)

          }
          else {
            if (!data[heading.name]) {
              data[heading.name] = '-';
            }

          }
        }
      }
      data['total_score'] = totalMarks.toFixed(2);

      var per = (totalMarks * 100) / (+exam.total_marks)
      data['per'] = per.toFixed(2);
      this.examTableFormattedData.push(data);
    }
    this.keys = Object.keys(this.examTableFormattedData[0])
    this.examTableFormattedData.sort((a, b) => (new Date(a.exam_date) > new Date(b.exam_date) ? -1 : 1));
    this.generateExamScoreGraph();
  }
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,

    scales: {
      yAxes: [{
        ticks: {
          min: 0,
          max: 100,
          stepSize: 25
        }
      }]
    }
  };

  generateExamScoreGraph() {
    this.examScorebarChartType = 'bar';

    this.examScorebarChartLabels = [];
    this.examScorebarChartData = []
    for (let data of this.examTableFormattedData) {
      this.examScorebarChartLabels.push(data.test_name)
    }

    let jsonTotalArray = [];
    for (let subject of this.examTablesHeading) {
      let jsonArray = [];

      for (let data of this.examTableFormattedData) {
        var subjectObtainedMarks = 0;
        var examMarks = 0;
        var countSubject = 0;
        for (let key of this.keys) {
          if (key == subject.name) {
            if (data[key] != "-") {
              countSubject++
            }
          }


        }

        for (let key of this.keys) {
          examMarks = (parseInt(data["exam_marks"]))
          let subjectMarks = (parseInt(data["exam_marks"])) / countSubject;
          if (key == subject.name) {
            if (data[key] != "-") {
              subjectObtainedMarks = subjectObtainedMarks + data[key]
              let subjectMarksPercentage = (data[key] * 100) / subjectMarks
              if (subjectMarksPercentage != null) {
                // subjectMarks=subjectMarks+data[key]
                jsonArray.push(subjectMarksPercentage.toFixed(2))

              }
              else {
                jsonArray.push(subjectMarksPercentage)

              }
            }
            else {
              jsonArray.push(0)
            }



          }
          if (key == "total_score") {
            var obtainedTotalPercentage = ((parseInt(data[key])) * 100 / data["exam_marks"]).toFixed(2);
            jsonTotalArray.push(obtainedTotalPercentage)

          }

        }

      }

      let json = {
        data: jsonArray, label: subject.name
      }
      this.examScorebarChartData.push(json);

    }
    let jsonSecond = {
      data: jsonTotalArray, label: "Total"
    }
    this.examScorebarChartData.push(jsonSecond);
    //.log(JSON.stringify(this.examScorebarChartData))
    this.examScorebarChartLegend = true;
    //.log(this.examScorebarChartLabels)
  }
  printReportCard() {
    this.isViewReportCardModel = true
    this.ReportsCard = []
    this.showLoader = true;
    if (this.roleId == "1") {
      this.adminAnalysisReportService.getScholarReportCard(localStorage.getItem("email"), "5", this.scholarId).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.ReportsCard = data.exam
          }
          else {
            swal.fire(data.status_message)
          }
        },
        err => {

        }
      )
    }
    else {
      this.adminAnalysisReportService.getScholarAllReportCard(localStorage.getItem("email"), "5", this.scholarId).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.ReportsCard = data.exam
            this.unfilterdScholarReport = data.exam
            this.getReportTotal();
          }
          else {
            swal.fire(data.status_message)
          }
        },
        err => {

        }
      )
    }

  }

  print() {


    let printContents, popupWin;



    var canvas = document.getElementById("canvasScholar") as HTMLCanvasElement;
    var img = this.getImageUrl("canvasScholar");

    printContents = document.getElementById('printSection').innerHTML;
    var htmlToPrint = '' +
      '<style type="text/css">' +
      'table th, table td {' +
      'border:1px solid #000;' +
      'padding;0.5em;' +
      '}' +
      '</style>';
    htmlToPrint += printContents;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
          <html>
              <head>
                 
                  <style>
                     #line-graph{
                       display:none;
                     }
                  </style>
              </head>
              <body onload="window.print();window.close()">${htmlToPrint}
              <h5>My Performance</h5>
              <img src="${img}"/>
              </body>
          </html>`
    );

    popupWin.document.close();
  }


  filterReport() {
    this.ReportsCard = [];
    if (this.filerStartDate && this.filerEndDate) {
      for (let data of this.unfilterdScholarReport) {
        if (data.exam_conducted_date >= this.filerStartDate && data.exam_conducted_date <= this.filerEndDate) {
          this.ReportsCard.push(data)
        }
      }
    }
    else if (this.filerStartDate) {
      for (let data of this.unfilterdScholarReport) {
        if (data.exam_conducted_date >= this.filerStartDate) {
          this.ReportsCard.push(data)
        }
      }
    }
    else if (this.filerEndDate) {
      for (let data of this.unfilterdScholarReport) {
        if (data.exam_conducted_date <= this.filerEndDate) {
          this.ReportsCard.push(data)
        }
      }
    }
    else {
      this.ReportsCard = this.unfilterdScholarReport
    }
    this.getReportTotal();
  }

  getReportTotal() {
    this.totalMarks = 0.0;
    this.positiveMarks = 0.0;
    this.negativeMarks = 0.0;
    this.effectiveMarks = 0.0;
    for (let data of this.ReportsCard) {
      this.totalMarks = this.totalMarks + (+data.exam_marks)
      this.positiveMarks = this.totalMarks + (+data.positive_mark)
      this.negativeMarks = this.totalMarks + (+data.negative_mark)
      this.effectiveMarks = this.totalMarks + (+data.effective_marks)
    }
  }

  openPrintReportCardModel() {
    this.maxExamNumber = "5"
    this.isPrintAllReportCard = true;
    this.AllReportCards = [];
  }

  printAllReportCard() {
    if (this.sectionId == "0") {
      swal.fire("Please select section.")
    }
    else {
      this.showLoader = true
      this.AllReportCards = [];
      const that = this;
      this.adminAnalysisReportService.getAllScholarReportCard(localStorage.getItem("email"), "5", this.sectionId).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.AllReportCards = data.report_card

            setTimeout(function () {

            }, 1000);
          }
          else {
            swal.fire(data.status_message)
          }
        },
        err => {

        }
      )
    }

  }

  printAllScholarReports() {

    this.showCanvasImage = true;


    setTimeout(
      function () {
        let printContents, popupWin;
        printContents = document.getElementById('printAllRecords').innerHTML;

        var htmlToPrint = '' +
          '<style type="text/css">' +
          'table th, table td {' +
          'border:1px solid #000;' +
          'padding;0.5em;' +

          '}' +
          '.canvas-div{' +
          'display:none !important' +
          '}' +
          '.image-div{' +
          'display:block !important' +
          '}' +
          '@media print{' +
          '.page-break	{ display: block; page-break-after: always; }' +
          '}' +
          '</style>';
        htmlToPrint += printContents;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();

        popupWin.document.write(`
        <html>
            <head>
               
                <style>
                 
                </style>
            </head>
            <body onload="window.print();window.close()">${htmlToPrint}
           
            </body>
        </html>`
        );
        this.showCanvasImage = false

        popupWin.document.close();

      }, 300
    );
  }


  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.AnalysisReport.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  }

  viewAllIITInstitutes() {
    this.iitInstitutes = this.unfilteredIitInstitutes
  }
  viewAllNITInstitutes() {
    this.niitInstitutes = this.unfilteredNitInstitutes
  }
  viewAllNEETInstitutes() {
    this.neetInstitutes = this.unfilteredNeetInstitutes
  }

  getLineChartLabels(perforamnce) {
    var lineScholarChartLabels = []
    for (let data of perforamnce) {
      lineScholarChartLabels.push(data.exam_name)
    }

    return lineScholarChartLabels
  }

  getLineChartData(perforamnce) {


    var lineScholarChartData = []

    var highestMarks = []
    var gain_marks = []
    var average_marks = []
    for (let data of perforamnce) {
      highestMarks.push(parseInt(data.highest_marks))
      gain_marks.push(parseInt(data.gain_marks))
      average_marks.push(parseInt(data.average_marks))
    }

    lineScholarChartData = [
      { data: gain_marks, label: 'Mark Secured' },
      { data: average_marks, label: 'Class Average' },
      { data: highestMarks, label: 'Class Topper Marks' }
    ];
    return lineScholarChartData
  }

  checkGrpahIsShowOrNot(data) {
    var tempOne = this.getLineChartData(data)
    var tempSecond = this.getLineChartLabels(data)
    if (tempOne.length > 0 && tempSecond.length > 0) {
      this.showCanvas = true;
      return true;
    }
    else {
      this.showCanvas = false;
      return false;
    }
  }

  getImageUrl(dataId) {
    var canvas = document.getElementById(dataId) as HTMLCanvasElement;
    var img = canvas.toDataURL("image/png");
    return img
  }


  public lineChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          min: 0,
          max: 100,
          stepSize: 10
        }
      }]
    }
  };
  public lineChartColors: Array<any> = [
    { // blue
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(123,104,238)',
      pointBackgroundColor: 'rgba(123,104,238)',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(123,104,238)'
    },
    { // purple
      backgroundColor: 'rgb(157, 68, 122)',
      borderColor: 'rgb(157, 68, 122)',
      pointBackgroundColor: 'rgb(157, 68, 122)',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(157, 68, 122)'
    },
    { // green
      backgroundColor: 'rgb(66, 142, 99)',
      borderColor: 'rgb(66, 142, 99)',
      pointBackgroundColor: 'rgb(66, 142, 99)',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(66, 142, 99)'
    },

    { // green
      backgroundColor: 'rgb(254, 119, 123)',
      borderColor: 'rgb(254, 119, 123)',
      pointBackgroundColor: 'rgb(254, 119, 123)',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(254, 119, 123)'
    }

  ];
  public lineChartType: string = 'line';

}
