import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class TopicMasterService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }

    addSubject(jsonData) {
        //(JSON.stringify(jsonData))
        return this.http.post(this.baseUrl + "Topic/addNewSubject", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }
    editSubject(jsonData) {
        //(JSON.stringify(jsonData))
        return this.http.post(this.baseUrl + "Topic/editSubject", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllSubject(email) {
        return this.http.get(this.baseUrl + "/Topic/getAllSubject?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    addSubSubject(jsonData) {
        //(JSON.stringify(jsonData))
        return this.http.post(this.baseUrl + "Topic/addNewSubSubject", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }
    editSubSubject(jsonData) {
        //(JSON.stringify(jsonData))
        return this.http.post(this.baseUrl + "Topic/editSubSubject", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllSubSubject(email) {
        return this.http.get(this.baseUrl + "/Topic/getAllSubSubject?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }


    addTopic(jsonData) {
        //(JSON.stringify(jsonData))
        return this.http.post(this.baseUrl + "Topic/addNewTopic", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }
    editTopic(jsonData) {
        //(JSON.stringify(jsonData))
        return this.http.post(this.baseUrl + "Topic/editTopic", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllTopic(email) {
        return this.http.get(this.baseUrl + "/Topic/getAllTopic?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeSubject(jsonData) {
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Topic/removeSubject", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }
    removeSubSubject(jsonData) {
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Topic/removeSubSubject", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }
    removeTopic(jsonData) {
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Topic/removeTopic", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllSubTopic(email) {
        return this.http.get(this.baseUrl + "/Topic/getAllSubTopic?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    addNewSubTopic(jsonData) {
        //.log(JSON.stringify(jsonData))
        return this.http.post(this.baseUrl + "Topic/addNewSubTopic", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }
    editSubTopic(jsonData) {
        //.log(JSON.stringify(jsonData))
        return this.http.post(this.baseUrl + "Topic/editSubTopic", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeSubTopic(jsonData) {
        //.log(JSON.stringify(jsonData))
        return this.http.post(this.baseUrl + "Topic/removeSubTopic", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    saveTopicWeightage(jsonData) {
        //.log(JSON.stringify(jsonData))
        return this.http.post(this.baseUrl + "Topic/saveTopicWeightage", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    saveSubSubjectWeightage(jsonData) {
        //.log(JSON.stringify(jsonData))
        return this.http.post(this.baseUrl + "Topic/saveSubSubjectWeightage", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }
}