import { Component, OnInit } from '@angular/core';
import { FacultyFeedbackRequestService } from './faculty-feedback-analysis.service'
import { Router, ActivatedRoute } from '@angular/router'
import swal from 'sweetalert2';
@Component({
  selector: 'app-faculty-feedback-analysis',
  templateUrl: './faculty-feedback-analysis.component.html',
  styleUrls: ['./faculty-feedback-analysis.component.css'],
  providers: [FacultyFeedbackRequestService]
})
export class FacultyFeedbackAnalysisComponent implements OnInit {
  facultyName: any;
  feedbackRequestDate: any;
  showLoader = false;
  facultyId: any;
  facultyFeedbackRequestId: any;
  netScore: any;
  netRating: any;
  FeedbackHistory = [];
  FeedBackQuestionType: any
  barChartLabel = [];
  barChartData = [];
  pieChartLabel = [];
  pieChartData = [];
  pieData: any;
  barData = [];
  isShowSectionWiseraph = false
  isShowSectionWiseRating = false;
  SectinWiseRatingArray = []
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private facultyFeedbackRequestService: FacultyFeedbackRequestService) {
    if (localStorage.getItem("isLogin") == "true") {
      this.activatedRoute.params.subscribe(params => {
        this.facultyId = params.id_faculty;
        this.facultyFeedbackRequestId = params.id_faculty_feedback_request;
        this.facultyName = params.faculty_name;
        this.feedbackRequestDate = params.feedback_date;
        this.getFeedbackHistory();

      });


    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  getFeedbackHistory() {
    this.showLoader = true;
    this.facultyFeedbackRequestService.getFeedbackDetailsGraph(localStorage.getItem("email"), this.facultyId, this.facultyFeedbackRequestId).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.netScore = data.net_score
          this.netRating = data.net_rating_percentage
          this.SectinWiseRatingArray = data.section_rating
          this.FeedbackHistory = data.feedback_questions_rating
        }
        else {
          swal.fire(data.status_message)
        }
      },
      err => {
        this.showLoader = false;
      }
    )
  }


  getAnswerTypeTwoLabel(data) {
    var tempArray = []
    for (let d of data) {
      tempArray.push(d.label)
    }
    return tempArray
  }
  getAnswerTypeTwoData(data) {
    var tempArray = []
    for (let d of data) {
      tempArray.push(d.data)
    }
    let json = {
      data: tempArray, label: "score"
    }
    var array = [];
    array.push(json)
    return array
  }

  getAnswerTypeOneData(data) {
    var tempArray = []
    for (let d of data) {
      tempArray.push(d.label)
    }
    return tempArray
  }

  getAnswerTypeOneLabel(data) {
    var tempArray = []

    for (let d of data) {
      tempArray.push(d.data)
    }
    return tempArray
  }
  viewDetails(data) {
    this.pieData = [];
    this.barData = [];
    this.FeedBackQuestionType = data.answer_type
    if (data.answer_type == "1") {
      this.pieData = data.section_wise_graph_data
    }
    else {
      this.barData = data.section_wise_graph_data
    }
    this.isShowSectionWiseraph = true;
    //.log(JSON.stringify(this.barData))

  }


  hideModal() {
    this.isShowSectionWiseraph = false;
    this.isShowSectionWiseRating = false;
  }

  viewSectionWiseRating() {
    this.isShowSectionWiseRating = true;
  }

  onBackPress() {
    this.router.navigate(['./feedback-history'], { queryParams: { facultyId: this.facultyId, facultyFedbackRequestId: this.facultyFeedbackRequestId, facultyName: this.facultyName } });

  }

  print() {

    setTimeout(
      function () {
        let printContents, popupWin;
        printContents = document.getElementById('printSection').innerHTML;
        //  document.getElementsByClassName("image-div").style.pageBreakAfter = "always";
        var htmlToPrint = '' +
          '<style type="text/css">' +
          'table th, table td {' +
          'border:1px solid #000;' +
          'padding;0.5em;' +

          '}' +
          '.canvas-div{' +
          'display:none !important' +
          '}' +
          '.image-div{' +
          'display:block !important' +
          '}' +
          '@media print{' +
          '.page-break	{ display: block; page-break-after: always; }' +
          '}' +
          '</style>';
        htmlToPrint += printContents;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();

        popupWin.document.write(`
        <html>
            <head>
               
                <style>
                 
                </style>
            </head>
            <body onload="window.print();window.close()">${htmlToPrint}
           
            </body>
        </html>`
        );


        // popupWin.document.write(htmlToPrint);
        this.showCanvasImage = false

        popupWin.document.close();

      }, 300
    );
  }

  getImageUrl(dataId) {
    var canvas = document.getElementById(dataId) as HTMLCanvasElement;
    ////.log(canvas)
    var img = canvas.toDataURL("image/png");
    return img
  }

  public barChartType: string = 'bar';
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          min: 0,
          max: 100,
          stepSize: 10
        }
      }]
    }
  };
  public chartColors: Array<any> = [
    { // blue
      backgroundColor: 'rgba(123,104,238)',
      borderColor: 'rgba(123,104,238)',
      pointBackgroundColor: 'rgba(123,104,238)',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(123,104,238)'
    },
    { // purple
      backgroundColor: 'rgb(157, 68, 122)',
      borderColor: 'rgb(157, 68, 122)',
      pointBackgroundColor: 'rgb(157, 68, 122)',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(157, 68, 122)'
    },
    { // green
      backgroundColor: 'rgb(66, 142, 99)',
      borderColor: 'rgb(66, 142, 99)',
      pointBackgroundColor: 'rgb(66, 142, 99)',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(66, 142, 99)'
    },

    { // orange
      backgroundColor: 'rgb(254, 119, 123)',
      borderColor: 'rgb(254, 119, 123)',
      pointBackgroundColor: 'rgb(254, 119, 123)',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(254, 119, 123)'
    }


  ];

  public chartOptions: any = {
    responsive: true
  };
  public chartClicked(e: any): void {
    //.log(e);
  }

  public chartHovered(e: any): void {
    //.log(e);
  }

  public pieChartType: string = 'pie';


  public pieChartColors: Array<any> = [
    {
      backgroundColor: [
        'rgba(123,104,238)',
        'rgb(157, 68, 122)',
        'rgb(66, 142, 99)',
        'rgb(254, 119, 123)',

      ]
    }


  ];
}
