import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuditService } from './audit.service';
import { DatePipe } from '@angular/common'
import swal from 'sweetalert2'
@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css'],
  providers: [AuditService, DatePipe]
})
export class AuditComponent implements OnInit {
  Audits = [];
  FillteredAudits = [];
  showLoader = false;
  fromDate: any
  toDate: any;
  searchText: any;
  constructor(private router: Router, private auditService: AuditService, private datePipe: DatePipe) {
    var tomorrow = new Date();
    this.fromDate = tomorrow.toISOString().split('T')[0];
    if (localStorage.getItem("isLogin") == "true") {
      this.getAllAudits()
    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  getAllAudits() {
    this.showLoader = true;
    this.FillteredAudits = [];
    this.Audits = [];
    this.auditService.getAllAudit(localStorage.getItem("email"), this.fromDate).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.Audits = data.audits
          this.FillteredAudits = data.audits
          // this.filterByDate();
        }
        else {

        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  filterByDate() {
    this.getAllAudits()
    // this.FillteredAudits = [];
    // // for (let data of this.Audits) {
    // //   if (this.datePipe.transform(data.created_tms, 'yyyy-MM-dd') == this.fromDate) {
    // //     this.FillteredAudits.push(data);
    // //   }
    // // }

    // if (this.fromDate && this.toDate) {
    //   for (let data of this.Audits) {
    //     if (this.datePipe.transform(data.created_tms, 'yyyy-MM-dd') >= this.fromDate && this.datePipe.transform(data.created_tms, 'yyyy-MM-dd') <= this.toDate) {
    //       this.FillteredAudits.push(data)
    //     }
    //   }
    // }
    // else if (this.fromDate) {
    //   for (let data of this.Audits) {
    //     if (this.datePipe.transform(data.created_tms, 'yyyy-MM-dd') >= this.fromDate) {
    //       this.FillteredAudits.push(data)
    //     }
    //   }
    // }
    // else if (this.toDate) {
    //   for (let data of this.Audits) {
    //     if (this.datePipe.transform(data.created_tms, 'yyyy-MM-dd') <= this.toDate) {
    //       this.FillteredAudits.push(data)
    //     }
    //   }
    // }
    // else {
    //   this.FillteredAudits = this.Audits
    // }
  }
}
