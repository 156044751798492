import { Component, OnInit } from '@angular/core';
import { AlumnMasterService } from './alumn-master.service';
import { DatePipe } from '@angular/common'
import swal from 'sweetalert2'
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { AnyAaaaRecord } from 'dns';
import { UploadService } from '../services/upload.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Helper } from '../helper';
@Component({
  selector: 'app-alumn-master',
  templateUrl: './alumn-master.component.html',
  styleUrls: ['./alumn-master.component.css'],
  providers:[AlumnMasterService,DatePipe,Helper]
})
export class AlumnMasterComponent implements OnInit {

  Coes = [];
  alumns = [];
  orignalAlumns = [];
  coeId:any;
  searchText:any;
  showLoader = false;
  scholarData:any;
  Years:any;
  selectedYear = '0';
  Courses = [];
  selectedCourseId = '0';
  selectedCoeId = '0';
  selectedStatusId = '0';

  constructor(private domSanitizer: DomSanitizer,private alumnMasterService:AlumnMasterService,private datepipe:DatePipe,private uploadService: UploadService,private helper:Helper) {
    this.getAllAlumns();
    this.Years = this.helper.getYears();
    this.getAllCoe();
   }

  ngOnInit() {
  }

  getAllAlumns() {
    this.showLoader = true
    this.alumnMasterService.getAllAlumns(localStorage.getItem("email")).subscribe(
        data => {
            this.showLoader = false;
            if (data.status_code == 0) {
                this.alumns = data["alumns"];   
                this.orignalAlumns = data["alumns"];                
            }
            else {
              swal.fire(data['status_message']);
            }
        },
        err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
        }
    )
}


  filterAlumn(){

  }

  sort(srt:any){

  }

  showScholarDetails = false;
  scholarMobileNumber:any;
  dakshnaRollNumber:any;
  dob:any;
  coe_name:any;
    scholarEmail:any;
    addressOne:any;
    addressTwo:any;
    city:any;
    state:any;
    home_state:any;
    fatherEmail:any;
    motherEmail:any;
    fatherMobile:any;
    motherMobile:any;
    name:any;
    scholarCOENumber:any;
    scholarDocumnets = [];
    userId:any;
  
  


  openMapping(data:any){
    this.scholarData = {}
    this.scholarData = data        
    this.userId = data.id_user
    this.showScholarDetails = true
    this.scholarMobileNumber = data.mobile
    this.dakshnaRollNumber = data.dakshana_roll_number
    console.log("dob=" + data.dob);
    this.dob = data.dob.split("-").reverse().join("-");
    console.log("dob1=" + this.dob);
    try {
        // this.dob = this.datepipe.transform(this.dob, 'yyyy-MM-dd', 'es-ES');
        console.log("dob2=" + this.dob);
        this.dob = this.datepipe.transform(this.dob, 'yyyy-MM-dd', 'es-ES');
        console.log("on openMapping dob =" + this.dob);
    } catch (e) {
        this.dob = "";
    }

    
    //  this.dob = this.datepipe.transform(data.dob, 'MM/dd/yyyy', 'es-ES'); data.dob
    this.coe_name = data.coe_name
    this.scholarEmail = data.email
    this.addressOne = data.address_one;
    this.addressTwo = data.address_two;
    this.city = data.city;
    this.state = data.state_name;
    this.home_state = data.home_state_name;

    this.fatherEmail = data.father_email;
    this.motherEmail = data.mother_email;
    this.fatherMobile = data.father_mobile;
    this.motherMobile = data.mother_mobile;
    this.name = data.name;
    this.scholarCOENumber = data.coe_roll_number;
    this.scholarDocumnets = [];
    this.getScholarDocuments(data.coe_roll_number);
  }

  checkSelectedOrNot(data:any){
    if (data.id_user == this.userId) {
      return true
    }
    else {
        return false
    }
  }

  editUser(data:any){

  }

  removeUser(data){

  }

  changeStatus(data){
    var changeStatusId: any;
              if (data.id_status == 0) {
                  changeStatusId = 1
              }
              else {
                  changeStatusId = 0
              }

              if (data.id_status == 0) {
                data.id_status = 1;
            }
            else {
              data.id_status = 0;
            }
          
              
  console.log("changeStatus called");
    var that = this;
        swal.fire({
            title: '',
            text: "Are you sure you want to change status of "+data.name+"?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#428e63',
            confirmButtonText: 'Yes, change it!'
        }).then((result) => {
          // console.log("result="+JSON.stringify(result));
          if(!result.dismiss){
            console.log("In if");
              that.userId = data.id_user
              
              that.showLoader = true
              that.alumnMasterService.changeUserStatus(that.userId, localStorage.getItem("email"), changeStatusId).subscribe(
                  data => {
                      that.showLoader = false;
                      swal.fire(data['status_message']);                    
                  },
                  err => {
                      
                  })

            
             }else{
                  console.log("In else");
                  if (data.id_status == 0) {
                      data.id_status = 1;
                  }
                  else {
                    data.id_status = 0;
                  }
                
                }
        });



       
    
  }

  hideViewDocumentModal() {
    this.isViewDocumentModal = false;
  }

  getScholarDocuments(path) {
    this.showLoader = true;
    this.scholarDocumnets = [];
    var that = this
    this.uploadService.getFile(path).then(function (data: any) {
        var objectsArray = [];
        for (let file of data) {
            let documentName = file.Key.split('/').pop().split('.')[0]
            that.uploadService.getDocument(file).then(
                function (fileObject: any) {
                    var data = fileObject
                    var json = { 'document_type': documentName }
                    var str = data.Body.reduce(function (a, b) { return a + String.fromCharCode(b) }, '');
                    json['string_images'] = str
                    if (data.ContentType != "application/pdf") {
                        json['document'] = that.getImage(btoa(str).replace(/.{76}(?=.)/g, '$&\n'))
                    }
                    else {
                        json['document'] = that.getPDF(btoa(str).replace(/.{76}(?=.)/g, '$&\n'))
                    }
                    json['content_type'] = data.ContentType
                    json['coe_roll_number'] = path
                    json['key'] = file.Key
                    that.scholarDocumnets.push(json)
                }
            );
        }
        that.showLoader = false;
    });
}

getImage(imageData) {
  var image;
  image = 'data:image/jpeg;base64,' + imageData;
  image = this.domSanitizer.bypassSecurityTrustUrl(image);
  return image;
}

public getPDF(data) {
  var image = 'data:application/pdf;base64,' + data;
  return image;
}

getScholarPhoto() {
  var imageString = "../assets/logoscholar.jpg";
  if (this.scholarDocumnets.length > 0) {
      let index = this.scholarDocumnets.findIndex(
          (data, index) => data.document_type === "Scholar Photo"
      );

      if (index != -1) {
          imageString = this.scholarDocumnets[index].document;
      }
  }
  return imageString;
}

isViewDocumentModal = false;
viewDocuments(data) {
  this.isViewDocumentModal = true;
}

getAllCourse() {
  this.Courses = []
  this.alumnMasterService.getAllCourse(localStorage.getItem("email")).subscribe(
      data => {
          this.showLoader = false;
          if (data.status_code == 0) {
              this.Courses = data["courses"];
              
          }
          else {
              swal.fire(data.status_message)
          }
      },
      err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
      }
  )
}

getAllCoe() {
  this.Coes = [];
  this.showLoader = true
  this.alumnMasterService.getAllCoe(localStorage.getItem("email")).subscribe(
      data => {
          this.showLoader = false;
          if (data.status_code == 0) {
              this.Coes = data["coes"];
              this.getAllCourse();
          }
          else {
              swal.fire(data.status_message)
          }
        
      },
      err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
      }
  )
}

filterAlumni()
{
  this.alumns= [];
  // filter by coe
  if(this.selectedCoeId != '0')
  {
    this.orignalAlumns.forEach(alumn => {
         if(alumn.id_coe==this.selectedCoeId)
         this.alumns.push(alumn);
    });
  }else{
    this.alumns = this.orignalAlumns;
  }

  // filter by year
  if(this.selectedYear != '0')
  {
    var temp = [];
    this.alumns.forEach(alumn => {
      if(alumn.year==this.selectedYear)
      temp.push(alumn);
     });

     this.alumns = temp;
  }

  // filter by course
  if(this.selectedCourseId != '0')
  {
    var temp = [];
    this.alumns.forEach(alumn => {
      if(alumn.id_course_type==this.selectedCourseId)
      temp.push(alumn);
     });

     this.alumns = temp;
  }

  // filter by status
  if(this.selectedStatusId != '-1')
  {
    var temp = [];
    this.alumns.forEach(alumn => {
      if(alumn.id_status==this.selectedStatusId)
      temp.push(alumn);
     });

     this.alumns = temp;
  }
 
}



}
