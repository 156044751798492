import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QuestionPatternService } from './question-pattern.service';
import swal from 'sweetalert2'
@Component({
  selector: 'question-pattern',
  templateUrl: './question-pattern.component.html',
  styleUrls: ['./question-pattern.component.css'],
  providers: [QuestionPatternService]
})
export class QuestionPatternComponent implements OnInit {
  statusMessage: any;
  showLoader = false;
  QuestionPatterns = [];
  showAlert = false;
  alertMsg: any;
  alertType: any;
  constructor(private router: Router, private questionPatternService: QuestionPatternService) {

    if (localStorage.getItem("isLogin") == "true") {
      this.getAllQuestionPattern()
    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }
  getAllQuestionPattern() {
    this.showLoader = true;
    this.questionPatternService.getAllQuestionPattern(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == 0) {
          this.QuestionPatterns = data["question_patterns"];
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }


  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    //("status" + status);
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }
}
