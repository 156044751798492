import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class NoticeBoardService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }

    getAllNotices(email,offset,limit) {
        return this.http.get(this.baseUrl + "/NoticeBoard/getAllNotices?caller_email=" + email+"&offset="+offset+"&limit="+limit).map((res) => JSON.parse(JSON.stringify(res)));
    }
  
  

    addNewNotice(jsonData) {
        return this.http.post(this.baseUrl + "NoticeBoard/addNewNotice", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    updateNotice(jsonData) {
        return this.http.post(this.baseUrl + "NoticeBoard/updateNotice", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }


    removeNotice(email,id_notice_board) {
        return this.http.get(this.baseUrl + "/NoticeBoard/removeNotice?caller_email=" + email+"&id_notice_board="+id_notice_board).map((res) => JSON.parse(JSON.stringify(res)));
    }

    changeStatus(email,id_notice_board,$id_status) {
        return this.http.get(this.baseUrl + "/NoticeBoard/changeStatus?caller_email=" + email+"&id_notice_board="+id_notice_board+"&id_status="+$id_status).map((res) => JSON.parse(JSON.stringify(res)));
    }

  
   
}

  