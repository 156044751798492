import { Component, OnInit } from '@angular/core';
import { BookMasterService } from '../book-master/book-master.service'
import { CSVExport } from '../Csv-export';
import swal from 'sweetalert2'
import { JsonPipe } from '@angular/common';
@Component({
  selector: 'book-copies',
  templateUrl: './book-copies.component.html',
  styleUrls: ['./book-copies.component.css'],
  providers: [BookMasterService]
})
export class BookCopiesComponent implements OnInit {
  searchText: any;
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  isAddModal = false;
  showLoader = false;
  CoE = [];
  coeId = "0";
  Status = [];
  statusId = "0";
  BookCopies = [];
  UnfilterdBookCopies = [];
  selectedBookCopies = [];
  isAssignSelfModal = false;
  BookShelf = [];
  selectedBookShelf: any;
  shelfName: any;
  bookCopiesObject = { 'id_coe': '', 'id_book_shelf': '' }
  isEditCopies = false;
  isBookInfoModal = false;
  isSelectAll = false;
  bookShelfPreviousId: any;
  currentPage = 1
  copiesCount: any
  pageNumber = 0;
  accessionCode = '';
  UnfilteredBookShelf = [];
  constructor(private bookMasterService: BookMasterService) {
    this.getAllActiveCoe()
    this.getBookRelatedStatus()
    this.getAllBookShelf()
    this.getAllBookQuantityForCopiesPagination()

  }

  ngOnInit() {
  }
  getAllActiveCoe() {
    this.showLoader = true;
    this.CoE = []
    this.bookMasterService.getAllActiveCoe(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.CoE = data.coes
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllBookShelf() {
    this.showLoader = true;
    this.BookShelf = []
    this.bookMasterService.getAllBookShelf(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.BookShelf = data.shelfes
          this.UnfilteredBookShelf = data.shelfes
          // //(this.BookShelf)
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllBookQuantityForCopiesPagination() {
    this.showLoader = true;
    this.UnfilterdBookCopies = []
    this.bookMasterService.getAllBookQuantityForCopiesPagination(localStorage.getItem("email"), this.pageNumber).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.UnfilterdBookCopies = data.book_copies
          if (this.pageNumber == 0) {
            this.copiesCount = data.copies_count
            this.currentPage = 1

          }
          this.filterCopies()
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getBookRelatedStatus() {
    this.showLoader = true;
    this.Status = []
    this.bookMasterService.getBookRelatedStatus(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.Status = data.book_status
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }

  hideAlert(val) {
    this.showAlert = val;
  }

  filterCopies() {
    this.BookCopies = this.UnfilterdBookCopies
    if (this.coeId != "0") {
      var temporaryArray = [];
      for (let data of this.BookCopies) {
        if (data.id_coe == this.coeId) {
          temporaryArray.push(data);
        }
      }
      this.BookCopies = temporaryArray;
    }
    if (this.statusId != "0") {
      var temporaryArray = [];
      for (let data of this.BookCopies) {
        if (data.id_status == this.statusId) {
          temporaryArray.push(data);
        }
      }
      this.BookCopies = temporaryArray;
    }
  }

  addRemovebookCopies(data) {
    let index = this.selectedBookCopies.findIndex(x => x.id_book_accession_code == data.id_book_accession_code)
    if (index == -1) {
      this.selectedBookCopies.push(data)
    }
    else {
      this.selectedBookCopies.splice(index, 1)
    }
    //(this.selectedBookCopies)
  }
  assignShelf() {
    if (this.selectedBookCopies.length == 0) {
      swal.fire("Please select some book copies first.")
    }
    else {
      this.shelfName = ''
      // this.selectedBookShelf = {}
      this.isAssignSelfModal = true
    }
  }
  selectBookShelf() {
    let index = this.BookShelf.findIndex(x => x.shelf_code == this.selectedBookShelf)
    this.shelfName = this.BookShelf[index]['name']
  }

  saveBookShelfData() {
    this.showLoader = true;
    this.Status = []
    let json = {}
    let index = this.BookShelf.findIndex(x => x.shelf_code == this.selectedBookShelf)
    let id_book_shelf = this.BookShelf[index]['id_book_shelf']
    json['email'] = localStorage.getItem("email")
    json['id_book_shelf'] = id_book_shelf
    json['book_copies'] = JSON.stringify(this.selectedBookCopies)
    this.bookMasterService.addBookCopieInBookShelf(json).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.selectedBookShelf = ""
          this.hideAssignSelfModal()
          this.getAllBookQuantityForCopiesPagination()
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  hideAssignSelfModal() {
    this.isAssignSelfModal = false;
  }

  editCopie(data) {
    this.isEditCopies = true;
    this.bookCopiesObject = Object.assign({}, data);
    this.bookShelfPreviousId = this.bookCopiesObject['id_book_shelf']
    this.bookCopiesObject['id_book_previous_shelf'] = this.bookShelfPreviousId
  }
  closeEditCopiesModal() {
    this.isEditCopies = false;
  }

  removeBookCopies(data) {
    swal.fire({
      title: '',
      text: "Deleting an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to remove this Book Copie?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        this.bookMasterService.removeBookCopies(localStorage.getItem("email"), data.id_book_accession_code).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.getAllBookQuantityForCopiesPagination()
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);

            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
    })
  }

  bookInfo(data) {
    this.isBookInfoModal = true
    this.bookCopiesObject = data;
  }
  hideBookInfoModal() {
    this.isBookInfoModal = false
  }

  printAccessionCode(printSectionId) {
    let printContents, popupWin;
    printContents = document.getElementById(printSectionId).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          .text-center{
            text-align:center !important;
           }
           .mb-0{
             margin-bottom:0px!important;
           } 
           .pb-0{
             padding-bottom:0px!important;
           } 
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  export() {
    var arrayToExport = [];
    this.showLoader = true;
    this.bookMasterService.downloadAllBookCopies(localStorage.getItem("email")).subscribe(
      data => {

        if (data.status_code == "0") {
          let bookCopies = data.book_copies;

          if (data.book_copies) {
            for (let data of bookCopies) {
              let json = {
                "COE": data.coe_name,
                "Book Title": data.book_title_name,
                "ISBN Code": data.isbn_code,
                "Accession Code": data.accession_code,
                "Shelf": data.book_shelf_name,
                "Status": data.status_name,


              }
              if (data.alloted_to_scholar) {
                json['Allotted to (CoE/Dakshana Roll No.)'] = data.alloted_to_scholar + "(" + data.coe_roll_no + "/" + data.dakshana_roll_number + ")";
              }
              else {
                json['Allotted to (CoE/Dakshana Roll No.)'] = '';
              }

              arrayToExport.push(json);


            }
            CSVExport.downloadcsv(arrayToExport, " Book Copies");
            this.showLoader = false;
          }


        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
          this.showLoader = false;
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
    // if (this.BookCopies.length > 0) {
    //   for (let data of this.BookCopies) {
    //     let json = {
    //       "COE": data.coe_name,
    //       "Book Title": data.book_title_name,
    //       "ISBN Code": data.isbn_code,
    //       "Accession Code": data.accession_code,
    //       "Shelf": data.book_shelf_name,
    //       "Status": data.status_name,


    //     }
    //     if (data.alloted_to_scholar) {
    //       json['Allotted to'] = data.alloted_to_scholar;
    //     }
    //     else {
    //       json['Allotted to'] = '';
    //     }

    //     arrayToExport.push(json);


    //   }
    //   CSVExport.downloadcsv(arrayToExport, " Book Copies");
    // }
  }

  editCopies() {
    this.bookCopiesObject['email'] = localStorage.getItem('email')
    this.bookMasterService.editCopies(this.bookCopiesObject).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.isEditCopies = false;
          this.getAllBookQuantityForCopiesPagination()
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  checkRfresne(data) {
    //(data.model)
  }

  selectAllCopies() {
    this.isSelectAll = !this.isSelectAll
    //(this.isSelectAll)
    if (this.isSelectAll) {
      this.selectedBookCopies = []
      for (let data of this.BookCopies) {
        if (data.id_status == '12') {
          this.selectedBookCopies.push(data)
        }
      }
    }
    else {
      this.selectedBookCopies = []
    }
    //(this.selectedBookCopies)
  }

  isBookCopiesSelected(accessionCodeId) {
    let index = this.selectedBookCopies.findIndex(x => x.id_book_accession_code == accessionCodeId)
    if (index != -1) {
      return true
    }
    else {
      return false
    }

  }


  nextPage() {
    this.pageNumber = this.pageNumber + this.UnfilterdBookCopies.length;

    if (this.coeId != '0' || this.statusId != '0') {
      let filterJsonArray = []
      if (this.coeId != '0') {
        filterJsonArray.push({ "bq.id_coe": this.coeId })
      }
      if (this.statusId != '0') {
        filterJsonArray.push({ "bac.book_status": this.statusId })
      }
      if (this.accessionCode != '') {
        filterJsonArray.push({ "bac.accession_code": this.accessionCode })
      }
      // this.pageNumber = 0;
      // this.currentPage = 1
      this.getAllBookQuantityForCopiesPaginationByFilter(filterJsonArray)
    }
    else {
      this.getAllBookQuantityForCopiesPagination()
    }

    // this.getAllBookQuantityForCopiesPagination()
  }
  previousPage() {
    this.pageNumber = this.pageNumber - this.UnfilterdBookCopies.length;
    if (this.coeId != '0' || this.statusId != '0') {
      let filterJsonArray = []
      if (this.coeId != '0') {
        filterJsonArray.push({ "bq.id_coe": this.coeId })
      }
      if (this.statusId != '0') {
        filterJsonArray.push({ "bac.book_status": this.statusId })
      }
      if (this.accessionCode != '') {
        filterJsonArray.push({ "bac.accession_code": this.accessionCode })
      }
      // this.pageNumber = 0;
      // this.currentPage = 1
      this.getAllBookQuantityForCopiesPaginationByFilter(filterJsonArray)
    }
    else {
      this.getAllBookQuantityForCopiesPagination()
    }
    // this.getAllBookQuantityForCopiesPagination()
  }

  pageChanged(event) {
    if (this.coeId != '0' || this.statusId != '0') {
      let filterJsonArray = []
      if (this.coeId != '0') {
        filterJsonArray.push({ "bq.id_coe": this.coeId })
      }
      if (this.statusId != '0') {
        filterJsonArray.push({ "bac.book_status": this.statusId })
      }
      if (this.accessionCode != '') {
        filterJsonArray.push({ "bac.accession_code": this.accessionCode })
      }
      // this.pageNumber = 0;
      // this.currentPage = 1
      this.getAllBookQuantityForCopiesPaginationByFilter(filterJsonArray)
    }
    else {
      this.pageNumber = (event - 1) * 500
      this.currentPage = event
      this.getAllBookQuantityForCopiesPagination()
    }

  }

  applyFiter() {
    let filterJsonArray = []
    if (this.coeId != '0') {
      filterJsonArray.push({ "bq.id_coe": this.coeId })
    }
    if (this.statusId != '0') {
      filterJsonArray.push({ "bac.book_status": this.statusId })
    }
    if (this.accessionCode != '') {
      filterJsonArray.push({ "bac.accession_code": this.accessionCode })
    }
    this.pageNumber = 0;
    this.currentPage = 1
    this.getAllBookQuantityForCopiesPaginationByFilter(filterJsonArray)
  }

  getAllBookQuantityForCopiesPaginationByFilter(filterJsonArray) {
    this.showLoader = true;
    this.UnfilterdBookCopies = []
    this.bookMasterService.getAllBookQuantityForCopiesPaginationByFilter(localStorage.getItem("email"), this.pageNumber, filterJsonArray).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.UnfilterdBookCopies = data.book_copies
          if (this.pageNumber == 0) {
            this.copiesCount = data.copies_count
            this.currentPage = 1

          }
          // this.filterCopies()
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
        this.filterCopies()
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  clearFilter() {
    this.currentPage = 1
    this.pageNumber = 0;
    this.coeId = "0"
    this.statusId = "0"
    this.searchText = ""
    this.accessionCode = ""
    this.getAllBookQuantityForCopiesPagination()
  }

  getCoeBookShelf(coeId) {
    this.BookShelf = this.UnfilteredBookShelf.filter(bookShelf => {
      return bookShelf.id_coe === coeId
    })
  }
}
