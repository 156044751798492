import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CoeMasterService } from './coe-master.service';
import swal from 'sweetalert2'
@Component({
    selector: 'coe-master',
    templateUrl: 'coe-master.component.html',
    styleUrls: ['coe-master.component.scss'],
    providers: [CoeMasterService]
})


export class CoeMasterComponent {
    isAddModal: boolean
    modelHeadelLabel: any
    name: any;
    addressLineOne: any;
    addressLineTwo: any;
    district: any;
    pincode: any;
    pricipalName: any;
    schoolMobileNumber: any;
    pricipalMobileNumber: any;
    schoolEmail: any;
    pricipalEmail: any;
    showLoader: boolean;
    Coes = [];
    States: any;
    stateId: any;
    purpose: any;
    coeId: any;
    searchText: any;
    showDetails: boolean;
    state: any;
    coeDetailsView: any;
    emailPattern = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/;
    alertDiv = false
    sucessAlertMessage: any;
    showAlert: boolean;
    alertMsg: any;
    alertType = "";
    column: string = 'CategoryName';
    direction: number;
    isDesc: boolean = false;
    constructor(private router: Router, private coeMasterService: CoeMasterService) {
        this.isAddModal = false;
        this.showLoader = false;
        this.showDetails = false;
        this.purpose = "Add"
        this.addressLineTwo = ""
        this.stateId = "0"
        this.coeDetailsView = "<h3>Hello</h3>"
        if (localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "1") {
            this.getAllState();
            //this.getUserByRoleId();
        }
        else {
            this.router.navigateByUrl('/');
        }
    }

    getAllState() {
        this.showLoader = true;
        this.coeMasterService.getAllStates(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {
                    this.States = data["states"];
                    this.getAllCoe();
                }
                else {
                    this.showAlertDiv(data.status_message)
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }
    getAllCoe() {
        this.showLoader = true;
        this.coeMasterService.getAllCoe(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {
                    this.Coes = data["coes"];
                    this.showCoeDetails(this.Coes[0]);
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
                else {
                    this.showDetails = false
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }
    addNew() {
        this.modelHeadelLabel = "Add New CoE"
        this.purpose = "Add"
        this.clearData();
        this.isAddModal = true;
    }
    hideAddEditModal() {
        this.isAddModal = false;
        this.clearData();
    }
    save() {
        if (!this.name) {
            swal.fire("Please enter name.")
        }
        else if (!this.addressLineOne) {
            swal.fire("Please enter address line 1.")
        }
        else if (!this.district) {
            swal.fire("Please enter district.")
        }
        else if (!this.pincode) {
            swal.fire("Please enter picode.")
        }
        else if (this.pincode.length != 6) {
            swal.fire("Please enter correct pincode.")
        }
        else if (!this.stateId || this.stateId == "0") {
            swal.fire("Please select state.")
        }
        else if (!this.pricipalName) {
            swal.fire("Please enter principal name.")
        }
        else if (!this.schoolMobileNumber) {
            swal.fire("Please enter school mobile number.")
        }
        else if (this.schoolMobileNumber.length != 10) {
            swal.fire("Please enter correct school mobile number.")
        }
        else if (!this.pricipalMobileNumber) {
            swal.fire("Please enter principal mobile number.")
        }
        else if (this.pricipalMobileNumber.length != 10) {
            swal.fire("Please enter correct principal mobile number.")
        }
        else if (!this.schoolEmail) {
            swal.fire("Please enter school email.")
        }
        else if (!(this.schoolEmail.toLowerCase().match(this.emailPattern))) {
            swal.fire("Please enter correct school email id.");
        }
        else if (!this.pricipalEmail) {
            swal.fire("Please enter principal email.")
        }
        else if (!(this.pricipalEmail.toLowerCase().match(this.emailPattern))) {
            swal.fire("Please enter correct principal email id.");
        }
        else {
            if (this.purpose != "Edit") {
                let json = {
                    'name': this.name,
                    'address_one': this.addressLineOne,
                    'address_two': this.addressLineTwo,
                    'district': this.district,
                    'pincode': this.pincode,
                    'principal_name': this.pricipalName,
                    'school_mobile': this.schoolMobileNumber,
                    'principal_mobile': this.pricipalMobileNumber,
                    'school_email': this.schoolEmail,
                    'principal_email': this.pricipalEmail,
                    'id_state': this.stateId,
                    'caller_email': localStorage.getItem("email")

                }

                this.coeMasterService.addNewCoe(json).subscribe(
                    data => {
                        this.showLoader = false;
                        if (data.status_code == 0) {
                            this.Coes = data["coes"];
                            this.isAddModal = false
                            this.showCoeDetails(this.Coes[0]);
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.isAddModal = false
                        this.showLoader = false;
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }
            else {
                //("id_coe" + this.coeId)
                let json = {
                    'id_coe': this.coeId,
                    'name': this.name,
                    'address_one': this.addressLineOne,
                    'address_two': this.addressLineTwo,
                    'district': this.district,
                    'pincode': this.pincode,
                    'principal_name': this.pricipalName,
                    'school_mobile': this.schoolMobileNumber,
                    'principal_mobile': this.pricipalMobileNumber,
                    'school_email': this.schoolEmail,
                    'principal_email': this.pricipalEmail,
                    'id_state': this.stateId,
                    'caller_email': localStorage.getItem("email")

                }
                this.showLoader = true;
                this.coeMasterService.editCoe(json).subscribe(
                    data => {
                        this.showLoader = false;
                        if (data.status_code == 0) {
                            this.Coes = data["coes"];
                            this.isAddModal = false
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.isAddModal = false
                        this.showLoader = false;
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }

        }
    }
    clearData() {
        this.showDetails = false
        this.modelHeadelLabel = "Add New CoE"
        this.purpose = "Add"
        this.name = ""
        this.addressLineOne = ""
        this.addressLineTwo = ""
        this.district = ""
        this.pincode = ""
        this.stateId = "0"
        this.pricipalName = ""
        this.pricipalEmail = ""
        this.schoolEmail = ""
        this.pricipalMobileNumber = ""
        this.schoolMobileNumber = ""
    }

    editCoe(data) {
        this.modelHeadelLabel = "Edit CoE"
        this.coeId = data.id_coe
        this.isAddModal = true;
        this.purpose = "Edit";
        this.name = data.name
        this.addressLineOne = data.address_one
        this.addressLineTwo = data.address_two
        this.district = data.district
        this.pincode = data.pincode
        this.stateId = data.id_state
        this.pricipalName = data.principal_name
        this.pricipalEmail = data.principal_email
        this.schoolEmail = data.school_email
        this.pricipalMobileNumber = data.principal_mobile
        this.schoolMobileNumber = data.principal_mobile
    }

    removeCoe(data) {
        this.coeId = data.id_coe
        swal.fire({
            title: '',
            text: "Deleting an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to remove this CoE?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#428e63',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.value) {
                this.Coes = [];
                this.showLoader = true;
                this.coeMasterService.removeCoe(this.coeId, localStorage.getItem("email")).subscribe(
                    data => {
                        this.showLoader = false;
                        if (data.status_code == 0) {
                            this.isAddModal = false;
                            this.clearData();
                            this.Coes = data["coes"];
                            this.showCoeDetails(this.Coes[0]);
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                        else {
                            this.showDetails = false
                            this.showAlertComponent(data['status_code'], data['status_message']);

                        }
                    },
                    err => {
                        this.showLoader = false;
                        this.isAddModal = false;
                        this.clearData();
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }
        })
    }

    showCoeDetails(data) {
        this.coeId = data.id_coe
        this.name = data.name
        this.addressLineOne = data.address_one
        this.addressLineTwo = data.address_two
        this.district = data.district
        this.pincode = data.pincode
        this.stateId = data.id_state
        this.state = data.state;
        this.pricipalName = data.principal_name
        this.pricipalEmail = data.principal_email
        this.schoolEmail = data.school_email
        this.pricipalMobileNumber = data.principal_mobile
        this.schoolMobileNumber = data.principal_mobile
        this.showDetails = true

    }

    changeStatus(data) {
        var changeStatusId: any;
        this.coeId = data.id_coe
        if (data.id_status == 0) {
            changeStatusId = 1
        }
        else {
            changeStatusId = 0
        }
        if (changeStatusId == 1) {
            swal.fire({
                title: '',
                text: "Deactivating an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to deactivate this CoE?",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#8B0000',
                cancelButtonColor: '#428e63',
                confirmButtonText: "Yes, deactivate it!"
            }).then((result) => {
                if (result.value) {
                    this.Coes = [];
                    this.showLoader = true;
                    this.coeMasterService.changeStatus(this.coeId, localStorage.getItem("email"), changeStatusId).subscribe(
                        data => {
                            this.showLoader = false;
                            if (data.status_code == 0) {
                                this.isAddModal = false;
                                this.clearData();
                                this.Coes = data["coes"];
                                this.showCoeDetails(this.Coes[0]);
                                this.showAlertComponent(data['status_code'], data['status_message']);
                            }
                            else {
                                this.hideAddEditModal()
                                this.showDetails = false
                                this.showAlertComponent(data['status_code'], data['status_message']);
                            }
                        },
                        err => {
                            this.showLoader = false;
                            this.isAddModal = false;
                            this.clearData();
                            swal.fire("Something went wrong. Please try again later.")
                        }
                    )
                }
                else {

                    if (changeStatusId == 0) {
                        (<HTMLInputElement>document.getElementById(data.id_coe)).checked = false;
                    }
                    else {
                        (<HTMLInputElement>document.getElementById(data.id_coe)).checked = true;
                    }
                }
            })
        }
        else {
            this.showLoader = true;
            this.coeMasterService.changeStatus(this.coeId, localStorage.getItem("email"), changeStatusId).subscribe(
                data => {
                    this.showLoader = false;
                    if (data.status_code == 0) {
                        this.isAddModal = false;
                        this.clearData();
                        this.Coes = data["coes"];
                        this.showCoeDetails(this.Coes[0]);
                        this.showAlertComponent(data['status_code'], data['status_message']);
                    }
                    else {
                        this.hideAddEditModal()
                        this.showDetails = false
                        this.showAlertComponent(data['status_code'], data['status_message']);
                    }
                },
                err => {
                    this.showLoader = false;
                    this.isAddModal = false;
                    this.clearData();
                    swal.fire("Something went wrong. Please try again later.")
                }
            )
        }

        //.log(data.id_status)
    }
    hideCoeDeatils() {
        this.showDetails = false
    }

    showAlertDiv(message) {
        var that = this
        that.alertDiv = true
        this.sucessAlertMessage = message
        setTimeout(function () {
            that.alertDiv = false
        }, 5000);
    }

    moveToBatch(data) {
        this.router.navigate(['./batch master'], { queryParams: { coeId: data.id_coe } });

    }

    checkSelectedOrNot(data) {
        if (data.id_coe == this.coeId) {
            return true
        }
        else {
            return false
        }
    }

    showAlertComponent(status, messege) {
        this.showAlert = true;
        this.alertMsg = messege;
        //("status" + status);
        if (parseInt(status) == 0) {
            this.alertType = "success";
        }
        else {
            this.alertType = "failure";
        }
    }
    hideAlert(val) {
        this.showAlert = val;
    }

    sort(property) {
        this.isDesc = !this.isDesc; //change the direction    
        this.column = property;
        let direction = this.isDesc ? 1 : -1;

        this.Coes.sort(function (a, b) {
            if (a[property] < b[property]) {
                return -1 * direction;
            }
            else if (a[property] > b[property]) {
                return 1 * direction;
            }
            else {
                return 0;
            }
        });
    }
}
