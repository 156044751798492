import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QuestionBankService } from './question-bank.service';
import swal from 'sweetalert2';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
declare let window;
@Component({
  selector: 'app-question-bank',
  templateUrl: './question-bank.component.html',
  styleUrls: ['./question-bank.component.css'],
  providers: [QuestionBankService]
})
export class QuestionBankComponent implements OnInit {
  isShowModal = false;
  Subjects = [];
  subjectId: any;
  SubSubjects = [];
  UnFiltterdSubSubjects = [];
  subSubjectId: any;
  Topics = [];
  topicId: any;
  UnfiltterdTopics = [];
  SubTopics = [];
  UnfilttersSubTopics = [];
  subTopicId: any;
  showLoader = false;
  showAlert = false;
  alertMsg: any;
  alertType: any;
  QuestionSources = [];
  questionSourceId: any;
  DifficultyLevels: any;
  difficultyLevelId: any;
  QuestionTypes = [];
  questionTypeId: any;
  ReviewRequirements = [];
  reviewRequirementId: any;
  isSelectQuestionTypeModal = false;
  Questions = [];
  previousData: any;
  unfilteredQuestions = [];
  roleId: any;
  QuestionInterpretation = [];
  TimeOfQuestion = [];
  Tabs = [];
  tabId = "1";
  questionStatusId = "0"
  QuestionStatus = [];
  initEditText: any;
  isQuestionViewModal = false

  QuestionData: any;
  selectedQuestionPatternId: any;
  correctAnswer: any;
  ColumnSecond: any;
  ColumnOne: any;
  Answer: any;
  Optiones: any;
  MatrixMatchingAnswerTable = [];
  sollutionText: any;
  p: number = 0;
  MatrixCheckedAnswer = [];
  ComprehensionQuestions = [];
  isComprehensionQuestionViewModal = false;
  ComprehensionTypeMatrix = [];
  isDownloadQuestionModal = false;
  isDownloadSelectedQuestionModal = false;
  isSollution = true;
  questionFor = "0";
  rowsPerPage = 20;
  selectedQuestionSource = [];

  filterObject = {
    id_subject: "0",
    id_sub_subject: "0",
    id_topic: "0",
    id_sub_topic: "0",
    id_question_source: [],
    id_difficult_level: "0",
    id_question_pattern: "0",
    id_review_requirement: "0",
    is_approved: "0",
    question_for: "0",
    offset: 1,
    limit: 20
  };
  totalQuestions = 0;

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id_question_source',
    textField: 'name',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
    limitSelection: 5
  };
  selectedQuestions: any[] = [];
  selectAll: boolean = false;

  constructor(private router: Router, private questionBankService: QuestionBankService) {
    this.p = 1;
    this.generateTab();
    if (localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "1" || localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "4") {
      this.roleId = localStorage.getItem("id_role");
      this.subjectId = "0";
      this.subSubjectId = "0";
      this.topicId = "0";
      this.subTopicId = "0";
      this.questionSourceId = "0";
      this.difficultyLevelId = "0";
      this.questionTypeId = "0";
      this.reviewRequirementId = "0"
      this.initializeTiny();
      this.generateQuestionStatus();
      this.getMasterDataForQuestionBank()
    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  changeDownloadMode() {
    this.isSollution = !this.isSollution;
  }

  initializeTiny() {
    this.initEditText = {
      selector: "textarea",  // change this value according to your HTML
      plugins: "noneditable",
      noneditable_editable_class: "mceEditable"
    }
  }

  generateQuestionStatus() {
    let jsonOne = {
      "id_question_status": "12",
      "name": "Pending"
    }
    this.QuestionStatus.push(jsonOne);
    let jsonSecond = {
      "id_question_status": "13",
      "name": "Approved"
    }
    this.QuestionStatus.push(jsonSecond);
    let jsonThird = {
      "id_question_status": "14",
      "name": "Rejected"
    }
    this.QuestionStatus.push(jsonThird);
  }

  ngOnInit() {
  }

  generateTab() {
    let jsonOne = {
      "id": "1",
      "text": "Pending for Approval"
    }
    this.Tabs.push(jsonOne);
    let jsonSecond = {
      "id": "2",
      "text": "Pending for Review"
    }
    this.Tabs.push(jsonSecond);
    let jsonThird = {
      "id": "3",
      "text": "Rejected"
    }
    this.Tabs.push(jsonThird);
    let jsonFourth = {
      "id": "4",
      "text": "Approved/Reviewed"
    }
    this.Tabs.push(jsonFourth);

  }

  isButtonActive(id) {
    if (id == this.tabId) {
      return true;
    }
    else {
      false;
    }
  }

  selectTab(id) {
    this.tabId = id;
    this.filterObject.offset = 1
    this.getAllQuestion();

  }

  filterQuestionForTab() {
    this.Questions = [];
    if (this.tabId == "1") {
      for (let data of this.unfilteredQuestions) {
        if (data.is_approved == "12") {
          this.Questions.push(data);
        }
      }
    }
    else if (this.tabId == "2") {
      for (let data of this.unfilteredQuestions) {
        if (this.checkQuestionIsAvailableForReview(data)) {
          this.Questions.push(data);
        }
      }
    }
    else if (this.tabId == "3") {
      for (let data of this.unfilteredQuestions) {
        if (data.is_approved == "14") {
          this.Questions.push(data);
        }
      }
    }
    else if (this.tabId == "4") {
      for (let data of this.unfilteredQuestions) {
        if (data.is_approved == "13") {
          this.Questions.push(data);
        }
      }
    }
  }

  checkQuestionIsAvailableForReview(data) {
    var today = new Date();
    var todayDate = today.toISOString().split('T')[0];
    var diff = Date.parse(todayDate) - Date.parse(data.last_review_date);
    var d = Math.floor(diff / 86400000)
    if (d >= data.review_after && data.is_approved == "13") {
      return true
    }
    else {
      return false
    }
  }

  orignalQuestionSource = [];
  idCourseType = 14;

  getMasterDataForQuestionBank() {
    this.showLoader = true;
    this.questionBankService.getMasterDataForQuestionBank(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == 0) {
          this.Subjects = data.subjects;
          // this.SubSubjects = data.sub_subjects;
          this.UnFiltterdSubSubjects = data.sub_subjects;
          // this.Topics = data.topics;
          this.UnfiltterdTopics = data.topics;
          //  this.SubTopics = data.sub_topics;
          this.UnfilttersSubTopics = data.sub_topics;
          this.orignalQuestionSource = data.question_sources;
          this.QuestionSources = data.question_sources;
          this.DifficultyLevels = data.question_difficulty_levels;
          this.QuestionTypes = data.question_types;
          this.ReviewRequirements = data.review_requirements;
          this.QuestionInterpretation = data.question_interpretation;
          this.TimeOfQuestion = data.time_of_questions;
          this.getAllQuestion();
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllQuestion() {
    this.showLoader = true;
    let json = this.filterObject;
    this.Questions = [];
    json['caller_email'] = localStorage.getItem("email")
    if (localStorage.getItem("id_role") == "4") {
      if (this.tabId == "1") {
        json["question_status_type"] = "12"
      }
      else if (this.tabId == "2") {
        json["question_status_type"] = "13"
      }
      else if (this.tabId == "3") {
        json["question_status_type"] = "14"
      }
      else if (this.tabId == "4") {
        json["question_status_type"] = "13"
      }

    }

    this.questionBankService.getAllQuestionWithPagination(json).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == 0) {
          //console.log(data);
          this.totalQuestions = data.count;
          let tempQuestionArray = data.questions;
          var QuestionArray = [];
          //console.log(QuestionArray);
          for (let dataOne of tempQuestionArray) {
            let json = {};
            if (dataOne.id_comprehension != "0") {
              const index = QuestionArray.findIndex(question => question.id_comprehension === dataOne.id_comprehension);
              if (index == -1) {

                json["id_comprehension"] = dataOne.id_comprehension
                json["id_question_source"] = dataOne.id_question_source
                json["id_difficult_level"] = dataOne.id_difficult_level
                json["id_review_requirement"] = dataOne.id_review_requirement
                json["comprehension_text"] = dataOne.comprehension_text
                json["question_code"] = dataOne.question_code
                json["id_subject"] = dataOne.id_subject
                json["subject_name"] = dataOne.subject_name
                json["id_sub_subject"] = dataOne.id_sub_subject
                json["sub_subject_name"] = dataOne.sub_subject_name
                json["id_topic"] = dataOne.id_topic
                json["topic_name"] = dataOne.topic_name
                json["last_review_date"] = dataOne.last_review_date

                json["id_question_pattern"] = "3"
                json["is_approved"] = dataOne.is_approved

                json["quesion_pattern_name"] = "Comprehension"

                var comprehensionQuestionArray = []

                for (let dataSecond of tempQuestionArray) {
                  if (dataOne.id_comprehension == dataSecond.id_comprehension && dataOne.id_comprehension != "0") {
                    comprehensionQuestionArray.push(dataSecond)
                  }
                  if (comprehensionQuestionArray.length > 0)
                    json["is_approved"] = comprehensionQuestionArray[0].is_approved
                }
                json["questions"] = comprehensionQuestionArray

                console.log("id_comprehenssion="+dataOne.id_comprehension+" questions length="+comprehensionQuestionArray.length);
                QuestionArray.push(json)
              }

            }
            else {
              QuestionArray.push(dataOne)
            }
          }

          QuestionArray.sort(function (a, b) {
            var nameA = a.subject_name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.subject_name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          });

          this.unfilteredQuestions = QuestionArray;
          this.Questions = QuestionArray;
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  searchText = '';
  getAllQuestionSearchQuestions() {
    this.showLoader = true;
    let json = this.filterObject;
    this.Questions = [];
    json['caller_email'] = localStorage.getItem("email")
    if (localStorage.getItem("id_role") == "4") {
      if (this.tabId == "1") {
        json["question_status_type"] = "12"
      }
      else if (this.tabId == "2") {
        json["question_status_type"] = "13"
      }
      else if (this.tabId == "3") {
        json["question_status_type"] = "14"
      }
      else if (this.tabId == "4") {
        json["question_status_type"] = "13"
      }

    }

    json['question_string'] = this.searchText;

    this.questionBankService.getAllQuestionWithPaginationSearchText(json).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == 0) {
          this.totalQuestions = data.count;
          let tempQuestionArray = data.questions;
          var QuestionArray = [];
          for (let dataOne of tempQuestionArray) {
            let json = {};
            if (dataOne.id_comprehension != "0") {
              const index = QuestionArray.findIndex(question => question.id_comprehension === dataOne.id_comprehension);
              if (index == -1) {

                json["id_comprehension"] = dataOne.id_comprehension
                json["id_question_source"] = dataOne.id_question_source
                json["id_difficult_level"] = dataOne.id_difficult_level
                json["id_review_requirement"] = dataOne.id_review_requirement
                json["comprehension_text"] = dataOne.comprehension_text
                json["question_code"] = dataOne.question_code
                json["id_subject"] = dataOne.id_subject
                json["subject_name"] = dataOne.subject_name
                json["id_sub_subject"] = dataOne.id_sub_subject
                json["sub_subject_name"] = dataOne.sub_subject_name
                json["id_topic"] = dataOne.id_topic
                json["topic_name"] = dataOne.topic_name
                json["last_review_date"] = dataOne.last_review_date

                json["id_question_pattern"] = "3"
                json["is_approved"] = dataOne.is_approved

                json["quesion_pattern_name"] = "Comprehension"

                var comprehensionQuestionArray = []

                for (let dataSecond of tempQuestionArray) {
                  if (dataOne.id_comprehension == dataSecond.id_comprehension && dataOne.id_comprehension != "0") {
                    comprehensionQuestionArray.push(dataSecond)
                  }
                  if (comprehensionQuestionArray.length > 0)
                    json["is_approved"] = comprehensionQuestionArray[0].is_approved
                }
                json["questions"] = comprehensionQuestionArray
                QuestionArray.push(json)
              }

            }
            else {
              QuestionArray.push(dataOne)
            }
          }
          this.unfilteredQuestions = QuestionArray;
          this.Questions = QuestionArray;
          // console.log( this.Questions)



          this.showAlertComponent(data['status_code'], data['status_message']);
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }

  hideAlert(val) {
    this.showAlert = val;
  }

  filterSuSuject() {
    this.SubSubjects = [];
    this.subSubjectId = "0";
    this.topicId = "0";
    this.subTopicId = "0";
    for (let data of this.UnFiltterdSubSubjects) {
      if (this.subjectId == data.id_subject) {
        this.SubSubjects.push(data)
      }
    }
    this.filterQuestions();
  }

  filterTopics() {
    this.Topics = [];
    this.topicId = "0";
    this.subTopicId = "0";
    for (let data of this.UnfiltterdTopics) {
      if (this.subSubjectId == data.id_sub_subject) {
        this.Topics.push(data)
      }
    }
    this.filterQuestions();
  }

  filterSubTopics() {
    this.SubTopics = [];
    this.subTopicId = "0";
    for (let data of this.UnfilttersSubTopics) {
      if (this.topicId == data.id_topic) {
        this.SubTopics.push(data)
      }
    }
    this.filterQuestions();
  }

  closeSelectQuestionTypeModal() {
    this.isSelectQuestionTypeModal = false;
  }

  addNewQuestion() {
    if((this.selectedQuestionSource.length == 1) || (this.selectedQuestionSource.length == 0))
    this.isSelectQuestionTypeModal = true;
    else    
    swal.fire("Please select only one question source while adding the question.");
  }

  selectQestionType(data) {

    if(this.selectedQuestionSource.length == 1)
    this.questionSourceId=this.selectedQuestionSource[0]['id_question_source'];


    this.previousData = null
    this.questionTypeId = data.id_question_pattern
    this.isShowModal = true;
    
  }

  editQuestion(data) {
    this.questionTypeId = data.id_question_pattern
    this.previousData = data;
    this.isShowModal = true;

    // if (data.id_comprehension != "0") {
    //   this.questionTypeId = "3";
    //   var tempArray = [];
    //   for (let question of this.Questions) {
    //     if (question.id_comprehension == data.id_comprehension) {
    //       tempArray.push(question);
    //     }
    //   }
    //   this.previousData = tempArray;
    //   this.isShowModal = true;
    // }
    // else {
    //   this.questionTypeId = data.id_question_pattern
    //   this.previousData = data;
    //   this.isShowModal = true;
    // }

  }

  hideAddQuestionModal(val) {
    this.isShowModal = false;
    this.questionTypeId = "0"
    this.closeSelectQuestionTypeModal();
    console.log(val)
    if (val == 'approved' || val == 'edit' || localStorage.getItem('id_role')) {
      let offset = this.filterObject.offset
      // this.filterObject={
      //   id_subject:"0",
      //   id_sub_subject:"0",
      //   id_topic:"0",
      //   id_sub_topic:"0",
      //   id_question_source:"0",
      //   id_difficult_level:"0",
      //   id_question_pattern:"0",
      //   id_review_requirement:"0",
      //   is_approved:"0",
      //   question_for:"0",
      //   offset:offset
      // };
      this.subjectId = this.filterObject.id_subject;
      this.subSubjectId = this.filterObject.id_sub_subject;
      this.topicId = this.filterObject.id_topic;
      this.subTopicId = this.filterObject.id_sub_topic;
      this.questionSourceId = this.filterObject.id_question_source;
      this.difficultyLevelId = this.filterObject.id_difficult_level;
      this.questionTypeId = this.filterObject.id_question_pattern;
      this.reviewRequirementId = this.filterObject.id_review_requirement;
      this.questionStatusId = this.filterObject.is_approved;
    }
    else {
      let offset = this.filterObject.offset
      this.filterObject = {
        id_subject: "0",
        id_sub_subject: "0",
        id_topic: "0",
        id_sub_topic: "0",
        id_question_source: [],
        id_difficult_level: "0",
        id_question_pattern: "0",
        id_review_requirement: "0",
        is_approved: "0",
        question_for: "0",
        offset: offset,
        limit: this.rowsPerPage
      };
    }

    this.getAllQuestion();
  }

  removeQuestion(data) {
    swal.fire({
      title: '',
      text: "Are you sure you want to remove this question?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        this.questionBankService.removeQuestion(data.id_comprehension, data.id_question, localStorage.getItem("email")).subscribe(
          data => {

            this.showLoader = false;
            if (data.status_code == 0) {
              this.getAllQuestion();
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
    })
  }

  getQuestionText(data) {
    if (data.id_question_pattern == "6") {
      let x = data.question_text.split("$$");
      return "Assertion: " + x[0] + " Reason: " + x[1]
    }
    else {
      return data.question_text;
    }
  }

  getQuestionTextForMdal(data) {

    let x = data.split("$$");

    return "Assertion: " + x[0] + " Reason: " + x[1]
  }

  onItemDeSelectQuestionSource(item?: any) {
    this.questionSourceId = 0;
    console.log("item id_question_source=" + JSON.stringify(item));
    // var index = this.selectedQuestionSource.findIndex(data => data.id_question_source == item.id_question_source);
    // console.log("index=" + index);
    // if (index != -1)
    //   this.selectedQuestionSource.splice(index, 1);

    console.log("deselect selectedQuestionSource=" + JSON.stringify(this.selectedQuestionSource));
    this.filterQuestions();
  }

  onItemSelectQuestionSource(item?: any) {

    // var obj = { "id_question_source": item.id_question_source }
    // this.selectedQuestionSource.push(obj);
    console.log("selectedQuestionSource=" + JSON.stringify(this.selectedQuestionSource));
    this.filterQuestions();
  }



  filterQuestions(item?: any) {

    // if (item) {
    //   this.questionSourceId = item.id_question_source
    // }

    this.filterObject = {
      id_subject: this.subjectId,
      id_sub_subject: this.subSubjectId,
      id_topic: this.topicId,
      id_sub_topic: this.subTopicId,
      id_question_source: this.selectedQuestionSource,
      id_difficult_level: this.difficultyLevelId,
      id_question_pattern: this.questionTypeId,
      id_review_requirement: this.reviewRequirementId,
      is_approved: this.questionStatusId,
      question_for: this.questionFor,
      offset: 1,
      limit: this.rowsPerPage
    };
    this.getAllQuestion();
    // this.Questions = this.unfilteredQuestions;
    // if (this.subjectId != "0") {
    //   var temporaryArray = [];
    //   for (let data of this.Questions) {
    //     if (data.id_subject == this.subjectId) {
    //       temporaryArray.push(data);
    //     }
    //   }
    //   this.Questions = temporaryArray;
    //   //(this.Questions)
    // }
    // if (this.subSubjectId != "0") {
    //   var temporaryArray = [];
    //   for (let data of this.Questions) {
    //     if (data.id_sub_subject == this.subSubjectId) {
    //       temporaryArray.push(data);
    //     }
    //   }
    //   this.Questions = temporaryArray;
    // }
    // if (this.topicId != "0") {
    //   var temporaryArray = [];
    //   for (let data of this.Questions) {
    //     if (data.id_topic == this.topicId) {
    //       temporaryArray.push(data);
    //     }
    //   }
    //   this.Questions = temporaryArray;
    // }
    // if (this.subTopicId != "0") {
    //   var temporaryArray = [];
    //   for (let data of this.Questions) {
    //     if (data.id_sub_topic == this.subTopicId) {
    //       temporaryArray.push(data);
    //     }
    //   }
    //   this.Questions = temporaryArray;
    // }
    // if (this.subTopicId != "0") {
    //   var temporaryArray = [];
    //   for (let data of this.Questions) {
    //     if (data.id_sub_topic == this.subTopicId) {
    //       temporaryArray.push(data);
    //     }
    //   }
    //   this.Questions = temporaryArray;
    // }
    // if (this.questionSourceId != "0") {
    //   var temporaryArray = [];
    //   for (let data of this.Questions) {
    //     if (data.id_question_source == this.questionSourceId) {
    //       temporaryArray.push(data);
    //     }
    //   }
    //   this.Questions = temporaryArray;
    //   //(this.Questions)
    // }
    // if (this.difficultyLevelId != "0") {
    //   var temporaryArray = [];
    //   for (let data of this.Questions) {
    //     if (data.id_difficult_level == this.difficultyLevelId) {
    //       temporaryArray.push(data);
    //     }
    //   }
    //   this.Questions = temporaryArray;
    // }
    // if (this.questionTypeId != "0") {
    //   var temporaryArray = [];
    //   for (let data of this.Questions) {
    //     if (data.id_question_pattern == this.questionTypeId) {
    //       temporaryArray.push(data);
    //     }
    //   }
    //   this.Questions = temporaryArray;
    // }
    // if (this.reviewRequirementId != "0") {
    //   var temporaryArray = [];
    //   for (let data of this.Questions) {
    //     if (data.id_review_requirement == this.reviewRequirementId) {
    //       temporaryArray.push(data);
    //     }
    //   }
    //   this.Questions = temporaryArray;
    // }
    // if (this.questionStatusId != "0") {
    //   var temporaryArray = [];
    //   for (let data of this.Questions) {
    //     if (data.is_approved == this.questionStatusId) {
    //       temporaryArray.push(data);
    //     }
    //   }
    //   this.Questions = temporaryArray;
    // }
    // if (this.questionFor != "0") {
    //   var temporaryArray = [];
    //   for (let data of this.Questions) {
    //     if (data.question_for == this.questionFor) {
    //       temporaryArray.push(data);
    //     }
    //   }
    //   this.Questions = temporaryArray;
    // }
  }

  rejectQuestion(data) {

  }

  approvedQuestion(data) {
    // if (data.id_comprehension != null) {
    //   this.questionTypeId = "3";
    //   var tempArray = [];
    //   for (let question of this.Questions) {
    //     if (question.id_comprehension == data.id_comprehension) {
    //       tempArray.push(question);
    //     }
    //   }
    //   this.previousData = tempArray;
    //   this.isShowModal = true;
    // }
    // else {
    //   this.questionTypeId = data.id_question_pattern
    //   this.previousData = data;
    //   this.isShowModal = true;
    // }

    this.questionTypeId = data.id_question_pattern
    this.previousData = data;
    this.isShowModal = true;

  }

  getApprovedStatus(status) {
    if (status == "12") {
      return "Pending for approval";
    }
    else if (status == "13") {
      return "Approved";
    }
    else if (status == "14") {
      return "Rejected";
    }
  }

  showQuestionMOdal(data) {
    if (data.id_comprehension == "0" && data.id_question_pattern != "3") {
      this.QuestionData = data.question_text
      this.Optiones = data.question_optiones
      this.Answer = data.answer;
      this.ColumnOne = data.column_one;
      this.ColumnSecond = data.column_second;
      this.selectedQuestionPatternId = data.id_question_pattern;
      this.sollutionText = data.sollution_text;
      this.MatrixCheckedAnswer = data.matrix_match
      this.generateMatrixMatchingAnswer();
      this.isQuestionViewModal = true;
    }
    else {
      this.QuestionData = data.comprehension_text
      this.ComprehensionQuestions = data.questions;
      console.log("ComprehensionQuestions length="+this.ComprehensionQuestions.length);
      this.isComprehensionQuestionViewModal = true;
    }


  }

  checkIsCheckedOrNot(optionName, answerName) {
    let status = false;
    for (let data of this.MatrixCheckedAnswer) {
      if (data.option == optionName && data.answers == answerName) {
        status = true;
        break;
      }
    }

    return status;
  }

  generateMatrixMatchingAnswer() {
    this.MatrixMatchingAnswerTable = [];
    if (this.ColumnOne.length > 0 && this.ColumnSecond.length > 0) {
      for (let data of this.ColumnOne) {
        let json = {
          "option": data.column_name,
          "answers": this.ColumnSecond
        }
        this.MatrixMatchingAnswerTable.push(json)
      }
    }

  }

  generateMatrixMatchingAnswerForComprehension(columnOne, columnSecond, matrixMatch) {
    this.MatrixCheckedAnswer = matrixMatch
    var tempArray = [];
    if (columnOne.length > 0 && columnSecond.length > 0) {
      for (let data of columnOne) {
        let json = {
          "option": data.column_name,
          "answers": columnSecond
        }
        tempArray.push(json)
      }
    }
    this.ComprehensionTypeMatrix = tempArray
    return tempArray

  }

  checkComprehensionMatrixIsCheckedOrNot(optionName, answerName) {
    let status = false;

    for (let data of this.MatrixCheckedAnswer) {
      if (data.option == optionName && data.answers == answerName) {
        status = true;
        break;
      }
    }

    return status;
  }

  hideModal() {
    this.isQuestionViewModal = false
    this.questionTypeId = "0"
    this.isComprehensionQuestionViewModal = false;
    this.isDownloadQuestionModal = false;
    this.isDownloadSelectedQuestionModal = false;
  }


  showQuestionBank() {
    //this.questionSourceId)
    if (this.selectedQuestionSource.length <= 0) {
      swal.fire("Please select question source first.");
      return false;
    }

    if (this.selectedQuestionSource.length > 1) {
      swal.fire("Please select only one question source.");
      return false;
    }
    // this.selectedQuestionSource=this.QuestionSources.find(x=>x.id_question_source===this.questionSourceId).name
    this.isDownloadQuestionModal = true;
  }

  downloadQuestionBank() {
    let printContents, popupWin;

    printContents = document.getElementById('contentToConvert').innerHTML;
    var htmlToPrint = '' +
      '<style type="text/css">' +
      'table th, table td {' +
      'border:1px solid #000;' +
      'padding;0.5em;' +
      '}' +
      '</style>';
    htmlToPrint += printContents;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
          <head>
          <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/css/bootstrap.min.css" integrity="sha384-9gVQ4dYFwwWSjIDZnLEWnxCjeSWFphJiwGPXr1jddIhOegiu1FwO5qRGvFXOdJZ4"
          crossorigin="anonymous">
        <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
          crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.0/umd/popper.min.js" integrity="sha384-cs/chFZiN24E4KMATLdqdvsezGxaGsi4hLGOzlXwp5UZB1LY//20VyM2taTB4QvJ"
          crossorigin="anonymous"></script>
        <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/js/bootstrap.min.js" integrity="sha384-uefMccjFJAIv6A+rW+L4AHf99KvxDjWSu1z9VI8SKNVmz4sk7buKt/6v9KI65qnm"
          crossorigin="anonymous"></script>
              <style>
              li{
                list-style:none;
              }
              ul {
                list-style-type: none;
              }
              .border-div-for-question{
                border: 1px solid #e3dbdb!important;
                border-radius: 5px;
            }

            .contentoconvert{
              padding: 20px 26px;
            }
              
              </style>
          </head>
          <body onload="window.print();window.close()">${htmlToPrint}

          </body>
      </html>`
    );
    popupWin.document.close();
    // var quotes = document.getElementById('contentToConvert');
    // var data = document.getElementById('contentToConvert');  
    // html2canvas(data).then(canvas => {  
    //   // Few necessary setting options  
    //   var imgWidth = 208;   
    //   var pageHeight = 295;    
    //   var imgHeight = canvas.height * imgWidth / canvas.width;  
    //   var heightLeft = imgHeight;  

    //   const contentDataURL = canvas.toDataURL('image/png')  
    //   let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
    //   var position = 0;  
    //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
    //   pdf.save('MYPdf.pdf'); // Generated PDF   
    // });  
    // html2canvas(quotes).then(canvas => {

    //   // var pdf = new jsPDF('p', 'pt', 'letter');

    //   // for (var i = 0; i <= quotes.clientHeight/980; i++) {
    //   //     //! This is all just html2canvas stuff
    //   //     var srcImg  = canvas;
    //   //     var sX      = 0;
    //   //     var sY      = 980*i; // start 980 pixels down for every new page
    //   //     var sWidth  = 900;
    //   //     var sHeight = 980;
    //   //     var dX      = 0;
    //   //     var dY      = 0;
    //   //     var dWidth  = 900;
    //   //     var dHeight = 980;

    //   //     window.onePageCanvas = document.createElement("canvas");
    //   //     window.onePageCanvas.setAttribute('width', 900);
    //   //     window.onePageCanvas.setAttribute('height', 980);
    //   //     var ctx = window.onePageCanvas.getContext('2d');
    //   //     // details on this usage of this function: 
    //   //     // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
    //   //     ctx.drawImage(srcImg,sX,sY,sWidth,sHeight,dX,dY,dWidth,dHeight);

    //   //     // document.body.appendChild(canvas);
    //   //     var canvasDataURL = window.onePageCanvas.toDataURL("image/png", 1.0);

    //   //     var width         = window.onePageCanvas.width;
    //   //     var height        = window.onePageCanvas.clientHeight;

    //   //     //! If we're on anything other than the first page,
    //   //     // add another page
    //   //     if (i > 0) {
    //   //         pdf.addPage(612, 791); //8.5" x 11" in pts (in*72)
    //   //     }
    //   //     //! now we declare that we're working on that page
    //   //     pdf.setPage(i+1);
    //   //     //! now we add content to that page!
    //   //     pdf.addImage(canvasDataURL, 'PNG', 20, 40, (width*.62), (height*.62));

    //   // }
    //   // //! after the for loop is finished running, we save the pdf.
    //   // pdf.save('test.pdf');
    //     var imgData = canvas.toDataURL();
    //     var imgWidth = 210;
    //     var pageHeight = 295;
    //     var imgHeight = canvas.height * imgWidth / canvas.width;
    //     var heightLeft = imgHeight;
    //     var doc = new jsPDF({ // reinitialize doc
    //       orientation: "portrait",
    //       unit: "in",
    //       format: "letter"
    //     });
    //     var position = 0;

    //     //imgData);
    //     doc.addImage(imgData, 'JPEG', 0, 0, 8.5, 11);
    //     // heightLeft -= pageHeight;

    //     // while (heightLeft >= 0) {
    //     //     position = heightLeft - imgHeight;
    //     //     doc.addPage();
    //     //     doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //     //     heightLeft -= pageHeight;
    //     // }
    //     doc.save("duumy"+ '.pdf')
    //     // that.showLoader = false;
    //     // pdf.save('MYPdf.pdf'); // Generated PDF
    // });
  }

  pageChanged(value) {
    console.log("Current Page => " + value);
    // if (!(this.searchText.length > 0))
    this.p = value;
    this.filterObject.offset = value;
    if (this.searchText.length > 0) {
      this.getAllQuestionSearchQuestions();
    }
    else {
      this.getAllQuestion();
    }
  }

  onSearchTextChange() {
    if (this.searchText.length > 0)
      this.getAllQuestionSearchQuestions();
    else
      this.getAllQuestion();
  }

  changeRowsPerPage(value) {
    console.log("rowsPerPage => " + this.rowsPerPage);
    this.filterObject.limit = this.rowsPerPage;
    this.filterObject.offset = 1;
    this.getAllQuestion();
  }

  filterSource() {
    this.QuestionSources = [];
    this.selectedQuestionSource = [];

    if (this.idCourseType == 14)
      this.QuestionSources = this.orignalQuestionSource;

    if (this.idCourseType == 15) {
      this.orignalQuestionSource.
        forEach(source => {
          if (source['source_for'] != 16) {
            this.QuestionSources.push(source);
          }
        });
    }

    if (this.idCourseType == 16) {

      this.orignalQuestionSource.
        forEach(source => {
          if (source['source_for'] != 15) {
            this.QuestionSources.push(source);
          }
        });

    }

    this.questionSourceId = '0';

  }

  downloadSelectedQuestions() {
    this.selectedQuestions = this.Questions.filter(question => question.selected);
    //console.log(this.selectedQuestions);

    if (this.selectedQuestions.length === 0) {
      alert('Please select at least one question.');
      return;
    }

    this.isDownloadSelectedQuestionModal = true;
  }

  toggleSelectAll() {
    this.selectAll = !this.selectAll;
    this.Questions.map(data => data.selected = this.selectAll);
  }


}
