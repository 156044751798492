import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'
import { AppComponent } from './app.component';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component'
import { MenuComponent } from './menu/menu.component'
import { MasterComponent } from './master/master.component'
import { UserMasterComponent } from './user-master/user-master.component'
import { LoaderComponent } from './loader/loader.component'
import { CoeMasterComponent } from './coe-master/coe-master.component'
import { BatchMasterComponent } from './batch-master/batch-master.component'
import { SectionMasterComponent } from './section-master/section-master.component'
import { TopicMasterComponent } from './topic-master/topic-master.component'
import { FeedbackMasterComponent } from './feedback-master/feedback-master.component'
import { RankMasterComponent } from './rank-master/rank-master.component'
import { InstituteMasterComponent } from './institute-master/institute-master.component'
import { FilterArrayPipeForGroup } from './pipe';
import { ExamMasterComponent } from './exam-master/exam-master.component';
import { SyllabusMasterComponent } from './syllabus-master/syllabus-master.component';
import { AddSyllabusMasterComponent } from './add-syllabus-master/add-syllabus-master.component';
import { ExamResultComponent } from './exam-result/exam-result.component';
import { AuditComponent } from './audit/audit.component';
import { AttendanceMasterComponent } from './attendance-master/attendance-master.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SectionSyllabusComponent } from './section-syllabus/section-syllabus.component';
import { ScheduleMasterComponent } from './schedule-master/schedule-master.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CoordinatorDasboardComponent } from './coordinator-dasboard/coordinator-dasboard.component';
import { ReportsComponent } from './reports/reports.component';
import { TopicReportComponent } from './topic-report/topic-report.component';
import { TestModule } from './test/test.module';
import { SectionSyllabusReportComponent } from './section-syllabus-report/section-syllabus-report.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AlertViewComponent } from './alert-view/alert-view.component';
import { FacultyFeedbackComponent } from './faculty-feedback/faculty-feedback.component';
import { ScholarFacultyFeedbackComponent } from './scholar-faculty-feedback/scholar-faculty-feedback.component';
import { AttendanceReportComponent } from './attendance-report/attendance-report.component';
import { FeedbackHistoryComponent } from './feedback-history/feedback-history.component';
import { FacultyDashboardComponent } from './faculty-dashboard/faculty-dashboard.component';
import { ManagementDashboardComponent } from './management-dashboard/management-dashboard.component';
import { ScholarDashboardComponent } from './scholar-dashboard/scholar-dashboard.component';
import { TopicStatusReportComponent } from './topic-status-report/topic-status-report.component';
import { AdminAnalysisReportsComponent } from './admin-analysis-reports/admin-analysis-reports.component';
import { AlertComponent } from './alert/alert.component';
import { ScholarAnalysisComponent } from './scholar-analysis/scholar-analysis.component';
import { QulayfyingProbablityReportsComponent } from './qulayfying-probablity-reports/qulayfying-probablity-reports.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { SortingByPipe } from './sorting';
import { OnlineTestMastersComponent } from './online-test-masters/online-test-masters.component';
import { QuestionBankComponent } from './question-bank/question-bank.component';
import { ExamPatternComponent } from './exam-pattern/exam-pattern.component';
import { QuestionSourceComponent } from './question-source/question-source.component';
import { DifficultLevelComponent } from './difficult-level/difficult-level.component';
import { QuestionInterpretationComponent } from './question-interpretation/question-interpretation.component';
import { TimeForQuestionComponent } from './time-for-question/time-for-question.component';
import { QuestionPatternComponent } from './question-pattern/question-pattern.component';
import { ReviewRequirementComponent } from './review-requirement/review-requirement.component';
import { SingleCorrectOptionQuestionComponent } from './single-correct-option-question/single-correct-option-question.component';
import { MultipleCorrectOptionQuestionComponent } from './multiple-correct-option-question/multiple-correct-option-question.component';
import { IntegertypeQuestionComponent } from './integertype-question/integertype-question.component';
import { MatrixtypeQuestionComponent } from './matrixtype-question/matrixtype-question.component';
import { ReasoningQuestionComponent } from './reasoning-question/reasoning-question.component';
import { ComprehensionQuestionComponent } from './comprehension-question/comprehension-question.component';
import { OnlineTestTemplateComponent } from './online-test-template/online-test-template.component';
import { AddExamAptternComponent } from './add-exam-apttern/add-exam-apttern.component'
import { EditorModule } from '@tinymce/tinymce-angular';
import { OnlineExamComponent } from './online-exam/online-exam.component';
import { OnlineExamResultComponent } from './online-exam-result/online-exam-result.component';
import { OnlineExamResultDetailsComponent } from './online-exam-result-details/online-exam-result-details.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FacultyFeedbackAnalysisComponent } from './faculty-feedback-analysis/faculty-feedback-analysis.component';
import { ErrorReportComponent } from './error-report/error-report.component';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { BookMasterComponent } from './book-master/book-master.component';
import { BookTypeComponent } from './book-type/book-type.component';
import { BookTitleComponent } from './book-title/book-title.component';
import { EditionsComponent } from './editions/editions.component';
import { BookShelvesComponent } from './book-shelves/shelf.component';
import { BookQuantitiesComponent } from './book-quantities/book-quantities.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { BookCopiesComponent } from './book-copies/book-copies.component';
import { LibraryDashboardComponent } from './library-dashboard/library-dashboard.component';
import { LibraryDashboardContentComponent } from './library-dashboard-content/library-dashboard-content.component';
import { BookIssueReturnComponent } from './book-issue-return/book-issue-return.component';
import { LibraryReportComponent } from './library-report/library-report.component';
import { ScholarLibraryRecordComponent } from './scholar-library-record/scholar-library-record.component';
import { ScholarDocumentsComponent } from './scholar-documents/scholar-documents.component';
import { MyDocumentComponent } from './my-document/my-document.component';
import { DailyAttendanceReportComponent } from './daily-attendance-report/daily-attendance-report.component';

import { QuizComponent } from './quiz/quiz.component';
import { LectureGroupsMasterComponent } from './lecture-groups-master/lecture-groups-master.component';
import { Ng2Uploader } from './lecture-groups-master/services/ng2-uploader';
import { NgFileSelect } from './lecture-groups-master/NgFileSelect.directive';
import { OnlineLectureComponent } from './online-lecture/online-lecture.component';
import { QuizDoubtComponent } from './quiz-doubt/quiz-doubt.component';
import { ImageViewerModule } from 'ng2-image-viewer';
import { NoticeBoardComponent } from './notice-board/notice-board.component';
import { BookletSolutionComponent } from './booklet-solution/booklet-solution.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TokenInterceptor } from './token-interceptor';
import { LabelConfigurationComponent } from './label-configuration/label-configuration.component';
import { AlumnMasterComponent } from './alumn-master/alumn-master.component';
import { CandidateResponseComponent } from './candidate-response/candidate-response.component';
import { MatrixSingleCorrectOptionComponent } from './matrix-single-correct-option/matrix-single-correct-option.component';
import { NumericaltypeComponent } from './numericaltype/numericaltype.component';


const appRoutes: Routes = [{ path: '', component: LoginComponent },
{ path: "Dashboard", component: AdminDashboardComponent, runGuardsAndResolvers: 'always', },
{ path: 'Coordinator-Dashboard', component: CoordinatorDasboardComponent },
{ path: 'Management-Dashboard', component: ManagementDashboardComponent },
{ path: 'Faculty-Dashboard', component: FacultyDashboardComponent },
{ path: 'Scholar-Dashboard', component: ScholarDashboardComponent },
{ path: 'Online Exam Result', component: OnlineExamComponent },
{ path: 'label_configuration', component: LabelConfigurationComponent },
{ path: 'alumn_master', component: AlumnMasterComponent },
{ path: 'Master', component: MasterComponent },
{ path: "Reports", component: ReportsComponent },
{ path: "Exams", component: ExamMasterComponent },
{ path: "topic-report", component: TopicReportComponent },
{ path: "section-syllabus-report", component: SectionSyllabusReportComponent },
{ path: "attendance-report", component: AttendanceReportComponent },
{ path: "Audit", component: AuditComponent },
{ path: 'user master', component: UserMasterComponent },
{ path: 'coe master', component: CoeMasterComponent },
{ path: 'batch master', component: BatchMasterComponent },
{ path: "section master", component: SectionMasterComponent },
{ path: "topic master", component: TopicMasterComponent },
{ path: "feedback master", component: FeedbackMasterComponent },
{ path: "rank master", component: RankMasterComponent },
{ path: "institute master", component: InstituteMasterComponent },
{ path: "exam master", component: ExamMasterComponent },
{ path: "syllabus master", component: SyllabusMasterComponent },
{ path: "add syllabus", component: AddSyllabusMasterComponent },
{ path: "view_result", component: ExamResultComponent },
{ path: "view result/:coeName/:batchName/:sectionName/:examName/:date/:examId/:examMarks", component: ExamResultComponent },
{ path: "Attendance", component: AttendanceMasterComponent },
{ path: "section-syllabus", component: SectionSyllabusComponent },
{ path: "Schedule", component: ScheduleMasterComponent },
{ path: "scholar-faculty-feedback", component: ScholarFacultyFeedbackComponent },
{ path: "admin-faculty-feedback", component: FacultyFeedbackComponent },
{ path: "feedback-history", component: FeedbackHistoryComponent },
{ path: "topic-status-report", component: TopicStatusReportComponent },
{ path: "admin-anaylsis", component: AdminAnalysisReportsComponent },
{ path: "scholar-analysis", component: ScholarAnalysisComponent },
{ path: "online test masters", component: OnlineTestMastersComponent },
{ path: "question bank", component: QuestionBankComponent },
{ path: "Online Test Template", component: OnlineTestTemplateComponent },
{ path: "Add exam pattern", component: AddExamAptternComponent },
{ path: "online-exam-result-anaylsis/:id_exam_template/:id_pattern/:id_section", component: OnlineExamResultComponent },
{ path: 'online-exam-result-details/:id_exam_template/:coe_roll_number', component: OnlineExamResultDetailsComponent },
{ path: 'qualifying-probability', component: QulayfyingProbablityReportsComponent },
{ path: 'faculty-feedback-analysis/:id_faculty/:id_faculty_feedback_request/:faculty_name/:feedback_date', component: FacultyFeedbackAnalysisComponent },
{ path: "Error Report", component: ErrorReportComponent },
{ path: "books master", component: BookMasterComponent },
{ path: "libraryDashboard", component: LibraryDashboardComponent },
{ path: "bookIssueReturn", component: BookIssueReturnComponent },
{ path: "libraryReport", component: LibraryReportComponent },
{ path: "issuedorreturnedbook", component: ScholarLibraryRecordComponent },
{ path: "issuedorreturnedbook", component: ScholarLibraryRecordComponent },
{ path: "my-documents", component: MyDocumentComponent },
{ path: "daily-attendance-report", component: DailyAttendanceReportComponent },
{ path: "Quiz", component: QuizComponent },
{ path: "lecture groups", component: LectureGroupsMasterComponent },
{ path: "Lecture Groups", component: LectureGroupsMasterComponent },
{ path: "online-lecture", component: OnlineLectureComponent },
{ path: "Doubt", component: QuizDoubtComponent },
{ path: "Notice Board", component: NoticeBoardComponent },
{ path: "candidate-response", component: CandidateResponseComponent }


]
@NgModule({
  declarations: [	
    AppComponent, LoaderComponent, LoginComponent, MenuComponent,
    MasterComponent, UserMasterComponent, FilterArrayPipeForGroup, AlertComponent,
    CoeMasterComponent, BatchMasterComponent, SectionMasterComponent,
    TopicMasterComponent, FeedbackMasterComponent, RankMasterComponent,
    InstituteMasterComponent, ExamMasterComponent, SyllabusMasterComponent,
    AddSyllabusMasterComponent, ExamResultComponent, AuditComponent,
    AttendanceMasterComponent, SectionSyllabusComponent, ScheduleMasterComponent,
    CoordinatorDasboardComponent, ReportsComponent, TopicReportComponent,
    SectionSyllabusReportComponent, AdminDashboardComponent,
    AlertViewComponent, FacultyFeedbackComponent,
    ScholarFacultyFeedbackComponent, AttendanceReportComponent,
    FeedbackHistoryComponent, FacultyDashboardComponent,
    ManagementDashboardComponent, ScholarDashboardComponent,
    TopicStatusReportComponent, AdminAnalysisReportsComponent,
    ScholarAnalysisComponent, QulayfyingProbablityReportsComponent,
    LineChartComponent, BarChartComponent, PieChartComponent,
    SortingByPipe, OnlineTestMastersComponent, QuestionBankComponent,
    ExamPatternComponent, QuestionSourceComponent, DifficultLevelComponent,
    QuestionInterpretationComponent, TimeForQuestionComponent,
    QuestionPatternComponent, ReviewRequirementComponent,
    SingleCorrectOptionQuestionComponent,
    MultipleCorrectOptionQuestionComponent,
    IntegertypeQuestionComponent, MatrixtypeQuestionComponent,
    ReasoningQuestionComponent, ComprehensionQuestionComponent,
    OnlineTestTemplateComponent, AddExamAptternComponent, OnlineExamComponent,
    OnlineExamResultComponent, OnlineExamResultDetailsComponent, FacultyFeedbackAnalysisComponent,
    ErrorReportComponent, BookMasterComponent, BookTypeComponent, BookTitleComponent,
    EditionsComponent, BookShelvesComponent, BookQuantitiesComponent, BookCopiesComponent,
    LibraryDashboardComponent, LibraryDashboardContentComponent, BookIssueReturnComponent,
    LibraryReportComponent, ScholarLibraryRecordComponent, ScholarDocumentsComponent,
    MyDocumentComponent, DailyAttendanceReportComponent, QuizComponent, LectureGroupsMasterComponent,
    NgFileSelect, OnlineLectureComponent, QuizDoubtComponent, NoticeBoardComponent, BookletSolutionComponent, LabelConfigurationComponent, AlumnMasterComponent, CandidateResponseComponent, MatrixSingleCorrectOptionComponent, NumericaltypeComponent
      
   ],
  imports: [
    NgxBarcodeModule, ReactiveFormsModule, ImageViewerModule,
    BrowserModule, HttpModule, HttpClientModule, ChartsModule,
    RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' }),
    FormsModule, CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    NgxMaterialTimepickerModule, EditorModule, NgxPaginationModule, AmazingTimePickerModule, BrowserAnimationsModule,
    NgMultiSelectDropDownModule.forRoot(),
    MDBBootstrapModule.forRoot()
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }, ThemeService],
  bootstrap: [AppComponent]
})
export class AppModule { }
