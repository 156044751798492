import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class OnlineExamService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }
    getAllExam(email, userId, sectionId,) {

        return this.http.get(this.baseUrl + "OnlineExam/getAllExam?email=" + email + "&id_user=" + userId + "&id_section=" + sectionId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllCoe(email) {
        return this.http.get(this.baseUrl + "/Coe/getAllActiveCoe?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllBatches(email) {
        return this.http.get(this.baseUrl + "/Batch/getAllActiveBatch?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllSections(email) {
        return this.http.get(this.baseUrl + "/Section/getAllActiveSection?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getOnlineExamResultForExams(jsonData) {

        return this.http.post(this.baseUrl + "OnlineExam/getOnlineExamResultForExams", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAbsentStudentList(jsonData) {

        return this.http.post(this.baseUrl + "OnlineExam/getAbsentStudentList", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

}