import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class QuestionSourceService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }

    getAllQuestionSources(email) {
        return this.http.get(this.baseUrl + "/TestMaster/getAllQuestionSources?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    addQuestionResource(jsonData) {
        //.log(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "TestMaster/addQuestionResource", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }
    editQuestionResource(jsonData) {
        //.log(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "TestMaster/editQuestionResource", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeQuestion(questionId, email) {
        return this.http.get(this.baseUrl + "/TestMaster/removeQuestionSource?caller_email=" + email + "&id_question_source=" + questionId).map((res) => JSON.parse(JSON.stringify(res)));
    }


}