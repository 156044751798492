import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SectionSyllabusReportService } from './section-syllabus-report.service'
import swal from 'sweetalert2'
import { Observable } from 'rxjs/Rx';
import * as moment from 'moment';
@Component({
  selector: 'app-section-syllabus-report',
  templateUrl: './section-syllabus-report.component.html',
  styleUrls: ['./section-syllabus-report.component.css'],
  providers: [SectionSyllabusReportService]
})
export class SectionSyllabusReportComponent implements OnInit {
  showLoader = false
  Coes = [];
  coeId = "0";
  Batches = [];
  unfiltersBatch = [];
  batchId = "0";
  Sections = [];
  unfiltersSection = [];
  sectionId = "0";
  UnfilterdReport = [];
  FilterdReport = [];
  Faculty = [];
  UnfilteredFaculty = [];
  facultyId = "0";
  alertDiv = false;
  sucessAlertMessage: any;
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  column: string = 'CategoryName';
  direction: number;
  isDesc: boolean = false;
  constructor(private sectionSyllabusReportService: SectionSyllabusReportService, private router: Router) {
    if (localStorage.getItem("isLogin") == "true") {
      this.getMasterData()
    }
    else {
      this.router.navigateByUrl('/');
    }
  }


  ngOnInit() {
  }

  getMasterData() {
    this.showLoader = true;
    this.sectionSyllabusReportService.getAllMasterData(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.Coes = data.coes;
          this.unfiltersBatch = data.batches;
          this.unfiltersSection = data.sections;
          this.UnfilteredFaculty = data.all_faculty;
          // this.Faculty = data.all_faculty
          this.getSectionSyllabusReports();
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false
      }
    );
  }

  getSectionSyllabusReports() {
    this.showLoader = true;
    this.sectionSyllabusReportService.getSectionSyllabusReports(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.UnfilterdReport = data.reports
          this.FilterdReport = data.reports
          //this.getData();
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false
      }
    );
  }

  getActualScheduleHours(hours, completedHours) {
    //var tempHoursArray=hours.splice('.');
    return this.differnceBetweenHours(hours, this.convertMinutesInHour(completedHours, hours))
  }
  getVarionBetweenMasterAndSection(sectionHours, masterHours) {
    return this.differnceBetweenHours(sectionHours, masterHours)
  }
  filterReport(type?) {
    this.Batches = [];
    this.Sections = [];
    this.Faculty = []
    if (type == 'coe') {
      this.batchId = "0";
      this.sectionId = "0"
    }
    else if (type == 'batch') {
      this.sectionId = "0"
    }

    this.FilterdReport = this.UnfilterdReport
    if (this.coeId != "0") {
      var temporaryArray = [];

      for (let data of this.FilterdReport) {
        if (data.id_coe == this.coeId) {
          temporaryArray.push(data);

        }
      }
      for (let data of this.unfiltersBatch) {
        if (data.id_coe == this.coeId) {
          this.Batches.push(data);

        }
      }
      this.FilterdReport = temporaryArray;
    }
    if (this.batchId != "0") {
      var temporaryArray = [];
      this.Sections = []
      for (let data of this.FilterdReport) {
        if (data.id_batch == this.batchId) {
          temporaryArray.push(data);

        }
      }
      for (let data of this.unfiltersSection) {
        if (data.id_batch == this.batchId) {
          this.Sections.push(data);

        }
      }
      this.FilterdReport = temporaryArray;
    }
    if (this.sectionId != "0") {
      var temporaryArray = [];
      for (let data of this.UnfilteredFaculty) {
        if (data.id_section == this.sectionId) {
          this.Faculty.push(data);

        }
      }
      for (let data of this.FilterdReport) {
        if (data.id_section == this.sectionId) {
          temporaryArray.push(data);

        }
      }
      this.FilterdReport = temporaryArray;
    }
    if (this.facultyId != "0") {
      var temporaryArray = [];
      for (let data of this.FilterdReport) {
        if (data.id_faculty == this.facultyId) {
          temporaryArray.push(data);

        }
      }
      this.FilterdReport = temporaryArray;
    }

  }
  showAlertDiv(message) {
    var that = this
    that.alertDiv = true
    this.sucessAlertMessage = message
    setTimeout(function () {
      that.alertDiv = false
    }, 5000);
  }

  onBack() {
    this.router.navigateByUrl('/Reports')
  }

  getActualDays(scheduleDate, actualDate) {
    if (actualDate == null) {
      return ""
    }
    var a = moment(scheduleDate, 'YYYY-MM-DD');
    var b = moment(actualDate, 'YYYY-MM-DD');
    var days = b.diff(a, 'days');
    return days
  }

  differnceBetweenHours(firstHours, secondHours) {
    var temp = firstHours.split(".")
    var decimalFirstMinutes = parseInt(temp[0]) * 60
    var decimalSecondMinutes = parseInt(temp[1])
    var totalMinutes = decimalFirstMinutes + decimalSecondMinutes
    //("totalMinutes-->" + totalMinutes)
    var temp1 = secondHours.split(".")
    var decimalFirstMinutes1 = parseInt(temp1[0]) * 60
    var decimalSecondMinutes1 = parseInt(temp1[1])
    var totalMinutes1 = decimalFirstMinutes1 + decimalSecondMinutes1
    //("totalMinutes1-->" + totalMinutes1)
    var differnce = totalMinutes1 - totalMinutes
    //("differnce-->" + differnce)
    var sign = ""
    if (differnce < 0) {
      sign = "-"
      differnce = differnce * (-1)
    }
    {
      //("differnce-->" + differnce)
      var hours = Math.floor(differnce / 60);
      var minutes = differnce % 60;
      //("hours-->" + hours)
      //("minutes-->" + minutes)

      if (minutes < 10) {
        return sign + hours + ".0" + minutes
      }
      else {
        return sign + hours + "." + minutes
      }

    }
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    //("status" + status);
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }

  print() {
    let printContents, popupWin;
    printContents = document.getElementById('printSection').innerHTML;
    setTimeout(function () {

      var htmlToPrint = '' +
        '<style type="text/css">' +
        'table th, table td {' +
        'border:1px solid #000;' +
        'padding;0.5em;' +
        '}' +
        '</style>';
      htmlToPrint += printContents;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
          <html>
              <head>
                 
                  <style>
                      //........Customized style.......
                  </style>
              </head>
              <body onload="window.print();window.close()">${htmlToPrint}

              </body>
          </html>`
      );
      popupWin.document.close();
    }, 10)
  }

  getTotalVarHours() {
    var totalHours = "0.0"
    for (let data of this.FilterdReport) {
      let temp = this.getActualScheduleHours(data.hours, data.completed_minutes)
      totalHours = this.sumOfHours(totalHours, temp);
    }
    if ((+totalHours) < 0.0) {
      return (+totalHours) * -1
    }
    else {
      return totalHours
    }


  }
  sumOfHours(firstHours, secondHours) {
    var temp = firstHours.split(".")
    var decimalFirstMinutes = parseInt(temp[0]) * 60
    var decimalSecondMinutes = parseInt(temp[1])
    var totalMinutes = decimalFirstMinutes + decimalSecondMinutes
    //("totalMinutes-->" + totalMinutes)
    var temp1 = secondHours.split(".")
    var decimalFirstMinutes1 = parseInt(temp1[0]) * 60
    var decimalSecondMinutes1 = parseInt(temp1[1])
    var totalMinutes1 = decimalFirstMinutes1 + decimalSecondMinutes1
    //("totalMinutes1-->" + totalMinutes1)
    var differnce = totalMinutes1 + totalMinutes
    //("differnce-->" + differnce)
    var sign = ""
    if (differnce < 0) {
      sign = "-"
      differnce = differnce * (-1)
    }
    {
      //("differnce-->" + differnce)
      var hours = Math.floor(differnce / 60);
      var minutes = differnce % 60;
      //("hours-->" + hours)
      //("minutes-->" + minutes)

      if (minutes < 10) {
        return sign + hours + ".0" + minutes
      }
      else {
        return sign + hours + "." + minutes
      }

    }
  }

  getTotalVarDays() {
    var totalDays = 0
    for (let data of this.FilterdReport) {
      let temp = this.getActualDays(data.shedule_completion_date, data.topic_completed_date)
      totalDays = (+totalDays) + (+temp);
    }
    if ((+totalDays) < 0) {
      return (+totalDays) * -1
    }
    else {
      return totalDays
    }
  }

  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.FilterdReport.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  }

  convertMinutesInHour(minutes, totalHours) {
    if (minutes != null) {
      var hour = Math.floor(parseInt(minutes) / 60)
      var newMinutes = Math.floor(parseInt(minutes) % 60)
      var temp = hour + "." + newMinutes
      if (parseFloat(temp) > totalHours) {
        return totalHours
      }
      else {
        return hour + "." + newMinutes
      }

    }
    else return "0"
  }
}
