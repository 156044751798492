import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class QuestionBankService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain; charset=utf-8' });
        this.options = new RequestOptions({ headers: this.headers });

    }
    getMasterDataForQuestionBank(email) {
        return this.http.get(this.baseUrl + "/QuestionBank/getMasterDataForQuestionBank?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }
    getAllQuestion(email) {
        return this.http.get(this.baseUrl + "/QuestionBank/getAllQuestion?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }
    removeQuestion(comprehensionId, questionId, email) {
        return this.http.get(this.baseUrl + "/QuestionBank/removeQuestion?caller_email=" + email + "&id_question=" + questionId + "&id_comprehension=" + comprehensionId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllQuestionWithPagination(json) {
        return this.http.post(this.baseUrl + "/QuestionBank/getAllQuestionWithPagination", json).map((res) => JSON.parse(JSON.stringify(res)));
    }
    getAllQuestionWithPaginationSearchText(json) {
        return this.http.post(this.baseUrl + "/QuestionBank/getAllQuestionBySearch", json).map((res) => JSON.parse(JSON.stringify(res)));
    }
}