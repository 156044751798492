import { Component, OnInit } from '@angular/core';
import { AdminDashBoardService } from './admin-dashboard.service'
import { Router } from '@angular/router'
import { Helper } from '../helper'
import swal from 'sweetalert2'
@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css'],
  providers: [AdminDashBoardService, Helper]
})
export class AdminDashboardComponent implements OnInit {
  Coes = []
  coeId = "0"
  sectionId = "0"
  Sections = []
  unfileterdSections = []
  showLoader = false
  Attendance = []
  public attendancebarChartLabels: String[];
  public attendancebarChartType: string;
  public attendancebarChartLegend = false;
  public attendancebarChartData: any[]
  public syllabusbarChartLabels: String[];
  public syllabusbarChartType: string;
  public syllabusbarChartLegend = false;
  public syllabusbarChartData: any[]
  CoeSyllabus = [];
  Subjects = [];
  SubjectGraph = [];
  CoeLectureGraph = [];
  CoeLectures = [];
  public lecturebarChartLabels: String[];
  public lecturebarChartType: string;
  public lecturebarChartLegend = false;
  public lecturebarChartData: any[];
  Years = []
  Months = [];
  selectedYear: any;
  selectedMonth: any;
  public schlarPerformancebarChartLabels: String[];
  public schlarPerformancebarChartType: string;
  public schlarPerformancebarChartLegend = false;
  public schlarPerformancebarChartData: any[];
  ScholarSelectionProbabilty = [];
  constructor(private adminDashBoardService: AdminDashBoardService, private router: Router, private helper: Helper) {
    this.Years = helper.getArrayOfYears();
    this.Months = helper.getArrayOfMonths();
    this.selectedYear = this.Years[0];
    this.selectedMonth = (new Date()).getMonth() + 1;
    if (localStorage.getItem("isLogin") == "true") {
      this.getAdminDashBoardMaster();

    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  getAdminDashBoardMaster() {
    this.showLoader = true
    this.adminDashBoardService.getAdminDashBoardMaster(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.Coes = data.coes
          this.Sections = data.sections
          this.unfileterdSections = data.sections
          this.coeId = data.coes[0]['id_coe'];
          this.sectionId = data.sections[0]['id_section'];
          //this.getAdminDashBoardScholarQualifyingProbability();
          //this.getGraphs();

          this.generateScholarPerforamnceGraph();
          this.getDashboardGraphData();

        }
      },
      err => {
        this.showLoader = false
      }
    )
  }

  getAdminDashBoardScholarQualifyingProbability() {
    this.showLoader = true
    this.adminDashBoardService.getScholarPerformanceGraphAdminDashBoard(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.ScholarSelectionProbabilty = data.selection_probability
          this.generateScholarPerforamnceGraph();
          this.getDashboardGraphData();
        }
      },
      err => {
        this.showLoader = false
      }
    )
  }

  getGraphs() {
    localStorage.setItem("id_section", this.sectionId);
    localStorage.setItem("id_coe", this.coeId);
    localStorage.setItem("selected_year", this.selectedYear);
    localStorage.setItem("selected_month", this.selectedMonth);
    this.redirectTo('/Dashboard');
  }

  getDashboardGraphData() {
    this.showLoader = true
    if (localStorage.getItem("id_section") != null) {
      this.sectionId = localStorage.getItem("id_section")
      this.coeId = localStorage.getItem("id_coe")
      this.selectedYear = localStorage.getItem("selected_year")
      this.selectedMonth = localStorage.getItem("selected_month")
      if (this.coeId != "0") {
        this.Sections = [];
        for (let data of this.unfileterdSections) {
          if (data.id_coe == this.coeId) {
            this.Sections.push(data);
          }
        }
      }

    }
    this.adminDashBoardService.getDashboardGraphData(localStorage.getItem("email"), this.selectedYear, this.selectedMonth, this.coeId, this.sectionId).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.Attendance = data.attendance
          this.CoeSyllabus = data.coe_syllabus
          this.Subjects = data.subject
          this.CoeLectures = data.coe_lecture
          this.checkGraphDataAvailableOrNot();
          this.generatAttendanceGraph()
          this.generatSyllabusGraph();
          this.generatSubjectGraph();
          this.generatCoeLectureGraph();
        }
      },
      err => {
        this.showLoader = false
      }
    )
  }

  checkGraphDataAvailableOrNot() {
    var checkAttandance = false
    var checkCoeLectures = false
    var checkCoeSyllabus = false
    var checkSubjects = false
    for (let data of this.Attendance) {
      if (data.class_attendence == "0" && data.hostel_attendence == "0") {
        checkAttandance = true
      }
      else {
        checkAttandance = false
        break
      }
    }
    for (let data of this.CoeLectures) {
      if (data.completed == "0" && data.not_completed == "0") {
        checkCoeLectures = true
      }
      else {
        checkCoeLectures = false
        break
      }
    }
    for (let data of this.CoeSyllabus) {
      if (data.completed_syllabus == "0" && data.not_completed_syllabus == "0") {
        checkCoeSyllabus = true
      }
      else {
        checkCoeSyllabus = false
        break
      }
    }
    for (let data of this.Subjects) {
      if (data.completed == "0" && data.not_completed == "0") {
        checkSubjects = true
      }
      else {
        checkSubjects = false
        break
      }
    }

    if (checkAttandance == true && checkCoeLectures == true && checkCoeSyllabus == true && checkSubjects == true) {
      swal.fire("There isn't any data to display. A chart will appear here if you add some data for time period.")
    }
  }
  generateScholarPerforamnceGraph() {
    this.schlarPerformancebarChartType = 'bar';
    this.schlarPerformancebarChartLegend = true;
    this.schlarPerformancebarChartLabels = [];
    this.schlarPerformancebarChartData = []
    for (let data of this.ScholarSelectionProbabilty) {
      this.schlarPerformancebarChartLabels.push(data.section_name)
    }
    var iitData = []
    for (let data of this.ScholarSelectionProbabilty) {
      iitData.push(parseInt(data.iit_probability))
    }
    var nitData = []
    for (let data of this.ScholarSelectionProbabilty) {
      nitData.push(parseInt(data.nit_probability))
    }
    var neetData = []
    for (let data of this.ScholarSelectionProbabilty) {
      neetData.push(parseInt(data.neet_probability))
    }
    this.schlarPerformancebarChartData = [
      { data: iitData, label: 'IIT' },
      { data: nitData, label: 'NIT' },
      { data: neetData, label: 'NEET' }
    ];
  }
  generatAttendanceGraph() {
    //attendance  graph

    this.attendancebarChartType = 'bar';
    this.attendancebarChartLegend = true;
    this.attendancebarChartLabels = [];
    this.attendancebarChartData = []
    for (let data of this.Attendance) {
      this.attendancebarChartLabels.push(data.coe_name)
    }
    var classData = []
    for (let data of this.Attendance) {
      classData.push(parseInt(data.class_attendence))
    }
    var hostelData = []
    for (let data of this.Attendance) {
      hostelData.push(parseInt(data.hostel_attendence))
    }
    this.attendancebarChartData = [
      { data: classData, label: 'Class' },
      { data: hostelData, label: 'Hostel' }
    ];
  }


  generatCoeLectureGraph() {
    //lecture  graph

    this.lecturebarChartType = 'bar';
    this.lecturebarChartLegend = true;
    this.lecturebarChartLabels = [];
    this.lecturebarChartData = [];
    for (let data of this.CoeLectures) {
      this.lecturebarChartLabels.push(data.coe_name)
    }
    var completed = []
    for (let data of this.CoeLectures) {
      completed.push(parseInt(data.completed))
    }
    var notCompleted = []
    for (let data of this.CoeLectures) {
      notCompleted.push(parseInt(data.not_completed))
    }
    this.lecturebarChartData = [
      { data: completed, label: 'Number of lecture conducted On time' },
      { data: notCompleted, label: 'not in time' }
    ];
  }

  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,

    scales: {
      yAxes: [{
        ticks: {
          min: 0,
          max: 100,
          stepSize: 25
        }
      }]
    }
  };


  generatSyllabusGraph() {
    //attendance  graph

    this.syllabusbarChartType = 'bar';
    this.syllabusbarChartLegend = true;
    this.syllabusbarChartLabels = [];
    this.syllabusbarChartData = [];
    for (let data of this.CoeSyllabus) {
      this.syllabusbarChartLabels.push(data.coe_name)
    }
    var completed = []
    for (let data of this.CoeSyllabus) {
      completed.push(parseInt(data.completed_syllabus))
    }
    var notCompleted = []
    for (let data of this.CoeSyllabus) {
      notCompleted.push(parseInt(data.not_completed_syllabus))
    }
    this.syllabusbarChartData = [
      { data: completed, label: 'Completed' },
      { data: notCompleted, label: 'Not-completed' }
    ];
  }


  generatSubjectGraph() {
    this.SubjectGraph = [];
    for (let data of this.Subjects) {
      //subject graph
      var pieChartLabels: string[] = ['Completed', 'Not-completed'];
      var completed = (data.completed).toFixed(2);
      var not_completed = (data.not_completed).toFixed(2);
      var pieChartData: number[] = [completed, not_completed];
      let json = {
        "subject_name": data.subject_name,
        "pieChartType": 'pie',
        "pieChartLabels": pieChartLabels,
        "pieChartData": pieChartData
      }

      this.SubjectGraph.push(json)
    }

  }

  // events
  public chartClicked(e: any): void {
    
  }

  public chartHovered(e: any): void {
    
  }

  getSections() {
    this.Sections = [];
    if (this.coeId != "0") {
      for (let data of this.unfileterdSections) {
        if (data.id_coe == this.coeId)
          this.Sections.push(data);
      }
    }
    else {
      this.sectionId = "0"
      this.Sections = this.unfileterdSections
    }
  }

  getGraphData() {
    if (this.sectionId == "0") {

      this.getDashboardGraphData();

    }
    else {
      localStorage.setItem("id_section", this.sectionId);
      localStorage.setItem("id_coe", this.coeId);
      localStorage.setItem("selected_year", this.selectedYear);
      localStorage.setItem("selected_month", this.selectedMonth);
      this.redirectTo('/Dashboard');
    }
  }
  redirectTo(uri: string) {
    this.router.navigateByUrl('/Exams', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }
  getDashboardGraphDataBySectionId() {
    this.showLoader = true
    this.adminDashBoardService.getDashboardGraphDataBySectionId(localStorage.getItem("email"), this.sectionId, this.selectedYear, this.selectedMonth).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.Attendance = data.attendance
          this.CoeSyllabus = data.coe_syllabus
          this.Subjects = data.subject
          this.CoeLectures = data.coe_lecture;
          this.attendancebarChartLegend = false;
          this.syllabusbarChartLegend = false;
          this.lecturebarChartLegend = false;
          this.SubjectGraph = [];
          this.generatAttendanceGraph()
          this.generatSyllabusGraph();
          this.generatSubjectGraph();
          this.generatCoeLectureGraph();
        }
      },
      err => {
        this.showLoader = false
      }
    )
  }

  public chartColors: Array<any> = [
    { // blue
      backgroundColor: 'rgba(123,104,238)',
      borderColor: 'rgba(123,104,238)',
      pointBackgroundColor: 'rgba(123,104,238)',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(123,104,238)'
    },
    { // purple
      backgroundColor: 'rgb(157, 68, 122)',
      borderColor: 'rgb(157, 68, 122)',
      pointBackgroundColor: 'rgb(157, 68, 122)',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(157, 68, 122)'
    },
    { // green
      backgroundColor: 'rgb(66, 142, 99)',
      borderColor: 'rgb(66, 142, 99)',
      pointBackgroundColor: 'rgb(66, 142, 99)',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(66, 142, 99)'
    },

    { // green
      backgroundColor: 'rgb(254, 119, 123)',
      borderColor: 'rgb(254, 119, 123)',
      pointBackgroundColor: 'rgb(254, 119, 123)',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(254, 119, 123)'
    }


  ];

  public pieChartColors: Array<any> = [
    {
      backgroundColor: [
        'rgba(123,104,238)',
        'rgb(157, 68, 122)',
        'rgb(66, 142, 99)',
        'rgb(66, 142, 99)',
      ]
    }


  ];

}
