import { Component } from '@angular/core';
import { SectionMasterService } from './section-master.service'
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2'

@Component({
    moduleId: module.id,
    selector: 'section-master',
    templateUrl: 'section-master.component.html',
    styleUrls: ['section-master.component.scss'],
    providers: [SectionMasterService]
})
export class SectionMasterComponent {
    showLoader: boolean
    Coes: any;
    statusMessage: any;
    Batches: any;
    coeId: any;
    batchId: any;
    unfilterdBatches: any;
    rollingFactor: any;
    sectionName: any
    isAddModal: boolean
    modelHeadelLabel: any
    purpose: any
    Sections: any;
    unfilterdSections: any;
    previousCoeId: any;
    previousBatchId: any;
    sectionId: any;
    Scholars = [];
    showDetails = false;
    sectioName: any;
    serachScholarMappingText: any;
    searchText: any;
    alertDiv = false;
    sucessAlertMessage: any;
    showAlert: boolean;
    alertMsg: any;
    alertType = "";
    column: string = 'CategoryName';
    direction: number;
    isDesc: boolean = false;
    constructor(private sectionMasterService: SectionMasterService, private router: Router, private activatedRoute: ActivatedRoute) {
        this.showLoader = false;
        this.isAddModal = false;
        this.Sections = [];
        this.unfilterdSections = [];
        this.purpose = "Add"
        this.modelHeadelLabel = "Add Section"
        this.coeId = "0"
        this.batchId = "0"
        this.previousCoeId = "0"
        this.previousBatchId = "0"
        this.statusMessage = "No records found"
        if (localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "1") {

            this.getAllCoe();

        }
        else {
            this.router.navigateByUrl('/');
        }
    }

    getAllCoe() {
        this.Coes = [];
        this.showLoader = true
        this.sectionMasterService.getAllCoe(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {
                    this.Coes = data["coes"];
                    this.getAllBatches();
                }
                else {
                    this.showAlertDiv(data.status_message)
                }
                if (this.Coes.length == 0) {
                    this.statusMessage = "No data found for CoE master, first add it.";
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }

    getAllBatches() {
        this.Batches = []
        this.showLoader = true
        this.sectionMasterService.getAllBatches(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {
                    //  this.Batches = data["batches"];
                    this.unfilterdBatches = data["batches"];
                    this.activatedRoute.queryParams.subscribe(params => {
                        if (params.coeId) {
                            this.batchId = params.batchId
                            this.previousBatchId = params.batchId
                            this.coeId = params.coeId
                        }
                    });
                    this.getAllSections()
                }
                else {
                    this.showAlertDiv(data.status_message)
                }
                if (this.Batches.length == 0) {
                    this.statusMessage = "No data found for Batch master, first add it.";
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }

    getAllSections() {
        this.Sections = [];
        this.unfilterdSections = [];
        this.showLoader = true
        this.sectionMasterService.getAllSections(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {
                    this.Sections = data["sections"];
                    this.showScholar(this.Sections[0])
                    this.unfilterdSections = data["sections"];
                    this.fillterData('batch');
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
                else {
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }
    fillterData(type: any) {
        // this.Batches = this.unfilterdBatches
        this.Sections = this.unfilterdSections
        this.showDetails = false;

        this.previousCoeId = this.coeId;
        this.previousBatchId = this.batchId;
        if (type == 'batch') {
            this.batchId = "0"
        }
        if (this.previousBatchId != "0") {
            this.batchId = this.previousBatchId
        }

        //(this.batchId)
        this.previousCoeId = this.coeId;
        this.previousBatchId = this.batchId;
        if (this.coeId != "0") {
            this.Batches = [];
            this.Sections = [];
            for (let data of this.unfilterdBatches) {
                if (data.id_coe == this.coeId) {
                    this.Batches.push(data)
                }
            }
            this.Sections = [];
            for (let data of this.unfilterdSections) {
                if (data.id_coe == this.coeId) {
                    this.Sections.push(data)
                }
            }

        }
        if (this.batchId != "0") {
            this.Sections = [];
            for (let data of this.unfilterdSections) {
                if (data.id_batch == this.batchId) {
                    this.Sections.push(data)
                }
            }

        }
    }
    hideAddEditModal() {
        this.isAddModal = false
        this.clearData()
        this.modelHeadelLabel = "Add Section"
    }
    addNew() {
        {
            this.isAddModal = true
            this.purpose = "Add"
            this.clearData()
            this.modelHeadelLabel = "Add Section"
        }

    }

    save() {
        if (!this.coeId || this.coeId == "0") {
            swal.fire("Please select CoE.")
        }
        else if (!this.batchId || this.batchId == "0") {
            swal.fire("Please select Batch.")
        }
        else if (!this.sectionName) {
            swal.fire("Please enter section name.")
        }
        else if (!this.rollingFactor) {
            swal.fire("Please enter rolling factor.")
        }
        else if (parseInt(this.rollingFactor) < 0 || parseInt(this.rollingFactor) > 1) {
            swal.fire("Please enter correct rolling factor.")
        }
        else {
            if (this.purpose == "Add") {
                let json = {
                    "caller_email": localStorage.getItem("email"),
                    "id_coe": this.coeId,
                    "id_batch": this.batchId,
                    "section_name": this.sectionName,
                    "rolling_factor": this.rollingFactor
                }
                this.showLoader = true
                this.sectionMasterService.addNewSection(json).subscribe(
                    data => {
                        this.showLoader = false;
                        if (data.status_code == 0) {
                            this.Sections = data["sections"];
                            this.showScholar(this.Sections[0])
                            this.unfilterdSections = data["sections"];
                            this.isAddModal = false;
                            this.clearData()
                            this.showAlertComponent(data['status_code'], data['status_message']);
                            if (this.coeId != "0" && this.batchId == "0") {
                                this.fillterData('batch')
                            }
                            else if (this.coeId != "0" && this.batchId != "0") {
                                this.fillterData('section')
                            }
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.showLoader = false;
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }
            else {
                let json = {
                    "id_section": this.sectionId,
                    "caller_email": localStorage.getItem("email"),
                    "id_coe": this.coeId,
                    "id_batch": this.batchId,
                    "section_name": this.sectionName,
                    "rolling_factor": this.rollingFactor
                }
                this.showLoader = true
                this.sectionMasterService.editSection(json).subscribe(
                    data => {
                        this.showLoader = false;
                        if (data.status_code == 0) {
                            this.Sections = data["sections"];
                            this.showScholar(this.Sections[0])
                            this.unfilterdSections = data["sections"];
                            this.isAddModal = false;
                            this.clearData()
                            this.showAlertComponent(data['status_code'], data['status_message']);
                            if (this.coeId != "0" && this.batchId == "0") {
                                this.fillterData('batch')
                            }
                            else if (this.coeId != "0" && this.batchId != "0") {
                                this.fillterData('section')
                            }
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.showLoader = false;
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }
        }

    }

    editSection(data) {
        this.sectionId = data.id_section
        this.coeId = data.id_coe
        this.purpose = "Edit"
        this.modelHeadelLabel = "Edit Section"
        this.batchId = data.id_batch
        this.sectionName = data.name
        this.rollingFactor = data.rolling_factor
        this.isAddModal = true
    }
    removeSection(data) {
        swal.fire({
            title: '',
            text: "Deleting an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to remove this section?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#428e63',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.value) {
                this.Sections = [];
                this.unfilterdSections = [];
                this.showLoader = true;
                this.sectionMasterService.removeSection(data.id_section, localStorage.getItem("email")).subscribe(
                    data => {
                        this.showLoader = false;
                        if (data.status_code == 0) {
                            this.Sections = data["sections"];
                            this.showScholar(this.Sections[0])
                            this.unfilterdSections = data["sections"];
                            this.isAddModal = false;
                            this.clearData()
                            this.showAlertComponent(data['status_code'], data['status_message']);
                            if (this.coeId != "0" && this.batchId == "0") {
                                this.fillterData('batch')
                            }
                            else if (this.coeId != "0" && this.batchId != "0") {
                                this.fillterData('section')
                            }
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.showLoader = false;
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }
        })

    }
    clearData() {
        this.coeId = this.previousCoeId
        this.batchId = this.previousBatchId
        this.purpose = "Add"
        this.sectionName = ""
        this.rollingFactor = "";
    }

    changeStatus(data) {
        var statusId: any
        if (data.id_status == "0") {
            statusId = "1"
        }
        else {
            statusId = "0"
        }
        if (statusId == 1) {
            swal.fire({
                title: '',
                text: "Deactivating an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to deactivate this Section?",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#8B0000',
                cancelButtonColor: '#428e63',
                confirmButtonText: "Yes, deactivate it!"
            }).then((result) => {
                if (result.value) {

                    this.showLoader = true;
                    this.sectionMasterService.changeStatus(data.id_section, localStorage.getItem("email"), statusId).subscribe(
                        data => {
                            this.showLoader = false;
                            if (data.status_code == 0) {
                                this.Sections = data["sections"];
                                this.showScholar(this.Sections[0])
                                this.unfilterdSections = data["sections"];
                                this.isAddModal = false;
                                this.clearData()
                                this.showAlertComponent(data['status_code'], data['status_message']);
                                if (this.coeId != "0" && this.batchId == "0") {
                                    this.fillterData('batch')
                                }
                                else if (this.coeId != "0" && this.batchId != "0") {
                                    this.fillterData('section')
                                }
                            }
                            else {
                                this.showAlertComponent(data['status_code'], data['status_message']);
                            }
                        },
                        err => {
                            this.showLoader = false;
                            swal.fire("Something went wrong. Please try again later.")
                        }
                    )
                }
                else {
                    if (statusId == 0) {
                        (<HTMLInputElement>document.getElementById(data.id_section)).checked = false;
                    }
                    else {
                        (<HTMLInputElement>document.getElementById(data.id_section)).checked = true;
                    }
                }
            })
        }
        else {
            this.showLoader = true;
            this.sectionMasterService.changeStatus(data.id_section, localStorage.getItem("email"), statusId).subscribe(
                data => {
                    this.showLoader = false;
                    if (data.status_code == 0) {
                        this.Sections = data["sections"];
                        this.showScholar(this.Sections[0])
                        this.unfilterdSections = data["sections"];
                        this.isAddModal = false;
                        this.clearData()
                        this.showAlertComponent(data['status_code'], data['status_message']);
                        if (this.coeId != "0" && this.batchId == "0") {
                            this.fillterData('batch')
                        }
                        else if (this.coeId != "0" && this.batchId != "0") {
                            this.fillterData('section')
                        }
                    }
                    else {
                        this.showAlertComponent(data['status_code'], data['status_message']);
                    }
                },
                err => {
                    this.showLoader = false;
                    swal.fire("Something went wrong. Please try again later.")
                }
            )
        }


    }
    showScholar(data) {
        this.showDetails = true
        this.sectionName = data.name;
        this.showLoader = true;
        this.sectionId = data.id_section
        this.Scholars = []
        this.sectionMasterService.getAllScholarBySection(localStorage.getItem("email"), data.id_section).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {
                    this.Scholars = data["scholars"];
                    //this.clearData()
                }
                else {
                    // this.showAlertDiv(data.status_message)
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }

    manageSyllabus(data) {
        this.router.navigate(['./section-syllabus'], { queryParams: { courseTypeId: data.id_course_type, sectionName: data.name, sectionId: data.id_section, coeId: data.id_coe, batchId: data.id_batch } });
    }

    showAlertDiv(message) {
        var that = this
        that.alertDiv = true
        this.sucessAlertMessage = message
        setTimeout(function () {
            that.alertDiv = false
        }, 5000);
    }
    filetBatch() {
        this.Batches = [];
        for (let data of this.unfilterdBatches) {
            if (data.id_coe == this.coeId) {
                this.Batches.push(data)
            }
        }
    }

    addScholar() {
        this.router.navigate(['/user master'], { queryParams: { roleId: 5, sectionId: this.sectionId } });
    }

    checkSelectedOrNot(data) {
        if (data.id_section == this.sectionId) {
            return true
        }
        else {
            return false
        }
    }

    showAlertComponent(status, messege) {
        this.showAlert = true;
        this.alertMsg = messege;
        //("status" + status);
        if (parseInt(status) == 0) {
            this.alertType = "success";
        }
        else {
            this.alertType = "failure";
        }
    }
    hideAlert(val) {
        this.showAlert = val;
    }

    sort(property) {
        this.isDesc = !this.isDesc; //change the direction    
        this.column = property;
        let direction = this.isDesc ? 1 : -1;

        this.Sections.sort(function (a, b) {
            if (a[property] < b[property]) {
                return -1 * direction;
            }
            else if (a[property] > b[property]) {
                return 1 * direction;
            }
            else {
                return 0;
            }
        });
    }
}
