import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class CoordinatoDashBoardService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }
    getTodaySectionSchedule(email, date, userId) {
        return this.http.get(this.baseUrl + "/Schedule/getTodaySectionSchedule?caller_email=" + email + "&date=" + date + "&id_user=" + userId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getTodayGraphData(email, date, userId) {
        return this.http.get(this.baseUrl + "/Reports/getDashboardGraphDataForCoordinator?caller_email=" + email + "&date=" + date + "&id_user=" + userId).map((res) => JSON.parse(JSON.stringify(res)));
    }
    saveComplitionChange(jsonData) {
        //.log(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Schedule/changeDailyClassScheduleStatus", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }
    addOtherLecture(jsonData) {
        //.log(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Schedule/addOtherLecture", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getTodaySubjectGraph(email, date, coeId, userId) {
        return this.http.get(this.baseUrl + "/Reports/getTodaySubjectGraph?caller_email=" + email + "&date=" + date + "&id_coe=" + coeId + "&id_user=" + userId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getTodayLectureGraph(email, date, coeId, userId, coeName) {
        return this.http.get(this.baseUrl + "/Reports/getTodayLectureGraph?caller_email=" + email + "&date=" + date + "&id_coe=" + coeId + "&id_user=" + userId + "&name=" + coeName).map((res) => JSON.parse(JSON.stringify(res)));
    }
}