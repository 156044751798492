import { Pipe } from '@angular/core'

@Pipe({ name: 'filter' })
export class FilterArrayPipeForGroup {
    public transform(value, keys: string, term: string) {
        //.log(term);
        if (term) {
            term = term.trim()
        }

        if (!term) return value;
        else
            return (value || []).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));
    }
}