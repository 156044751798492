import { Component, OnInit } from '@angular/core';
import { BookMasterService } from '../book-master/book-master.service'
import swal from 'sweetalert2'
import { CSVExport } from '../Csv-export';
@Component({
  selector: 'book-title',
  templateUrl: './book-title.component.html',
  styleUrls: ['./book-title.component.css'],
  providers: [BookMasterService]
})
export class BookTitleComponent implements OnInit {
  isBookTitle = true;
  bookTitlesObject: any = { 'id_book_type': '' }
  searchText: any;
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  isAddModal = false;
  showLoader = false;
  BookTitles = [];
  BookTypes = []
  isAdd = 0;
  purpose = "Add"
  selectedBookTitleId: any
  constructor(private bookMasterService: BookMasterService) {

  }
  ngOnInit() {
    this.getAllBookTitle()
    this.getAllBookType()

  }


  getAllBookType() {
    this.showLoader = true;
    this.BookTypes = []
    this.bookMasterService.getAllBookType(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.BookTypes = data.book_types
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllBookTitle() {
    this.showLoader = true;
    this.BookTitles = []
    this.bookMasterService.getAllBookTitle(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.BookTitles = data.book_titles
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  addBookTitle() {
    this.bookTitlesObject = { 'id_book_type': '' }
    this.isAddModal = true
    this.isAdd = 0
  }

  editBookTitle(data) {
    this.purpose = "Edit"
    this.isAddModal = true
    this.isAdd = 1
    let tempData = Object.assign({}, data)
    this.bookTitlesObject = { 'id_book_type': '' }
    this.bookTitlesObject['name'] = tempData.book_title
    this.bookTitlesObject['author'] = tempData.author
    this.bookTitlesObject['id_book_type'] = tempData.id_book_type
    this.bookTitlesObject['publisher'] = tempData.publisher
    this.bookTitlesObject['id_book_title'] = tempData.id_book_title
    this.bookTitlesObject['short_title'] = tempData.short_title

  }

  save() {
    // this.showLoader = true;
    this.bookTitlesObject['email'] = localStorage.getItem("email")
    if (this.isAdd == 0) {
      this.bookMasterService.addBookTitle(this.bookTitlesObject).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.closeModal();
            this.getAllBookTitle()
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }
    else {
      this.bookMasterService.editBookTitle(this.bookTitlesObject).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.closeModal();
            this.getAllBookTitle()
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }

  }
  closeModal() {
    this.isAddModal = false
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }

  hideAlert(val) {
    this.showAlert = val;
  }

  removeBookTitle(data) {
    swal.fire({
      title: '',
      text: "Deleting an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to remove this Book Title?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        this.bookMasterService.removeBookTitle(localStorage.getItem("email"), data.id_book_title).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.getAllBookTitle()
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);

            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
    })
  }
  manageEdition(bookTitleId) {
    this.selectedBookTitleId = bookTitleId
    this.isBookTitle = false;
  }
  closeBookEdition() {
    this.isBookTitle = true;
  }

  export() {
    var arrayToExport = [];
    if (this.BookTitles.length > 0) {
      for (let data of this.BookTitles) {
        let json = {
          "Book Title": data.book_title,
          "Author": data.author,
          "Book Type": data.book_type_name,
          "Publisher": data.publisher,

        }

        arrayToExport.push(json);


      }
      CSVExport.downloadcsv(arrayToExport, "Book Title");
    }
  }
}
