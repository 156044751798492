import { Component, OnInit } from '@angular/core';
import { TopicReportService } from './topic-report.service'
import { Router } from '@angular/router';
import swal from 'sweetalert2'
@Component({
  selector: 'app-topic-report',
  templateUrl: './topic-report.component.html',
  styleUrls: ['./topic-report.component.css'],
  providers: [TopicReportService]
})
export class TopicReportComponent implements OnInit {
  Coes = [];
  coeId = "0";
  batchId = "0";
  sectionId = "0";
  Batches = [];
  UnfilterdBatches = [];
  UnfilterdSections = [];
  Sections = []
  showLoader = false;
  UnfilteredTopicReports = []
  TopicReports = []
  isCompleted = false
  isOnGoing = false
  isScheduled = false
  completedOnTime = 0;
  completedBeforeTime = 0;
  completedOnDelayed = 0;
  onGoingOnTime = 0;
  onGoingBeforeTime = 0;
  onGoingOnDelayed = 0;
  scheduled = 0;
  unScheduled = 0;
  alertDiv = false;
  sucessAlertMessage: any;
  filterReportsByFromDown = [];
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  column: string = 'CategoryName';
  direction: number;
  isDesc: boolean = false;
  constructor(private topicReportService: TopicReportService, private router: Router) {
    if (localStorage.getItem("isLogin") == "true") {
      this.isCompleted = true
      this.isOnGoing = true;
      this.isScheduled = true;
      this.getMasterData()
    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  getMasterData() {
    this.showLoader = true;
    this.topicReportService.getAllMasterData(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.Coes = data.coes;
          this.UnfilterdBatches = data.batches
          // this.Batches = data.batches;
          this.UnfilterdSections = data.sections
          //this.Sections = data.sections;
          this.getScheduleTopics();
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false
      }
    );
  }

  getScheduleTopics() {
    this.showLoader = true;
    this.topicReportService.getAllScheduleTopic(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.UnfilteredTopicReports = data.reports
          this.TopicReports = data.reports
          this.getData();
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false
      }
    );
  }


  filterReport() {
    this.TopicReports = this.UnfilteredTopicReports
    this.Batches = [];
    this.Sections = [];
    if (this.coeId != "0") {
      var temporaryArray = [];
      for (let data of this.TopicReports) {
        if (data.id_coe == this.coeId) {
          temporaryArray.push(data);

        }
      }
      for (let data of this.UnfilterdBatches) {
        if (data.id_coe == this.coeId) {
          this.Batches.push(data);

        }
      }
      this.TopicReports = temporaryArray;
    }
    if (this.batchId != "0") {
      var temporaryArray = [];
      for (let data of this.TopicReports) {
        if (data.id_batch == this.batchId) {
          temporaryArray.push(data);

        }
      }
      for (let data of this.UnfilterdSections) {
        if (data.id_batch == this.batchId) {
          this.Sections.push(data);

        }
      }
      this.TopicReports = temporaryArray;
    }
    if (this.sectionId != "0") {
      var temporaryArray = [];
      for (let data of this.TopicReports) {
        if (data.id_section == this.sectionId) {
          temporaryArray.push(data);

        }
      }
      this.TopicReports = temporaryArray;
    }
    this.filterReportsByFromDown = this.TopicReports
    this.filterByStatus();
  }

  filterByStatus() {
    var temporaryArray = [];
    //("this.filterReportsByFromDown.length==" + this.filterReportsByFromDown.length);

    if (this.isCompleted) {
      for (var i = 0; i < this.filterReportsByFromDown.length; i++) {
        if (this.filterReportsByFromDown[i].id_topic_status == "3") {
          temporaryArray.push(this.filterReportsByFromDown[i]);
        }
      }
    }
    if (this.isOnGoing) {
      for (var i = 0; i < this.filterReportsByFromDown.length; i++) {
        if (this.filterReportsByFromDown[i].id_topic_status == "2") {
          temporaryArray.push(this.filterReportsByFromDown[i]);
        }
      }
    }
    if (this.isScheduled) {
      for (var i = 0; i < this.filterReportsByFromDown.length; i++) {
        if (this.filterReportsByFromDown[i].id_topic_status == "1") {
          temporaryArray.push(this.filterReportsByFromDown[i]);
        }
      }
    }

    // if (this.isCompleted && this.isOnGoing && this.isScheduled) {
    //   temporaryArray = this.filterReportsByFromDown;
    // }

    this.TopicReports = temporaryArray
    this.getData();
  }


  getStatus(scheduleDate, completedDate) {
    if (scheduleDate == null && completedDate == null) {
      return "On Time"
    }
    else if (scheduleDate == null && completedDate != null) {
      return "Before Time"
    }
    else if (scheduleDate != null && completedDate == null) {
      return "On Time"
    }
    else if (scheduleDate > completedDate) {
      return "Before Time"
    }
    else if (scheduleDate < completedDate) {
      return "Delayed"
    }
    else if (scheduleDate == completedDate) {
      return "On Time"
    }
  }

  getData() {
    this.completedOnTime = 0
    this.completedBeforeTime = 0
    this.completedOnDelayed = 0
    this.onGoingOnTime = 0;
    this.onGoingBeforeTime = 0;
    this.onGoingOnDelayed = 0;
    this.scheduled = 0;
    this.unScheduled = 0;
    for (let data of this.TopicReports) {
      if (data.id_topic_status == "3") {
        if (data.shedule_completion_date == null && data.topic_completed_date == null) {
          this.completedOnTime++;
        }
        else if (data.shedule_completion_date == null && data.topic_completed_date != null) {
          this.completedBeforeTime++;
        }
        else if (data.shedule_completion_date != null && data.topic_completed_date == null) {
          this.completedOnTime++;
        }
        else if ((data.shedule_completion_date != null && data.topic_completed_date != null) && (data.shedule_completion_date == data.topic_completed_date)) {
          this.completedOnTime++;
        }
        else if (data.shedule_completion_date > data.topic_completed_date) {
          this.completedBeforeTime++;
        }
        else if (data.shedule_completion_date < data.topic_completed_date) {
          this.completedOnDelayed++;
        }
      }
      else if (data.id_topic_status == "2") {
        if (data.shedule_completion_date == null && data.topic_completed_date == null) {
          this.onGoingOnTime++;
        }
        else if (data.shedule_completion_date == null && data.topic_completed_date != null) {
          this.onGoingBeforeTime++;
        }
        else if (data.shedule_completion_date != null && data.topic_completed_date == null) {
          this.onGoingOnTime++;
        }
        else if (data.shedule_completion_date > data.topic_completed_date) {
          this.onGoingBeforeTime++;
        }
        else if (data.shedule_completion_date < data.topic_completed_date) {
          this.onGoingOnDelayed++;
        }
      }
      else if (data.id_topic_status == "1") {
        this.scheduled++;

      }
      else if (data.id_topic_status == "0") {
        this.unScheduled++;

      }
    }
  }

  showAlertDiv(message) {
    var that = this
    that.alertDiv = true
    this.sucessAlertMessage = message
    setTimeout(function () {
      that.alertDiv = false
    }, 5000);
  }


  onBack() {
    this.router.navigateByUrl('/Reports')
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    //("status" + status);
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }
  print() {
    let printContents, popupWin;
    printContents = document.getElementById('printSection').innerHTML;
    setTimeout(function () {

      var htmlToPrint = '' +
        '<style type="text/css">' +
        'table th, table td {' +
        'border:1px solid #000;' +
        'padding;0.5em;' +
        '}' +
        '</style>';
      htmlToPrint += printContents;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
          <html>
              <head>
                 
                  <style>
                      //........Customized style.......
                  </style>
              </head>
              <body onload="window.print();window.close()">${htmlToPrint}

              </body>
          </html>`
      );
      popupWin.document.close();
    }, 10)
  }

  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.TopicReports.sort(function (a, b) {
        if (a[property] < b[property]) {
            return -1 * direction;
        }
        else if (a[property] > b[property]) {
            return 1 * direction;
        }
        else {
            return 0;
        }
    });
}
}
