import { Component, OnInit } from '@angular/core';
import { TopicStatusReportService } from './topic-status-report.service'
import { Router } from '@angular/router';
import swal from 'sweetalert2'
@Component({
  selector: 'app-topic-status-report',
  templateUrl: './topic-status-report.component.html',
  styleUrls: ['./topic-status-report.component.css'],
  providers: [TopicStatusReportService]
})
export class TopicStatusReportComponent implements OnInit {
  showLoader = false;
  TopicHeading = [];
  tempHeading = [];
  TopicReportData = [];
  UnfilterdTopicReportData = [];
  reportData = [];
  Coes = [];
  coeId = "0";
  unFilteredBatches = [];
  Batches = [];
  batchId = "0"
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  constructor(private topicStatusReportService: TopicStatusReportService, private router: Router) {
    if (localStorage.getItem("isLogin") == "true") {
      this.getActiveCoe();

    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  getActiveCoe() {
    this.showLoader = true
    this.topicStatusReportService.getAllActiveCoe(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.Coes = data.coes
          this.getActiveBatches();
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {

      }
    )
  }
  getActiveBatches() {
    this.showLoader = true
    this.topicStatusReportService.getAllActiveBatch(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.Batches = data.batches
          this.unFilteredBatches = data.batches
          this.getTopicStatusReport();

        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {

      }
    )
  }
  getTopicStatusReport() {
    this.showLoader = true
    this.topicStatusReportService.getTopicStatusReport(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.TopicHeading = data.topic_report_headaing
          this.tempHeading = data.topic_report_headaing;
          this.TopicReportData = data.topic_report_data
          this.UnfilterdTopicReportData = data.topic_report_data
          this.generateFormatedData();
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {

      }
    )
  }

  generateFormatedData() {
    this.reportData = [];
    var subjectAndTopic = []
    for (let data of this.TopicReportData) {

      var isDataExist = false;
      if (subjectAndTopic.length == 0) {
        subjectAndTopic.push({
          "subject_name": data.subject_name,
          "topic_name": data.topic_name
        });
      }
      else {
        for (let data1 of subjectAndTopic) {
          if (data1.topic_name == data.topic_name) {
            isDataExist = true;
            break;
          }
        }
        if (!isDataExist) {
          subjectAndTopic.push({
            "subject_name": data.subject_name,
            "topic_name": data.topic_name
          });
        }
      }
    }
    for (let data of subjectAndTopic) {
      var obj = {
        "subject_name": data.subject_name,
        "topic_name": data.topic_name,
      }
      for (let data1 of this.TopicReportData) {
        if (data.subject_name == data1.subject_name && data.topic_name == data1.topic_name) {
          obj[data1.id_section] = data1.topic_status,
            obj["topic_status"] = data1.topic_status
        }

      }
      this.reportData.push(obj);
    }

  }

  filterReport() {
    this.TopicReportData = this.UnfilterdTopicReportData
    this.TopicHeading = this.tempHeading
    if (this.coeId != "0") {
      this.Batches = []
      this.TopicReportData = []
      this.TopicHeading = []
      for (let data of this.unFilteredBatches) {
        if (data.id_coe == this.coeId) {
          this.Batches.push(data)
        }
      }
      for (let data of this.UnfilterdTopicReportData) {
        if (data.id_coe == this.coeId) {
          this.TopicReportData.push(data)
        }
      }
      for (let data of this.tempHeading) {
        if (data.id_coe == this.coeId) {
          this.TopicHeading.push(data)
        }
      }
    }
    if (this.batchId != "0") {
      this.TopicReportData = []
      this.TopicHeading = []
      for (let data of this.UnfilterdTopicReportData) {
        if (data.id_batch == this.batchId) {
          this.TopicReportData.push(data)
        }
      }
      for (let data of this.tempHeading) {
        if (data.id_coe == this.coeId) {
          this.TopicHeading.push(data)
        }
      }
    }
    this.generateFormatedData();

    //(this.TopicReportData)
  }

  onBack() {
    this.router.navigateByUrl('/Reports')
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    //("status" + status);
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }

}
