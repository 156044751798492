import { Component, OnInit } from '@angular/core';
import { ScholarDocumentsService } from './scholar-documnets.sevice'
import { UploadService } from '../services/upload.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2'
import * as JSZip from 'jszip';
import { saveAs } from 'save-as'
import { PassThrough } from 'stream';
@Component({
  selector: 'app-scholar-documents',
  templateUrl: './scholar-documents.component.html',
  styleUrls: ['./scholar-documents.component.css'],
  providers: [ScholarDocumentsService, UploadService]
})
export class ScholarDocumentsComponent implements OnInit {
  isErrorMessage = false;
  searchText: any;
  showLoader = false;
  alertDiv = false;
  sucessAlertMessage: any;
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  statusMessage = ""
  Coes = [];
  coeId = "0"
  Batches = [];
  batchId = "0";
  unfilterdBatches = []
  unfilterdSections = [];
  Sections = [];
  sectionId = "0";
  isUploadModal = false;
  fileInput: any;
  fileReaded: any;
  imageFile: any;
  Users = [];
  unfilteredUsers = []
  scholarData: any;
  isViewDocumentModal = false;
  scholarDocumnets = []
  isViewSingleDocumentModal = false
  scholarDocument: any;
  errorMessage: any;
  isBulkDownload = false;
  documentType: any;
  selectedSectionName = "";
  selectedCoEName = "";
  selectedBatchName = "";
  constructor(private domSanitizer: DomSanitizer, private service: ScholarDocumentsService, private router: Router, private uploadService: UploadService) {
    // this.service.getFile()
    if (localStorage.getItem("isLogin") == "true") {

      this.getAllCoe();
      this.getUserByRoleId()

    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }
  getUserByRoleId() {
    this.showLoader = true
    this.Users = [];
    this.service.getUserByRoleId(localStorage.getItem("email"), "5").subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == 0) {

          this.Users = data["users"];
          this.unfilteredUsers = data["users"]

        }

      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }


  getScholarDocuments(path) {
    this.scholarDocumnets = []
    var that = this
    this.uploadService.getFile(path).then(function (data: any) {


      var objectsArray = []
      for (let file of data) {
        //(file)
        let documentName = file.Key.split('/').pop().split('.')[0]
        that.uploadService.getDocument(file).then(
          function (fileObject: any) {

            var data = fileObject
            // //(fileObject)
            var json = { 'document_type': documentName }
            var str = data.Body.reduce(function (a, b) { return a + String.fromCharCode(b) }, '');
            json['string_images'] = str
            if (data.ContentType != "application/pdf") {
              json['document'] = that.getImage(btoa(str).replace(/.{76}(?=.)/g, '$&\n'))
            }
            else {
              json['document'] = that.getPDF(btoa(str).replace(/.{76}(?=.)/g, '$&\n'))
            }
            json['content_type'] = data.ContentType
            json['coe_roll_number'] = path
            json['key'] = file.Key
            that.scholarDocumnets.push(json)
          }
        );


      }
    });
  }

  public getImage(data) {

    var image;
    // //(data)
    image = 'data:image/jpeg;base64,' + data;
    image = this.domSanitizer.bypassSecurityTrustUrl(image);
    return image
  }

  public getPDF(data) {
    var image = 'data:application/pdf;base64,' + data;
    return image;
  }

  downloadDocument(data) {
    var fileName = "";
    var image = "";
    if (data.content_type == "application/pdf") {
      fileName = this.scholarData.coe_roll_number + "-" + this.scholarData.name + "-" + data.document_type + ".pdf"
      image = 'data:application/pdf;base64,' + btoa(data.string_images).replace(/.{76}(?=.)/g, '$&\n');
    }
    else {
      fileName = this.scholarData.coe_roll_number + "-" + this.scholarData.name + "-" + data.document_type + ".jpg"
      image = 'data:image/jpeg;base64,' + btoa(data.string_images).replace(/.{76}(?=.)/g, '$&\n');
    }
    const downloadLink = document.createElement("a");

    downloadLink.href = image;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  getAllCoe() {
    this.Coes = [];
    this.showLoader = true
    this.service.getAllCoe(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == 0) {
          this.Coes = data["coes"];
          this.getAllBatches();
        }
        else {
          this.showAlertDiv(data.status_message)
        }
        if (this.Coes.length == 0) {
          this.statusMessage = "No data found for CoE master, first add it.";
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }
  showAlertDiv(message) {
    var that = this
    that.alertDiv = true
    this.sucessAlertMessage = message
    setTimeout(function () {
      that.alertDiv = false
    }, 5000);
  }

  getAllBatches() {
    this.Batches = []
    this.showLoader = true
    this.service.getAllBatches(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == 0) {
          //  this.Batches = data["batches"];
          this.unfilterdBatches = data["batches"];

          this.getAllSections()
        }
        else {
          this.showAlertDiv(data.status_message)
        }
        if (this.Batches.length == 0) {
          this.statusMessage = "No data found for Batch master, first add it.";
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }


  getAllSections() {
    this.showLoader = true
    this.service.getAllSections(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.unfilterdSections = data.sections
        }
        else {
          // this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false
      }
    )
  }

  filterScholar(type) {
    if (type == 'coe') {
      this.batchId = "0"
      this.sectionId = "0"
    }
    else if (type == 'batch') {
      this.sectionId = "0"
    }
    this.Batches = [];
    this.Sections = [];
    this.Users = this.unfilteredUsers;
    this.searchText = ''
    if (this.coeId != "0") {
      for (let data of this.unfilterdBatches) {
        if (data.id_coe == this.coeId) {

          this.Batches.push(data)
        }
      }
      for (let data of this.unfilterdSections) {
        if (data.id_coe == this.coeId) {
          this.Sections.push(data)
        }
      }
      var temporaryArray = [];
      for (let data of this.Users) {
        if (data.id_coe == this.coeId) {
          temporaryArray.push(data)
        }
      }
      this.Users = temporaryArray;
    }
    if (this.batchId != "0") {
      this.Sections = []
      var temporaryArray = [];
      for (let data of this.unfilterdSections) {
        if (data.id_batch == this.batchId) {
          this.Sections.push(data)
        }
      }
      temporaryArray = [];
      for (let data of this.Users) {
        if (data.id_batch == this.batchId) {
          temporaryArray.push(data)
        }
      }
      this.Users = temporaryArray;
    }

    if (this.sectionId != "0") {
      var temporaryArray = [];
      for (let data of this.Users) {
        if (data.id_section == this.sectionId) {
          temporaryArray.push(data)
        }
      }
      this.Users = temporaryArray;
    }

  }
  upload() {
    this.isUploadModal = true
  }
  hideUploadModal() {
    this.isUploadModal = false;
  }

  csv2Array(fileInput: any) {
    this.fileInput = fileInput
    //(fileInput)
  }

  uploadDocumnets() {
    let file = this.fileInput.target.files[0];
    this.isErrorMessage = false
    this.errorMessage = ""
    //(file)
    let documentName = file.name.split('/').pop().split('.')[0]
    if (!this.fileInput) {
      this.isErrorMessage = true
      this.errorMessage = "Please select file to upload."

    }
    else if (this.scholarDocument.document_type != documentName) {
      this.isErrorMessage = true
      this.errorMessage = "Please select correct file."

    }
    else if (file.type != "image/jpeg" && file.type != "image/png") {
      this.isErrorMessage = true
      this.errorMessage = "You can upload only image in jpeg or png format."

    }
    else {
      var that = this
      this.uploadService.uploadFile(that.scholarDocument.coe_roll_number, file).then(function (data: any) {
        that.hideUploadModal();
        that.isViewDocumentModal = true
        that.getScholarDocuments(that.scholarDocument.coe_roll_number)
      });
    }

  }

  removeDocument(dataObject) {
    var that = this;
    this.uploadService.deleteFile(dataObject.key).then(function (data: any) {
      that.hideUploadModal();
      that.isViewDocumentModal = true
      that.getScholarDocuments(dataObject.coe_roll_number)
    });

  }
  
  viewDocuments(data) {
    this.scholarData = {}
    this.scholarData = data
    this.getScholarDocuments(data.coe_roll_number)

    this.isViewDocumentModal = true
  }

  viewDocument(data) {
    this.scholarDocument = {}
    this.isViewSingleDocumentModal = true
    this.scholarDocument = data

  }
  uploadDocument(data) {
    this.scholarDocument = data
    //(this.scholarDocument)
    this.isUploadModal = true
  }

  downloadImageAsZip() {
    var zip = new JSZip();
    var zipFilename = this.scholarData.coe_roll_number + "-" + this.scholarData.name + "-" + ".zip";
    var count = 0;
    for (let file of this.scholarDocumnets) {
      var fileName = ""
      if (file.content_type == "application/pdf") {
        fileName = file.document_type + ".pdf"
      }
      else {
        fileName = file.document_type + ".jpg"
      }
      zip.file(fileName, file.string_images, { binary: true });
      count++;
      if (count == this.scholarDocumnets.length) {
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          saveAs(content, zipFilename);
        });
      }
    }

  }

  hideSingleDocumentModal() {
    this.isViewSingleDocumentModal = false;
  }
  hideViewDocumentModal() {
    this.isViewDocumentModal = false;
  }

  bulkDownload() {
    if (this.sectionId == "0") {
      swal.fire("Please select section first.");
      return false;
    }
    this.isBulkDownload = true
  }

  getDocumentJson() {
    var that = this;

    let promise = new Promise(function (resolve, reject) {
      //"Total = " + that.Users.length);
      let allPromise = 0;
      // for (let i = 0; i < that.Users.length;) {
      for (const data of that.Users) {
        // const data = that.Users[i];
        let getListPromise = that.uploadService.listObject(data.coe_roll_number, that.documentType);
        getListPromise.then(function (resolveOutput) {
          // //resolveOutput);
          // promiseArray.push(getListPromise);
          var objectData: any = resolveOutput;
          var json = { 'document_type': that.documentType }
          // var str = objectData.Body.reduce(function (a, b) { return a + String.fromCharCode(b) }, '');
          json['string_images'] = objectData.Body;
          json['content_type'] = objectData.ContentType
          json['coe_roll_number'] = objectData.coe_roll_number
          that.scholarDocumnets.push(json)
          // //that.scholarDocumnets);
          allPromise++;
          // //allPromise);
          if (allPromise == that.Users.length) {
            // //'resove');
            // //allPromise);
            resolve(true);
          }
        }, function (rejectOutput) {
          allPromise++;
          // //rejectOutput)
          // //allPromise);
          if (allPromise == that.Users.length) {
            // //'reject');
            // //allPromise);
            reject(false);
          }
        });
      }
    });
    return promise;
  }

  downloadBulkDocument() {
    if (this.documentType) {
      this.scholarDocumnets = [];
      this.showLoader = true;
      var that = this;
      let getDocumentPromise = that.getDocumentJson();
      getDocumentPromise.then(function (resolveOutput) {
        //resolveOutput)
        //that.scholarDocumnets)
        that.isBulkDownload = false;
        // that.showLoader = false;
        that.downloadBulkImageAsZip(that.scholarDocumnets);
      }, function (rejectOutput) {
        //rejectOutput)
        that.showLoader = false;
      });
    }
    else {
      swal.fire("Please select document type.")
    }

  }

  downloadBulkImageAsZip(documentsArray) {
    const that = this;
    var zip = new JSZip();
    var tempObject = this.Sections.find(x => x.id_section == this.sectionId);
    this.selectedSectionName = tempObject.name
    this.selectedCoEName = tempObject.coe_name
    this.selectedBatchName = tempObject.batch_name
    var zipFilename = this.selectedCoEName + "-" + this.selectedBatchName + "-" + this.selectedSectionName + "-" + this.documentType;
    var count = 0;
    for (let file of documentsArray) {
      var fileName = ""
      if (file.content_type == "application/pdf") {
        fileName = file.coe_roll_number + "_" + file.document_type + ".pdf"
      }
      else {
        fileName = file.coe_roll_number + "_" + file.document_type + ".jpg"
      }
      // var str = objectData.Body.reduce(function (a, b) { return a + String.fromCharCode(b) }, '');
      // file['string_images'] = file['string_images'].reduce(function (a, b) { return a + String.fromCharCode(b) }, '');;
      zip.file(fileName, file.string_images, { binary: true });
      count++;
      if (count == documentsArray.length) {
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          saveAs(content, zipFilename);
          that.showLoader = false;
        });
      }

    }

  }

  closeDownloadBulkDocument() {
    this.isBulkDownload = false;
  }
}
