import { Component, OnInit } from '@angular/core';
import { ScholarFacultyFeedbackRequestService } from './scholar-faculty-feedback.service'
import { Router } from '@angular/router'
import swal from 'sweetalert2'

@Component({
  selector: 'app-scholar-faculty-feedback',
  templateUrl: './scholar-faculty-feedback.component.html',
  styleUrls: ['./scholar-faculty-feedback.component.css'],
  providers: [ScholarFacultyFeedbackRequestService]
})
export class ScholarFacultyFeedbackComponent implements OnInit {
  sucessAlertMessage: any;
  alertDiv = false;
  showLoader = false;
  FeedbackRequest = [];
  facultyId: any;
  facultyName: any;
  subjectName: any;
  isAddModal = false;
  YesNoQuestions = [];
  AgreeDiagreeQuestions = [];
  RatingQuestions = [];
  FeedbackAnswers = [];
  fecultyFeedbackRequestId: any;
  searchText: any;
  next = 0;
  constructor(private router: Router, private scholarFacultyFeedbackRequestService: ScholarFacultyFeedbackRequestService) {
    //(localStorage.getItem("id_section_scholar"))
    if (localStorage.getItem("isLogin") == "true") {
      this.getAllFeedbackRequest();

    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  getAllFeedbackRequest() {
    this.showLoader = true;
    this.scholarFacultyFeedbackRequestService.getAllFacultyFeedback(localStorage.getItem("email"), localStorage.getItem("id_user")).subscribe(
      data => {
        this.showLoader = false;
        this.FeedbackRequest = [];
        if (data.status_code == "0") {
          this.FeedbackRequest = data.feedback_request
        }
        else {
          this.showAlertDiv(data.status_message)
        }
      },
      err => {
        this.showLoader = false;
      }
    )
  }
  showAlertDiv(message) {
    var that = this
    that.alertDiv = true
    this.sucessAlertMessage = message
    setTimeout(function () {
      that.alertDiv = false
    }, 5000);
  }

  viewFeedback(data) {
    this.next = 0;
    this.isAddModal = true;
    this.facultyId = data.id_faculty;
    this.facultyName = data.name;
    this.subjectName = data.subject_name;
    this.fecultyFeedbackRequestId = data.id_faculty_feedback_request
    this.getAllFeedBackQuestion();
  }

  getAllFeedBackQuestion() {
    this.showLoader = true;
    this.scholarFacultyFeedbackRequestService.getAllFeedbackQuestion(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.YesNoQuestions = data.yes_no
          this.AgreeDiagreeQuestions = data.agree_disagree
          this.RatingQuestions = data.rating
        }
        else {
          this.showAlertDiv(data.status_message)
        }
      },
      err => {
        this.showLoader = false;
      }
    )
  }

  getSelectedAnswer(answerId, answerPoint, questionId, answerTypeId, maxMarks) {
    //(answerTypeId)
    for (var i = 0; i < this.FeedbackAnswers.length; i++) {
      if (this.FeedbackAnswers[i].id_question == questionId) {
        this.FeedbackAnswers.splice(i, 1);
        break;
      }
    }
    let json = {
      "id_feedback_item": questionId,
      "id_faculty": this.facultyId,
      "answer_point": answerPoint,
      "max_mark": maxMarks,
      "id_section": localStorage.getItem("id_section_scholar"),
      "id_faculty_feedback_request": this.fecultyFeedbackRequestId,
      "id_scholar": localStorage.getItem("id_user"),
      "id_answer_point": answerId,
      "id_answer_type": answerTypeId
    }
    this.FeedbackAnswers.push(json)
  }
  hideAddEditModal() {
    this.isAddModal = false;
  }

  save() {
    
    // var totalGivenPoint=0;
    // var maxGivenPoint=0;
    // var YesNomaxGivenPoint=0;
    // var AgrreDisagreeGivenPoint=0;
    // var RatingGivenPoint=0;
    // for(let data of this.FeedbackAnswers){
    //  totalGivenPoint=totalGivenPoint+parseInt(data.answer_point)
    // }
    // for(let data of this.FeedbackAnswers){
    //   maxGivenPoint=maxGivenPoint+parseInt(data.max_mark)
    //  }
    if(this.FeedbackAnswers != null && this.FeedbackAnswers.length > 0 )
    {
      this.showLoader = true;
    let json = {
      "caller_email": localStorage.getItem("email"),
      "feedback_answer": JSON.stringify(this.FeedbackAnswers)
    }
    this.scholarFacultyFeedbackRequestService.addFacultyFeedbackRequestByScholar(json).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.showAlertDiv(data.status_message)
          this.hideAddEditModal();
          this.getAllFeedbackRequest();
        }
        else {
          this.showAlertDiv(data.status_message)
        }
      },
      err => {
        this.showLoader = false;
      }
    )
    }else{
      this.isAddModal=false; 
      swal.fire("No feedback given");
    }
  }
  nextClick()
  {
    if(this.next !=2)
    this.next = this.next + 1;
  }
  previousClick()
  {
    if(this.next !=0)
    this.next = this.next - 1;
  }

  


}
