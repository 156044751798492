import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class CandidateResponseService {
  baseUrl = Helper.baseURL
  headers: any;
  options: any

  constructor(private http: HttpClient) { 
    this.headers = new Headers({ 'Content-Type': 'text/plain' });
    this.options = new RequestOptions({ headers: this.headers });
  }

  getExamTemplates(email) {
    return this.http.get(this.baseUrl + "/ScholarResponse/getExamTemplates?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
  }

  getResponseByTemplate(email, id_exam_template): Observable<any> {
    // const params = new HttpParams()
    //   .set('id_exam_template', id_exam_template.toString());

    // return this.http.get(this.baseUrl + "/ScholarResponse/getResponseOfCandidate?caller_email=" + email, { params });
    return this.http.get(this.baseUrl + "/ScholarResponse/getResponseOfCandidate?caller_email=" + email + "&id_exam_template=" + id_exam_template).map((res) => JSON.parse(JSON.stringify(res)));
  }
}
