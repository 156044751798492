import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CSVExport } from '../Csv-export';
@Component({
  selector: 'library-dashboard-content',
  templateUrl: './library-dashboard-content.component.html',
  styleUrls: ['./library-dashboard-content.component.css']
})
export class LibraryDashboardContentComponent implements OnInit {
  @Input() tableName: any
  @Input() booksDataArray: any
  @Output() isLibraryDahsBoardContent = new EventEmitter();
  column: string = 'CategoryName';
  direction: number;
  isDesc: boolean = false;
  constructor() { }

  ngOnInit() {
    //(this.booksDataArray)
  }

  backToLibraryDashBoard() {
    this.isLibraryDahsBoardContent.emit(true)
  }


  export() {
    var arrayToExport = [];
    if (this.booksDataArray.length > 0) {
      for (let data of this.booksDataArray) {
        if (this.tableName == 'titles') {
          let json = {
            "Book Name": data.book_title,
            "Author": data.author,
            "Book Type": data.book_type_name,
            "Publisher": data.publisher,


          }

          arrayToExport.push(json);
        }
        else if (this.tableName == "books") {
          let json = {
            "Book Name": data.book_title_name,
            "Author": data.author,
            "Book Type": data.book_type_name,
            "Publisher": data.publisher,
            "Edition": data.book_edition_name,
            "Year Of Purchase": data.year_of_puchase,
            "Acceesion Code": data.accession_code,
            "Status": data.status_name,
            "Price": data.price,


          }

          arrayToExport.push(json);
        }

        else if (this.tableName == "scholar-issued") {
          let json = {
            "Scholar Name": data.scholar_name,
            "Roll No.": data.coe_roll_number,
            "Book Name": data.book_title_name,
            "Accession Code": data.accession_code,
            "Issued date": data.issued_date,
            "Price": data.price,


          }

          arrayToExport.push(json);
        }
        else if (this.tableName == "library") {
          let json = {
            "Book Name": data.book_title_name,
            "Author": data.author,
            "Book Type": data.book_type_name,
            "Publisher": data.publisher,
            "Edition": data.book_edition_name,
            "Year Of Purchase": data.year_of_puchase,
            "Acceesion Code": data.accession_code,
            "Price": data.price,


          }

          arrayToExport.push(json);
        }
        else if (this.tableName == "damaged") {
          let json = {
            "Book Name": data.book_title_name,
            "Author": data.author,
            "Book Type": data.book_type_name,
            "Publisher": data.publisher,
            "Edition": data.book_edition_name,
            "Year Of Purchase": data.year_of_puchase,
            "Acceesion Code": data.accession_code,
            "Price": data.price,


          }

          arrayToExport.push(json);
        }
        else if (this.tableName == "lost") {
          let json = {
            "Book Name": data.book_title_name,
            "Author": data.author,
            "Book Type": data.book_type_name,
            "Publisher": data.publisher,
            "Edition": data.book_edition_name,
            "Year Of Purchase": data.year_of_puchase,
            "Acceesion Code": data.accession_code,
            "Price": data.price,


          }

          arrayToExport.push(json);
        }



      }
      CSVExport.downloadcsv(arrayToExport, this.tableName);
    }
  }


  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.booksDataArray.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  }
}
