import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import { Helper } from '../helper';
import { Headers, RequestOptions } from '@angular/http';
@Injectable({
  providedIn: 'root'
})
export class LabelConfigurationService {
  baseUrl = Helper.baseURL;
  headers: any;
  options: any
  constructor(private http: HttpClient) { 
    this.headers = new Headers({ 'Content-Type': 'text/plain' });
    this.options = new RequestOptions({ headers: this.headers });
  }

  getAllLabels(email) {
    return this.http.get(this.baseUrl + "/Alumni/getAllLabels?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
  }

  changeStatus(id_alumni_info_label, callerEmail, statusId) {
    return this.http.get(this.baseUrl + "/Alumni/changeLabelStatus?&id_alumni_info_label=" + id_alumni_info_label + "&caller_email=" + callerEmail + "&id_status=" + statusId).map((res) => JSON.parse(JSON.stringify(res)));
  }
  addNewLabel(jsonData) {
        return this.http.post(this.baseUrl + '/Alumni/addNewLabel', JSON.stringify(jsonData))
  }

  swapSequenceNo(jsonData) {
    return this.http.post(this.baseUrl + '/Alumni/swapLabelSequenceNo', JSON.stringify(jsonData))
  }

  changePublicStatus(id_alumni_info_label, callerEmail, statusId) {
    return this.http.get(this.baseUrl + "/Alumni/changeLabelPublicStatus?&id_alumni_info_label=" + id_alumni_info_label + "&caller_email=" + callerEmail + "&id_status=" + statusId).map((res) => JSON.parse(JSON.stringify(res)));
  }

}
