import { Component } from '@angular/core';
import { UserMasterService } from './user-master.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import swal from 'sweetalert2'
import { md5 } from '../md5'
import { Helper } from '../helper'
import { DatePipe } from '@angular/common'
import * as fs from 'json2csv';
import { CSVExport } from '../Csv-export';
import { UploadService } from '../services/upload.service';
import * as JSZip from 'jszip';
import { saveAs } from 'save-as';
//import csv from 'json2csv'
@Component({
    selector: 'user-master',
    templateUrl: 'user-master.component.html',
    styleUrls: ['user-master.component.scss'],
    providers: [UserMasterService, Helper, DatePipe]
})
export class UserMasterComponent {
    showLoader: boolean;
    UserRoles: any;
    roleIdSelected: any;
    modelHeadelLabel: any;
    isAddModal: boolean;
    Users: any;
    unfilteredUsers = []
    name: any;
    email: any;
    mobile: any;
    password: any
    confirmPassword: any;
    purpose = "Add"
    userId: any;
    emailPattern = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/;
    searchText: any;
    showCoordinatorSection: any
    isAddCoordinatorSectioinMapingModal: any;
    coordinatorName: any
    coeId: any;
    batchId: any
    Coes: any;
    Batches: any
    tempBatches: any
    Sections: any
    tempSections: any
    isCheckedAll: any
    CheckedSections: any
    coodinatorId: any
    CoordinatoSectionMapping: any
    serachCorrdinatorSectionMappingText: any
    showFacultySection: any
    facultyName: any
    facultyId: any
    isAddFacultySectioinMapingModal: any
    isAddFacultyTopicMapingModal: any
    FacultySectionMapping: any
    FacultyTopicMapping: any
    subjectId: any;
    subSubjectId: any
    Subjects = []
    SubSubjects = []
    tempTopics = []
    Topics = []
    tempSubSubjects = []
    isCheckedAllTopic = "1"
    CheckedTopics = [];
    serachFacultyTopicMappingText: any;
    isAddScholarModal: any;
    States = [];
    districts = [];
    stateId = "0"
    districtId = "0"
    pincode = ""
    homeStateId = "0"
    Categorys = []
    categoryId = "0"
    PD = []
    pdValue = "-1"
    sectionId = "0"
    addressOne: any
    addressTwo: any;
    city: any;
    admissionDate: any;
    fatherMobile: any;
    motherMobile: any;
    fatherEmail: any;
    motherEmail: any;
    dakshnaRollNumber: any;
    coeRollNumber: any;
    showScholarDetails: any;
    state: any;
    isScholarImportModal = false;
    fileInput: any;
    fileReaded: any;
    scholarMobileNumber: any;
    scholarCOENumber: any;
    scholarEmail: any;
    alertDiv = false;
    sucessAlertMessage: any;
    unfilterdBatch = [];
    unfilterdSection = [];
    showAlert: boolean;
    alertMsg: any;
    alertType = "";
    column: string = 'CategoryName';
    direction: number;
    isDesc: boolean = false;
    schoolName: any;
    adharNumber: any;
    dob: any;
    home_state: any;
    gender: any;
    externalScholarData: any;
    userInfo: any;
    externalRegistraionStatus = "0";
    isEditExternalScholarModal = false;
    externalEditScholarData: any;
    scholarData: any;
    scholarDocumnets = []
    isViewDocumentModal = false;
    isViewSingleDocumentModal = false
    scholarDocument: any;
    coe_name: any;
    scholar_template_url = Helper.url + "/assets/Scholar.csv";
    isUploadModal = false;
    isErrorMessage = false;
    errorMessage = "";
    selectedStatus = "0";


    constructor(private domSanitizer: DomSanitizer, private userMasterService: UserMasterService, private router: Router, private helper: Helper, private datepipe: DatePipe, private activatedRoute: ActivatedRoute, private uploadService: UploadService) {
        this.showLoader = false;
        this.PD = this.helper.getPd();
        this.Coes = []
        this.showCoordinatorSection = false
        this.isAddCoordinatorSectioinMapingModal = false
        this.isAddScholarModal = false
        this.isAddModal = false;
        this.roleIdSelected = 1;
        this.Sections = []
        this.CheckedSections = []
        this.CoordinatoSectionMapping = []
        this.FacultySectionMapping = []
        this.FacultyTopicMapping = []
        this.showFacultySection = false
        this.isAddFacultySectioinMapingModal = false
        this.isAddFacultyTopicMapingModal = false
        this.isCheckedAll = "1"
        this.modelHeadelLabel = "Add New Admin"
        this.coeId = "0"
        this.batchId = "0"
        this.subjectId = "0"
        this.subSubjectId = "0"
        this.showScholarDetails = false

        if (localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "1") {
            this.getAllUserRole();
            //this.getUserByRoleId();
        }
        else {
            this.router.navigateByUrl('/');
        }
    }

    getAllUserRole() {
        this.showLoader = true
        this.userMasterService.getAllUserRole(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {

                    this.UserRoles = data["user_roles"];
                    this.activatedRoute.queryParams.subscribe(params => {
                        if (params.roleId) {
                            this.roleIdSelected = params.roleId
                            this.getUserForScholarByRoleId();
                        }
                        else {
                            this.getUserByRoleId();
                        }

                    });

                }
                else {
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }

    getUserByRoleId() {
        this.showLoader = true
        this.Users = [];
        this.serachCorrdinatorSectionMappingText = ""
        this.userMasterService.getUserByRoleId(localStorage.getItem("email"), this.roleIdSelected).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {

                    this.Users = data["users"];
                    this.unfilteredUsers = data["users"]
                    if (this.Users.length != 0) {
                        this.openMapping(this.Users[0])
                        //.log("under getUserByRoleId")
                    }
                    else {
                        this.hideAddEditModal()
                    }
                }
                else {
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }

    isButtonActive(roleId) {
        ////(roleId);
        if (this.roleIdSelected == roleId) {
            return true
        }
        else {
            false
        }
    }

    selectRoleId(roleId) {

        this.searchText = "";
        this.cleanModalData();
        this.roleIdSelected = roleId;
        this.showCoordinatorSection = false
        this.showFacultySection = false
        if (this.roleIdSelected != "5") {
            this.coeId = "0"
            this.batchId = "0"
            this.sectionId = "0"
            this.getUserByRoleId();
        }
        else {
            this.getUserForScholarByRoleId();


        }

    }
    getUserForScholarByRoleId() {
        this.showLoader = true
        this.Users = [];
        this.serachCorrdinatorSectionMappingText = ""
        this.userMasterService.getUserByRoleId(localStorage.getItem("email"), this.roleIdSelected).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {

                    this.Users = data["users"];
                    this.unfilteredUsers = data["users"]
                    if (this.Users.length != 0) {
                        this.openMapping(this.Users[0])
                        //.log("under getUserForScholarByRoleId")
                    } else {
                        this.hideAddEditModal()
                    }
                    this.getAllState();
                }
                else {
                    this.getAllState()
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }
    getAllState() {
        this.showLoader = true
        this.userMasterService.getAllStates(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false
                if (data.status_code == "0") {
                    this.States = data.states;
                    this.districts = data.district;
                    this.getAllCategory()
                }
                else {
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false
            }
        )
    }
    getAllCategory() {
        this.showLoader = true
        this.userMasterService.getAllCategory(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false
                if (data.status_code == "0") {
                    this.Categorys = data.categorys
                    this.getAllCoe()
                }
                else {
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false
            }
        )
    }

    getAllCoe() {
        this.showLoader = true

        this.userMasterService.getAllCoe(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false
                if (data.status_code == "0") {
                    this.Coes = data.coes

                    this.getAllBatch()
                }
                else {
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false
            }
        )

    }
    addNew() {

        this.cleanModalData();

        if (this.roleIdSelected == 2) {
            this.modelHeadelLabel = "Add New Coordinator"
            this.isAddModal = true;
        }
        else if (this.roleIdSelected == 3) {
            this.modelHeadelLabel = "Add New Management"
            this.isAddModal = true;
        }
        else if (this.roleIdSelected == 4) {
            this.modelHeadelLabel = "Add New Faculty"
            this.isAddModal = true;
        }
        else if (this.roleIdSelected == 5) {
            if (this.Coes.length == 0) {
                swal.fire("First you have to add CoE, to add Scholar")
            }
            else {
                this.batchId = "0"
                this.sectionId = "0"
                this.modelHeadelLabel = "Add New Scholar"
                this.cleanModalData();
                this.isAddScholarModal = true
            }

        }
        else {
            this.modelHeadelLabel = "Add New Admin"
            this.isAddModal = true;
        }
    }
    hideAddEditModal() {
        this.isAddModal = false;
        this.isCheckedAll = "1"
        this.isCheckedAllTopic == "1"
        this.isAddCoordinatorSectioinMapingModal = false
        this.isAddFacultyTopicMapingModal = false
        this.isAddFacultySectioinMapingModal = false
        this.isAddScholarModal = false
        this.showScholarDetails = false
        this.isScholarImportModal = false;
        this.CheckedSections = [];
        this.CheckedTopics = [];
        this.cleanModalData()

    }
    saveUsers() {
        if (!this.name) {
            swal.fire("Please enter name.");
        }
        else if (!this.email) {
            swal.fire("Please enter email.");
        }
        else if (!(this.email.toLowerCase().match(this.emailPattern))) {
            swal.fire("Please enter correct email id.");
        }
        else if (!this.mobile) {
            swal.fire("Please enter mobile number.");
        }
        else if (this.mobile.length != 10) {
            swal.fire("Please enter correct mobile number.");
        }
        else {
            if (this.purpose == "Add") {
                {
                    let tempPassword = md5(this.password)
                    this.showLoader = true
                    this.userMasterService.addNewUserRatherThanScholar(this.name, this.email, this.mobile, tempPassword, this.roleIdSelected, localStorage.getItem("email"), this.userInfo).subscribe(
                        data => {
                            this.showLoader = false;
                            if (data.status_code == 0) {
                                this.isAddModal = false;
                                this.cleanModalData();
                                this.Users = data["users"];
                                this.unfilteredUsers = data["users"]
                                if (this.Users.length != 0) {
                                    this.openMapping(this.Users[0])
                                    // //.log("under getUserForScholarByRoleId")
                                } else {
                                    this.hideAddEditModal()
                                }
                                this.showAlertComponent(data['status_code'], data['status_message'])
                            }
                            else {
                                this.showAlertComponent(data['status_code'], data['status_message']);
                            }
                        },
                        err => {
                            this.showLoader = false;
                            this.isAddModal = false;
                            this.cleanModalData();
                            swal.fire("Something went wrong. Please try again later.")
                        }
                    )
                }
            }

            else {
                this.showLoader = true
                this.userMasterService.editUserRatherThanScholar(this.userId, this.name, this.email, this.mobile, this.roleIdSelected, localStorage.getItem("email"), this.userInfo).subscribe(
                    data => {
                        this.showLoader = false;
                        if (data.status_code == 0) {
                            this.isAddModal = false;
                            this.cleanModalData();
                            this.Users = data["users"];
                            this.unfilteredUsers = data["users"]
                            if (this.Users.length != 0) {
                                this.openMapping(this.Users[0])
                            } else {
                                this.hideAddEditModal()
                            }
                            this.showAlertComponent(data['status_code'], data['status_message'])
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.showLoader = false;
                        this.isAddModal = false;
                        this.cleanModalData();
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }
        }

    }

    editUser(data) {
        if (data.id_role == "5") {
            this.isAddScholarModal = true;
            this.userId = data.id_user;
            this.name = data.name;
            this.addressOne = data.address_one;
            this.addressTwo = data.address_two;
            this.mobile = data.mobile;
            this.email = data.email;
            this.fatherEmail = data.father_email;
            this.motherEmail = data.mother_email;
            this.fatherMobile = data.father_mobile;
            this.motherMobile = data.mother_mobile;
            this.stateId = data.id_state;
            this.districtId = data.id_district;
            this.pincode = data.pincode;
            this.categoryId = data.id_category;
            this.homeStateId = data.id_home_state;
            this.dakshnaRollNumber = data.dakshana_roll_number;
            this.coeRollNumber = data.coe_roll_number;
            this.city = data.city;
            this.coeId = data.id_coe
            this.gender = data.gender;


            this.filteredDistricts();
            //data.dob    )
            if (data.admission_date != "0000-00-00 00:00:00") {
                // let myDate = data.dob.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
                // this.dob = this.datepipe.transform(myDate, 'yyyy-MM-dd', 'es-ES');

            }

            // this.dob = data.dob.split("-").reverse().join("-");

            // this.dob = this.datepipe.transform(data.dob, 'dd-MM-yyyy', 'es-ES');
            // this.dob = this.datepipe.transform(this.dob, 'dd-MM-yyyy', 'es-ES');

            // let mdob = this.dob.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
            // this.dob = this.datepipe.transform(mdob, 'yyyy-MM-dd', 'es-ES');

            console.log("on edit dob =" + this.dob);

            this.adharNumber = data.adhar_card_number;
            this.schoolName = data.school_name
            this.getBatch();
            this.batchId = data.id_batch;
            this.checkCoeSelect();
            this.sectionId = data.id_section;
            this.pdValue = data.is_pd;
            this.admissionDate = data.admission_date
            this.purpose = "Edit"
            this.modelHeadelLabel = "Edit Scholar"
            //this.admissionDate)
            let myDate = data.admission_date.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
            this.admissionDate = this.datepipe.transform(myDate, 'yyyy-MM-dd', 'es-ES');
            // this.admissionDate = this.datepipe.transform(data.admission_date, 'yyyy/dd/MM');


            //("section Id " + this.sectionId)
        }
        else {
            if (data.id_role == "1") {
                this.modelHeadelLabel = "Edit Admin"
            }
            else if (data.id_role == "2") {
                this.modelHeadelLabel = "Edit Coordinator"
            }
            else if (data.id_role == "3") {
                this.modelHeadelLabel = "Edit Management"
            }
            else if (data.id_role == "4") {
                this.modelHeadelLabel = "Edit Faculty"
            }
            this.isAddModal = true;
            this.purpose = "Edit";
            this.name = data.name;
            this.email = data.email;
            this.mobile = data.mobile;
            this.userId = data.id_user;
            this.userInfo = data.user_info;
            this.roleIdSelected = data.id_role;
        }

        //.log(this.purpose)
    }
    cleanModalData() {
        this.purpose = "Add"
        this.name = "";
        this.email = "";
        this.gender = "";
        this.schoolName = "";
        this.dob = "";
        this.adharNumber = "";
        this.mobile = "";
        this.password = "";
        this.confirmPassword = "";
        this.name = "";
        this.addressOne = "";
        this.email = "";
        this.mobile = "";
        this.addressTwo = "";
        this.fatherEmail = "";
        this.fatherEmail = "";
        this.motherEmail = "";
        this.fatherMobile = "";
        this.motherMobile = "";
        this.city = "";
        this.districtId = "0";
        this.pincode = "";

        this.stateId = "0";
        this.homeStateId = "0";
        this.categoryId = "0";
        this.pdValue = "-1";
        this.dakshnaRollNumber = ""
        this.coeRollNumber = "";
        //this.coeId = "0";
        //  this.Batches = [];
        // this.Sections = [];
        // this.batchId = "0";
        // this.sectionId = "0";
        this.admissionDate = "";
        this.showScholarDetails = false
    }

    removeUser(data) {
        this.userId = data.id_user
        swal.fire({
            title: '',
            text: "Are you sure you want to remove this user?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#428e63',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.value) {
                this.userMasterService.removeUser(this.userId, this.roleIdSelected, localStorage.getItem("email")).subscribe(
                    data => {
                        this.showLoader = false;
                        this.unfilteredUsers = [];

                        if (data.status_code == 0) {
                            this.Users = [];
                            this.isAddModal = false;
                            this.cleanModalData();
                            //swal.fire(data["status_message"])
                            this.Users = data["users"];
                            this.unfilteredUsers = data["users"]
                            if (this.Users.length != 0) {
                                this.openMapping(this.Users[0])
                            }
                            else {
                                this.hideAddEditModal()
                            }
                            this.filterScholar()
                            this.showAlertComponent(data['status_code'], data['status_message'])
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                            if (this.Users.length != 0) {
                                this.openMapping(this.Users[0])
                            }
                            else {
                                this.hideAddEditModal()
                            }
                        }
                    },
                    err => {
                        this.showLoader = false;
                        this.isAddModal = false;
                        this.cleanModalData();
                        swal.fire("Something went wrong. Please try again later.")
                    }
                )
            }
        })
    }

    changeStatus(data) {
        this.userId = data.id_user
        var changeStatusId: any;
        if (data.id_status == 0) {
            changeStatusId = 1
        }
        else {
            changeStatusId = 0
        }
        this.showLoader = true
        this.userMasterService.changeUserStatus(this.userId, this.roleIdSelected, localStorage.getItem("email"), changeStatusId).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {
                    this.isAddModal = false;
                    this.cleanModalData();
                    this.Users = data["users"];
                    this.unfilteredUsers = data["users"]
                    if (this.Users.length != 0) {
                        this.openMapping(this.Users[0])
                    } else {
                        this.hideAddEditModal()
                    }
                    this.filterScholar()
                    this.showAlertComponent(data['status_code'], data['status_message'])
                }
                else {
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false;
                this.isAddModal = false;
                this.cleanModalData();
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }

    openMapping(data) {
        this.scholarData = {}
        this.scholarData = data
        this.userId = data.id_user
        this.serachCorrdinatorSectionMappingText = ""
        if (this.roleIdSelected == "2") {
            this.coordinatorName = data.name
            this.coodinatorId = data.id_user
            this.showCoordinatorSection = true
            this.getCooodinatorSectionMapping()

        }
        else if (this.roleIdSelected == "4") {
            // //.log("under openMapping")
            this.facultyName = data.name
            this.facultyId = data.id_user
            this.showFacultySection = true
            this.getFacultySectionMapping()


        }
        else if (this.roleIdSelected == "5") {
            this.showScholarDetails = true
            this.scholarMobileNumber = data.mobile
            this.dakshnaRollNumber = data.dakshana_roll_number
            console.log("dob=" + data.dob);
            this.dob = data.dob.split("-").reverse().join("-");
            console.log("dob1=" + this.dob);
            try {
                // this.dob = this.datepipe.transform(this.dob, 'yyyy-MM-dd', 'es-ES');
                console.log("dob2=" + this.dob);
                this.dob = this.datepipe.transform(this.dob, 'yyyy-MM-dd', 'es-ES');
                console.log("on openMapping dob =" + this.dob);
            } catch (e) {
                this.dob = "";
            }

            //  this.dob = this.datepipe.transform(data.dob, 'MM/dd/yyyy', 'es-ES'); data.dob
            this.coe_name = data.coe_name
            this.scholarEmail = data.email
            this.addressOne = data.address_one;
            this.addressTwo = data.address_two;
            this.city = data.city;
            this.state = data.state_name;
            this.home_state = data.home_state_name;

            this.fatherEmail = data.father_email;
            this.motherEmail = data.mother_email;
            this.fatherMobile = data.father_mobile;
            this.motherMobile = data.mother_mobile;
            this.name = data.name;
            this.scholarCOENumber = data.coe_roll_number;
            this.scholarDocumnets = [];
            this.getScholarDocuments(data.coe_roll_number);
        }
        else if (this.roleIdSelected == "7") {
            this.showScholarDetails = true
            this.externalScholarData = data;
        }
        else {
            this.showCoordinatorSection = false
        }
    }

    addNewCoordinatorMapping() {
        this.isCheckedAll = "1";
        this.isAddCoordinatorSectioinMapingModal = true
        this.showLoader = true

        this.userMasterService.getAllCoe(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false
                if (data.status_code == "0") {
                    this.Coes = data.coes
                    this.getAllBatch()
                }
                else {
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false
            }
        )
    }

    getAllBatch() {
        this.showLoader = true
        this.userMasterService.getAllBatches(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false
                if (data.status_code == "0") {
                    this.tempBatches = data.batches
                    this.getAllSection()
                }
                else {
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false
            }
        )
    }

    getAllSection() {
        this.showLoader = true
        this.userMasterService.getAllSections(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false
                if (data.status_code == "0") {
                    this.tempSections = data.sections
                }
                else {
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false
            }
        )
    }

    getBatch() {
        this.Sections = [];
        this.Batches = []
        for (let data of this.tempBatches) {
            if (this.coeId == data.id_coe) {
                this.Batches.push(data)
            }
        }
    }

    checkCoeSelect() {
        this.Sections = [];
        if (this.coeId == "0") {
            swal.fire("First select CoE.");
        }
        else {
            for (let data of this.tempSections) {
                if (this.coeId == data.id_coe && this.batchId == data.id_batch) {
                    this.Sections.push(data)
                }
            }
        }
    }

    selectAllSection() {
        this.CheckedSections = []
        if (this.isCheckedAll == "1") {
            this.isCheckedAll = "0"
            for (let data of this.Sections) {
                let json = {
                    "id_section": data.id_section
                }
                this.CheckedSections.push(json)
            }
        }
        else {
            this.isCheckedAll = "1"
            this.CheckedSections = []
        }
        //(this.CheckedSections)
    }

    checkSectionIschecked(idSection) {
        var isChecked = false;
        if (this.CheckedSections.length != 0) {
            for (let checkedData of this.CheckedSections) {
                if (idSection == checkedData.id_section) {
                    isChecked = true;
                    break;
                }

            }

        }
        else {
            return false
        }
        if (isChecked) {
            return true;
        }
        else {
            return false;
        }
    }

    checkUncheckSection(idSection) {
        var check = true
        //.log(idSection)
        if (this.CheckedSections.length != 0) {
            for (let i = 0; i < this.CheckedSections.length; i++) {
                if (this.CheckedSections[i].id_section == idSection) {
                    this.CheckedSections.splice(i, 1)
                    this.isCheckedAll = "1"
                    check = false
                    break;
                }
                else {
                    check = true;
                }
            }

            if (check == true) {
                let json = {
                    "id_section": idSection
                }
                this.CheckedSections.push(json)
            }
        }
        else {
            let json = {
                "id_section": idSection
            }
            this.CheckedSections.push(json)
        }
    }

    saveCoordinatorSectionMapping() {
        //(this.CheckedSections)
        if (this.coeId == "0") {
            swal.fire("Please select CoE.")
        }
        else if (this.batchId == "0") {
            swal.fire("Please select batch.")
        }
        else if (this.CheckedSections.length == 0) {
            swal.fire("Please select at least one section.")
        }
        else {
            let json = {
                "caller_email": localStorage.getItem("email"),
                "id_coordinator": this.coodinatorId,
                "id_coe": this.coeId,
                "sections": JSON.stringify(this.CheckedSections)
            }

            this.showLoader = true
            this.userMasterService.addCoordinatorSectionMapping(json).subscribe(
                data => {
                    this.showLoader = false
                    if (data.status_code == "0") {
                        this.CoordinatoSectionMapping = data.coordinator_section_mappings
                        this.isAddCoordinatorSectioinMapingModal = false
                        this.coeId = "0"
                        this.batchId = "0"
                        this.Sections = [];
                        this.Batches = [];
                        this.CheckedSections = []
                    }
                    else {
                        this.showAlertComponent(data['status_code'], data['status_message']);
                    }
                },
                err => {
                    this.showLoader = false
                }
            )
        }
    }

    removeCoordinatorSectionMapping(data) {
        swal.fire({
            title: '',
            text: "Are you sure you want to remove this coordinator section?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#428e63',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.value) {
                this.showLoader = true
                this.userMasterService.removeCoordinatorSectionMappings(localStorage.getItem("email"), this.coodinatorId, data.id_coe, data.id_section).subscribe(
                    data => {
                        this.showLoader = false
                        this.CoordinatoSectionMapping = []
                        if (data.status_code == "0") {
                            this.CoordinatoSectionMapping = data.coordinator_section_mappings
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.showLoader = false
                    }
                )
            }
        })




        //this.CoordinatoSectionMapping=[]

    }

    getCooodinatorSectionMapping() {
        this.CoordinatoSectionMapping = []
        this.showLoader = true
        this.userMasterService.getCoordinatorSectionMappings(localStorage.getItem("email"), this.coodinatorId).subscribe(
            data => {
                this.showLoader = false
                if (data.status_code == "0") {
                    this.CoordinatoSectionMapping = data.coordinator_section_mappings
                }
                else {
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false
            }
        )
    }

    addNewFacultySectionMapping() {
        this.isAddFacultySectioinMapingModal = true
        this.showLoader = true

        this.userMasterService.getAllCoe(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false
                if (data.status_code == "0") {
                    this.Coes = data.coes
                    this.getAllBatch()
                }
                else {
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false
            }
        )
    }

    saveFacultySectionMapping() {
        //(this.CheckedSections)
        if (this.coeId == "0") {
            swal.fire("Please select CoE.")
        }
        else if (this.batchId == "0") {
            swal.fire("Please select batch.")
        }
        else if (this.CheckedSections.length == 0) {
            swal.fire("Please select at least one section.")
        }
        else {
            let json = {
                "caller_email": localStorage.getItem("email"),
                "id_faculty": this.facultyId,
                "sections": JSON.stringify(this.CheckedSections)
            }

            this.showLoader = true
            this.userMasterService.addFacultySectionMapping(json).subscribe(
                data => {
                    this.showLoader = false
                    if (data.status_code == "0") {
                        this.FacultySectionMapping = data.faculty_section_mappings
                        this.isAddFacultySectioinMapingModal = false
                        this.coeId = "0"
                        this.batchId = "0"
                        this.Sections = [];
                        this.Batches = [];
                        this.CheckedSections = []
                    }
                    else {
                        this.showAlertComponent(data['status_code'], data['status_message']);
                    }
                },
                err => {
                    this.showLoader = false
                }
            )
        }
    }

    getFacultySectionMapping() {
        this.FacultySectionMapping = []
        this.showLoader = true
        this.userMasterService.getFacultySectionMappings(localStorage.getItem("email"), this.facultyId).subscribe(
            data => {
                this.showLoader = false
                if (data.status_code == "0") {
                    this.FacultySectionMapping = data.faculty_section_mappings
                    this.getFacultyTopicsMapping();
                }
                else {
                    this.getFacultyTopicsMapping();
                }
            },
            err => {
                this.showLoader = false
            }
        )
    }

    removeFacultySectionMapping(data) {

       

        swal.fire({
            title: '',
            text: "Are you sure you want to remove this faculty section?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#428e63',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.value) {
                this.showLoader = true
                this.userMasterService.removeFacultySectionMappings(localStorage.getItem("email"), this.facultyId, data.id_section).subscribe(
                    data => {
                        this.showLoader = false
                        this.FacultySectionMapping = []
                        if (data.status_code == "0") {
                            this.FacultySectionMapping = data.faculty_section_mappings
                        }
                        else {
                            //swal.fire(data.status_message)
                        }
                    },
                    err => {
                        this.showLoader = false
                    }
                )
            }
        })



    }

    addNewFacultyTopicMapping() {
        this.isCheckedAllTopic = "1"
        this.isAddFacultyTopicMapingModal = true
        this.showLoader = true
        this.Subjects = []
        this.SubSubjects = []
        this.Topics = []
        this.subjectId = "0"
        this.subSubjectId = "0"
        this.showLoader = true
        this.userMasterService.getAllSubject(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false
                if (data.status_code == "0") {
                    this.Subjects = data.subjects
                    this.getAllSubSubject()
                }
                else {

                }
            },
            err => {
                this.showLoader = false
            }
        )
    }

    getAllSubSubject() {
        this.showLoader = true

        this.userMasterService.getAllSubSubject(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false
                if (data.status_code == "0") {
                    this.tempSubSubjects = data.sub_sujects
                    this.getAllTopics()
                }
                else {

                }
            },
            err => {
                this.showLoader = false
            }
        )
    }

    getAllTopics() {
        this.showLoader = true

        this.userMasterService.getAllTopics(localStorage.getItem("email")).subscribe(
            data => {
                this.showLoader = false
                if (data.status_code == "0") {
                    this.tempTopics = data.topics

                }
                else {

                }
            },
            err => {
                this.showLoader = false
            }
        )
    }

    getSubSubject() {
        this.SubSubjects = []
        this.subSubjectId = "0"
        for (let data of this.tempSubSubjects) {
            if (data.id_subject == this.subjectId) {
                this.SubSubjects.push(data);
            }
        }
        //.log(this.subjectId)
        //.log("this.tempTopics" + this.tempTopics)
        this.Topics = []
        for (let data of this.tempTopics) {
            if (data.id_subject == this.subjectId) {
                this.Topics.push(data);
            }
        }
        //.log("this.Topics" + JSON.stringify(this.Topics))
    }

    getTopic() {
        if (this.subjectId == "0") {
            swal.fire("Please select subject.");
        }
        else {
            this.Topics = []
            for (let data of this.tempTopics) {
                if (data.id_sub_subject == this.subSubjectId) {
                    this.Topics.push(data);
                }
            }
        }
    }

    selectAllSectionTopic() {
        this.CheckedTopics = []
        if (this.isCheckedAllTopic == "1") {
            this.isCheckedAllTopic = "0"
            for (let data of this.Topics) {
                let json = {
                    "id_topic": data.id_topic
                }
                this.CheckedTopics.push(json)
            }
        }
        else {
            this.isCheckedAllTopic = "1"
            this.CheckedTopics = []
        }
    }






    checkTopicIschecked(topicId) {
        var isChecked = false;
        if (this.CheckedTopics.length != 0) {
            for (let checkedData of this.CheckedTopics) {
                if (topicId == checkedData.id_topic) {
                    isChecked = true;
                    break;
                }
                else {
                    isChecked = false;
                }

            }

        }
        else {
            return false
        }
        if (isChecked) {
            return true;
        }
        else {
            return false;
        }
    }

    checkUncheckTopic(topicId) {
        //(topicId)
        var check = true
        if (this.CheckedTopics.length != 0) {
            for (let i = 0; i < this.CheckedTopics.length; i++) {
                if (this.CheckedTopics[i].id_topic == topicId) {
                    this.CheckedTopics.splice(i, 1)
                    this.isCheckedAllTopic = "1"
                    check = false
                    break;
                }
                else {
                    check = true;
                }
            }

            if (check == true) {
                let json = {
                    "id_topic": topicId
                }
                this.CheckedTopics.push(json)
            }
        }
        else {
            let json = {
                "id_topic": topicId
            }
            this.CheckedTopics.push(json)
        }
        //(this.CheckedTopics)

    }

    saveFacultyTopicMapping() {
        //(this.CheckedTopics)
        if (this.subjectId == "0") {
            swal.fire("Please select subject.")
        }
        else if (this.CheckedTopics.length == 0) {
            swal.fire("Please select at least one topic.")
        }
        else {
            let json = {
                "caller_email": localStorage.getItem("email"),
                "id_faculty": this.facultyId,
                "topics": JSON.stringify(this.CheckedTopics)
            }

            this.showLoader = true
            this.userMasterService.addFacultyTopicMapping(json).subscribe(
                data => {
                    this.showLoader = false
                    if (data.status_code == "0") {
                        this.FacultyTopicMapping = data.faculty_topic_mappings
                        this.subjectId = "0"
                        this.subSubjectId = "0"
                        this.Subjects = [];
                        this.SubSubjects = [];
                        this.CheckedTopics = [];
                        this.hideAddEditModal()
                    }
                    else {
                        this.showAlertComponent(data['status_code'], data['status_message']);
                    }
                },
                err => {
                    this.showLoader = false
                }
            )
        }
    }

    getFacultyTopicsMapping() {
        this.showLoader = true
        this.FacultyTopicMapping = []
        this.userMasterService.getFacultyTopicMapping(localStorage.getItem("email"), this.facultyId).subscribe(
            data => {
                this.showLoader = false
                if (data.status_code == "0") {
                    this.FacultyTopicMapping = data.faculty_topic_mappings
                    //(this.FacultyTopicMapping)
                }
                else {
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false
            }
        )
    }

    removeFacultyTopicMapping(data) {
        swal.fire({
            title: '',
            text: "Are you sure you want to remove this faculty topic?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#428e63',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.value) {
                this.showLoader = true
                this.userMasterService.removeFacultyTopicsMappings(localStorage.getItem("email"), this.facultyId, data.id_topic).subscribe(
                    data => {
                        this.showLoader = false
                        this.FacultyTopicMapping = []
                        if (data.status_code == "0") {
                            this.FacultyTopicMapping = data.faculty_topic_mappings
                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.showLoader = false
                    }
                )
            }
        })

    }


    saveSholar() {
        //.log(this.gender)
        if (!this.name) {
            swal.fire("Please enter name.");
        }
        else if (!this.addressOne) {
            swal.fire("Please enter address line 1.");
        }
        else if (!this.gender) {
            swal.fire("Please select gender.");
        }

        else if (!this.adharNumber) {
            swal.fire("Please enter adhar card number.");
        }
        else if (!this.schoolName) {
            swal.fire("Please enter school name.");
        }
        else if (this.mobile.length != 10) {
            swal.fire("Please enter correct mobile number.");
        }
        else if (!this.dob) {
            swal.fire("Please enter DOB.");
        }
        else if (!this.email) {
            swal.fire("Please enter email.");
        }
        else if (!(this.email.toLowerCase().match(this.emailPattern))) {
            swal.fire("Please enter correct email id.");
        }
        else if (!this.city) {
            swal.fire("Please enter city.");
        }
        else if (this.stateId == "0") {
            swal.fire("Please select state.");
        } else if (this.districtId == "0") {
            swal.fire("Please select district.");
        }
        else if ((this.pincode == "") || (this.pincode == null)) {
            swal.fire("Please enter pincode.");
        }
        else if (this.pincode.length != 6) {
            swal.fire("Please enter valid pincode.");
        }
        else if (!this.admissionDate) {
            swal.fire("Please enter admission date.");
        }
        else if (this.fatherEmail && !(this.fatherEmail.toLowerCase().match(this.emailPattern))) {
            swal.fire("Please enter correct father email.")
        }
        else if (this.motherEmail && !(this.motherEmail.toLowerCase().match(this.emailPattern))) {
            swal.fire("Please enter correct mother email.")
        }
        else if (!this.fatherMobile) {
            swal.fire("Please enter father mobile number.");
        }
        else if (this.fatherMobile.length != 10) {
            swal.fire("Please enter correct father mobile number.");
        }
        else if (this.motherMobile && this.motherMobile.length != 10) {
            swal.fire("Please enter correct mother mobile number.");
        }
        else if (this.coeId == "0") {
            swal.fire("Please select CoE.");
        }
        else if (this.batchId == "0") {
            swal.fire("Please select batch.");
        }
        else if (this.sectionId == "0") {
            swal.fire("Please select section.");
        }
        else if (!this.dakshnaRollNumber) {
            swal.fire("Please enter dakshna roll number.");
        }
        else if (!this.coeRollNumber) {
            swal.fire("Please enter CoE roll number.");
        }
        else if (this.categoryId == "0") {
            swal.fire("Please select category.")
        }
        else if (this.homeStateId == "0") {
            swal.fire("Please select home state.")
        }
        else if (this.pdValue == "-1") {
            swal.fire("Please select PD.")
        }
        else {

            var birthdate = this.datepipe.transform(this.dob, 'dd-MM-yyyy', 'es-ES');

            if (this.purpose == "Add") {

                let json = {
                    "caller_email": localStorage.getItem("email"),
                    "id_role": this.roleIdSelected,
                    "name": this.name,
                    "address_one": this.addressOne,
                    "mobile": this.mobile,
                    "email": this.email,
                    "address_two": this.addressTwo,
                    "city": this.city,
                    "admission_date": this.admissionDate,
                    "father_email": this.fatherEmail,
                    "father_mobile": this.fatherMobile,
                    "mother_mobile": this.motherMobile,
                    "mother_email": this.motherEmail,
                    "dakshana_roll_number": this.dakshnaRollNumber,
                    "coe_roll_number": this.coeRollNumber,
                    "is_pd": this.pdValue,
                    "id_state": this.stateId,
                    "id_district": this.districtId,
                    "pincode": this.pincode,
                    "id_category": this.categoryId,
                    "id_coe": this.coeId,
                    "id_batch": this.batchId,
                    "id_section": this.sectionId,
                    "id_home_state": this.homeStateId,
                    "gender": this.gender,
                    "dob": birthdate,
                    "adhar_card_number": this.adharNumber,
                    "school_name": this.schoolName
                }
                this.showLoader = true
                this.userMasterService.addScholar(json).subscribe(
                    data => {
                        this.showLoader = false
                        if (data.status_code == "0") {
                            this.Users = data.users;
                            this.hideAddEditModal();
                            this.cleanModalData();
                            this.filterScholar()

                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.showLoader = false
                    }
                )
            }
            else {
                let json = {
                    "id_user": this.userId,
                    "caller_email": localStorage.getItem("email"),
                    "id_role": this.roleIdSelected,
                    "name": this.name,
                    "address_one": this.addressOne,
                    "mobile": this.mobile,
                    "email": this.email,
                    "address_two": this.addressTwo,
                    "city": this.city,
                    "admission_date": this.admissionDate,
                    "father_email": this.fatherEmail,
                    "father_mobile": this.fatherMobile,
                    "mother_mobile": this.motherMobile,
                    "mother_email": this.motherEmail,
                    "dakshana_roll_number": this.dakshnaRollNumber,
                    "coe_roll_number": this.coeRollNumber,
                    "is_pd": this.pdValue,
                    "id_state": this.stateId,
                    "id_district": this.districtId,
                    "pincode": this.pincode,
                    "id_category": this.categoryId,
                    "id_coe": this.coeId,
                    "id_batch": this.batchId,
                    "id_section": this.sectionId,
                    "id_home_state": this.homeStateId,
                    "gender": this.gender,
                    "dob": birthdate,
                    "adhar_card_number": this.adharNumber,
                    "school_name": this.schoolName
                }
                this.showLoader = true
                this.userMasterService.editScholar(json).subscribe(
                    data => {
                        this.showLoader = false
                        if (data.status_code == "0") {
                            this.Users = data.users;
                            this.unfilteredUsers = data.users
                            this.hideAddEditModal();
                            this.cleanModalData();
                            this.filterScholar()

                        }
                        else {
                            this.showAlertComponent(data['status_code'], data['status_message']);
                        }
                    },
                    err => {
                        this.showLoader = false
                    }
                )
            }
        }

    }

    filterScholar() {
        this.Batches = [];
        this.Sections = [];
        this.Users = this.unfilteredUsers;
        if (this.coeId != "0") {
            //.log(this.coeId)
            //.log(this.tempBatches)
            for (let data of this.tempBatches) {
                if (data.id_coe == this.coeId) {
                    //.log("test")
                    this.Batches.push(data)
                }
            }
            for (let data of this.tempSections) {
                if (data.id_coe == this.coeId) {
                    this.Sections.push(data)
                }
            }
            var temporaryArray = [];
            for (let data of this.Users) {
                if (data.id_coe == this.coeId) {
                    temporaryArray.push(data)
                }
            }
            this.Users = temporaryArray;
        }
        if (this.batchId != "0") {
            this.Sections = []
            var temporaryArray = [];
            for (let data of this.tempSections) {
                if (data.id_batch == this.batchId) {
                    this.Sections.push(data)
                }
            }
            for (let data of this.Users) {
                if (data.id_batch == this.batchId) {
                    temporaryArray.push(data)
                }
            }
            this.Users = temporaryArray;
        }
        if (this.sectionId != "0") {
            var temporaryArray = [];
            for (let data of this.Users) {
                if (data.id_section == this.sectionId) {
                    temporaryArray.push(data)
                }
            }
            this.Users = temporaryArray;
        }

        if((this.selectedStatus !="0")&&(this.selectedStatus !="-1"))
        {
            var temporaryArray = [];
            if(this.selectedStatus == "1"){
                for (let data of this.Users) {
                    if (data.id_status == "0") {
                        temporaryArray.push(data)
                    }
                }
            }

            if(this.selectedStatus == "2"){
                for (let data of this.Users) {
                    if (data.id_status == "1") {
                        temporaryArray.push(data)
                    }
                }
            }

            this.Users = temporaryArray; 
        }

        if (this.Users.length == 0) {
            this.hideAddEditModal();
        }
        else {
            if (this.Users.length != 0) {
                this.openMapping(this.Users[0])
                //.log("under filterScholar")
            }
            else {
                //   this.hideAddEditModal()
            }
        }
    }

    closeSideView() {
        //(this.searchText)
        if (this.searchText != "") {
            this.hideAddEditModal();
        }
    }

    exportScholarData() {
        var arrayToExport = [];
        if (this.Users.length > 0) {
            for (let data of this.Users) {
                var pd = ""
                if (data.is_pd == "1") {
                    pd = "No"
                }
                else {
                    pd = "Yes"
                }
                var dob: any;
                var admissionDate: any;
                dob = data.dob;
                // if (data.admission_date != "0000-00-00 00:00:00") {
                //     let myDate = data.dob.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
                //     dob = this.datepipe.transform(myDate, 'yyyy-MM-dd', 'es-ES');

                // }
                try {
                    let myDate = data.admission_date.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
                    admissionDate = this.datepipe.transform(myDate, 'yyyy-MM-dd', 'es-ES');
                } catch (e) { }
                let json = {
                    "Scholar Name": data.name,
                    "Mobile": data.mobile,
                    "Email": data.email,
                    "Dakshna Roll Number": data.dakshana_roll_number,
                    "CoE Roll Number": data.coe_roll_number,
                    "CoE": data.coe_name,
                    "Batch": data.batch_name,
                    "Section": data.section_name,
                    "Address One": data.address_one,
                    "Address Two": data.address_two,
                    "City": data.city,
                    "State": data.state_name,
                    "Pincode": data.pincode,
                    "Father Mobile Number": data.father_mobile,
                    "Mother Mobile Number": data.mother_mobile,
                    "Father Email": data.father_email,
                    "Mother Email": data.mother_email,
                    "Category": data.category_name,
                    "PD": pd,
                    "Gender": data.gender,
                    "DOB": dob,
                    "Adhar Card Number": data.adhar_card_number,
                    "School Name": data.school_name
                }
                arrayToExport.push(json);
            }
            CSVExport.downloadcsv(arrayToExport, "scholarRecords");
        }
        else {
            swal.fire("There is no record to export.")
        }

    }
    csv2Array(fileInput: any) {
        this.fileInput = fileInput
    }
    importScholarData() {
        this.isScholarImportModal = true;
    }

    saveImportData() {
        if ((!this.coeId) || this.coeId == "0") {
            swal.fire("Please select CoE.")
        }
        else if ((!this.batchId) || this.batchId == "0") {
            swal.fire("Please select batch.")
        }
        else if ((!this.sectionId) || this.sectionId == "0") {
            swal.fire("Please select section.")
        }
        else if (!this.fileInput) {
            swal.fire("Please select file.")
        }
        else {
            this.fileReaded = this.fileInput.target.files[0];
            let reader: FileReader = new FileReader();
            reader.readAsText(this.fileReaded);
            let lines = [];
            reader.onload = (e) => {
                let csv: string = reader.result as string as string;
                let allTextLines = csv.split(/\r|\n|\r/);
                let headers = allTextLines[0].split(',');


                for (let i = 0; i < allTextLines.length; i++) {
                    // split content based on comma
                    let data = allTextLines[i].split(',');
                    if (data.length === headers.length) {
                        let tarr = [];
                        for (let j = 0; j < headers.length; j++) {
                            tarr.push(data[j]);
                        }

                        // log each row to see output 
                        lines.push(tarr);

                    }
                }
                let json = {
                    "caller_email": localStorage.getItem("email"),
                    "id_coe": this.coeId,
                    "id_batch": this.batchId,
                    "id_section": this.sectionId,
                    "excel": JSON.stringify(lines)
                }
                this.showLoader = true
                this.userMasterService.importScholarData(json).subscribe(
                    data => {
                        this.showLoader = false
                        if (data.status_code == "0") {
                            // this.Users = data.users;
                            this.unfilteredUsers = data.users
                            this.cleanModalData();
                            this.hideAddEditModal();
                            this.filterScholar()
                            //.log(this.Users)
                            swal.fire(data.status_message)
                        }
                        else {
                            this.hideAddEditModal();
                            this.cleanModalData();
                            this.filterScholar()
                            swal.fire(data.status_message)
                        }
                    },
                    err => {
                        this.showLoader = false
                        swal.fire("Something went wrong, Please try again later.")
                        this.hideAddEditModal()
                    }
                )
            }
        }

    }

    showAlertDiv(message) {
        var that = this
        that.alertDiv = true
        this.sucessAlertMessage = message
        setTimeout(function () {
            that.alertDiv = false
        }, 5000);
    }

    checkSelectedOrNot(data) {
        if (data.id_user == this.userId) {
            return true
        }
        else {
            return false
        }
    }

    showAlertComponent(status, messege) {
        this.showAlert = true;
        this.alertMsg = messege;
        //("status" + status);
        if (parseInt(status) == 0) {
            this.alertType = "success";
        }
        else {
            this.alertType = "failure";
        }
    }
    hideAlert(val) {
        this.showAlert = val;
    }
    sort(property) {
        this.isDesc = !this.isDesc; //change the direction    
        this.column = property;
        let direction = this.isDesc ? 1 : -1;

        this.Users.sort(function (a, b) {
            if (a[property] < b[property]) {
                return -1 * direction;
            }
            else if (a[property] > b[property]) {
                return 1 * direction;
            }
            else {
                return 0;
            }
        });
    }

    approveRejectRegistration(data, status) {
        this.showLoader = true
        this.userMasterService.changeExternalScholarRegistrationStatus(data.id_user, localStorage.getItem("email"), status).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {
                    this.getUserForScholarByRoleId();
                    this.showAlertComponent(data['status_code'], data['status_message'])
                }
                else {
                    this.showAlertComponent(data['status_code'], data['status_message']);
                }
            },
            err => {
                this.showLoader = false;
                this.isAddModal = false;
                this.cleanModalData();
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }

    filterExternalRegistration() {
        this.Users = this.unfilteredUsers;
        if (this.externalRegistraionStatus != '0') {
            this.Users = this.unfilteredUsers.filter((x) => x.scholar_registration_status == this.externalRegistraionStatus);
        }
    }

    downloadDocument(data, name, imageData) {
        var fileName = "";
        var image = "";
        fileName = data.name + "_" + name + ".png"
        image = 'data:image/png;base64,' + imageData;
        const downloadLink = document.createElement("a");
        downloadLink.href = image;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    downloadScholarDocument(data) {
        var fileName = "";
        var image = "";
        if (data.content_type == "application/pdf") {
            fileName = this.scholarData.coe_roll_number + "-" + this.scholarData.name + "-" + data.document_type + ".pdf"
            image = 'data:application/pdf;base64,' + btoa(data.string_images).replace(/.{76}(?=.)/g, '$&\n');
        }
        else {
            fileName = this.scholarData.coe_roll_number + "-" + this.scholarData.name + "-" + data.document_type + ".jpg"
            image = 'data:image/jpeg;base64,' + btoa(data.string_images).replace(/.{76}(?=.)/g, '$&\n');
        }
        const downloadLink = document.createElement("a");

        downloadLink.href = image;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    openExternalScholarEditModal(data) {
        this.getAllState();
        this.externalEditScholarData = data;
        this.externalEditScholarData['dob'] = this.datepipe.transform(data.dob, 'yyyy-MM-dd', 'es-ES')
        this.isEditExternalScholarModal = true;
    }

    closeExternalScholarEditModal() {
        this.isEditExternalScholarModal = false;
    }

    saveExternalSholar() {
        if (!this.externalEditScholarData.name) {
            swal.fire("Please enter name.");
        }
        else if (!this.externalEditScholarData.dob) {
            swal.fire("Please enter dob.");
        }
        else if (!this.externalEditScholarData.email) {
            swal.fire("Please enter email.");
        }
        else if (!this.externalEditScholarData.mobile) {
            swal.fire("Please enter mobile.");
        }
        else if (!this.externalEditScholarData.school_name) {
            swal.fire("Please enter school name.");
        }
        else if (this.externalEditScholarData.id_state == 0) {
            swal.fire("Please select state.");
        }
        else if (!this.externalEditScholarData.district) {
            swal.fire("Please enter district.");
        }
        else if (!this.externalEditScholarData.address) {
            swal.fire("Please enter address.");
        }
        else if (this.externalEditScholarData.id_course_type == 0) {
            swal.fire("Please  select course type.");
        }
        else if (this.externalEditScholarData.applicable_for == 0) {
            swal.fire("Please  select class.");
        }
        else if (!this.externalEditScholarData.year_attempt) {
            swal.fire("Please enter year of attempt.");
        }

        else {

            this.externalEditScholarData['caller_email'] = localStorage.getItem("email");
            this.showLoader = true
            this.userMasterService.editExternalScholar(this.externalEditScholarData).subscribe(
                data => {
                    this.showLoader = false
                    if (data.status_code == "0") {
                        this.Users = data.users;
                        this.closeExternalScholarEditModal();
                        this.getUserForScholarByRoleId();

                    }
                    else {
                        this.showAlertComponent(data['status_code'], data['status_message']);
                    }
                },
                err => {
                    this.showLoader = false
                }
            )

        }

    }

    getImage(imageData) {
        var image;
        image = 'data:image/jpeg;base64,' + imageData;
        image = this.domSanitizer.bypassSecurityTrustUrl(image);
        return image;
    }

    filteredDistrict = [];
    filteredDistricts() {
        this.filteredDistrict = [];
        this.filteredDistrict = this.districts.filter(val => (val.id_state == this.stateId));
    }

    viewDocuments(data) {
        this.isViewDocumentModal = true;
    }

    viewDocument(data) {
        this.scholarDocument = {}
        this.isViewSingleDocumentModal = true
        this.scholarDocument = data
    }

    getScholarDocuments(path) {
        this.showLoader = true;
        this.scholarDocumnets = [];
        var that = this
        this.uploadService.getFile(path).then(function (data: any) {
            var objectsArray = [];
            for (let file of data) {
                let documentName = file.Key.split('/').pop().split('.')[0]
                that.uploadService.getDocument(file).then(
                    function (fileObject: any) {
                        var data = fileObject
                        var json = { 'document_type': documentName }
                        var str = data.Body.reduce(function (a, b) { return a + String.fromCharCode(b) }, '');
                        json['string_images'] = str
                        if (data.ContentType != "application/pdf") {
                            json['document'] = that.getImage(btoa(str).replace(/.{76}(?=.)/g, '$&\n'))
                        }
                        else {
                            json['document'] = that.getPDF(btoa(str).replace(/.{76}(?=.)/g, '$&\n'))
                        }
                        json['content_type'] = data.ContentType
                        json['coe_roll_number'] = path
                        json['key'] = file.Key
                        that.scholarDocumnets.push(json)
                    }
                );
            }
            that.showLoader = false;
        });
    }

    public getPDF(data) {
        var image = 'data:application/pdf;base64,' + data;
        return image;
    }

    hideViewDocumentModal() {
        this.isViewDocumentModal = false;
    }

    hideSingleDocumentModal() {
        this.isViewSingleDocumentModal = false;
    }

    downloadImageAsZip() {
        var zip = new JSZip();
        var zipFilename = this.scholarData.coe_roll_number + "-" + this.scholarData.name + "-" + ".zip";
        var count = 0;
        for (let file of this.scholarDocumnets) {
            var fileName = ""
            if (file.content_type == "application/pdf") {
                fileName = file.document_type + ".pdf"
            }
            else {
                fileName = file.document_type + ".jpg"
            }
            zip.file(fileName, file.string_images, { binary: true });
            count++;
            if (count == this.scholarDocumnets.length) {
                zip.generateAsync({ type: 'blob' }).then(function (content) {
                    saveAs(content, zipFilename);
                });
            }
        }
    }

    getScholarPhoto() {
        var imageString = "../assets/logoscholar.jpg";
        if (this.scholarDocumnets.length > 0) {
            let index = this.scholarDocumnets.findIndex(
                (data, index) => data.document_type === "Scholar Photo"
            );

            if (index != -1) {
                imageString = this.scholarDocumnets[index].document;
            }
        }
        return imageString;
    }

     upload() {
        this.isUploadModal = true
      }
      hideUploadModal() {
        this.isUploadModal = false;
      }
    
      uploadDocument(data) {
        this.scholarDocument = data
        //(this.scholarDocument)
        this.isUploadModal = true
      }
      

    uploadDocumnets() {
        let file = this.fileInput.target.files[0];
        this.isErrorMessage = false
        this.errorMessage = ""
        //(file)
        console.log("file name="+file.name)
        let documentName = file.name.split('/').pop().split('.')[0]
        console.log("documentName="+documentName);
        console.log("document_type="+this.scholarDocument.document_type);
        
        if (!this.fileInput) {
          this.isErrorMessage = true
          this.errorMessage = "Please select file to upload."
    
        }
        else if (this.scholarDocument.document_type != documentName) {
          this.isErrorMessage = true
          this.errorMessage = "Please select correct file."
    
        }
        else if (file.type != "image/jpeg" && file.type != "image/png") {
          this.isErrorMessage = true
          this.errorMessage = "You can upload only image in jpeg or png format."
    
        }
        else {
          var that = this
          this.uploadService.uploadFile(that.scholarDocument.coe_roll_number, file).then(function (data: any) {
            that.hideUploadModal();
            that.isViewDocumentModal = true
            that.getScholarDocuments(that.scholarDocument.coe_roll_number)
          });
        }
         
      }
    
    removeDocument(dataObject) {

        var that = this;
        swal.fire({
            title: '',
            text: "Are you sure you want to remove this "+dataObject.document_type+" document?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#428e63',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.value) {
                that.uploadService.deleteFile(dataObject.key).then(function (data: any) {
                    that.hideUploadModal();
                    that.isViewDocumentModal = true
                    that.getScholarDocuments(dataObject.coe_roll_number)
                });
            }
        })      
    
    }

    resetPassword(sc_data){
        var json = {"id_user":sc_data.id_user,"dob":sc_data.dob,"password":(sc_data.dob)?md5(sc_data.dob):md5('dakshana123'),"email":sc_data.email, "caller_email": localStorage.getItem("email"),}
        var that = this;
        swal.fire({
            title: '',
            text: "Password will be reset to scholars default password i.e. Birth date in DD-MM-YYYY format.Are you sure you want to reset? (Email will be sent to the scholar on his registered email id)",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#428e63',
            confirmButtonText: 'Yes, reset it!'
        }).then((result) => {
            if (result.value) {
                that.userMasterService.resetPassword(json).subscribe(
                    data => {
                        this.showLoader = false;                        
                        //this.showAlertComponent(data['status_code'], data['status_message']);
                        swal.fire(data['status_message'])
                    },
                    err => {
                        this.showLoader = false;
                        swal.fire("Something went wrong. Please try again later.")
                    }
                );
            }
        })
    }
         
}
