import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QulayfyingProbablityReportsService } from './qualifying-probablity-reports.service'
import swal from 'sweetalert2'
import { Observable } from 'rxjs/Rx';
import * as moment from 'moment';
@Component({
  selector: 'app-qulayfying-probablity-reports',
  templateUrl: './qulayfying-probablity-reports.component.html',
  styleUrls: ['./qulayfying-probablity-reports.component.css'],
  providers: [QulayfyingProbablityReportsService]
})
export class QulayfyingProbablityReportsComponent implements OnInit {
  showLoader = false;
  Coes = [];
  coeId = "0"
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  UnfilterdReport = [];
  FilterdReport = [];
  column: string = 'CategoryName';
  direction: number;
  isDesc: boolean = false;
  examLimit = 5;
  constructor(private qulayfyingProbablityReportsService: QulayfyingProbablityReportsService, private router: Router) {
    if (localStorage.getItem("isLogin") == "true") {
      this.getMasterData()
    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }
  getMasterData() {
    this.showLoader = true;
    this.qulayfyingProbablityReportsService.getAllMasterData(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.Coes = data.coes;
          // this.Faculty = data.all_faculty
          this.coeId = this.Coes[0]['id_coe'];
          this.filterReport();
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false
      }
    );
  }

  getReports() {
    this.showLoader = true;
    this.qulayfyingProbablityReportsService.getScholarPerformanceGraphAdminDashBoard(localStorage.getItem("email"),this.examLimit).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.UnfilterdReport = data.selection_probability
          this.FilterdReport = data.selection_probability
          //this.getData();
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false
      }
    );
  }
  onBack() {
    this.router.navigateByUrl('/Reports')
  }

  filterReport() {
    // this.FilterdReport = [];
    // if (this.coeId != "0") {
    //   for (let data of this.UnfilterdReport) {
    //     if (data.id_coe == this.coeId) {
    //       this.FilterdReport.push(data);
    //     }
    //   }
    // }
    // else {
    //   this.FilterdReport = this.UnfilterdReport
    // }

    // 
    if(this.coeId=='0') {
       this.getReports();
    } else{ 
    this.showLoader = true;
    this.qulayfyingProbablityReportsService.getScholarPerformanceGraphAdminDashBoardByCoeId(localStorage.getItem("email"),this.examLimit,this.coeId).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.UnfilterdReport = data.selection_probability
          this.FilterdReport = data.selection_probability
          //this.getData();
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false
      }
    );
    }

  }
  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    //("status" + status);
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }

  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.FilterdReport.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  }

  getReportsByLimit()
  {
    if(this.coeId == '0')
    {
      this.getReports();
    }else{
      this.showLoader = true;
      this.qulayfyingProbablityReportsService.getScholarPerformanceGraphAdminDashBoardByCoeId(localStorage.getItem("email"),this.examLimit,this.coeId).subscribe(
        data => {
          this.showLoader = false
          if (data.status_code == "0") {
            this.UnfilterdReport = data.selection_probability
            this.FilterdReport = data.selection_probability
            //this.getData();
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false
        }
      );
  
    }
  }

}
