import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BookMasterService } from '../book-master/book-master.service'
import { CSVExport } from '../Csv-export';
import swal from 'sweetalert2'
@Component({
  selector: 'book-editions',
  templateUrl: './editions.component.html',
  styleUrls: ['./editions.component.css'],
  providers: [BookMasterService]
})
export class EditionsComponent implements OnInit {

  bookEditionObject: any = {}
  @Input() bookTitleId: any
  @Output() isbookEdition = new EventEmitter<boolean>();
  searchText: any;
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  isAddModal = false;
  showLoader = false;
  BookEditions = [];
  isAdd = 0;
  purpose = "Add"
  constructor(private bookMasterService: BookMasterService) {

  }
  ngOnInit() {
    //(this.bookTitleId)
    this.getAllBookEditionByBookTitle()


  }


  getAllBookEditionByBookTitle() {
    this.showLoader = true;
    this.BookEditions = []
    this.bookMasterService.getAllBookEditionByBookTitle(localStorage.getItem("email"), this.bookTitleId).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.BookEditions = data.book_editions
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }


  addBookTitle() {
    this.bookEditionObject = {}
    this.isAddModal = true
    this.isAdd = 0
  }

  editBookEdition(data) {
    this.purpose = "Edit"
    this.isAddModal = true
    this.isAdd = 1
    this.bookEditionObject = {}
    let tempData = Object.assign({}, data);
    this.bookEditionObject['name'] = tempData.book_edition_name
    this.bookEditionObject['price'] = tempData.price.trim()
    this.bookEditionObject['isbn_code'] = tempData.isbn_code
    this.bookEditionObject['id_book_edition'] = tempData.id_book_edition

  }

  save() {
    // this.showLoader = true;
    this.bookEditionObject['email'] = localStorage.getItem("email")
    this.bookEditionObject['id_book_title'] = this.bookTitleId
    if (this.isAdd == 0) {
      this.bookMasterService.addBookEdition(this.bookEditionObject).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.closeModal();
            this.getAllBookEditionByBookTitle()
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }
    else {
      this.bookMasterService.editBookEdition(this.bookEditionObject).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.closeModal();
            this.getAllBookEditionByBookTitle()
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }

  }
  closeModal() {
    this.isAddModal = false
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }

  hideAlert(val) {
    this.showAlert = val;
  }

  removeBookEdition(data) {
    swal.fire({
      title: '',
      text: "Deleting an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to remove this CoE?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        this.bookMasterService.removeBookEdition(localStorage.getItem("email"), data.id_book_edition).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.getAllBookEditionByBookTitle()
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);

            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
    })
  }

  backToBookTitle() {
    this.isbookEdition.emit(false)
  }

  export() {
    let bookTilte = ''
    var arrayToExport = [];
    if (this.BookEditions.length > 0) {
      for (let data of this.BookEditions) {
        bookTilte = data.book_title
        let json = {
          "Book Title": data.book_title,
          "Author": data.author,
          "Book Type": data.book_type_name,
          "Publisher": data.publisher,
          "Edition": data.book_edition_name,
          "ISBN Code": data.isbn_code,
          "Price": data.price,

        }

        arrayToExport.push(json);


      }
      CSVExport.downloadcsv(arrayToExport, bookTilte + " Book Edition");
    }
  }
}
