import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class ScholarFacultyFeedbackRequestService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }
    getAllFacultyFeedback(email,scholarId) {
        return this.http.get(this.baseUrl + "/Feedback/getAllFacultyFeedbackRequestBySectionId?caller_email="+email+"&id_scholar="+scholarId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllFeedbackQuestion(email) {
        return this.http.get(this.baseUrl + "/Feedback/getFeedbackquestion?caller_email="+email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    addFacultyFeedbackRequestByScholar(jsonData) {
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Feedback/addFacultyFeedbackRequestByScholar", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }
    
}