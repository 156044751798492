import { Component } from '@angular/core';
import {BookletSolutionService} from './booklet-solution.service';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import swal from "sweetalert2";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Helper } from '../helper';
@Component({
    selector: 'booklet-solution',
    templateUrl: 'booklet-solution.component.html',
    styleUrls: ['booklet-solution.component.scss'],
    providers:[BookletSolutionService]
})
export class BookletSolutionComponent {
    showLoader = false;
    showAlert = false;
    alertMsg: any;
    alertType: any;
    isModalShow = false;
    faculty = [];
    SubTopics = [];
    Topics = [];
    SubSubjects = [];
    Subjects = [];
    tableArray = [];
    UnFiltterdSubSubjects = [];
    UnfiltterdTopics = [];
    UnfilttersSubTopics = [];
    filter = {
        id_course_type: "0",
        id_subject: "0",
        id_sub_subject: "0",
        id_topic: "0",
        id_faculty: "0",
      };
    column: string = "CategoryName";
    direction: number;
    isDesc: boolean = false;  
    roleId: any;
    userId: any;
    unFiltterdTableArray=[];
    doubtType=0;
    searchText:any;
    form: FormGroup;
    isAddModal=false;
    preViewImages=[];
    isImagePreviewModal=false;
    initEditText:any;
    filterObject={
      id_subject:"0",
      id_course_type:"0",
      id_sub_subject:"0",
      id_topic:"0",
      id_faculty:"0",
      offset:1
    };
    p: number = 0;
    totalQuestions:any;
    coeArray=[];
    batchArray=[];
    unFilteredBatchArray=[];
    bookletSolutionData={'id_booklet_solution':''};
    constructor(private bookletSolutionService:BookletSolutionService,private domSanitizer: DomSanitizer){
        this.roleId = localStorage.getItem("id_role");
        this.userId = localStorage.getItem("id_user");
        if (this.roleId == 4) {
          this.filter.id_faculty = this.userId;
        }
        this.getMasterDataForLectureGroup();
        this.getBookletSolutionForAdmin(0);
        this.initializeTiny();
    }

    ngOnInit() {
      this.buildForm();
     
    }

    initializeTiny() {
      this.initEditText = {
        plugins: "image",
        readonly: 1,
        toolbar: "sizeselect | bold italic | fontselect |  fontsizeselect",
        images_upload_url: 'http://dakshana.beta.vendorZApp.in/service/upload_image.php',
        convert_urls: false,
        images_upload_handler: function (blobInfo, success, failure) {
          var xhr, formData;
          xhr = new XMLHttpRequest();
          xhr.withCredentials = false;
          xhr.open('POST', 'http://dakshana.beta.vendorZApp.in/service/upload_image.php');
          xhr.onload = function () {
            var json;
  
            if (xhr.status != 200) {
              failure('HTTP Error: ' + xhr.status);
              return;
            }
            json = JSON.parse(xhr.responseText);
  
            if (!json || typeof json.generatedName != 'string') {
              failure('Invalid JSON: ' + xhr.responseText);
              return;
            }
            success(json.generatedName);
          };
          formData = new FormData();
          formData.append('file', blobInfo.blob(), blobInfo.filename());
          xhr.send(formData);
        }
      }
  
  
    }
    buildForm() {
      this.form = new FormGroup({
        booklet_name:new FormControl("", [Validators.required]),
        exercise_name:new FormControl("", [Validators.required]),
        id_course_type:new FormControl("", [Validators.required]),
        question_number: new FormControl("", [Validators.required]),
        id_subject: new FormControl("", [Validators.required]),
        id_sub_subject: new FormControl("", [Validators.required]),
        id_topic: new FormControl("", [Validators.required]),
        question: new FormControl("", [Validators.required]),
        question_image: new FormControl(""),
        answer:new FormControl("",[Validators.required]),
        video_link: new FormControl(""),
        answer_image: new FormControl(""),
        doubt_relevance: new FormControl("High", [Validators.required])
      });
    }
    getMasterDataForLectureGroup() {
        this.showLoader = true;
        this.bookletSolutionService
          .getMasterDataForLectureGroup(localStorage.getItem("email"), this.roleId)
          .subscribe(
            (data) => {
              this.showLoader = false;
              if (data.status_code == 0) {
                this.Subjects = data.subjects;
                this.UnFiltterdSubSubjects = data.sub_subjects;
                this.UnfiltterdTopics = data.topics;
                this.UnfilttersSubTopics = data.sub_topics;
                this.faculty = data.faculty;
                this.unFilteredBatchArray=data.batches;
                this.batchArray=data.batches;
                this.coeArray=data.coes;
               
                this.showAlertComponent(
                  data["status_code"],
                  data["status_message"]
                );
              } else {
                this.showAlertComponent(
                  data["status_code"],
                  data["status_message"]
                );
              }
            },
            (err) => {
              this.showLoader = false;
              swal.fire("Something went wrong. Please try again later.");
            }
          );
    }

    getBookletSolutionForAdmin(doubtType,callFrom?:any) {
            if(callFrom=='html'){
                this.filterObject={
                  id_course_type: "0",
                  id_subject:"0",
                  id_sub_subject:"0",
                  id_topic:"0",
                  id_faculty:"0",
                  offset:1
                };
                this.filter = {
                  id_course_type: "0",
                  id_subject: "0",
                  id_sub_subject: "0",
                  id_topic: "0",
                  id_faculty: "0"
                };
                this.totalQuestions=0;
              }
                this.searchText="";
                this.doubtType=doubtType;
                this.tableArray=[];
                this.unFiltterdTableArray=[];
                this.showLoader = true;
                let json=this.filterObject;
                json['caller_email']=localStorage.getItem("email");
                json['doubt_type']=doubtType
                json['id_role']=this.roleId
                this.bookletSolutionService
                  .getBookletSolutionForAdmin(json)
                  .subscribe(
                    (data) => {
                      this.showLoader = false;
                      if (data.status_code == 0) {
                      //  if(this.roleId=='4'){
                      //   data.quiz_doubts=data.quiz_doubts.filter(x=>x.id_faculty==this.userId)
                      //  }
                      this.totalQuestions=data.count;
                      console.log(this.totalQuestions)
                       this.tableArray=data.booklet_solution;
                       this.unFiltterdTableArray=data.booklet_solution;
                        this.showAlertComponent(
                          data["status_code"],
                          data["status_message"]
                        );
                      } else {
                        this.showAlertComponent(
                          data["status_code"],
                          data["status_message"]
                        );
                      }
                    },
                    (err) => {
                      this.showLoader = false;
                      swal.fire("Something went wrong. Please try again later.");
                    }
                  );
    
    }

 

    showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
        this.alertType = "success";
    } else {
        this.alertType = "failure";
    }
    }
    hideAlert(val) {
    this.showAlert = val;
    }

    filterSuSuject(call?: any) {
       
        if(call!='form'){
            this.SubSubjects = [];
            this.filter.id_sub_subject = "0";
            this.filter.id_topic = "0";
            if(this.roleId!='4')
            this.filter.id_faculty = "0";
            for (let data of this.UnFiltterdSubSubjects) {
              if (this.filter.id_subject == data.id_subject) {
                this.SubSubjects.push(data);
              }
            }
            this.filteredBookletSolution();
        }
        else{
            this.SubSubjects = [];
           
            for (let data of this.UnFiltterdSubSubjects) {
              if (this.form.value.id_subject == data.id_subject) {
                this.SubSubjects.push(data);
              }
            }
        }
        
        // this.filterTopics();
      }
    
      filterTopics(call?: any) {
       
        if(call!='form'){
            this.Topics = [];
            this.filter.id_topic = "0";
            if(this.roleId!='4')
            this.filter.id_faculty = "0";
            for (let data of this.UnfiltterdTopics) {
              if (this.filter.id_sub_subject == data.id_sub_subject) {
                this.Topics.push(data);
              }
            }
            this.filteredBookletSolution();
        }
        else{
            this.Topics = [];
           
            for (let data of this.UnfiltterdTopics) {
              if (this.form.value.id_sub_subject == data.id_sub_subject) {
                this.Topics.push(data);
              }
            }
        }
      }

      filteredBookletSolution() {
        this.filterObject={
          id_course_type:this.filter.id_course_type,
          id_subject:this.filter.id_subject,
          id_sub_subject:this.filter.id_sub_subject,
          id_topic:this.filter.id_topic,
          id_faculty:this.filter.id_faculty,
          offset:1
        };
        this.getBookletSolutionForAdmin(this.doubtType);
        // this.tableArray = this.unFiltterdTableArray;
        // if (this.filter.id_subject != "0") {
        //   var temporaryArray = [];
        //   for (let data of this.tableArray) {
        //     if (data.id_subject == this.filter.id_subject) {
        //       temporaryArray.push(data);
        //     }
        //   }
        //   this.tableArray = temporaryArray;
        // }
        // if (this.filter.id_sub_subject != "0") {
        //   var temporaryArray = [];
        //   for (let data of this.tableArray) {
        //     if (data.id_sub_subject == this.filter.id_sub_subject) {
        //       temporaryArray.push(data);
        //     }
        //   }
        //   this.tableArray = temporaryArray;
        // }
        // if (this.filter.id_topic != "0") {
        //   var temporaryArray = [];
        //   for (let data of this.tableArray) {
        //     if (data.id_topic == this.filter.id_topic) {
        //       temporaryArray.push(data);
        //     }
        //   }
        //   this.tableArray = temporaryArray;
        // }
        // if (this.filter.id_faculty != "0") {
        //   var temporaryArray = [];
        //   for (let data of this.tableArray) {
        //     if (data.id_faculty == this.filter.id_faculty) {
        //       temporaryArray.push(data);
        //     }
        //   }
        //   this.tableArray = temporaryArray;
        // }
      }

      closeAddModal(){
        this.buildForm();
        this.isAddModal=false;
      }

      openAddModal(){
        this.form.reset();
        this.isAddModal=true;
      }
    editBookletSolution(data){
        this.bookletSolutionData=data;
        this.form.patchValue({
            booklet_name:data.booklet_name,
            exercise_name:data.exercise_name,
            question_number:data.question_number,
            question:data.question,
            question_image:data.question_image,
            answer:data.answer,
            answer_image:data.answer_image,
            video_link:data.video_link,
            doubt_relevance:data.doubt_relevance,
            id_subject:data.id_subject,
            id_course_type:data.id_course_type

          })
          this.filterSuSuject('form');
          this.form.patchValue({id_sub_subject:data.id_sub_subject});
         
          this.filterTopics('form');
          this.form.patchValue({ id_topic:data.id_topic});

          this.isAddModal=true;
       
      }

      getImageData(imageData){
        let imageUrl:SafeResourceUrl;
        imageUrl= this.domSanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpeg;base64,' + imageData);
        return imageUrl
      }


handleQuestionFileSelect(evt){
  var files = evt.target.files;
  var file = files[0];

    if (files && file) {
        var reader = new FileReader();

        reader.onload =this._handleQuestionFileReaderLoaded.bind(this);

        reader.readAsBinaryString(file);
    }
}



    _handleQuestionFileReaderLoaded(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.form.patchValue({
            question_image: btoa(binaryString)
        })
  
    }



    handleAnswerFileSelect(evt,forType){
        var files = evt.target.files;
        var file = files[0];
      
          if (files && file) {
              var reader = new FileReader();
      
              reader.onload =this._handleAnswerFileReaderLoaded.bind(this);
      
              reader.readAsBinaryString(file);
          }
      }
      
      
      
          _handleAnswerFileReaderLoaded(readerEvt) {
              var binaryString = readerEvt.target.result;
              this.form.patchValue({
                  answer_image: btoa(binaryString)
              })
        
          }

  submit(){
    let json=this.form.getRawValue();
    json['caller_email']=localStorage.getItem("email");
    this.showLoader = true;
    if(this.bookletSolutionData.id_booklet_solution==''){
        this.bookletSolutionService
        .addBookletSolution(json)
        .subscribe(
          (data) => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.filterObject={
                id_subject:"0",
                id_course_type:"0",
                id_sub_subject:"0",
                id_topic:"0",
                id_faculty:"0",
                offset:1
              };
                this.closeAddModal();
                this.getBookletSolutionForAdmin(this.doubtType);
            } else {
              this.showAlertComponent(
                data["status_code"],
                data["status_message"]
              );
            }
          },
          (err) => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.");
          }
        );
    }
    else{
        json['id_booklet_solution']=this.bookletSolutionData.id_booklet_solution;
        this.bookletSolutionService
        .editBookletSolution(json)
        .subscribe(
          (data) => {
            this.showLoader = false;
            if (data.status_code == 0) {
                this.closeAddModal();
                this.getBookletSolutionForAdmin(this.doubtType);
            } else {
              this.showAlertComponent(
                data["status_code"],
                data["status_message"]
              );
            }
          },
          (err) => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.");
          }
        );
    }
  
  }

  checkAlreadyDoubt(){
    if(this.form.getRawValue().doubt_related_to==null || this.form.getRawValue().doubt_related_to=='null'){
      return false
    }
    else{
      return true
    }
  }


  openImagePreviewModal(imageData){
    console.log(imageData)
    this.preViewImages=[];
    // let imageUrl:SafeResourceUrl;
    // imageUrl= this.domSanitizer.bypassSecurityTrustResourceUrl(
    //   'data:image/jpeg;base64,' + imageData);
      this.isImagePreviewModal=true;
    return this.preViewImages.push(imageData)
    
  }
  closeImagePreviewModal(){
    this.isImagePreviewModal=false;
  }

  pageChanged(value){
    this.filterObject.offset=value;
    this.getBookletSolutionForAdmin(this.doubtType);
  }

  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.tableArray.sort(function (a, b) {
        if (a[property] < b[property]) {
            return -1 * direction;
        }
        else if (a[property] > b[property]) {
            return 1 * direction;
        }
        else {
            return 0;
        }
    });
}

removeBookletSolution(data){
    swal
    .fire({
      title: "",
      text:
        "Deleting an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to remove this booklet solution?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#8B0000",
      cancelButtonColor: "#428e63",
      confirmButtonText: "Yes, remove it!",
    })
    .then((result) => {
      if (result.value) {
        this.showLoader = true;
        let json={'caller_email':localStorage.getItem("email"),id_booklet_solution:data.id_booklet_solution}
        this.bookletSolutionService
          .removeBookletSolution(
           json
          )
          .subscribe(
            (data) => {
              this.showLoader = false;
              if (data.status_code == 0) {
                this.getBookletSolutionForAdmin(this.doubtType);
                this.showAlertComponent(
                  data["status_code"],
                  data["status_message"]
                );
              } else {
                this.showAlertComponent(
                  data["status_code"],
                  data["status_message"]
                );
              }
            },
            (err) => {
              this.showLoader = false;

              swal.fire("Something went wrong. Please try again later.");
            }
          );
      }
    });
}
}
