import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import swal from 'sweetalert2';
import { ComprehensionQuestionService } from './comprehension-question.service'
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
  selector: 'comprehension-question',
  templateUrl: './comprehension-question.component.html',
  styleUrls: ['./comprehension-question.component.css'],
  providers: [ComprehensionQuestionService]
})
export class ComprehensionQuestionComponent implements OnInit {
  comprehensionText: any;
  solutionText: any;
  @Output() isHidden = new EventEmitter<string>();
  @Input() Subjects = [];
  @Input() subjectId: any;
  SubSubjects = [];
  @Input() UnFiltterdSubSubjects = [];
  @Input() subSubjectId: any;
  Topics = [];
  @Input() topicId: any;
  @Input() UnfiltterdTopics = [];
  SubTopics = [];
  @Input() UnfilttersSubTopics = [];
  @Input() subTopicId: any;
  showLoader = false;
  @Input() QuestionSources = [];
  @Input() questionSourceId: any;
  @Input() DifficultyLevels: any;
  @Input() difficultyLevelId: any;
  @Input() QuestionTypes = [];
  CpmprehensiveQuestionTypes = [];
  @Input() questionTypeId: any;
  @Input() ReviewRequirements = [];
  @Input() reviewRequirementId: any;
  base64textQuestionString = "";
  questionImage: any;
  sollutionText: any;
  questionText: any;
  QuestionsSingleOptions = [];
  QuestionsMultipleOptions = [];
  isAddQueestionOptionModal = false;
  isAddMatrixMatch = false
  isCorrect = false;
  optionName: any;
  optionText: any;
  questionOptionImage: any;
  base64textQuestionOtionString = "";
  purposeOfQuestionOptionModal = "Add"
  questionOptionIndex: any;
  questionMatrixIndex: any;
  @Input() previousData: any;
  questionId: any
  showAlert = false;
  alertMsg: any;
  alertType: any;
  addQuestionModal = false;
  selectComprehensionQuestionTypeId: any;
  Questiones = [];
  questionAnswer: any;
  columnFor: any;
  columnName: any;
  columnValue: any;
  ColumnOne = [];
  columnSecond = [];
  MatrixMatchingAnswerTable = [];
  CheckedArray = [];
  assertion: any;
  reason: any;
  QuestionsOptionsForReasoning = [];
  comprehensionQuestionIndex: any;
  comprehensionQuestionPurpose = "Add";
  comprehensionId: any;
  comrhensionPurpose = "Add"
  @Input() QuestionInterpretation = [];
  questionInterpretationId: any;
  @Input() TimeOfQuestion = [];
  timeOfQuestionId: any;
  @Input() tabId: any;
  roleId: any;
  isAddRejectionCommentModal = false;
  rejectComment: any;
  questionStatusId: any;
  imageType: any;
  base64textSolutionString = "";
  solutionImage: any;
  initEditText: any;
  SingleOptiones = [];
  MultipleOptiones = [];
  questionCode: any;
  comprehensionQuestionStatusId = 12;
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'id_question_source',
    textField: 'name',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    closeDropDownOnSelection: true
  };
  dropdownSettings1: IDropdownSettings = {
    singleSelection: true,
    idField: 'id_question_source',
    textField: 'name',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    closeDropDownOnSelection: true
  };
  selectedSource = [];
  idCourseType = 14;
  orignalQuestionSources = [];
  selectedPatternItems = [];
  firstTime = true;

  constructor(private domSanitizer: DomSanitizer, private comprehensionQuestionService: ComprehensionQuestionService) { 
   
  }


  ngOnInit() {

    console.log("questionSourceID="+this.questionSourceId);

    this.initializeTiny();
    this.generateColumnOne();
    this.generateColumnSecond();
    this.roleId = localStorage.getItem("id_role");
    this.timeOfQuestionId = "0";
    this.questionInterpretationId = "0";
    this.questionStatusId = "12"

    this.orignalQuestionSources = this.QuestionSources;

    for (let data of this.QuestionTypes) {
      this.CpmprehensiveQuestionTypes.push(data);
    }

    for (var i = 0; i < this.CpmprehensiveQuestionTypes.length; i++) {
      if (this.CpmprehensiveQuestionTypes[i].id_question_pattern == "3") {
        this.CpmprehensiveQuestionTypes.splice(i, 1);
        break;
      }
    }
    this.selectComprehensionQuestionTypeId = "1"
    this.generateOptions();
    this.generateSIngleMultipleOption();
  
    if (this.previousData) {
      this.comrhensionPurpose = "Edit";
      this.comprehensionText = this.previousData["comprehension_text"]
      this.comprehensionId = this.previousData["id_comprehension"]
      this.subjectId = this.previousData["id_subject"]
      this.filterSuSuject();
      this.subSubjectId = this.previousData["id_sub_subject"]
      //(this.previousData["id_sub_subject"])
      this.filterTopics();
      this.topicId = this.previousData["id_topic"]
      this.Questiones = this.previousData["questions"];
      this.questionCode = this.previousData["question_code"];
      this.comprehensionQuestionStatusId = this.Questiones[0]["is_approved"]
    }
    else {
      
      console.log("Question sources length="+this.QuestionSources.length);

      this.QuestionSources.forEach(item => {
        if (item.id_question_source == this.questionSourceId) {
          this.selectedSource.push({ id_question_source: this.questionSourceId, name: item.name });
          console.log("Match found");
        }
      });


      this.filterSuSuject();
      this.filterTopics();
      this.filterSubTopics();

    

    }
    //(this.previousData["id_sub_subject"])
  }
  filterSuSuject() {
    this.SubSubjects = [];
    for (let data of this.UnFiltterdSubSubjects) {
      if (this.subjectId == data.id_subject) {
        this.SubSubjects.push(data)
      }
    }
    this.subSubjectId = "0"
    this.topicId = "0"
    this.subTopicId = "0"
  }

  filterTopics() {
    // this.Questiones = [];
    this.Topics = [];
    for (let data of this.UnfiltterdTopics) {
      if (this.subSubjectId == data.id_sub_subject) {
        this.Topics.push(data)
      }
    }

    this.topicId = "0"
    this.subTopicId = "0"
  }
  filterSubTopics() {
    this.SubTopics = [];
    for (let data of this.UnfilttersSubTopics) {
      if (this.topicId == data.id_topic) {
        this.SubTopics.push(data)
      }
    }

    this.subTopicId = "0"
  }
  handleFileSelect(evt, image) {
    var files = evt.target.files;
    var file = files[0];
    this.imageType = image;
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {

    var binaryString = readerEvt.target.result;
    if (this.imageType == "question") {
      this.base64textQuestionString = btoa(binaryString);
      var image = 'data:image/png;base64,' + this.base64textQuestionString;
      this.questionImage = this.domSanitizer.bypassSecurityTrustUrl(image);
    }
    else {
      this.base64textSolutionString = btoa(binaryString);
      var image = 'data:image/png;base64,' + this.base64textSolutionString;
      this.solutionImage = this.domSanitizer.bypassSecurityTrustUrl(image);
    }

  }

  getImage(imagString) {
    var image: any;
    if ((!imagString) || imagString == "") {
      image = "../../assets/logo.png";
      return image
    }
    else {
      image = 'data:image/png;base64,' + imagString;
      image = this.domSanitizer.bypassSecurityTrustUrl(image);
      return image
    }
  }

  addQuestion() {
    if (this.topicId == "0") {
      swal.fire("Please select  first topic.")
    }
    else {
      this.comprehensionQuestionPurpose = "Add"
      this.addQuestionModal = true;

      this.subTopicId = "0";
      this.filterSubTopics();
      this.difficultyLevelId = "0";
      this.reviewRequirementId = "0";
      // this.questionSourceId = "0";
      this.questionInterpretationId = "0";
      this.timeOfQuestionId = "0"
      this.questionText = "";
      this.solutionText = "";
      this.selectComprehensionQuestionTypeId = "0";
      this.optionName = "";
      this.optionText = "";
      this.questionAnswer = "";
      this.SingleOptiones = [];
      this.MultipleOptiones = [];
      this.generateSIngleMultipleOption();
    }
  }

  addNewQuestionOptions() {
    this.isAddQueestionOptionModal = true;
    this.purposeOfQuestionOptionModal = "Add";
    this.clearData();
  }
  clearData() {
    this.optionName = "";
    this.optionText = "";
    this.isCorrect = false;
    this.questionOptionImage = null;
    this.base64textQuestionOtionString = "";
  }

  save() {
    if (!this.optionName) {
      swal.fire("Please enter option name.")
    }
    else if (!this.optionText) {
      swal.fire("Please enter option text.")
    }
    else {
      var correct = "1";
      if (this.isCorrect) {
        if (this.selectComprehensionQuestionTypeId == "1") {
          for (let data of this.QuestionsSingleOptions) {
            data.is_option_correct = "1";
          }
        }

        correct = "0"
      }
      let json = {
        "option_name": this.optionName,
        "option_text": this.optionText,
        "option_image": this.base64textQuestionOtionString,
        "is_option_correct": correct
      }
      if (this.purposeOfQuestionOptionModal == "Add") {
        if (this.selectComprehensionQuestionTypeId == "1") {
          this.QuestionsSingleOptions.push(json);
        }
        else {
          this.QuestionsMultipleOptions.push(json);
        }

      }
      else {
        if (this.selectComprehensionQuestionTypeId == "1") {
          this.QuestionsSingleOptions[this.questionOptionIndex] = json
        }
        else {
          this.QuestionsMultipleOptions[this.questionOptionIndex] = json
        }

      }

      this.hideAddEditOptionModal();
    }
  }

  changeQuestionType() {
    if (this.selectComprehensionQuestionTypeId == "1") {
      if (this.QuestionsMultipleOptions.length > 0) {
        this.QuestionsSingleOptions = this.QuestionsMultipleOptions;
      }
    }
    if (this.selectComprehensionQuestionTypeId == "2") {
      if (this.QuestionsSingleOptions.length > 0) {
        this.QuestionsMultipleOptions = this.QuestionsSingleOptions;
      }
    }

    this.generateColumnOne();
    this.generateColumnSecond();
    this.generateMatrixMatchingAnswer();
    this.CheckedArray = [];
  }

  hideAddEditOptionModal() {
    this.isAddQueestionOptionModal = false;
  }
  saveQuestion() {

    var invalidSolutionTextSize = false;
    if (this.sollutionText) {
      if (+this.getByteSize(this.sollutionText) > 64) {
        invalidSolutionTextSize = true
      }
    }

    if (this.subTopicId == "0") {
      swal.fire("Please select sub-topic.");
    }
    else if (this.questionSourceId == "0") {
      swal.fire("Please select question source.");
    }
    else if (!this.solutionText) {
      swal.fire("Please enter solution text.")
    }
    else if (invalidSolutionTextSize) {
      swal.fire("Allowed size exceeded. Please reduce text/image size for fields with validation errors.")
    }
    else {
      if (this.comprehensionQuestionPurpose == "Add") {
        if (this.selectComprehensionQuestionTypeId == "1" || this.selectComprehensionQuestionTypeId == "2" || this.selectComprehensionQuestionTypeId == "6" || this.selectComprehensionQuestionTypeId == "7") {
          var QuestionOptiones = [];
          if (this.selectComprehensionQuestionTypeId == "1") {
            QuestionOptiones = this.SingleOptiones
          } else if (this.selectComprehensionQuestionTypeId == "7") {
            QuestionOptiones = this.SingleOptiones
          }
          else if (this.selectComprehensionQuestionTypeId == "2") {
            QuestionOptiones = this.MultipleOptiones
          }
          else {
            QuestionOptiones = this.QuestionsOptionsForReasoning
          }

          var checkAllOptionISIncorrect = false;
          for (let data of QuestionOptiones) {
            if (data.is_option_correct == "0") {
              checkAllOptionISIncorrect = true
            }
          }

          var invalidOptionSize = false;
          for (let data of QuestionOptiones) {
            if (+this.getByteSize(data.option_text) > 64) {
              invalidOptionSize = true
              break
            }
          }

          if (checkAllOptionISIncorrect == false) {
            swal.fire("Atleast one option is correct.")
          }
          else if (invalidOptionSize) {
            swal.fire("Allowed size exceeded. Please reduce text/image size for fields with validation errors.")
          }
          else {
            if (this.selectComprehensionQuestionTypeId != "6") {
              let json = {

                "id_subject": this.subjectId,
                "id_sub_subject": this.subSubjectId,
                "id_topic": this.topicId,
                "id_sub_topic": this.subTopicId,
                "id_question_source": this.questionSourceId,
                "id_difficult_level": this.difficultyLevelId,
                "id_question_pattern": this.selectComprehensionQuestionTypeId,
                "id_review_requirement": this.reviewRequirementId,
                "id_time_for_question": this.timeOfQuestionId,
                "id_question_interpretation": this.questionInterpretationId,
                "question_text": this.questionText,
                "question_optiones": QuestionOptiones,
                "answer": "",
                "sollution_text": this.solutionText,
                "is_approved": "12",
              }
              this.Questiones.push(json)
            }
            else {
              let json = {

                "id_subject": this.subjectId,
                "id_sub_subject": this.subSubjectId,
                "id_topic": this.topicId,
                "id_sub_topic": this.subTopicId,
                "id_question_source": this.questionSourceId,
                "id_difficult_level": this.difficultyLevelId,
                "id_question_pattern": this.selectComprehensionQuestionTypeId,
                "id_review_requirement": this.reviewRequirementId,
                "id_time_for_question": this.timeOfQuestionId,
                "id_question_interpretation": this.questionInterpretationId,
                "question_text": this.assertion + "$$" + this.reason,
                "question_optiones": QuestionOptiones,
                "answer": "",
                "sollution_text": this.solutionText,
                "is_approved": "12",
              }
              this.Questiones.push(json)
            }
            this.hideQuestionModal();
          }

        }
        else if (this.selectComprehensionQuestionTypeId == "4") {
          let json = {
            "caller_email": localStorage.getItem("email"),
            "id_subject": this.subjectId,
            "id_sub_subject": this.subSubjectId,
            "id_topic": this.topicId,
            "id_sub_topic": this.subTopicId,
            "id_question_source": this.questionSourceId,
            "id_difficult_level": this.difficultyLevelId,
            "id_question_pattern": this.selectComprehensionQuestionTypeId,
            "id_review_requirement": this.reviewRequirementId,
            "id_time_for_question": this.timeOfQuestionId,
            "id_question_interpretation": this.questionInterpretationId,
            "question_text": this.questionText,
            "question_optiones": "",
            "answer": this.questionAnswer,
            "sollution_text": this.solutionText,
            "is_approved": "12",
          }
          this.Questiones.push(json)
          this.hideQuestionModal();
        }
        else if (this.selectComprehensionQuestionTypeId == "8") {
          let json = {
            "caller_email": localStorage.getItem("email"),
            "id_subject": this.subjectId,
            "id_sub_subject": this.subSubjectId,
            "id_topic": this.topicId,
            "id_sub_topic": this.subTopicId,
            "id_question_source": this.questionSourceId,
            "id_difficult_level": this.difficultyLevelId,
            "id_question_pattern": this.selectComprehensionQuestionTypeId,
            "id_review_requirement": this.reviewRequirementId,
            "id_time_for_question": this.timeOfQuestionId,
            "id_question_interpretation": this.questionInterpretationId,
            "question_text": this.questionText,
            "question_optiones": "",
            "answer": this.questionAnswer,
            "sollution_text": this.solutionText,
            "is_approved": "12",
          }
          this.Questiones.push(json)
          this.hideQuestionModal();
        }
        else if (this.selectComprehensionQuestionTypeId == "5") {
          let json = {
            "caller_email": localStorage.getItem("email"),
            "id_subject": this.subjectId,
            "id_sub_subject": this.subSubjectId,
            "id_topic": this.topicId,
            "id_sub_topic": this.subTopicId,
            "id_question_source": this.questionSourceId,
            "id_difficult_level": this.difficultyLevelId,
            "id_question_pattern": this.selectComprehensionQuestionTypeId,
            "id_review_requirement": this.reviewRequirementId,
            "id_time_for_question": this.timeOfQuestionId,
            "id_question_interpretation": this.questionInterpretationId,
            "question_text": this.questionText,
            "column_one": this.ColumnOne,
            "column_second": this.columnSecond,
            "sollution_text": this.solutionText,
            "matrix_match": this.CheckedArray,
            "is_approved": "12",
          }
          this.Questiones.push(json)
          this.hideQuestionModal();
        }


      }
      else {
        if (this.selectComprehensionQuestionTypeId != "4" && this.selectComprehensionQuestionTypeId != "8" && this.selectComprehensionQuestionTypeId != "6" && this.selectComprehensionQuestionTypeId != "5") {
          var QuestionOptiones = [];
          if (this.selectComprehensionQuestionTypeId == "1") {
            QuestionOptiones = this.SingleOptiones
          } else if (this.selectComprehensionQuestionTypeId == "7") {
            QuestionOptiones = this.SingleOptiones
          }
          else {
            QuestionOptiones = this.MultipleOptiones
          }
          var checkAllOptionISIncorrect = false;
          for (let data of QuestionOptiones) {
            if (data.is_option_correct == "0") {
              checkAllOptionISIncorrect = true
            }
          }
          if (checkAllOptionISIncorrect == false) {
            swal.fire("Atleast one option is correct.")
          }
          else {
            let json = {
              "id_question": this.questionId,
              "id_subject": this.subjectId,
              "id_sub_subject": this.subSubjectId,
              "id_topic": this.topicId,
              "id_sub_topic": this.subTopicId,
              "id_question_source": this.questionSourceId,
              "id_difficult_level": this.difficultyLevelId,
              "id_question_pattern": this.selectComprehensionQuestionTypeId,
              "id_review_requirement": this.reviewRequirementId,
              "question_text": this.questionText,
              "question_optiones": QuestionOptiones,
              "answer": this.questionAnswer,
              "id_time_for_question": this.timeOfQuestionId,
              "id_question_interpretation": this.questionInterpretationId,
              "sollution_text": this.solutionText,
              "is_approved": this.questionStatusId,
            }
            this.Questiones[this.comprehensionQuestionIndex] = json
            this.hideQuestionModal();
          }

        }
        else if (this.selectComprehensionQuestionTypeId == "6") {
          let json = {
            "id_question": this.questionId,
            "caller_email": localStorage.getItem("email"),
            "id_subject": this.subjectId,
            "id_sub_subject": this.subSubjectId,
            "id_topic": this.topicId,
            "id_sub_topic": this.subTopicId,
            "id_question_source": this.questionSourceId,
            "id_difficult_level": this.difficultyLevelId,
            "id_question_pattern": this.selectComprehensionQuestionTypeId,
            "id_review_requirement": this.reviewRequirementId,
            "question_text": this.assertion + "$$" + this.reason,
            "id_time_for_question": this.timeOfQuestionId,
            "id_question_interpretation": this.questionInterpretationId,
            "question_optiones": this.QuestionsOptionsForReasoning,
            "sollution_text": this.solutionText,
            "is_approved": this.questionStatusId,
          }
          this.Questiones[this.comprehensionQuestionIndex] = json
          this.hideQuestionModal();
        }
        else if (this.selectComprehensionQuestionTypeId == "5") {
          let json = {
            "id_question": this.questionId,
            "caller_email": localStorage.getItem("email"),
            "id_subject": this.subjectId,
            "id_sub_subject": this.subSubjectId,
            "id_topic": this.topicId,
            "id_sub_topic": this.subTopicId,
            "id_question_source": this.questionSourceId,
            "id_difficult_level": this.difficultyLevelId,
            "id_question_pattern": this.selectComprehensionQuestionTypeId,
            "id_review_requirement": this.reviewRequirementId,
            "question_text": this.questionText,
            "column_one": this.ColumnOne,
            "column_second": this.columnSecond,
            "id_time_for_question": this.timeOfQuestionId,
            "id_question_interpretation": this.questionInterpretationId,
            "sollution_text": this.solutionText,
            "matrix_match": this.CheckedArray,
            "is_approved": this.questionStatusId,
          }
          this.Questiones[this.comprehensionQuestionIndex] = json
          this.hideQuestionModal();
        }
        else if (this.selectComprehensionQuestionTypeId == "4") {
          let json = {
            "id_question": this.questionId,
            "caller_email": localStorage.getItem("email"),
            "id_subject": this.subjectId,
            "id_sub_subject": this.subSubjectId,
            "id_topic": this.topicId,
            "id_sub_topic": this.subTopicId,
            "id_question_source": this.questionSourceId,
            "id_difficult_level": this.difficultyLevelId,
            "id_question_pattern": this.selectComprehensionQuestionTypeId,
            "id_review_requirement": this.reviewRequirementId,
            "id_time_for_question": this.timeOfQuestionId,
            "id_question_interpretation": this.questionInterpretationId,
            "question_text": this.questionText,
            "column_one": this.ColumnOne,
            "column_second": this.columnSecond,
            "sollution_text": this.solutionText,
            "answer": this.questionAnswer,
            "matrix_match": this.CheckedArray,
            "is_approved": this.questionStatusId,
          }
          this.Questiones[this.comprehensionQuestionIndex] = json
          this.hideQuestionModal();

        }
        else if (this.selectComprehensionQuestionTypeId == "8") {
          let json = {
            "id_question": this.questionId,
            "caller_email": localStorage.getItem("email"),
            "id_subject": this.subjectId,
            "id_sub_subject": this.subSubjectId,
            "id_topic": this.topicId,
            "id_sub_topic": this.subTopicId,
            "id_question_source": this.questionSourceId,
            "id_difficult_level": this.difficultyLevelId,
            "id_question_pattern": this.selectComprehensionQuestionTypeId,
            "id_review_requirement": this.reviewRequirementId,
            "id_time_for_question": this.timeOfQuestionId,
            "id_question_interpretation": this.questionInterpretationId,
            "question_text": this.questionText,
            "column_one": this.ColumnOne,
            "column_second": this.columnSecond,
            "sollution_text": this.solutionText,
            "answer": this.questionAnswer,
            "matrix_match": this.CheckedArray,
            "is_approved": this.questionStatusId,
          }
          this.Questiones[this.comprehensionQuestionIndex] = json
          this.hideQuestionModal();

        }
      }

    }

  }
  editQuestionOption(data, index) {
    this.optionName = data.option_name;
    this.optionText = data.option_text;
    this.base64textQuestionOtionString = data.option_image;
    this.questionOptionImage = this.getImage(this.base64textQuestionOtionString);
    if (data.is_option_correct == "0") {
      this.isCorrect = true
    }
    else {
      this.isCorrect = false
    }
    this.purposeOfQuestionOptionModal = "Edit";
    this.isAddQueestionOptionModal = true;
    this.questionOptionIndex = index;
  }

  hideQuestionModal(forData?: string) {
    this.addQuestionModal = false
  }

  addNewMatrixMatch(value) {
    this.columnFor = value
    this.isAddMatrixMatch = true;
    this.purposeOfQuestionOptionModal = "Add";
    this.columnName = "";
    this.columnValue = "";
  }
  hideAddEditMatrixMatchModal() {
    this.isAddMatrixMatch = false;
  }

  saveMatrixMatch() {
    if (!this.columnName) {
      swal.fire("Please enter column name.")
    }
    else if (!this.columnValue) {
      swal.fire("Please enter value.")
    }
    else {

      let json = {
        "column_name": this.columnName,
        "column_value": this.columnValue,
        "is_checked": "0",
      }
      if (this.purposeOfQuestionOptionModal == "Add") {
        if (this.columnFor == "1") {
          this.ColumnOne.push(json);
        }
        else {
          this.columnSecond.push(json);
        }


      }
      else {
        if (this.columnFor == "1") {
          this.ColumnOne[this.questionMatrixIndex] = json
        }
        else {
          this.columnSecond[this.questionMatrixIndex] = json
        }

      }
      this.generateMatrixMatchingAnswer();
      this.hideAddEditMatrixMatchModal();
    }

  }
  generateMatrixMatchingAnswer() {
    this.MatrixMatchingAnswerTable = [];
    if (this.ColumnOne.length > 0 && this.columnSecond.length > 0) {
      for (let data of this.ColumnOne) {
        let json = {
          "option": data.column_name,
          "answers": this.columnSecond
        }
        this.MatrixMatchingAnswerTable.push(json)
      }
    }


  }


  checkUncheck(optionName, answerName, answer) {

    for (let data of this.MatrixMatchingAnswerTable) {
      for (let answer of data.answers) {
        if (data.option == optionName && answer.column_name == answerName) {
          if ((<HTMLInputElement>document.getElementById(optionName + answerName)).checked === true) {
            let json = {
              "option": optionName,
              "answers": answerName,
            }
            this.CheckedArray.push(json);
            break;
          }
          else {
            for (var i = 0; i < this.CheckedArray.length; i++) {
              if (this.CheckedArray[i].option == optionName && this.CheckedArray[i].answers == answerName) {
                this.CheckedArray.splice(i, 1)
                break;
              }
            }

          }
        }
      }
    }
  }

  checkIsCheckedOrNot(optionName, answerName) {
    let status = false;
    for (let data of this.CheckedArray) {
      if (data.option == optionName && data.answers == answerName) {
        status = true;
        break;
      }
    }

    return status;
  }
  editMatrixMatch(data, index, value) {
    this.columnFor = value;
    this.columnName = data.column_name;
    this.columnValue = data.column_value;
    this.base64textQuestionOtionString = data.option_image;
    this.questionOptionImage = this.getImage(this.base64textQuestionOtionString);
    this.base64textQuestionString = this.previousData.question_image
    if (data.is_option_correct == "0") {
      this.isCorrect = true
    }
    else {
      this.isCorrect = false
    }
    this.purposeOfQuestionOptionModal = "Edit";
    this.isAddMatrixMatch = true;
    this.questionMatrixIndex = index;
  }

  removeMatrixMatch(data, index, value) {
    swal.fire({
      title: '',
      text: "Are you sure you want to remove this option?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        if (value == '1') {
          this.ColumnOne.splice(this.questionMatrixIndex, 1);
        }
        else {
          this.columnSecond.splice(this.questionMatrixIndex, 1);
        }

      }
    })

    this.generateMatrixMatchingAnswer();
  }

  generateOptions() {
    let jsonOne = {
      "option_name": "Option 1",
      "option_text": " Both Assertion and Reason are true and Reason is the correct explanation of Assertion",
      "option_image": "",
      "is_option_correct": "1"
    }
    this.QuestionsOptionsForReasoning.push(jsonOne)

    let jsonTwo = {
      "option_name": "Option 2",
      "option_text": "Both Assertion and Reason are true but Reason is not correct explanation of Assertion.",
      "option_image": "",
      "is_option_correct": "1"
    }
    this.QuestionsOptionsForReasoning.push(jsonTwo)

    let jsonThree = {
      "option_name": "Option 3",
      "option_text": "Assertion is true but Reason is false.",
      "option_image": "",
      "is_option_correct": "1"
    }
    this.QuestionsOptionsForReasoning.push(jsonThree)
    let jsonFour = {
      "option_name": "Option 4",
      "option_text": "Assertion and Reason are false.",
      "option_image": "",
      "is_option_correct": "1"
    }
    this.QuestionsOptionsForReasoning.push(jsonFour)
  }

  editComprehensionQuestion(data, index) {

    this.SingleOptiones = [];
    this.MultipleOptiones = [];
    this.questionStatusId = data.is_approved
    this.comprehensionQuestionPurpose = "Edit"

    this.comprehensionQuestionIndex = index;
    this.subjectId = data.id_subject;
    // this.filterSuSuject();
    this.subSubjectId = data.id_sub_subject;
    // this.filterTopics();
    if (this.topicId == data.id_topic) {
      this.topicId = data.id_topic;
      this.filterSubTopics();
      this.subTopicId = data.id_sub_topic;
    }
    else {
      this.filterSubTopics();
      this.subTopicId = "0";
    }


    if (data.id_difficult_level == null) {
      this.difficultyLevelId = "0";
    }
    else {
      this.difficultyLevelId = data.id_difficult_level
    }
    this.selectComprehensionQuestionTypeId = data.id_question_pattern;
    if (this.selectComprehensionQuestionTypeId == "6") {
      this.assertion = data.question_text.split("$$")[0]
      this.reason = data.question_text.split("$$")[1]
      this.QuestionsOptionsForReasoning = data.question_optiones;
    }
    else {
      this.questionText = data.question_text;
      this.SingleOptiones = data.question_optiones;
    }
    this.questionSourceId = data.id_question_source;
    if (data.id_review_requirement == null) {
      this.reviewRequirementId = "0";
    }
    else {
      this.reviewRequirementId = data.id_review_requirement;
    }
    this.solutionText = data.sollution_text;

    this.MultipleOptiones = data.question_optiones;
    this.questionAnswer = data.answer;
    if (data.column_one) {
      this.ColumnOne = data.column_one;
    }
    if (data.column_one) {
      this.columnSecond = data.column_second;
    }


    this.generateMatrixMatchingAnswer();
    this.CheckedArray = data.matrix_match;
    this.questionId = data.id_question
    this.addQuestionModal = true;
    if (data.id_question_interpretation == null) {
      this.questionInterpretationId = "0"
    }
    else {
      this.questionInterpretationId = data.id_question_interpretation
    }
    if (data.id_time_for_question == null) {
      this.timeOfQuestionId = "0"
    }
    else {
      this.timeOfQuestionId = data.id_time_for_question
    }
  }

  getByteSize(str) {
    var size = (new Blob([str]).size * .001).toFixed(2);
    return Number(size);
  }

  saveComprehensionQuestionQuestion() {

    var invalidComprehensionQuesionTextSize = false;
    if (+this.getByteSize(this.comprehensionText) > 64) {
      invalidComprehensionQuesionTextSize = true
    }

    if (!this.comprehensionText) {
      swal.fire("Please enter comprehension text.")
    }
    else if (this.Questiones.length == 0) {
      swal.fire("Please at least one question.")
    }
    else if (invalidComprehensionQuesionTextSize) {
      swal.fire("Allowed size exceeded. Please reduce text/image size for fields with validation errors.")
    }
    else {
      if (!this.comprehensionId) {
        let json = {
          "caller_email": localStorage.getItem("email"),
          "comprehension_text": this.comprehensionText,
          "question_code": this.questionCode,
          "questiones": JSON.stringify(this.Questiones)
        }
        this.showLoader = true;
        this.comprehensionQuestionService.addQuestion(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.showAlertComponent(data['status_code'], data['status_message']);
              this.hideModal();
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
      else {
        let json = {
          "caller_email": localStorage.getItem("email"),
          "id_comprehension": this.comprehensionId,
          "comprehension_text": this.comprehensionText,
          "question_code": this.questionCode,
          "purpose": "edit",
          "questiones": JSON.stringify(this.Questiones)
        }
        this.comprehensionQuestionService.editQuestion(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.showAlertComponent(data['status_code'], data['status_message']);
              this.hideQuestionModal('edit');
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }

    }
  }

  hideModal(forData?: string) {
    this.isHidden.emit(forData);
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }

  hideRejectionCommentModal() {
    this.isAddRejectionCommentModal = false
  }

  reject() {
    this.isAddRejectionCommentModal = true

  }

  saveComment() {
    if (!this.rejectComment) {
      swal.fire("Please enter comment")
    }
    else {
      let json = {
        "caller_email": localStorage.getItem("email"),
        "id_comprehension": this.comprehensionId,
        "comment": this.rejectComment
      }
      this.showLoader = true;
      this.comprehensionQuestionService.rejectComment(json).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.showAlertComponent(data['status_code'], data['status_message']);
            this.hideQuestionModal();
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }
  }

  approved() {
    var tempCheck = false

    for (let question of this.Questiones) {
      if (question.id_difficult_level == null || question.id_difficult_level == "0" || question.id_review_requirement == null || question.id_review_requirement == "0" || question.id_question_source == null || question.id_question_source == "0" || question.id_question_interpretation == null || question.id_question_interpretation == "0" || question.id_time_for_question == null || question.id_time_for_question == "0") {
        tempCheck = true;
        break;
      }
    }
    if (!this.comprehensionText) {
      swal.fire("Please enter comprehension text.")
    }
    else if (this.Questiones.length == 0) {
      swal.fire("Please at least one question.")
    }
    else if (tempCheck == true) { //&& this.roleId != '4'(this line removed from if condition)
      swal.fire("Please select all meta data of comprehension questions.")
    }
    else {
      let json = {
        "caller_email": localStorage.getItem("email"),
        "id_comprehension": this.comprehensionId,
        "comprehension_text": this.comprehensionText,
        "purpose": "approved",
        "question_code": this.questionCode,
        "questiones": JSON.stringify(this.Questiones)
      }
      this.comprehensionQuestionService.editQuestion(json).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.showAlertComponent(data['status_code'], data['status_message']);
            this.hideModal('approved');
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )

    }
  }
  removeSingleTypeQuestionOption(data, index) {
    swal.fire({
      title: '',
      text: "Are you sure you want to remove this option?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.QuestionsSingleOptions.splice(index, 1);
      }
    })
  }

  removeMultipleTypeQuestionOption(data, index) {
    swal.fire({
      title: '',
      text: "Are you sure you want to remove this option?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.QuestionsMultipleOptions.splice(index, 1);
      }
    })
  }

  handleOptionImageFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];

    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderOptionLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderOptionLoaded(readerEvt) {

    var binaryString = readerEvt.target.result;
    this.base64textQuestionOtionString = btoa(binaryString);
    var image = 'data:image/png;base64,' + this.base64textQuestionOtionString;
    this.questionOptionImage = this.domSanitizer.bypassSecurityTrustUrl(image);
  }

  initializeTiny() {
    this.initEditText = {
      plugins: "powerpaste imagetools",
      powerpaste_allow_local_images: true,
      readonly: 1,
      toolbar: "sizeselect | bold italic | fontselect |  fontsizeselect|charmap",
    }
  }

  generateSIngleMultipleOption() {
    this.SingleOptiones = [];
    this.MultipleOptiones = [];
    for (var i = 0; i < 4; i++) {
      let json = {
        "option_name": i + 1,
        "option_text": "",
        "is_option_correct": 1
      }
      this.SingleOptiones.push(json)
      this.MultipleOptiones.push(json)
    }
  }
  checkUncheckSingleOption(data, index) {
    for (let option of this.SingleOptiones) {
      if (option.option_name != data.option_name) {
        option.is_option_correct = 1;
      }
    }
    if (data.is_option_correct == 0) {
      data.is_option_correct = 1
    }
    else {
      data.is_option_correct = 0
    }
  }
  checkSingleTypeCheckBoxIsCheckedOrNot(data) {
    if (data.is_option_correct == 0) {
      return true;
    }
    else {
      return false;
    }
  }


  checkUncheckMultipleOption(data, index) {
    if (data.is_option_correct == 0) {
      data.is_option_correct = 1
    }
    else {
      data.is_option_correct = 0
    }
  }
  checkMultipleTypeCheckBoxIsCheckedOrNot(data) {
    if (data.is_option_correct == 0) {
      return true;
    }
    else {
      return false;
    }
  }

  checkUncheckForReasoning(data, index) {
    for (let option of this.QuestionsOptionsForReasoning) {
      if (option.option_name != data.option_name) {
        option.is_option_correct = 1;
      }
    }
    if (data.is_option_correct == 0) {
      data.is_option_correct = 1
    }
    else {
      data.is_option_correct = 0
    }
  }
  checkReasoningTypeCheckBoxIsCheckedOrNot(data) {
    if (data.is_option_correct == 0) {
      return true;
    }
    else {
      return false;
    }
  }

  generateColumnOne() {
    this.ColumnOne = [];
    for (var i = 97; i < 101; i++) {
      let json = {
        "column_name": String.fromCharCode(i),
        "column_value": "",
      }
      this.ColumnOne.push(json)
    }
    this.generateMatrixMatchingAnswer();
  }
  generateColumnSecond() {
    this.columnSecond = [];
    for (var i = 112; i < 116; i++) {
      let json = {
        "column_name": String.fromCharCode(i),
        "column_value": "",
      }
      this.columnSecond.push(json)
    }
    this.generateMatrixMatchingAnswer();
  }

  addNewColumnOne() {
    var columnNameCharCode = this.ColumnOne[this.ColumnOne.length - 1].column_name.charCodeAt(0);
    let json = {
      "column_name": String.fromCharCode(columnNameCharCode + 1),
      "column_value": "",
    }
    this.ColumnOne.push(json);
    this.generateMatrixMatchingAnswer();
  }

  addNewColumnSecond() {
    var columnNameCharCode = this.columnSecond[this.columnSecond.length - 1].column_name.charCodeAt(0);
    let json = {
      "column_name": String.fromCharCode(columnNameCharCode + 1),
      "column_value": "",
    }
    this.columnSecond.push(json);
    this.generateMatrixMatchingAnswer();
  }

  removeColumnOne(index) {
    var columnName = this.ColumnOne[index].column_name;

    var tempIndex = this.CheckedArray.findIndex(x => x.option == columnName)

    this.ColumnOne.splice(tempIndex, 1)

    this.ColumnOne.splice(index, 1)
    this.generateMatrixMatchingAnswer();
  }
  removeColumnSecond(index) {
    var columnName = this.columnSecond[index].column_name;

    var tempIndex = this.CheckedArray.findIndex(x => x.answers == columnName)

    this.ColumnOne.splice(tempIndex, 1)
    this.columnSecond.splice(index, 1)
    this.generateMatrixMatchingAnswer();
  }

  getQuestionTextForMdal(data) {

    let x = data.split("$$");

    return "Assertion: " + x[0] + " Reason: " + x[1]
  }
  remove(data, index) {
    this.Questiones.splice(index, 1)
  }

  onItemDeSelectQuestionSource() {
    this.questionSourceId = 0;
  }

  onItemSelectQuestionSource(event) {
    this.questionSourceId = event.id_question_source;
    console.log("questionSourse length="+this.selectedSource.length);
    if((this.Questiones != undefined) && (this.Questiones.length>0))
    {
      this.Questiones.forEach(question => {
              question['id_question_source']=event.id_question_source;
      });
    }
  }

 
  filterSource() {
    this.QuestionSources = [];

    if (this.idCourseType == 14)
      this.QuestionSources = this.orignalQuestionSources;

    if (this.idCourseType == 15) {
      this.orignalQuestionSources.
        forEach(source => {
          if (source['source_for'] != 16) {
            this.QuestionSources.push(source);
          }
        });
    }

    if (this.idCourseType == 16) {
      this.orignalQuestionSources.
        forEach(source => {
          if (source['source_for'] != 15) {
            this.QuestionSources.push(source);
          }
        });
    }

    if(this.firstTime){
      var flag = false; 
      this.selectedPatternItems = [];
      this.QuestionSources.forEach(item => {
        if (item.id_question_source == this.questionSourceId) {
          this.selectedPatternItems.push({ id_question_source: this.questionSourceId, name: item.name });
          flag = true;
        }
      });

      if(!flag)
        this.questionSourceId = '0';

      this.firstTime = false;  
    }else{
      this.questionSourceId = '0';
      this.selectedPatternItems = [];
    }
  }


}