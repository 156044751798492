import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class ScholarDashBoardService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }
    getAllFacultyFeedback(email, scholarId) {
        return this.http.get(this.baseUrl + "/Feedback/getAllFacultyFeedbackRequestBySectionId?caller_email=" + email + "&id_scholar=" + scholarId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllFeedbackQuestion(email) {
        return this.http.get(this.baseUrl + "/Feedback/getFeedbackquestion?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    addFacultyFeedbackRequestByScholar(jsonData) {
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Feedback/addFacultyFeedbackRequestByScholar", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getDashboardGraphData(email, scholarId, selectedYear, selectedMonth, monthName) {
        return this.http.get(this.baseUrl + "/Reports/getDashboardGraphDataForScholar?caller_email=" + email + "&id_user=" + scholarId + "&year=" + selectedYear + "&month=" + selectedMonth + "&month_name=" + monthName).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getScholarAnaysisReport(email,sectionId,scholarId){
        return this.http.get(this.baseUrl + "/Reports/getScholarAnaysisReport?caller_email=" + email+"&id_section="+sectionId+"&minimum_exam_number=0"+"&id_scholar="+scholarId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getScholarAnaysisReport1(email,sectionId,limit,scholarId){
        return this.http.get(this.baseUrl + "/Reports/getScholarAnaysisReport?caller_email=" + email+"&id_section="+sectionId+"&minimum_exam_number="+limit+"&id_scholar="+scholarId).map((res) => JSON.parse(JSON.stringify(res)));
    }
    
    getAllSubject(email){
        return this.http.get(this.baseUrl + "/Topic/getAllSubject?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    // getStrengthAndWeakTopicsListBySubjectId(email,subjects,userId){
    //     return this.http.get(this.baseUrl + "/Reports/getStrengthAndWeakTopicsListBySubjectId?caller_email=" + email+"&id_user="+userId+"&subjects="+subjects).map((res) => JSON.parse(JSON.stringify(res)));
    // }

    getStrengthAndWeakTopicsListBySubjectId(jsonData) {
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "/Reports/getStrengthAndWeakTopicsListBySubjectId", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getTimeUsageData(jsonData) {
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "/Reports/getTimeUsageData", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllExamPerformanceGraph(email, userId, examTemplateId) {
        return this.http.get(this.baseUrl + "OnlineExam/getAllExamScoreGraph?caller_email=" + email + "&id_scholar=" + userId + "&by_default=" + "5");
    }

}