import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class AddExamPatternService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any

    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });
    }

    getAllCourseType(email) {
        return this.http.get(this.baseUrl + "Course/getAllCourseForBatch?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllPattern(email) {
        return this.http.get(this.baseUrl + "TestMaster/getAllExamPattern?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllExamPattern(email) {
        return this.http.get(this.baseUrl + "TestMaster/getAllExamPattern?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllSubjects(email) {
        return this.http.get(this.baseUrl + "Topic/getAllSubject?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllSubSubject(email) {
        return this.http.get(this.baseUrl + "Topic/getAllSubSubject?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllTopic(email) {
        return this.http.get(this.baseUrl + "Topic/getAllTopic?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllSubTopic(email) {
        return this.http.get(this.baseUrl + "Topic/getAllSubTopic?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllPatternSubjectMapping(email) {
        return this.http.get(this.baseUrl + "TestMaster/getAllPatternSubjectMapping?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    addExamPattern(jsonData) {
        //.log(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "TestMaster/addExamPattern", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    editExamPattern(jsonData) {
        //.log(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "TestMaster/editExamPattern", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removePattern(patternId, email) {
        return this.http.get(this.baseUrl + "TestMaster/removePattern?caller_email=" + email + "&id_pattern=" + patternId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    addPatternSubjectMapping(jsonData) {
        //.log(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "TestMaster/addPatternSubjectMapping", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removePatternSubject(patternId, subjectId, email) {
        return this.http.get(this.baseUrl + "TestMaster/removePatternSubjectByPaternIdAndSubjectId?caller_email=" + email + "&id_pattern=" + patternId + "&id_subject=" + subjectId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    editQuestionExamPattern(jsonData) {
        //.log(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "TestMaster/editExamQuestionPattern", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getPatternSubject(email, patternId) {
        return this.http.get(this.baseUrl + "TestMaster/getAllPatternSubjectMappingByPatternId?caller_email=" + email + "&id_pattern=" + patternId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getQuestionForPatternSubject(email, patternId, courseTypeId, questionSourceId) {
        return this.http.get(this.baseUrl + "QuestionBank/getQuestionForPatternSubject?caller_email=" + email + "&id_pattern=" + patternId + "&id_course_type=" + courseTypeId + "&questionSourceId=" + questionSourceId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    automatedQuestionGeneration(jsonData) {
        //.log(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "TestTemplate/automatedQuestionGeneration", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    automatedRemainingQuestionGeneration(jsonData) {
        //.log(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "TestTemplate/automatedRemainingQuestionGeneration", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    addNewExamTemplate(json) {
        //.log(JSON.stringify(json))
        return this.http.post(this.baseUrl + "TestTemplate/addNewExamTemplate", json, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    editExamTemplate(json) {
        //.log(JSON.stringify(json))
        return this.http.post(this.baseUrl + "TestTemplate/editNewExamTemplate", json, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllQuestionSources(email) {
        return this.http.get(this.baseUrl + "TestMaster/getAllQuestionSources?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }
}