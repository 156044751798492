import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class BatchMasterService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any;

    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });
    }

    getAllCoe(email) {
        return this.http.get(this.baseUrl + "/Coe/getAllActiveCoe?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllCourse(email) {
        return this.http.get(this.baseUrl + "/Course/getAllCourseForBatch?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }
    addNewBatch(jsonData) {
        //JSON.stringify(jsonData);
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Batch/addNewBatch", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllBatches(email) {
        return this.http.get(this.baseUrl + "/Batch/getAllBatch?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }
    getAllbatchStudents(email,id_batch) {
        return this.http.get(this.baseUrl + "/Batch/getAllBatchStudentsByBatchId?caller_email=" + email+"&id_batch="+id_batch).map((res) => JSON.parse(JSON.stringify(res)));
    }

    editBatch(jsonData) {
        //JSON.stringify(jsonData);
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Batch/editBatch", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeBatch(batchId, email) {
        return this.http.get(this.baseUrl + "/Batch/removeBatch?caller_email=" + email + "&id_batch=" + batchId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    changeStatus(batchId, callerEmail, statusId) {
        return this.http.get(this.baseUrl + "/Batch/changeStatus?&id_batch=" + batchId + "&caller_email=" + callerEmail + "&id_status=" + statusId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllSectionsByBatchId(email, batchId) {
        return this.http.get(this.baseUrl + "/Section/getAllSectionByBatchId?caller_email=" + email + "&id_batch=" + batchId).map((res) => JSON.parse(JSON.stringify(res)));
    }
    
    getAllBatchByCoeId(email, coeId) {
        return this.http.get(this.baseUrl + "/Batch/getAllCoeInActiveBatches?caller_email=" + email + "&id_coe=" + coeId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllSectionStudents(jsonData) {
        //JSON.stringify(jsonData);
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Batch/getAllSectionStudents", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    convertToAlumn(jsonData) {
        //JSON.stringify(jsonData);
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Batch/convertStudentToAlumn", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    convertToNonAlumn(jsonData) {
        //JSON.stringify(jsonData);
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Batch/convertStudentToNonAlumn", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }


}