import { Component } from '@angular/core';
import {QuizDoubtService} from './quiz-doubt.service';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import swal from "sweetalert2";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Helper } from '../helper';
@Component({
    selector: 'quiz-doubt',
    templateUrl: 'quiz-doubt.component.html',
    styleUrls: ['quiz-doubt.component.scss'],
    providers:[QuizDoubtService]
})
export class QuizDoubtComponent {
    showLoader = false;
    showAlert = false;
    alertMsg: any;
    alertType: any;
    isModalShow = false;
    faculty = [];
    SubTopics = [];
    Topics = [];
    SubSubjects = [];
    Subjects = [];
    tableArray = [];
    UnFiltterdSubSubjects = [];
    UnfiltterdTopics = [];
    UnfilttersSubTopics = [];
    filter = {
        id_course_type: "0",
        id_subject: "0",
        id_sub_subject: "0",
        id_topic: "0",
        id_faculty: "0",
        id_coe:"0",
        id_batch:"0"
      };
    column: string = "CategoryName";
    direction: number;
    isDesc: boolean = false;  
    roleId: any;
    userId: any;
    unFiltterdTableArray=[];
    doubtType=0;
    searchText:any;
    form: FormGroup;
    isPostAnswerModal=false;
    doubtData:any;
    solvedRaisedDoubts=[];
    isViewAnswerModal=false;
    preViewImages=[];
    isImagePreviewModal=false;
    initEditText:any;
    filterObject={
      id_subject:"0",
      id_sub_subject:"0",
      id_topic:"0",
      id_faculty:"0",
      id_coe:"0",
      id_batch:"0",
      offset:1
    };
    p: number = 0;
    totalQuestions:any;
    coeArray=[];
    batchArray=[];
    unFilteredBatchArray=[];
    constructor(private quizDoubtService:QuizDoubtService,private domSanitizer: DomSanitizer){
        this.roleId = localStorage.getItem("id_role");
        this.userId = localStorage.getItem("id_user");
        if (this.roleId == 4) {
          this.filter.id_faculty = this.userId;
        }
        this.getMasterDataForLectureGroup();
        this.getQuizDoubtForAdmin(0);
        this.initializeTiny();
    }

    ngOnInit() {
      this.buildForm();
     
    }

    initializeTiny() {
      this.initEditText = {
        plugins: "image",
        readonly: 1,
        toolbar: "sizeselect | bold italic | fontselect |  fontsizeselect",
        images_upload_url: 'http://dakshana.beta.vendorZApp.in/service/upload_image.php',
        convert_urls: false,
        images_upload_handler: function (blobInfo, success, failure) {
          var xhr, formData;
          xhr = new XMLHttpRequest();
          xhr.withCredentials = false;
          xhr.open('POST', 'http://dakshana.beta.vendorZApp.in/service/upload_image.php');
          xhr.onload = function () {
            var json;
  
            if (xhr.status != 200) {
              failure('HTTP Error: ' + xhr.status);
              return;
            }
            json = JSON.parse(xhr.responseText);
  
            if (!json || typeof json.generatedName != 'string') {
              failure('Invalid JSON: ' + xhr.responseText);
              return;
            }
            success(json.generatedName);
          };
          formData = new FormData();
          formData.append('file', blobInfo.blob(), blobInfo.filename());
          xhr.send(formData);
        }
      }
  
  
    }
    buildForm() {
      this.form = new FormGroup({
        question_text:new FormControl("", [Validators.required]),
        doubt_related_to: new FormControl(null),
        answer_text: new FormControl("", [Validators.required]),
        video_link: new FormControl(""),
        answer_image: new FormControl(""),
        answered_by: new FormControl(this.userId, [Validators.required]),
        doubt_relevance: new FormControl("High", [Validators.required]),
        is_for_public: new FormControl(false),
      });
    }
    getMasterDataForLectureGroup() {
        this.showLoader = true;
        this.quizDoubtService
          .getMasterDataForLectureGroup(localStorage.getItem("email"), this.roleId)
          .subscribe(
            (data) => {
              this.showLoader = false;
              if (data.status_code == 0) {
                this.Subjects = data.subjects;
                this.UnFiltterdSubSubjects = data.sub_subjects;
                this.UnfiltterdTopics = data.topics;
                this.UnfilttersSubTopics = data.sub_topics;
                this.faculty = data.faculty;
                this.unFilteredBatchArray=data.batches;
                this.batchArray=data.batches;
                this.coeArray=data.coes;
               
                this.showAlertComponent(
                  data["status_code"],
                  data["status_message"]
                );
              } else {
                this.showAlertComponent(
                  data["status_code"],
                  data["status_message"]
                );
              }
            },
            (err) => {
              this.showLoader = false;
              swal.fire("Something went wrong. Please try again later.");
            }
          );
    }

    getQuizDoubtForAdmin(doubtType,callFrom?:any) {
      this.doubtType=doubtType;
      if(doubtType!=2){
        if(callFrom=='html'){
          this.filterObject={
            id_subject:"0",
            id_sub_subject:"0",
            id_topic:"0",
            id_faculty:"0",
            id_coe:"0",
            id_batch:"0",
            offset:1
          };
          this.filter = {
            id_course_type: "0",
            id_subject: "0",
            id_sub_subject: "0",
            id_topic: "0",
            id_faculty: "0",
            id_coe:"0",
            id_batch:"0"
          };
          this.totalQuestions=0;
        }
          this.searchText="";
         
          this.tableArray=[];
          this.unFiltterdTableArray=[];
          this.showLoader = true;
          let json=this.filterObject;
          json['caller_email']=localStorage.getItem("email");
          json['doubt_type']=doubtType
          json['id_role']=this.roleId
          this.quizDoubtService
            .getQuizDoubtForAdmin(json)
            .subscribe(
              (data) => {
                this.showLoader = false;
                if (data.status_code == 0) {
                //  if(this.roleId=='4'){
                //   data.quiz_doubts=data.quiz_doubts.filter(x=>x.id_faculty==this.userId)
                //  }
                this.totalQuestions=data.count;
                console.log(this.totalQuestions)
                 this.tableArray=data.quiz_doubts;
                 this.unFiltterdTableArray=data.quiz_doubts;
                  this.showAlertComponent(
                    data["status_code"],
                    data["status_message"]
                  );
                } else {
                  this.showAlertComponent(
                    data["status_code"],
                    data["status_message"]
                  );
                }
              },
              (err) => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.");
              }
            );
      }
    }

    getSolvedQuizDoubtForAdminByTopicId(topicId) {
      this.searchText="";
      this.solvedRaisedDoubts=[];
      this.showLoader = true;
      this.quizDoubtService
        .getSolvedQuizDoubtForAdminByTopicId(localStorage.getItem("email"), topicId)
        .subscribe(
          (data) => {
            this.showLoader = false;
            if (data.status_code == 0) {
             this.solvedRaisedDoubts=data.quiz_doubts;
          
              this.showAlertComponent(
                data["status_code"],
                data["status_message"]
              );
            } else {
              this.showAlertComponent(
                data["status_code"],
                data["status_message"]
              );
            }
          },
          (err) => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.");
          }
        );
  }

    showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
        this.alertType = "success";
    } else {
        this.alertType = "failure";
    }
    }
    hideAlert(val) {
    this.showAlert = val;
    }

    filterSuSuject(call?: any) {
        this.SubSubjects = [];
        this.filter.id_sub_subject = "0";
        this.filter.id_topic = "0";
        if(this.roleId!='4')
        this.filter.id_faculty = "0";
        for (let data of this.UnFiltterdSubSubjects) {
          if (this.filter.id_subject == data.id_subject) {
            this.SubSubjects.push(data);
          }
        }
        this.filteredQuizDoubts();
        this.Topics = [];
      }
    
      filterTopics(call?: any) {
        this.Topics = [];
        this.filter.id_topic = "0";
        if(this.roleId!='4')
        this.filter.id_faculty = "0";
        for (let data of this.UnfiltterdTopics) {
          if (this.filter.id_sub_subject == data.id_sub_subject) {
            this.Topics.push(data);
          }
        }
        this.filteredQuizDoubts();
      }

      filteredQuizDoubts() {
        this.filterObject={
          id_subject:this.filter.id_subject,
          id_sub_subject:this.filter.id_sub_subject,
          id_topic:this.filter.id_topic,
          id_faculty:this.filter.id_faculty,
          id_coe:this.filter.id_coe,
          id_batch:this.filter.id_batch,
          offset:1
        };
        this.getQuizDoubtForAdmin(this.doubtType);
        // this.tableArray = this.unFiltterdTableArray;
        // if (this.filter.id_subject != "0") {
        //   var temporaryArray = [];
        //   for (let data of this.tableArray) {
        //     if (data.id_subject == this.filter.id_subject) {
        //       temporaryArray.push(data);
        //     }
        //   }
        //   this.tableArray = temporaryArray;
        // }
        // if (this.filter.id_sub_subject != "0") {
        //   var temporaryArray = [];
        //   for (let data of this.tableArray) {
        //     if (data.id_sub_subject == this.filter.id_sub_subject) {
        //       temporaryArray.push(data);
        //     }
        //   }
        //   this.tableArray = temporaryArray;
        // }
        // if (this.filter.id_topic != "0") {
        //   var temporaryArray = [];
        //   for (let data of this.tableArray) {
        //     if (data.id_topic == this.filter.id_topic) {
        //       temporaryArray.push(data);
        //     }
        //   }
        //   this.tableArray = temporaryArray;
        // }
        // if (this.filter.id_faculty != "0") {
        //   var temporaryArray = [];
        //   for (let data of this.tableArray) {
        //     if (data.id_faculty == this.filter.id_faculty) {
        //       temporaryArray.push(data);
        //     }
        //   }
        //   this.tableArray = temporaryArray;
        // }
      }

      closePostAnswerModal(){
        this.buildForm();
        this.isPostAnswerModal=false;
      }
      postAnswer(data,forType){
        this.isPostAnswerModal=true;

        this.doubtData=data;
        let isForPublic=false;
        if(data.is_for_public=="1"){
          isForPublic=true;
        }
        if(forType=='edit'){
          this.form.patchValue({
            question_text:data.question_text,
            answer_text:data.answer_text,
            video_link:data.video_link,
            is_for_public:isForPublic,
            doubt_relevance:data.doubt_relevance
          })
        }
        else{
          this.form.patchValue({
            question_text:data.question_text,
         
          })
        }
       
       this.getSolvedQuizDoubtForAdminByTopicId(data.id_topic)
      }

      getImageData(imageData){
        let imageUrl:SafeResourceUrl;
        imageUrl= this.domSanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpeg;base64,' + imageData);
        return imageUrl
      }

getAlreadyRaisedData(key){
  console.log(this.form.getRawValue())
  if(this.form.getRawValue().doubt_related_to!=null && this.form.getRawValue().doubt_related_to!='null'){
    let findObject=this.solvedRaisedDoubts.find(x=>x.id_quiz_doubt===this.form.getRawValue().doubt_related_to);
    this.form.patchValue({
      
      answer_text:findObject.answer_text,
      video_link:findObject.video_link,
      answer_image:findObject.answer_image
    })
    if(key==='answer_text'){
      console.log(findObject)
    }
   
    return findObject[key]
  }
  else{
   
    return "";
  }
 
}

handleFileSelect(evt){
  var files = evt.target.files;
  var file = files[0];

if (files && file) {
    var reader = new FileReader();

    reader.onload =this._handleReaderLoaded.bind(this);

    reader.readAsBinaryString(file);
}
}



_handleReaderLoaded(readerEvt) {
 var binaryString = readerEvt.target.result;
       this.form.patchValue({
         answer_image: btoa(binaryString)
       })
  }

  submit(){
    let json=this.form.getRawValue();
    json['caller_email']=localStorage.getItem("email");
    json['id_quiz_doubt']=this.doubtData.id_quiz_doubt;
    this.showLoader = true;
    this.quizDoubtService
      .editQuizDoubt(json)
      .subscribe(
        (data) => {
          this.showLoader = false;
          if (data.status_code == 0) {
            // this.filterObject={
            //   id_subject:"0",
            //   id_sub_subject:"0",
            //   id_topic:"0",
            //   id_faculty:"0",
            //   id_coe:"0",
            //   id_batch:"0",
            //   offset:1
            // };
              this.closePostAnswerModal();
              this.getQuizDoubtForAdmin(this.doubtType);
          } else {
            this.showAlertComponent(
              data["status_code"],
              data["status_message"]
            );
          }
        },
        (err) => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.");
        }
      );
  }

  checkAlreadyDoubt(){
    if(this.form.getRawValue().doubt_related_to==null || this.form.getRawValue().doubt_related_to=='null'){
      return false
    }
    else{
      return true
    }
  }

  updateFormValue(){
    if(this.form.getRawValue().doubt_related_to===null || this.form.getRawValue().doubt_related_to==='null'){
      let findObject=this.solvedRaisedDoubts.find(x=>x.id_quiz_doubt===this.form.getRawValue().doubt_related_to)
      if(findObject){
        this.form.patchValue({
      
          answer_text:findObject.answer_text,
          video_link:findObject.video_link,
          answer_image:findObject.answer_image
        })
      }
      else{
        this.form.patchValue({
      
          answer_text:"",
          answer_image:"",
          video_link:"",
        })
      }
     
    }
    else{
      this.form.patchValue({
      
        answer_text:"",
        answer_image:"",
        video_link:"",
      })
    }
    console.log(this.form)
  
  }

  closeViewAnswerModal(){
    this.isViewAnswerModal=false;
  }

  viewAnswer(data){
    this.doubtData=data
    this.isViewAnswerModal=true;
  }

  openImagePreviewModal(imageData){
    console.log(imageData)
    this.preViewImages=[];
    // let imageUrl:SafeResourceUrl;
    // imageUrl= this.domSanitizer.bypassSecurityTrustResourceUrl(
    //   'data:image/jpeg;base64,' + imageData);
      this.isImagePreviewModal=true;
    return this.preViewImages.push(imageData)
    
  }
  closeImagePreviewModal(){
    this.isImagePreviewModal=false;
  }

  pageChanged(value){
    this.filterObject.offset=value;
    this.getQuizDoubtForAdmin(this.doubtType);
  }

  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.tableArray.sort(function (a, b) {
        if (a[property] < b[property]) {
            return -1 * direction;
        }
        else if (a[property] > b[property]) {
            return 1 * direction;
        }
        else {
            return 0;
        }
    });
}
}
