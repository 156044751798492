import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent implements OnInit {
  @Input() pieChartLabels = [];
  @Input() pieChartData = [];
  constructor() { }

  ngOnInit() {
  }
  public pieChartType: string = 'pie';
  public chartClicked(e: any): void {
    //.log(e);
  }

  public chartHovered(e: any): void {
    //.log(e);
  }


  public chartColors: Array<any> = [
    {
      backgroundColor: [
        'rgba(123,104,238)',
        'rgb(157, 68, 122)',
        'rgb(66, 142, 99)',
        'rgb(254, 119, 123)',

      ]
    }


  ];

}
