import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class SectionMasterService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }

    getAllCoe(email) {
        return this.http.get(this.baseUrl + "/Coe/getAllActiveCoe?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllSections(email) {
        return this.http.get(this.baseUrl + "/Section/getAllSection?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }
    addNewSection(jsonData) {
        //JSON.stringify(jsonData);
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Section/addNewSection", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllBatches(email) {
        return this.http.get(this.baseUrl + "/Batch/getAllActiveBatch?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }
    editSection(jsonData) {
        //JSON.stringify(jsonData);
        //(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Section/editSection", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeSection(sectionId, email) {
        return this.http.get(this.baseUrl + "/Section/removeSection?caller_email=" + email + "&id_section=" + sectionId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    changeStatus(sectionId, callerEmail, statusId) {
        return this.http.get(this.baseUrl + "/Section/changeStatus?&id_section=" + sectionId + "&caller_email=" + callerEmail + "&id_status=" + statusId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllScholarBySection(callerEmail,sectionId){
        return this.http.get(this.baseUrl + "/Section/getAllScholarBySectionId?&id_section=" + sectionId + "&caller_email=" + callerEmail).map((res) => JSON.parse(JSON.stringify(res)));
        
    }
}