import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PatternService } from './exam-pattern.service'
import swal from 'sweetalert2'
@Component({
  selector: 'exam-pattern',
  templateUrl: './exam-pattern.component.html',
  styleUrls: ['./exam-pattern.component.css'],
  providers: [PatternService]
})
export class ExamPatternComponent implements OnInit {
  patternName: any;
  isPatternModal = false
  showLoader = false;
  Patterns = [];
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  column: string = 'CategoryName';
  direction: number;
  isDesc: boolean = false;
  modalPurpose = "Add";
  patternId: any;
  alertDiv = false;
  sucessAlertMessage = "";
  Subjects = [];
  UnfilteredSubjects = [];
  isSubjectModal = false;
  CheckedSubjects = [];
  isCheckedAllTopic = "1";
  PatternSubjects = [];
  UnfilterdPatternSubjects = [];
  ExamQuestionPatterns = [];
  isQuestionPatternModal = false;
  subjectId: any;
  serachPatternText: any;
  serachSubjectText: any;
  serachQuestionPattern: any;
  CourseType = [];
  courseTypeId = "0";
  searchText: any;
  isApplyPatternToAllSubject = 0;


  constructor(private router: Router, private patternService: PatternService) {
    if (localStorage.getItem("isLogin") == "true") {
      this.patternId = "0";
      this.getAllCourseType();

    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  getAllCourseType() {
    this.showLoader = true;
    this.patternService.getAllCourseType(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.CourseType = data.courses
          this.showAlertComponent(data['status_code'], data['status_message']);
          this.getExamPattern();
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }



  getExamPattern() {
    this.showLoader = true;
    this.patternService.getAllExamPattern(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.Patterns = data.patterns
          this.showAlertComponent(data['status_code'], data['status_message']);
          this.getAllSubjectcs();
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllSubjectcs() {
    this.showLoader = true;
    this.patternService.getAllSubjects(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.Subjects = data.subjects
          this.UnfilteredSubjects = data.subjects
          this.getAllPatternSubjectMapping();
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  getAllPatternSubjectMapping() {
    this.showLoader = true;
    this.patternService.getAllPatternSubjectMapping(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.PatternSubjects = data.pattern_subjects
          this.UnfilterdPatternSubjects = data.pattern_subjects
          this.showAlertComponent(data['status_code'], data['status_message']);
          if (this.patternId) {
            this.fileteSubjectByPattern(this.patternId);
          }
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  fileteSubjectByPattern(patternId) {
    this.patternId = patternId
    this.PatternSubjects = [];
    ////.log("patternId" + patternId)
    ////.log("patternId" + JSON.stringify(this.UnfilterdPatternSubjects))
    for (let data of this.UnfilterdPatternSubjects) {
      ////.log(data.id_pattern == patternId)
      if (data.id_pattern == patternId) {
        this.PatternSubjects.push(data);
      }



    }
    if (this.PatternSubjects.length > 0) {
      this.ExamQuestionPatterns = this.PatternSubjects[0].pattern_question_subject
      this.subjectId = this.PatternSubjects[0].id_subject
      this.selectedSubject = this.PatternSubjects[0].subject_name

    }
    else {
      this.ExamQuestionPatterns = [];
    }
  }
  addPatternName() {
    this.isPatternModal = true
    this.modalPurpose = "Add"
    this.clearData();
  }
  hideModal() {
    this.isPatternModal = false
    this.isSubjectModal = false;
    this.isQuestionPatternModal = false
  }
  showAlertDiv(message) {
    var that = this
    that.alertDiv = true
    this.sucessAlertMessage = message
    setTimeout(function () {
      that.alertDiv = false
    }, 5000);
  }

  savePattern() {
    if (!this.patternName) {
      swal.fire("Please enter pattern name")
    }
    else if (this.courseTypeId == "0") {
      swal.fire("Please select course type.")
    }
    else {
      if (this.modalPurpose == "Add") {
        let json = {
          "caller_email": localStorage.getItem("email"),
          "name": this.patternName,
          "id_course_type": this.courseTypeId
        }
        this.showLoader = true;
        this.patternService.addExamPattern(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == "0") {
              this.hideModal()
              this.Patterns = data.patterns
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
      else {
        let json = {
          "caller_email": localStorage.getItem("email"),
          "name": this.patternName,
          "id_pattern": this.patternId,
          "id_course_type": this.courseTypeId
        }

        this.showLoader = true;
        this.patternService.editExamPattern(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == "0") {
              this.hideModal()
              this.Patterns = data.patterns
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
    }
  }

  clearData() {
    this.patternName = "";
    this.CheckedSubjects = [];
    this.isCheckedAllTopic = "1";
    this.courseTypeId = "0"
  }
  filterSubject() {
    this.Subjects = [];
    const index = this.Patterns.find(x => x.id_pattern == this.patternId);
    //.log(index)
    var courseTypeId = index.id_course_type;
    //.log(courseTypeId)
    for (let data of this.UnfilteredSubjects) {
      for (let course of data.courses) {
        if (courseTypeId == course.id_course_type) {
          this.Subjects.push(data);
        }
      }
    }
  }
  editPattern(data) {
    this.patternName = data.name;
    this.patternId = data.id_pattern;
    this.modalPurpose = "Edit";
    this.courseTypeId = data.id_course_type
    this.isPatternModal = true;
    this.CheckedSubjects = [];

  }
  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    //("status" + status);
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }

  removePattern(data) {
    swal.fire({
      title: '',
      text: "Are you sure you want to remove this pattern?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;

        this.patternService.removePattern(data.id_pattern, localStorage.getItem("email")).subscribe(
          data => {
            this.Patterns = []
            this.showLoader = false;
            if (data.status_code == 0) {
              this.Patterns = data.patterns;
              this.hideModal()
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.hideModal()
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
    })
  }
  addSubject() {
    this.isSubjectModal = true;
  }

  checkUncheckSubject(subjectId) {
    var check = true
    if (this.CheckedSubjects.length != 0) {
      for (let i = 0; i < this.CheckedSubjects.length; i++) {
        if (this.CheckedSubjects[i].id_subject == subjectId) {
          this.CheckedSubjects.splice(i, 1)
          this.isCheckedAllTopic = "1"
          check = false
          break;
        }
        else {
          check = true;
        }
      }

      if (check == true) {
        let json = {
          "id_subject": subjectId
        }
        this.CheckedSubjects.push(json)
      }
    }
    else {
      let json = {
        "id_subject": subjectId
      }
      this.CheckedSubjects.push(json)
    }
  }


  checkSubjectIschecked(subjectId) {
    var isChecked = false;
    if (this.CheckedSubjects.length != 0) {
      for (let checkedData of this.CheckedSubjects) {
        if (subjectId == checkedData.id_subject) {
          isChecked = true;
          break;
        }
        else {
          isChecked = false;
        }

      }

    }
    else {
      return false
    }
    if (isChecked) {
      return true;
    }
    else {
      return false;
    }
  }

  selectAllSubject() {
    this.CheckedSubjects = []
    if (this.isCheckedAllTopic == "1") {
      this.isCheckedAllTopic = "0"
      for (let data of this.Subjects) {
        let json = {
          "id_subject": data.id_subject
        }
        this.CheckedSubjects.push(json)
      }
    }
    else {
      this.isCheckedAllTopic = "1"
      this.CheckedSubjects = []
    }
  }

  saveSubject() {
    if (this.patternId == "0") {
      swal.fire("Please select pattern.")
    }
    else if (this.CheckedSubjects.length == 0) {
      swal.fire("Please select at least one subject.")
    }
    else {
      let json = {
        "caller_email": localStorage.getItem("email"),
        "id_pattern": this.patternId,
        "subjects": JSON.stringify(this.CheckedSubjects)

      }

      this.showLoader = true;
      this.patternService.addPatternSubjectMapping(json).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.hideModal()
            // this.PatternSubjects = data.pattern_subjects
            this.getExamPattern();
            this.fileteSubjectByPattern(this.patternId);
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }
  }

  removedPatternSubject(data) {
    swal.fire({
      title: '',
      text: "Are you sure you want to remove this subject from pattern?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;

        this.patternService.removePatternSubject(data.id_pattern, data.id_subject, localStorage.getItem("email")).subscribe(
          data => {
            // this.Patterns = []
            this.showLoader = false;
            if (data.status_code == 0) {
              // this.PatternSubjects = data.pattern_subjects;
              this.hideModal()
              this.getExamPattern();
              //.log(this.patternId)
              this.fileteSubjectByPattern(this.patternId);
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.hideModal()
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
    })
  }

  filterQuestionPattern(subjectId, patternId, subject_name) {
    this.subjectId = subjectId
    this.selectedSubject = subject_name

    for (let data of this.UnfilterdPatternSubjects) {
      if (data.id_subject == subjectId && data.id_pattern == patternId) {
        this.ExamQuestionPatterns = data.pattern_question_subject
        break;
      }
    }
  }

  selectedSubject = '';

  editQuestionPattern() {
    this.isQuestionPatternModal = true;

  }

  saveQuestionPattern() {
    var check = false;
    for (let data of this.ExamQuestionPatterns) {
      check = false;
      if (data.number_of_question == "") {
        swal.fire("Please enter number of question.")
        check = true;
        break;
      }
      else if (data.correct_marks == "") {
        swal.fire("Please enter correct marks.")
        check = true;
        break;
      }
      else if (data.negative_marks == "") {
        swal.fire("Please enter neagtive marks.")
        check = true;
        break;
      }
      else if (data.partial == "") {
        swal.fire("Please enter partial marks.")
        check = true;
        break;
      }
    }



    if (check == false) {

      if (!this.isApplyPatternToAllSubject) {
        //.log(this.subjectId)
        let json = {
          "caller_email": localStorage.getItem("email"),
          "id_pattern": this.patternId,
          "id_subject": this.subjectId,
          "question_pattern": JSON.stringify(this.ExamQuestionPatterns)
        }
        this.showLoader = true;
        this.patternService.editQuestionExamPattern(json).subscribe(
          data => {
            this.Patterns = []
            this.showLoader = false;
            if (data.status_code == 0) {
              this.hideModal();
              this.getExamPattern();
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.hideModal()
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      } else {

        let subjects = [];
        subjects = [];

        this.PatternSubjects.forEach(subject => {
          var s = { 'id_subject': subject['id_subject'] };
          subjects.push(s);
        });



        let json = {
          "caller_email": localStorage.getItem("email"),
          "id_pattern": this.patternId,
          "subjects": subjects,
          "question_pattern": JSON.stringify(this.ExamQuestionPatterns)
        }
        this.showLoader = true;
        this.patternService.editQuestionExamPatternToAllSubject(json).subscribe(
          data => {
            this.Patterns = []
            this.showLoader = false;
            if (data.status_code == 0) {
              this.hideModal();
              this.getExamPattern();
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.hideModal()
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )


      }
    }


  }

  applyPatternToallSubjectClick(event) {
    console.log("isTenthAvialble =" + this.isApplyPatternToAllSubject);


  }

  clearSubjectMarkingScheme() {

    var that = this;

    swal.fire({
      title: '',
      text: "Are you sure you want to remove marking scheme for " + this.selectedSubject + " ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        that.showLoader = true;

        that.ExamQuestionPatterns.forEach(element => {
          element.number_of_question = 0;
          element.correct_marks = 0;
          element.negative_marks = 0;
          element.partial = 0;
        });

        let json = {
          "caller_email": localStorage.getItem("email"),
          "id_pattern": that.patternId,
          "id_subject": that.subjectId,
          "question_pattern": JSON.stringify(that.ExamQuestionPatterns)
        }

        that.patternService.removeQuestionExamPattern(json).subscribe(
          data => {

            that.showLoader = false;
            that.showAlertComponent(data['status_code'], data['status_message']);
          },
          err => {

            that.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )

      }
    })
  }

}
