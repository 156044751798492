import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login/login.service';
import swal from 'sweetalert2'
import { md5 } from '../md5'
import { Helper } from '../helper';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadService } from '../services/upload.service'; 

@Component({
    selector: 'nav-bar',
    templateUrl: 'menu.component.html',
    styleUrls: ['menu.component.scss'],
    providers: [LoginService]
})
export class MenuComponent {
    // adminMenu = [{ name: 'Dashboard' }, { name: 'Master' }, { name: 'Attendance' }, { name: 'Analysis' }, { name: 'Reports' }, { name: 'Feedback' }, { name: 'Audit' }, { name: 'Online Exam Result' }, { name: 'Error Report' }, { name: 'Doubt' }, { name: 'Notice Board' }]
    adminMenu = [{ name: 'Dashboard' }, { name: 'Master' }, { name: 'Attendance' }, { name: 'Analysis' }, { name: 'Candidate Response' }]
    scholarMenu = [ { name: 'Schedule' }, { name: 'Attendance' }, { name: 'Analysis' }];
    coordinatorMenu = [{ name: 'Dashboard' }, { name: 'Schedule' }, { name: 'Attendance' }, { name: 'Analysis' }];
    managementMenu = [{ name: 'Dashboard' }, { name: 'Reports' }];
    facultyMenu = [{ name: 'Dashboard' }, { name: 'Analysis' }, { name: "Question Review" }, { name: "Lecture Groups" }, { name: 'Doubt' }];
    dataOperatorMenu = [{ name: 'Question Bank' }];

    isRole = "1";
    loginUserName: any;
    showProfile = false;
    email: any;
    mobile: any;
    isUpdatePassword = false;
    showLoader = false;
    otp: any;
    newPassword: any;
    confirmPassword: any;
    selectedLink = "";
    imageString = "../assets/logoscholar.jpg";
    dakshana_roll_no='';
    batch='';
    coe='';
    coe_roll_no='';
    section = '';
    address = '';

    constructor(private router: Router, private loginService: LoginService,private domSanitizer:DomSanitizer,private uploadService:UploadService) {
        this.loginUserName = localStorage.getItem("name");
        this.email = localStorage.getItem("email");
        this.mobile = localStorage.getItem("mobile");
        this.isRole = localStorage.getItem("id_role");
        if(this.isRole == '5'){
            console.log("coe_roll_number="+localStorage.getItem("coe_roll_number"));
            this.getScholarDocuments(localStorage.getItem("coe_roll_number")); 
            this.coe_roll_no =  localStorage.getItem("coe_roll_number");
            this.dakshana_roll_no =  localStorage.getItem("dakshana_roll_number");    
            this.batch =  localStorage.getItem("batch_name");    
            this.coe =  localStorage.getItem("coe_name");    
            this.section =  localStorage.getItem("section_name");    
            this.address =  localStorage.getItem("address");    
        }
        //("role=" + this.isRole)
    }

    logout() {
        this.loginService.logout().subscribe(
            (data: any) => {
                this.showLoader = false;
                if (data["status_code"] == 0) {
                    // localStorage.setItem("isLogin", "false");
                    // localStorage.removeItem("id_section_scholar");
                    localStorage.clear();
                    this.router.navigateByUrl('/');
                }
                else {
                    swal.fire(data["status_message"]);
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }

    navigate(link) {
        //link)
        this.selectedLink = link;
        var check = false
        var privousAttandanceData = []
        var currentAttandanceData;
        if (link != "Dashboard") {
            localStorage.removeItem("id_section")
            localStorage.removeItem("id_coe")
            localStorage.removeItem("selected_year")
            localStorage.removeItem("selected_month")
        }

        privousAttandanceData = JSON.parse(localStorage.getItem("previous_attandance_data"));
        currentAttandanceData = JSON.parse(localStorage.getItem("current_attandance_data"));
        if (privousAttandanceData != null && currentAttandanceData != null) {
            for (let previousData of privousAttandanceData) {
                for (let currentData of currentAttandanceData) {
                    if (previousData.attendance_status != currentData.attendance_status) {
                        check = true
                    }
                }
            }

        }

        if (check == true) {
            swal.fire({
                title: '',
                text: "Going back will discard your recent changes. Are you sure you want to go back?",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#8B0000',
                cancelButtonColor: '#428e63',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.value) {
                    localStorage.removeItem("previous_attandance_data")
                    localStorage.removeItem("current_attandance_data")
                    this.router.navigateByUrl('/' + link);
                }
            })
        }
        else {
            localStorage.removeItem("previous_attandance_data")
            localStorage.removeItem("current_attandance_data")
            if (link == "Feedback") {
                if (this.isRole == "1") {
                    this.router.navigateByUrl('/admin-faculty-feedback');
                }
                else if (this.isRole == "5") {
                    this.router.navigateByUrl('/scholar-faculty-feedback');
                }


            }
            else if (link == "Dashboard") {
                if (this.isRole == "1") {
                    this.router.navigateByUrl('/Dashboard');
                }
                else if (this.isRole == "2") {
                    this.router.navigateByUrl('/Coordinator-Dashboard');
                }
                else if (this.isRole == "3") {
                    this.router.navigateByUrl('/Management-Dashboard');
                }
                else if (this.isRole == "4") {
                    this.router.navigateByUrl('/Faculty-Dashboard');
                }
                else if (this.isRole == "5") {
                    this.router.navigateByUrl('/Scholar-Dashboard');
                }
                else if (this.isRole == "6") {
                    this.router.navigateByUrl('/question bank');
                }
            }
            else if (link == "Analysis") {
                if (this.isRole == "1") {
                    this.router.navigateByUrl('/admin-anaylsis');
                }
                else if (this.isRole == "2") {
                    this.router.navigateByUrl('/admin-anaylsis');
                }
                else if (this.isRole == "3") {
                    this.router.navigateByUrl('/admin-anaylsis');
                }
                else if (this.isRole == "4") {
                    this.router.navigateByUrl('/admin-anaylsis');
                }

                else if (this.isRole == "5") {
                    this.router.navigateByUrl('/scholar-analysis');
                }
            }
            else if (link == "Question Review") {
                this.router.navigateByUrl('/question bank');
            }
            else if (link == "Error Report") {
                this.router.navigateByUrl('/Error Report');
            }
            else if (link == "Online Exam") {
                var idUser = btoa(localStorage.getItem('id_user'));
                var authToken = btoa(localStorage.getItem('token'));
                var idUserKey = md5("id_user_key");
                var tokenKey = md5("auth_token_key");
                let url = Helper.examsURL + "?" + idUserKey + "=" + idUser + "&" + tokenKey + "=" + authToken;
                let width = "width=" + window.innerWidth
                let height = "height=" + window.innerHeight
                let frame = width + height
                window.open(url);
            }
            else if (link == "s") {

            }
            else if (link == "Candidate Response") {
                if (this.isRole == "1") {
                    this.router.navigateByUrl('/candidate-response');
                }
            }
            else {
                this.router.navigateByUrl('/' + link);
            }

        }
    }
    showMyProfile() {
        if (this.showProfile == false) {
            this.showProfile = true
        }
        else {
            this.showProfile = false
        }

    }

    updateNewPassword() {
        this.isUpdatePassword = true;
        this.generatePassword();
    }

    hideUpdatePasswordModal() {
        this.isUpdatePassword = false;
    }

    generatePassword() {
        this.showLoader = true;
        this.loginService.generateOtp(this.email).subscribe(
            data => {
                this.showLoader = false;
                if (data.status_code == 0) {
                    swal.fire(data["status_message"]);
                }
                else {
                    localStorage.setItem("isLogin", "false");
                    swal.fire(data["status_message"]);
                }
            },
            err => {
                this.showLoader = false;
                swal.fire("Something went wrong. Please try again later.")
            }
        )
    }

    updatePassword() {
        if (!this.otp) {
            swal.fire("Please enter OTP.");
        }
        else if (!this.newPassword) {
            swal.fire("Please enter new password.");
        }
        else if (!this.confirmPassword) {
            swal.fire("Please confirm password.");
        }
        else if (this.confirmPassword != this.newPassword) {
            swal.fire("Password does not match.");
        }
        else {
            let encruptPassword = md5(this.newPassword);
            this.showLoader = true;
            this.loginService.verifyUserOtp(this.otp, localStorage.getItem("id_user"), encruptPassword, this.email).subscribe(
                data => {
                    this.showLoader = false;
                    if (data.status_code == 0) {
                        // let userData = data["user_data"][0];
                        // localStorage.setItem("isLogin", "true");
                        // localStorage.setItem("id_user", userData["id_user"]);
                        // localStorage.setItem("name", userData["name"]);
                        // localStorage.setItem("mobile", userData["mobile"]);
                        // localStorage.setItem("email", userData["email"]);
                        // localStorage.setItem("id_role", userData["id_role"]);
                        // localStorage.setItem("role", userData["role"]);
                        // localStorage.setItem("id_section_scholar", data.id_section);
                        // localStorage.setItem("token", userData["token"]);
                        this.hideUpdatePasswordModal();
                        swal.fire(data.status_message)
                        localStorage.clear()
                        this.router.navigateByUrl('/');
                    }
                    else {
                        localStorage.setItem("isLogin", "false");
                        swal.fire(data["status_message"]);
                    }
                },
                err => {
                    this.showLoader = false;
                    swal.fire("Something went wrong. Please try again later.")
                }
            )
        }
    }

    getScholarDocuments(path) {
        this.showLoader = true;
       
        var that = this
        this.uploadService.getFile(path).then(function (data: any) {
            var objectsArray = [];
            var imgStr ={'img':''};
            for (let file of data) {
                let documentName = file.Key.split('/').pop().split('.')[0]
                that.uploadService.getDocument(file).then(
                    function (fileObject: any) {
                        var data = fileObject
                        var json = { 'document_type': documentName }
                        if(documentName == 'Scholar Photo'){
                            var str = data.Body.reduce(function (a, b) { return a + String.fromCharCode(b) }, '');
                            json['string_images'] = str
                            if (data.ContentType != "application/pdf") {
                                that.imageString = that.getImage(btoa(str).replace(/.{76}(?=.)/g, '$&\n'))
                            }
                            else {
                                that.imageString = that.getPDF(btoa(str).replace(/.{76}(?=.)/g, '$&\n'))
                            }
    
                             console.log("photo_url="+that.imageString);                          
    
                        }
                       
                    }
                );
            }
    
            // that.imageString = imgStr['img'];
            that.showLoader = false;
    
        });
    }


    getImage(imageData) {
        var image;
        image = 'data:image/png;base64,' + imageData;
        image = this.domSanitizer.bypassSecurityTrustResourceUrl(image);
        return image;
    }
    public getPDF(data) {
        var image = 'data:application/pdf;base64,' + data;
        return image;
    }
    
    getScholarPhoto(){
        return this.imageString;
    }
}
