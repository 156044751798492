import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-online-test-masters',
  templateUrl: './online-test-masters.component.html',
  styleUrls: ['./online-test-masters.component.css']
})
export class OnlineTestMastersComponent implements OnInit {
  Buttons = []
  showLoader = false;
  showAlert = false;
  activeButtonId: any;
  constructor(private router: Router) {
    if (localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "1") {
      this.generateButtons();
      this.activeButtonId = "1"
    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  generateButtons() {
    var jsonOne = {
      "id": "1",
      "name": "Exam Pattern",
    }
    this.Buttons.push(jsonOne)
    var jsonOne = {
      "id": "2",
      "name": "Question Source",
    }
    this.Buttons.push(jsonOne)
    var jsonOne = {
      "id": "3",
      "name": "Difficulty Level",
    }
    this.Buttons.push(jsonOne)
    var jsonOne = {
      "id": "4",
      "name": "Question Interpretation",
    }
    this.Buttons.push(jsonOne)
    var jsonOne = {
      "id": "5",
      "name": "Time For Question",
    }
    this.Buttons.push(jsonOne)
    var jsonOne = {
      "id": "6",
      "name": "Question Pattern",
    }
    this.Buttons.push(jsonOne)
    var jsonOne = {
      "id": "7",
      "name": "Review Requirement",
    }
    this.Buttons.push(jsonOne)

  }

  isButtonActive(id) {
    if (this.activeButtonId == id) {
      return true
    }
    else {
      return false;
    }
  }

  selectButton(id) {
    this.activeButtonId = id
  }
}
