import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class AttendanceMasterService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }


    getAllMasterDataForAttendence(email) {
        return this.http.get(this.baseUrl + "/Attendance/getAllMasterDataForAttendance?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }
    getAllAttendence(email, sectionId, date, attendanceFor, todayDate) {
        return this.http.get(this.baseUrl + "/Attendance/getAllAttendanceByDate?caller_email=" + email + "&id_section=" + sectionId + "&date=" + date + "&attendance_for=" + attendanceFor + "&today_date=" + todayDate).map((res) => JSON.parse(JSON.stringify(res)));
    }

    saveAttandance(jsonData) {
        //.log(JSON.stringify(jsonData));
        return this.http.post(this.baseUrl + "Attendance/saveAttendance", jsonData, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllAttendenceByScholarId(email, scholarId, date, attendanceFor) {
        return this.http.get(this.baseUrl + "/Attendance/getAllAttendenceByScholarId?caller_email=" + email + "&id_scholar=" + scholarId + "&date=" + date + "&attendance_for=" + attendanceFor).map((res) => JSON.parse(JSON.stringify(res)));
    }
}