import { Component, OnInit } from '@angular/core';
import { FacultyFeedbackRequestService } from './faculty-feedback.service'
import { Router } from '@angular/router'
import { DatePipe } from '@angular/common'
@Component({
  selector: 'app-faculty-feedback',
  templateUrl: './faculty-feedback.component.html',
  styleUrls: ['./faculty-feedback.component.css'],
  providers: [FacultyFeedbackRequestService, DatePipe]
})
export class FacultyFeedbackComponent implements OnInit {
  sucessAlertMessage: any;
  alertDiv = false;
  showLoader = false;
  FeedbackRequest = [];
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  searchText: any;
  constructor(private router: Router, private facultyFeedbackRequestService: FacultyFeedbackRequestService, private datePipe: DatePipe) {
    if (localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "1") {
      this.getAllFeedbackRequest();

    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  getAllFeedbackRequest() {
    this.showLoader = true;
    this.facultyFeedbackRequestService.getAllFacultyFeedback(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.FeedbackRequest = data.feedback_request
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
      }
    )
  }

  showAlertDiv(message) {
    var that = this
    that.alertDiv = true
    this.sucessAlertMessage = message
    setTimeout(function () {
      that.alertDiv = false
    }, 5000);
  }
  requestFeedback(data) {
    this.showLoader = true;
    this.facultyFeedbackRequestService.requestFacultyFeedback(localStorage.getItem("email"), data.id_faculty).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.FeedbackRequest = data.feedback_request
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
      }
    )
  }

  checkRequestDate(data) {
    if (data.request_date != "") {
      var today = new Date();
      var todayDate = today.toISOString().split('T')[0];
      var requestedDate = this.datePipe.transform(data.request_date, 'yyyy-MM-dd');
      let requestedDateFormet = new Date(requestedDate);
      requestedDateFormet.setDate(requestedDateFormet.getDate() + 30);
      //requestedDateFormet)
      //today)
      if (today <= requestedDateFormet) {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return true;
    }

  }

  viewFeedback(data) {
    this.router.navigate(['./feedback-history'], { queryParams: { facultyId: data.id_faculty, facultyFedbackRequestId: data.id_faculty_feedback_request, facultyName: data.name } });

  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    //("status" + status);
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }
}
