import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { AddSyllabusMasterService } from './add-syllabus-master.service'
import { Helper } from '../helper'
import swal from 'sweetalert2'
@Component({
  selector: 'app-add-syllabus-master',
  templateUrl: './add-syllabus-master.component.html',
  styleUrls: ['./add-syllabus-master.component.css'],
  providers: [AddSyllabusMasterService, Helper]
})
export class AddSyllabusMasterComponent implements OnInit {
  Courses = [];
  courseTypeId: any;
  showLoader = false;
  Subjects = []
  SubSubjects = [];
  unfilteredSubSubjects = [];
  Topics = [];
  unfilteredTopics = [];
  SyllabusRows = [];
  Syllabus = [];
  isAddModal = false;
  syllabusName: any;
  purpose = "Add";
  tempData: any;
  Rows = [];
  numberOfRows: any;
  syllabusId: any;
  totalHours: any;
  fromIndex: number;
  searchText: any;
  alertDiv = false;
  sucessAlertMessage: any;
  NewTopics = [];
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  UnFilterdSubjects = [];
  isDeleted = false;
  isChangeDuration = false
  tempTopicData: any;
  topicDuration: any;
  column: string = 'CategoryName';
  direction: number;
  isDesc: boolean = false;
  constructor(private helper: Helper, private addSyllabusMasterService: AddSyllabusMasterService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.courseTypeId = "0"
    this.Rows = this.helper.getRowsValue();
    this.numberOfRows = this.Rows[0];
    this.activatedRoute.queryParams.subscribe(params => {
      this.purpose = params['purpose'];
      if (this.purpose == "Edit") {
        this.tempData = JSON.parse(localStorage.getItem("syllabus_topic"))
        this.syllabusId = this.tempData.id_master_syllabus;
        this.syllabusName = this.tempData.syllabus_name;
        this.courseTypeId = this.tempData.id_course_type;
        this.Syllabus = this.tempData.topics
        this.totalHours = 0.0;
        var totalMinutes = 0;
        for (let data of this.Syllabus) {
          let temp = data.hours.split(".");
          let hour = temp[0];
          let minutes = temp[1];
          let tempTotalMinutes = ((+hour) * 60) + (+minutes);
          totalMinutes += tempTotalMinutes

        }
        var hours = Math.floor(totalMinutes / 60);
        var tempminutes = totalMinutes % 60;
        this.totalHours = hours + "." + tempminutes
      }


    });
    localStorage.removeItem('syllabus_topic');
    this.getAllCourseType();
  }

  getAllCourseType() {
    this.showLoader = true
    this.addSyllabusMasterService.getAllCourse(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.Courses = data.courses
          this.getAllMasterDataForExam();
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false
      }
    )
  }

  getAllMasterDataForExam() {
    this.showLoader = true
    this.addSyllabusMasterService.getAllExamMasterData(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false
        if (data.status_code == "0") {
          this.UnFilterdSubjects = data.subjects;

          this.unfilteredSubSubjects = data.sub_subjects
          this.unfilteredTopics = data.topics
          for (var i = 0; i < this.Rows[0]; i++) {
            let json = {
              'id_subject': "0",
              'id_sub_subject': "0",
              'id_topic': "0",
              'hours': "",
              "subjects": this.Subjects,
              "sub_subjects": this.unfilteredSubSubjects,
              "topics": this.unfilteredTopics
            }
            this.SyllabusRows.push(json)
          }
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false
      }
    )
  }
  ngOnInit() {
  }

  getSubSubject(data) {
    data.sub_subjects = []
    data.topics = []
    for (let subSubject of this.unfilteredSubSubjects) {
      if (subSubject.id_subject == data.id_subject) {
        data.sub_subjects.push(subSubject)
      }
    }
    for (let topic of this.NewTopics) {
      if (topic.id_subject == data.id_subject) {
        data.topics.push(topic)
      }
    }
  }

  getTopics(data) {
    data.topics = []
    for (let topic of this.NewTopics) {
      if (topic.id_sub_subject == data.id_sub_subject) {
        data.topics.push(topic)
      }
    }
  }
  getSelectedSubject(subjectId) {
    //(subjectId);
  }
  addNewRows() {
    if (this.courseTypeId == "0") {
      swal.fire("Please select course type.")
    }
    else {
      var selectedCourse: any
      if (this.courseTypeId == "1" || this.courseTypeId == "3" || this.courseTypeId == "4") {
        selectedCourse = "1"
      }
      else {
        selectedCourse = "2"
      }
      for (let data of this.UnFilterdSubjects) {
        for (let course of data.courses) {
          if (course.id_course_type == selectedCourse) {
            this.Subjects.push(data)
          }
        }
      }
      this.fromIndex = null;
      this.isAddModal = true;
      this.SyllabusRows = [];
      this.NewTopics = [];
      if (this.purpose == "Edit") {
        if (this.Syllabus.length > 0) {
          for (let data of this.unfilteredTopics) {
            var check = false
            for (let topic of this.Syllabus) {
              if (data.id_topic == topic.id_topic) {
                check = false;
                break;
              }
              else {
                check = true;
              }
            }
            if (check == true) {
              this.NewTopics.push(data);
            }
          }
        }
        else {
          this.NewTopics = this.unfilteredTopics;
        }
      }
      else {
        this.NewTopics = this.unfilteredTopics;
      }
      //("topics" + JSON.stringify(this.NewTopics));
      for (var i = 0; i < this.Rows[0]; i++) {
        let json = {
          'id_subject': "0",
          'id_sub_subject': "0",
          'id_topic': "0",
          'hours': "",
          "subjects": this.Subjects,
          "sub_subjects": this.unfilteredSubSubjects,
          "topics": this.NewTopics
        }
        this.SyllabusRows.push(json)
      }
      var totalMinutes = 0;
      for (let data of this.Syllabus) {
        let temp = data.hours.split(".");
        let hour = temp[0];
        let minutes = temp[1];
        let tempTotalMinutes = ((+hour) * 60) + (+minutes);
        totalMinutes += tempTotalMinutes

      }
      var hours = Math.floor(totalMinutes / 60);
      var tempminutes = totalMinutes % 60;
      this.totalHours = hours + "." + tempminutes
    }

  }
  hideAddEditModal() {
    this.isAddModal = false;
    this.isChangeDuration = false
  }
  saveData() {
    if (this.purpose == "Add") {
      // this.Syllabus = [];
    }
    var check = false;
    var message: any;
    for (let data of this.SyllabusRows) {
      if ((data.id_subject == "0" || data.id_sub_subject == "0" || data.id_topic == "0" || data.hours == "")) {
        check = true
        message = "Some fields are empty please fill it first."
        break
      }
      else {
        let hours = data.hours
        //(hours)
        let x = hours.split(".");
        let decimalRightValue = x[1];
        if (!decimalRightValue) {
          decimalRightValue = "00"
        }
        if (parseFloat(decimalRightValue) > 59) {
          check = true
          message = "Please enter a valid fraction of an hour. Fractions are allowed only till .59. Every .01 means 1 minute from an hour."
          break
        }
      }
    }
    if (check == true) {
      swal.fire(message)
    }
    else {
      check = false;
      //(this.SyllabusRows);
      for (var i = 0; i < this.SyllabusRows.length; i++) {
        for (var j = i + 1; j < this.SyllabusRows.length; j++) {
          if (this.SyllabusRows[i].id_topic == this.SyllabusRows[j].id_topic) {
            check = true
            break
          }
        }
      }
      if (check == true) {
        swal.fire("Topic already present in syllabus.")
      }
      else {
        check = false;
        for (let data of this.Syllabus) {
          for (let row of this.SyllabusRows) {
            if (data.id_topic == row.id_topic) {
              check = true
              break
            }
          }
        }
        if (check == true) {
          swal.fire("Topic already present in syllabus.")
        }
        else {
          let index = 0;
          for (let row of this.SyllabusRows) {
            index = index + 1
            for (let data of row.topics) {

              if (row.id_topic == data.id_topic) {
                let json = {
                  "id_topic": data.id_topic,
                  "topic_name": data.name,
                  "id_sub_subject": data.id_sub_subject,
                  "sub_subject_name": data.sub_subject_name,
                  "id_subject": data.id_subject,
                  "subject_name": data.subject_name,
                  "hours": row.hours,
                }
                //("value of index" + this.fromIndex);
                if (this.fromIndex != null) {
                  //(this.fromIndex)
                  this.Syllabus.splice(this.fromIndex + index, 0, json);
                }
                else {
                  //("under else")
                  this.Syllabus.push(json)
                }
              }
            }
          }
          this.SyllabusRows = [];
          //(this.Syllabus);
          this.hideAddEditModal();
        }
      }

    }
  }

  removeRow(index) {

    this.SyllabusRows.splice(index, 1);
  }

  saveSyllabus() {
    if (!this.syllabusName) {
      swal.fire("Please enter syllabus name.")
    }
    else if (this.courseTypeId == "0") {
      swal.fire("Please select course type.")
    }
    else if (this.Syllabus.length == 0) {
      swal.fire("Please create at least one syllabus.")
    }
    else {

      if (this.purpose == "Add") {
        let json = {
          "caller_email": localStorage.getItem("email"),
          "syllabus_name": this.syllabusName,
          "id_course_type": this.courseTypeId,
          "topics": JSON.stringify(this.Syllabus)
        }
        this.showLoader = true
        this.addSyllabusMasterService.addSyllabus(json).subscribe(
          data => {
            if (data.status_code == "0") {
              this.showLoader = false;
              this.back();
            }
            else {
              this.showLoader = false
              swal.fire(data.status_message)
            }

          },
          err => {
            this.showLoader = false
          }
        )
      }
      else {
        let json = {
          "id_master_syllabus": this.syllabusId,
          "caller_email": localStorage.getItem("email"),
          "syllabus_name": this.syllabusName,
          "id_course_type": this.courseTypeId,
          "topics": JSON.stringify(this.Syllabus)
        }
        this.showLoader = true
        this.addSyllabusMasterService.editSyllabus(json).subscribe(
          data => {
            if (data.status_code == "0") {
              this.showLoader = false
              let tempSyllabus = data.syllabus[0];
              this.syllabusName = tempSyllabus.syllabus_name
              this.courseTypeId = tempSyllabus.id_course_type
              this.Syllabus = tempSyllabus.topics;
              this.totalHours = 0;
              var totalMinutes = 0;
              for (let data of this.Syllabus) {
                let temp = data.hours.split(".");
                let hour = temp[0];
                let minutes = temp[1];
                let tempTotalMinutes = ((+hour) * 60) + (+minutes);
                totalMinutes += tempTotalMinutes

              }
              var hours = Math.floor(totalMinutes / 60);
              var tempminutes = totalMinutes % 60;
              this.totalHours = hours + "." + tempminutes
              this.back();
            }
            else {
              this.showLoader = false
              swal.fire(data.status_message)
            }

          },
          err => {
            this.showLoader = false
          }
        )
      }

    }
  }
  back() {
    this.router.navigate(['./syllabus master'], { queryParams: { purpose: 'Add' } });
  }

  showTopics() {

  }

  generateRows() {
    this.SyllabusRows = []
    this.NewTopics = [];
    if (this.Syllabus.length > 0) {
      for (let data of this.unfilteredTopics) {
        var check = false
        for (let topic of this.Syllabus) {
          if (data.id_topic == topic.id_topic) {
            check = false;
            break;
          }
          else {
            check = true;
          }
        }
        if (check == true) {
          this.NewTopics.push(data);
        }
      }
    }
    else {
      this.NewTopics = this.unfilteredTopics;
    }
    for (var i = 0; i < this.numberOfRows; i++) {
      let json = {
        'id_subject': "0",
        'id_sub_subject': "0",
        'id_topic': "0",
        'hours': "",
        "subjects": this.Subjects,
        "sub_subjects": this.unfilteredSubSubjects,
        "topics": this.NewTopics
      }
      this.SyllabusRows.push(json)
    }
  }
  onUp(index) {
    this.shuffleRow(index, index - 1);
  }
  onDown(index) {
    this.shuffleRow(index, index + 1);
  }

  shuffleRow(from, to) {
    var temp = this.Syllabus[from];
    this.Syllabus[from] = this.Syllabus[to];
    this.Syllabus[to] = temp;
  }

  remove(index) {
    this.isDeleted = true
    this.Syllabus.splice(index, 1);
    var totalMinutes = 0;
    for (let data of this.Syllabus) {
      let temp = data.hours.split(".");
      let hour = temp[0];
      let minutes = temp[1];
      let tempTotalMinutes = ((+hour) * 60) + (+minutes);
      totalMinutes += tempTotalMinutes

    }
    var hours = Math.floor(totalMinutes / 60);
    var tempminutes = totalMinutes % 60;
    this.totalHours = hours + "." + tempminutes
  }

  addMoreInMiddle(index) {
    this.fromIndex = index;
    //("from-index" + this.fromIndex)
    this.isAddModal = true;
    this.SyllabusRows = []
    this.NewTopics = [];
    var selectedCourse: any
    if (this.courseTypeId == "1" || this.courseTypeId == "3" || this.courseTypeId == "4") {
      selectedCourse = "1"
    }
    else {
      selectedCourse = "2"
    }
    if (this.Syllabus.length > 0) {
      for (let data of this.UnFilterdSubjects) {
        for (let course of data.courses) {
          if (course.id_course_type == selectedCourse) {
            this.Subjects.push(data)
          }
        }
      }
      for (let data of this.unfilteredTopics) {
        var check = false
        for (let topic of this.Syllabus) {
          if (data.id_topic == topic.id_topic) {
            check = false;
            break;
          }
          else {
            check = true;
          }
        }
        if (check == true) {
          this.NewTopics.push(data);
        }
      }
    }
    else {
      this.NewTopics = this.unfilteredTopics;
    }
    for (var i = 0; i < this.Rows[0]; i++) {
      let json = {
        'id_subject': "0",
        'id_sub_subject': "0",
        'id_topic': "0",
        'hours': "",
        "subjects": this.Subjects,
        "sub_subjects": this.unfilteredSubSubjects,
        "topics": this.NewTopics
      }
      this.SyllabusRows.push(json)
    }
    var totalMinutes = 0;
    for (let data of this.Syllabus) {
      let temp = data.hours.split(".");
      let hour = temp[0];
      let minutes = temp[1];
      let tempTotalMinutes = ((+hour) * 60) + (+minutes);
      totalMinutes += tempTotalMinutes

    }
    var hours = Math.floor(totalMinutes / 60);
    var tempminutes = totalMinutes % 60;
    this.totalHours = hours + "." + tempminutes
  }

  showAlertDiv(message) {
    var that = this
    that.alertDiv = true
    this.sucessAlertMessage = message
    setTimeout(function () {
      that.alertDiv = false
    }, 5000);
  }
  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    //("status" + status);
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }

  changeDurationOfTopic(data) {
    this.tempTopicData = data;
    this.topicDuration = data.hours
    this.isChangeDuration = true
  }

  saveTopicDuration() {
    if (!this.topicDuration) {
      swal.fire("Please enter duration")
    }
    else {
      let hours = this.topicDuration
      //(hours)
      let x = hours.split(".");
      let decimalRightValue = x[1];
      if (!decimalRightValue) {
        decimalRightValue = "00"
      }
      if (parseFloat(decimalRightValue) > 59 || decimalRightValue.length != 2) {
        swal.fire("Please enter a valid fraction of an hour. Fractions are allowed only till .59. Every .01 means 1 minute from an hour.");
      }
      else {
        for (let data of this.Syllabus) {
          if (data.id_topic == this.tempTopicData.id_topic) {
            data.hours = this.topicDuration;
            break;
          }
        }
        this.isChangeDuration = false
      }
    }

  }
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.Syllabus.sort(function (a, b) {
        if (a[property] < b[property]) {
            return -1 * direction;
        }
        else if (a[property] > b[property]) {
            return 1 * direction;
        }
        else {
            return 0;
        }
    });
}
}
