import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
@Injectable()
export class SyllabusMasterService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    private data = new BehaviorSubject<any>(null);
    currentData = this.data.asObservable();
    
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }

    sendData(data: any) {
        this.data.next(data)
    }


    getAllSyllabus(email) {
        return this.http.get(this.baseUrl + "/Syllabus/getAllSyllabus?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeSyllabus(email,syllabusId) {
        return this.http.get(this.baseUrl + "/Syllabus/removeSyllabus?caller_email=" + email+"&id_master_syllabus="+syllabusId).map((res) => JSON.parse(JSON.stringify(res)));
    }

   
}