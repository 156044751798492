import { Component, OnInit } from '@angular/core';
import { DailyAttendanceReportService } from './daily-attendance-report.service'
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { DatePipe } from '@angular/common'
@Component({
    selector: 'daily-attendance-report',
    templateUrl: 'daily-attendance-report.component.html',
    styleUrls: ['daily-attendance-report.component.css'],
    providers:[DailyAttendanceReportService,DatePipe]
})
export class DailyAttendanceReportComponent {
    showLoader = false;
    Coes = [];
    selectedDate:any;
    AttendanceReport = [];
    minimumTodate: any;
    showAlert: boolean;
    alertMsg: any;
    alertType = "";
    column: string = 'CategoryName';
    direction: number;
    isDesc: boolean = false;
    isModal=false;
    studentArray=[];
    constructor(private datePipe:DatePipe,private router: Router, private dailyAttendanceReportService:DailyAttendanceReportService ){
        this.selectedDate = new Date();
    this.selectedDate = this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd');
        if (localStorage.getItem("isLogin") == "true") {
           this.apply();
          }
          else {
            this.router.navigateByUrl('/');
          }
    }
    ngOnInit() {
    }
    openCloseModal(data){
        if(!data){
            return false;
        }
        this.studentArray=data;
        this.isModal=true;
    }
    closeModal(){
        this.isModal=false;
    }
  
    // getMasterData() {
    //   this.showLoader = true;
    //   this.dailyAttendanceReportService.getAllMasterData(localStorage.getItem("email")).subscribe(
    //     data => {
    //       this.showLoader = false
    //       if (data.status_code == "0") {
    //         this.Coes = data.coes;
  
    //       }
    //       else {
    //       }
    //     },
    //     err => {
    //       this.showLoader = false
    //     }
    //   );
    // }
  
    apply() {
     if(!this.selectedDate) {
        swal.fire("Please select  date.")
      }
      else {
        this.showLoader = true;
        this.dailyAttendanceReportService.getDailyAttendanceReportByCoeForClassAndHostel(localStorage.getItem("email"), this.selectedDate).subscribe(
          data => {
            this.showLoader = false
            if (data.status_code == "0") {
              this.AttendanceReport = data.attendance_report
  
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false
          }
        );
      }
  
    }
  
    getClassAttendance(absent, present) {
      if (present == "0") {
        return 0 + "%"
      }
      else {
        var presentPercentage = (((+present) * 100) / ((+absent) + (+present))).toFixed(2);
        return presentPercentage + "%"
      }
  
    }
  
    getHostelAttendance(absent, present) {
      if (present == 0) {
        return 0 + "%"
      }
      else {
        var presentPercentage = (((+present) * 100) / ((+absent) + (+present))).toFixed(2);
        return presentPercentage + "%"
      }
  
    }
  
    print() {
      let printContents, popupWin;
  
      printContents = document.getElementById('printSection').innerHTML;
      var htmlToPrint = '' +
        '<style type="text/css">' +
        'table th, table td {' +
        'border:1px solid #000;' +
        'padding;0.5em;' +
        '}' +
        '</style>';
      htmlToPrint += printContents;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
            <html>
                <head>
                   
                    <style>
                        //........Customized style.......
                    </style>
                </head>
                <body onload="window.print();window.close()">${htmlToPrint}
  
                </body>
            </html>`
      );
      popupWin.document.close();
    }
  
    onBack() {
      this.router.navigateByUrl('/Reports')
    }
  
   
  
    showAlertComponent(status, messege) {
      this.showAlert = true;
      this.alertMsg = messege;
      if (parseInt(status) == 0) {
        this.alertType = "success";
      }
      else {
        this.alertType = "failure";
      }
    }
    hideAlert(val) {
      this.showAlert = val;
    }
    sort(property) {
      this.isDesc = !this.isDesc; //change the direction    
      this.column = property;
      let direction = this.isDesc ? 1 : -1;
  
      this.AttendanceReport.sort(function (a, b) {
        if (a[property] < b[property]) {
          return -1 * direction;
        }
        else if (a[property] > b[property]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      });
    }
}
