import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-book-master',
  templateUrl: './book-master.component.html',
  styleUrls: ['./book-master.component.css']
})
export class BookMasterComponent implements OnInit {
  selectedTab = 'Book Types'
  constructor(private router: Router) { 
    if(localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "1" || localStorage.getItem("isLogin") == "true" && localStorage.getItem("id_role") == "2") {
      this.selectedTab
    }
    else{
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {

  }

  selectTab(tab) {
    this.selectedTab = tab
  }

}
