import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import swal from 'sweetalert2';
import { MatrixTypeQuestionService } from './matrixtype-question.service'
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
  selector: 'matrixtype-question',
  templateUrl: './matrixtype-question.component.html',
  styleUrls: ['./matrixtype-question.component.css'],
  providers: [MatrixTypeQuestionService]
})
export class MatrixtypeQuestionComponent implements OnInit {
  showLoader = false;
  @Output() isHidden = new EventEmitter<string>();
  @Input() Subjects = [];
  @Input() subjectId: any;
  SubSubjects = [];
  @Input() UnFiltterdSubSubjects = [];
  @Input() subSubjectId: any;
  Topics = [];
  @Input() topicId: any;
  @Input() UnfiltterdTopics = [];
  SubTopics = [];
  @Input() UnfilttersSubTopics = [];
  @Input() subTopicId: any;
  idCourseType = 14;
  orignalQuestionSources = [];
  @Input() QuestionSources = [];
  @Input() questionSourceId = "0";
  @Input() DifficultyLevels: any;
  @Input() difficultyLevelId: any;
  @Input() QuestionTypes = [];
  @Input() questionTypeId: any;
  @Input() ReviewRequirements = [];
  @Input() reviewRequirementId: any;
  base64textQuestionString = "";
  questionImage: any;
  sollutionText = "";
  questionText = "";
  ColumnOne = [];
  isAddQueestionOptionModal = false
  isCorrect = false;
  columnName: any;
  searchText: any;
  columnValue: any;
  questionOptionImage: any;
  base64textQuestionOtionString = "";
  constructor(private domSanitizer: DomSanitizer, private matrixTypeQuestionService: MatrixTypeQuestionService) { }
  purposeOfQuestionOptionModal = "Add"
  questionOptionIndex: any;
  @Input() previousData: any;
  questionId: any
  showAlert = false;
  alertMsg: any;
  alertType: any;
  columnFor: any;
  columnSecond = [];
  MatrixMatchingAnswerTable = [];
  CheckedArray = [];
  roleId: any;
  @Input() QuestionInterpretation = [];
  questionInterpretationId: any;
  @Input() TimeOfQuestion = [];
  timeOfQuestionId: any;
  isAddRejectionCommentModal = false;
  rejectComment: any;
  @Input() tabId: any;
  questionStatusId: any;
  purpose = "Add";
  imageType: any;
  base64textSolutionString = "";
  solutionImage: any;
  questionCode: any;
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'id_question_source',
    textField: 'name',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    closeDropDownOnSelection: true
  };
  selectedPatternItems = [];
  firstTime = true;  
  ngOnInit() {

    this.orignalQuestionSources = this.QuestionSources;

    console.log("questionSourceID="+this.questionSourceId);

    this.generateColumnOne();
    this.generateColumnSecond();
    this.roleId = localStorage.getItem("id_role");
    this.timeOfQuestionId = "0";
    this.questionInterpretationId = "0";
    this.questionStatusId = "12"
    if (this.previousData) {
      this.purpose = "Edit"
      this.questionId = this.previousData.id_question;
      this.questionText = this.previousData.question_text;
      this.questionImage = this.getImage(this.previousData.question_image);
      this.base64textQuestionString = this.previousData.question_image
      this.solutionImage = this.getImage(this.previousData.solution_image);
      this.base64textSolutionString = this.previousData.solution_image
      this.sollutionText = this.previousData.sollution_text;
      this.ColumnOne = this.previousData.questionoptions;
      this.subjectId = this.previousData.id_subject;
      this.filterSuSuject();
      this.subSubjectId = this.previousData.id_sub_subject;
      this.filterTopics();
      this.topicId = this.previousData.id_topic;
      this.filterSubTopics();
      this.subTopicId = this.previousData.id_sub_topic;
      this.questionCode = this.previousData.question_code;

      this.questionSourceId = this.previousData.id_question_source;
      this.QuestionSources.forEach(item => {
        if (item.id_question_source == this.questionSourceId) {
          this.selectedPatternItems.push({ item_id: this.questionSourceId, name: item.name });
        }
      });

      this.questionStatusId = this.previousData.is_approved;
      if (this.previousData.id_question_interpretation == null) {
        this.questionInterpretationId = "0"
      }
      else {
        this.questionInterpretationId = this.previousData.id_question_interpretation
      }
      if (this.previousData.id_time_for_question == null) {
        this.timeOfQuestionId = "0"
      }
      else {
        this.timeOfQuestionId = this.previousData.id_time_for_question
      }
      if (this.previousData.id_difficult_level == null) {
        this.difficultyLevelId = "0";
      }
      else {
        this.difficultyLevelId = this.previousData.id_difficult_level;
      }
      if (this.previousData.id_review_requirement == null) {
        this.reviewRequirementId = "0";
      }
      else {
        this.reviewRequirementId = this.previousData.id_review_requirement;
      }

      this.questionTypeId = this.previousData.id_question_pattern;
      this.ColumnOne = this.previousData.column_one
      this.columnSecond = this.previousData.column_second
      this.generateMatrixMatchingAnswer();
      this.CheckedArray = this.previousData.matrix_match
      // this.filterSuSuject();
      // this.filterTopics();
      // this.filterSubTopics();
    }
    else {
      this.QuestionSources.forEach(item => {
        if (item.id_question_source == this.questionSourceId) {
          this.selectedPatternItems.push({ id_question_source: this.questionSourceId, name: item.name });
        }
      });

      this.filterSuSuject();
      this.filterTopics();
      this.filterSubTopics();
    }
  }

  public json = {
    plugins: "powerpaste imagetools",
    powerpaste_allow_local_images: true,
    readonly: 1,
    toolbar: "sizeselect | bold italic | fontselect |  fontsizeselect|charmap",
  }
  generateColumnOne() {
    this.ColumnOne = [];
    for (var i = 97; i < 101; i++) {
      let json = {
        "column_name": String.fromCharCode(i),
        "column_value": "",
      }
      this.ColumnOne.push(json)
    }
    this.generateMatrixMatchingAnswer();
  }
  generateColumnSecond() {
    this.columnSecond = [];
    for (var i = 112; i < 116; i++) {
      let json = {
        "column_name": String.fromCharCode(i),
        "column_value": "",
      }
      this.columnSecond.push(json)
    }
    this.generateMatrixMatchingAnswer();
  }
  filterSuSuject() {
    this.SubSubjects = [];
    for (let data of this.UnFiltterdSubSubjects) {
      if (this.subjectId == data.id_subject) {
        this.SubSubjects.push(data)
      }
    }
    this.subSubjectId = "0"
    this.topicId = "0"
    this.subTopicId = "0"
  }

  filterTopics() {
    this.Topics = [];
    for (let data of this.UnfiltterdTopics) {
      if (this.subSubjectId == data.id_sub_subject) {
        this.Topics.push(data)
      }
    }

    this.topicId = "0"
    this.subTopicId = "0"
  }
  filterSubTopics() {
    this.SubTopics = [];
    for (let data of this.UnfilttersSubTopics) {
      if (this.topicId == data.id_topic) {
        this.SubTopics.push(data)
      }
    }

    this.subTopicId = "0"
  }
  handleFileSelect(evt, image) {
    var files = evt.target.files;
    var file = files[0];
    this.imageType = image;
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {

    var binaryString = readerEvt.target.result;
    if (this.imageType == "question") {
      this.base64textQuestionString = btoa(binaryString);
      var image = 'data:image/png;base64,' + this.base64textQuestionString;
      this.questionImage = this.domSanitizer.bypassSecurityTrustUrl(image);
    }
    else {
      this.base64textSolutionString = btoa(binaryString);
      var image = 'data:image/png;base64,' + this.base64textSolutionString;
      this.solutionImage = this.domSanitizer.bypassSecurityTrustUrl(image);
    }

  }



  addNewQuestionOptions(value) {
    this.columnFor = value
    this.isAddQueestionOptionModal = true;
    this.purposeOfQuestionOptionModal = "Add";
    this.clearData();
  }

  handleOptionImageFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];

    if (files && file) {
      var reader = new FileReader();

      reader.onload = this._handleOptionImageReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  _handleOptionImageReaderLoaded(readerEvt) {

    var binaryString = readerEvt.target.result;
    this.base64textQuestionOtionString = btoa(binaryString);
    var image = 'data:image/png;base64,' + this.base64textQuestionOtionString;
    this.questionOptionImage = this.domSanitizer.bypassSecurityTrustUrl(image);
  }
  hideAddEditModal() {
    this.isAddQueestionOptionModal = false;
  }
  save() {
    if (!this.columnName) {
      swal.fire("Please enter column name.")
    }
    else if (!this.columnValue) {
      swal.fire("Please enter value.")
    }
    else {

      let json = {
        "column_name": this.columnName,
        "column_value": this.columnValue,
        "is_checked": "0",
      }
      if (this.purposeOfQuestionOptionModal == "Add") {
        if (this.columnFor == "1") {
          this.ColumnOne.push(json);
        }
        else {
          this.columnSecond.push(json);
        }


      }
      else {
        if (this.columnFor == "1") {
          this.ColumnOne[this.questionOptionIndex] = json
        }
        else {
          this.columnSecond[this.questionOptionIndex] = json
        }

      }
      this.generateMatrixMatchingAnswer();
      this.hideAddEditModal();
    }
  }
  getImage(imagString) {
    var image: any;
    if ((!imagString) || imagString == "") {

      return image
    }
    else {
      image = 'data:image/png;base64,' + imagString;
      image = this.domSanitizer.bypassSecurityTrustUrl(image);
      return image
    }
  }

  editQuestionOption(data, index, value) {
    this.columnFor = value;
    this.columnName = data.column_name;
    this.columnValue = data.column_value;
    this.base64textQuestionOtionString = data.option_image;
    this.questionOptionImage = this.getImage(this.base64textQuestionOtionString);
    if (data.is_option_correct == "0") {
      this.isCorrect = true
    }
    else {
      this.isCorrect = false
    }
    this.purposeOfQuestionOptionModal = "Edit";
    this.isAddQueestionOptionModal = true;
    this.questionOptionIndex = index;
  }

  removeQuestionOption(data, index, value) {
    swal.fire({
      title: '',
      text: "Are you sure you want to remove this option?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        if (value == '1') {
          for (var i = 0; i < this.CheckedArray.length; i++) {
            if (data.column_name == this.CheckedArray[i].option) {
              this.CheckedArray.splice(i, 1)
            }
          }
          this.ColumnOne.splice(index, 1);
          this.generateMatrixMatchingAnswer();
        }
        else {

          for (var i = 0; i < this.CheckedArray.length; i++) {
            if (data.column_name == this.CheckedArray[i].answers) {

              this.CheckedArray.splice(i, 1);

            }
          }
          this.columnSecond.splice(index, 1);
          this.generateMatrixMatchingAnswer();
        }

      }
    })
  }

  clearData() {
    this.columnName = "";
    this.columnValue = "";
    this.isCorrect = false;
    this.questionOptionImage = null;
    this.base64textQuestionOtionString = "";
  }

  getByteSize(str) {
    var size = (new Blob([str]).size * .001).toFixed(2);
    return Number(size);
  }

  saveQuestion() {


    // for (var i = 0; i < this.CheckedArray.length; i++) {
    //   var isPresent = false;
    //   var remove;
    //   remove = i;
    //   for (let data of this.ColumnOne) {
    //     if (data.column_name == this.CheckedArray[i].option) {
    //       isPresent = true;
    //     }
    //   }

    //   if (!isPresent) {
    //     this.CheckedArray.splice(remove, 1)
    //   }
    // }
    // for (var i = 0; i < this.CheckedArray.length; i++) {
    //   var isPresent = false;
    //   var remove;
    //   remove = i;
    //   for (let data of this.columnSecond) {
    //     if (data.column_name == this.CheckedArray[i].answers) {
    //       isPresent = true;
    //     }
    //   }

    //   if (!isPresent) {
    //     this.CheckedArray.splice(remove, 1)
    //   }
    // }

    var checkColmnValueIsEmpty = false;
    for (let data of this.ColumnOne) {
      if (data.column_value == "") {
        checkColmnValueIsEmpty = true;
        break
      }
    }
    for (let data of this.columnSecond) {
      if (data.column_value == "") {
        checkColmnValueIsEmpty = true;
        break
      }
    }
    for (let data of this.ColumnOne) {
      var checkAllOptionISIncorrect = false;
      for (let match of this.CheckedArray) {
        if (data.column_name == match.option) {
          checkAllOptionISIncorrect = true;
        }
      }
      if (checkAllOptionISIncorrect == false) {
        break;
      }
    }

    var invalidQuesionTextSize = false;
    if (+this.getByteSize(this.questionText) > 64) {
      invalidQuesionTextSize = true
    }

    var invalidSolutionTextSize = false;
    if (this.sollutionText) {
      if (+this.getByteSize(this.sollutionText) > 64) {
        invalidSolutionTextSize = true
      }
    }

    var invalidOptionSize = false;
    for (let data of this.ColumnOne) {
      if (+this.getByteSize(data.option_text) > 64) {
        invalidOptionSize = true
        break
      }
    }

    for (let data of this.columnSecond) {
      if (+this.getByteSize(data.option_text) > 64) {
        invalidOptionSize = true
        break
      }
    }

    if (this.subjectId == "0") {
      swal.fire("Please select subject.");
    }
    else if (this.subSubjectId == "0") {
      swal.fire("Please select sub-subject.");
    }
    else if (this.topicId == "0") {
      swal.fire("Please select topic.");
    }
    else if (this.subTopicId == "0") {
      swal.fire("Please select sub-topic.");
    }
    else if (this.questionSourceId == "0") {
      swal.fire("Please select question source.");
    }
    else if (!(this.questionText)) {
      swal.fire("Question text  are compulsory");
    }
    else if (!(this.questionCode)) {
      swal.fire("Question tag  are compulsory");
    }
    // else if (this.ColumnOne.length == 0) {
    //   swal.fire("Please add some options to the column one.")
    // }
    // else if (this.columnSecond.length == 0) {
    //   swal.fire("Please add some options to the column second.")
    // }
    else if (!(this.sollutionText)) {
      swal.fire("Solution text are compulsory.")
    }
    else if (this.CheckedArray.length == 0) {
      swal.fire("Please select at least one matching of each column.")
    }
    else if (checkColmnValueIsEmpty == true) {
      swal.fire("Column value are compulsary.")
    }
    else if (checkAllOptionISIncorrect == false) {
      swal.fire("Atleast one matching of each column.")
    }
    else if (invalidQuesionTextSize || invalidSolutionTextSize || invalidOptionSize) {
      swal.fire("Allowed size exceeded. Please reduce text/image size for fields with validation errors.")
    }
    else {
      var matrixMatch = [];
      for (let data of this.MatrixMatchingAnswerTable) {
        var answers = [];
        var json = {}
        json["option"] = data.option
        for (let answer of data.answers) {
          if (answer.is_checked == "1") {
            answers.push(answer.column_name);
          }
        }
        json["answer"] = answers;
        matrixMatch.push(json);
      }
      if (!this.questionId) {
        let json = {
          "caller_email": localStorage.getItem("email"),
          "id_subject": this.subjectId,
          "id_sub_subject": this.subSubjectId,
          "id_topic": this.topicId,
          "id_sub_topic": this.subTopicId,
          "id_question_source": this.questionSourceId,
          "id_question_pattern": this.questionTypeId,
          "question_text": this.questionText,
          "question_image": this.base64textQuestionString,
          "column_one": JSON.stringify(this.ColumnOne),
          "column_second": JSON.stringify(this.columnSecond),
          "sollution_text": this.sollutionText,
          "matrix_match": JSON.stringify(this.CheckedArray),
          "solution_image": this.base64textSolutionString,
          "question_code": this.questionCode,
        }
        this.showLoader = true;
        this.matrixTypeQuestionService.addQuestion(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.showAlertComponent(data['status_code'], data['status_message']);
              this.hideQuestionModal();
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
      else {
        let json = {
          "id_question": this.questionId,
          "caller_email": localStorage.getItem("email"),
          "id_subject": this.subjectId,
          "id_sub_subject": this.subSubjectId,
          "id_topic": this.topicId,
          "id_sub_topic": this.subTopicId,
          "id_question_source": this.questionSourceId,
          "id_question_pattern": this.questionTypeId,
          "question_text": this.questionText,
          "question_image": this.base64textQuestionString,
          "column_one": JSON.stringify(this.ColumnOne),
          "column_second": JSON.stringify(this.columnSecond),
          "sollution_text": this.sollutionText,
          "matrix_match": JSON.stringify(this.CheckedArray),
          "solution_image": this.base64textSolutionString,
          "question_code": this.questionCode,
          "purpose": "edit"
        }
        this.showLoader = true;
        this.matrixTypeQuestionService.editQuestion(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.showAlertComponent(data['status_code'], data['status_message']);
              this.hideQuestionModal();
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }

    }
  }
  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }
  hideAlert(val) {
    this.showAlert = val;
  }
  hideQuestionModal(forData?: string) {
    this.isHidden.emit(forData);
  }


  generateMatrixMatchingAnswer() {
    this.MatrixMatchingAnswerTable = [];
    if (this.ColumnOne.length > 0 && this.columnSecond.length > 0) {
      for (let data of this.ColumnOne) {
        let json = {
          "option": data.column_name,
          "answers": this.columnSecond
        }
        this.MatrixMatchingAnswerTable.push(json)
      }
    }


  }

  checkUncheck(optionName, answerName, answer) {

    for (let data of this.MatrixMatchingAnswerTable) {
      for (let answer of data.answers) {
        if (data.option == optionName && answer.column_name == answerName) {
          if ((<HTMLInputElement>document.getElementById(optionName + answerName)).checked === true) {
            let json = {
              "option": optionName,
              "answers": answerName,
            }
            this.CheckedArray.push(json);
            break;
          }
          else {
            for (var i = 0; i < this.CheckedArray.length; i++) {
              if (this.CheckedArray[i].option == optionName && this.CheckedArray[i].answers == answerName) {
                this.CheckedArray.splice(i, 1)
                break;
              }
            }

          }
        }
      }
    }
  }

  checkIsCheckedOrNot(optionName, answerName) {
    let status = false;
    for (let data of this.CheckedArray) {
      if (data.option == optionName && data.answers == answerName) {
        status = true;
        break;
      }
    }

    return status;
  }

  approved() {
    var checkColmnValueIsEmpty = false;
    for (let data of this.ColumnOne) {
      if (data.column_value == "") {
        checkColmnValueIsEmpty = true;
        break
      }
    }
    for (let data of this.columnSecond) {
      if (data.column_value == "") {
        checkColmnValueIsEmpty = true;
        break
      }
    }
    for (let data of this.ColumnOne) {
      var checkAllOptionISIncorrect = false;
      for (let match of this.CheckedArray) {
        if (data.column_name == match.option) {
          checkAllOptionISIncorrect = true;
        }
      }
      if (checkAllOptionISIncorrect == false) {
        break;
      }
    }
    if (this.subjectId == "0") {
      swal.fire("Please select subject.");
    }
    else if (this.subSubjectId == "0") {
      swal.fire("Please select sub-subject.");
    }
    else if (this.topicId == "0") {
      swal.fire("Please select topic.");
    }
    else if (this.subTopicId == "0") {
      swal.fire("Please select sub-topic.");
    }
    else if (this.questionSourceId == "0") {
      swal.fire("Please select question source.");
    }
    else if (this.difficultyLevelId == "0") {
      swal.fire("Please select question difficulty level.");
    }
    else if (this.reviewRequirementId == "0") {
      swal.fire("Please select review frequency.");
    }
    else if (this.questionInterpretationId == "0") {
      swal.fire("Please select question interpretation.");
    }
    else if (this.timeOfQuestionId == "0") {
      swal.fire("Please select Time to solve.");
    }
    else if (!(this.questionText)) {
      swal.fire("Question text are compulsory");
    }
    else if (this.ColumnOne.length == 0) {
      swal.fire("Please add some options to the column one.")
    }
    else if (this.ColumnOne.length == 0) {
      swal.fire("Please add some options to the column second.")
    }
    else if (!(this.sollutionText)) {
      swal.fire("Solution text  are compulsory.")
    }
    else if (this.CheckedArray.length == 0) {
      swal.fire("Please select at least one matching of each column.")
    }
    else if (checkColmnValueIsEmpty == true) {
      swal.fire("Column value are compulsary.")
    }
    else if (checkAllOptionISIncorrect == false) {
      swal.fire("Atleast one matching of each column.")
    }
    else {
      var matrixMatch = [];
      for (let data of this.MatrixMatchingAnswerTable) {
        var answers = [];
        var json = {}
        json["option"] = data.option
        for (let answer of data.answers) {
          if (answer.is_checked == "1") {
            answers.push(answer.column_name);
          }
        }
        json["answer"] = answers;
        matrixMatch.push(json);
      }

      {
        let json = {
          "id_question": this.questionId,
          "caller_email": localStorage.getItem("email"),
          "id_subject": this.subjectId,
          "id_sub_subject": this.subSubjectId,
          "id_topic": this.topicId,
          "id_sub_topic": this.subTopicId,
          "id_question_source": this.questionSourceId,
          "id_difficult_level": this.difficultyLevelId,
          "id_question_pattern": this.questionTypeId,
          "id_review_requirement": this.reviewRequirementId,
          "question_text": this.questionText,
          "question_image": this.base64textQuestionString,
          "column_one": JSON.stringify(this.ColumnOne),
          "column_second": JSON.stringify(this.columnSecond),
          "sollution_text": this.sollutionText,
          "matrix_match": JSON.stringify(this.CheckedArray),
          "id_time_for_question": this.timeOfQuestionId,
          "id_question_interpretation": this.questionInterpretationId,
          "solution_image": this.base64textSolutionString,
          "question_code": this.questionCode,
          "purpose": "approved"
        }
        this.showLoader = true;
        this.matrixTypeQuestionService.editQuestion(json).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.showAlertComponent(data['status_code'], data['status_message']);
              this.hideQuestionModal('approved');
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }

    }
  }

  hideRejectionCommentModal() {
    this.isAddRejectionCommentModal = false
  }
  reject() {
    this.isAddRejectionCommentModal = true

  }

  saveComment() {
    if (!this.rejectComment) {
      swal.fire("Please enter comment")
    }
    else {
      let json = {
        "caller_email": localStorage.getItem("email"),
        "id_question": this.questionId,
        "comment": this.rejectComment
      }
      this.showLoader = true;
      this.matrixTypeQuestionService.rejectComment(json).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == 0) {
            this.showAlertComponent(data['status_code'], data['status_message']);
            this.hideQuestionModal();
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }
  }


  addNewColumnOne() {
    var columnNameCharCode = this.ColumnOne[this.ColumnOne.length - 1].column_name.charCodeAt(0);
    let json = {
      "column_name": String.fromCharCode(columnNameCharCode + 1),
      "column_value": "",
    }
    this.ColumnOne.push(json);
    this.generateMatrixMatchingAnswer();
  }

  addNewColumnSecond() {
    var columnNameCharCode = this.columnSecond[this.columnSecond.length - 1].column_name.charCodeAt(0);
    let json = {
      "column_name": String.fromCharCode(columnNameCharCode + 1),
      "column_value": "",
    }
    this.columnSecond.push(json);
    this.generateMatrixMatchingAnswer();
  }

  removeColumnOne(index) {
    var columnName = this.ColumnOne[index].column_name;

    var tempIndex = this.CheckedArray.findIndex(x => x.option == columnName)

    this.ColumnOne.splice(tempIndex, 1)

    this.ColumnOne.splice(index, 1)
    this.generateMatrixMatchingAnswer();
  }
  removeColumnSecond(index) {
    var columnName = this.columnSecond[index].column_name;

    var tempIndex = this.CheckedArray.findIndex(x => x.answers == columnName)

    this.ColumnOne.splice(tempIndex, 1)
    this.columnSecond.splice(index, 1)
    this.generateMatrixMatchingAnswer();
  }

  filterSource() {
    this.QuestionSources = [];

    if (this.idCourseType == 14)
      this.QuestionSources = this.orignalQuestionSources;

    if (this.idCourseType == 15) {
      this.orignalQuestionSources.
        forEach(source => {
          if (source['source_for'] != 16) {
            this.QuestionSources.push(source);
          }
        });
    }

    if (this.idCourseType == 16) {

      this.orignalQuestionSources.
        forEach(source => {
          if (source['source_for'] != 15) {
            this.QuestionSources.push(source);
          }
        });

    }

    if(this.firstTime){
      var flag = false; 
      this.selectedPatternItems = [];
      this.QuestionSources.forEach(item => {
        if (item.id_question_source == this.questionSourceId) {
          this.selectedPatternItems.push({ id_question_source: this.questionSourceId, name: item.name });
          flag = true;
        }
      });

      if(!flag)
        this.questionSourceId = '0';

      this.firstTime = false;  
    }else{
      this.questionSourceId = '0';
      this.selectedPatternItems = [];
    }
  }

  onItemDeSelectQuestionSource() {
    this.questionSourceId = '0';
    this.selectedPatternItems = [];
  }

  onItemSelectQuestionSource(event) {
    console.log("id_question_source=" + event.id_question_source);
    this.questionSourceId = event.id_question_source;
  }

}

