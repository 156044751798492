import { Component,AfterContentChecked } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterContentChecked {
  title = 'app';
  isUserLogin=false;
  constructor(){
  }

  
  ngAfterContentChecked() {
    ////("ngAfterContentChecked");
    if(localStorage.getItem("isLogin")=="true"){
      this.isUserLogin=true
    }
    else{
     this.isUserLogin=false
    }
  
  }
}
