import { Component, OnInit } from '@angular/core';
import { BookMasterService } from '../book-master/book-master.service'
import swal from 'sweetalert2'
import { CSVExport } from '../Csv-export';
@Component({
  selector: 'book-type',
  templateUrl: './book-type.component.html',
  styleUrls: ['./book-type.component.css'],
  providers: [BookMasterService]
})
export class BookTypeComponent implements OnInit {
  searchText: any;
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  bookTypeObject: any = {};
  isAddModal = false;
  showLoader = false;
  BookTypes = [];
  isAdd = 0;
  purpose = "Add"
  constructor(private bookMasterService: BookMasterService) {

  }
  ngOnInit() {
    this.getAllBookType()

  }


  getAllBookType() {
    this.showLoader = true;
    this.bookMasterService.getAllBookType(localStorage.getItem("email")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.BookTypes = data.book_types
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  addBookType() {
    this.bookTypeObject = {}
    this.isAddModal = true
    this.isAdd = 0
  }

  editBookType(data) {
    this.purpose = "Edit"
    this.isAddModal = true
    this.isAdd = 1
    this.bookTypeObject = Object.assign({}, data);
  }

  save() {
    // this.showLoader = true;
    this.bookTypeObject['email'] = localStorage.getItem("email")
    if (this.isAdd == 0) {
      this.bookMasterService.addBookType(this.bookTypeObject).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.closeModal();
            this.getAllBookType()
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }
    else {
      this.bookMasterService.editBookType(this.bookTypeObject).subscribe(
        data => {
          this.showLoader = false;
          if (data.status_code == "0") {
            this.closeModal();
            this.getAllBookType()
          }
          else {
            this.showAlertComponent(data['status_code'], data['status_message']);
          }
        },
        err => {
          this.showLoader = false;
          swal.fire("Something went wrong. Please try again later.")
        }
      )
    }

  }
  closeModal() {
    this.isAddModal = false
  }

  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }

  hideAlert(val) {
    this.showAlert = val;
  }

  removeBookType(data) {
    swal.fire({
      title: '',
      text: "Deleting an entity will remove that entity and all it's child entities from the system permanently. This action cannot be undone.Are you sure you want to remove this Book Type?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8B0000',
      cancelButtonColor: '#428e63',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        this.bookMasterService.removeBookType(localStorage.getItem("email"), data.id_book_type).subscribe(
          data => {
            this.showLoader = false;
            if (data.status_code == 0) {
              this.getAllBookType()
              this.showAlertComponent(data['status_code'], data['status_message']);
            }
            else {
              this.showAlertComponent(data['status_code'], data['status_message']);

            }
          },
          err => {
            this.showLoader = false;
            swal.fire("Something went wrong. Please try again later.")
          }
        )
      }
    })
  }

  export() {
    var arrayToExport = [];
    if (this.BookTypes.length > 0) {
      for (let data of this.BookTypes) {
        let json = {
          "Book Type": data.name,
          "Description": data.description,

        }

        arrayToExport.push(json);


      }
      CSVExport.downloadcsv(arrayToExport, "Book Type");
    }
  }
}
