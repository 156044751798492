import { Component, OnInit } from '@angular/core';
import { BookMasterService } from '../book-master/book-master.service'
import swal from 'sweetalert2'
import { DatePipe } from '@angular/common'
@Component({
  selector: 'app-scholar-library-record',
  templateUrl: './scholar-library-record.component.html',
  styleUrls: ['./scholar-library-record.component.css'],
  providers: [BookMasterService, DatePipe]
})
export class ScholarLibraryRecordComponent implements OnInit {
  UnfilterdBookCopies = []  
  BookCopies = []
  searchText: any;
  bookStatus = [];
  showAlert: boolean;
  alertMsg: any;
  alertType = "";
  showLoader = false;
  IssueReturn = true;
  bookIssued = 0
  returnedBook = 0
  totalFine = 0
  fromDate: any;
  toDate: any;
  selectedBookStatusId = 0;
  constructor(private bookMasterService: BookMasterService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getAllAllotedAndDamagedCopiesInLibrary()
  }

  getAllAllotedAndDamagedCopiesInLibrary() {

    this.showLoader = true;
    this.UnfilterdBookCopies = []
    this.BookCopies = [];
    this.bookMasterService.getAllAllotedAndDamagedCopiesInLibraryByScholar(localStorage.getItem("email"), localStorage.getItem("id_user")).subscribe(
      data => {
        this.showLoader = false;
        if (data.status_code == "0") {
          this.UnfilterdBookCopies = data.book_copies;
          this.BookCopies = data.book_copies;
          this.bookStatus = data.book_status;
          console.log("BookCopies length="+this.BookCopies.length);
          console.log("bookStatus length="+this.bookStatus.length);
          this.calculateData()
        }
        else {
          this.showAlertComponent(data['status_code'], data['status_message']);
        }
      },
      err => {
        this.showLoader = false;
        swal.fire("Something went wrong. Please try again later.")
      }
    )
  }

  calculateData() {
    this.totalFine = 0
    this.bookIssued = 0
    this.returnedBook = 0
    for (let data of this.BookCopies) {
      if (data.book_status == '17' || data.book_status == '21' || data.book_status == '19' || data.book_status == '20') {
        if (data.book_status == '17') {
          this.bookIssued = this.bookIssued + 1
        }
        else {
          this.returnedBook = this.returnedBook + 1
        }
        this.totalFine = this.totalFine = data.fine
        // this.BookCopies.push(data)
      }
    }
  }
  showAlertComponent(status, messege) {
    this.showAlert = true;
    this.alertMsg = messege;
    if (parseInt(status) == 0) {
      this.alertType = "success";
    }
    else {
      this.alertType = "failure";
    }
  }


  filterCopiesByDate() {
     
    console.log("UnfilterdBookCopies length = "+this.UnfilterdBookCopies.length);
    console.log("BookCopies length = "+this.BookCopies.length);
     console.log("this.fromDate = "+this.fromDate);
    //  this.selectedBookStatusId = 0;
    //(this.fromDate)
    if (this.fromDate && this.toDate) {
      this.BookCopies = [];
      for (let data of this.UnfilterdBookCopies) {
        if (this.datePipe.transform(data.issued_date, 'yyyy-MM-dd') >= this.fromDate && this.datePipe.transform(data.issued_date, 'yyyy-MM-dd') <= this.toDate) {
          this.BookCopies.push(data)
        }
      }
    }
    else if (this.fromDate) {
      this.BookCopies = [];
      for (let data of this.UnfilterdBookCopies) {
        //(this.datePipe.transform(data.issued_date, 'yyyy-MM-dd'))
        if (this.datePipe.transform(data.issued_date, 'yyyy-MM-dd') >= this.fromDate) {
          this.BookCopies.push(data)
        }
      }
    }
    else if (this.toDate) {
      this.BookCopies = [];
      for (let data of this.UnfilterdBookCopies) {
        if (this.datePipe.transform(data.issued_date, 'yyyy-MM-dd') <= this.toDate) {
          this.BookCopies.push(data)
        }
      }
    }

    if(!(this.fromDate && this.toDate))
    this.BookCopies = this.UnfilterdBookCopies;

    console.log("after BookCopies length="+this.BookCopies.length);
   if(this.selectedBookStatusId != 0)
   {    
    var tempBookCopies = [];
    console.log("selectedBookStatusId="+this.selectedBookStatusId);
    // FILTER BY BOOK STATUS
    this.BookCopies.forEach(book => {
      if(book.book_status === this.selectedBookStatusId)
      {
        console.log("book_status="+book.book_status);
        tempBookCopies.push(book);
      }
    });
    this.BookCopies = tempBookCopies;
   } 
    this.calculateData()
  }

  
}
